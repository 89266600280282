import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
// import { MenuService } from '../../shared/services/menu.service';
import { HomeService } from '../../shared/services/home.service';
import { Subscription } from 'rxjs';
import { HomeComponent } from '../home/home.component';
import { CommonService } from '../../shared/services/common.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  // @Input() data:any;
  // currUserEmail = localStorage['email'];

  // navbarOpen = false;
  // isNewWindow: boolean = false;
  // opened: boolean;
  // isColor:string = 'colorGreen';
  // menuConfig:any;
  // wordLogo:any = '';
  getAllMessage: Subscription;
  notifListItem = [];
  notifList = [];

  constructor(
    private authService: AuthService,
    private homeService: HomeService,
    private homeComponent: HomeComponent,
    private _commonService: CommonService
  ) {}

  ngOnInit() {
    var allMessageCache = this.homeService.getAllMessageCache();
    this.notifList = allMessageCache;
    // console.log(this.notifList);
    // this.homeService.getAllMessagePanelCache();//init message panel

    this.notifListItem = this.homeService.getMessageMessageList();

    this.getAllMessage = this.homeService.getAllMessage().subscribe(message => {
      // console.log(message);
      // console.log(this.notifListItem);
      // console.log(this.notifList);
      const currItemIndex = this.notifList.findIndex(
        item => item.busRegNo === message.busRegNo
      );
      if (currItemIndex < 0) {
        // console.log(1);
        this.notifListItem.push(message.busRegNo);
        this.notifList.push(message);
        // this.homeService.addMessageCache(message);
      } else {
        this.notifList[currItemIndex] = message;
        this.notifList.unshift(this.notifList.splice(currItemIndex, 1)[0]);
      }
    });
  }

  onLogout() {
    this._commonService.logout();
  }

  // toggleNavbar() {
  //   this.navbarOpen = !this.navbarOpen;
  // }

  notifEvent(data) {
    console.log('notifList', this.notifList);
    console.log('notif', data);
    for (const message of this.homeService.setPredefinedData) {
      if (message.busRegNo === data.busRegNo) {
        message.isVisible = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.getAllMessage.unsubscribe();
  }
}
