import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/services/auth.service';
import { CommonService } from '../../shared/services/common.service';
import { AddRoleComponent } from './add-role/add-role.component';
import { ConfirmModalComponent } from '../../shared/services/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent implements OnInit {
  isNewWindow: boolean = false;
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  dataPageClone;
  dataPage = {
    pageList: [
      // {
      //   'pageId': '1',
      //   'pageName': 'Vehicle Management'
      // },
      // {
      //   'pageId': '2',
      //   'pageName': 'Device Monitoring'
      // },
      // {
      //   'pageId': '3',
      //   'pageName': 'Service Route Management'
      // },
      // {
      //   'pageId': '4',
      //   'pageName': 'Time Table'
      // },
      // {
      //   'pageId': '5',
      //   'pageName': 'Playback'
      // },
      // {
      //   'pageId': '6',
      //   'pageName': 'Driver Management'
      // },
      // {
      //   'pageId': '7',
      //   'pageName': 'Report'
      // }
    ],

    roleList: [
      // {
      //   'roleId': '11',
      //   'roleName': 'Controller',
      //   'pages': [
      //     {
      //       'pageId': '4',
      //       // 'pageName': 'Time Table2'
      //     },
      //     {
      //       'pageId': '5',
      //       // 'pageName': 'Playback'
      //     }
      //   ]
      // },
      // {
      //   'roleId': '12',
      //   'roleName': 'Admin',
      //   'pages': [
      //     {
      //       'pageId': '1',
      //       // 'pageName': 'Vehicle Management'
      //     },
      //     {
      //       'pageId': '2',
      //       // 'pageName': 'Device Monitoring'
      //     }
      //   ]
      // }
    ],
    permissionList: [],
  };
  permissionListClone: any = [];
  pageData = {
    id: 8,
    name: 'roleManagement',
    displayPageName: 'Role Management',
  };
  isHasAccess: boolean = false;
  rightsError: string = '';
  isEdit: boolean = false;
  isLoading: boolean = false;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    public authService: AuthService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.dataPage.pageList = JSON.parse(localStorage.getItem('featureList'));
      this.getAllRoles();
      // this.authService.initMenu();

      if (this.route.snapshot.params.id === '2') {
        this.isNewWindow = true;
      }

      this.isHasAccess = this.authService.isHasAccess(this.pageData);

      this.rightsError = "You don't have access rights to this module.";
    }, 500);
  }

  getAllRoles() {
    var urlAction = 'users/v1/getRoleFeatureMaster';
    var featureListSort = environment.featureListSorting;

    this._commonService.commonPostAction(urlAction, '').subscribe(roles => {
      let idx = 1;
      this.dataPage.pageList.forEach(element => {
        // console.log(this.dataPage);
        let isExist = featureListSort.find(x => x.name === element.name);
        if (isExist) {
          element.priorityNumber = isExist.priorityNumber;
        } else {
          element.priorityNumber = featureListSort.length + idx;
          idx++;
        }
      });
      this.dataPage.pageList.sort((a, b) =>
        a.priorityNumber > b.priorityNumber ? 1 : -1
      );

      // console.log(featureListSort);

      // console.log('Roles: role API: ', this.dataPage, roles);
      for (const key in roles) {
        if (roles.hasOwnProperty(key)) {
          // console.log(`${key} : ${roles[key]}`);
          let pageId = [];
          let idx = 1;
          roles[key].forEach(element => {
            let isExist = featureListSort.find(x => x.id === element);
            let priorityNumber;
            let eventName;

            if (isExist) {
              priorityNumber = isExist.priorityNumber;
              eventName = isExist.name;
            } else {
              priorityNumber = featureListSort.length + idx;
              eventName = '';
              idx++;
            }
            pageId.push({
              id: element,
              priorityNumber: priorityNumber,
              name: eventName,
              isChecked: true,
            });
          });

          this.dataPage.roleList.push({
            roleName: key,
            pages: pageId,
            isEdit: false,
          });
        }
      }

      this.permissionListClone = this.dataPage.pageList.map(featurePage => {
        const permissions = Object.entries(roles).map(
          ([key, values]: [string, Number[]]) => {
            const checked =
              values?.some(featureId => featurePage?.id === featureId) ?? false;
            return {
              roleName: key,
              checked,
            };
          }
        );
        return {
          id: featurePage.id,
          name: featurePage.name,
          priority: featurePage.priorityNumber,
          permissions,
        };
      });
      this.dataPage.permissionList = JSON.parse(
        JSON.stringify(this.permissionListClone)
      );
      // console.log('permission list', this.permissionListClone);

      // console.log(this.dataPage.roleList);
      // return false;

      // var urlAction = 'users/v1/getRolesMasterData';
      // this._commonService.commonPostAction(urlAction, '').subscribe(roleData => {

      this.dataPage.roleList.forEach(role => {
        // var roleSearch = roleData.find(x => x.name === role.roleName);
        // role.id = roleSearch.id;

        let difference = role.pages.filter(
          x => !this.dataPage.pageList.includes(x)
        );
        // console.log(difference);
        this.dataPage.pageList.forEach(element => {
          // console.log(element);

          let isExist = difference.find(
            x => parseInt(x.id) === parseInt(element.id)
          );
          // console.log(isExist, element.pageId);
          if (!isExist) {
            // role.pages.push(element);
            role.pages.push({
              id: element.id,
              priorityNumber: element.priorityNumber,
              name: element.name,
              isChecked: false,
            });
          }
        });
        // role.pages.sort();
        // role.pages.sort((a, b) => (a.id > b.id) ? 1 : -1)
        role.pages.sort((a, b) =>
          a.priorityNumber > b.priorityNumber ? 1 : -1
        );
      });

      // this.dataPage.roleList.sort((a, b) => (a.id > b.id) ? 1 : -1)
      this.dataPageClone = JSON.parse(JSON.stringify(this.dataPage));
      // console.log('Role Management: Roles: ',this.dataPageClone);

      // });
    });
  }

  openWindow() {
    this._router.navigate(['/account/home']);
    let newwin = window.open(
      'account/system-configuration/account-management/role/2',
      'role management',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  // onFilter(data) {
  //   var filterData = data.trim();
  //   if(filterData.length === 0) {
  //     this.dataPage.roleList = this.dataPageClone.roleList;
  //   }
  //   else {
  //     let role = this.dataPage.roleList.filter(x => x.roleName.toLowerCase().includes(filterData.toLowerCase()));
  //     this.dataPage.roleList = role;
  //   }
  // }

  onAddRole() {
    var dialogData = {
      data: {
        pageList: this.dataPageClone.pageList,
      },
    };

    const dialogRef = this.dialog.open(AddRoleComponent, {
      data: dialogData,
      disableClose: true,
      // height: '400px',
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result);
        // console.log(this.dataPage);
        let difference = result.pages.filter(
          x => !this.dataPage.pageList.includes(x)
        );

        this.dataPage.pageList.forEach(element => {
          let isExist = difference.find(x => x.id === element.id);
          if (!isExist) {
            result.pages.push({
              id: element.id,
              name: '',
              isChecked: false,
            });
          }
        });
        result.pages.sort((a, b) => (a.id > b.id ? 1 : -1));

        this.dataPage.roleList.unshift(result);
      }
    });
  }

  onEdit(data, event = null) {
    // console.log(data);

    if (event === 'cancel') {
      let respAccount = this.dataPageClone.roleList.find(
        x => x.roleName === data.roleName
      );
      // console.log(respAccount);

      let resp = this.dataPage.roleList.find(x => x.roleName === data.roleName);
      // console.log(resp);

      resp.pages = JSON.parse(JSON.stringify(respAccount.pages));

      data.isEdit = false;
      return false;
    }
    data.isEdit = !data.isEdit;

    // console.log(this.dataPage);
    // console.log(event);
    // var respAccount = this.dataPageClone.respList.find(x => x.respId === data.respId);
    // console.log(respAccount);
    // respAccount.respList = data;
  }

  onSave(data) {
    var idsOnly = [];
    data.pages.forEach(element => {
      if (element.isChecked) {
        idsOnly.push(element.id);
      }
    });
    var dataBody = {
      role: data.roleName,
      feaureIdList: idsOnly,
    };

    if (idsOnly.length <= 0) {
      this._snackBar.open('Atleast select 1 feature', null, {
        duration: 5000,
        panelClass: 'custom-snack-bar-panel-error',
      });
      return false;
    }

    var urlAction = 'users/v1/assignRoleFeature';
    this._commonService.commonPostAction(urlAction, dataBody).subscribe(
      roles => {
        // this.dialogRef.close({
        //   "isEdit": false,
        //   "roleName": roleName,
        //   "pages": ids
        // });

        this._snackBar.open('Update Successfully.', null, {
          duration: 5000,
          panelClass: 'custom-snack-bar-panel-success',
        });
      },
      err => {
        this._snackBar.open(err.error.errorMessage, null, {
          duration: 5000,
          panelClass: 'custom-snack-bar-panel-error',
        });
      }
    );

    data.isEdit = !data.isEdit;
  }

  changeEditMode(value: boolean) {
    this.isEdit = value;
  }

  cancelEdit() {
    if (this.isLoading) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        header: 'Cancel',
        message: 'Do you want to cancel editing and discard changes?',
        mode: 'warn',
        textConfirm: 'Yes, discard changes',
        textCancel: 'No, continue editing',
      },
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.dataPage.permissionList = JSON.parse(
          JSON.stringify(this.permissionListClone)
        );
        this.isEdit = false;
      }
    });
  }

  editSave() {
    if (this.isLoading) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        header: 'Update role access',
        message: 'Do you want to save changes?',
        mode: 'warn',
        textConfirm: 'Yes, save changes',
        textCancel: 'No, continue editing',
      },
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult) {
        this.isLoading = true;
        const roleResults = this.dataPage.roleList.map(role => {
          const rolePermissions = [];
          this.dataPage.permissionList.forEach(rolePermission => {
            const { permissions, id } = rolePermission;
            const currentPermission = permissions.find(
              perm => perm.roleName === role.roleName
            );
            if (!!currentPermission?.checked) {
              rolePermissions.push(id);
            }
          });
          return {
            role: role.roleName,
            feaureIdList: rolePermissions, // some type in the API
          };
        });

        // current API only does one role at a time, need to process each
        // console.log('Save: ', roleResults);
        try {
          const urlAction = 'users/v1/assignRoleFeature';
          const getRolePromise = roleResult => {
            return new Promise((resolve, reject) => {
              // promise test
              // if (roleResult.role === 'admin') {
              //   setTimeout(() => {
              //     console.log('roleresult', roleResult);
              //     resolve(roleResult.role);
              //   }, 1000);
              // } else {
              //   setTimeout(() => {
              //     console.log('roleresult', roleResult);
              //     reject(roleResult.role);
              //   }, 1000);
              // }
              this._commonService
                .commonPostAction(urlAction, roleResult)
                .subscribe(
                  roles => {
                    resolve(roleResult.role);
                  },
                  err => {
                    reject({ error: err, role: roleResult.role });
                  }
                );
            });
          };
          const rolePromises = roleResults.map(roleResult =>
            getRolePromise(roleResult)
          );
          const results = await Promise.allSettled(rolePromises); //es2020
          // console.log('Save results', results);
          // const fulfilledResult: any = results.filter((result) => result?.status === 'fulfilled') ?? [];
          const rejectedResult: any =
            results.filter(result => result?.status === 'rejected') ?? [];
          let resultMessage = '';
          if (rejectedResult.length > 0) {
            const rejectedRoles =
              rejectedResult.map(result => {
                return (
                  result?.reason?.role[0].toUpperCase() +
                  (result?.reason?.role?.slice(1) ?? '')
                );
              }) ?? [];
            resultMessage =
              'An error occurred when updating ' +
              rejectedRoles?.join(', ') +
              ' permissions. Please try again.';
            throw { error: { errorMessage: resultMessage } };
          } else {
            this._snackBar.open(
              'Successfully updated role access permissions.',
              null,
              {
                duration: 5000,
                panelClass: 'custom-snack-bar-panel-success',
              }
            );
            this.permissionListClone = JSON.parse(
              JSON.stringify(this.dataPage.permissionList)
            );
            this.isEdit = false;
          }
        } catch (e) {
          console.log('Role Access Update Error: ', e);
          const message = e?.error?.errorMessage
            ? e?.error?.errorMessage
            : 'There was an error when updating. Please try again.';
          this._snackBar.open(message, null, {
            duration: 5000,
            panelClass: 'custom-snack-bar-panel-error',
          });
        }
        this.isLoading = false;
      }
    });
  }
}
