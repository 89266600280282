import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/component/shared/services/map.service';

import OlMap from 'ol/Map';
import OlTileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
import Point from 'ol/geom/Point';
import OlFeature from 'ol/Feature';
import { ScaleLine, defaults as defaultOlControls } from 'ol/control';
import { Vector as VectorSource } from 'ol/source.js';
import {
  Vector as VectorLayer,
  VectorImage as VectorImageLayer,
  Image as ImageLayer,
} from 'ol/layer.js';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style.js';
import { transform } from 'ol/proj.js';
import { fromLonLat } from 'ol/proj';
import LineString from 'ol/geom/LineString.js';
import OSM from 'ol/source/OSM';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { StopManagementService } from 'src/app/component/shared/services/stop-management.service';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
import { InfoModalComponent } from 'src/app/component/shared/services/info-modal/info-modal.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfirmModalComponent } from 'src/app/component/shared/services/confirm-modal/confirm-modal.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { MenuService } from 'src/app/component/shared/services/menu.service';
import { NgxSpinnerService } from 'ngx-spinner';

import GoogleLayer from 'olgm/layer/Google.js';
import OLGoogleMaps from 'olgm/OLGoogleMaps.js';
import { fadeInOut } from 'src/app/component/shared/others/animation/fadeInOut';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { MatSort } from '@angular/material/sort';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { slideInLeft } from 'src/app/component/shared/others/animation/slide';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { boundingExtent } from 'ol/extent';
import { MockMapService } from '../mock-map/mock-map-service.service';
import { demoResultService, routeLabel } from './demo-route-data';
import { demoStreetLines } from '../demo-stop-management/demo-stop-data';

declare var decode: any;
declare var H: any;

@Component({
  selector: 'app-demo-route-management',
  templateUrl: './demo-route-management.component.html',
  styleUrls: ['./demo-route-management.component.scss'],
  animations: [fadeInOut, slideInLeft],
})
export class DemoRouteManagementComponent {
  routeLabel = routeLabel;
  map: OlMap;
  modalAddStopData = {};
  running: boolean = false; // doing some blocking action
  isPanel: boolean = false; // stop detail panel is displayed
  routeServiceDisplay: 'map' | 'table' = 'table';
  mode: 'reset' | 'new' | 'view' | 'edit' = 'reset';
  isMapModeInit: boolean = false;
  isServiceExist: boolean = false;
  selectedServiceData: any = {};
  get isModify() {
    return this.mode === 'new' || this.mode === 'edit';
  }

  panelData: any;
  isLoop: boolean = true; // service route - loop mode
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  @ViewChild('chatPS2') chatPS2: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild(MatSort) sort: MatSort;
  currDirection: number = 1;
  direction1Data: any = [];
  direction2Data = [];
  direction1DataPath = [];
  direction2DataPath = [];
  direction1Distance = [];
  direction2Distance = [];

  searchForm = new FormGroup({
    searchService: new FormControl(null, []),
    newService: new FormControl(null, []),
    startPoint: new FormControl(null, []),
    endPoint: new FormControl(null, []),
    streetName: new FormControl(null, [Validators.required]),
  });

  options: any = [];
  filteredOptions: Observable<any[]>;
  selectedStreetData1: any = {};
  selectedStopCount: number = 0;
  isSearchStreet: boolean = false;

  showDistance = environment.serviceRouteMgmt.distance ?? false;

  boundingExtent = boundingExtent([
    fromLonLat(environment.map.boundingExtent.min),
    fromLonLat(environment.map.boundingExtent.max),
  ]);

  viewAnimate: OlView = new OlView({
    center: fromLonLat(environment.map.locationLonLat),
    zoom: environment.map.defaultZoom,
    minZoom: environment.map.minZoom,
    maxZoom: environment.map.maxZoom,
    projection: 'EPSG:900913',
    extent: this.boundingExtent,
  });
  busIntTer: any[] = [
    {
      stopName: "S'pore Indoor Stadium",
      stopCode: '80199',
      stopLat: '1.3016',
      stopLon: '103.8751',
    },
    {
      stopName: 'Ang Mo Kio Bus Interchange',
      stopCode: '54009',
      stopLat: '1.369841',
      stopLon: '103.848094',
    },
    {
      stopName: 'Bedok Bus Interchange',
      stopCode: '84009',
      stopLat: '1.324553',
      stopLon: '103.929143',
    },
    {
      stopName: 'Bishan Bus Interchange',
      stopCode: '53009',
      stopLat: '1.35047',
      stopLon: '103.84978',
    },
    {
      stopName: 'Boon Lay Bus Interchange',
      stopCode: '22009',
      stopLat: '1.339132',
      stopLon: '103.705296',
    },
    {
      stopName: 'Bukit Batok Bus Interchange',
      stopCode: '43009',
      stopLat: '1.350008',
      stopLon: '103.750774',
    },
    {
      stopName: 'Bukit Merah Bus Interchange',
      stopCode: '10009',
      stopLat: '1.28208',
      stopLon: '103.817215',
    },
    {
      stopName: 'Bukit Panjang Bus Interchange',
      stopCode: '45009',
      stopLat: '1.378445',
      stopLon: '103.76284',
    },
    {
      stopName: 'Choa Chu Kang Bus Interchange',
      stopCode: '44009',
      stopLat: '1.385647',
      stopLon: '103.7453',
    },
    {
      stopName: 'Clementi Bus Interchange',
      stopCode: '17009',
      stopLat: '1.314793',
      stopLon: '103.764205',
    },
    {
      stopName: 'Compassvale Bus Interchange',
      stopCode: '67759',
      stopLat: '1.39122',
      stopLon: '103.89712',
    },
    {
      stopName: 'Eunos Bus Interchange',
      stopCode: '82009',
      stopLat: '1.31948',
      stopLon: '103.90166',
    },
    {
      stopName: 'HarbourFront Bus Interchange',
      stopCode: '14009',
      stopLat: '1.266777',
      stopLon: '103.819546',
    },
    {
      stopName: 'Hougang Central Bus Interchange',
      stopCode: '64009',
      stopLat: '1.37055',
      stopLon: '103.8923',
    },
    {
      stopName: 'Joo Koon Bus Interchange',
      stopCode: '24009',
      stopLat: '1.32697',
      stopLon: '103.6784',
    },
    {
      stopName: 'Jurong East Temporary Bus Interchange',
      stopCode: '28009',
      stopLat: '1.33325',
      stopLon: '103.742',
    },
    {
      stopName: 'Pasir Ris Bus Interchange',
      stopCode: '77009',
      stopLat: '1.373745',
      stopLon: '103.949681',
    },
    {
      stopName: 'Punggol Temporary Bus Interchange',
      stopCode: '65009',
      stopLat: '1.40443',
      stopLon: '103.90244',
    },
    {
      stopName: 'Sembawang Bus Interchange',
      stopCode: '58009',
      stopLat: '1.447427',
      stopLon: '103.819953',
    },
    {
      stopName: 'Sengkang Bus Interchange',
      stopCode: '67009',
      stopLat: '1.39174',
      stopLon: '103.89572',
    },
    {
      stopName: 'Serangoon Bus Interchange',
      stopCode: '66009',
      stopLat: '1.350799',
      stopLon: '103.872143',
    },
    {
      stopName: 'Tampines Bus Interchange',
      stopCode: '75009',
      stopLat: '1.354341',
      stopLon: '103.943616',
    },
    {
      stopName: 'Tampines Concourse Bus Interchange',
      stopCode: '75019',
      stopLat: '1.357283',
      stopLon: '103.941798',
    },
    {
      stopName: 'Toa Payoh Bus Interchange',
      stopCode: '52009',
      stopLat: '1.331623',
      stopLon: '103.847519',
    },
    {
      stopName: 'Woodlands Temporary Bus Interchange',
      stopCode: '46010',
      stopLat: '1.43739',
      stopLon: '103.78583',
    },
    {
      stopName: 'Yio Chu Kang Bus Interchange',
      stopCode: '55509',
      stopLat: '1.382573',
      stopLon: '103.844619',
    },
    {
      stopName: 'Yishun Bus Interchange',
      stopCode: '59008',
      stopLat: '1.427485',
      stopLon: '103.836293',
    },
    {
      stopName: 'Buona Vista Bus Terminal',
      stopCode: '11379',
      stopLat: '1.309416',
      stopLon: '103.79208',
    },
    {
      stopName: 'Changi Airport Bus Terminal',
      stopCode: '95129',
      stopLat: '1.356985',
      stopLon: '103.989906',
    },
    {
      stopName: 'Changi Business Park Bus Terminal',
      stopCode: '97009',
      stopLat: '1.341888',
      stopLon: '103.967137',
    },
    {
      stopName: 'Changi Village Bus Terminal',
      stopCode: '99009',
      stopLat: '1.38949',
      stopLon: '103.98791',
    },
    {
      stopName: 'Ghim Moh Bus Terminal',
      stopCode: '11009',
      stopLat: '1.31099',
      stopLon: '103.78924',
    },
    {
      stopName: 'Kampong Bahru Bus Terminal',
      stopCode: '10499',
      stopLat: '1.275969',
      stopLon: '103.83346',
    },
    {
      stopName: 'Kent Ridge Bus Terminal',
      stopCode: '16009',
      stopLat: '1.29444',
      stopLon: '103.76991',
    },
    {
      stopName: 'Lorong 1 Geylang Bus Terminal',
      stopCode: '80009',
      stopLat: '1.310304',
      stopLon: '103.871817',
    },
    {
      stopName: 'Marina Centre Bus Terminal',
      stopCode: '2099',
      stopLat: '1.29224',
      stopLon: '103.86166',
    },
    {
      stopName: 'Queen Street Bus Terminal',
      stopCode: '1109',
      stopLat: '1.303571',
      stopLon: '103.856509',
    },
    {
      stopName: 'Shenton Way Bus Terminal',
      stopCode: '3239',
      stopLat: '1.27429',
      stopLon: '103.84756',
    },
    {
      stopName: 'Sims Place Bus Terminal',
      stopCode: '80289',
      stopLat: '1.31608',
      stopLon: '103.87933',
    },
    {
      stopName: "St. Michael/'s Bus Terminal",
      stopCode: '52491',
      stopLat: '1.32597',
      stopLon: '103.855314',
    },
    {
      stopName: 'Tuas Bus Terminal',
      stopCode: '25009',
      stopLat: '1.34173',
      stopLon: '103.63953',
    },
    {
      stopName: 'Upper East Coast Bus Terminal',
      stopCode: '94009',
      stopLat: '1.317946',
      stopLon: '103.952805',
    },
  ];

  @ViewChild('mapElementRef') mapElementRef: ElementRef;
  @ViewChild('inputSearchStreet', { read: MatAutocompleteTrigger })
  inputSearchStreet: MatAutocompleteTrigger;

  @ViewChild(MatAutocomplete) inputSearchStreetAutoComplete: MatAutocomplete;
  // @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputSearchService: MatAutocompleteTrigger;

  streetNameBusStop: any = '';
  isBusCodeSearch: boolean = false;
  isEverySearchLock: boolean = false;

  OSMLayer: OlTileLayer<any>;
  mapOptions: any = {
    isIncident: false,
    isLadder: true,
    isMapLayer: false,
    isDirection: false,
  };
  allTrainStation: any;
  isStopZoom: boolean = false;
  // hereMapName:string = '';
  routeType: string = 'motorway';
  directionProvider: string = '';
  isExpand: boolean = false;

  constructor(
    // private homeService: HomeService,
    // private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    private mapService: MapService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private stopManagementService: StopManagementService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    public authService: AuthService,
    private menuService: MenuService,
    private _spinner: NgxSpinnerService,
    private _mapService: MapService,
    private mockMapService: MockMapService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.initMap();
      this.initRouteAutocomplete();
    }, 300);
  }

  selectedStreetDirection1Data: any = {};
  selectedStreetDirection2Data: any = {};
  selectedStreetDirection2PathData: any = {};
  selectedStreetDirection1PathData: any = {};
  resultServiceSearch: any = {};
  direction1Count: number = 0;
  direction2Count: number = 0;
  searchServiceRoute(event?) {
    if (event) {
      event?.preventDefault();
      event?.stopPropagation();
    }

    this.running = true;
    this.options = [];
    //remove existing layers
    this.map
      .getLayers()
      .getArray()
      .filter(
        layer =>
          layer.get('name') === 'pathStreet-1' ||
          layer.get('name') === 'pathStreet-2' ||
          layer.get('name') === 'stopOtherDirection-1' ||
          layer.get('name') === 'stopOtherDirection-2'
      )
      .forEach((layer2: VectorImageLayer<any> | VectorLayer<any>) => {
        // this.map.removeLayer(layer)
        let features = layer2.getSource().getFeatures();
        features.forEach(element => {
          this.mapService.removeLayerFeature(layer2, element.get('name'));
        });
      });

    this.direction1Data = [];
    this.direction2Data = [];
    this.direction1DataPath = [];
    this.direction2DataPath = [];
    this.selectedStreetDirection1Data = {};
    this.selectedStreetDirection2Data = {};
    this.isLoop = true;
    this.isEverySearchLock = false;
    this.directionProvider = '';
    this.routeType = 'motorway';
    this.direction1Count = 0;
    this.direction2Count = 0;
    this.direction1Distance = [];
    this.direction2Distance = [];

    let searchValue = this.searchForm.value.searchService;
    // mock search service
    setTimeout(() => {
      if (!this.allowedRouteList.includes(searchValue)) {
        this._snackBar.open(
          'Service line ' + searchValue + ' does not exist.',
          null,
          {
            duration: 2000,
          }
        );
        this.running = false;
        return;
      }
      this.resultServiceSearch = demoResultService[searchValue] ?? {};

      const data = this.resultServiceSearch;
      const currPolyline = this.mockMapService.routePolylines[searchValue];
      data.navigation.forEach(nav => {
        const { direction } = nav;
        const decodedPolyline = currPolyline.find(
          lineObj => lineObj.value === direction
        );
        nav.path = decodedPolyline.path;
      });
      let direction1Len = data.navigation[0].stops.length - 1;
      let startPoint = this.busIntTer.find(
        x => x.stopCode === data.navigation[0].stops[0]
      );
      let endPoint = this.busIntTer.find(
        x => x.stopCode === data.navigation[0].stops[direction1Len]
      );

      if (data.navigation.length === 1) {
        this.searchForm.controls['endPoint'].setValue(startPoint);
      } else {
        this.searchForm.controls['endPoint'].setValue(endPoint);
      }
      this.searchForm.controls['startPoint'].setValue(startPoint);

      data.navigation.forEach(element => {
        if (element.direction === 1) {
          this.direction1DataPath = element.path;
          this.direction1Distance = element?.distance ?? [];
        } else {
          this.direction2DataPath = element.path;
          this.direction2Distance = element?.distance ?? [];
        }

        let demoStopStreetLines: any = demoStreetLines.find(
          streetLine => streetLine.routeId === this.resultServiceSearch.routeId
        )?.stops;

        // console.log(
        //   'streetstop',
        //   this.resultServiceSearch,
        //   demoStopStreetLines
        // );

        // stopManagementService.getMultipleStops

        if (demoStopStreetLines) {
          demoStopStreetLines = JSON.parse(JSON.stringify(demoStopStreetLines));

          if (element.direction === 2) {
            demoStopStreetLines.reverse();
          }

          this.isServiceExist = true;
          this.isSearchStreet = false;
          let street: any = [];

          demoStopStreetLines.forEach((stop, index) => {
            stop.isChecked = true;
            let prev = demoStopStreetLines[index - 1];

            if (prev !== undefined && prev.streetName === stop.streetName) {
              street.slice(-1)[0].stops.push(stop);
            } else {
              let streetTest: any = {};
              streetTest.streetName = stop.streetName;
              streetTest.stops = [];
              streetTest.stops.push(stop);
              street.push(streetTest);
            }
            stop.sequence = index + 1;
          });
          if (element.direction === 1) {
            this.currDirection = 1;
            this.direction1Data = street;
            this.selectedStreetDirection1Data.stops = demoStopStreetLines;
            this.drawStPathMapManually(element, street); //temp remove
            this.selectedStreetDirection1PathData = element;
            this.direction1Count = element.stops.length;
          } else {
            this.direction2Data = street;
            this.selectedStreetDirection2Data.stops = demoStopStreetLines;
            this.isLoop = false;
            this.selectedStreetDirection2PathData = element;
            this.direction2Count = element.stops.length;
          }
          // this.mode = 'view'; // should default to view mode at first
          this.mode = 'edit';
        }
      });

      setTimeout(() => {
        this.running = false;
      }, 300);
    }, 200);
  }

  removeLayer(layerName) {
    this.map
      .getLayers()
      .getArray()
      .filter(layer => layer.get('name') === layerName)
      .forEach(layer => this.map.removeLayer(layer));
  }

  removePathLayers() {
    this.removeLayer('pathStreet-1');
    this.removeLayer('pathStreet-2');
    this.removeLayer('stopOtherDirection-1');
    this.removeLayer('stopOtherDirection-2');
  }

  drawStPathMapManually(selectedStops, stopData) {
    this.removePathLayers();
    const { direction } = selectedStops || {};
    if (!direction) {
      console.log('Manual Path - No path to draw');
      return;
    }

    var vectorLayer = new VectorImageLayer({
      source: new VectorSource(),
      className: 'pathStreet-' + direction,
      properties: {
        name: 'pathStreet-' + direction,
      },
    });
    this.map.addLayer(vectorLayer);

    if (stopData) {
      var listStops: any = [];
      stopData.forEach(element => {
        listStops.push(element.stops);
      });
      this.addMarkStopManually(listStops.flat(), selectedStops.direction);
    }

    if (selectedStops.path === null) {
      return false;
    }

    var stopsData = [];

    stopsData = selectedStops.path;

    var coordsArray = [];
    let arrPaths = [];
    stopsData.forEach((element: any) => {
      coordsArray.push([parseFloat(element[1]), parseFloat(element[0])]);
    });

    var routeGeom = new LineString(coordsArray).transform(
      'EPSG:4326',
      'EPSG:3857'
    );
    var routeFeature = new OlFeature({
      type: 'route',
      geometry: routeGeom,
    });

    var style = new Style({
      stroke: new Stroke({
        color: this.currDirection === 1 ? '#111e6c' : '#3C3105',
        width: 4,
      }),
    });
    routeFeature.setStyle(style);
    arrPaths.push(routeFeature);
    let featursList = [];

    for (const path of arrPaths) {
      featursList.push(path); //route
    }
    vectorLayer.getSource().addFeatures(featursList);

    var extent = vectorLayer.getSource().getExtent();
    this.map
      .getView()
      .fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
  }

  addMarkStopManually(selectedStops, direction) {
    // console.log('Add mark stop manually');
    var currLayerName = 'pathStreet-' + direction;
    var layerToAppend: VectorLayer<any> | VectorImageLayer<any> =
      new VectorImageLayer();
    var isExisLayer: boolean = false;

    this.map.getLayers().forEach(function (layer) {
      let layerName = layer.get('name');
      // console.log('layerName', layerName)
      if (
        layerName === currLayerName &&
        (layer instanceof VectorLayer || layer instanceof VectorImageLayer)
      ) {
        layerToAppend = layer;
        isExisLayer = true;
      } else if (isExisLayer) {
        isExisLayer = false;
      }
    });

    const source = layerToAppend.getSource();
    let featureCount = source?.getFeatures()?.length + 1 ?? 1;

    var firstStop = selectedStops.shift();
    var lastStop = selectedStops.pop();
    var featureList = [];

    if (firstStop) {
      firstStop.idx = 1;
      firstStop.imgSrc = 'assets/images/icon/map-marker-red.png';
      firstStop.zIndex = 2;
      firstStop.direction = direction;
      var firstFeature = this.iconMap(
        firstStop,
        featureCount + selectedStops.length + 2
      );
      featureList.push(firstFeature);
    }

    if (lastStop) {
      lastStop.idx = selectedStops.length + 2; //add first and last stop
      lastStop.imgSrc = 'assets/images/icon/map-marker-red.png';
      lastStop.zIndex = 2;
      lastStop.direction = direction;
      var lastFeature = this.iconMap(
        lastStop,
        featureCount + selectedStops.length + 1
      );
      featureList.push(lastFeature);
    }

    var idxCount: number = 0;
    selectedStops.forEach((stop, index) => {
      if (index === 0) {
        idxCount++;
      }
      stop.idx = idxCount += 1;
      stop.imgSrc = 'assets/images/icon/map-marker-green.png';
      stop.zIndex = 1;
      stop.direction = direction;
      featureCount += 1;
      let iconFeature = this.iconMap(stop, featureCount);

      featureList.push(iconFeature);
    });

    if (isExisLayer) {
      let source = layerToAppend.getSource();
      source.addFeatures(featureList);
    } else {
      var newLayerToAppend = new VectorImageLayer({
        source: new VectorSource({
          features: featureList,
        }),
        className: currLayerName,
        properties: {
          name: currLayerName,
        },
      });
      this.map.addLayer(newLayerToAppend);
    }
  }

  iconMap(data, zIndex = 0) {
    const { direction = 0 } = data || {};
    let iconFeature = new OlFeature({
      geometry: new Point(
        transform(
          [parseFloat(data.stopLon), parseFloat(data.stopLat)],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      name: 'stop-' + data.stopCode,
      dataObj: data,
    });

    const textData = data.idx.toString()?.trim();

    const markerSrc =
      direction === 1 || direction === 2
        ? `assets/images/icon/marker-location-dir-${direction}.svg`
        : 'assets/images/icon/marker-location-red.svg';

    const pinStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 1,
        scale: 0.9,
        src: markerSrc,
      }),
      zIndex: zIndex,
    });

    const textStyle = new Style({
      zIndex: zIndex,
      text: new Text({
        offsetY: -20,
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'bottom',
        text: textData,
        fill: new Fill({
          color: '#333',
        }),
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
      }),
    });
    iconFeature.setStyle([pinStyle, textStyle]);
    return iconFeature;
  }

  clickOption() {
    this.running = true;
    this.mode = 'edit';
  }

  selectAllowedRoute(event: MatAutocompleteSelectedEvent) {
    event.option.deselect();
    this.running = true;
    this.mode = 'edit';
    this.searchServiceRoute();
  }

  loopService(e) {}

  changeMode() {
    this.routeServiceDisplay =
      this.routeServiceDisplay === 'table' ? 'map' : 'table';
    this.isPanel = false; //close stop details panel

    setTimeout(() => {
      if (!this.isMapModeInit) {
        this.initMap();
      } else if (this.routeServiceDisplay === 'map') {
        this.map
          .getLayers()
          .getArray()
          .filter(
            layer =>
              layer.get('name') === 'pathStreet-1' ||
              layer.get('name') === 'pathStreet-2' ||
              layer.get('name') === 'stopOtherDirection-1' ||
              layer.get('name') === 'stopOtherDirection-2'
          )
          .forEach((layer2: VectorLayer<any> | VectorImageLayer<any>) => {
            // this.map.removeLayer(layer)
            let features = layer2.getSource().getFeatures();
            features.forEach(element => {
              this.mapService.removeLayerFeature(layer2, element.get('name'));
            });
          });

        this.map.setTarget(this.mapElementRef.nativeElement);

        let selectedStops = [];
        let otherDirection = [];
        if (this.currDirection === 1) {
          this.direction1Data.forEach(element => {
            element.stops.forEach(stop => {
              if (stop.isChecked) {
                selectedStops.push(stop);
              }
            });
          });
          this.direction2Data.forEach(element => {
            element.stops.forEach(stop => {
              if (stop.isChecked) {
                otherDirection.push(stop);
              }
            });
          });
        } else {
          this.direction2Data.forEach(element => {
            element.stops.forEach(stop => {
              if (stop.isChecked) {
                selectedStops.push(stop);
              }
            });
            // listStopCode.push(element.stops.map(a => a.stopCode));
          });
          this.direction1Data.forEach(element => {
            element.stops.forEach(stop => {
              if (stop.isChecked) {
                otherDirection.push(stop);
              }
            });
          });
        }

        var directionProviderMap =
          this.directionProvider === '' ? 'heremap' : this.directionProvider;
        this.onChangeDirectionProvider(this.directionProvider);

        this.initMapInteraction();
      } else if (this.routeServiceDisplay === 'table') {
        this.isExpand = false;
      }
    }, 100);
  }

  cancelNewSearch() {
    this.selectedStreetDirection1Data = {};
    this.selectedStreetDirection2Data = {};
    this.isSearchStreet = false;
    this.selectedStopCount = 0;
    this.isEverySearchLock = false;
    this.filteredOptions = undefined;
    this.searchForm.get('streetName').reset();
  }

  changeDirection(direction) {
    if (direction === this.currDirection || this.isLoop) {
      return false;
    }
    this.currDirection = direction;
    this.directionProvider = ''; // TODO: reset direction provider or not?

    this.cancelNewSearch();

    this.map
      .getLayers()
      .getArray()
      .filter(
        layer =>
          layer.get('name') === 'pathStreet-1' ||
          layer.get('name') === 'pathStreet-2' ||
          layer.get('name') === 'stopOtherDirection-1' ||
          layer.get('name') === 'stopOtherDirection-2'
      )
      .forEach((layer2: VectorImageLayer<any> | VectorLayer<any>) => {
        // this.map.removeLayer(layer)
        let features = layer2.getSource().getFeatures();
        features.forEach(element => {
          this.mapService.removeLayerFeature(layer2, element.get('name'));
        });
      });

    var startPoint = this.searchForm.value.startPoint;
    var endPoint = this.searchForm.value.endPoint;

    this.searchForm.controls['startPoint'].setValue(endPoint);
    this.searchForm.controls['endPoint'].setValue(startPoint);

    // this.directionProvider = 'heremap'; //default TODO replace here with current direction

    const currDirectionData = this.getSelectedFlatStops(
      this.getCurrentDirectionData().directionData
    );

    if (this.isModify && currDirectionData?.listStop?.length > 0) {
      this.onChangeDirectionProvider(this.directionProvider);
      // this.routeHereDirection(currDirectionData.listStop);
    }
  }

  initMapInteraction() {
    var me = this;
    this.map.on('pointermove', e => {
      if (e.dragging) {
        return;
      }
      var pixel = e.map.getEventPixel(e.originalEvent);
      var hit = false;
      var dataObj = undefined;

      e.map.forEachFeatureAtPixel(
        pixel,
        function (feature, layer) {
          let layerName = layer.get('name');
          let featureName = feature.get('name');
          if (
            layerName &&
            layerName.includes('pathStreet-' + me.currDirection)
          ) {
            hit = true;
            dataObj = feature.get('dataObj');
          }
        },
        {
          hitTolerance: 3,
        }
      );
      e.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    this.map.on('singleclick', evt => {
      let feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        },
        {
          hitTolerance: 3,
        }
      );
      if (feature) {
        let featureName = feature.get('name') ?? '';

        if (featureName.includes('stop')) {
          me.isPanel = true;
          me.panelData = feature.get('dataObj');
          // this.openBusStopEvent(me.panelData);
        }
      }
      evt.preventDefault();
    });
  }

  onChangeDirectionProvider(provider) {
    this.removePathLayers();

    if (provider === 'mapbox') {
      this.initMapbox();
    } else if (provider === 'heremap') {
      const currDirectionData = this.getSelectedFlatStops(
        this.getCurrentDirectionData().directionData
      );
      if (currDirectionData?.listStop?.length > 0) {
        this.routeHereDirection(currDirectionData.listStop);
      }
    } else if (this.currDirection === 1) {
      this.drawStPathMapManually(
        this.selectedStreetDirection1PathData,
        this.direction1Data
      ); //temp remove
    } else if (this.currDirection === 2) {
      this.drawStPathMapManually(
        this.selectedStreetDirection2PathData,
        this.direction2Data
      );
    } else {
      this.drawStPathMapManually(
        this.selectedStreetDirection1PathData,
        this.direction1Data
      );
    }
    this.directionProvider = provider;
  }

  initMapbox() {
    this.reRenderMap('motorway');
  }

  reRenderMap(exclude) {
    this.running = true;
    this.map.setTarget(this.mapElementRef.nativeElement);

    let selectedStops = [];
    let otherDirection = [];

    if (this.currDirection === 1) {
      this.direction1Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            selectedStops.push(stop);
          }
        });
      });
      this.direction2Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            otherDirection.push(stop);
          }
        });
      });
    } else {
      this.direction2Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            selectedStops.push(stop);
          }
        });
      });
      this.direction1Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            otherDirection.push(stop);
          }
        });
      });
    }

    if (this.directionProvider === 'heremap') {
      this.routeHereDirection(
        this.getSelectedFlatStops(this.getCurrentDirectionData().directionData)
          .listStop
      );
    } else {
      this.drawStPathMapBox(selectedStops, exclude);
    }
    this.initMapInteraction();
  }

  chunked(array, chunkSize) {
    var retArr = [];
    for (var i = 0; i < array.length - (chunkSize - 1); i++) {
      if (i !== 0) {
        i += 23;
      }
      retArr.push(array.slice(i, chunkSize + i));
    }
    return retArr;
  }

  chunkedByGroup(data) {
    var perChunk = 25; // items per chunk
    var retData = [];
    if (data.length < 25) {
      retData = data.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    } else {
      retData = this.chunked(data, perChunk);
    }
    return retData;
  }

  drawStPathMapBox(selectedStops, excludes: string = null) {
    this.mapService.removeLayerByLayerName(
      this.map,
      'pathStreet-' + this.currDirection
    );
    var result = this.chunkedByGroup(selectedStops);

    var stopsLonLat: any = [];
    var replaceStr = /undefined/gi;

    result.forEach((element, index) => {
      element.forEach(element2 => {
        stopsLonLat[index] += element2.stopLon + ',' + element2.stopLat + ';';
      });
      if (stopsLonLat[index]) {
        stopsLonLat[index] = stopsLonLat[index]
          .replace(replaceStr, '')
          .slice(0, -1);
      }
    });
    var delayTimer2 = stopsLonLat.length * 500;

    if (selectedStops.length <= 1) {
      return false;
    }

    this._snackBar.open('Calculating Route..');

    this.stopManagementService
      .getDirection2('driving', stopsLonLat, excludes)
      .then(
        (data: any) => {
          setTimeout(() => {
            var allCoords = [];
            var allCoordsOrig = [];
            const distance = [0];

            if (data[0].code === 'NoRoute') {
              this.running = false;
              // this._snackBar.dismiss();
              this._snackBar.open('Map render error.', null, {
                duration: 2000,
              });
              return false;
            }

            if (data[0]) {
              data.forEach((element: any) => {
                let coords = element.routes[0].geometry.coordinates;
                coords.forEach((element2: any) => {
                  allCoords.push([
                    parseFloat(element2[0]),
                    parseFloat(element2[1]),
                  ]);
                  allCoordsOrig.push([
                    parseFloat(element2[1]),
                    parseFloat(element2[0]),
                  ]);
                });

                const { routes } = element || {};

                if (routes[0]) {
                  const { legs } = routes[0];
                  legs.forEach(leg => {
                    distance.push(leg?.distance ?? undefined);
                  });
                }
              });
            }
            console.log('Mapbox distance: ', distance);
            let arrPaths = [];

            var routeGeom = new LineString(allCoords).transform(
              'EPSG:4326',
              'EPSG:3857'
            );
            var routeFeature = new OlFeature({
              type: 'route',
              geometry: routeGeom,
            });

            var style = new Style({
              stroke: new Stroke({
                color: this.currDirection === 1 ? '#111e6c' : '#3C3105',
                width: 4,
              }),
            });
            routeFeature.setStyle(style);
            arrPaths.push(routeFeature);
            let featursList = [];

            for (const path of arrPaths) {
              featursList.push(path); //route
            }

            let vectorLayer = new VectorImageLayer({
              source: new VectorSource({
                features: featursList,
              }),
              className: 'pathStreet-' + this.currDirection,
              properties: {
                name: 'pathStreet-' + this.currDirection,
              },
            });

            var isAllStopLayer = this.mapService.isLayerExist(
              this.map,
              'pathStreet-' + this.currDirection
            );
            if (isAllStopLayer) {
              let features = isAllStopLayer.getSource().getFeatures();
              features.forEach(element => {
                this.mapService.removeLayerFeature(
                  isAllStopLayer,
                  element.get('name')
                );
              });
            }

            this.map.addLayer(vectorLayer);

            if (this.isStopZoom) {
              this.isStopZoom = false;
            } else {
              var extent = vectorLayer.getSource().getExtent();
              this.map
                .getView()
                .fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
            }

            this.addMarkStopManually(selectedStops, this.currDirection);

            this.running = false;
            this._snackBar.dismiss();
            this._snackBar.open('Map rendered.', null, {
              duration: 2000,
            });

            if (this.currDirection === 1) {
              this.direction1DataPath = allCoordsOrig;
              this.direction1Distance = [...distance];
            } else {
              this.direction2DataPath = allCoordsOrig;
              this.direction2Distance = [...distance];
            }
          }, delayTimer2);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  getCurrentDirectionData() {
    var directionData: any;
    var directionPath: any;
    if (this.currDirection === 1) {
      directionData = this.direction1Data;
      directionPath = this.direction1DataPath;
    } else {
      directionData = this.direction2Data;
      directionPath = this.direction2DataPath;
    }
    return {
      directionData: directionData,
      directionPath: directionPath,
    };
  }

  getSelectedFlatStops(directionData) {
    var listStopCode: any = [];
    var listStopChecked: any = [];
    directionData.forEach(element => {
      element.stops.forEach(stop => {
        if (stop.isChecked) {
          listStopCode.push(stop.stopCode);
          listStopChecked.push(stop);
        }
      });
    });
    return {
      listStopCode: listStopCode,
      listStop: listStopChecked,
    };
  }

  routeHereDirection(selectedStops) {
    const platform = new H.service.Platform({
      apikey: environment.hereApiKey,
    });
    const routerHere = platform.getRoutingService(null, 8);
    const firstStop = JSON.parse(JSON.stringify(selectedStops[0]));
    const lastStop = JSON.parse(
      JSON.stringify(selectedStops[selectedStops.length - 1])
    );
    const waypoints = [];
    const selectedStopsOrig = JSON.parse(JSON.stringify(selectedStops));
    selectedStops.shift(); //remove first array
    selectedStops.pop(); //remove last array

    selectedStops.forEach(element => {
      if (element.nameHint) {
        waypoints.push(
          element.stopLat +
            ',' +
            element.stopLon +
            ';nameHint=' +
            element.nameHint
        );
      } else {
        waypoints.push(element.stopLat + ',' + element.stopLon);
      }
    });
    const waypointsParsed = {
      transportMode: 'bus',
      departureTime: 'any',
      return: 'polyline,summary',
      routingMode: 'fast',
      origin: firstStop.stopLat + ',' + firstStop.stopLon,
      destination: lastStop.stopLat + ',' + lastStop.stopLon,
      via: new H.service.Url.MultiValueQueryParameter(waypoints),
    };

    routerHere.calculateRoute(
      waypointsParsed,
      data => {
        if (data.routes[0] !== undefined) {
          let sections = data.routes[0].sections;
          let latlon = {
            path: [],
            direction: 1,
          };
          const distances = [];
          sections.forEach(element => {
            const { summary } = element || {};
            const { length } = summary;
            distances.push(length);
            // latlon.path.push([element.arrival.place.location.lat, element.arrival.place.location.lng]);
            decode(element.polyline).polyline.forEach(polyline => {
              latlon.path.push(polyline);
            });
            // console.log(decode(element.polyline));
          });

          //remove layer
          this.removeLayer('pathStreet-1');
          this.removeLayer('pathStreet-2');
          this.removeLayer('stopOtherDirection-1');
          this.removeLayer('stopOtherDirection-2');

          // console.log(latlon);
          this.drawStPathMapManually(latlon, null);
          this.addMarkStopManually(selectedStopsOrig, this.currDirection);

          if (distances?.length > 1) {
            distances.unshift(0);
          } else if (distances?.length > 0 && distances[0] !== 0) {
            distances.unshift(0);
          }

          if (this.currDirection === 1) {
            this.direction1DataPath = latlon.path;
            this.direction1Distance = [...distances];
          } else {
            this.direction2DataPath = latlon.path;
            this.direction2Distance = [...distances];
          }
        } else {
          this._snackBar.open(data.notices[0].title, null, {
            duration: 2000,
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSearchLocation(stop, direction: number) {
    this.isStopZoom = true;
    this.changeMode();
    this.changeDirection(direction);

    this.viewAnimate.animate({
      center: fromLonLat([stop.stopLon, stop.stopLat]),
      duration: 1000,
      zoom: 15,
    });

    this.isPanel = true;
    this.panelData = stop;
    // this.openBusStopEvent(this.panelData);
  }

  closePanel() {
    this.isPanel = false;
  }

  onExpand() {
    this.isExpand = !this.isExpand;
    var me = this;
    setTimeout(function () {
      me.map.updateSize();
    }, 200);
  }

  initMap() {
    this.OSMLayer = new OlTileLayer({
      source: new OSM(),
      className: 'OSMLayer',
      properties: {
        name: 'OSMLayer',
      },
    });

    const scaleLine = new ScaleLine({
      units: 'metric',
    });

    this.map = new OlMap({
      controls: defaultOlControls().extend([scaleLine]),
      target: 'map-mode',
      view: this.viewAnimate,
    });

    this.map.addLayer(this.OSMLayer);

    setTimeout(() => {
      this.map.updateSize();
    }, 200);
    this.isMapModeInit = true;
  }

  /**
   * Service ID Autocomplete
   */
  @ViewChild('serviceAutocompleteTrigger')
  routeAutoCompleteTrigger: MatAutocompleteTrigger;
  allowedRouteList = ['7001', '7002', '7003'];
  filteredServiceList: Observable<string[]>;
  initRouteAutocomplete() {
    this.filteredServiceList =
      this.searchForm.controls.searchService.valueChanges.pipe(
        startWith(''),
        map(value => this.filterRouteOptions(value || ''))
      );
  }
  private filterRouteOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allowedRouteList.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  distanceDisplay(distance) {
    if (isNaN(distance)) return '';
    if (distance >= 1000) {
      return parseFloat((distance / 1000).toFixed(3)) + ' km';
    } else if (distance > 0) {
      return distance + ' m';
    }
    return '';
  }

  onReset() {
    this.reset();
  }

  reset() {
    this.searchForm.reset();
    this.cancelNewSearch();

    this.direction1Data = [];
    this.direction1DataPath = [];
    this.direction1Distance = [];
    this.selectedStreetDirection1PathData = {};
    this.direction1Count = 0;

    this.direction2Data = [];
    this.direction2Distance = [];
    this.direction2DataPath = [];
    this.selectedStreetDirection2PathData = {};
    this.direction2Count = 0;

    this.isServiceExist = false;

    this.isLoop = true;
    this.resultServiceSearch = {};

    this.directionProvider = '';

    this.mode = 'reset';
    this.isExpand = false;

    this.map
      .getLayers()
      .getArray()
      .filter(
        layer =>
          layer.get('name') === 'pathStreet-1' ||
          layer.get('name') === 'pathStreet-2' ||
          layer.get('name') === 'stopOtherDirection-1' ||
          layer.get('name') === 'stopOtherDirection-2'
      )
      .forEach(layer => this.map.removeLayer(layer));

    this.viewAnimate.animate({
      center: fromLonLat(environment.map.locationLonLat),
      duration: 1000,
      zoom: 12,
    });
  }
}
