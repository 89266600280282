import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ColorData } from '../others/constant';
import { VectorImage as VectorImageLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { transform } from 'ol/proj.js';
import OlFeature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style.js';

@Injectable({
  providedIn: 'root',
})
export class MqttService {
  topic;
  obsBusMqtt: BehaviorSubject<any>;
  mapInstance: any;
  busLadder: any;
  route: any;
  private bus: Array<any>;

  private mqttSource = new Subject<any>();
  public mqtt$ = this.mqttSource.asObservable();

  constructor() {
    this.obsBusMqtt = new BehaviorSubject<any>(this.bus);
  }

  mqttConnect(mapInstanceData, route, busLadderData, message) {
    this.mapInstance = mapInstanceData;
    this.busLadder = busLadderData;
    this.route = route;

    // console.log(this.mapInstance);
    // console.log(this.busLadder);
    // console.log(this.route);

    // Create a client instance

    // // set callback handlers
    // this.client.onConnectionLost = this.onConnectionLost.bind(this);
    // this.client.onMessageArrived = this.onMessageArrived.bind(this);
    // return this.client;
    // // connect the client
    // setTimeout(() => {
    //   this.client.connect({onSuccess:this.onConnect.bind(this)});

    // setInterval(function(){
    // console.log('test2');
    // this.client.connect({onSuccess:this.onConnect.bind(this), useSSL:true});

    // }, 1000);
    this.onMessageArrived(message);
    // }, 500);
  }

  // called when the client connects
  onConnect() {
    // Once a connection has been made, make a subscription and send a message.
    console.log('onConnect');
    // console.log(this.client);

    // this.client.subscribe(this.topic);

    // message.destinationName = this.topic; //publish topic
    // this.client.send(message); //publish message
  }

  // called when the client loses its connection
  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log('onConnectionLost:' + responseObject.errorMessage);
    }
  }

  // mqttMessage() {
  //   console.log('test message');
  // }
  // called when a message arrives
  onMessageArrived(message: any) {
    // console.log('mqtt: ', message);
    // // this.obsBusMqtt.next(msg.payloadString);

    const currClass = this;
    let busNats = this.obsBusMqtt;
    // console.log("onMessageArrived:"+msg.payloadString);
    // console.log(busNats);

    let service = this.route.service;
    const dataBus = message; //JSON.parse(message.payloadString);
    const busCode = 'bus-' + dataBus.busCode;
    const layerName = 'VectorLayerMqtt-' + service;
    let currLayer: VectorImageLayer<any>;

    // console.log(this.route);

    let newBusLoc = currClass.addBus(dataBus);
    let layers = this.mapInstance.getLayers().getArray().slice();
    for (let row of layers) {
      let rowName = row.values_.name;
      if (rowName === layerName) {
        currLayer = row;
      }
    }

    // console.log(currLayer);
    // console.log(dataBus);
    if (currLayer) {
      var features = currLayer.getSource().getFeatures();

      // let index = 0;

      for (let feature of features) {
        let featureName = feature.values_.name;
        // console.log('featureName ' + featureName + ', layerName' + layerName)
        //   // currLayer.getSource().removeFeature(feature);
        if (featureName === busCode) {
          //     // console.log(8);
          currLayer.getSource().removeFeature(feature);
          //     // let newBusLoc = currClass.addBus(dataBus);
          // currLayer.getSource().addFeature(newBusLoc);
        } else {
          //if(index === 1) {
          //     // console.log('9 ' + index);
          // currLayer.getSource().addFeature(newBusLoc);
        }
        //   index++;
      }
      // currLayer.getSource().clear();
      currLayer.getSource().addFeature(newBusLoc);
    } else {
      // console.log(7)
      // console.log('create new layer with feature ' + service);
      let featursList = [];

      // let newBusLoc = currClass.addBus(dataBus);
      featursList.push(newBusLoc); //start service

      // should be declared as the same type as currLayer
      let vectorLayer = new VectorImageLayer({
        source: new VectorSource({
          features: featursList,
          useSpatialIndex: false,
        }),
        renderBuffer: 2,
        className: layerName,
        properties: {
          name: layerName,
        },
      });

      vectorLayer.setZIndex(2);
      vectorLayer.setVisible(true);

      this.mapInstance.addLayer(vectorLayer);
    }

    busNats.next(dataBus);
    this.mqttSource.next(dataBus);

    // let layers1 = this.mapInstance.getLayers().getArray().slice();
    // console.log(layers1);
  }

  addBus(dataBus) {
    let busColor = '';
    var bus2Style = new Style({});
    let caret = '';
    var textColor = '#fff';

    if (dataBus.status === '0') {
      // on-time
      busColor = ColorData.eGreen;
      // caret = 'caret-green-32.png';
      caret = 'location-green-32.png';
    } else if (dataBus.status === '-1') {
      // early
      busColor = ColorData.eYellow;
      // caret = 'caret-yellow-32.png';
      caret = 'location-yellow-32.png';
      textColor = '#000';
    } else if (dataBus.status === '1') {
      // late
      busColor = ColorData.eRed;
      // caret = 'caret-red-32.png';
      caret = 'location-red-32.png';
    } else if (dataBus.status === '2') {
      // not on trip
      busColor = ColorData.eGray;
      // caret = 'caret-red-32.png';
      caret = 'location-gray-32.png';
    } else if (dataBus.status === '3') {
      // ?
      busColor = ColorData.eOrange;
      caret = 'location-orange-32.png';
    }
    //start for demo
    // let status;
    // if(dataBus.busCode === 'SBS111') {
    //   status = ColorData.eGreen;
    // }
    // else if(dataBus.busCode === 'SBS222') {
    //   status = ColorData.eYellow;
    // }
    // else {
    //   status = ColorData.eRed;
    // }
    //end for demo

    var bus1 = new OlFeature({
      type: 'icon',
      geometry: new Point(
        transform(
          [parseFloat(dataBus.lon), parseFloat(dataBus.lat)],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      name: 'bus-' + dataBus.busCode,
      description: dataBus,
    });

    var bus1Style = new Style({
      //draw circle
      // image: new CircleStyle({
      //   radius: 6,
      //   stroke: new Stroke({
      //     color: '#c3c3c3',
      //     width: 2,
      //     padding: 2
      //   }),
      //   fill: new Fill({
      //     color: busColor
      //   })
      // }),

      //draw triangle
      // image: new RegularShape({
      //   fill: new Fill({
      //     color: busColor
      //   }),
      //   // stroke: stroke,
      //   points: 3,
      //   radius: 50,
      //   // rotation: 0,//Math.PI / 4,
      //   angle: 10
      // }),
      //icon triangle
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        // opacity: 1,
        src: 'assets/images/icon/' + caret,
        scale: 0.5,
        rotation: (parseFloat(dataBus.degrees) * Math.PI) / 180,
        // rotation: 60 * 0.01745329251,  // in rad / 360° = 6.28318531 rad = 2PI rad
        rotateWithView: true,
      }),

      text: new Text({
        offsetY: -24,
        text: dataBus.busCode,
        fill: new Fill({
          color: textColor,
        }),
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'middle',
        // fill: new Fill({
        //   color: '#c3c3c3'
        // }),
        // backgroundFill: new Fill({
        //   color: '#2a2b30'
        // }),
        padding: [5, 5, 5, 5],
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
        // stroke: new Stroke({
        //   color: busColor, //'#000',
        //   width: 20,
        // }),
      }),
    });

    // rounded background
    const squareText = '\u25A0'.repeat(dataBus.busCode.length);
    const squareBgTextStyle = new Style({
      text: new Text({
        text: squareText,
        offsetY: -26,
        fill: new Fill({
          color: busColor,
        }),
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'middle',
        padding: [5, 5, 5, 5],
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
        stroke: new Stroke({
          color: busColor,
          width: 21,
        }),
      }),
    });

    if (dataBus.isSelected) {
      bus2Style = new Style({
        image: new Icon({
          anchor: [0.5, 1.6],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          // opacity: 1,
          src: 'assets/images/icon/map-marker-orange.png',
        }),
        zIndex: 2,
      });
    }

    // bus1.setStyle([bus1Style, bus2Style]);
    bus1.setStyle([squareBgTextStyle, bus1Style]);
    // bus1.getStyle().getImage().setRotation(135 * (Math.PI / 180));

    // //console.log(bus1);
    return bus1;
  }

  removeBus(dataBus) {
    let service = this.route.service;
    const busCode = 'bus-' + dataBus.busCode;
    const layerName = 'VectorLayerMqtt-' + service;
    let currLayer: VectorImageLayer<any>;

    let layers = this.mapInstance.getLayers().getArray().slice();
    for (let row of layers) {
      let rowName = row.values_.name;
      if (rowName === layerName) {
        currLayer = row;
      }
    }

    if (currLayer) {
      var features = currLayer.getSource().getFeatures();

      for (let feature of features) {
        let featureName = feature.values_.name;
        if (featureName === busCode) {
          currLayer.getSource().removeFeature(feature);
        }
      }
    }
  }
}
