<div class="loader-group" *ngIf="!isGetAllRoutes">
  <p-progressSpinner></p-progressSpinner>
</div>

<div class="trip-group">
  <perfect-scrollbar class="ps-accRoutes" [config]="config">
    <div class="accordion" id="accRoutes" *ngIf="isTrip">
      <div class="card" *ngFor="let row of objectKeys(trips); index as i">
        <div class="card-header" 
        (click)="initMap(trips[row], row)" id=acc-{{row}} data-toggle="collapse" [attr.data-target]="'#col-' + row" aria-expanded="true" aria-controls="collapseOne">
          <h2 class="mb-0">
            <button class="btn btn-link" type="button"
            >
              {{row}}
            </button>
          </h2>
        </div>

        <div id=col-{{row}} class="collapse" aria-labelledby="headingOne" data-parent="#accRoutes"
        [ngClass]="{
          'show': i === 0
        }">
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-6">
                <div class="text-right">
                  <button type="button" class="btn btn-outline-secondary" (click)="onAddTrip()">Add Trip</button>
                </div>
                <ng-container *ngFor="let direction of objectKeys(trips[row])">
                  <!-- {{direction}} -->
                  <div class="form-row route-direction-group">
                    <div class="col-8">
                      <ng-container *ngFor="let trip of trips[row][direction]; first as isFirst; last as isLast">
                        <span *ngIf="isFirst">{{trip.busStopName}}</span>
                        <!-- <div *ngIf="isLast" class="double-arrow"></div> -->
                        <span *ngIf="isLast"> - {{trip.busStopName}}</span>
                        <!-- {{trip.busStopName}} -->
                      </ng-container>
                    </div>
                    <div class="col text-right">
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-outline-secondary">Delete</button>
                        <button type="button" class="btn btn-outline-secondary" (click)="onEditTrip(trips[row][direction])">Edit</button>
                        <!-- <button type="button" class="btn btn-secondary">Right</button> -->
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="form-group col-6">
                <div id="map-trip-{{row}}" class="map-trip"><div class="popo" id="popup-{{row}}"></div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
  <!-- <p-paginator [rows]="5" totalRecords="100" (onPageChange)="onPaginate($event)"></p-paginator> -->
  <!-- <p-paginator [rows]="10" [totalRecords]="120" [rowsPerPageOptions]="[10,20,30]"></p-paginator> -->
</div>
<!-- <p-dialog header="{{headerModal}}" [(visible)]="isDisplayModal" [responsive]="true" showEffect="fade" [modal]="true"  [style]="{width: 'auto'}">
  <app-add-edit-trip *ngIf="isDisplayModal" [data]="trip"></app-add-edit-trip>
</p-dialog> -->