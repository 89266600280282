import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
// import { InitDemo } from '../../../../assets/js/lib/siptest_2.js';
// import * as Janus from '../../../../assets/js/lib/janus_2';

import { LogsData } from 'src/app/component/shared/others/data-types';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare const Janus: any;
declare const InitDemo: any;
declare const registerSip: any;
declare const doCall: any;
declare const doHangup: any;
declare const doAnswer: any;
declare const doDecline: any;
declare const getIncomingData: any;
@Injectable({
  providedIn: 'root'
})
export class VoipService {
  // server = 'https://ec2-18-136-242-141.ap-southeast-1.compute.amazonaws.com/janus'; // localhost
  server = 'https://10.12.0.105/janus';
  // server = 'https://ngbms-sg.com:8080/voip/janus/';

  opaqueId = 'siptest-' + Janus.randomString(12);
  sipcall = null;

  constructor(
    // private commonService: CommonService,
    private httpClient: HttpClient,
  ) { }
  initVoip() {
    // console.log(localStorage.getItem('sipNumber'));
    let host = '@10.12.0.101';
    let authuser = localStorage.getItem('sipNumber');
    let password = '123456';
    let sipIdentity = 'sip:' + authuser + host;
    let sipData = {
      request   : 'register',
      username  : sipIdentity, 
      force_udp : true,
      secret  : password
    };
    // InitDemo(sipData);

    // setTimeout(() => {
    //  registerSip(sipData);
    // }, 3000);
  }

  doCall(sipNumber) {
    doCall(sipNumber);
  }

  doHangup() {
    doHangup();
  }

  doAnswer() {
    doAnswer();
  }

  doDecline() {
    doDecline();
  }
  
  getIncomingData() {
    getIncomingData();
  }
  
  
  logVoip(data:LogsData) {
    var url = environment.apiServerUrl + 'operatormessage/v1/voipLog';

    // return this.httpClient.post(url, data).pipe(
    //   map((data: any) => {
    //     return data;
    //   })
    // );
    
  //   var bodyOption = {
  //     "busRegNo": data.busCode,
  //     // "currLat": data.lat,
  //     // "currLon": data.lon,
  //     // "nextBusStopCode": data.nextStopId,
  //     "tripId": data.trip
  // }
  const promise = new Promise(
      (resolve, reject) => {
          // this.httpClient.post(environment.apiServerUrl + 'gtfsupload/etaEstimate', bodyOption)
          this.httpClient.post(url, data)
          .subscribe(
              data => {
                  resolve(data);
              },
              err => {
                  resolve(undefined);
              },
              () => console.log('log voip done')
          );
      }
    );
    return promise;
  }

  logOffSipNumber(data) {
    let url = environment.apiServerUrl + 'operatormessage/v1/logOffSip';
    return this.httpClient.post(url, data).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getAvailableSipNumber() {
    let url = environment.apiServerUrl + 'operatormessage/v1/getAvailableSipId';
    return this.httpClient.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getVehicleSipNumber(vehicleId) {
    let url = environment.apiServerUrl + 'operatormessage/v1/getVehicleSipId/'+vehicleId;
    return this.httpClient.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
