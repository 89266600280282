<div class="upload-file-group">
  <h2 mat-dialog-title>Upload File</h2>

  <mat-dialog-content [formGroup]="form">
    <div class="row no-margin">
      <mat-form-field floatLabel="always">
        <mat-label class="form-font">From Date</mat-label>

        
        <input matInput [matDatepicker]="effectiveFromDate" placeholder="Date" formControlName="effectiveFromDate">
        <mat-datepicker-toggle matSuffix [for]="effectiveFromDate"></mat-datepicker-toggle>
        <mat-datepicker #effectiveFromDate></mat-datepicker>

        <mat-error *ngIf="form.get('effectiveFromDate').invalid">Invalid from date</mat-error>
      </mat-form-field>
      
      <mat-form-field floatLabel="always">
        <mat-label class="form-font">Until Date</mat-label>
        <!-- <input matInput placeholder="Date" formControlName="effectiveTillDate" required> -->
        
        <input matInput [matDatepicker]="effectiveTillDate" placeholder="Date" formControlName="effectiveTillDate">
        <mat-datepicker-toggle matSuffix [for]="effectiveTillDate"></mat-datepicker-toggle>
        <mat-datepicker #effectiveTillDate></mat-datepicker>

        <mat-error *ngIf="form.get('effectiveTillDate').invalid">Invalid until date</mat-error>
      </mat-form-field>
    </div>
    <div class="row no-margin">
      <mat-checkbox (change)="isGTFS = !isGTFS">Is GTFS file?</mat-checkbox>
    </div>

    <div *ngIf="!running">
      <div class="row no-margin">
        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
          <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
          <div class="drag-drop">
            <div class="centered">DRAG & DROP</div>
            <!-- <div class="centered-sub">Excel format is suggested.</div> -->
          </div>
        </div>
        <mat-error *ngIf="files === null && running">No file existing</mat-error>
      </div>

      <div class="row no-margin">
        <div class="files-list" *ngIf="files">
          <span> {{ files.name }} </span>
          <i class="material-icons" (click)="deleteAttachment(0)">
            delete_forever
          </i>
        </div>
        <!-- <div class="files-list" *ngFor="let file of files;let i= index">
          <span> {{ file.name }} </span>
          <i class="material-icons" (click)="deleteAttachment(i)">
            delete_forever
          </i>
        </div> -->
      </div>
    </div>

    <div class="row no-margin mar-top-bot-2vh" *ngIf="running">
      <mat-spinner diameter="50" class="margin-auto"></mat-spinner>
    </div>

    <div class="row no-margin" *ngIf="isUploaded">
      {{uploadedMessage}}
    </div>

  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="btnUploadFile()" 
        [disabled]="running || !form.valid">Upload</button>
  </mat-dialog-actions>
</div>