<div class="side-menu-wrapper">
  <div class="account-header">
    <mat-list role="list">
      <!-- <a mat-list-item role="listitem" matRipple [routerLink]="['home']"><div class="logo" [style]="getBackgroundImg(wordLogo)"></div></mat-list-item> -->
      <a
        mat-list-item
        *ngIf="menuConfig.home.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/home']"
        ><mat-icon matListIcon>home</mat-icon>Home</a
      >

      <mat-expansion-panel
        [expanded]="menuService.panelState['accountManagement']"
        (opened)="clickPanel('accountManagement', true)"
        (closed)="clickPanel('accountManagement', false)"
        *ngIf="
          menuConfig.accountManagement.userManagement.isVisible ||
          menuConfig.accountManagement.roleManagement.isVisible ||
          menuConfig.accountManagement.responsibility.isVisible
        ">
        <mat-expansion-panel-header
          matRipple
          collapsedHeight="48px"
          expandedHeight="48px">
          <mat-panel-title>
            <mat-icon matListIcon>person</mat-icon>Account Management
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container
          *ngFor="let navItem of menuConfig.accountManagement | keyvalue">
          <a
            mat-menu-item
            [routerLink]="navItem?.value?.route"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngIf="!!navItem?.value?.isVisible">
            {{ navItem?.value?.title }}
          </a>
        </ng-container>
      </mat-expansion-panel>

      <a
        mat-list-item
        *ngIf="menuConfig.schedule.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/schedule', 1]"
        ><mat-icon matListIcon>schedule</mat-icon>Schedule</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.kpi.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/kpi', 1]"
        ><mat-icon matListIcon>bar_chart</mat-icon>KPI Stats</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.event.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/events', 1]"
        ><mat-icon matListIcon>events</mat-icon>Events</a
      >

      <!-- <a mat-list-item role="listitem" matRipple [matMenuTriggerFor]="personnelManagement"><mat-icon matListIcon>person_add</mat-icon>Personnel Management</mat-list-item> -->
      <mat-expansion-panel
        *ngIf="
          menuConfig.personnelManagement.drivers.isVisible ||
          menuConfig.personnelManagement.controllers.isVisible
        ">
        <mat-expansion-panel-header matRipple>
          <mat-panel-title>
            <mat-icon matListIcon>person_add</mat-icon>Driver Management
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button
          mat-menu-item
          matRipple
          *ngIf="menuConfig.personnelManagement.drivers.isVisible"
          routerLink="/account/personnel-management/driver/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Drivers
        </button>
        <button
          mat-menu-item
          matRipple
          *ngIf="menuConfig.personnelManagement.controllers.isVisible"
          routerLink="/account/personnel-management/controller/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Controllers
        </button>
      </mat-expansion-panel>
      <!-- <a mat-list-item role="listitem" matRipple [matMenuTriggerFor]="vehicleManagement"><mat-icon matListIcon>directions_bus</mat-icon>Vehicle Management</mat-list-item> -->

      <mat-expansion-panel
        [expanded]="menuService.panelState['vehicleManagement']"
        (opened)="clickPanel('vehicleManagement', true)"
        (closed)="clickPanel('vehicleManagement', false)"
        *ngIf="
          menuConfig.vehicleManagement.vehicles.isVisible ||
          menuConfig.vehicleManagement.devices.isVisible ||
          menuConfig.vehicleManagement.vehicleList.isVisible ||
          menuConfig.vehicleManagement.deviceMonitor.isVisible
        ">
        <mat-expansion-panel-header
          matRipple
          collapsedHeight="48px"
          expandedHeight="48px">
          <mat-panel-title>
            <mat-icon matListIcon>directions_bus</mat-icon>Vehicle Management
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container
          *ngFor="let navItem of menuConfig.vehicleManagement | keyvalue">
          <a
            mat-menu-item
            [routerLink]="navItem?.value?.route"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngIf="!!navItem?.value?.isVisible">
            {{ navItem?.value?.title }}
          </a>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="
          menuConfig.masterdata.vehicleList.isVisible ||
          menuConfig.masterdata.driverList.isVisible ||
          menuConfig.masterdata.organization.isVisible
        ">
        <mat-expansion-panel-header matRipple>
          <mat-panel-title>
            <mat-icon matListIcon>folder_open</mat-icon>Master Data
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button
          mat-menu-item
          *ngIf="menuConfig.masterdata.vehicleList.isVisible"
          routerLink="/account/master-data/vehicles/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Vehicles List
        </button>
        <button
          mat-menu-item
          *ngIf="menuConfig.masterdata.driverList.isVisible"
          routerLink="/account/master-data/drivers/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Drivers List
        </button>
        <button
          mat-menu-item
          *ngIf="menuConfig.masterdata.organization.isVisible"
          routerLink="/account/master-data/organization/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Organizational Data
        </button>
      </mat-expansion-panel>

      <mat-expansion-panel
        [expanded]="menuService.panelState['systemConfiguration']"
        (opened)="clickPanel('systemConfiguration', true)"
        (closed)="clickPanel('systemConfiguration', false)"
        *ngIf="
          menuConfig.systemConfiguration.stopManagement.isVisible ||
          menuConfig.systemConfiguration.serviceManagement.isVisible ||
          menuConfig.systemConfiguration.scheduleManagement.isVisible
        ">
        <mat-expansion-panel-header
          matRipple
          collapsedHeight="48px"
          expandedHeight="48px">
          <mat-panel-title>
            <mat-icon matListIcon>settings_applications</mat-icon>Master Data
            Configuration
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container
          *ngFor="let navItem of menuConfig.systemConfiguration | keyvalue">
          <a
            mat-menu-item
            [routerLink]="navItem?.value?.route"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngIf="!!navItem?.value?.isVisible">
            {{ navItem?.value?.title }}
          </a>
        </ng-container>
      </mat-expansion-panel>

      <a
        mat-list-item
        *ngIf="menuConfig.dispatcher.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/dispatcher', 1]"
        ><mat-icon matListIcon>departure_board</mat-icon>Dispatcher</a
      >

      <mat-expansion-panel
        *ngIf="
          menuConfig.routeManagement.route.isVisible ||
          menuConfig.routeManagement.segment.isVisible
        ">
        <mat-expansion-panel-header matRipple>
          <mat-panel-title>
            <mat-icon matListIcon>where_to_vote</mat-icon>Route Management
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button
          mat-menu-item
          matRipple
          *ngIf="menuConfig.routeManagement.route.isVisible"
          routerLink="/account/route-management/route/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Route
        </button>
        <button
          mat-menu-item
          matRipple
          *ngIf="menuConfig.routeManagement.segment.isVisible"
          routerLink="/account/route-management/segment/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Segment
        </button>
      </mat-expansion-panel>

      <a
        mat-list-item
        *ngIf="menuConfig.timeTable.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/time-table', 1]"
        ><mat-icon matListIcon>table_chart</mat-icon>Time Table</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.multiLadder.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/multi-ladder', 1]"
        ><mat-icon matListIcon>view_agenda</mat-icon>Multiple Ladder</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.playback.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/playback', 1]"
        ><mat-icon matListIcon>play_arrow</mat-icon>Playback</a
      >
      <!-- <a mat-list-item *ngIf="menuConfig.userManagement.isVisible" role="listitem" matRipple [routerLinkActive]="['active']" [routerLink]="['/account/user-management', 1]"><mat-icon matListIcon>supervisor_account</mat-icon>User Management</mat-list-item> -->
      <a
        mat-list-item
        *ngIf="menuConfig.incidentManagement.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/incident-management', 1]"
        ><mat-icon matListIcon>error_outline</mat-icon>Incident Management</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.trafficCongestion.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/traffic-events', 1]"
        ><mat-icon matListIcon>traffic</mat-icon>Traffic Congestion</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.messages.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/messages', 1]"
        ><mat-icon matListIcon>message</mat-icon>Messages</a
      >

      <a
        mat-list-item
        *ngIf="menuConfig.alarmManagement.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/alarm', 1]"
        >Alarm Management</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.dispatch.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/dispatch-assign', 1]"
        >Dispatch</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.driverBehaviour.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        >Driver Behavior</a
      >

      <mat-expansion-panel
        [expanded]="menuService.panelState['driverManagement']"
        (opened)="clickPanel('driverManagement', true)"
        (closed)="clickPanel('driverManagement', false)"
        *ngIf="
          menuConfig.driverManagement.driverList.isVisible ||
          menuConfig.driverManagement.dutyRoster.isVisible
        ">
        <mat-expansion-panel-header
          matRipple
          collapsedHeight="48px"
          expandedHeight="48px">
          <mat-panel-title>
            <mat-icon matListIcon>person</mat-icon>Driver Management
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container
          *ngFor="let navItem of menuConfig.driverManagement | keyvalue">
          <a
            mat-menu-item
            [routerLink]="navItem?.value?.route"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngIf="!!navItem?.value?.isVisible">
            {{ navItem?.value?.title }}
          </a>
        </ng-container>
      </mat-expansion-panel>

      <!-- <a mat-list-item
        *ngIf="menuConfig.dutyRoster.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/duty-rostering', 1]"
        ><mat-icon matListIcon>date_range</mat-icon>Driver Duty
        Roster</a> -->
      <a
        mat-list-item
        *ngIf="menuConfig.fleetHealth.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        >Fleet Health Monitoring</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.fleetMaintenance.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        >Fleet Maintenance</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.fuelConsumption.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        >Fuel Consumption Control</a
      >
      <a
        mat-list-item
        *ngIf="menuConfig.managementDashboard.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        >Management Dashboard</a
      >
      <!-- <a mat-list-item *ngIf="menuConfig.home.isVisible" role="listitem" matRipple [routerLinkActive]="['active']">Reports & Data Analysis</mat-list-item> -->

      <mat-expansion-panel
        *ngIf="
          menuConfig.reports.schedule.isVisible ||
          menuConfig.reports.interval.isVisible ||
          menuConfig.reports.tripCauseCode.isVisible
        ">
        <mat-expansion-panel-header matRipple>
          <mat-panel-title>
            <mat-icon matListIcon>description</mat-icon>Reports & Data Analysis
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button
          mat-menu-item
          *ngIf="menuConfig.reports.schedule.isVisible"
          routerLink="/account/reports/schedule-deviation/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Bus Arrival Time
        </button>
        <button
          mat-menu-item
          *ngIf="menuConfig.reports.interval.isVisible"
          routerLink="/account/reports/interval/1"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Actual Travel Time
        </button>
        <button
          mat-menu-item
          *ngIf="menuConfig.reports.tripCauseCode.isVisible"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Trip Cause Code Analysis
        </button>
      </mat-expansion-panel>

      <a
        mat-list-item
        *ngIf="menuConfig.busScheduleDataManagement.isVisible"
        role="listitem"
        matRipple
        [routerLinkActive]="['active']"
        [routerLink]="['/account/schedule-data-management', 1]"
        ><mat-icon matListIcon>av_timer</mat-icon>Bus Dataset Management</a
      >
      <!-- <a mat-list-item *ngIf="menuConfig.busScheduleDataManagement.isVisible" role="listitem" matRipple [routerLinkActive]="['active']" [routerLink]="['/account/playback', 1]"><mat-icon matListIcon>play_arrow</mat-icon>Playback</mat-list-item> -->

      <!-- <a mat-list-item role="listitem" matRipple> -->

      <!-- </mat-list-item> -->
    </mat-list>
  </div>
  <mat-menu
    #personnelManagement="matMenu"
    xPosition="after"
    class="personnel-menu">
    <button
      mat-menu-item
      routerLink="/account/personnel-management/driver/1"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      Drivers
    </button>
    <button
      mat-menu-item
      routerLink="/account/personnel-management/controller/1"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      Controllers
    </button>
  </mat-menu>
  <mat-menu #vehicleManagement="matMenu" xPosition="after" class="vehicle-menu">
    <button
      mat-menu-item
      routerLink="/account/vehicle-management/vehicle/1"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      Vehicles
    </button>
  </mat-menu>
</div>
