<div
  class="emergency-call-group mat-elevation-z12"
  fxLayout="column"
  cdkDrag
  cdkDragBoundary=".account-body"
  [cdkDragDisabled]="isMaximized || !jitsiInitialized"
  [ngStyle]="
    !isMaximized
      ? { top: 2 + index + 'rem', 'z-index': index ? 101 + index : 101 }
      : {
          top: 0,
          left: 'calc(50% + 21px)',
          transform: '',
          'z-index': 500,
          width: 'calc(100% - 59px)',
          height: '100%',
          'border-radius': 0
        }
  ">
  <div
    class="call-header bgGreen"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    cdkDragHandle
    #callHeader
    (dblclick)="$event.preventDefault(); toggleMaximize()">
    <span>Emergency {{ emergencyData?.bus }}</span>
    <div fxLayout="row" fxLayoutGap="6px">
      <div
        class="header-icon"
        [ngClass]="{
          'icon-expand': !isMaximized,
          'icon-collapse': isMaximized
        }"
        (click)="toggleMaximize()"></div>
      <!-- <div class="icon-cancel header-icon" (click)="closeJitsiCall()"></div> -->
    </div>
  </div>
  <div class="call-body">
    <div
      id="jitsicallframe"
      class="call-frame"
      #jitsicallframe
      #resizeBox></div>
    <span
      #dragHandleCorner
      cdkDragLockAxis="both"
      class="dragHandle corner"
      [ngClass]="{ dragEnable: !isMaximized && jitsiInitialized }"
      cdkDrag
      [cdkDragDisabled]="isMaximized || !jitsiInitialized"
      (cdkDragMoved)="dragMove(dragHandleCorner, $event)"
      [cdkDragStartDelay]="100"></span>
    <span
      #dragHandleRight
      cdkDragLockAxis="x"
      class="dragHandle right"
      [ngClass]="{ dragEnable: !isMaximized && jitsiInitialized }"
      cdkDrag
      [cdkDragDisabled]="isMaximized || !jitsiInitialized"
      (cdkDragMoved)="dragMove(dragHandleRight, $event)"
      [cdkDragStartDelay]="100"></span>
    <span
      #dragHandleBottom
      cdkDragLockAxis="y"
      class="dragHandle bottom"
      [ngClass]="{ dragEnable: !isMaximized && jitsiInitialized }"
      cdkDrag
      [cdkDragDisabled]="isMaximized || !jitsiInitialized"
      (cdkDragMoved)="dragMove(dragHandleBottom, $event)"
      [cdkDragStartDelay]="100"></span>
  </div>
</div>
