<div class="call-window" app-private-call fxLayout="column">
    <div class="call-header bgGreen" fxLayout="row" fxLayoutAlign="space-between center" cdkDragHandle>
        <span *ngIf="busRegistrationNumber">{{busRegistrationNumber}} Call</span>
        <div *ngIf="isPopup" class="icon-cancel header-icon" (click)="closeJitsiCall()"></div>
    </div>
    <div class="call-body">
        <div *ngIf="callError || jitsiEnd" class="call-message">
            <span *ngIf="callError">There was an error with the call.</span>
            <span *ngIf="jitsiEnd">Call Ended.</span>
            <button *ngIf="isPopup" class="close-button" mat-button (click)="closeJitsiCall()">Close Window</button>
        </div>
        <div *ngIf="participantCount < 2 && !jitsiHidden" class="waiting-message">
            <span>Waiting for others to join the call</span>
        </div>
        <div class="call-container">
            <div id="jitsicallframe" class="call-frame" #jitsicallframe [class.hidden]="callError || jitsiEnd || jitsiHidden"></div>
        </div>

    </div>
</div>