import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceMonitorService {

  constructor(private httpClient: HttpClient
    ) { }

  getDeviceStatus() {
    return this.httpClient.post(environment.apiServerUrl + 'devices/getAllVehicleDeviceStatus', null);
  }

}

export interface DeviceStatusElement {
  deviceId: string
  busRegNo: string;
  voipId: string;
  ipAddress: string;
  deviceStatus: string;
  driverId: string;
  routeName: string;
  tripStatus: string;
  swVersion: string;
}
