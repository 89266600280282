import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MatTableDataSource, MatPaginator, MatTable, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import moment from 'moment';
import { map } from 'rxjs/operators';
// import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
// import { MapService } from 'src/app/component/shared/services/map.service';
import { ScheduleData } from 'src/app/component/shared/others/data-types';
import { UploadScheduleComponent } from '../schedule-management/upload-schedule/upload-schedule.component';
import { environment } from 'src/environments/environment';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


const ELEMENT_DATA: any[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  isNewWindow: boolean = false;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private httpClient: HttpClient, 
    private _snackBar: MatSnackBar, 
    private router: Router, 
    private route: ActivatedRoute,
    private dialog: MatDialog) { 
  }

  rptForm = new FormGroup({
    // route: new FormControl(null, [Validators.required]),
    // startDate: new FormControl(null, [Validators.required]),
    schedDate: new FormControl(moment().format('YYYY-MM-DD'), [Validators.required])
  });

  allRoutes = [];
  filteredRoutes: string[] = [];
  		 
  displayedColumns: string[] = ['Block ID', 'Trip ID', 'Parade Ground', 'PhilExcel', 'Friendship 2', 'CGC 2', 'SM', 'CGC 1', 'Friendship 1', 'Airport', 'Puregold', 'Parade Ground End', 'Total Travel Time'];

  // displayedColumns = [
  //   {'type': 'trip', 'code': 'tripId', 'name': 'Trip ID'}, 
  //   {'type': 'stop', 'code': 'paradeGround', 'name': 'Parade Ground'},
  //   {'type': 'stop', 'code': 'philExcel', 'name': 'Phil Excel'}, 
  //   {'type': 'stop', 'code': 'friendship1', 'name': 'Friendship 1'}, 
  //   {'type': 'stop', 'code': 'CGC1', 'name': 'CGC 1'}, 
  //   {'type': 'stop', 'code': 'SM', 'name': 'SM'}, 
  //   {'type': 'stop', 'code': 'CGC2', 'name': 'CGC 2'},
  //   {'type': 'stop', 'code': 'friendship2', 'name': 'Friendship 2'},
  //   {'type': 'stop', 'code': 'airport', 'name': 'Airport'}, 
  //   {'type': 'stop', 'code': 'puregold', 'name': 'Puregold'}
  // ];
  dataSource = new MatTableDataSource<ScheduleData>();

  running: boolean = false;
  schedDate:string = '';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  
  dialogConfig = new MatDialogConfig();
  // inputColumns = ['position', 'name', 'weight', 'symbol'];
  // inputData = ELEMENT_DATA;

  // displayColumns: string[];
  // displayData: any[];
  // showTable: boolean;
  ngOnInit() {
    
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }

    this.schedDate = moment().format('DD-MM-YYYY');
    this.run();
    
  }

  filterRoutes(search: string) {
    return this.allRoutes.filter(value => value.toLowerCase().indexOf(search.toLowerCase()) === 0);
  }

  run() {
    this.exportStarted('Generating report...');

    let url = environment.apiServerUrl + 'gtfsupload/busfleetdetails/getTransitDataSetByDateWebUI';
    let body = {
      tripDate: moment(this.rptForm.value.schedDate).format('YYYY-MM-DD')
    };

    this.httpClient.post(url, body).pipe(
      map((data: any) => {
        if (data.length == 0) {
          throw new Error('No record found');
        } else {
          return data;
        }
      })
    )
      .subscribe(
        (data: any) => {
          // let t = [];
          if(data instanceof Array) {
            data.forEach(scheduleData => {
              let total = 0;
              scheduleData.forEach((block,index) => {
                if(index <= 8 ){
                  let startTime = moment(scheduleData[index].arrivalTimeToStop, "HH:mm");// new Date("01/01/2007 "+ scheduleData[index].arrivalTimeToStop).getMinutes();
                  let endTime = moment(scheduleData[index+1].arrivalTimeToStop, "HH:mm"); //new Date("01/01/2007 " + scheduleData[index+1].arrivalTimeToStop).getMinutes();
                  let duration = moment.duration(endTime.diff(startTime));
                  
                  block.nextStopTimeDiff = duration.asMinutes();
                  total += block.nextStopTimeDiff;
                }
              });
              scheduleData.totalStopTimeDiff = total;
            });

            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            // this.tableData = t;
            this.dataSource.data = data;
            this.table.renderRows();

            this.exportCompleted();
          }
          else {
            this._snackBar.open(data, null, {
              duration: 2000,
            });
            this.running = false;
          }
        },
        (error: any) => {
          this._snackBar.open(error.statusText, null, {
            duration: 2000,
          });
          this.running = false;
        }
      );
  }

  exportStarted(message: string) {
    this.running = true;

    this._snackBar.open(message);
  }

  exportCompleted() {
    this._snackBar.dismiss();

    this.running = false;
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/reports/schedule/2','reports','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }

  uploadFile() {
    this.dialog.open(UploadScheduleComponent, this.dialogConfig);
  }
}
