import {
  trigger,
  transition,
  style,
  group,
  animate,
  state,
} from '@angular/animations';
export const slideUpDown = trigger('slideUpDown', [
  state(
    'in',
    style({
      'max-height': '500px',
      opacity: '1',
      display: 'inherit',
    })
  ),
  state(
    'out',
    style({
      'max-height': '0',
      opacity: '0',
      display: 'none',
    })
  ),
  transition('in => out', [
    group([
      animate(
        '400ms ease-in-out',
        style({
          opacity: '0',
        })
      ),
      animate(
        '600ms ease-in-out',
        style({
          'max-height': '0',
        })
      ),
      animate(
        '400ms ease-in-out',
        style({
          display: 'none',
        })
      ),
    ]),
  ]),
  transition('out => in', [
    group([
      animate(
        '1ms ease-in-out',
        style({
          display: 'inherit',
        })
      ),
      animate(
        '500ms ease-in-out',
        style({
          'max-height': '500px',
        })
      ),
      animate(
        '500ms ease-in-out',
        style({
          opacity: '1',
        })
      ),
    ]),
  ]),
  transition('in => out', [
    group([
      animate(
        '1ms ease-in-out',
        style({
          display: 'none',
        })
      ),
      animate(
        '1ms ease-in-out',
        style({
          'max-height': '0',
        })
      ),
      animate(
        '1ms ease-in-out',
        style({
          opacity: '0',
        })
      ),
    ]),
  ]),
]);
