<ng-container
  *ngFor="
    let emergencies of pushNotificationService.emergencyList;
    let index = index;
    trackBy: trackByRid
  ">
  <app-emergency-call
    [index]="emergencies.index"
    [emergencyData]="emergencies"
    (closeEmergency)="closeEmergency($event)"
    [id]="emergencies.rid">
  </app-emergency-call>
</ng-container>
