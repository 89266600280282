import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-ladder',
  templateUrl: './settings-ladder.component.html',
  styleUrls: ['./settings-ladder.component.scss']
})
export class SettingsLadderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
