import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
// import { PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';
import { MapService } from 'src/app/component/shared/services/map.service';
import { myinterface } from '../bus-details/bus-details.component';
import { slideUpDown } from 'src/app/component/shared/others/slideInUp';
import moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stop-details-all',
  templateUrl: './stop-details-all.component.html',
  styleUrls: ['./stop-details-all.component.scss'],
  animations: [slideUpDown],
})
export class StopDetailsAllComponent implements OnInit {
  @Input() data: any = null;
  @Output() busData: EventEmitter<any> = new EventEmitter<any>();

  public index: number;
  public selfRef: StopDetailsAllComponent;

  public compInteraction: myinterface;
  // public config: PerfectScrollbarConfigInterface = {};

  stop: any = null;
  route = '';
  isMinimize = 'out';
  isMinimizeEstimate = 'out';
  isSeeMore = 'out';
  arrivals: any;
  etaList: any;
  refreshInterval: any;
  isBusStopCodeShow: boolean;
  seeMore;
  seeMoreData: boolean = false;

  constructor(
    private mapService: MapService,
    public elementRef: ElementRef
  ) {}

  ngOnChanges() {}

  ngOnInit() {
    this.route = this.data.route;
    // console.log('init '+this.mapService.mapProvider);
    this.stop = this.data;
    this.isBusStopCodeShow = environment.isBusStopCodeShow;
    // let orientation = this.data.value.orientation;
    // let headway = this.data.value.headway;
    // if(orientation === 'middle' && headway === 1) {
    //   this.location = 'top';
    // }
    // else if(orientation === 'middle' && headway === 2) {
    //   this.location = 'bottom';
    // }
  }

  removeBus(index, busCode, stopCode, key) {
    this.compInteraction.removeBusComponent(
      index,
      busCode,
      stopCode,
      key,
      this.route
    );
  }

  zoomBlinkBusStop() {
    this.compInteraction.zoomBlinkBusStop(this.data);
  }

  /**
   * actual and real time bus arrival
   */
  // showMinimize() {
  //   this.isMinimize = this.isMinimize === 'in' ? 'out' : 'in';
  //   this.isMinimizeEstimate = 'out';
  //   if(this.isMinimize === 'in') {
  //     this.mapService.getStopDetailsByStopCode(this.stop.stopCode).then(
  //       (stopResData:any) => {
  //         this.arrivals = stopResData.arrivals;
  //         for (const eta of this.arrivals) {
  //           let duration = eta.duration / 60;
  //           eta.duration = Math.ceil(duration) <= 1 ? 'Arriving' : Math.ceil(duration)+' mins';
  //         }
  //       }
  //     );
  //   }

  // }

  showMinimizeEstimate() {
    this.isMinimizeEstimate = this.isMinimizeEstimate === 'in' ? 'out' : 'in';
    this.isMinimize = 'out';

    if (this.isMinimizeEstimate === 'in') {
      var self = this;
      this.etaEvent();
      this.refreshInterval = setInterval(function () {
        self.etaEvent();
      }, environment.bus.ETARefreshTime);
    }
  }

  showSeeMore() {
    this.isSeeMore = this.isSeeMore === 'in' ? 'out' : 'in';
  }

  etaEvent() {
    var self = this;

    if (self.stop.route !== '96C') {
      let etaAPI =
        environment.project === 'cetrac'
          ? self.mapService.getStopEtaByStopCodeDataMall(self.stop.stopCode)
          : self.mapService.getStopEtaByStopCode(self.stop.stopCode);
      etaAPI.then(
        // self.mapService.getStopEtaByStopCode(self.stop.stopCode).then(
        (stopResData: any) => {
          let testData = [];
          let testSeeMore = [];
          for (const eta of stopResData) {
            let route = eta.ServiceNo;
            // let i = 0;

            for (const etaBus in eta) {
              if (eta[etaBus].EstimatedArrival) {
                var now = moment(new Date()); //todays date
                var end = moment(eta[etaBus].EstimatedArrival); // another date
                var duration = moment.duration(end.diff(now));
                // var min = duration.asMinutes();

                if (
                  route === self.stop.routeName &&
                  typeof eta[etaBus] === 'object'
                ) {
                  let durationSec = duration.asSeconds();
                  eta[etaBus].routeName = route;
                  eta[etaBus].duration =
                    durationSec <= 20
                      ? 'Arriving '
                      : Math.ceil(duration.asMinutes()) + ' mins';
                  testData.push(eta[etaBus]);
                } else if (
                  eta[etaBus].EstimatedArrival === undefined &&
                  typeof eta[etaBus] === 'object'
                ) {
                  eta[etaBus].routeName =
                    eta[etaBus].routeName === undefined
                      ? '-'
                      : eta[etaBus].routeName;
                  eta[etaBus].duration =
                    eta[etaBus].duration === undefined
                      ? '-'
                      : eta[etaBus].duration;
                  testData.push(eta[etaBus]);
                } else if (
                  route !== self.stop.routeName &&
                  typeof eta[etaBus] === 'object'
                ) {
                  testSeeMore[route] =
                    testSeeMore[route] === undefined ? [] : testSeeMore[route];

                  setTimeout(() => {
                    for (const row of testSeeMore[route]) {
                      row.routeName = route;
                      let endSeeMore = moment(row.EstimatedArrival); // another date
                      let durationSeeMore = moment.duration(
                        endSeeMore.diff(now)
                      );
                      let minSeemore = durationSeeMore.asMinutes();
                      let secSeeMore = durationSeeMore.asSeconds();

                      // row.duration = Math.ceil(minSeemore) <= 1 ? 'Arriving' : Math.ceil(durationSeeMore.asMinutes())+' mins';
                      row.duration =
                        secSeeMore <= 20
                          ? 'Arriving'
                          : Math.ceil(minSeemore) + ' mins';
                    }
                  }, 50);

                  testSeeMore[route].push(eta[etaBus]);
                  self.seeMoreData = true;
                }
              }
            }
          }

          self.etaList = {
            currentRoute: testData,
            others: testSeeMore,
          };
          let seeMore2 = [];
          for (const i in self.etaList.others) {
            seeMore2.push(testSeeMore[i]); //remove index
          }
          self.seeMore = seeMore2;
        },
        err => {
          console.log('err');
        }
      );
    }
    // else {
    //   self.mapService.getStopEtaByStopCode(self.stop.stopCode).then(
    //     (stopResData:any) => {
    //       if(stopResData === true) {
    //         self.etaList = [];
    //         self.etaList.push([{'routeId': '-'}, {'duration':'-'}]);
    //       }
    //       else {
    //         self.etaList = stopResData;
    //         for (const eta of self.etaList) {
    //           for (const etaBus of eta) {
    //             let duration = etaBus.duration / 60;
    //             etaBus.duration = duration < 3 ? 'Arriving' : Math.ceil(duration)+' mins';
    //           }
    //           if(eta.length === 1) {
    //             eta.push({'duration':'-'});
    //           }
    //         }
    //       }
    //     },
    //     err => {
    //       console.log('err');
    //     }
    //   );
    // }
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }
}
