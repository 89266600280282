<div class="info-modal">
  <h1 mat-dialog-title>{{ dataDialog.header }}</h1>
  <div mat-dialog-content class="body-content">
    {{ dataDialog.message }}
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button (click)="onCloseModal()" cdkFocusInitial>
      {{
        dataDialog.textCancel === undefined ? 'Close' : dataDialog.textCancel
      }}
    </button>
    <button
      mat-raised-button
      [color]="dataDialog.mode === 'warn' ? 'warn' : 'primary'"
      (click)="onConfirm()">
      {{ dataDialog.textConfirm === undefined ? 'OK' : dataDialog.textConfirm }}
    </button>
  </div>
</div>
