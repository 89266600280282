import moment from 'moment';
import { initTimeTable } from '../constant/route-time-table';
import { generateTimeSlots, getNearestTimeSlot } from './demo-timer';

export const availableBlockIds = {
  7001: [],
  7002: [],
  7003: [],
};

export function createTimeTables() {
  const timeTable = [...initTimeTable];

  const generatedTimetable = timeTable.map(currTable => {
    const {
      routeId,
      routePrefix,
      stopList,
      timetable,
      timeStop,
      numBus,
      maxBus,
      busArr,
      busCode,
    } = currTable;
    const blockIds = generateBlockIds(routePrefix, maxBus, busArr, busCode);

    // const initialTrips = createTrips2(
    //   routePrefix,
    //   blockIds,
    //   stopList,
    //   timeStop
    // );

    const tripTables = stopList.map(stopListItem => {
      const { direction, stops } = stopListItem;

      availableBlockIds[routeId] = [...blockIds];

      const dir2Start = moment('06:00', 'HH:mm').add(
        stops.length * timeStop + 5,
        'minutes'
      );
      const dir2End = moment('22:00', 'HH:mm').add(
        (stops.length + 2) * timeStop,
        'minutes'
      );
      const blockTimeDiff = 15;
      const getDir2StartTime = getNearestTimeSlot(dir2Start, blockTimeDiff);
      const getDir2EndTime = getNearestTimeSlot(dir2End, blockTimeDiff);

      const timeStart = direction === '1' ? '06:00' : getDir2StartTime;
      const timeEnd = direction === '1' ? '22:00' : getDir2EndTime;

      // const trips = initialTrips.filter(item => item.direction === direction);

      const trips = createTrips(
        routePrefix,
        blockIds,
        stops,
        direction,
        timeStop,
        timeStart,
        timeEnd
      );

      // console.log('generatedtrip', trips);

      const newTimeTableItem = {
        direction,
        trips,
      };
      return newTimeTableItem;
    });

    const newTimeTable = {
      routeId,
      numBus,
      // routePrefix,
      stopList,
      timetable: tripTables,
    };
    return newTimeTable;
  });

  console.log('generated timetable', generatedTimetable);

  // const filteredTimeTable = generatedTimetable.map(data => {
  //   const { routeId, stopList, timetable } = data;

  //   const dir1table = timetable.find(item => item.direction === '1');
  //   const dir2table = timetable.find(item => item.direction === '2');

  //   const filteredDir2Trips = dir2table.trips.filter(trip => {
  //     const { stops } = trip;
  //     const firstStop2 = stops[0].schArrTime;

  //     // check if first stop exists in dir1
  //     const isStartInDir1 = dir1table.trips.some(trip1 => {
  //       const { stops: dir1Stops } = trip1;
  //       const firstStop1 = dir1Stops[0].schArrTime;
  //       if (firstStop1 === firstStop2) return true;
  //       return false;
  //     });
  //     return !isStartInDir1;
  //   });

  //   const newTimeTable = [
  //     dir1table,
  //     { direction: '2', trips: filteredDir2Trips },
  //   ];

  //   return { routeId, stopList, timetable: newTimeTable };
  // });

  // console.log('generated timetable', generatedTimetable, filteredTimeTable);

  return generatedTimetable;
}

const tripTemplate = {
  blockId: '',
  tripId: '',
  stops: [],
};
const stopTemplate = {
  stopId: 'PS1',
  stopSequence: 1,
  schArrTime: '00:00:00',
  obsArrTime: null,
  busRegNo: null,
  driverId: null,
  driverName: null,
  plannedHeadway: '0',
  observedHeadway: null,
  headwayDeviation: null,
  direction: '0',
  itp: 0,
};

function generateBlockIds(routePrefix, stopLength, busArr?, busCode?) {
  const blockIds = Array.from({ length: stopLength }, (_, index) => {
    if (busArr?.length > 0) {
      // return `${busCode}-${busArr[index]}`;
      return `${busArr[index]}`;
    }
    const blockId = routePrefix + (index + 1).toString().padStart(6, '0');
    return blockId;
  });
  return blockIds;
}

function createTrips(
  routePrefix,
  blockIds,
  stops,
  direction = '1',
  timeStop = 15,
  timeStart = '06:00',
  timeEnd = '22:00'
) {
  const blockTimeDiff = 15;
  const timeSlots = generateTimeSlots(timeStart, timeEnd, blockTimeDiff);

  let blockIndex = 0;

  const trips = timeSlots.reduce((acc, curr, index) => {
    const blockId = blockIds[blockIndex++];
    if (blockIndex >= blockIds.length) {
      blockIndex = 0;
    }
    const tripId =
      routePrefix + direction + (index + 1).toString().padStart(5, '0');
    const newTrip = {
      ...tripTemplate,
      tripId: 'T' + tripId,
      blockId,
      direction,
    };

    const tripTimeslots = generateTimeSlots(curr, '23:30:00', timeStop).slice(
      0,
      stops.length
    );
    // console.log(curr, tripTimeslots, generateTimeSlots(curr), stops);

    const newStops = tripTimeslots.map((timeSlot, index) => {
      const newStop = {
        ...stopTemplate,
      };
      newStop.stopId = stops[index];
      newStop.stopSequence = index + 1;
      newStop.schArrTime = timeSlot;
      newStop.direction = direction;
      return newStop;
    });

    newTrip.stops = newStops;

    acc.push(newTrip);

    return acc;
  }, []);

  return trips;
}

function createTrips2(
  routePrefix,
  blockIds,
  stopList,
  // direction = '1',
  timeStop = 15,
  timeStart = '06:00',
  timeEnd = '22:00'
) {
  const blockTimeDiff = 15;
  const stopLength = stopList[0].stops.length;
  const timeSlots = generateTimeSlots(timeStart, timeEnd, blockTimeDiff);
  const dir2Start = moment(timeStart, 'HH:mm').add(
    stopLength * timeStop,
    'minutes'
  );
  const dir2End = moment(timeEnd, 'HH:mm').add(
    (stopLength + 2) * timeStop,
    'minutes'
  );
  const getDir2StartTime = getNearestTimeSlot(dir2Start, blockTimeDiff);
  const getDir2EndTime = getNearestTimeSlot(dir2End, blockTimeDiff);

  const timeSlots2 = generateTimeSlots(
    getDir2StartTime,
    getDir2EndTime,
    blockTimeDiff
  );

  const combinedArr = timeSlots
    .map(function (v, i) {
      return [v, timeSlots2[i]];
    })
    .reduce(function (a, b) {
      return a.concat(b);
    });

  let blockIndex = 0;

  let direction = '1';

  const trips = combinedArr.reduce((acc, curr, index) => {
    const blockId = blockIds[blockIndex++];
    if (blockIndex >= blockIds.length) {
      blockIndex = 0;
    }
    const tripId =
      routePrefix + direction + (index + 1).toString().padStart(5, '0');
    const newTrip = {
      ...tripTemplate,
      tripId: 'T' + tripId,
      blockId,
      direction,
    };

    const stops = stopList.find(item => item.direction === direction).stops;

    const tripTimeslots = generateTimeSlots(curr, '23:30:00', timeStop).slice(
      0,
      stops.length
    );
    // console.log(curr, tripTimeslots, generateTimeSlots(curr), stops);

    const newStops = tripTimeslots.map((timeSlot, index) => {
      const newStop = {
        ...stopTemplate,
      };
      newStop.stopId = stops[index];
      newStop.stopSequence = index + 1;
      newStop.schArrTime = timeSlot;
      newStop.direction = direction;
      return newStop;
    });

    newTrip.stops = newStops;

    acc.push(newTrip);

    direction = direction === '1' ? '2' : '1';

    return acc;
  }, []);

  return trips;
}

export function getStop(stops, timeslot) {
  const currStop = stops.find(stop => stop.schArrTime === timeslot);
  return currStop;
}
