import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Home2Service } from './home2.service';

declare var H: any;

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.scss']
})
export class Home2Component implements OnInit, OnDestroy {
  constructor(
    private _home2Service: Home2Service
  ) {
    this.platform = new H.service.Platform({
        "apikey": environment.hereApiKey
    });
  }

  platform: any;
  @ViewChild("map", { static: true }) public mapElement: ElementRef;
  mapInstance:any;
  ui: any;
  coords1 = {'lat':1.3379, 'lng': 103.8269};
  coords2 = {'lat':1.3304, 'lng': 103.8379};
  stopList = [];

  ngOnInit() {

  }

  ngAfterViewInit() {
    let defaultLayers = this.platform.createDefaultLayers();
    this.mapInstance = new H.Map(
        this.mapElement.nativeElement,
        defaultLayers.raster.normal.map,
        {
            zoom: 12,
            center: { lat: 1.3521, lng: 103.8198 }
        }
    );
    
    let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.mapInstance));
    this.ui = H.ui.UI.createDefault(this.mapInstance, defaultLayers);

    this.ui.removeControl('mapsettings');
    var ms = new H.ui.MapSettingsControl({
        layers: [
        {
            label: 'Map View', layer: defaultLayers.raster.normal.map
        },
        {
            label: 'Incidents', layer: defaultLayers.vector.normal.trafficincidents
        },
        {
            label: 'Traffic Layer', layer: defaultLayers.vector.normal.traffic
        }]
    });
    this.ui.addControl('customized', ms);

    this.addDraggableMarker(this.mapInstance, behavior, 1);
    this.addDraggableMarker(this.mapInstance, behavior, 2);
  }

  addDraggableMarker(map, behavior, iconNumber){
    var me = this;
    var pngIcon = new H.map.Icon("assets/images/icon/map-marker-orange.png");
    var coords = this.coords1;
    if(iconNumber === 2) {
      pngIcon = new H.map.Icon("assets/images/icon/map-marker-blue-2.png");
      coords = this.coords2;
    }
    // var svgMarkup = '<svg width="24" height="24" ' +
    //     'xmlns="http://www.w3.org/2000/svg">' +
    //     '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
    //     'height="22" /><text x="12" y="18" font-size="12pt" ' +
    //     'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
    //     'fill="white">H</text></svg>';
    

    var outerElement = document.createElement('div'),
    innerElement = document.createElement('div');

    innerElement.innerHTML = 'test1';
    outerElement.appendChild(innerElement);

    // var pngIcon = new H.map.Icon(svgMarkup);
      // var pngIcon = new H.map.DomIcon(outerElement);

    var marker = new H.map.Marker({lat:coords.lat, lng:coords.lng}, {
      // mark the object as volatile for the smooth dragging
      volatility: true,
      icon: pngIcon
    });
    // Ensure that the marker can receive drag events
    marker.draggable = true;
    marker.data = {'iconNumber': iconNumber};
    map.addObject(marker);

    // disable the default draggability of the underlying map
    // and calculate the offset between mouse and target's position
    // when starting to drag a marker object:
    map.addEventListener('dragstart', function(ev) {
      var target = ev.target,
          pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        var targetPosition = map.geoToScreen(target.getGeometry());
        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
        behavior.disable();
      }
    }, false);


    // re-enable the default draggability of the underlying map
    // when dragging has completed
    map.addEventListener('dragend', function(ev) {
      var target = ev.target;
      var pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        var coords = map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y);
        if(target.data.iconNumber === 1) {
          me.coords1 = {
            lat: parseFloat(coords.lat),
            lng: parseFloat(coords.lng)
          }
        }
        else if(target.data.iconNumber === 2) {
          me.coords2 = {
            lat: parseFloat(coords.lat),
            lng: parseFloat(coords.lng)
          }
        }

        behavior.enable();
      }
    }, false);

    // Listen to the drag event and move the position of the marker
    // as necessary
    map.addEventListener('drag', function(ev) {
      var target = ev.target,
          pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
      }
    }, false);
  }

  addRectangleToMap() {
    var coords = this.coords1.lng + ',' + this.coords2.lat + ',' + this.coords2.lng + ',' + this.coords1.lat;
    this._home2Service.getBbox(coords).then((resp:any) => {
      this.stopList = resp.data.items;
    }, 
    (err) => {
      console.log(err);
    });

    var bboxLayer = this.mapInstance.getObjects().find(x => x.data.name === 'bbox');
    if(bboxLayer) {
      this.mapInstance.removeObject(bboxLayer);
    }

    var boundingBox = new H.geo.Rect(this.coords1.lat, this.coords1.lng, this.coords2.lat, this.coords2.lng);
    this.mapInstance.addObject(
      new H.map.Rect(boundingBox, {
        style: {
          // fillColor: '#FFFFCC',
          strokeColor: '#808080',
          lineWidth: 2
        },
        data: {name:'bbox'}
      })
    );


  }

  ngOnDestroy() {

  }
}
