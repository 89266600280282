import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../shared/services/menu.service';

//start map
import Map from 'ol/Map';
// import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import {
  Vector as VectorLayer,
  VectorImage as VectorImageLayer,
} from 'ol/layer.js';
import View from 'ol/View';
import { fromLonLat, toLonLat } from 'ol/proj';
import { transform } from 'ol/proj.js';
import Point from 'ol/geom/Point';
// import Feature from 'ol/Feature';
import { Vector as VectorSource } from 'ol/source.js';
import { Icon, Style } from 'ol/style.js';
// import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Overlay from 'ol/Overlay';
// import { toStringHDMS } from 'ol/coordinate';
// import TileJSON from 'ol/source/TileJSON.js';
// import { PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';
import OlFeature from 'ol/Feature';
// import LineString from 'ol/geom/LineString.js';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/observable/interval';

//google map
import GoogleLayer from 'olgm/layer/Google.js';
import OLGoogleMaps from 'olgm/OLGoogleMaps.js';
import { MapService } from '../../shared/services/map.service';
import { environment } from 'src/environments/environment';

//end map

declare var $: any;

@Component({
  selector: 'app-traffic-events',
  templateUrl: './traffic-events.component.html',
  styleUrls: ['./traffic-events.component.scss'],
})
export class TrafficEventsComponent implements OnInit {
  cars = [];
  // cols: any[];
  // trafficData;
  congestionFlatData = [];
  map: Map;
  congestionMapList = [];
  isNewWindow: boolean = false;
  getParams: any;
  // viewAnimate: View = new View({
  //   center: fromLonLat([103.8198, 1.3521]),
  //   // center: fromLonLat([data[1][0]['busStopLon'], data[1][0]['busStopLat']]),
  //   zoom: 12,
  //   // minZoom: 8,
  //   maxZoom: 18
  // })
  listMark = [];

  glRoadmap: GoogleLayer;
  OSMLayer: TileLayer<any>;
  isOSM: boolean = true;
  prevPopup;
  locations = [];
  locationSubs = [];
  locationInterval;
  isIconSrcYellow = new Style({
    image: new Icon(
      /** @type {module:ol/style/Icon~Options} */ {
        src: 'assets/images/icon/mark-yellow-16.png', //map-marker-white-2.png',
      }
    ),
  });
  isIconSrcRed = new Style({
    image: new Icon(
      /** @type {module:ol/style/Icon~Options} */ {
        src: 'assets/images/icon/mark-red-16.png', //map-marker-white-2.png',
      }
    ),
  });
  isIconSrcMaroon = new Style({
    image: new Icon(
      /** @type {module:ol/style/Icon~Options} */ {
        src: 'assets/images/icon/mark-maroon-16.png', //map-marker-white-2.png',
      }
    ),
  });
  markerArray: any = [];

  constructor(
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private mapService: MapService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }

    this.map = new Map({
      target: 'traffic-map',
      // layers: [
      //   glRoadmap
      // ],
      // loadTilesWhileAnimating: true,
      view: new View({
        center: fromLonLat(environment.map.locationLonLat),
        // center: fromLonLat([data[1][0]['busStopLon'], data[1][0]['busStopLat']]),
        zoom: 12,
        // minZoom: 8,
        maxZoom: 18,
      }),
    });

    this.getMapMark();
    this.initGooleMap();
  }
  getMapMark() {
    // console.log(
    // this.mapService.getHereTrafficData('1.302845,103.900380;1.301706,103.909890')
    // )

    this.locations = [
      // '1.39306646,103.64192963;1.30382728,103.77101898',
      // '1.44592194,103.7682724;1.27087661,103.86920929',
      // '1.41503252,103.86508942;1.29833553,103.91384125',
      // '1.39375291,103.90491486;1.30520021,103.97151947',

      // '3.226750, 101.723442;3.016263, 101.471443' //KL
      //'1.409391,103.915901;1.304364,103.710594'

      //clark
      '15.358211,120.678145;15.149538,120.488049',
    ];
    let i = 0;
    let locLen = this.locations.length - 1;
    let me = this;
    let locationIntervalTime = 5000;

    this.locationInterval = setInterval(function () {
      // console.log(me.locations[i]);

      me.mapService
        .getHereTrafficData(me.locations[i])
        .then((hereTrafficData: any) => {
          let index = 0;
          // console.log(hereTrafficData);
          // for (const location of hereTrafficData) {

          me.locationSubs[index] = hereTrafficData.subscribe(
            (locationData: any) => {
              // console.log(locationData);

              let rwsTraffic = locationData.data.RWS; //sampleTrafficData['RWS']
              for (const rws of rwsTraffic) {
                for (const rw of rws.RW) {
                  for (const fis of rw.FIS) {
                    let fiObj = {};
                    for (const fi of fis.FI) {
                      let su: number = 0;
                      let ff: number = 0;
                      let jf: number = 0;
                      let shpValue = [];
                      for (const cf of fi.CF) {
                        // console.log(cf);
                        su = cf.SU;
                        ff = cf.FF;
                        jf = cf.JF;
                      }
                      for (const shp of fi.SHP) {
                        let val = shp.value.toString().split(' ');
                        // console.log(val.toString().split(' '));
                        for (const latLon of val) {
                          if (latLon) {
                            let loc = latLon.split(',');
                            shpValue.push({
                              lat: parseFloat(loc[0]),
                              lon: parseFloat(loc[1]),
                            });
                          }
                        }
                      }
                      fiObj = {
                        fi: {
                          su: su,
                          ff: ff,
                          jf: jf,
                        },
                        shp: shpValue,
                      };
                      // console.log(fiObj);
                      me.listMark.push(fiObj);
                    }
                  }
                }
              }

              // console.log(me.listMark);

              me.map.on('singleclick', evt => {
                // console.log(123);

                $(me.prevPopup).popover('dispose');

                // let feature = me.map.forEachFeatureAtPixel(evt.pixel,
                //   function(feature) {
                //     return feature;
                // });
                var feature = me.map.forEachFeatureAtPixel(
                  evt.pixel,
                  function (feature: OlFeature, layer) {
                    return feature;
                  }
                );

                if (feature) {
                  let element = document.getElementById('popup-' + index);
                  $(me.prevPopup).popover('dispose');
                  //pop up id
                  index++;
                  me.prevPopup = element;

                  let popup = new Overlay({
                    element: element,
                    positioning: 'bottom-center',
                    stopEvent: false,
                    offset: [0, -10],
                  });
                  me.map.addOverlay(popup);
                  let geometry = feature.getGeometry();
                  if (geometry instanceof Point) {
                    let coordinates = geometry.getCoordinates();
                    popup.setPosition(coordinates);
                    $(element).popover({
                      placement: 'top',
                      html: true,
                      content: feature.get('name'),
                    }).css;
                    $(element).popover('show');
                  }
                } else {
                  $(me.prevPopup).popover('dispose');
                }
              });
              // var hit = '';
              // // change mouse cursor when over marker
              me.map.on('pointermove', e => {
                if (e.dragging) {
                  $(me.prevPopup).popover('dispose');
                  return;
                }
                var hit = me.map.hasFeatureAtPixel(e.pixel);
                me.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
              });

              me.addMarkMap(i);
            }
          );
          // }
        }); //end get traffic data

      if (locLen === i) {
        clearInterval(me.locationInterval);
      }
      i++;
    }, locationIntervalTime);
    // setTimeout(() => {
    //   let layers = this.map.getLayers().getArray().slice();
    //   console.log(layers);
    // }, 15000);
  }

  addMarkMap(indexLayer) {
    console.log('mark map ' + indexLayer);
    // let markListVector = [];

    const vectorSource = new VectorSource({
      features: [],
      useSpatialIndex: false,
    });

    var markerCount = 0;
    for (const mark of this.listMark) {
      let su = mark.fi.su;
      let ff = mark.fi.ff;
      let jf = parseFloat(mark.fi.jf);
      let markStyle: Style;
      let iconSrc = '';
      let tcf: any = (10 * (ff - su)) / ff;
      let tcfTotal = parseFloat(tcf).toFixed(2);

      // console.log(mark);
      if (tcf >= 6 && tcf < 8) {
        iconSrc = 'mark-yellow-16.png'; //'#ebd350'; //yellow orange
        markStyle = this.isIconSrcYellow;
      } else if (tcf >= 8 && tcf < 9) {
        iconSrc = 'mark-red-16.png'; //'#ff0000'; //red
        markStyle = this.isIconSrcRed;
      } else if (tcf >= 9) {
        iconSrc = 'mark-maroon-16.png'; //'#800000'; //maroon
        markStyle = this.isIconSrcMaroon;
      }
      // else {
      //   iconSrc = 'mark-yellow.png';//'#008000'; //green
      // }
      if (iconSrc) {
        for (const shp of mark.shp) {
          var startMarker = new OlFeature({
            type: 'point',
            geometry: new Point(
              transform([shp.lon, shp.lat], 'EPSG:4326', 'EPSG:3857')
            ),
            name:
              'Current Ave. Speed: <b>' +
              su +
              '</b><br> Free Flow Speed: <b>' +
              ff +
              '</b>' +
              '<br> TCF: <b>' +
              tcfTotal +
              '</b>',
          });
          startMarker.setStyle(markStyle);
          // markListVector.push(startMarker);
          vectorSource.addFeature(startMarker);
        }
      }
      this.markerArray.push(markerCount++);
    }

    let markLayer = new VectorImageLayer({
      renderBuffer: 2,
      // source: new VectorSource({
      //   features: markListVector
      // }),

      source: vectorSource,
      className: 'markList-' + indexLayer,
      zIndex: 2,
      properties: {
        name: 'markList-' + indexLayer,
      },
    });

    this.map.addLayer(markLayer);
  }

  initGooleMap() {
    this.glRoadmap = new GoogleLayer({
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      name: 'gm-roadmap',
      visible: true,
    });

    this.map.addLayer(this.glRoadmap);

    let olGM = new OLGoogleMaps({
      map: this.map,
    }); // map is the Map instance

    let trafficLayer = new google.maps.TrafficLayer();
    const gmap = olGM.getGoogleMapsMap();
    trafficLayer.setMap(gmap);

    olGM.activate();

    // let layers = this.map.getLayers().getArray().slice();
    // console.log(layers);
  }

  initMap() {
    const mapId = 'traffic-map';
    // this.OSMLayer = new TileLayer({
    //   preload: 4,
    //   source: new XYZ({
    //     url: 'http://tile.osm.org/{z}/{x}/{y}.png',
    //     crossOrigin: ''
    //   }),
    //   name: 'openlayer'
    // });

    this.map = new Map({
      target: mapId,
      layers: [
        // ,markLayer
      ],
      // loadTilesWhileAnimating: true,
      view: new View({
        center: fromLonLat(environment.map.locationLonLat),
        // center: fromLonLat([data[1][0]['busStopLon'], data[1][0]['busStopLat']]),
        zoom: 12,
        // minZoom: 8,
        maxZoom: 18,
      }),
    });
    // this.initTraffic();
  }

  openWindow() {
    this.menuService.setDispatcher(true);
    this.router.navigate(['/account/home']);
    // console.log("open window " + screen.height + ' ,' + screen.width);
    let newwin = window.open(
      'account/traffic-events/2',
      'traffic-events',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  ngOnDestroy() {
    for (const location of this.locationSubs) {
      location.unsubscribe();
    }
    clearInterval(this.locationInterval);
  }

  mapClick(evt) {
    // console.log(evt);
    return false;

    // this.map.on('pointermove', (e) => {
    //   if (e.dragging) {
    //     // $(this.prevPopup).popover('dispose');
    //     return;
    //   }
    //   var pixel = this.map.getEventPixel(e.originalEvent);
    //   var hit = this.map.hasFeatureAtPixel(pixel);
    //   document.getElementById(this.map.getTarget()).style.cursor = hit ? 'pointer' : '';
    // });

    // this.map.on('singleclick', (evt) => {
    //   // console.log('clicked map');
    //   // $(me.prevPopup).popover('dispose');
    //   // me.prevPopup = element;

    //   let feature = this.map.forEachFeatureAtPixel(evt.pixel,
    //     function(feature) {
    //       return feature;
    //     }
    //   );

    //   let element = feature.values_.name;
    //   let popup = new Overlay({
    //     element: element,
    //     positioning: 'bottom-center',
    //     stopEvent: false,
    //     offset: [0, -10]
    //   });
    //   this.map.addOverlay(popup);

    //   if (feature) {
    //     console.log(feature);
    //     let coordinates = feature.getGeometry().getCoordinates();
    //     popup.setPosition(coordinates);
    //     element.popover({
    //       placement: 'top',
    //       html: true,
    //       content: feature.get('name')
    //     });
    //     element.popover('show');
    //   } else {
    //     $(this.prevPopup).popover('dispose');
    //     console.log(555);
    //   }
    //   evt.preventDefault(); //add this to avoid bubling
    // });
  }
}
