import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { CountryCode } from 'src/app/component/shared/others/constant';

@Component({
  selector: 'app-vehicle-device-edit',
  templateUrl: './vehicle-device-edit.component.html',
  styleUrls: ['./vehicle-device-edit.component.scss']
})
export class VehicleDeviceEditComponent implements OnInit {
  form: FormGroup;
  data:any;
  title: string = 'Edit';
  CountryCode: any = CountryCode;

  constructor(private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) data: any
    // private drvService: PmDriverService, 
    // private _snackBar: MatSnackBar
    ) {
      this.data = data;
  }

  ngOnInit() {
    this.title = this.data.busRegNo === null ? 'New' : 'Edit';

    this.form = this.formBuilder.group({
      busRegNo: [this.data.busRegNo, Validators.required],
      deviceId: [this.data.deviceId, Validators.required],
      voipId: [this.data.deviceVoipId, Validators.required],
      ctryCode: [this.data.ctryCode, Validators.required]
    });
  }

  save() {
    console.log(this.data);
  }
}
