import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
// import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'perfect-scrollbar-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bus-switch',
  templateUrl: './bus-switch.component.html',
  styleUrls: ['./bus-switch.component.scss'],
})
export class BusSwitchComponent implements OnInit, OnDestroy {
  @Input() data: any = null;
  @Input() disabled = false;
  @Output() busData: EventEmitter<any> = new EventEmitter<any>();
  isCLick: boolean = true;
  // public config: PerfectScrollbarConfigInterface = {};
  switchData: any = {
    primary: undefined,
    secondary: undefined,
  };
  // @ViewChild('PerfectScrollbarComponent') perfectScrollbar?: PerfectScrollbarComponent;
  // @ViewChild('perfectScroll', { static: true }) perfectScroll: PerfectScrollbarComponent;
  isRouteNameUse: boolean;
  filteredSwitchData = {
    primary: undefined,
    secondary: undefined,
  };

  searchName: string = '';
  searchNameValue: string = '';
  searchNameSubject = new Subject<string | undefined>();
  searchNameSubscription: Subscription;

  onSearchNameInput(data) {
    this.searchNameSubject.next(data);
  }

  startSearchNameSubscription() {
    this.searchNameSubscription = this.searchNameSubject
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(result => {
        const searchQuery = result?.toString()?.trim()?.toLowerCase();
        this.searchNameValue = searchQuery;
        this.onSearchChange();
      });
  }

  clearNameTextBox(event) {
    event.stopPropagation();
    this.searchName = '';
    this.searchNameValue = '';
    this.onSearchChange();
    // this.searchNameSubject.next('');
  }

  onSearchChange() {
    let primarySwitchData = this.switchData.primary;
    let secondarySwitchData = this.switchData.secondary;

    if (this.searchNameValue?.length > 0) {
      primarySwitchData =
        primarySwitchData.filter(
          bus =>
            bus?.service?.toLowerCase().includes(this.searchNameValue) ||
            bus?.routeName?.toLowerCase().includes(this.searchNameValue)
        ) ?? undefined;
      secondarySwitchData =
        secondarySwitchData?.filter(
          bus =>
            bus?.service?.toLowerCase().includes(this.searchNameValue) ||
            bus?.routeName?.toLowerCase().includes(this.searchNameValue)
        ) ?? undefined;
    }
    this.filteredSwitchData = {
      primary: primarySwitchData,
      secondary: secondarySwitchData,
    };
  }

  constructor() {}

  ngOnInit() {
    this.switchData = this.data;
    this.isRouteNameUse = environment.isRouteNameUse;
    this.onSearchChange();
    this.startSearchNameSubscription();
  }

  ngOnDestroy() {
    this.searchNameSubscription?.unsubscribe();
    this.searchNameSubscription = undefined;
  }

  // onListScroll(querySelector) {
  //   this.perfectScroll.directiveRef.update(); //for update scroll
  //   this.perfectScroll.directiveRef.scrollToElement(querySelector, 0);
  // }

  isSwitch(busCode, event) {
    // console.log(event.checked);
    busCode.isOn = event.checked ? 'checked' : '';
    busCode.isChecked = event.checked;
    this.busData.emit(busCode);
    // event.stopPropagation();
  }
}
