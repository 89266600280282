<div class="drawer-container" app-driver-detail>
  <div class="drawer-header" [ngClass]="{ 'header-edit': isEdit }">
    <div class="header-text">
      {{ isEdit ? 'Edit Driver' : 'Driver Details' }}
    </div>
    <div>
      <button
        *ngIf="!isEdit && !isLoading"
        matTooltip="Delete Driver"
        mat-icon-button
        [disabled]="isLoading || drawerOpen"
        (click)="clickDelete()">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        *ngIf="!isEdit"
        matTooltip="Edit Driver"
        mat-icon-button
        [disabled]="isLoading"
        (click)="clickEdit(!isEdit)">
        <mat-icon>edit</mat-icon>
      </button>
      <button
        matTooltip="Close Details"
        mat-icon-button
        (click)="clickClose()"
        [disabled]="isLoading">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="drawer-content custom-page-scroll">
    <form
      [formGroup]="parentForm"
      (keydown.enter)="$event.preventDefault()"
      (ngSubmit)="clickSave()"
      id="driver-detail-form">
      <div class="drawer-row">
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          floatLabel="always"
          class="read-only">
          <mat-label>Driver ID</mat-label>
          <input
            name="Driver ID"
            matInput
            aria-label="Driver ID"
            formControlName="userid"
            readonly />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          floatLabel="always"
          class="read-only">
          <mat-label>Date Joined</mat-label>
          <input
            name="Date Joined"
            matInput
            aria-label="Date Joined"
            [value]="
              driverDetails?.date_joined | momentPipe: 'DD/MM/YYYY HH:mm'
            "
            readonly />
        </mat-form-field>
      </div>
      <div class="drawer-group">
        <div class="drawer-title"><span>Information</span></div>

        <div class="drawer-row">
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>First Name</mat-label>
            <input
              name="First Name"
              matInput
              aria-label="First Name"
              formControlName="first_name"
              [readonly]="!isEdit" />
          </mat-form-field>
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>Middle Name</mat-label>
            <input
              name="Middle Name"
              matInput
              aria-label="Middle Name"
              formControlName="middle_name"
              [readonly]="!isEdit" />
          </mat-form-field>
        </div>
        <div class="drawer-row">
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>Family Name</mat-label>
            <input
              name="Family Name"
              matInput
              aria-label="Family Name"
              formControlName="family_name"
              [readonly]="!isEdit" />
          </mat-form-field>
        </div>
        <div class="drawer-row">
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>Status</mat-label>
            <ng-container *ngIf="!isEdit; else editStatus">
              <input
                name="status"
                matInput
                aria-label="Status"
                [value]="statusList | getById: driverDetails?.status : 'name'"
                readonly />
            </ng-container>
            <ng-template #editStatus>
              <mat-select
                formControlName="status"
                name="status"
                aria-label="Status">
                <mat-option
                  *ngFor="let status of statusList"
                  [value]="status.id">
                  {{ status.name }}
                </mat-option>
              </mat-select>
            </ng-template>
          </mat-form-field>
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>Country Code</mat-label>
            <input
              name="Country Code"
              matInput
              aria-label="Country Code"
              formControlName="country_code"
              [readonly]="!isEdit" />
          </mat-form-field>
        </div>
      </div>

      <div class="drawer-group">
        <div class="drawer-title"><span>Identification</span></div>
        <div class="drawer-row" formGroupName="identification">
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>Identification Type</mat-label>
            <ng-container *ngIf="!isEdit; else editIdentification">
              <input
                name="type"
                matInput
                aria-label="Identification Type"
                [value]="
                  identificationList
                    | getById: driverDetails?.identification?.type : 'name'
                "
                readonly />
            </ng-container>
            <ng-template #editIdentification>
              <mat-select
                hideRequiredMarker
                formControlName="type"
                name="type"
                aria-label="Identification Type">
                <mat-option
                  *ngFor="let identity of identificationList"
                  [value]="identity.id">
                  {{ identity.name }}
                </mat-option>
              </mat-select>
            </ng-template>
          </mat-form-field>
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>ID</mat-label>
            <input
              name="identification"
              matInput
              aria-label="ID"
              formControlName="identification"
              [readonly]="!isEdit" />
          </mat-form-field>
        </div>
      </div>

      <div class="drawer-group">
        <div class="drawer-title"><span>Address Details</span></div>
        <div class="drawer-row">
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }"
            style="width: 100%">
            <mat-label>Residential Address</mat-label>
            <input
              name="Residential Address"
              matInput
              aria-label="Residential Address"
              formControlName="residential_address"
              [readonly]="!isEdit" />
          </mat-form-field>
        </div>
        <div class="drawer-row">
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }"
            style="width: 100%">
            <mat-label>Mailing Address</mat-label>
            <input
              name="Mailing Address"
              matInput
              aria-label="Mailing Address"
              formControlName="mailing_address"
              [readonly]="!isEdit" />
          </mat-form-field>
        </div>
      </div>

      <div class="drawer-group" formArrayName="contact_numbers">
        <div class="drawer-title"><span>Contact Details</span></div>
        <div
          class="drawer-row"
          *ngFor="let contactFields of contactDetails?.controls; let i = index"
          formGroupName="{{ i }}">
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>Contact Type</mat-label>
            <ng-container *ngIf="!isEdit">
              <input
                name="type"
                matInput
                aria-label="Contact Type"
                [value]="
                  contactList | getById: contactFields.value.type : 'name'
                "
                readonly />
            </ng-container>
            <ng-container *ngIf="isEdit">
              <mat-select
                formControlName="type"
                name="type"
                aria-label="Contact Type">
                <mat-option
                  *ngFor="let contactType of contactList"
                  [value]="contactType.id">
                  {{ contactType.name }}
                </mat-option>
              </mat-select>
            </ng-container>
          </mat-form-field>
          <mat-form-field
            hideRequiredMarker
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always"
            [ngClass]="{ 'read-only': !isEdit }">
            <mat-label>{{ i > 0 ? 'Secondary' : 'Primary' }} Contact</mat-label>
            <input
              name="contact"
              matInput
              aria-label="Contact"
              formControlName="value"
              [readonly]="!isEdit" />
          </mat-form-field>
          <div class="button-container">
            <button
              [ngClass]="{ 'd-none': !isEdit }"
              matTooltip="Remove Contact"
              mat-icon-button
              type="button"
              color="warn"
              (click)="onRemoveContactDetail(i)"
              [disabled]="isLoading">
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </div>
        <div
          class="drawer-row"
          *ngIf="isEdit && !(contactDetails?.controls?.length >= 2)">
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="onAddContactDetail()"
            [disabled]="contactDetails?.controls?.length >= 2">
            Add Contact
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="drawer-footer" *ngIf="isEdit">
    <button mat-button type="button" (click)="clickCancel()">Discard</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      form="driver-detail-form"
      [disabled]="!parentForm.valid">
      Save
    </button>
  </div>
</div>
