<div class="info-modal">
  <h1 mat-dialog-title>{{dataDialog.header}}</h1>
  <div mat-dialog-content class="body-content">
    <!-- {{dataDialog.message}} -->
    You want to continue your session? Automatically logout in {{timer}} seconds.
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button (click)="onCloseModal()" cdkFocusInitial>{{dataDialog.textConfirm === undefined ? 'Close' : dataDialog.textConfirm}}</button>
    <button mat-button (click)="onConfirm()">{{dataDialog.textCancel === undefined ? 'Okay' : dataDialog.textCancel}}</button>
  </div>
</div>