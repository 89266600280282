import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import { LocalDataSource } from 'ng2-smart-table';
import { VmService } from '../vm.service';
import { promise } from 'protractor';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/component/shared/services/modal/modal.component';
// import { SmartTableDatePickerComponent } from 'src/app/component/shared/services/smart-table-date-picker/smart-table-date-picker.component';
import { VmJoinDatePickerComponent } from './vm-join-date-picker/vm-join-date-picker.component';
import { VmTemporaryDropdownComponent } from './vm-temporary-dropdown/vm-temporary-dropdown.component';
import { ActivatedRoute, Router } from '@angular/router';

export class modalConfirmData {
  target: string;
  title: string;
  body: string;
  footer: string;

  constructor() {
    this.target = '';
    this.title = '';
    this.body = '';
    this.footer = '';
  }
}

@Component({
  selector: 'app-vm-driver',
  templateUrl: './vm-driver.component.html',
  styleUrls: ['./vm-driver.component.scss']
})
export class VmDriverComponent implements OnInit {
  modalAddDriverData = {};

  ownerSettings = {};
  typeSettings = {};
  vehicleSettings = {};
  propertySettings = {};

  // vehicleData:any = new LocalDataSource();
  // vehicleOwnerData:any = new LocalDataSource();
  // vehicleTypesData:any = new LocalDataSource();
  // vehiclePropertyData:any = new LocalDataSource();

  isGetAllVehicle: boolean = false;
  isGetAllOwner: boolean = false;
  isGetAllVehicleTypes: boolean = false;
  isGetAllVehicleProperty: boolean = false;
  isNewWindow: boolean = false;
  // confirmModalData: modalConfirmData = new modalConfirmData();
  // @ViewChild('btnModal') btnModal: ElementRef;

  constructor(private vmService: VmService, private datePipe: DatePipe, private modalService: NgbModal, private route: ActivatedRoute, private router: Router) { 
    // this.source = this.data;
    // this.source = new LocalDataSource(this.data);
    // this.source.setPaging({ 'page': 1, 'perPage': 2, });
    // this.source = new LocalDataSource();
  }

  ngOnInit() {
    this.initDriverModal();
    this.initVehicleOwner();
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
  }

  // getPaging() {
  //   this.source.setPaging(2, 10, true);
  //   console.log('getpaging');
  // }
  
  // configurationsColumnMap = new Map([["date", ""]]) //put here the list of mandatory columns

  onEditConfirm(event) {
    // console.log(event);
    let closeResult: boolean;
    this.modalService.open(ModalComponent, { centered: true }).result
    .then(
      (result) => {
        // closeResult = result == 'OK'? true : false; console.log('result');
        // console.log(result);
        // event.confirm.resolve(event.newData);
        let oldData = JSON.stringify(event.data);
        let newData = JSON.stringify(event.newData);
        let index = -1;
        let invalid = false;
        let isTemp = '';
        let lastDutyDate = '';
    
       if (oldData != newData) { //uncomment if you want to run when there is a change!
    
        for (var i = 0; i < event.source.data.length; i++) {
          if (JSON.stringify(event.source.data[i]) == oldData) {
            index = i;
            break;
          }
        }
  
        var cells = document.getElementsByTagName('tr').item(index + 2).getElementsByClassName('form-control ng-valid');
        for (var i = 0; i < cells.length; i++) {
          let placeholder = cells[i].getAttribute("placeholder");
          let joinDate = '';
          var inputValue = (<HTMLInputElement>cells[i]).value;
          

          if(i === 7) {
            // console.log( 'inputValue ' + inputValue );
            isTemp = inputValue;
          }
          else if(i === 8) {
            lastDutyDate = inputValue;
          }
          else {
            console.log( 'index ' + i );
          }
        }

        if(isTemp === 'Y' && lastDutyDate === '') {
          cells[8].classList.add('mandatory-cell');
          invalid = true;
          event.confirm.reject();
        }
        else {
          event.confirm.resolve(event.newData);
        }
        // console.log( 'isTemp ' + isTemp );
        // console.log( 'lastDutyDate ' + lastDutyDate );
      }  
      else{ 
        event.confirm.resolve();
      }

      },
      (reason) => {
        closeResult = false; console.log('cross');
      }
    );
  }

  onDeleteConfirm(event) {
    let closeResult: boolean;
    this.modalService.open(ModalComponent, { centered: true }).result
    .then(
      (result) => {
        // closeResult = result == 'OK'? true : false; console.log('result');
        // console.log(result);
        event.confirm.resolve(event.newData);
      },
      (reason) => {
        closeResult = false; console.log('cross');
      }
    );
  }

  initDriverModal() {
    this.modalAddDriverData = {
      'modalTarget': 'modalAddDriver',
      'title': 'Driver Registration',
      'body': '<app-pm-driver-registration></app-pm-driver-registration>'
    }
    console.log(this.modalAddDriverData);
  }

  addDriver(event){//:promise<any> {
    // this.source = new LocalDataSource();
    // let newData = event; 2019-05-09T14:15:25.123Z
    // console.log(event.joiningDate.year + '-' + event.joiningDate.month + '-' + event.joiningDate.day);
    let address = event.address.block + ' ' + event.address.street + ' ' + event.address.floorUnit + ' ' + event.address.postal;
    let lastDuty = '';
    if(event.lastDutyDate) {
      lastDuty = this.datePipe.transform(new Date(event.lastDutyDate.year + '-' + event.lastDutyDate.month + '-' + event.lastDutyDate.day), 'yyyy-MM-ddThh:mm:ss.SSSZ');
    }
    let joiningDate = this.datePipe.transform(new Date(event.joiningDate.year + '-' + event.joiningDate.month + '-' + event.joiningDate.day), 'yyyy-MM-ddThh:mm:ss.SSSZ');
    let newData = {
      "address": address,
      "driverId": event.driverId,
      "driverStatus": event.driverStatus,
      "firstName": event.firstName,
      "identificationDoc": event.identificationDoc,
      "identificationId": event.identificationId,
      "isTemporary": event.isTemporary,
      "joiningDate": joiningDate,
      "lastDutyDate": lastDuty,
      "lastName": event.lastName,
      "middleName": event.middleName,
      "primaryPhone": event.primaryPhone,
      "recId": '3',
      "secondaryPhone": event.secondaryPhone
    };
    
    // if(typeof this.vehicleData.data === 'undefined') {
    //   this.vehicleData.unshift(newData);
    //   this.vehicleData = new LocalDataSource(this.vehicleData);
    // }
    // else {
    //   this.vehicleData.data.unshift(newData);
    //   this.vehicleData = new LocalDataSource(this.vehicleData.data);
    // }
  }

  closeModalAction(event){
    console.log(event);
  }
  okayModalAction(event){
    console.log(event);
  }

  initVehicleOwner() {
    this.isGetAllVehicle = false;
    this.isGetAllVehicleProperty = false;
    this.isGetAllOwner = false;
    this.isGetAllVehicleTypes = false;
    this.vmService.getAllVehicleOwner().then(
      (ret:any) => {
        // this.vehicleOwnerData = ret;
        // console.log(this.vehicleOwnerData);
        // return false;

        this.ownerSettings = {
          selectMode: 'multi',
          pager: {
            display: true,
            perPage: 10
          },
          actions: {
            add: false,
            edit: true,
            delete: true,
            position: 'right',
          },
          edit: {
            editButtonContent: '<i class="fa fa-edit"></i>',
            saveButtonContent: '<i class="fa fa-check-circle"></i>',
            cancelButtonContent: '<i class="fa fa-times-circle"></i>',
            confirmSave: true
          },
          delete: {
            deleteButtonContent: '<i class="fa fa-trash"></i>',
            confirmDelete: true
          },
          columns: {
            // ownerId: {
            //   title: 'Owner Id',
            //   placeholder: 'Search'
            // },
            name: {
              title: 'Name',
              placeholder: 'Search'
            },
            abbreviation: {
              title: 'Abbreviation',
              placeholder: 'Search'
            }
          }
        };
        this.isGetAllOwner = true;
      }
    );
  }

  initVehicleTypes() {
    this.isGetAllVehicle = false;
    this.isGetAllVehicleProperty = false;
    this.isGetAllOwner = false;
    this.isGetAllVehicleTypes = false;
    this.vmService.getAllVehicleTypes().then(
      (ret:any) => {
        // this.vehicleTypesData = ret;
        // console.log(this.isGetAllVehicleTypes);
        // return false;

        this.typeSettings = {
          selectMode: 'multi',
          pager: {
            display: true,
            perPage: 10
          },
          actions: {
            add: false,
            edit: true,
            delete: true,
            position: 'right',
          },
          edit: {
            editButtonContent: '<i class="fa fa-edit"></i>',
            saveButtonContent: '<i class="fa fa-check-circle"></i>',
            cancelButtonContent: '<i class="fa fa-times-circle"></i>',
            confirmSave: true
          },
          delete: {
            deleteButtonContent: '<i class="fa fa-trash"></i>',
            confirmDelete: true
          },
          columns: {
            vehicleType: {
              title: 'Type',
              placeholder: 'Search'
            },
            operationOwner: {
              title: 'Owner',
              placeholder: 'Search'
            },
            modelName: {
              title: 'Model Name',
              placeholder: 'Search'
            },
            modelDescription: {
              title: 'Description',
              placeholder: 'Search'
            },
            noOfAxles: {
              title: 'No. Axles',
              placeholder: 'Search'
            },
            vehicleLength: {
              title: 'Length',
              placeholder: 'Search'
            },
            vehicleWidth: {
              title: 'Width',
              placeholder: 'Search'
            },
            turnRadius: {
              title: 'Turn Radius',
              placeholder: 'Search'
            },
            vehicleWeight: {
              title: 'Weight',
              placeholder: 'Search'
            },
            gauge: {
              title: 'Gauge',
              placeholder: 'Search'
            },
            voltage: {
              title: 'Voltage',
              placeholder: 'Search'
            },
            noOfSeats: {
              title: 'Seats',
              placeholder: 'Search'
            },
            noOfStanding: {
              title: 'Standing',
              placeholder: 'Search'
            },
            noOfDisabledSeats: {
              title: 'Disab. Seats',
              placeholder: 'Search'
            }
            // disabledTransport: {
            //   title: 'Transport of people with disabilities',
            //   placeholder: 'Search'
            // }
          }
        };
        this.isGetAllVehicleTypes = true;
      }
    );
  }

  initVehicle() {
    this.isGetAllVehicle = false;
    this.isGetAllVehicleProperty = false;
    this.isGetAllOwner = false;
    this.isGetAllVehicleTypes = false;

    this.vmService.getAllVehicle().then(
      (ret:any) => {
        // this.vehicleData = ret;
        // console.log(this.source);
        // return false;

        this.vehicleSettings = {
          selectMode: 'multi',
          pager: {
            display: true,
            perPage: 10
          },
          actions: {
            add: false,
            edit: true,
            delete: true,
            position: 'right',
          },
          edit: {
            editButtonContent: '<i class="fa fa-edit"></i>',
            saveButtonContent: '<i class="fa fa-check-circle"></i>',
            cancelButtonContent: '<i class="fa fa-times-circle"></i>',
            confirmSave: true
          },
          delete: {
            deleteButtonContent: '<i class="fa fa-trash"></i>',
            confirmDelete: true
          },
          columns: {
            techVehicleNo: {
              title: 'Tech Vehicle No.',
              placeholder: 'Search'
            },
            registrationNo: {
              title: 'Registration No.',
              placeholder: 'Search'
            },
            vehicleType: {
              title: 'Type',
              placeholder: 'Search'
            },
            destDispType: {
              title: 'Vehicle Dest Display Type',
              placeholder: 'Search'
            },
            owner: {
              title: 'Owner',
              placeholder: 'Search'
            },
            wheelChairliftAvailable: {
              title: 'Wheel Ch. Lift',
              placeholder: 'Search'
            },
            supplyGroup: {
              title: 'Supply Group',
              placeholder: 'Search'
            },
            transportAuthority: {
              title: 'Transport Authority',
              placeholder: 'Search'
            },
            constructionYear: {
              title: 'Construction Year',
              placeholder: 'Search',
              valuePrepareFunction: (value) => {
                if(value) {
                  return this.datePipe.transform(new Date(value), 'dd MMM yyyy');
                }
                else {
                  return null;
                }
              },
              type: 'html',
              editor: {type: 'custom', component: VmJoinDatePickerComponent}
            },
            targetDevices: {
              title: 'Target Devices',
              placeholder: 'Search'
            },
            baseDepot: {
              title: 'Vehicle Base Depot',
              placeholder: 'Search'
            }
          }
        };
        this.isGetAllVehicle = true;
      }
    );
  }


  // isGetAllVehicle: boolean = false;
  // isGetAllOwner: boolean = false;
  // isGetAllVehicleTypes: boolean = false;
  // isGetAllVehicleProperty: boolean = false;
  // ownerSettings = {};
  // typeSettings = {};
  // vehicleSettings = {};
  // propertySettings = {};
  initVehicleProperty() {
    this.isGetAllVehicleProperty = false;
    this.isGetAllVehicle = false;
    this.isGetAllOwner = false;
    this.isGetAllVehicleTypes = false;

    this.vmService.getAllVehicleProperty().then(
      (ret:any) => {
        // this.vehiclePropertyData = ret;
        // console.log(this.vehicleOwnerData);
        // return false;

        this.propertySettings = {
          selectMode: 'multi',
          pager: {
            display: true,
            perPage: 10
          },
          actions: {
            add: false,
            edit: true,
            delete: true,
            position: 'right',
          },
          edit: {
            editButtonContent: '<i class="fa fa-edit"></i>',
            saveButtonContent: '<i class="fa fa-check-circle"></i>',
            cancelButtonContent: '<i class="fa fa-times-circle"></i>',
            confirmSave: true
          },
          delete: {
            deleteButtonContent: '<i class="fa fa-trash"></i>',
            confirmDelete: true
          },
          columns: {
            propertyName: {
              title: 'Name',
              placeholder: 'Search'
            },
            abbreviation: {
              title: 'Abbreviation',
              placeholder: 'Search'
            },
            propertyGroup: {
              title: 'Property Group',
              placeholder: 'Search'
            },
            remarks: {
              title: 'Remarks',
              placeholder: 'Search'
            }
          }
        };
        this.isGetAllVehicleProperty = true;
      }
    );
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/vehicle-management/vehicle/2','personnel-management','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}