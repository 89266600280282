<div class="loader-group" *ngIf="!isGetAllStops">
    <p-progressSpinner></p-progressSpinner>
</div>

<ng-container *ngIf="isGetAllStops">
    <p-table #dt [columns]="cols" [value]="stopsData" [paginator]="true" [rows]="10" dataKey="stopId" editMode="row" class="stops-table" 

    currentPageReportTemplate="{currentPage} of {totalPages}"
        (onPage)="onPaginate($event)"
        >
        
        <!-- 
            showCurrentPageReport="true"
            [(selection)]="selectedStop" 
            [scrollable]="true" scrollHeight="27vh"
        -->
        <!-- lazyload attribute -->
        <!-- [lazy]="true" (onLazyLoad)="loadCarsLazy($event)" [loading]="loading" [totalRecords]="totalRecords" --> 
        <!-- <ng-template pTemplate="caption">
            <div style="text-align: right">        
                <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
        </ng-template> -->
        <ng-template pTemplate="paginatorright" let-state> Total elements : {{ dt .totalRecords }} </ng-template>

        <ng-template pTemplate="header" let-columns>
        <tr>
            <th class="td-checkbox">&nbsp;</th>
            <th *ngFor="let col of columns" class=td-{{col.field}}>
                {{col.header}}
            </th>
            <th class="td-action">Actions</th>
        </tr>
        <tr>
            <th class="td-checkbox">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [ngSwitch]="col.field" class=td-{{col.field}}>
                <input *ngSwitchCase="'busStopName'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <!-- <input *ngSwitchCase="'busStopName'" pInputText class="form-control" type="text" (input)="dt.filter($event, col.field)"> -->
                <!-- <input (click)="$event.stopPropagation()" [style.width]="'90%'" *ngSwitchCase="'busStopName'" pInputText type="text" (keyup.enter)="columnFilter($event, col.field)"> -->
                
            </th>
            <th>&nbsp;</th>
        </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData" [pSelectableRow]="rowData">
                <td class="td-checkbox">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>

                <td class="td-busStopName">
                    {{rowData.busStopName}}
                </td>
                
                <!-- <td class="td-busStopName">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="rowData.busStopName" class="form-control" required>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{rowData.busStopName}}
                        </ng-template>
                    </p-cellEditor>
                </td> -->

                <td  class="td-action">
                    <button pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" (click)="onRowSelect(rowData)"></button>
                    <!-- <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" (click)="onRowEditInit(rowData)"></button>
                    <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check" class="ui-button-success" (click)="onRowEditSave(rowData)"></button>
                    <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times" class="ui-button-danger" (click)="onRowEditCancel(rowData, ri)"></button> -->
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- <p-paginator [rows]="10" [totalRecords]="120" [rowsPerPageOptions]="[10,20,30]"></p-paginator> -->
    <!-- <p-paginator [rows]="5" totalRecords="100" (onPageChange)="onPaginate($event)"></p-paginator> -->
</ng-container>


<!-- <p-dialog header="Stop Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"  [style]="{width: 'auto'}"> -->
    <form [formGroup]="stopForm" (ngSubmit)="onSubmit()">
        <div class="ui-g1 ui-fluid" *ngIf="stop">
            <div class="form-group row1">
                <div class="1">
                    <div id="map1" class="map1"></div>
                </div>
            </div>
        
            <div class="form-row">
                <div class="form-group col">
                    <label>Stop Name</label>
                    <!-- <input type="text" formControlName="busStopName" class="form-control" [(ngModel)]="stop.busStopName" [ngClass]="{ 'is-invalid': isvalid && f.busStopName.errors }" /> -->
                    <div *ngIf="isvalid && f.busStopName.errors" class="invalid-feedback">
                        <div *ngIf="f.busStopName.errors.required">Stop name is required</div>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Longitude</label>
                    <!-- <input type="text" formControlName="busStopLon" class="form-control" [(ngModel)]="stop.busStopLon" [ngClass]="{ 'is-invalid': isvalid && f.busStopLon.errors }" /> -->
                    <div *ngIf="isvalid && f.busStopLon.errors" class="invalid-feedback">
                        <div *ngIf="f.busStopLon.errors.required">Stop longitude is required</div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label>Latitude</label>
                    <!-- <input type="text" formControlName="busStopLat" class="form-control" [(ngModel)]="stop.busStopLat" [ngClass]="{ 'is-invalid': isvalid && f.busStopLat.errors }" /> -->
                    <div *ngIf="isvalid && f.busStopLat.errors" class="invalid-feedback">
                        <div *ngIf="f.busStopLat.errors.required">Stop latitude is required</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <p-footer> -->
            <div class="ui-dialog-buttonpane ui-helper-clearfix text-right">
                <!-- <button type="button" pButton icon="fa fa-close" label="Delete"></button> -->
                <button type="submit" pButton icon="fa fa-check" label="Save"></button>
            </div>
        <!-- </p-footer> -->
    </form>
<!-- </p-dialog> -->