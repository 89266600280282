import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'service-modal.html',
})
export class ServiceDialog {

  constructor(
    public dialogRef: MatDialogRef<ServiceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  modalEvent(res): void {
    this.dialogRef.close(res);
  }
}

// export class ModalComponent implements OnInit {
//   @Input() data:any;
//   @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
//   @Output() okayAction: EventEmitter<any> = new EventEmitter<any>();

//   modalId:string = null;
//   bodyModal: string;

//   constructor(config: NgbModalConfig, public modal: NgbActiveModal) { 
//     config.backdrop = 'static';
//     config.keyboard = false;
//   }

//   ngOnInit() {
//     console.log(this.data);
//     // this.modalId = this.data.target;
//     // this.bodyModal = this.data.body;
    
//   }
// }