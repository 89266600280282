import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatTable, MatDialog, MatDialogConfig, MatSnackBar, MatSort } from '@angular/material';
import { DriverElement } from '../../personnel-management/pm-driver-mat/pm-driver.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { DriverElement, PmDriverService } from './pm-driver.service';
// import { PmDriverEditComponent } from './pm-driver-edit/pm-driver-edit.component';

@Component({
  selector: 'app-masterdata-drivers',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss'],
})
export class MasterDataDriversComponent implements OnInit {
  dataSource: MatTableDataSource<DriverElement>;

  displayedColumns: string[] = [
    'driverId',
    'name',
    'licenseNumber',
    'phone1',
    'phone2',
    'company',
    'depot',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  isNewWindow: boolean = false;

  @ViewChild('chatPS', { static: true }) chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  allDrivers: any = [
    {
      driverId: '1300468',
      name: 'MUAHMMED TAHIR RASHID',
      phone1: '0504237565',
      phone2: '0504237565',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN329510',
    },
    {
      driverId: '1300475',
      name: 'DILDAR HUSSAIN HAJI',
      phone1: '0502141845',
      phone2: '0502141845',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN418781',
    },
    {
      driverId: '1300485',
      name: 'ALI RAHMANI ABDUL',
      phone1: '060054545',
      phone2: '0509442291',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN762294',
    },
    {
      driverId: '1300383',
      name: 'HUSSAIN KHAN UMAR',
      phone1: '0506863508',
      phone2: '0506863508',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN990959',
    },
    {
      driverId: '1102317',
      name: 'ALAA ALDEN RASHED',
      phone1: '060054545',
      phone2: '0503724370',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN567855',
    },
    {
      driverId: '1101697',
      name: 'BASHIR H.S. ALI',
      phone1: '060054545',
      phone2: '0501223586',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN567335',
    },
    {
      driverId: '1101296',
      name: 'MUHAMMAD IQBAL',
      phone1: '060054545',
      phone2: '0502210460',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN788170',
    },
    {
      driverId: '2200465',
      name: 'Inayat Khan Gul Bad Shah',
      phone1: '0506303310',
      phone2: '0506303310',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN591026',
    },
    {
      driverId: '2200936',
      name: 'Asmat Ali Ali Noor',
      phone1: '0522198458',
      phone2: '0522198458',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN633438',
    },
    {
      driverId: '2200606',
      name: 'Rafiq Ahmad Abdul Rehman',
      phone1: '0504292784',
      phone2: '0504292784',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN530226',
    },
    {
      driverId: '2201288',
      name: 'Rukedin Sarwar Din',
      phone1: '0502106991',
      phone2: '0502106991',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN414485',
    },
    {
      driverId: '2201601',
      name: 'ZIA UD DIN MOHAMED',
      phone1: '0557152071',
      phone2: '0557152071',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN215469',
    },
    {
      driverId: '2200729',
      name: 'Ghani Ur Rahman Abdul Rahman',
      phone1: '0508059542',
      phone2: '0508059542',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN949824',
    },
    {
      driverId: '2200666',
      name: 'Farin Khan Saddar Khan',
      phone1: '0504799024',
      phone2: '0504799024',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN319942',
    },
    {
      driverId: '2200734',
      name: 'Qamar Zaman Abbas Khan',
      phone1: '0507370463',
      phone2: '0507370463',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN324531',
    },
    {
      driverId: '2200826',
      name: 'Abdul Qadir Khan Sharif',
      phone1: '0601234567',
      phone2: '0504809659',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN180274',
    },
    {
      driverId: '2201224',
      name: 'Saidalavi Chekkamadath',
      phone1: '0601234567',
      phone2: '0504807101',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN487326',
    },
    {
      driverId: '1100366',
      name: 'Nazar Mohd Ghulam Mohd',
      phone1: '0503058696',
      phone2: '0503058696',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN690419',
    },
    {
      driverId: '2200793',
      name: 'SHAMSHI REHMAN MIR AFZAL',
      phone1: '0558846417',
      phone2: '0558846417',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN172503',
    },
    {
      driverId: '2201092',
      name: 'AJMAL SHAH ABDUR RAHMAN',
      phone1: '0503026283',
      phone2: '0503026283',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN841590',
    },
    {
      driverId: '1101888',
      name: 'JAVID HUSSAIN AHMAD ALI',
      phone1: '0501188497',
      phone2: '0501188497',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN529149',
    },
    {
      driverId: '1102276',
      name: 'NAEEM BADSHAH HAJI',
      phone1: '0509391686',
      phone2: '0509391686',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN178034',
    },
    {
      driverId: '1102641',
      name: 'Khorshid Ali MuhAMMAD',
      phone1: '0524217488',
      phone2: '0524217488',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN662523',
    },
    {
      driverId: '1101253',
      name: 'Mohammed Yousuf Mohammed',
      phone1: '0558088947',
      phone2: '0558088947',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN617567',
    },
    {
      driverId: '2201363',
      name: 'ASHRAF MOIDU HAJI',
      phone1: '0552116050',
      phone2: '0552116050',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      licenseNumber: 'LN662574',
    },
    {
      driverId: '2200853',
      name: 'Qadir Bakshsh Khan Mohd',
      phone1: '0509787466',
      phone2: '0509787466',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN769356',
    },
    {
      driverId: '2200390',
      name: 'Iftkhar Ali Fida Ali',
      phone1: '0508908579',
      phone2: '0508908579',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN510111',
    },
    {
      driverId: '2201025',
      name: 'Gopinathan Chakrath',
      phone1: '0601234567',
      phone2: '0508981781',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN575826',
    },
    {
      driverId: '2200519',
      name: 'Padoor Velayudhan Vinothan',
      phone1: '0503353406',
      phone2: '0503353406',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN736162',
    },
    {
      driverId: '2200575',
      name: 'Surab Ali Aptab Ali',
      phone1: '0502214407',
      phone2: '0502214407',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN516860',
    },
    {
      driverId: '2200914',
      name: 'Sajaws Khan Anvar Gul',
      phone1: '0601234567',
      phone2: '0509685682',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN358931',
    },
    {
      driverId: '2200553',
      name: 'MASAM KHAN MURAD KHAN',
      phone1: '0502528368',
      phone2: '0502528368',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN376167',
    },
    {
      driverId: '2200867',
      name: 'Said Wanin Khan',
      phone1: '0503085013',
      phone2: '0503085013',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN825989',
    },
    {
      driverId: '2200113',
      name: 'P. L. Sudheer',
      phone1: '0601234567',
      phone2: '0507142180',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN432462',
    },
    {
      driverId: '2200491',
      name: 'Chaudhry Bashir Ahmed',
      phone1: '0601234567',
      phone2: '0502560383',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      licenseNumber: 'LN768044',
    },
    {
      driverId: '2200405',
      name: 'Hazrat Sher Ghafor Khan',
      phone1: '0503523267',
      phone2: '0503523267',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN614945',
    },
    {
      driverId: '2200875',
      name: 'Yousof Ali Shah Haji',
      phone1: '0502047698',
      phone2: '0502047698',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN168630',
    },
    {
      driverId: '2200964',
      name: 'Gul Haider Gulam  Haider',
      phone1: '0601234567',
      phone2: '0502278082',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN886058',
    },
    {
      driverId: '1300396',
      name: 'SHAKIR ULLAH AYUB',
      phone1: '060054545',
      phone2: '0509605167',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN941798',
    },
    {
      driverId: '1102322',
      name: 'MIR ABASS KHAN GUL',
      phone1: '0502776805',
      phone2: '0502776805',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN242860',
    },
    {
      driverId: '1101460',
      name: 'BABAR KHAN ROSHAM KHAN',
      phone1: '0506970644',
      phone2: '0506970644',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN250712',
    },
    {
      driverId: '2200923',
      name: 'Rehman Gul Bakhtiar Gul',
      phone1: '0505025922',
      phone2: '0505025922',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN404683',
    },
    {
      driverId: '2200869',
      name: 'AJMAT GUL NAYAT MIR',
      phone1: '065334444',
      phone2: '0503089460',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN921154',
    },
    {
      driverId: '2200501',
      name: 'Atta Khan Hakim Khan',
      phone1: '0601234567',
      phone2: '0505393174',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN626608',
    },
    {
      driverId: '2200933',
      name: 'Jahangir Alam Saleh Ahmad',
      phone1: '0601234567',
      phone2: '0502011936',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      licenseNumber: 'LN793799',
    },
    {
      driverId: '2201191',
      name: 'Padathu Parambil Subru',
      phone1: '0505902584',
      phone2: '0505902584',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN939561',
    },
    {
      driverId: '2200934',
      name: 'Shaukat Hussain Said',
      phone1: '0502183684',
      phone2: '0502183684',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN500745',
    },
    {
      driverId: '1102325',
      name: 'HAZRAT ALI AWAL GHANI',
      phone1: '0566321426',
      phone2: '0566321426',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN977606',
    },
    {
      driverId: '2200824',
      name: 'Mustafa Alarpat',
      phone1: '0601234567',
      phone2: '0508296358',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN217473',
    },
    {
      driverId: '2200673',
      name: 'MADHAVAN SOMAN MADHAVAN PILLAI',
      phone1: '0502344765',
      phone2: '0502344765',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN868836',
    },
    {
      driverId: '2200565',
      name: 'Amjad Pervaiz Haji Din Mohammed',
      phone1: '0504970278',
      phone2: '0504970278',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN745006',
    },
    {
      driverId: '2200453',
      name: 'Umar Haji Saifulla',
      phone1: '0502434527',
      phone2: '0502434527',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN439610',
    },
    {
      driverId: '2200469',
      name: 'Fazal Gafoor Abid Gafoor',
      phone1: '0553856377',
      phone2: '0553856377',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN735724',
    },
    {
      driverId: '2201678',
      name: 'FAZLUL HOQUE NURZAMAN',
      phone1: '0503571592',
      phone2: '0503571592',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN554385',
    },
    {
      driverId: '2201688',
      name: 'MUHAMMAD OONAS HAJI GULISTAN',
      phone1: '0508586995',
      phone2: '0508586995',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      licenseNumber: 'LN239031',
    },
    {
      driverId: '2201691',
      name: 'BAHAR GUL WAHEED GUL',
      phone1: '0501624925',
      phone2: '0501624925',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN933399',
    },
    {
      driverId: '2201689',
      name: 'ZAR WALI KHAN NOOR WALI KHAN',
      phone1: '0509443741',
      phone2: '0509443741',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN830482',
    },
    {
      driverId: '2202024',
      name: 'PRODIP MALIKAR PARITUSH MALIKAR',
      phone1: '0504197022',
      phone2: '0504197022',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN226890',
    },
    {
      driverId: '2201916',
      name: 'MUJAHID HUSSAIN NOOR',
      phone1: '0508834509',
      phone2: '0508834509',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN904409',
    },
    {
      driverId: '2201736',
      name: 'MAZHAR IQBAL KHIZAR HAYAT',
      phone1: '0506398752',
      phone2: '0506398752',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN456248',
    },
    {
      driverId: '2201718',
      name: 'GUL ZAMIN SHAH AMIN SHAH',
      phone1: '0552945868',
      phone2: '0552945868',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN449017',
    },
    {
      driverId: '2202027',
      name: 'Syed Khalid Mehmood Shah fazal ',
      phone1: '0507867825',
      phone2: '0507867825',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN909527',
    },
    {
      driverId: '2202005',
      name: 'EAKUB AHAMMED AKABBAR ALI',
      phone1: '0601234567',
      phone2: '0501234567',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN701801',
    },
    {
      driverId: '2201914',
      name: 'MUHAMMED SHAH ALAM',
      phone1: '0509082143',
      phone2: '0509082143',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN613790',
    },
    {
      driverId: '2201961',
      name: 'NANDE DANGI NAR',
      phone1: '0553348475',
      phone2: '0553348475',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN586132',
    },
    {
      driverId: '2202001',
      name: 'SATYA NARAYAN GWACHHA SATYA',
      phone1: '0557935722',
      phone2: '0557935722',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      licenseNumber: 'LN733012',
    },
    {
      driverId: '2201991',
      name: 'MOHAMMAD ISLAM MOHAMMAD AKBER ALI',
      phone1: '0562539435',
      phone2: '0562539435',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN194242',
    },
    {
      driverId: '2201987',
      name: 'ALAMIN LATE SHAMSUL HOQUE',
      phone1: '0553742824',
      phone2: '0553742824',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN892859',
    },
    {
      driverId: '2201731',
      name: 'KHALID MUHAMMAD WALI MUHAMMAD',
      phone1: '0502042899',
      phone2: '0502042899',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN451917',
    },
    {
      driverId: '2201995',
      name: 'UDDHAB POKHAREL',
      phone1: '0501638072',
      phone2: '0501638072',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN420753',
    },
    {
      driverId: '2201922',
      name: 'SHAH IRAN BADSHAH AMIN',
      phone1: '0601234567',
      phone2: '0552311913',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN922228',
    },
    {
      driverId: '2201772',
      name: 'WAHAB GUL MOHTARAM GUL',
      phone1: '0508981270',
      phone2: '0508981270',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN705965',
    },
    {
      driverId: '2201747',
      name: 'ANWAR IQBAL NAQIB ULLAH KHAN',
      phone1: '0527488710',
      phone2: '0527488710',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN274014',
    },
    {
      driverId: '2201891',
      name: 'JAN BAHADAR SHER ALAM KHAN',
      phone1: '0552304746',
      phone2: '0552304746',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN858246',
    },
    {
      driverId: '2201973',
      name: 'SHAH SAUD JAN SAID',
      phone1: '0553735022',
      phone2: '0553735022',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN518653',
    },
    {
      driverId: '2201997',
      name: 'SHABBIR AHMED BARKAT',
      phone1: '0553819578',
      phone2: '0553819578',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      licenseNumber: 'LN112170',
    },
    {
      driverId: '2201785',
      name: 'NISAR AHMAD BANAT GUL',
      phone1: '0507329431',
      phone2: '0507329431',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      licenseNumber: 'LN424707',
    },
    {
      driverId: '2201789',
      name: 'MOHD SAHADAT HOSSEN MOHD JOYNAL ABDIN',
      phone1: '0601234567',
      phone2: '0566489033',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      licenseNumber: 'LN728560',
    },
    {
      driverId: '2201975',
      name: 'HAKIM UDDIN MUAHMMED SONA',
      phone1: '0566374759',
      phone2: '0566374759',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      licenseNumber: 'LN116466',
    },
    {
      driverId: '2201984',
      name: 'MUAHMMED DULAL HOSSAIN',
      phone1: '0555761263',
      phone2: '0555761263',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      licenseNumber: 'LN260270',
    },
    {
      driverId: '2201841',
      name: 'ZAR SAID NOOR GHULAM',
      phone1: '0502629848',
      phone2: '0502629848',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      licenseNumber: 'LN658679',
    },
    {
      driverId: '2201828',
      name: 'NASEER ZAMAN MIR',
      phone1: '0553309771',
      phone2: '0553309771',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      licenseNumber: 'LN449922',
    },
    {
      driverId: '2201958',
      name: 'MUHAMMED SHARIF MAUHMMED',
      phone1: '0553833213',
      phone2: '0553833213',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      licenseNumber: 'LN491411',
    },
    {
      driverId: '2201094',
      name: 'Mohd Mawaj Sher Zada',
      phone1: '0502696736',
      phone2: '0502696736',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      licenseNumber: 'LN375900',
    },
    {
      driverId: '2201004',
      name: 'Sajid Ali',
      phone1: '0601234567',
      phone2: '0501234567',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      licenseNumber: 'LN477244',
    },
    {
      driverId: '2201067',
      name: 'Karamat Ullah Gul Amin',
      phone1: '0557094472',
      phone2: '0557094472',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      licenseNumber: 'LN476525',
    },
    {
      driverId: '2201327',
      name: 'GULZAR NABI MOHD NAZIR',
      phone1: '0601234567',
      phone2: '0501277317',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      licenseNumber: 'LN989660',
    },
    {
      driverId: '2201251',
      name: 'FAZAL MIR ALLAH KHAN',
      phone1: '0503533056',
      phone2: '0503533056',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      licenseNumber: 'LN309001',
    },
    {
      driverId: '2201261',
      name: 'MUHAMMAD ISRAR KHAN',
      phone1: '0507389872',
      phone2: '0507389872',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      licenseNumber: 'LN486233',
    },
    {
      driverId: '2201314',
      name: 'BAKHT  RAHIM ABDUL AZIM',
      phone1: '0503917185',
      phone2: '0503917185',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      licenseNumber: 'LN112692',
    },
    {
      driverId: '2201333',
      name: 'MUHAMMAD ALI KHAN JAFAR',
      phone1: '0508981885',
      phone2: '0508981885',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      licenseNumber: 'LN358328',
    },
    {
      driverId: '2201431',
      name: 'EID MUHAMMAD KHAN AZMAT KHAN',
      phone1: '0505195279',
      phone2: '0505195279',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      licenseNumber: 'LN607434',
    },
    {
      driverId: '2201505',
      name: 'ABDUL GHAFOOR MIR SANAD KHAN',
      phone1: '0601234567',
      phone2: '0503024235',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      licenseNumber: 'LN886696',
    },
    {
      driverId: '2202667',
      name: 'MUJAHID ALI KHALID KHAN',
      phone1: '0559081971',
      phone2: '0559081971',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      licenseNumber: 'LN795814',
    },
    {
      driverId: '2201183',
      name: 'Gul Sher Khan Sharin',
      phone1: '0502704183',
      phone2: '0502704183',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      licenseNumber: 'LN129768',
    },
    {
      driverId: '2200773',
      name: 'Riaz Hussain Nabi Bakhsh',
      phone1: '0504973445',
      phone2: '0504973445',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      licenseNumber: 'LN950240',
    },
    {
      driverId: '2201110',
      name: 'KHAIR MOHAMMAD ALLAH WASAYA',
      phone1: '0564248311',
      phone2: '0564248311',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      licenseNumber: 'LN257578',
    },
    {
      driverId: '2201245',
      name: 'Mirza Talib Beg',
      phone1: '0506535437',
      phone2: '0506535437',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      licenseNumber: 'LN144037',
    },
    {
      driverId: '2201283',
      name: 'WAHID ZAMAN SAID FAQIR',
      phone1: '0502008148',
      phone2: '0502008148',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      licenseNumber: 'LN442824',
    },
    {
      driverId: '2201351',
      name: 'FAZAL REHMAN ALMAR KHAN',
      phone1: '0508331485',
      phone2: '0508331485',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      licenseNumber: 'LN997013',
    },
    {
      driverId: '2201282',
      name: 'NOOR SALAM PIAO MIR',
      phone1: '0601234567',
      phone2: '0501234567',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      licenseNumber: 'LN114999',
    },
  ];

  constructor(
    private dialog: MatDialog,
    // private drvService: PmDriverService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // this.dataSource = new MatTableDataSource<DriverElement>(drivers);

    this.dataSource = new MatTableDataSource<any>();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this._snackBar.open('Loading...');

    // let self = this;
    // this.drvService.getAllDriver().subscribe((data: any) => {
    this.dataSource.data = this.allDrivers;
    // this.table.renderRows();

    //   // this._snackBar.dismiss();
    // }, error => {
    //   this._snackBar.open(error.message, null, {
    //     duration: 2000,
    //   });
    // });
    // this.allDrivers.forEach(element => {
    //   element.licenseNumber = 'LN'+Math.floor(100000 + Math.random() * 900000);
    // });
    // console.log(JSON.stringify(this.allDrivers));
  }

  refresh() {
    this._snackBar.open('Refreshing...');

    // this.drvService.getAllDriver().subscribe((data: any) => {
    //   this.dataSource.data = data;
    //   this.table.renderRows();

    //   this._snackBar.dismiss();
    // }, error => {
    //   this._snackBar.open(error.message, null, {
    //     duration: 2000,
    //   });
    // });
  }

  filter(keyword: string) {
    this.dataSource.filter = keyword.trim().toLowerCase();
  }

  // add() {
  //   let drv = {
  //     driverId: null,
  //     fullName: null,
  //     firstName: null,
  //     middleName: null,
  //     lastName: null,
  //     identificationId: null,
  //     identificationDocType: null,
  //     primaryPhone: null,
  //     secondaryPhone: null,
  //     address: null,
  //     joiningDate: null,
  //     isTemporary: 'N',
  //     lastDutyDate: null,
  //     driverStatus: 'NEW'
  //   };

  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.autoFocus = true;
  //   dialogConfig.data = drv;

  //   // this.dialog.open(PmDriverEditComponent, dialogConfig);
  // }

  // edit(drv: DriverElement) {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.data = drv;

  //   // this.dialog.open(PmDriverEditComponent, dialogConfig);
  // }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/master-data/drivers/2',
      'drivers',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }
}
