import { Pipe, PipeTransform } from '@angular/core';

export interface DataId {
  id: string;
  name: string;
}

@Pipe({
  name: 'getById',
  pure: false,
})
export class GetByIdPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {DataId[]} value
   * @param {any} id
   * @param {string} property
   * @returns {any}
   */
  transform(value: DataId[], id: any, property: string = 'name'): any {
    const foundItem = value.find(item => {
      if (item.id !== undefined) {
        return item.id === id;
      }

      return false;
    });

    if (foundItem) {
      return foundItem[property];
    }
    return id;
  }
}
