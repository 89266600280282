import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
// import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { ConfirmModalComponent } from 'src/app/component/shared/services/confirm-modal/confirm-modal.component';
import { AddAccountComponent } from '../add-account/add-account.component';

@Component({
  selector: 'app-group-account',
  templateUrl: './group-account.component.html',
  styleUrls: ['./group-account.component.scss'],
})
export class GroupAccountComponent implements OnInit {
  constructor(
    private router: Router,
    private _commonService: CommonService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public authService: AuthService
  ) {}

  isNewWindow: boolean = false;
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'displayName',
    'agency',
    'roleName',
    'userEmail',
    'supervisorName',
    'isAccountDisabled',
    'isCredentialExpired',
    'isAccountLocked',
    'action',
  ];
  allAccountClone;
  // allAccount:any = [
  // {
  //   'login': 'casper',
  //   'password': '12345',
  //   'name': 'Casper M.',
  //   'company': 'sbst',
  //   'roleId': '11',
  //   'role': 'Controller',
  //   'status': 'Enable',
  //   'expiryDate': '24/04/2021'
  // },
  // {
  //   'login': 'john',
  //   'password': '12345',
  //   'name': 'John V.',
  //   'company': 'smrt',
  //   'roleId': '12',
  //   'role': 'Admin',
  //   'status': 'Enable',
  //   'expiryDate': '16/07/2021'
  // }
  // {
  //   "userName": "admin",
  //   "userEmail": "admin@mail.com",
  //   "roleName": "admin",
  //   "supervisorName": "nicholas",
  //   "isAccountDisabled": true,
  //   "isAccountLocked": false,
  //   "isCredentialExpired": false,
  //   "isAccountDeactivated": false
  // },
  // {
  //     "userName": "agiltrackadmin",
  //     "userEmail": "agiladmin@mail.com",
  //     "roleName": "admin",
  //     "supervisorName": "nicholas",
  //     "isAccountDisabled": true,
  //     "isAccountLocked": false,
  //     "isCredentialExpired": false,
  //     "isAccountDeactivated": false
  // }
  // ];
  pageData = {
    id: 9,
    name: 'userManagement',
    displayPageName: 'User Management',
  };
  isHasAccess: boolean = false;
  rightsError: string = '';

  ngOnInit() {
    setTimeout(() => {
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      this.initUserData();
      this.rightsError = "You don't have access rights to this module.";
    }, 500);
    // this.dataSource = new MatTableDataSource<any>();
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  initUserData() {
    // var urlAction = 'users/v1/getAllUsers';
    var urlAction = 'users/v4/getAllUsers';
    this._commonService
      .commonPostAction(urlAction, '')
      .subscribe(accountsResp => {
        // console.log(accountsResp);
        let allAccount = [];

        for (const key in accountsResp) {
          if (Object.prototype.hasOwnProperty.call(accountsResp, key)) {
            const element = accountsResp[key];

            element.forEach(account => {
              if (!account.agency) {
                account.agency = '';
              }
              allAccount.push(account);
            });
          }
        }

        this.dataSource.data = allAccount;
        this.allAccountClone = JSON.parse(JSON.stringify(allAccount));

        this.dataSource.paginator = this.paginator;
        this._snackBar.dismiss();
      });
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/system-configuration/account-management/user/2',
      'user management',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  onAddAccount(data = null) {
    // console.log('add account', data);

    var dialogData = {
      data: {
        accountData: data,
      },
    };

    const dialogRef = this._dialog.open(AddAccountComponent, {
      data: dialogData,
      disableClose: true,
      // height: '400px',
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result);
        let accountIndex = this.allAccountClone.findIndex(
          x => x.userEmail === result.userEmail
        );
        if (accountIndex < 0) {
          this.allAccountClone.push(result);
        } else {
          this.allAccountClone[accountIndex] = result;
        }

        this.dataSource.data = this.allAccountClone;
        this.allAccountClone = JSON.parse(JSON.stringify(this.allAccountClone));
      }
    });
  }

  // onEdit(data) {
  //   console.log('edit', data)
  // }

  onDelete(data) {
    // console.log('data', data);
    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        header: 'Delete Account',
        message: `Are you sure you want to delete ${
          data.userName || data.userEmail
        }?`,
        textConfirm: 'Delete',
        textCancel: 'Cancel',
        mode: 'warn',
      },
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        var urlAction = 'users/v1/deleteUser';
        var bodyData = {
          userName: data.userName,
        };
        this._commonService.commonDeleteAction(urlAction, bodyData).subscribe(
          retData => {
            this._snackBar.open(retData.response, null, {
              duration: 5000,
            });

            let filtered = this.allAccountClone.filter(
              x => x.userName !== data.userName
            );
            this.dataSource.data = filtered;
          },
          err => {
            console.log(err);
            this._snackBar.open(
              err.error.errorType + ', try again later.',
              null,
              {
                duration: 5000,
              }
            );
          }
        );
      }
    });
  }

  onCheckChange(isChecked, data, action) {
    var bodyData: any = {
      userName: data.userEmail,
    };

    var urlAction = '';
    if (action === 'enabled') {
      urlAction = 'users/v3/enableDisableUser';
      bodyData.userDisableFlag = isChecked;
      data.isAccountDisabled = isChecked;
    } else if (action === 'locked') {
      urlAction = 'users/v2/lockUnlockUser';
      bodyData.userLockFlag = isChecked;
    } else if (action === 'expired') {
      this._snackBar.open('Credential Expiration API not available.', null, {
        duration: 5000,
      });
      return false;
    } else if (action === 'activated') {
      urlAction = 'users/v2/activateDeactivateUser';
      bodyData.deActivateUserFlag = isChecked;
      data.isAccountDeactivated = isChecked;
    }

    // console.log(bodyData);
    this._commonService.commonPostAction(urlAction, bodyData).subscribe(
      accounts => {
        // console.log(accounts);
        // console.log(data);
        let isValid = accounts.response.includes('Cannot process');
        // this.dataSource = accounts;
        // console.log(isValid);
        if (isValid) {
          this.dataSource = JSON.parse(JSON.stringify(this.allAccountClone));
          this._snackBar.open(accounts.response + ', data reset', null, {
            duration: 5000,
          });
        } else {
          let accountIndex = this.allAccountClone.findIndex(
            x => x.userEmail === data.userEmail
          );
          this.allAccountClone[accountIndex] = data;

          this.dataSource.data = this.allAccountClone;
          this.allAccountClone = JSON.parse(
            JSON.stringify(this.allAccountClone)
          );

          this._snackBar.open(accounts.response, null, {
            duration: 5000,
          });
        }
      },
      err => {
        // let accountOrig = this.allAccountClone.find(x => x.userEmail === data.userEmail);
        // let account = this.allAccount.find(x => x.userEmail === data.userEmail);
        // account = accountOrig;
        // console.log(this.allAccountClone);
        this.dataSource = JSON.parse(JSON.stringify(this.allAccountClone));

        this._snackBar.open(err.error.errorType + ', data reset', null, {
          duration: 5000,
        });
      }
    );
  }

  onRefresh() {
    this._snackBar.open('Refreshing...', null);

    this.initUserData();
  }

  onFilter(keyword: string) {
    this.dataSource.filter = keyword.trim().toLowerCase();
  }

  onResetPassword(dataElem) {
    // console.log(dataElem);
    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        header: 'Reset Password',
        message: `Are you sure you want to send a reset password URL to ${dataElem.userEmail}?`,
        textConfirm: 'Reset',
        textCancel: 'Cancel',
      },
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        // console.log('reset password');
        let bodyData = {
          userName: dataElem.userEmail,
        };
        let urlAction = 'users/v1/sendResetPasswordLinkByAdmin';
        this._commonService.commonPostAction(urlAction, bodyData).subscribe(
          resp => {
            // console.log(resp);
            this._snackBar.open(
              // resp.response + ', ' + ' changed to default password.',
              "A reset password link has been sent to the user's registered email address.",
              null,
              {
                duration: 5000,
              }
            );
          },
          err => {
            this._snackBar.open(err.error.errorType, null, {
              duration: 5000,
            });
          }
        );
      }
    });
  }
}
