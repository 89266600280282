import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { FirebaseService } from './firebase.service';
import jwt_decode from 'jwt-decode';

export type NotificationObj = {
  notification: {
    actions?: {
      action: string;
      title: string;
      icon?: string;
    }[];
    data?: {
      onActionClick?: any;
      message?: any;
    };
    body?: string;
    renotify?: boolean;
    requireInteraction?: boolean;
    tag?: string;
    title: string;
  };
};

export const exampleNotification = {
  notification: {
    actions: [
      { action: 'foo', title: 'Open new tab' },
      { action: 'bar', title: 'Focus last' },
      { action: 'baz', title: 'Navigate last' },
      { action: 'qux', title: 'Send request in the background' },
      { action: 'other', title: 'Just notify existing clients' },
    ],
    data: {
      onActionClick: {
        default: { operation: 'focusLastFocusedOrOpen', url: '/' },
        foo: { operation: 'openWindow', url: '/absolute/path' },
        bar: { operation: 'focusLastFocusedOrOpen', url: '.' },
        baz: {
          operation: 'navigateLastFocusedOrOpen',
          url: 'https://other.domain.com/',
        },
        qux: {
          operation: 'sendRequest',
          url: 'https://yet.another.domain.com/',
        },
      },
    },
    body: 'Message Body',
    renotify: true,
    requireInteraction: true,
    tag: 'test',
    title: 'test title',
  },
};

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  public notificationAction$: Observable<any>;
  notificationSubscription: any;
  notificationActionSubscription: any;
  currentAction: Subject<any> = new Subject<any>();
  initialized: boolean = false;

  private emergencySubject: Subject<any> = new Subject<any>();
  public emergencyObservable: Observable<any> =
    this.emergencySubject.asObservable();

  constructor(
    private _swPush: SwPush,
    private _firebase: FirebaseService
  ) {
    this.notificationAction$ = this._swPush.notificationClicks;
    this.emergencyList = [];
    this.emergencyCloseList = [];

    // sample emergency - uncomment to show emergency
    // this.emergencyList = [
    //   {
    //     id: '12313-21312312-3123-12313-1318993',
    //     rid: '1234569',
    //     bus: 'SBS3325U',
    //     agency: 'TTS',
    //     index: 0,
    //   },
    //   {
    //     id: '12313-21312312-3123-12313-1318994',
    //     rid: '1234560',
    //     bus: 'SBS3320X',
    //     agency: 'TTS',
    //     index: 1,
    //   },
    // ];
  }

  init() {
    if (!environment.sw) {
      return;
    }
    if (!this.initialized) {
      try {
        this._isShowNotificationPermitted(false);
        this.notificationListener();
        this.notificationActionListener();
        this.listenToEmergencyBroadcast();
        this.initialized = true;
      } catch (err) {
        console.log('Error initializing push notification service: ', err);
      }
    }
  }

  onExit() {
    this.notificationSubscription?.unsubscribe?.();
    this.notificationActionSubscription?.unsubscribe?.();
    this.initialized = false;
  }

  notificationListener() {
    if (!environment.sw) {
      return;
    }
    // clean up old notifications on start
    navigator.serviceWorker.getRegistration().then(function (reg) {
      reg.getNotifications().then(function (notifications) {
        notifications.forEach(function (notification) {
          notification.close();
        });
      });
    });
    this.notificationSubscription = this._firebase
      .getMessage()
      .subscribe(payload => {
        console.log('Push Notification Service payload: ', payload);
        if (payload) {
          const { data } = payload;
          const { notif, type } = data || {};
          // console.log('Push Notification Service: ', data);
          const me = this;
          try {
            if (notif) {
              const notification = JSON.parse(notif);
              // console.log('notification JSON', notification);
              const { tag, data: notifData } = notification || {};
              const { type: notifType, rid } = notifData || {};
              if (tag) {
                console.log('Push Notification Service: Notif ID - ', tag);
              }

              if (
                notifType === 'emergency-call' ||
                notifType === 'emergency-dismiss'
              ) {
                if (notifType === 'emergency-call') {
                  console.log('firebase foreground emergency: ', notification);
                  this.emergencyCall(notification, me);
                }
                if (notifType === 'emergency-dismiss') {
                  this.emergencyEnd(rid);
                }
              } else {
                const newNotification = { notification };
                this.notify(newNotification);
              }
            }
            if (type) {
              console.log('Push Data: ', type, ' - ', data);
              if (type === 'notification-dismiss') {
                const { tag } = data || {};
                this.dismiss({ tag });
              }
              if (type === 'emergency-dismiss') {
                const { rid } = data || {};
                this.emergencyEnd(rid);
              }
            }
          } catch (e) {
            console.log('Error parsing notification', e);
          }
        }
      });
  }

  notificationActionListener() {
    if (!environment.sw) {
      return;
    }
    this.notificationActionSubscription = this.notificationAction$.subscribe(
      arg => {
        const { target = false } = arg;
        if (target) {
          // console.log('notification click', arg);
          this.currentAction.next(arg);
        }
      }
    );
  }

  getAction(): Observable<any> {
    return this.currentAction.asObservable();
  }

  // https://stackoverflow.com/a/76099693 - Edge quiet permissions will block permission requests
  async _isShowNotificationPermitted(shouldAskPermission = true) {
    if ('Notification' in window) {
      let { permission } = Notification;
      if (permission === 'granted') {
        return true;
      }
      if (permission !== 'denied' && shouldAskPermission) {
        // console.log('Request permission');
        return new Promise((resolve, reject) => {
          // console.log('Start request');
          Notification.requestPermission()
            .then(result => {
              // console.log('Permission Result', result);
              if (result === 'granted') {
                resolve(true);
                return;
              } else {
                reject(false);
                return;
              }
            })
            .catch(e => {
              console.error('Permission Request Error', e);
              reject(false);
            });
        });
        // try {
        //   const permissionRequest = await Notification.requestPermission();
        //   console.log('Permission Request Result', permissionRequest);
        // } catch (e) {
        //   console.error('Permission Request Error: ', e);
        // }
      }
    }
    return false;
  }

  public emergencyList;
  public emergencyCloseList = [];
  emergencyBroadcasts;
  listenToEmergencyBroadcast() {
    this.emergencyBroadcasts = new BroadcastChannel('emergency');
    const me = this;
    this.emergencyBroadcasts.onmessage = message => {
      const { data } = message;
      console.log('broadcast emergency message: ', message, data);
      const { type, rid } = data || {};
      if (type === 'emergency-dismiss') {
        me.emergencyEnd(rid, me);
      } else {
        me.emergencyCall({ data }, me);
      }
    };
  }

  getEmergencyList() {
    return this.emergencyList;
  }

  serviceEmergencyCall(notifData) {
    const me = this;
    this.emergencyCall(notifData, me);
  }

  emergencyCall(notifData, context) {
    console.log('Emergency call: ', notifData);
    const { data } = notifData;
    const { agency, id, rid, bus } = data;
    const isOngoingEmergency = context.emergencyList.some(
      emergency => emergency.rid === rid
    );
    const isClosed = !!context.emergencyCloseList.some(
      closeItem => closeItem === rid
    );
    if (!isOngoingEmergency && !isClosed) {
      const emergencyItem = {
        id,
        rid,
        bus,
        agency,
        index: context.emergencyList.length,
      };
      context.emergencyList.push(emergencyItem);
      // console.log('new emergency: ', context.emergencyList);
      context.emergencySubject.next(context.emergencyList);
    }
  }

  emergencyEnd(rid, context = this) {
    console.log('Push Emergency End: ', context.emergencyList, rid);
    context.emergencyCloseList.push(rid);
    context.emergencyList = context.emergencyList.filter(
      emergencyItem => emergencyItem.rid !== rid
    );
    context.emergencySubject.next(context.emergencyList);
  }

  // foreground notification
  notificationTags = [];
  notify(newNotification: NotificationObj) {
    if (!environment.sw) {
      console.log('Notifications are disabled if SW is disabled.');
      return;
    }

    // do not show notification if refresh token or access token is expired
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const { exp: expAccess } = jwt_decode<any>(accessToken);
    const { exp: expRefresh } = jwt_decode<any>(refreshToken);
    const expRefreshTimeStamp = +expRefresh * 1000;
    // const expRefreshTimeStamp = (1685951185) * 1000; // expired time
    const expAccessTimeStamp = +expAccess * 1000;
    const dateNow = Date.now();

    if (dateNow > expRefreshTimeStamp && dateNow > expAccessTimeStamp) {
      return;
    }

    const { notification } = newNotification;
    const { actions, body, data, title, tag, renotify, requireInteraction } =
      notification;

    // do not show notification again
    // need to share state with service worker. how?
    if (this.notificationTags.indexOf(tag) >= 0) {
      // console.log('current notifications: ', this.notificationTags);
      return;
    }

    // this should last just for the current session, since storage is cleared on logout
    const localStorageNotifications = localStorage.getItem('notificationsList');
    if (localStorageNotifications) {
      const parsedNotifications = JSON.parse(localStorageNotifications);
      if (parsedNotifications?.indexOf(tag) >= 0) return;
    }

    if (Notification.permission === 'granted') {
      console.log('foreground notification: ', tag, this.notificationTags);
      if (this.notificationTags.indexOf(tag) < 0) {
        this.notificationTags.push(tag);
        const notificationsJson = JSON.stringify(this.notificationTags);
        localStorage.setItem('notificationsList', notificationsJson);
      }

      let me = this;
      navigator.serviceWorker.getRegistration().then(async function (reg) {
        if (reg) {
          const notifications = await reg.getNotifications({ tag });

          const currentNotification = notifications.find(
            notifItem => notifItem.tag === tag
          );
          // console.log('notifications', notifications, currentNotification);

          // show notification if not in notification list
          if (!currentNotification) {
            var options = {
              body,
              icon: 'assets/icons/icon-192x192.png',
              vibrate: [100, 50, 100],
              data,
              actions,
              tag,
              renotify,
              requireInteraction: true,
            };
            reg.showNotification(title, options);
          }
        }
      });
    } else {
      console.log('Notifications are not allowed on this site.');
    }
  }

  dismiss({ tag }) {
    if (!environment.sw) {
      console.log('Notifications are disabled if SW is disabled.');
      return;
    }
    if (Notification.permission === 'granted') {
      let me = this;
      navigator.serviceWorker.getRegistration().then(async function (reg) {
        if (reg) {
          const notifications = await reg.getNotifications({ tag });
          notifications?.forEach(function (notification) {
            notification.close();
          });
        }
      });
    } else {
      console.log('Notifications are not allowed on this site.');
    }
  }
}
