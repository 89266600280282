import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Title } from '@angular/platform-browser';

import { JitsiService } from '../shared/services/jitsi.service';

import {
  INTERFACE_CONFIG_OVERWRITE,
  CONFIG_OVERWRITE,
} from '../shared/others/jitsi-config';
import { environment } from 'src/environments/environment';
import { CommonService } from '../shared/services/common.service';

import { LogsData } from '../shared/others/data-types';

declare var JitsiMeetExternalAPI;

// interface LogsData {
//   busRegNo: string,
//   routeId: string,
//   tripId: string,
//   latitude: number,
//   longitude: number,
//   timeInSeconds: number,
//   msgEvent: string
// }

/**
 * Private call only has audio enabled because of bus device limitation (Android permission)
 * Opted to disable private call to allow announcement and emergency call camera access
 */
@Component({
  selector: 'app-private-call',
  templateUrl: './private-call.component.html',
  styleUrls: ['./private-call.component.scss'],
})
export class PrivateCallComponent implements OnInit {
  decodedToken: any = jwt_decode(localStorage.getItem('access_token'));
  displayName = localStorage['displayname'];
  jitsiApi;
  @ViewChild('jitsicallframe') callFrame: ElementRef;
  busRegistrationNumber: string = '';
  operatorName: string = '';
  operatorInfo: any;
  bus;
  jitsiInitialized: boolean = false;
  callError: boolean = false;
  isPopup: boolean = false;
  jitsiEnd: boolean = false;
  jitsiHidden: boolean = true;
  participantCount: number = 0;

  constructor(
    private route: ActivatedRoute,
    private _jitsiService: JitsiService,
    private _commonService: CommonService,
    private titleService: Title
  ) {
    this._commonService.onIdle();
  }

  ngOnInit(): void {
    this._jitsiService.generateJaasToken(false);
    this.isPopup = !!window.opener;
    this.busRegistrationNumber = this.route.snapshot.queryParamMap.get(
      'busRegistrationNumber'
    ) as string;

    if (!this.busRegistrationNumber) {
      this.busRegistrationNumber = '';
      this.callError = true;
    }
  }

  ngAfterViewInit() {
    if (!this.callError) {
      this.initJitsi();
    }
  }

  callRoomName: string = '';
  initJitsi() {
    const domain = this._jitsiService.domain;
    const { agency } = this.decodedToken;
    this.operatorName = `${agency}-OPS-${this.displayName}`;
    this.callRoomName = `${environment.jitsi.appId}/${agency}-call-${this.busRegistrationNumber}`;
    const options = {
      jwt: this._jitsiService.jaasToken,
      roomName: this.callRoomName,
      parentNode: this.callFrame.nativeElement,
      width: '100%',
      height: '100%',
      interfaceConfigOverwrite: {
        TILE_VIEW_MAX_COLUMNS: 1,
        DEFAULT_BACKGROUND: '#292929',
        ...INTERFACE_CONFIG_OVERWRITE,
      },
      configOverwrite: {
        ...CONFIG_OVERWRITE,
        subject: `Call with ${this.busRegistrationNumber}`,
        startWithVideoMuted: true,
        startAudioOnly: true,
        toolbarButtons: [
          'tileview',
          'hangup',
          'microphone',
          // 'camera',
          'settings',
        ],
        hideConferenceTimer: false,
        disableTileView: false,
        startWithAudioMuted: false,
        // Disables self-view tile. (hides it from tile view and from filmstrip)
        disableSelfView: false,
        // Disables self-view settings in UI
        // disableSelfViewSettings: true,
        toolbarConfig: {
          alwaysVisible: true,
        },
        // defaultLocalDisplayName: `${agency}-OPS-${this.displayName} (Me)`
      },
    };
    this.jitsiApi = new JitsiMeetExternalAPI(domain, options);
    this.titleService.setTitle(`Call with ${this.busRegistrationNumber}`);
    let me = this;
    this.jitsiApi.executeCommand('displayName', this.operatorName);

    this.jitsiApi.addListener('videoConferenceJoined', info => {
      me.participantCount += 1;
      setTimeout(() => {
        me.jitsiHidden = false;
      }, 200);
    });

    this.jitsiApi.addListener('videoConferenceLeft', res => {
      me.participantCount -= 1;
      setTimeout(() => {
        me.jitsiHidden = true;
      }, 0);
      me.closeJitsiCall();
    });

    this.jitsiApi.addListener('participantJoined', () => {
      me.participantCount += 1;
      // if (me.participantCount < 2) {
      //   me.jitsiApi.isAudioMuted().then(muted => {
      //     if (muted) {
      //       me.jitsiApi.executeCommand('toggleAudio');
      //     }
      //   })
      // }
    });

    this.jitsiApi.addListener('participantLeft', () => {
      me.participantCount -= 1;
      // if (me.participantCount < 2) {
      //   me.jitsiApi.isAudioMuted().then(muted => {
      //     if (!muted) {
      //       me.jitsiApi.executeCommand('toggleAudio');
      //     }
      //   })
      // }
    });

    // this.jitsiApi.addListener('audioMuteStatusChanged', (res) => {
    //   const { muted = true } = res;
    //   if (!muted) {
    //     me._jitsiService.setBroadcastMuteStatus(true);
    //   }
    // });
  }

  closeJitsiCall() {
    this.jitsiEnd = true;
    if (this.jitsiApi) {
      this.jitsiApi.executeCommand('hangup');
      this.jitsiApi.dispose();
    }
    setTimeout(() => {
      window.close();
    }, 200);
  }

  ngOnDestroy() {
    if (this.jitsiApi) {
      this.jitsiApi.executeCommand('hangup');
      this.jitsiApi.dispose();
    }
  }
}
