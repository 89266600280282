<div
  class="bus-switch mat-elevation-z4"
  cdkDrag
  [ngClass]="{
    hidden:
      filteredSwitchData.primary === undefined &&
      filteredSwitchData.secondary === undefined
  }">
  <div
    class="container no-padding"
    [ngClass]="{ switchShortName: isRouteNameUse }">
    <!-- <div class="route-header text-center">Bus Route</div> -->
    <!-- <perfect-scrollbar class="ps-switch" [config]="config"> -->
    <!-- <perfect-scrollbar
      class="ps-switch"
      #perfectScroll
      [config]="config"
      [scrollIndicators]="false"
      minScrollbarLength="100"
      maxScrollbarLength="100"
      wheelSpeed="10"
    > -->
    <div
      class="bus-search"
      [ngClass]="{
        hidden:
          filteredSwitchData.primary === undefined &&
          filteredSwitchData.secondary === undefined
      }">
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        style="width: 110px"
        floatLabel="always">
        <mat-label>Bus Route</mat-label>
        <input
          name="Service Search"
          matInput
          [(ngModel)]="searchName"
          #filterName
          [disabled]="disabled"
          (input)="onSearchNameInput($event.target.value)"
          placeholder="Search" />
        <button
          [disableRipple]="true"
          *ngIf="filterName.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearNameTextBox($event)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="custom-scroll route-container">
      <!-- (psScrollY)="onListScroll()" -->
      <ng-container *ngFor="let bus of filteredSwitchData.primary">
        <div
          fxLayout="column"
          fxLayoutAlign="center start"
          id="route-{{ bus.service }}">
          <!-- <div class="col text-left" *ngIf="!isRouteNameUse">{{bus.service}}</div>
          <div class="col text-left no-padding" *ngIf="isRouteNameUse">{{bus.routeName}}</div>
          <div class="col no-padding text-right">
            <div>
              <label class="switch" >
                <input class="sw-cb" type="checkbox" (click)="isSwitch(bus, $event)" [(ngModel)]="bus.isOn">
                <span class="slider round"></span>
              </label>
            </div>
          </div> -->
          <!-- <div class="minWidth50"> -->
          <mat-slide-toggle
            (change)="isSwitch(bus, $event)"
            [(ngModel)]="bus.isOn"
            [disabled]="disabled"
            class="toggle-element">
            <div *ngIf="!isRouteNameUse">{{ bus.service }}</div>
            <div *ngIf="isRouteNameUse">{{ bus.routeName }}</div>
          </mat-slide-toggle>
          <!-- </div> -->
        </div>
      </ng-container>

      <div class="secondary-line" *ngIf="filteredSwitchData.secondary"></div>
      <div *ngFor="let bus of filteredSwitchData.secondary">
        <!-- <div class="row text-left" *ngIf="switchData.secondary">
          <div class="col text-left" *ngIf="!isRouteNameUse">{{bus.service}}</div>
          <div class="col text-left no-padding" *ngIf="isRouteNameUse">{{bus.routeName}}</div>
          <div class="col no-padding text-right">
            <div>
              <label class="switch" >
                <input class="sw-cb" type="checkbox" (click)="isSwitch(bus, $event)" [(ngModel)]="bus.isOn">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div> -->

        <div
          fxLayout="column"
          fxLayoutAlign="center start"
          *ngIf="filteredSwitchData.secondary">
          <!-- <div class="minWidth50"> -->
          <mat-slide-toggle
            (change)="isSwitch(bus, $event)"
            [(ngModel)]="bus.isOn"
            class="toggle-element">
            <div *ngIf="!isRouteNameUse">{{ bus.service }}</div>
            <div *ngIf="isRouteNameUse">{{ bus.routeName }}</div>
          </mat-slide-toggle>
          <!-- </div> -->
        </div>
      </div>
      <!-- </perfect-scrollbar> -->
    </div>
  </div>
</div>
