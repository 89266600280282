<!-- <div *ngIf="!isNewWindow" >
    <app-account-header></app-account-header>
</div> -->
<div class="events-group">
  <div class="container no-padding">
    <div class="col">
      <div class="row">
        <h3 class="title-header">Events Management<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
      </div>
    </div>
    <!-- <p-table #dt [columns]="cols" [value]="cars" [paginator]="true" [rows]="10" dataKey="routeId" editMode="row" class="events-table" 
      [scrollable]="true" scrollHeight="77vh"
      showCurrentPageReport="true"
      currentPageReportTemplate="{currentPage} of {totalPages}"
      > -->
      <ng-template pTemplate="caption">
          <!-- <div class="inner-addon right-addon"> -->
          <!-- <i class="fa fa-search" style="margin:4px 4px 0 0"></i> -->
          <!-- <input type="text" pInputText size="50" class="global-filter form-control" placeholder="Search" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto"> -->
        
            <!-- <i class="glyphicon glyphicon-search"></i> -->
            <!-- <i class="fa fa-cog"></i>
            <input type="text" class="form-control" placeholder="Search" />
        </div> -->
        
        <div class="input-group group-filter">
          <input pInputText type="text" class="form-control global-filter" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" (input)="dt.filterGlobal($event.target.value, 'contains')">
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"><i class="fa fa-cog"></i></span>
            <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="paginatorright" let-state> Total elements : {{ dt .totalRecords }} </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" class=td-{{col.field}}>
              {{col.header}}
          </th>
        </tr>
      </ng-template>
      
      <!-- <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex"> -->
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <!-- <tr [pEditableRow]="rowData" [pSelectableRow]="rowData"> -->
        <tr [ngClass]="'type-' + rowData.eventTypeId">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}} 
          </td>
        </tr>
      </ng-template>
    <!-- </p-table> -->
  </div>
</div>