<div class="bus-sched-mgt-group">
  <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="large" color="#fff" type="{{spinnerType}}" [fullScreen]="true"><p style="color: white; font-size: 18px; margin-top: 20px;" > Loading... </p></ngx-spinner> -->
  <ng-container *ngIf="isHasAccess; else noAccess">
    <div class="container no-padding">
      <div class="col">
        <div class="row">
          <h3 class="title-header">Bus Dataset Management<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
        </div>
      </div>

      <app-vehicle-calendar></app-vehicle-calendar>
    </div>
  </ng-container>
  
  <ng-template #noAccess>
    <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
      <h3>{{rightsError}}</h3>  
    </div>
  </ng-template>
</div>