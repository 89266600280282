<div class="bus-details-group {{ location }}">
  <div class="wrapper mat-elevation-z4">
    <div
      class="bd-header"
      [ngClass]="{
        bgGreen: busData.status === '0',
        'bgYellow textBlack': busData.status === '-1',
        bgRed: busData.status === '1',
        bgGray: busData.status === '2',
        bgOrange: busData.status === '3'
      }">
      <span class="bus-code" (click)="zoomBusLocation()">{{
        busData.busCode
      }}</span>
      <div
        class="icon-cancel"
        (click)="removeBus(busKey, busData.busCode, '', '')"></div>
      <!-- </div> -->
    </div>
    <div class="bd-body">
      <!-- <div class="container"> -->
      <div class="row no-margin">
        <div class="col-4 no-padding">
          <div class="bus-route text-center route-name">
            {{ routeName }}
          </div>
        </div>
        <div class="col no-padding">
          <div class="bus-status bgGreen" *ngIf="busData.status === '0'">
            On time
          </div>
          <div
            class="bus-status bgYellow textBlack"
            *ngIf="busData.status === '-1'">
            Early
          </div>
          <div class="bus-status bgRed" *ngIf="busData.status === '1'">
            Late
          </div>
          <div class="bus-status bgGray" *ngIf="busData.status === '2'">
            Not on trip
          </div>
          <div class="bus-status bgOrange" *ngIf="busData.status === '3'">
            Off Route
          </div>
        </div>
        <!-- <div
          class="col-2 no-padding"
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <div>
            <div
              class="icon icon-phone"
              (click)="callJitsi()"
              matTooltip="Start Jitsi Call"></div>
            <div
              class="icon icon-msg"
              (click)="showMessageList()"
              matTooltip="Show messages list"></div>
          </div>
        </div> -->
      </div>

      <div class="row no-margin">
        <div class="col-6 no-padding">
          <div class="">
            <span class="color-brown">Trip ID:</span>
            {{ busData.tripId }}
          </div>
        </div>
        <div class="col no-padding">
          <div class="">
            <div><span class="color-brown">Driver:</span> Bus Captain</div>
          </div>
        </div>
      </div>
      <!-- <div class="row no-margin">
        <div class="col-5 no-padding">
          <div class="">
            <span class="color-brown">Vehicle:</span>
            {{ busData.blockId }}
          </div>
        </div>
        <div class="col no-padding">
          <div class="">
            <span class="color-brown">Model: </span>
            -
          </div>
        </div>
      </div> -->

      <div class="row no-margin color-brown">
        <div class="col-10 no-padding">Stop</div>
        <div class="col no-padding">ETA</div>
      </div>
      <ng-container *ngIf="busData?.stops?.length > 0">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="col-10 no-padding">
            Next stop: {{ busData.stops[0].stop }}
          </div>
          <div class="col no-padding">
            {{ busData.stops[0].eta }}
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="col-10 no-padding">
            After next stop: {{ busData.stops[1].stop }}
          </div>
          <div class="col no-padding">
            {{ busData.stops[1].eta }}
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="col-10 no-padding">
            Third stop: {{ busData.stops[2].stop }}
          </div>
          <div class="col no-padding">
            {{ busData.stops[2].eta }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
