import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-incident-management',
  templateUrl: './incident-management.component.html',
  styleUrls: ['./incident-management.component.scss']
})
export class IncidentManagementComponent implements OnInit {

  isNewWindow: boolean = false;
  
  constructor(private router: Router) { }

  ngOnInit() {
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/incident-management/2','incident-management','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}
