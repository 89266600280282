import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  group,
  animate,
} from '@angular/animations';
import { slideUpDown } from 'src/app/component/shared/others/slideInUp';
import { HomeService } from 'src/app/component/shared/services/home.service';
import { freeTextMessageData } from 'src/app/component/shared/others/data-types';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
import { Subscription } from 'rxjs';
import { MqttService } from 'src/app/component/shared/services/mqtt.service';
import moment from 'moment';
import { VoipService } from 'src/app/component/shared/services/voip.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-predefined-messages',
  templateUrl: './predefined-messages.component.html',
  styleUrls: ['./predefined-messages.component.scss'],
  animations: [slideUpDown],
})
export class PredefinedMessagesComponent implements OnInit {
  @Input() data: any;
  messages: any[] = [];

  @Output() predefinedMessageZoom: EventEmitter<any> = new EventEmitter<any>();
  @Output() predefinedMessageClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeMessage: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;

  public config: PerfectScrollbarConfigInterface = {};
  messageTextRes: string = '';
  slideUpDownState = 'out';
  dataDuplicate;
  isError: boolean = false;
  isBlinkRedYellow: boolean = true;
  // isMinimize = 'in';
  messageSubscription: Subscription;
  isMessageShow: boolean = true;
  isAutofocus: boolean = true;

  private mqttSubcription: Subscription;

  constructor(
    private homeService: HomeService,
    private mqttService: MqttService,
    private _voipService: VoipService,
    private _snackBar: MatSnackBar
  ) {
    this.messageSubscription = this.homeService
      .getAllMessage()
      .subscribe(message => {
        if (
          message.busRegNo !== undefined &&
          message.busRegNo === this.data.busRegNo
        ) {
          // console.log(message);
          this.messages.push(message);
          this.homeService.setMessageVehicleCache(message);
          setTimeout(() => {
            this.chatPS.directiveRef.scrollToBottom(0, 50);
          }, 100);
        }
      });
    let self = this;

    this.mqttSubcription = this.mqttService.mqtt$.subscribe((data: any) => {
      if (data.busCode === self.data.busRegNo) {
        self.data.latitude = data.lat;
        self.data.longitude = data.lon;
      }
    });
  }

  // get displayMessages(){
  //   // console.log(this.messages);
  //   this.chatPS.directiveRef.scrollToBottom(0, 50);
  //   return this.messages;
  // }

  ngOnChanges() {}

  ngOnInit() {
    this.dataDuplicate = Object.assign({}, this.data);

    if (this.dataDuplicate.routeId === 'protobuf') {
      this.dataDuplicate.routeId = '';
    }

    this.dataDuplicate.messageText = '';
    this.isBlinkRedYellow = this.dataDuplicate.isBlinkRedYellow;
    if (!this.isBlinkRedYellow) {
      this.slideUpDownState = 'in';
      this.isAutofocus = false;
    }

    this.homeService.getMessageVehicleCache().forEach(element => {
      if (element.busRegNo !== undefined && element.busRegNo) {
        this.messages.push(element);
      }
    });
  }

  // showMessage() {
  //   this.slideUpDownState = this.slideUpDownState === 'in' ? 'out' : 'in';
  // }

  showMinimize() {
    // this.isMinimize = this.isMinimize === 'in' ? 'out' : 'in';

    for (const message of this.homeService.setPredefinedData) {
      if (message.busRegNo === this.data.busRegNo) {
        message.isVisible = false;
      }
    }
  }

  sendMessage() {
    if (!this.dataDuplicate.messageText) {
      this.messageTextRes = 'Message text cannot be empty.';
      this.isError = true;
      return;
    }
    var freeTextData: freeTextMessageData = {
      busRegNo: this.dataDuplicate.busRegNo,
      tripId: this.dataDuplicate.tripId,
      routeId: this.dataDuplicate.routeId,
      messageText: this.dataDuplicate.messageText,
      isBroadcast: false,
      isPIDSBroadcast: false,
      // "messageTime": new Date().toISOString().substring(0, 10).toString()
    };

    // this._voipService.getVehicleSipNumber(freeTextData.busRegNo).subscribe(
    //   respSip => {
    //     // console.log(respSip);
    //     // if(respSip.sipId >= 1 && respSip.sipId <= 600) {
    //     //   this._snackBar.open('SIP number disabled '+freeTextData.busRegNo, null, {
    //     //     duration: 5000,
    //     //   });
    //     //   return false;
    //     // }
    // });

    var freeTextRes = this.homeService.sendFreeTextMessage(freeTextData);
    freeTextRes.then(data => {
      console.log('sendFreeTextMessage');
      this.isError = false;
      this.isBlinkRedYellow = false;
      this.dataDuplicate.messageText = '';
      this.messageTextRes = 'Message sent!';
      // this.displayMessages('test');
      let messageText = {
        busRegNo: this.dataDuplicate.busRegNo,
        messageText: freeTextData.messageText,
        messageTime: moment().format('HH:mm a'),
        from: 'myself',
      };
      this.messages = this.messages === undefined ? [] : this.messages;
      this.messages.push(messageText);
      setTimeout(() => {
        // this.showMessage();
        this.messageTextRes = '';
      }, 3000);
      setTimeout(() => {
        this.chatPS.directiveRef.scrollToBottom(0, 50);
      }, 100);
      this.homeService.setMessageVehicleCache(messageText);
    });
  }

  // clearMessage() {
  //   this.dataDuplicate.messageText = '';
  //   this.messageTextRes = '';
  //   this.predefinedMessageClear.emit(this.dataDuplicate);
  // }

  // textMessageKeyPress() {
  //   // if(this.data.messageText)
  //   console.log(this.isMessageShow);
  //   this.messageTextRes = '';
  //   this.isMessageShow = false;
  //   console.log(this.isMessageShow);
  // }

  textMessageFocus() {
    this.messageTextRes = '';
    this.isMessageShow = false;
  }

  textMessageBlur() {
    this.isMessageShow = true;
  }

  zoomBusLocation() {
    this.predefinedMessageZoom.emit(this.data);
  }

  closeMessagePanel() {
    this.closeMessage.emit(this.dataDuplicate);
  }

  ngOnDestroy() {
    this.messages = [];
    this.messageSubscription.unsubscribe();
    this.mqttSubcription.unsubscribe();
  }
}
