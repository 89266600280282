import { Component, OnInit } from '@angular/core';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { ActivatedRoute, Router } from '@angular/router';
export var service = [];
export var single2 = [];


@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit {
  
  service: any[];
  single2: any[];
  multi: any[];

  view: any[] = [700, 400];

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#0d1fbf', '#9e0dbf']
  };
  
  colorSchemeCard = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#5AA454', '#5AA454']
  };
  isNewWindow: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {
    service = [
      {
        "name": "Route 3",
        "value": 25457
      },
      {
        "name": "Route 3A",
        "value": 47895
      },
      {
        "name": "Route 3B",
        "value": 73946
      },
      {
        "name": "Route 3N",
        "value": 15987
      },
      {
        "name": "Route 5",
        "value": 85741
      },
    ];
      single2 = [
        {
          "name": "Route 3",
          "value": "On time"
        },
        {
          "name": "Route 3A",
          "value": "-3mins"
        },
        {
          "name": "Route 3B",
          "value": "+5mins"
        },
        {
          "name": "Route 3N",
          "value": "On time"
        },
        {
          "name": "Route 5",
          "value": "On time"
        },
      ];
    Object.assign(this, { service, single2 });  
   }

  ngOnInit() {
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
  }

  myYAxisTickFormatting(val) {
    return '$' + (val).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/kpi/2','kpi','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}