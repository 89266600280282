export const busRoutes = [
  {
    route: 'Green Route',
    id: '7002',
  },
  {
    route: 'Orange Route',
    id: '7003',
  },
  {
    route: 'Red Route',
    id: '7001',
  },
];

const greenRoute = {
  ladder: {
    isVisible: 'true',
    routeId: ['7002'],
    routeName: ['7002'],
    direction: [
      {
        value: '2',
        stops: [
          {
            streetName: 'Green Route',
            stopCode: 'GS10',
            stopName: 'Liverpool Landing',
            latitude: 1.340476,
            longitude: 103.637113,
            stopSequence: '1',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS9',
            stopName: 'Bristol Bridge',
            latitude: 1.336771,
            longitude: 103.705133,
            stopSequence: '2',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS8',
            stopName: 'Cantebury Court',
            latitude: 1.315043,
            longitude: 103.764998,
            stopSequence: '3',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS7',
            stopName: 'Leeds Lane',
            latitude: 1.2927635057238263,
            longitude: 103.78620150994891,
            stopSequence: '4',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS6',
            stopName: 'York Yard',
            latitude: 1.2811824,
            longitude: 103.8409212,
            stopSequence: '5',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS5',
            stopName: 'Cambridge Cresent',
            latitude: 1.2996007,
            longitude: 103.8550052,
            stopSequence: '6',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS4',
            stopName: 'Oxford Overpass',
            latitude: 1.3179272,
            longitude: 103.891838,
            stopSequence: '7',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS3',
            stopName: 'Windsor Way',
            latitude: 1.3237203,
            longitude: 103.9290162,
            stopSequence: '8',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS2',
            stopName: 'Stratford',
            latitude: 1.3518124,
            longitude: 103.9437422,
            stopSequence: '9',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS1',
            stopName: 'Buckingham Blvd',
            latitude: 1.3725841,
            longitude: 103.9480903,
            stopSequence: '10',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
        ],
      },
      {
        value: '1',
        stops: [
          {
            streetName: 'Green Route',
            stopCode: 'GS1',
            stopName: 'Buckingham Blvd',
            latitude: 1.3725841,
            longitude: 103.9480903,
            stopSequence: '1',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS2',
            stopName: 'Stratford',
            latitude: 1.3518124,
            longitude: 103.9437422,
            stopSequence: '2',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS3',
            stopName: 'Windsor Way',
            latitude: 1.3237203,
            longitude: 103.9290162,
            stopSequence: '3',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS4',
            stopName: 'Oxford Overpass',
            latitude: 1.3179272,
            longitude: 103.891838,
            stopSequence: '4',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS5',
            stopName: 'Cambridge Cresent',
            latitude: 1.2996007,
            longitude: 103.8550052,
            stopSequence: '5',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS6',
            stopName: 'York Yard',
            latitude: 1.2811824,
            longitude: 103.8409212,
            stopSequence: '6',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS7',
            stopName: 'Leeds Lane',
            latitude: 1.2927635057238263,
            longitude: 103.78620150994891,
            stopSequence: '7',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS8',
            stopName: 'Cantebury Court',
            latitude: 1.315043,
            longitude: 103.764998,
            stopSequence: '8',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS9',
            stopName: 'Bristol Bridge',
            latitude: 1.336771,
            longitude: 103.705133,
            stopSequence: '9',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Green Route',
            stopCode: 'GS10',
            stopName: 'Liverpool Landing',
            latitude: 1.340476,
            longitude: 103.637113,
            stopSequence: '10',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
        ],
      },
    ],
  },
  route: {
    route: ['7002'],
    direction: [
      {
        value: 2,
        path: [
          '1.340507,103.637014',
          '1.3399899999999998,103.63685',
          '1.3398799999999997,103.63707',
          '1.3398099999999997,103.63718',
          '1.3397599999999996,103.63725',
          '1.3396499999999996,103.63736999999999',
          '1.3395199999999996,103.63752999999998',
          '1.3391499999999996,103.63791999999998',
          '1.3387599999999997,103.63832999999998',
          '1.3384099999999997,103.63870999999999',
          '1.3381499999999997,103.639',
          '1.3378099999999997,103.63933',
          '1.3372299999999997,103.63987',
          '1.3368599999999997,103.64028',
          '1.3366199999999997,103.64056000000001',
          '1.3363899999999997,103.64079000000001',
          '1.3358099999999997,103.64137000000001',
          '1.3355299999999997,103.64169000000001',
          '1.3353099999999996,103.6419',
          '1.3346799999999996,103.64253000000001',
          '1.3355999999999997,103.64341',
          '1.3359299999999996,103.64372',
          '1.3364899999999995,103.64424',
          '1.3366599999999995,103.64439999999999',
          '1.3370799999999996,103.64479999999999',
          '1.3382399999999997,103.64589',
          '1.3386499999999997,103.64629',
          '1.3388999999999998,103.64649',
          '1.3389899999999997,103.64657',
          '1.3390899999999997,103.64667',
          '1.3391699999999997,103.64675',
          '1.3390799999999998,103.64684',
          '1.3387599999999997,103.64717',
          '1.3384099999999997,103.64752',
          '1.3378099999999997,103.64818',
          '1.3374199999999998,103.6486',
          '1.3369699999999998,103.64908',
          '1.3367099999999998,103.64936',
          '1.3364899999999997,103.6496',
          '1.3361799999999997,103.64993000000001',
          '1.3353399999999997,103.65079000000001',
          '1.3348199999999997,103.65131000000001',
          '1.3343999999999996,103.65174',
          '1.3342999999999996,103.65184',
          '1.3339499999999995,103.65220000000001',
          '1.3337799999999995,103.65237',
          '1.3335399999999995,103.65262',
          '1.3332999999999995,103.65285',
          '1.3330999999999995,103.65303',
          '1.3329399999999996,103.65316',
          '1.3326999999999996,103.65333',
          '1.3325399999999996,103.6535',
          '1.3320499999999995,103.65404',
          '1.3318099999999995,103.65428999999999',
          '1.3312099999999996,103.65491999999999',
          '1.3307099999999996,103.65543999999998',
          '1.3302999999999996,103.65586999999998',
          '1.3292999999999997,103.65692999999997',
          '1.3286999999999998,103.65755999999998',
          '1.3284699999999998,103.65780999999997',
          '1.3278699999999999,103.65846999999997',
          '1.3272899999999999,103.65910999999997',
          '1.3270099999999998,103.65940999999997',
          '1.3268199999999999,103.65969999999997',
          '1.32673,103.65982999999997',
          '1.32659,103.66000999999997',
          '1.3263099999999999,103.66031999999997',
          '1.32588,103.66077999999997',
          '1.3257299999999999,103.66092999999998',
          '1.32516,103.66153999999997',
          '1.32452,103.66219999999997',
          '1.32428,103.66243999999998',
          '1.32405,103.66264999999997',
          '1.32386,103.66281999999997',
          '1.3238,103.66290999999997',
          '1.32369,103.66306999999996',
          '1.32365,103.66320999999996',
          '1.32364,103.66335999999997',
          '1.3236299999999999,103.66350999999997',
          '1.32364,103.66365999999998',
          '1.32365,103.66368999999997',
          '1.32367,103.66372999999997',
          '1.32375,103.66384999999997',
          '1.32422,103.66415999999997',
          '1.3252,103.66480999999996',
          '1.32577,103.66518999999997',
          '1.3258699999999999,103.66525999999996',
          '1.32595,103.66531999999997',
          '1.32603,103.66537999999997',
          '1.32609,103.66542999999997',
          '1.32616,103.66548999999998',
          '1.32624,103.66555999999997',
          '1.3264200000000002,103.66572999999997',
          '1.32658,103.66587999999997',
          '1.3268000000000002,103.66608999999997',
          '1.3268700000000002,103.66615999999996',
          '1.3269300000000002,103.66622999999996',
          '1.3269700000000002,103.66627999999996',
          '1.3271200000000003,103.66648999999995',
          '1.3271700000000004,103.66653999999996',
          '1.3272500000000005,103.66662999999996',
          '1.3273800000000004,103.66677999999996',
          '1.3275200000000005,103.66694999999996',
          '1.3276400000000004,103.66710999999995',
          '1.3277400000000004,103.66724999999995',
          '1.3278200000000004,103.66736999999995',
          '1.3279100000000004,103.66750999999995',
          '1.3280200000000004,103.66768999999995',
          '1.3281700000000005,103.66794999999995',
          '1.3282800000000006,103.66813999999995',
          '1.3283800000000006,103.66831999999995',
          '1.3285000000000005,103.66853999999995',
          '1.3285900000000004,103.66870999999995',
          '1.3290700000000004,103.66961999999995',
          '1.3293000000000004,103.67004999999995',
          '1.3294000000000004,103.67022999999995',
          '1.3294800000000004,103.67037999999995',
          '1.3295900000000005,103.67057999999996',
          '1.3296900000000005,103.67069999999995',
          '1.3297500000000004,103.67076999999995',
          '1.3298000000000005,103.67082999999995',
          '1.3298300000000005,103.67086999999995',
          '1.3298700000000006,103.67092999999996',
          '1.3299200000000007,103.67101999999996',
          '1.3299600000000007,103.67108999999995',
          '1.3300000000000007,103.67115999999994',
          '1.3300600000000007,103.67127999999994',
          '1.3301000000000007,103.67135999999994',
          '1.3301500000000008,103.67147999999993',
          '1.330230000000001,103.67167999999994',
          '1.330280000000001,103.67181999999994',
          '1.3303300000000011,103.67197999999993',
          '1.3303800000000012,103.67215999999993',
          '1.3304200000000013,103.67236999999993',
          '1.3304700000000014,103.67261999999992',
          '1.3305100000000014,103.67283999999992',
          '1.3305400000000014,103.67298999999993',
          '1.3305700000000014,103.67311999999993',
          '1.3306000000000013,103.67323999999992',
          '1.3306200000000012,103.67330999999992',
          '1.3306700000000014,103.67344999999992',
          '1.3307200000000015,103.67357999999992',
          '1.3307800000000014,103.67370999999991',
          '1.3308700000000013,103.67387999999991',
          '1.3310900000000014,103.67428999999991',
          '1.3311300000000015,103.67435999999991',
          '1.3311600000000015,103.67445999999991',
          '1.3311700000000015,103.67450999999991',
          '1.3311700000000015,103.67452999999992',
          '1.3311600000000015,103.67459999999991',
          '1.3311500000000014,103.67463999999991',
          '1.3311400000000013,103.67465999999992',
          '1.3311300000000013,103.67467999999992',
          '1.3311100000000013,103.67470999999992',
          '1.3310600000000012,103.67476999999992',
          '1.3309400000000013,103.67491999999993',
          '1.3309200000000014,103.67493999999994',
          '1.3308200000000014,103.67509999999993',
          '1.3307400000000014,103.67522999999993',
          '1.3306500000000014,103.67537999999993',
          '1.3306300000000015,103.67550999999993',
          '1.3306100000000016,103.67560999999993',
          '1.3305900000000017,103.67569999999994',
          '1.3305700000000018,103.67578999999994',
          '1.330550000000002,103.67587999999994',
          '1.3305400000000018,103.67593999999994',
          '1.3305300000000018,103.67600999999993',
          '1.3305200000000017,103.67610999999994',
          '1.3305100000000016,103.67631999999993',
          '1.3304300000000016,103.67727999999994',
          '1.3303600000000015,103.67806999999993',
          '1.3303000000000016,103.67879999999994',
          '1.3302800000000017,103.67905999999994',
          '1.3302500000000017,103.67939999999993',
          '1.3302400000000016,103.67955999999992',
          '1.3302000000000016,103.68007999999992',
          '1.3301500000000015,103.68071999999992',
          '1.3301300000000016,103.68096999999992',
          '1.3300400000000017,103.68227999999992',
          '1.3299800000000017,103.68311999999992',
          '1.3299700000000017,103.68327999999991',
          '1.3299200000000015,103.68400999999992',
          '1.3298900000000016,103.68435999999991',
          '1.3298800000000015,103.68459999999992',
          '1.3298500000000015,103.68495999999992',
          '1.3297500000000015,103.68633999999992',
          '1.3297400000000015,103.68653999999992',
          '1.3297400000000015,103.68669999999992',
          '1.3297500000000015,103.68682999999992',
          '1.3297700000000015,103.68695999999991',
          '1.3298000000000014,103.68708999999991',
          '1.3298300000000014,103.68717999999991',
          '1.3298900000000013,103.6873399999999',
          '1.3299500000000013,103.6874699999999',
          '1.3300200000000013,103.6875899999999',
          '1.3300900000000013,103.6876899999999',
          '1.3301800000000013,103.68779999999991',
          '1.3302900000000013,103.68790999999992',
          '1.3303400000000014,103.68795999999992',
          '1.3304300000000013,103.68803999999992',
          '1.3308500000000014,103.68837999999991',
          '1.3309700000000013,103.68847999999991',
          '1.3311100000000013,103.68859999999991',
          '1.3312800000000014,103.68873999999991',
          '1.3314500000000014,103.68886999999991',
          '1.3317400000000013,103.6890799999999',
          '1.3327300000000013,103.6897999999999',
          '1.3329700000000013,103.6899799999999',
          '1.3330900000000012,103.6900699999999',
          '1.3336300000000012,103.6904699999999',
          '1.3338200000000011,103.69061999999991',
          '1.3340600000000011,103.69079999999991',
          '1.334290000000001,103.69097999999991',
          '1.334480000000001,103.6911399999999',
          '1.334590000000001,103.69124999999991',
          '1.334690000000001,103.6913699999999',
          '1.334780000000001,103.6914999999999',
          '1.334860000000001,103.69164999999991',
          '1.334920000000001,103.69177999999991',
          '1.334970000000001,103.69192999999991',
          '1.335000000000001,103.69204999999991',
          '1.335020000000001,103.69214999999991',
          '1.335030000000001,103.69222999999991',
          '1.335030000000001,103.69226999999991',
          '1.335030000000001,103.6923899999999',
          '1.335030000000001,103.6924199999999',
          '1.335030000000001,103.6925499999999',
          '1.335020000000001,103.6927199999999',
          '1.334960000000001,103.6933999999999',
          '1.3349400000000011,103.69364999999989',
          '1.3349200000000012,103.69394999999989',
          '1.3349000000000013,103.6942399999999',
          '1.3348900000000012,103.6944399999999',
          '1.3348800000000012,103.6955899999999',
          '1.3348800000000012,103.6957599999999',
          '1.3348800000000012,103.6959599999999',
          '1.3348800000000012,103.6963799999999',
          '1.3348800000000012,103.6965399999999',
          '1.3348800000000012,103.6967299999999',
          '1.3348900000000012,103.69804999999991',
          '1.3348800000000012,103.69848999999991',
          '1.3348800000000012,103.69890999999991',
          '1.3348800000000012,103.69914999999992',
          '1.3348800000000012,103.70004999999992',
          '1.3348800000000012,103.70202999999992',
          '1.3348800000000012,103.70339999999992',
          '1.3348800000000012,103.70426999999992',
          '1.3348900000000012,103.70445999999993',
          '1.3349100000000012,103.70462999999992',
          '1.334930000000001,103.70472999999993',
          '1.334960000000001,103.70484999999992',
          '1.334990000000001,103.70495999999993',
          '1.335020000000001,103.70503999999993',
          '1.335060000000001,103.70511999999992',
          '1.335130000000001,103.70523999999992',
          '1.335220000000001,103.70537999999992',
          '1.335320000000001,103.70550999999992',
          '1.3354100000000009,103.70559999999992',
          '1.335520000000001,103.70569999999992',
          '1.335620000000001,103.70576999999992',
          '1.3357500000000009,103.70584999999991',
          '1.3358500000000009,103.70589999999991',
          '1.3360200000000009,103.70597999999991',
          '1.3361900000000009,103.7060499999999',
          '1.3363500000000008,103.70596999999991',
          '1.3365300000000009,103.7058599999999',
          '1.336770000000001,103.7052799999999',
          '1.336840000000001,103.7051839999999',
          '1.33684,103.705184',
          '1.33702,103.70494000000001',
          '1.3372000000000002,103.70483',
          '1.3375500000000002,103.7047',
          '1.3379300000000003,103.70459',
          '1.3380700000000003,103.70452999999999',
          '1.3382000000000003,103.70448999999999',
          '1.3383100000000003,103.70443999999999',
          '1.3385600000000004,103.70434999999999',
          '1.3390900000000003,103.70415999999999',
          '1.3392600000000003,103.70410999999999',
          '1.3395100000000004,103.70405999999998',
          '1.3396900000000005,103.70402999999999',
          '1.3397700000000006,103.70401999999999',
          '1.3398100000000006,103.70409999999998',
          '1.3399700000000005,103.70440999999998',
          '1.3400000000000005,103.70448999999998',
          '1.3400700000000005,103.70466999999998',
          '1.3401000000000005,103.70475999999998',
          '1.3401300000000005,103.70483999999998',
          '1.3402900000000004,103.70528999999998',
          '1.3403600000000004,103.70547999999998',
          '1.3404200000000004,103.70564999999998',
          '1.3404700000000005,103.70577999999998',
          '1.3405000000000005,103.70585999999997',
          '1.3405700000000005,103.70604999999998',
          '1.3406900000000004,103.70635999999998',
          '1.3407800000000003,103.70660999999997',
          '1.3408300000000004,103.70672999999996',
          '1.3408800000000005,103.70685999999996',
          '1.3409100000000005,103.70695999999997',
          '1.3408600000000004,103.70697999999997',
          '1.3406100000000003,103.70707999999998',
          '1.3404300000000002,103.70716999999998',
          '1.3403300000000002,103.70721999999998',
          '1.3401500000000002,103.70730999999998',
          '1.3400900000000002,103.70733999999997',
          '1.3399000000000003,103.70745999999997',
          '1.3398500000000002,103.70749999999997',
          '1.3398,103.70754999999997',
          '1.33973,103.70763999999997',
          '1.3397700000000001,103.70768999999997',
          '1.33983,103.70776999999997',
          '1.3398800000000002,103.70784999999997',
          '1.3399300000000003,103.70793999999997',
          '1.3399900000000002,103.70804999999997',
          '1.3400800000000002,103.70826999999997',
          '1.3402300000000003,103.70863999999997',
          '1.3403700000000003,103.70899999999997',
          '1.3404800000000003,103.70927999999998',
          '1.3405000000000002,103.70932999999998',
          '1.3405500000000004,103.70946999999998',
          '1.3404200000000004,103.70951999999998',
          '1.3402800000000004,103.70956999999999',
          '1.3401500000000004,103.70961999999999',
          '1.3400200000000004,103.70965999999999',
          '1.3398100000000004,103.70971999999999',
          '1.3396300000000003,103.70975999999999',
          '1.3394400000000004,103.70979999999999',
          '1.3393100000000004,103.70982',
          '1.3391300000000004,103.70984',
          '1.3389500000000003,103.70985',
          '1.3387400000000003,103.70985',
          '1.3384700000000003,103.70984',
          '1.3383300000000002,103.70984',
          '1.3382700000000003,103.70984',
          '1.3381700000000003,103.70983',
          '1.3380700000000003,103.70982',
          '1.3378400000000004,103.70979',
          '1.3375400000000004,103.70974',
          '1.3374400000000004,103.70971999999999',
          '1.3372400000000004,103.70967999999999',
          '1.3368000000000004,103.70956999999999',
          '1.3366600000000004,103.70953999999999',
          '1.3364700000000005,103.70949999999999',
          '1.3363800000000006,103.70947999999999',
          '1.3362400000000005,103.70944999999999',
          '1.3360800000000006,103.70940999999999',
          '1.3360100000000006,103.70938999999998',
          '1.3359100000000006,103.70935999999999',
          '1.3357700000000006,103.70930999999999',
          '1.3356400000000006,103.70925999999999',
          '1.3352000000000006,103.70906999999998',
          '1.3349300000000006,103.70895999999998',
          '1.3347700000000007,103.70889999999997',
          '1.3346800000000008,103.70886999999998',
          '1.3345200000000008,103.70881999999997',
          '1.334330000000001,103.70876999999997',
          '1.333460000000001,103.70854999999997',
          '1.332820000000001,103.70839999999997',
          '1.332460000000001,103.70830999999997',
          '1.332040000000001,103.70820999999997',
          '1.331720000000001,103.70812999999997',
          '1.3316100000000008,103.70810999999996',
          '1.3314800000000009,103.70808999999996',
          '1.331350000000001,103.70806999999995',
          '1.331260000000001,103.70805999999995',
          '1.331120000000001,103.70804999999994',
          '1.330980000000001,103.70804999999994',
          '1.330890000000001,103.70804999999994',
          '1.330780000000001,103.70804999999994',
          '1.330650000000001,103.70805999999995',
          '1.330540000000001,103.70806999999995',
          '1.3303900000000008,103.70808999999996',
          '1.3302400000000008,103.70811999999995',
          '1.3300500000000008,103.70815999999995',
          '1.3299700000000008,103.70817999999996',
          '1.3297900000000007,103.70821999999995',
          '1.3294800000000007,103.70829999999995',
          '1.3293100000000007,103.70833999999995',
          '1.3290800000000007,103.70837999999995',
          '1.3289200000000008,103.70840999999994',
          '1.3287600000000008,103.70844999999994',
          '1.3285300000000009,103.70850999999995',
          '1.3282900000000009,103.70858999999994',
          '1.3281800000000008,103.70862999999994',
          '1.3278400000000008,103.70873999999995',
          '1.3274300000000008,103.70886999999995',
          '1.3270500000000007,103.70899999999995',
          '1.3268600000000008,103.70906999999994',
          '1.3265100000000007,103.70918999999994',
          '1.3263400000000007,103.70923999999994',
          '1.3261700000000007,103.70928999999994',
          '1.3259700000000008,103.70933999999994',
          '1.3255200000000007,103.70945999999994',
          '1.3253300000000008,103.70950999999994',
          '1.3250200000000008,103.70958999999993',
          '1.3247800000000007,103.70964999999994',
          '1.3245500000000008,103.70969999999994',
          '1.3242700000000007,103.70974999999994',
          '1.3240900000000007,103.70977999999994',
          '1.3238700000000005,103.70981999999994',
          '1.3235500000000004,103.70987999999994',
          '1.3233600000000005,103.70991999999994',
          '1.3231400000000004,103.70996999999994',
          '1.3228900000000003,103.71002999999995',
          '1.3223600000000004,103.71015999999995',
          '1.3222800000000003,103.71020999999995',
          '1.3222200000000004,103.71024999999995',
          '1.3221600000000004,103.71028999999994',
          '1.3221200000000004,103.71031999999994',
          '1.3220900000000004,103.71034999999993',
          '1.3220600000000005,103.71038999999993',
          '1.3220300000000005,103.71043999999993',
          '1.3219800000000004,103.71053999999994',
          '1.3219400000000003,103.71069999999993',
          '1.3219100000000004,103.71081999999993',
          '1.3218900000000005,103.71092999999993',
          '1.3218800000000004,103.71103999999994',
          '1.3218900000000005,103.71116999999994',
          '1.3219100000000004,103.71130999999994',
          '1.3219400000000003,103.71146999999993',
          '1.3221600000000004,103.71245999999994',
          '1.3223900000000004,103.71345999999994',
          '1.3226100000000005,103.71511999999994',
          '1.3227400000000005,103.71568999999994',
          '1.3230100000000005,103.71688999999994',
          '1.3231700000000004,103.71758999999993',
          '1.3233000000000004,103.71816999999993',
          '1.3234500000000005,103.71883999999993',
          '1.3236000000000006,103.71947999999993',
          '1.3236400000000006,103.71965999999993',
          '1.3237100000000006,103.71997999999994',
          '1.3238200000000007,103.72048999999994',
          '1.3241400000000008,103.72187999999994',
          '1.3242600000000007,103.72240999999994',
          '1.3243100000000008,103.72262999999994',
          '1.3243800000000008,103.72291999999995',
          '1.3246500000000008,103.72412999999995',
          '1.3249100000000007,103.72523999999994',
          '1.3251000000000006,103.72605999999995',
          '1.3252900000000005,103.72691999999995',
          '1.3254700000000006,103.72769999999996',
          '1.3255800000000006,103.72816999999996',
          '1.3256500000000007,103.72847999999996',
          '1.3257400000000006,103.72886999999996',
          '1.3258400000000006,103.72928999999996',
          '1.3260800000000006,103.73023999999997',
          '1.3261700000000005,103.73062999999996',
          '1.3264300000000004,103.73176999999997',
          '1.3266100000000005,103.73256999999997',
          '1.3268300000000006,103.73351999999997',
          '1.3269700000000006,103.73409999999997',
          '1.3270400000000007,103.73437999999997',
          '1.3271200000000007,103.73468999999997',
          '1.3272000000000008,103.73501999999998',
          '1.3273100000000009,103.73546999999998',
          '1.327360000000001,103.73569999999998',
          '1.327390000000001,103.73584999999999',
          '1.3274100000000009,103.73597999999998',
          '1.3274300000000008,103.73611999999999',
          '1.3274400000000008,103.73621999999999',
          '1.327450000000001,103.73634999999999',
          '1.327460000000001,103.73656999999999',
          '1.327460000000001,103.73684999999999',
          '1.327450000000001,103.73707999999999',
          '1.327430000000001,103.73729999999999',
          '1.327410000000001,103.73745',
          '1.3273900000000012,103.73756999999999',
          '1.3273600000000012,103.73772999999998',
          '1.3273300000000012,103.73786999999999',
          '1.3273000000000013,103.73799999999999',
          '1.3272700000000013,103.73810999999999',
          '1.3272200000000012,103.73827999999999',
          '1.3271800000000011,103.73839999999998',
          '1.327140000000001,103.73850999999999',
          '1.3270800000000011,103.73866',
          '1.3270200000000012,103.73879',
          '1.3269200000000012,103.73899999999999',
          '1.3268200000000012,103.7392',
          '1.3267400000000011,103.73935',
          '1.326660000000001,103.73949',
          '1.326550000000001,103.73966',
          '1.326450000000001,103.73981',
          '1.325790000000001,103.74077000000001',
          '1.325080000000001,103.74180000000001',
          '1.3248300000000008,103.74216000000001',
          '1.3246200000000008,103.74246000000001',
          '1.3244000000000007,103.74279000000001',
          '1.3243100000000008,103.74293000000002',
          '1.3241700000000007,103.74316000000002',
          '1.3241000000000007,103.74329000000002',
          '1.3240300000000007,103.74344000000002',
          '1.3239600000000007,103.74361000000002',
          '1.3239000000000007,103.74377000000001',
          '1.3238300000000007,103.74397000000002',
          '1.3237800000000006,103.74414000000002',
          '1.3237400000000006,103.74430000000001',
          '1.3237100000000006,103.74443000000001',
          '1.3236600000000005,103.74466000000001',
          '1.3234800000000004,103.74552000000001',
          '1.3234500000000005,103.74566000000002',
          '1.3234100000000004,103.74584000000002',
          '1.3231000000000004,103.74731000000001',
          '1.3228000000000004,103.74872000000002',
          '1.3227300000000004,103.74905000000003',
          '1.3226800000000003,103.74929000000003',
          '1.3226000000000002,103.74966000000003',
          '1.3225000000000002,103.75008000000004',
          '1.3224600000000002,103.75024000000003',
          '1.3224000000000002,103.75047000000004',
          '1.3223300000000002,103.75074000000004',
          '1.3222500000000001,103.75103000000004',
          '1.32214,103.75139000000004',
          '1.32203,103.75173000000004',
          '1.3219,103.75212000000003',
          '1.3218400000000001,103.75230000000003',
          '1.3214500000000002,103.75349000000003',
          '1.3213800000000002,103.75369000000003',
          '1.3213300000000001,103.75382000000003',
          '1.3212700000000002,103.75397000000004',
          '1.3212100000000002,103.75411000000004',
          '1.3211400000000002,103.75428000000004',
          '1.3210800000000003,103.75442000000004',
          '1.3210300000000001,103.75452000000004',
          '1.3209700000000002,103.75462000000005',
          '1.3209100000000003,103.75472000000005',
          '1.3208500000000003,103.75481000000005',
          '1.3207600000000004,103.75492000000006',
          '1.3206500000000003,103.75504000000005',
          '1.3205300000000004,103.75516000000005',
          '1.3204200000000004,103.75527000000005',
          '1.3202900000000004,103.75539000000005',
          '1.3201900000000004,103.75548000000005',
          '1.3199200000000004,103.75571000000005',
          '1.3195100000000004,103.75605000000004',
          '1.3187900000000004,103.75666000000004',
          '1.3178700000000003,103.75744000000005',
          '1.3176000000000003,103.75767000000005',
          '1.3172200000000003,103.75799000000005',
          '1.3163400000000003,103.75873000000004',
          '1.3159700000000003,103.75905000000004',
          '1.3156600000000003,103.75932000000005',
          '1.3153900000000003,103.75956000000005',
          '1.3151400000000002,103.75979000000005',
          '1.3148800000000003,103.76004000000005',
          '1.3145700000000002,103.76035000000005',
          '1.3144700000000002,103.76044000000005',
          '1.3144000000000002,103.76051000000004',
          '1.3142400000000003,103.76068000000004',
          '1.3135100000000004,103.76140000000004',
          '1.3132000000000004,103.76171000000004',
          '1.3130600000000003,103.76185000000004',
          '1.3120600000000004,103.76284000000004',
          '1.3115400000000004,103.76338000000004',
          '1.3112600000000003,103.76367000000005',
          '1.3109400000000002,103.76399000000005',
          '1.3104600000000002,103.76445000000005',
          '1.3101700000000003,103.76471000000005',
          '1.3098600000000002,103.76498000000005',
          '1.3097300000000003,103.76513000000006',
          '1.3096100000000004,103.76527000000006',
          '1.3094900000000005,103.76540000000006',
          '1.3094300000000005,103.76547000000005',
          '1.3093900000000005,103.76553000000006',
          '1.3093600000000005,103.76558000000006',
          '1.3093400000000006,103.76562000000006',
          '1.3093200000000007,103.76568000000006',
          '1.3093100000000006,103.76572000000006',
          '1.3093000000000006,103.76578000000006',
          '1.3093000000000006,103.76584000000007',
          '1.3093100000000006,103.76593000000007',
          '1.3093200000000007,103.76599000000007',
          '1.3093300000000008,103.76603000000007',
          '1.3093500000000007,103.76608000000007',
          '1.3093800000000007,103.76614000000008',
          '1.3094200000000007,103.76621000000007',
          '1.3094700000000008,103.76628000000007',
          '1.3095500000000009,103.76638000000007',
          '1.3096400000000008,103.76649000000008',
          '1.3098400000000008,103.76671000000007',
          '1.3104400000000007,103.76705000000007',
          '1.3106100000000007,103.76714000000007',
          '1.3108300000000008,103.76725000000008',
          '1.311250000000001,103.76745000000008',
          '1.311800000000001,103.76770000000008',
          '1.311870000000001,103.76767000000008',
          '1.311940000000001,103.76764000000009',
          '1.312000000000001,103.76761000000009',
          '1.312080000000001,103.76757000000009',
          '1.312240000000001,103.76730000000009',
          '1.312340000000001,103.7671300000001',
          '1.3124300000000009,103.76699000000009',
          '1.3125500000000008,103.7668200000001',
          '1.3126500000000008,103.7666900000001',
          '1.3128100000000007,103.7665000000001',
          '1.3128800000000007,103.7664200000001',
          '1.3131000000000008,103.7662000000001',
          '1.3132200000000007,103.76609000000009',
          '1.3133500000000007,103.76598000000008',
          '1.3134900000000007,103.76587000000008',
          '1.3136200000000007,103.76578000000008',
          '1.3138100000000006,103.76566000000008',
          '1.3139300000000005,103.76559000000009',
          '1.3140500000000004,103.76553000000008',
          '1.3142400000000003,103.76544000000008',
          '1.3145700000000002,103.76529000000008',
          '1.3150750000000002,103.76507200000007',
          '1.315075,103.765072',
          '1.31515,103.76504',
          '1.31534,103.76496',
          '1.31539,103.76494',
          '1.31556,103.76487',
          '1.3157800000000002,103.76478',
          '1.3159300000000003,103.76472',
          '1.3160400000000003,103.76468',
          '1.3161300000000002,103.76465',
          '1.3163100000000003,103.76458000000001',
          '1.3164400000000003,103.76453000000001',
          '1.3165500000000003,103.76448',
          '1.3166900000000004,103.76441000000001',
          '1.3167600000000004,103.76437000000001',
          '1.3168300000000004,103.76433000000002',
          '1.3169200000000003,103.76428000000001',
          '1.3170300000000004,103.76422000000001',
          '1.3171000000000004,103.76418000000001',
          '1.3172000000000004,103.76412',
          '1.3172700000000004,103.76408',
          '1.3174000000000003,103.76399',
          '1.3175800000000004,103.76387000000001',
          '1.3177000000000003,103.76379000000001',
          '1.3178200000000002,103.76372000000002',
          '1.3179500000000002,103.76364000000002',
          '1.3181100000000001,103.76353000000002',
          '1.3182800000000001,103.76341000000002',
          '1.31834,103.76334000000003',
          '1.31837,103.76330000000003',
          '1.31841,103.76324000000002',
          '1.31843,103.76321000000003',
          '1.31845,103.76318000000003',
          '1.31846,103.76314000000004',
          '1.31847,103.76309000000003',
          '1.31847,103.76305000000004',
          '1.31846,103.76298000000004',
          '1.31827,103.76289000000004',
          '1.31817,103.76284000000004',
          '1.31804,103.76277000000005',
          '1.3178800000000002,103.76268000000005',
          '1.3177200000000002,103.76258000000004',
          '1.3176100000000002,103.76251000000005',
          '1.3175200000000002,103.76245000000004',
          '1.3174200000000003,103.76238000000005',
          '1.3172900000000003,103.76228000000005',
          '1.3171900000000003,103.76220000000005',
          '1.3170900000000003,103.76210000000005',
          '1.3169100000000002,103.76189000000005',
          '1.3167700000000002,103.76177000000006',
          '1.3165600000000002,103.76159000000006',
          '1.3164200000000001,103.76147000000006',
          '1.3163000000000002,103.76137000000006',
          '1.3161200000000002,103.76127000000005',
          '1.3160200000000002,103.76121000000005',
          '1.31594,103.76116000000005',
          '1.31583,103.76110000000004',
          '1.31573,103.76105000000004',
          '1.31566,103.76102000000004',
          '1.31559,103.76099000000005',
          '1.31548,103.76095000000005',
          '1.31534,103.76091000000005',
          '1.31525,103.76089000000005',
          '1.31515,103.76087000000004',
          '1.31509,103.76086000000004',
          '1.3149300000000002,103.76084000000003',
          '1.3148000000000002,103.76083000000003',
          '1.3147000000000002,103.76083000000003',
          '1.3145800000000003,103.76084000000003',
          '1.3145000000000002,103.76085000000003',
          '1.3143900000000002,103.76087000000004',
          '1.3143500000000001,103.76088000000004',
          '1.3142900000000002,103.76090000000005',
          '1.31424,103.76092000000006',
          '1.3142,103.76094000000006',
          '1.31416,103.76096000000007',
          '1.3141,103.76100000000007',
          '1.3140100000000001,103.76107000000006',
          '1.31387,103.76119000000006',
          '1.3136800000000002,103.76136000000005',
          '1.3136200000000002,103.76141000000005',
          '1.3135300000000003,103.76148000000005',
          '1.3134700000000004,103.76152000000005',
          '1.3132000000000004,103.76171000000005',
          '1.3130600000000003,103.76185000000005',
          '1.3120600000000004,103.76284000000005',
          '1.3115400000000004,103.76338000000005',
          '1.3112600000000003,103.76367000000006',
          '1.3109400000000002,103.76399000000006',
          '1.3104600000000002,103.76445000000007',
          '1.3101700000000003,103.76471000000006',
          '1.3098600000000002,103.76498000000007',
          '1.3096600000000003,103.76514000000006',
          '1.3094200000000003,103.76532000000006',
          '1.3087600000000001,103.76582000000006',
          '1.3085700000000002,103.76596000000006',
          '1.3085000000000002,103.76601000000007',
          '1.3083300000000002,103.76613000000006',
          '1.3076900000000002,103.76658000000006',
          '1.3075000000000003,103.76672000000006',
          '1.3074100000000004,103.76679000000006',
          '1.3072900000000005,103.76689000000006',
          '1.3071600000000005,103.76701000000006',
          '1.3070600000000006,103.76710000000006',
          '1.3069600000000006,103.76720000000006',
          '1.3068200000000005,103.76735000000006',
          '1.3066900000000006,103.76750000000007',
          '1.3066000000000006,103.76761000000008',
          '1.3064000000000007,103.76786000000007',
          '1.3057500000000006,103.76866000000007',
          '1.3055600000000007,103.76892000000007',
          '1.3053200000000007,103.76925000000007',
          '1.3050700000000006,103.76962000000007',
          '1.3049600000000006,103.76979000000007',
          '1.3048700000000006,103.76994000000008',
          '1.3047200000000005,103.77021000000008',
          '1.3046200000000006,103.77040000000008',
          '1.3045300000000006,103.77058000000008',
          '1.3044300000000006,103.77080000000008',
          '1.3043300000000007,103.77102000000008',
          '1.3042700000000007,103.77116000000008',
          '1.3042100000000008,103.77130000000008',
          '1.3041000000000007,103.77155000000008',
          '1.3038900000000007,103.77201000000008',
          '1.3036900000000007,103.77245000000008',
          '1.3035700000000008,103.77270000000007',
          '1.3034000000000008,103.77303000000008',
          '1.3032500000000007,103.77331000000008',
          '1.3029700000000006,103.77379000000008',
          '1.3028700000000006,103.77396000000007',
          '1.3027600000000006,103.77415000000008',
          '1.3022700000000005,103.77500000000008',
          '1.3018400000000006,103.77575000000007',
          '1.3013200000000005,103.77666000000008',
          '1.3008800000000005,103.77742000000008',
          '1.3005000000000004,103.77805000000008',
          '1.3001700000000005,103.77858000000008',
          '1.2999800000000006,103.77889000000008',
          '1.2994200000000007,103.77979000000008',
          '1.2991100000000007,103.78031000000007',
          '1.2988200000000008,103.78081000000007',
          '1.2985300000000009,103.78132000000008',
          '1.2982200000000008,103.78187000000008',
          '1.2980100000000008,103.78225000000009',
          '1.2978100000000008,103.78261000000009',
          '1.2977000000000007,103.78279000000009',
          '1.2976000000000008,103.78295000000008',
          '1.2974700000000008,103.78314000000009',
          '1.2973300000000008,103.78332000000009',
          '1.2972600000000007,103.7834700000001',
          '1.2972100000000006,103.7835800000001',
          '1.2971600000000005,103.7836900000001',
          '1.2971100000000004,103.7837800000001',
          '1.2970500000000005,103.7838700000001',
          '1.2969600000000006,103.78398000000011',
          '1.2968500000000005,103.78410000000011',
          '1.2965900000000006,103.7843600000001',
          '1.2964100000000005,103.7845300000001',
          '1.2962500000000006,103.78468000000011',
          '1.2960600000000007,103.78486000000011',
          '1.2959300000000007,103.7849900000001',
          '1.2958600000000007,103.7850700000001',
          '1.2958000000000007,103.7851400000001',
          '1.2957500000000006,103.78521000000009',
          '1.2956900000000007,103.7853100000001',
          '1.2956400000000006,103.78539000000009',
          '1.2955900000000005,103.7854900000001',
          '1.2955600000000005,103.7855500000001',
          '1.2955400000000006,103.7856000000001',
          '1.2955200000000007,103.7856500000001',
          '1.2955000000000008,103.78571000000011',
          '1.2954800000000009,103.7857800000001',
          '1.2954500000000009,103.7858600000001',
          '1.295430000000001,103.7859100000001',
          '1.295400000000001,103.7859800000001',
          '1.295370000000001,103.7860400000001',
          '1.295320000000001,103.7861400000001',
          '1.295290000000001,103.7861900000001',
          '1.295270000000001,103.7862200000001',
          '1.295230000000001,103.7862700000001',
          '1.295190000000001,103.7863100000001',
          '1.295160000000001,103.7863300000001',
          '1.295130000000001,103.78635000000011',
          '1.295080000000001,103.78637000000012',
          '1.2950000000000008,103.78640000000011',
          '1.2949000000000008,103.78643000000011',
          '1.2947300000000008,103.78647000000011',
          '1.2946300000000008,103.78649000000011',
          '1.2944900000000008,103.78651000000012',
          '1.2944200000000008,103.78652000000012',
          '1.2943100000000007,103.78654000000013',
          '1.2942200000000008,103.78656000000014',
          '1.2941500000000008,103.78658000000014',
          '1.2940800000000008,103.78660000000015',
          '1.2940690000000008,103.78660300000016',
          '1.294069,103.786603',
          '1.29402,103.78662',
          '1.29395,103.78665',
          '1.29388,103.78669',
          '1.29382,103.78672999999999',
          '1.29376,103.78678',
          '1.29367,103.78685999999999',
          '1.2936,103.78692999999998',
          '1.29352,103.78700999999998',
          '1.2934,103.78711999999999',
          '1.29323,103.78726999999999',
          '1.29306,103.78741',
          '1.29279,103.78763',
          '1.2925600000000002,103.78793999999999',
          '1.29228,103.78836',
          '1.29207,103.78869',
          '1.29176,103.78918',
          '1.29162,103.7894',
          '1.29156,103.7895',
          '1.29119,103.79011',
          '1.2910000000000001,103.79042',
          '1.2908800000000002,103.79062',
          '1.2906300000000002,103.79102',
          '1.2904300000000002,103.79133',
          '1.2902200000000001,103.79165',
          '1.2900500000000001,103.7919',
          '1.2899100000000001,103.7921',
          '1.2897800000000001,103.79228',
          '1.2896500000000002,103.79245',
          '1.2895300000000003,103.79260000000001',
          '1.2894100000000004,103.79275000000001',
          '1.2892400000000004,103.79295000000002',
          '1.2891500000000005,103.79306000000003',
          '1.2889600000000005,103.79329000000003',
          '1.2887400000000004,103.79356000000003',
          '1.2886100000000005,103.79373000000002',
          '1.2885200000000006,103.79385000000002',
          '1.2884200000000006,103.79399000000002',
          '1.2883300000000006,103.79412000000002',
          '1.2882200000000006,103.79428000000001',
          '1.2881300000000007,103.79442000000002',
          '1.2880300000000007,103.79458000000001',
          '1.2879200000000006,103.79476000000001',
          '1.2875700000000005,103.79538000000001',
          '1.2872300000000005,103.79601000000001',
          '1.2870300000000006,103.79636',
          '1.2869000000000006,103.79657',
          '1.2868000000000006,103.79673',
          '1.2866800000000007,103.79691',
          '1.2865400000000007,103.79709',
          '1.2864000000000007,103.79728',
          '1.2859600000000007,103.79791',
          '1.2858800000000006,103.79803',
          '1.2858000000000005,103.79814999999999',
          '1.2857000000000005,103.7983',
          '1.2855100000000006,103.79857',
          '1.2852700000000006,103.79892',
          '1.2850900000000005,103.79917999999999',
          '1.2849700000000006,103.79936',
          '1.2848500000000007,103.79955',
          '1.2847400000000007,103.79974',
          '1.2846800000000007,103.79985',
          '1.2846400000000007,103.79993',
          '1.2845900000000006,103.80004000000001',
          '1.2845200000000006,103.8002',
          '1.2844500000000005,103.80038',
          '1.2843900000000006,103.80055',
          '1.2843400000000005,103.80071',
          '1.2842900000000004,103.80087999999999',
          '1.2842600000000004,103.80099999999999',
          '1.2842400000000005,103.80109999999999',
          '1.2842200000000006,103.80121',
          '1.2842100000000005,103.80127',
          '1.2841900000000006,103.80139',
          '1.2841800000000005,103.80147',
          '1.2841700000000005,103.80156',
          '1.2841600000000004,103.80169',
          '1.2841500000000003,103.80180999999999',
          '1.2841400000000003,103.80187999999998',
          '1.2841100000000003,103.80229999999999',
          '1.2840500000000004,103.8033',
          '1.2839300000000005,103.80524999999999',
          '1.2838500000000004,103.80642999999999',
          '1.2837800000000004,103.80748',
          '1.2837400000000003,103.80808999999999',
          '1.2837200000000004,103.80838',
          '1.2836900000000004,103.80889',
          '1.2836300000000005,103.80989000000001',
          '1.2836000000000005,103.81031000000002',
          '1.2835800000000006,103.81058000000002',
          '1.2835600000000007,103.81080000000001',
          '1.2835400000000008,103.81098000000001',
          '1.2835200000000009,103.81114000000001',
          '1.283500000000001,103.81129000000001',
          '1.283480000000001,103.81143000000002',
          '1.283450000000001,103.81161000000002',
          '1.283400000000001,103.81186000000001',
          '1.283310000000001,103.81226000000001',
          '1.2832500000000011,103.81250000000001',
          '1.2831900000000012,103.81272000000001',
          '1.283140000000001,103.81288',
          '1.283090000000001,103.81303000000001',
          '1.2830400000000008,103.81317000000001',
          '1.282980000000001,103.81333000000001',
          '1.282920000000001,103.81349',
          '1.282830000000001,103.81371',
          '1.282740000000001,103.81392',
          '1.282660000000001,103.8141',
          '1.282570000000001,103.81428',
          '1.2824800000000012,103.81446',
          '1.2823600000000013,103.81469',
          '1.2822800000000012,103.81483',
          '1.2822000000000011,103.81497',
          '1.282120000000001,103.8151',
          '1.282020000000001,103.81526',
          '1.281740000000001,103.81569999999999',
          '1.281330000000001,103.81635999999999',
          '1.281060000000001,103.81678',
          '1.2806400000000009,103.81745',
          '1.2805700000000009,103.81756',
          '1.2805000000000009,103.81767',
          '1.280380000000001,103.81787000000001',
          '1.2803300000000009,103.81794000000001',
          '1.2802300000000009,103.8181',
          '1.2800500000000008,103.81838',
          '1.2799100000000008,103.8186',
          '1.2796600000000007,103.819',
          '1.2795900000000007,103.81912',
          '1.2795200000000007,103.81925',
          '1.2794600000000007,103.81936999999999',
          '1.2794100000000006,103.81948',
          '1.2793600000000005,103.81959',
          '1.2793100000000004,103.81971',
          '1.2792800000000004,103.81979',
          '1.2792400000000004,103.8199',
          '1.2791900000000003,103.82005000000001',
          '1.2791300000000003,103.82025000000002',
          '1.2790800000000002,103.82044000000002',
          '1.2790400000000002,103.82061000000002',
          '1.2790200000000003,103.82071000000002',
          '1.2790000000000004,103.82083000000002',
          '1.2789800000000004,103.82096000000001',
          '1.2789600000000005,103.82114000000001',
          '1.2789400000000006,103.82147000000002',
          '1.2789300000000006,103.82174000000002',
          '1.2789200000000005,103.82216000000003',
          '1.2789100000000004,103.82257000000003',
          '1.2789000000000004,103.82278000000002',
          '1.2788900000000003,103.82293000000003',
          '1.2788800000000002,103.82305000000002',
          '1.2788700000000002,103.82317000000002',
          '1.27886,103.82322000000002',
          '1.27885,103.82326000000002',
          '1.2788,103.82338000000001',
          '1.27876,103.82348000000002',
          '1.27873,103.82357000000002',
          '1.27871,103.82364000000001',
          '1.27865,103.82382000000001',
          '1.27861,103.82393000000002',
          '1.27858,103.82401000000002',
          '1.27854,103.82411000000002',
          '1.27849,103.82423000000001',
          '1.2784399999999998,103.82434000000002',
          '1.2783699999999998,103.82448000000002',
          '1.2783099999999998,103.82459000000003',
          '1.2782399999999998,103.82472000000003',
          '1.2781599999999997,103.82486000000003',
          '1.2779399999999996,103.82524000000004',
          '1.2778299999999996,103.82543000000004',
          '1.2777699999999996,103.82554000000005',
          '1.2776999999999996,103.82568000000005',
          '1.2776499999999995,103.82579000000005',
          '1.2775999999999994,103.82592000000005',
          '1.2775599999999994,103.82603000000006',
          '1.2775299999999994,103.82613000000006',
          '1.2774999999999994,103.82625000000006',
          '1.2774799999999995,103.82636000000007',
          '1.2774599999999996,103.82649000000006',
          '1.2774499999999995,103.82663000000007',
          '1.2774499999999995,103.82679000000006',
          '1.2774599999999996,103.82691000000005',
          '1.2774699999999997,103.82700000000006',
          '1.2774899999999996,103.82712000000005',
          '1.2775099999999995,103.82722000000005',
          '1.2775299999999994,103.82730000000005',
          '1.2775499999999993,103.82737000000004',
          '1.2775699999999992,103.82743000000005',
          '1.2775999999999992,103.82750000000004',
          '1.2776599999999991,103.82763000000004',
          '1.277719999999999,103.82774000000005',
          '1.2777699999999992,103.82783000000005',
          '1.2778199999999993,103.82791000000005',
          '1.2778799999999992,103.82800000000005',
          '1.2779399999999992,103.82808000000004',
          '1.2779899999999993,103.82814000000005',
          '1.2780499999999992,103.82820000000005',
          '1.2781399999999992,103.82829000000005',
          '1.278229999999999,103.82837000000005',
          '1.278349999999999,103.82847000000005',
          '1.278429999999999,103.82853000000006',
          '1.278559999999999,103.82862000000006',
          '1.278719999999999,103.82873000000006',
          '1.279019999999999,103.82893000000007',
          '1.2791399999999988,103.82901000000007',
          '1.2793099999999988,103.82914000000007',
          '1.2794799999999988,103.82928000000007',
          '1.2797399999999988,103.82950000000007',
          '1.2799899999999989,103.82973000000007',
          '1.2801499999999988,103.82989000000006',
          '1.2803599999999988,103.83010000000006',
          '1.280579999999999,103.83033000000006',
          '1.2807699999999989,103.83054000000006',
          '1.280989999999999,103.83081000000006',
          '1.281199999999999,103.83110000000006',
          '1.281369999999999,103.83136000000006',
          '1.281499999999999,103.83157000000006',
          '1.281649999999999,103.83182000000005',
          '1.281809999999999,103.83211000000006',
          '1.281959999999999,103.83241000000005',
          '1.2820999999999991,103.83272000000005',
          '1.2822399999999992,103.83306000000005',
          '1.282359999999999,103.83339000000005',
          '1.282559999999999,103.83374000000005',
          '1.2826099999999991,103.83384000000005',
          '1.2826499999999992,103.83392000000005',
          '1.2826799999999992,103.83398000000005',
          '1.2827199999999992,103.83406000000005',
          '1.2827599999999992,103.83415000000005',
          '1.2827899999999992,103.83422000000004',
          '1.282909999999999,103.83454000000005',
          '1.2829799999999991,103.83473000000005',
          '1.2830899999999992,103.83504000000005',
          '1.2831499999999991,103.83521000000005',
          '1.283239999999999,103.83544000000005',
          '1.283269999999999,103.83549000000005',
          '1.283289999999999,103.83551000000006',
          '1.283329999999999,103.83554000000005',
          '1.2833499999999989,103.83555000000005',
          '1.2833799999999989,103.83556000000006',
          '1.283429999999999,103.83557000000006',
          '1.283459999999999,103.83557000000006',
          '1.2835499999999989,103.83556000000006',
          '1.283659999999999,103.83550000000005',
          '1.283759999999999,103.83543000000006',
          '1.283829999999999,103.83538000000006',
          '1.283869999999999,103.83535000000006',
          '1.2838899999999989,103.83533000000006',
          '1.2839099999999988,103.83530000000006',
          '1.2839599999999989,103.83522000000006',
          '1.283999999999999,103.83513000000006',
          '1.284029999999999,103.83505000000007',
          '1.284069999999999,103.83493000000007',
          '1.284119999999999,103.83482000000006',
          '1.284229999999999,103.83483000000007',
          '1.2842799999999992,103.83484000000007',
          '1.2843099999999992,103.83485000000007',
          '1.284329999999999,103.83486000000008',
          '1.2843699999999991,103.83489000000007',
          '1.284399999999999,103.83492000000007',
          '1.284429999999999,103.83497000000007',
          '1.284529999999999,103.83521000000007',
          '1.284479999999999,103.83523000000008',
          '1.2842999999999989,103.83530000000007',
          '1.284169999999999,103.83536000000008',
          '1.283969999999999,103.83546000000008',
          '1.283709999999999,103.83564000000008',
          '1.283489999999999,103.83579000000009',
          '1.2833399999999988,103.83591000000008',
          '1.2832599999999987,103.83601000000009',
          '1.2832299999999988,103.83605000000009',
          '1.2831599999999987,103.83617000000008',
          '1.2828999999999988,103.83650000000009',
          '1.2826499999999987,103.8368800000001',
          '1.2823499999999988,103.8373500000001',
          '1.2822399999999987,103.8375300000001',
          '1.2820399999999987,103.8378400000001',
          '1.2819399999999987,103.8380000000001',
          '1.2817599999999987,103.83830000000009',
          '1.2816999999999987,103.83839000000009',
          '1.2816299999999987,103.83849000000009',
          '1.2815699999999988,103.83857000000009',
          '1.2814599999999987,103.83871000000009',
          '1.2812699999999988,103.8389100000001',
          '1.2811399999999988,103.8390200000001',
          '1.2809199999999987,103.8391800000001',
          '1.2808199999999987,103.83925000000009',
          '1.2807099999999987,103.83932000000009',
          '1.2805899999999988,103.83939000000008',
          '1.2805099999999987,103.83943000000008',
          '1.2803599999999986,103.83954000000008',
          '1.2803299999999986,103.83957000000008',
          '1.2803099999999987,103.83960000000008',
          '1.2802999999999987,103.83962000000008',
          '1.2802899999999986,103.83964000000009',
          '1.2802799999999985,103.8396600000001',
          '1.2802699999999985,103.83969000000009',
          '1.2802599999999984,103.83972000000009',
          '1.2802499999999983,103.83977000000009',
          '1.2802499999999983,103.83980000000008',
          '1.2802499999999983,103.83983000000008',
          '1.2802599999999984,103.83989000000008',
          '1.2802699999999985,103.83993000000008',
          '1.2802899999999984,103.84000000000007',
          '1.2805099999999985,103.84017000000007',
          '1.2806799999999985,103.84030000000007',
          '1.2807999999999984,103.84039000000007',
          '1.2808499999999985,103.84043000000007',
          '1.2813199999999985,103.84083000000007',
          '1.2813679999999985,103.84087000000007',
          '1.281368,103.84087',
          '1.28156,103.84102999999999',
          '1.28176,103.84119999999999',
          '1.28183,103.84125999999999',
          '1.2819,103.84132',
          '1.28219,103.84155',
          '1.28241,103.84172',
          '1.28264,103.84191',
          '1.28285,103.84209',
          '1.2831700000000001,103.84236',
          '1.2833200000000002,103.84249',
          '1.2835000000000003,103.84264',
          '1.2839900000000004,103.84306000000001',
          '1.2841200000000004,103.84317000000001',
          '1.2843100000000003,103.84333000000001',
          '1.2844400000000002,103.84344000000002',
          '1.2845800000000003,103.84356000000001',
          '1.2847300000000004,103.84369000000001',
          '1.2848900000000003,103.84383000000001',
          '1.2849600000000003,103.84389000000002',
          '1.2850100000000004,103.84393000000001',
          '1.2853600000000005,103.84423000000001',
          '1.2857800000000006,103.84457',
          '1.2859900000000006,103.84473',
          '1.2862900000000006,103.84496',
          '1.2865300000000006,103.84515',
          '1.2865700000000007,103.84518',
          '1.2867500000000007,103.84532',
          '1.2869100000000007,103.84546',
          '1.2870300000000006,103.84557000000001',
          '1.2871600000000005,103.84568000000002',
          '1.2873400000000006,103.84584000000001',
          '1.2874000000000005,103.84589000000001',
          '1.2875000000000005,103.84598000000001',
          '1.2877900000000004,103.84625000000001',
          '1.2878900000000004,103.84634000000001',
          '1.2882100000000005,103.84664000000001',
          '1.2883800000000005,103.84679000000001',
          '1.2886500000000005,103.84702000000001',
          '1.2887600000000006,103.84711000000001',
          '1.2890100000000007,103.84730000000002',
          '1.2892700000000006,103.84751000000001',
          '1.2894600000000005,103.84766000000002',
          '1.2895700000000005,103.84775000000002',
          '1.2896400000000006,103.84780000000002',
          '1.2897500000000006,103.84789000000002',
          '1.2899500000000006,103.84805000000001',
          '1.2899900000000006,103.84808000000001',
          '1.2900400000000007,103.84812000000001',
          '1.2900900000000008,103.84816000000001',
          '1.290170000000001,103.84823',
          '1.290320000000001,103.84834000000001',
          '1.2905400000000011,103.84849000000001',
          '1.290700000000001,103.84859000000002',
          '1.2909100000000011,103.84871000000001',
          '1.291100000000001,103.84882000000002',
          '1.291140000000001,103.84884000000002',
          '1.291230000000001,103.84889000000003',
          '1.291430000000001,103.84900000000003',
          '1.291560000000001,103.84907000000003',
          '1.291770000000001,103.84919000000002',
          '1.291900000000001,103.84926000000002',
          '1.292000000000001,103.84931000000002',
          '1.2921900000000008,103.84939000000001',
          '1.292300000000001,103.84943000000001',
          '1.292450000000001,103.84949000000002',
          '1.292560000000001,103.84953000000002',
          '1.292730000000001,103.84960000000001',
          '1.2929500000000012,103.84970000000001',
          '1.2931000000000012,103.84977',
          '1.2933300000000012,103.84989',
          '1.2934900000000011,103.84998',
          '1.293580000000001,103.85003',
          '1.2938000000000012,103.85014000000001',
          '1.2940400000000012,103.85027000000001',
          '1.2942100000000012,103.85036000000001',
          '1.2943600000000013,103.85044',
          '1.2945300000000013,103.85055000000001',
          '1.2946500000000012,103.85062',
          '1.2947600000000012,103.8507',
          '1.2948700000000013,103.85078',
          '1.2949800000000014,103.85088',
          '1.2951200000000014,103.85101',
          '1.2952000000000015,103.85109',
          '1.2954400000000015,103.85132',
          '1.2955200000000016,103.85139',
          '1.2956100000000015,103.85148',
          '1.2959200000000015,103.85177999999999',
          '1.2960300000000016,103.85188',
          '1.2961000000000016,103.85194',
          '1.2962500000000017,103.85208',
          '1.2965300000000017,103.85235',
          '1.2967400000000018,103.85252',
          '1.2969000000000017,103.85265',
          '1.2970300000000017,103.85276',
          '1.2971100000000018,103.85282000000001',
          '1.2973700000000017,103.85303',
          '1.2974100000000017,103.85306',
          '1.2975100000000017,103.85315',
          '1.2976100000000017,103.85324',
          '1.2977700000000016,103.85338',
          '1.2978500000000017,103.85345',
          '1.2980200000000017,103.85359',
          '1.2981500000000017,103.8537',
          '1.2985000000000018,103.85398',
          '1.2985500000000019,103.85403000000001',
          '1.298870000000002,103.85429',
          '1.299050000000002,103.85443000000001',
          '1.299480000000002,103.85480000000001',
          '1.2996700000000019,103.85496',
          '1.299740000000002,103.85502000000001',
          '1.3000210000000019,103.85524300000002',
          '1.300021,103.855243',
          '1.30013,103.85533',
          '1.30034,103.85549999999999',
          '1.3004200000000001,103.85556999999999',
          '1.30051,103.85564999999998',
          '1.30065,103.85576999999998',
          '1.3006900000000001,103.85579999999997',
          '1.3007700000000002,103.85586999999997',
          '1.3009500000000003,103.85601999999997',
          '1.3011100000000002,103.85615999999997',
          '1.3013100000000002,103.85632999999997',
          '1.3014800000000002,103.85647999999998',
          '1.3018100000000001,103.85677999999997',
          '1.3021600000000002,103.85708999999997',
          '1.3023500000000001,103.85724999999996',
          '1.3025300000000002,103.85739999999997',
          '1.3026100000000003,103.85746999999996',
          '1.3026900000000003,103.85753999999996',
          '1.3028100000000002,103.85764999999996',
          '1.3029600000000003,103.85779999999997',
          '1.3032400000000004,103.85806999999997',
          '1.3034300000000003,103.85825999999997',
          '1.3035700000000003,103.85839999999997',
          '1.3036800000000004,103.85851999999997',
          '1.3038500000000004,103.85868999999997',
          '1.3039400000000003,103.85878999999997',
          '1.3041100000000003,103.85897999999997',
          '1.3042800000000003,103.85917999999998',
          '1.3044800000000003,103.85941999999999',
          '1.3047000000000004,103.85968999999999',
          '1.3050300000000004,103.86009999999999',
          '1.3050500000000003,103.86012',
          '1.3051400000000002,103.86022',
          '1.3053000000000001,103.8604',
          '1.3056400000000001,103.86079',
          '1.3058800000000002,103.86106',
          '1.30607,103.86126',
          '1.3063,103.86151',
          '1.30633,103.86153999999999',
          '1.30642,103.86164',
          '1.3064399999999998,103.86166999999999',
          '1.3065099999999998,103.86173999999998',
          '1.3065999999999998,103.86183999999999',
          '1.3068399999999998,103.86208999999998',
          '1.3072099999999998,103.86248999999998',
          '1.3073499999999998,103.86264999999997',
          '1.3076299999999998,103.86293999999998',
          '1.30781,103.86313999999999',
          '1.30809,103.86343999999998',
          '1.30834,103.86370999999998',
          '1.3084900000000002,103.86386999999998',
          '1.30851,103.86388999999998',
          '1.30863,103.86403999999999',
          '1.30869,103.86411999999999',
          '1.30874,103.86418999999998',
          '1.3088,103.86427999999998',
          '1.30885,103.86435999999998',
          '1.30889,103.86443999999997',
          '1.3089300000000001,103.86452999999997',
          '1.3089700000000002,103.86462999999998',
          '1.3090000000000002,103.86471999999998',
          '1.3090300000000001,103.86482999999998',
          '1.3090700000000002,103.86497999999999',
          '1.3091000000000002,103.86509999999998',
          '1.3092400000000002,103.86570999999998',
          '1.3093100000000002,103.86599999999999',
          '1.30933,103.86608999999999',
          '1.30942,103.86648999999998',
          '1.30951,103.86690999999999',
          '1.30957,103.86716999999999',
          '1.30964,103.86742999999998',
          '1.3096999999999999,103.86763999999998',
          '1.30978,103.86790999999998',
          '1.30989,103.86826999999998',
          '1.30997,103.86848999999998',
          '1.31009,103.86886999999999',
          '1.31018,103.86917999999999',
          '1.3102399999999998,103.86940999999999',
          '1.31028,103.86959999999999',
          '1.31035,103.86990999999999',
          '1.31043,103.8702',
          '1.31051,103.87048',
          '1.31057,103.87066',
          '1.31064,103.87082',
          '1.3107,103.87095',
          '1.31076,103.87106999999999',
          '1.3108199999999999,103.87118',
          '1.3108799999999998,103.87128',
          '1.31093,103.87136',
          '1.3109899999999999,103.87143999999999',
          '1.3110899999999999,103.87156999999999',
          '1.3112099999999998,103.87172',
          '1.3113899999999998,103.87194',
          '1.3114999999999999,103.87208',
          '1.31157,103.87217',
          '1.31165,103.87226',
          '1.31187,103.87253',
          '1.31203,103.87273',
          '1.31216,103.87291',
          '1.31224,103.87304',
          '1.31233,103.87318',
          '1.31241,103.87332',
          '1.3125,103.87347000000001',
          '1.31257,103.87358000000002',
          '1.31263,103.87367000000002',
          '1.31269,103.87377000000002',
          '1.31273,103.87384000000002',
          '1.3128,103.87397000000001',
          '1.31283,103.87404000000001',
          '1.3129,103.8742',
          '1.313,103.87447',
          '1.31306,103.87467000000001',
          '1.31311,103.87481000000001',
          '1.31315,103.87492000000002',
          '1.31316,103.87495000000001',
          '1.31319,103.87506000000002',
          '1.3132000000000001,103.87509000000001',
          '1.31322,103.87515000000002',
          '1.31329,103.87537000000002',
          '1.31336,103.87557000000002',
          '1.3135800000000002,103.87621000000003',
          '1.3136200000000002,103.87633000000002',
          '1.3137600000000003,103.87675000000003',
          '1.3137900000000002,103.87684000000003',
          '1.3139300000000003,103.87728000000003',
          '1.3140800000000004,103.87772000000002',
          '1.3141700000000003,103.87798000000002',
          '1.3142100000000003,103.87810000000002',
          '1.3142600000000004,103.87826000000001',
          '1.3143000000000005,103.87842',
          '1.3143300000000004,103.87856000000001',
          '1.3144300000000004,103.87909',
          '1.3145900000000004,103.87996000000001',
          '1.3147300000000004,103.88071000000001',
          '1.3148400000000005,103.88131000000001',
          '1.3148700000000004,103.88145000000002',
          '1.3148800000000005,103.88151000000002',
          '1.3149100000000005,103.88167000000001',
          '1.3149300000000004,103.88179000000001',
          '1.3149400000000004,103.88186',
          '1.3149900000000005,103.88208',
          '1.3150400000000007,103.8823',
          '1.3150800000000007,103.88248',
          '1.3151300000000008,103.88275',
          '1.3151600000000008,103.8829',
          '1.3152000000000008,103.88310000000001',
          '1.3152300000000008,103.88326',
          '1.3152600000000008,103.88342',
          '1.3153100000000009,103.88372',
          '1.315350000000001,103.88394',
          '1.315420000000001,103.88432999999999',
          '1.315530000000001,103.88489999999999',
          '1.315640000000001,103.88551999999999',
          '1.315750000000001,103.88611999999999',
          '1.315810000000001,103.88645999999999',
          '1.3158600000000011,103.88673999999999',
          '1.3159100000000012,103.88699999999999',
          '1.3159600000000014,103.88726999999999',
          '1.3160400000000014,103.88770999999998',
          '1.3161000000000014,103.88802999999999',
          '1.3161700000000014,103.88838999999999',
          '1.3162500000000015,103.88879999999999',
          '1.3163200000000015,103.88918',
          '1.3164600000000015,103.88994',
          '1.3165000000000016,103.89021',
          '1.3165200000000015,103.89034',
          '1.3165500000000014,103.89052',
          '1.3165700000000014,103.89065',
          '1.3166500000000014,103.8912',
          '1.3166800000000014,103.89135999999999',
          '1.3167500000000014,103.89174',
          '1.3167800000000014,103.89189',
          '1.3168500000000014,103.89195000000001',
          '1.3169100000000014,103.89200000000001',
          '1.3169700000000013,103.89205000000001',
          '1.3170200000000014,103.89209000000001',
          '1.3170700000000015,103.89212',
          '1.3171200000000016,103.89214000000001',
          '1.3171500000000016,103.89215000000002',
          '1.3172300000000017,103.89217000000002',
          '1.3176900000000016,103.89206000000001',
          '1.3177800000000015,103.89204000000001',
          '1.3178600000000016,103.89202',
          '1.3180000000000016,103.89199',
          '1.3179900000000015,103.89186000000001',
          '1.3179900000000015,103.89182000000001',
          '1.31799,103.89182',
          '1.31799,103.89182',
          '1.31793,103.89179',
          '1.3179100000000001,103.89177',
          '1.3179,103.89168',
          '1.3179100000000001,103.89165',
          '1.3179400000000001,103.89162999999999',
          '1.31797,103.89162999999999',
          '1.318,103.89162999999999',
          '1.31802,103.89165999999999',
          '1.31802,103.89175999999999',
          '1.31799,103.89182',
          '1.31799,103.89186',
          '1.318,103.89198999999999',
          '1.3183,103.89192999999999',
          '1.31863,103.89186',
          '1.31877,103.89183',
          '1.31894,103.89179',
          '1.3191,103.89175',
          '1.31933,103.89169',
          '1.31944,103.89166',
          '1.3197999999999999,103.89156',
          '1.31991,103.89153',
          '1.3199999999999998,103.89151',
          '1.32035,103.89143',
          '1.3204399999999998,103.89141',
          '1.32079,103.89134',
          '1.3211,103.89128',
          '1.3213,103.89124',
          '1.32168,103.89116',
          '1.32186,103.89112',
          '1.322,103.89109',
          '1.32226,103.89103',
          '1.32246,103.89098',
          '1.32274,103.89092',
          '1.3228,103.89094',
          '1.32286,103.89097',
          '1.3229,103.89101',
          '1.32294,103.89104999999999',
          '1.32297,103.89107999999999',
          '1.32302,103.89111999999999',
          '1.32312,103.89120999999999',
          '1.3232000000000002,103.89128999999998',
          '1.3232500000000003,103.89133999999999',
          '1.3232900000000003,103.89138999999999',
          '1.3233300000000003,103.89144999999999',
          '1.3234000000000004,103.89156',
          '1.3235600000000003,103.89251',
          '1.3236100000000004,103.89283',
          '1.3237300000000003,103.89357',
          '1.3238000000000003,103.89401',
          '1.3238300000000003,103.89421999999999',
          '1.3238500000000002,103.89437999999998',
          '1.32387,103.89460999999999',
          '1.32389,103.89483999999999',
          '1.32419,103.89631999999999',
          '1.32426,103.89666999999999',
          '1.32431,103.89694999999999',
          '1.3244500000000001,103.89762999999999',
          '1.3245500000000001,103.89813',
          '1.3246200000000001,103.89847999999999',
          '1.3247200000000001,103.89896999999999',
          '1.32505,103.90066999999999',
          '1.32512,103.90101999999999',
          '1.3253700000000002,103.90231999999999',
          '1.3254200000000003,103.90258999999999',
          '1.3254900000000003,103.90299999999999',
          '1.3256000000000003,103.90351999999999',
          '1.3256900000000003,103.9039',
          '1.3258100000000002,103.90435',
          '1.3259100000000001,103.90469999999999',
          '1.3260600000000002,103.9052',
          '1.3261500000000002,103.90549',
          '1.3262200000000002,103.90572',
          '1.3262300000000002,103.90574000000001',
          '1.3262500000000002,103.90582',
          '1.32627,103.90589',
          '1.3262800000000001,103.90592',
          '1.32647,103.90656',
          '1.32663,103.90709',
          '1.3269199999999999,103.90802',
          '1.32706,103.90840999999999',
          '1.32714,103.90861999999998',
          '1.32721,103.90877999999998',
          '1.32732,103.90902999999997',
          '1.32742,103.90923999999997',
          '1.32749,103.90937999999997',
          '1.32768,103.90973999999997',
          '1.32781,103.90995999999997',
          '1.32809,103.91041999999997',
          '1.3282,103.91060999999998',
          '1.3282800000000001,103.91074999999998',
          '1.3283600000000002,103.91087999999998',
          '1.32865,103.91138999999998',
          '1.3288200000000001,103.91170999999999',
          '1.3289900000000001,103.91204999999998',
          '1.32915,103.91237999999998',
          '1.32954,103.91321999999998',
          '1.32969,103.91352999999998',
          '1.33014,103.91447999999998',
          '1.3305900000000002,103.91542999999999',
          '1.3311600000000001,103.91664999999999',
          '1.33135,103.91703999999999',
          '1.33149,103.91734999999998',
          '1.33155,103.91747999999998',
          '1.33188,103.91819999999998',
          '1.33213,103.91873999999999',
          '1.3322100000000001,103.91890999999998',
          '1.33234,103.91917999999998',
          '1.33246,103.91933999999998',
          '1.33254,103.91944999999998',
          '1.33257,103.91947999999998',
          '1.3326,103.91951999999998',
          '1.33277,103.91974999999998',
          '1.33291,103.91993999999998',
          '1.33315,103.92027999999998',
          '1.33324,103.92041999999998',
          '1.33328,103.92050999999998',
          '1.33329,103.92053999999997',
          '1.3333000000000002,103.92057999999997',
          '1.3333100000000002,103.92065999999997',
          '1.3333100000000002,103.92071999999997',
          '1.3333000000000002,103.92080999999997',
          '1.3332800000000002,103.92087999999997',
          '1.3332600000000003,103.92092999999997',
          '1.3332400000000004,103.92096999999997',
          '1.3332200000000005,103.92100999999997',
          '1.3332000000000006,103.92103999999996',
          '1.3331700000000006,103.92106999999996',
          '1.3330700000000006,103.92114999999995',
          '1.3330000000000006,103.92119999999996',
          '1.3329400000000007,103.92122999999995',
          '1.3328800000000007,103.92124999999996',
          '1.3328300000000006,103.92125999999996',
          '1.3327500000000005,103.92126999999996',
          '1.3326200000000006,103.92126999999996',
          '1.3324400000000005,103.92125999999996',
          '1.3320200000000004,103.92122999999997',
          '1.3316400000000004,103.92120999999996',
          '1.3315400000000004,103.92120999999996',
          '1.3314400000000004,103.92121999999996',
          '1.3313500000000005,103.92122999999997',
          '1.3312400000000004,103.92124999999997',
          '1.3311700000000004,103.92126999999998',
          '1.3311100000000005,103.92128999999998',
          '1.3310200000000005,103.92131999999998',
          '1.3309000000000006,103.92137999999998',
          '1.3308000000000006,103.92143999999999',
          '1.3306900000000006,103.92151999999999',
          '1.3305600000000006,103.92163999999998',
          '1.3304000000000007,103.92180999999998',
          '1.3301700000000007,103.92206999999998',
          '1.3299200000000007,103.92247999999998',
          '1.3298000000000008,103.92269999999998',
          '1.3297400000000008,103.92282999999998',
          '1.3296800000000009,103.92296999999998',
          '1.3296100000000008,103.92315999999998',
          '1.329550000000001,103.92334999999999',
          '1.3295000000000008,103.92351999999998',
          '1.3294600000000008,103.92367999999998',
          '1.3294300000000008,103.92380999999997',
          '1.3294100000000009,103.92392999999997',
          '1.329390000000001,103.92406999999997',
          '1.329360000000001,103.92433999999997',
          '1.329350000000001,103.92460999999997',
          '1.329350000000001,103.92476999999997',
          '1.329360000000001,103.92495999999997',
          '1.329370000000001,103.92510999999998',
          '1.329390000000001,103.92527999999997',
          '1.329420000000001,103.92547999999998',
          '1.329450000000001,103.92563999999997',
          '1.329530000000001,103.92595999999998',
          '1.329560000000001,103.92607999999997',
          '1.329590000000001,103.92619999999997',
          '1.329670000000001,103.92646999999997',
          '1.329690000000001,103.92653999999996',
          '1.329560000000001,103.92655999999997',
          '1.328930000000001,103.92662999999996',
          '1.328720000000001,103.92665999999996',
          '1.328350000000001,103.92670999999996',
          '1.328210000000001,103.92672999999996',
          '1.327660000000001,103.92679999999996',
          '1.3270700000000009,103.92688999999996',
          '1.3268200000000008,103.92692999999996',
          '1.3266300000000009,103.92695999999995',
          '1.326570000000001,103.92696999999995',
          '1.3264300000000009,103.92698999999996',
          '1.326140000000001,103.92702999999996',
          '1.325610000000001,103.92708999999996',
          '1.3254500000000011,103.92710999999997',
          '1.325370000000001,103.92711999999997',
          '1.3252800000000011,103.92712999999998',
          '1.3250500000000012,103.92715999999997',
          '1.3248400000000011,103.92718999999997',
          '1.3246500000000012,103.92720999999997',
          '1.3244500000000012,103.92722999999998',
          '1.3242500000000013,103.92724999999999',
          '1.3240700000000012,103.92727',
          '1.3239400000000012,103.92732',
          '1.3239000000000012,103.92734',
          '1.3238600000000011,103.92737',
          '1.323820000000001,103.92741',
          '1.323780000000001,103.92746',
          '1.3237600000000012,103.92748999999999',
          '1.3237300000000012,103.92754',
          '1.3237100000000013,103.92757999999999',
          '1.3236900000000014,103.92763',
          '1.3237200000000013,103.92779999999999',
          '1.3238200000000013,103.92831999999999',
          '1.3238800000000013,103.92858999999999',
          '1.3239300000000014,103.92882999999999',
          '1.3240000000000014,103.92909999999999',
          '1.3240400000000014,103.92925999999999',
          '1.3240600000000013,103.92934999999999',
          '1.3240800000000013,103.92943999999999',
          '1.3241000000000012,103.92955999999998',
          '1.3241300000000011,103.92974999999998',
          '1.3241600000000011,103.92994799999998',
          '1.32416,103.929948',
          '1.32421,103.93028',
          '1.32424,103.93052',
          '1.3242800000000001,103.93097',
          '1.3243,103.93142',
          '1.32432,103.93167',
          '1.32433,103.93182999999999',
          '1.32434,103.93195999999999',
          '1.3243500000000001,103.93224',
          '1.32437,103.93254999999999',
          '1.3243800000000001,103.93280999999999',
          '1.32441,103.93319999999999',
          '1.3244500000000001,103.93355999999999',
          '1.3244900000000002,103.93386999999998',
          '1.32461,103.93482999999999',
          '1.32467,103.93531999999999',
          '1.32471,103.93538999999998',
          '1.32474,103.93542999999998',
          '1.32478,103.93546999999998',
          '1.32481,103.93548999999999',
          '1.32483,103.93549999999999',
          '1.32488,103.93552',
          '1.32494,103.93554',
          '1.32508,103.93552',
          '1.3253000000000001,103.93548',
          '1.32549,103.93544',
          '1.32572,103.93539',
          '1.32595,103.93534',
          '1.3261,103.93531',
          '1.32656,103.93522',
          '1.32679,103.93517',
          '1.32707,103.93512',
          '1.32716,103.93509999999999',
          '1.3276,103.93502',
          '1.32771,103.93499999999999',
          '1.32781,103.93497999999998',
          '1.3278999999999999,103.93495999999998',
          '1.32815,103.93490999999997',
          '1.3289199999999999,103.93475999999997',
          '1.3293,103.93469999999996',
          '1.32958,103.93463999999996',
          '1.32977,103.93459999999996',
          '1.3299699999999999,103.93455999999996',
          '1.33049,103.93444999999996',
          '1.33063,103.93440999999996',
          '1.33086,103.93434999999995',
          '1.33114,103.93427999999996',
          '1.33135,103.93422999999996',
          '1.33146,103.93420999999995',
          '1.3315400000000002,103.93418999999994',
          '1.3316900000000003,103.93416999999994',
          '1.3319100000000004,103.93413999999994',
          '1.3322400000000003,103.93408999999994',
          '1.3325600000000004,103.93404999999994',
          '1.3327000000000004,103.93402999999994',
          '1.3328700000000004,103.93399999999994',
          '1.3330300000000004,103.93396999999995',
          '1.3332400000000004,103.93391999999994',
          '1.3335500000000005,103.93384999999995',
          '1.3337100000000004,103.93381999999995',
          '1.3339500000000004,103.93376999999995',
          '1.3341500000000004,103.93372999999995',
          '1.3343800000000003,103.93368999999996',
          '1.3345300000000004,103.93366999999995',
          '1.3345700000000005,103.93366999999995',
          '1.3346000000000005,103.93367999999995',
          '1.3346600000000004,103.93369999999996',
          '1.3348300000000004,103.93366999999996',
          '1.3351200000000003,103.93361999999996',
          '1.3352100000000002,103.93359999999996',
          '1.3353100000000002,103.93358999999995',
          '1.3353700000000002,103.93355999999996',
          '1.3354100000000002,103.93354999999995',
          '1.3354800000000002,103.93352999999995',
          '1.3357600000000003,103.93347999999995',
          '1.3359100000000004,103.93343999999995',
          '1.3359700000000003,103.93341999999994',
          '1.3360200000000004,103.93338999999995',
          '1.3360700000000005,103.93336999999994',
          '1.3361200000000006,103.93334999999993',
          '1.3362400000000005,103.93330999999993',
          '1.3363600000000004,103.93326999999994',
          '1.3365300000000004,103.93322999999994',
          '1.3366900000000004,103.93318999999994',
          '1.3368200000000003,103.93315999999994',
          '1.3369300000000004,103.93312999999995',
          '1.3370500000000003,103.93309999999995',
          '1.3371800000000003,103.93306999999996',
          '1.3373000000000002,103.93303999999996',
          '1.33742,103.93301999999996',
          '1.33754,103.93299999999995',
          '1.33765,103.93297999999994',
          '1.33773,103.93296999999994',
          '1.33792,103.93295999999994',
          '1.338,103.93294999999993',
          '1.33802,103.93302999999993',
          '1.33808,103.93330999999993',
          '1.33812,103.93348999999994',
          '1.33815,103.93360999999993',
          '1.33818,103.93370999999993',
          '1.33821,103.93379999999993',
          '1.33826,103.93393999999994',
          '1.33831,103.93405999999993',
          '1.3383500000000002,103.93413999999993',
          '1.3384000000000003,103.93423999999993',
          '1.3385100000000003,103.93443999999994',
          '1.3385500000000004,103.93450999999993',
          '1.3386200000000004,103.93461999999994',
          '1.3386800000000003,103.93470999999994',
          '1.3387400000000003,103.93478999999994',
          '1.3388100000000003,103.93486999999993',
          '1.3388700000000002,103.93493999999993',
          '1.3390900000000003,103.93516999999993',
          '1.3392200000000003,103.93529999999993',
          '1.3393500000000003,103.93542999999993',
          '1.3395000000000004,103.93556999999993',
          '1.3395400000000004,103.93560999999993',
          '1.3397700000000003,103.93582999999992',
          '1.3403400000000003,103.93628999999993',
          '1.3405100000000003,103.93641999999993',
          '1.3407900000000004,103.93660999999993',
          '1.3409100000000003,103.93667999999992',
          '1.3410100000000003,103.93673999999993',
          '1.3411100000000002,103.93679999999993',
          '1.3419700000000003,103.93723999999993',
          '1.3425300000000002,103.93752999999994',
          '1.3431000000000002,103.93782999999993',
          '1.3436000000000001,103.93807999999993',
          '1.3437800000000002,103.93815999999993',
          '1.3438900000000003,103.93819999999992',
          '1.3439600000000003,103.93821999999993',
          '1.3440400000000003,103.93823999999994',
          '1.3441000000000003,103.93824999999994',
          '1.3441900000000002,103.93825999999994',
          '1.3442900000000002,103.93826999999995',
          '1.3443800000000001,103.93826999999995',
          '1.3445600000000002,103.93826999999995',
          '1.3447000000000002,103.93826999999995',
          '1.3448100000000003,103.93826999999995',
          '1.3448000000000002,103.93838999999994',
          '1.3447900000000002,103.93872999999994',
          '1.3448000000000002,103.93904999999994',
          '1.3448200000000001,103.93931999999994',
          '1.34484,103.93953999999994',
          '1.34487,103.93976999999994',
          '1.3449,103.93996999999995',
          '1.34494,103.94016999999995',
          '1.34498,103.94035999999996',
          '1.34501,103.94048999999995',
          '1.34507,103.94069999999995',
          '1.34511,103.94080999999996',
          '1.34518,103.94099999999996',
          '1.34525,103.94117999999996',
          '1.3453300000000001,103.94135999999996',
          '1.34539,103.94148999999996',
          '1.34549,103.94167999999996',
          '1.34556,103.94179999999996',
          '1.34563,103.94191999999995',
          '1.34569,103.94200999999995',
          '1.3457700000000001,103.94210999999996',
          '1.34589,103.94226999999995',
          '1.34603,103.94244999999995',
          '1.34612,103.94246999999996',
          '1.34621,103.94248999999996',
          '1.3462699999999999,103.94249999999997',
          '1.3463299999999998,103.94250999999997',
          '1.3464399999999999,103.94251999999997',
          '1.3465099999999999,103.94252999999998',
          '1.3465699999999998,103.94252999999998',
          '1.3467299999999998,103.94252999999998',
          '1.3468499999999997,103.94245999999998',
          '1.3469899999999997,103.94238999999999',
          '1.3471799999999996,103.94229999999999',
          '1.3472999999999995,103.94224999999999',
          '1.3474299999999995,103.94219999999999',
          '1.3476199999999994,103.94213999999998',
          '1.3479199999999993,103.94205999999998',
          '1.3482399999999994,103.94197999999999',
          '1.3486199999999995,103.94188999999999',
          '1.3494499999999996,103.94167999999999',
          '1.3498099999999995,103.94158999999999',
          '1.3500299999999996,103.94153999999999',
          '1.3501799999999997,103.94151',
          '1.3503499999999997,103.94148',
          '1.3505099999999997,103.94145999999999',
          '1.3506399999999996,103.94144999999999',
          '1.3508299999999995,103.94143999999999',
          '1.3509799999999996,103.94142999999998',
          '1.3511499999999996,103.94142999999998',
          '1.3515799999999996,103.94142999999998',
          '1.3516799999999995,103.94142999999998',
          '1.3517799999999995,103.94142999999998',
          '1.3517799999999995,103.94154999999998',
          '1.3517799999999995,103.94284999999998',
          '1.3517799999999995,103.94318999999997',
          '1.3517799999999995,103.94329999999998',
          '1.3517799999999995,103.94374199999999',
          '1.35178,103.943742',
          '1.35178,103.94384',
          '1.35178,103.94398',
          '1.35179,103.94418',
          '1.3518000000000001,103.94429000000001',
          '1.3518100000000002,103.94438000000001',
          '1.35183,103.94448000000001',
          '1.35185,103.94456000000001',
          '1.35187,103.94463',
          '1.35191,103.94475',
          '1.35195,103.94485',
          '1.352,103.94496000000001',
          '1.35207,103.94509000000001',
          '1.3521500000000002,103.94522',
          '1.3522500000000002,103.94536000000001',
          '1.3523300000000003,103.94546000000001',
          '1.3523900000000002,103.94553',
          '1.3524300000000002,103.94557',
          '1.3525300000000002,103.94567',
          '1.3526200000000002,103.94575',
          '1.3527000000000002,103.94581000000001',
          '1.3528100000000003,103.94589',
          '1.3528800000000003,103.94593',
          '1.3529700000000002,103.94598',
          '1.3530800000000003,103.94603000000001',
          '1.3532400000000002,103.94609000000001',
          '1.3533900000000003,103.94614000000001',
          '1.3535100000000002,103.94617000000001',
          '1.3536400000000002,103.94619000000002',
          '1.3538400000000002,103.94621000000002',
          '1.3540800000000002,103.94622000000003',
          '1.35434,103.94622000000003',
          '1.35434,103.94601000000003',
          '1.35434,103.94578000000003',
          '1.35434,103.94569000000003',
          '1.35436,103.94560000000003',
          '1.3544100000000001,103.94549000000002',
          '1.35447,103.94538000000001',
          '1.3545200000000002,103.94531000000002',
          '1.3549700000000002,103.94484000000001',
          '1.3551200000000003,103.94470000000001',
          '1.3553400000000004,103.94448000000001',
          '1.3553900000000005,103.94441000000002',
          '1.3554500000000005,103.94430000000001',
          '1.3554700000000004,103.94418000000002',
          '1.3554800000000005,103.94412000000001',
          '1.3555600000000005,103.9441',
          '1.3557300000000005,103.94408',
          '1.3558400000000006,103.94407',
          '1.3559400000000006,103.94407',
          '1.3561200000000007,103.94407',
          '1.3565300000000007,103.94408',
          '1.3566800000000008,103.94408',
          '1.3566800000000008,103.94384',
          '1.3566900000000008,103.94363',
          '1.3567100000000007,103.94347',
          '1.3567300000000007,103.94338',
          '1.3567500000000006,103.94329',
          '1.3567700000000005,103.94322000000001',
          '1.3568000000000005,103.94314000000001',
          '1.3568500000000006,103.94303000000001',
          '1.3569200000000006,103.94290000000001',
          '1.3570000000000007,103.94278000000001',
          '1.3570700000000007,103.94269000000001',
          '1.3571500000000007,103.94260000000001',
          '1.3572300000000008,103.94252000000002',
          '1.3573400000000009,103.94243000000002',
          '1.3574400000000009,103.94236000000002',
          '1.3575600000000008,103.94229000000003',
          '1.3576800000000007,103.94223000000002',
          '1.3578000000000006,103.94218000000002',
          '1.3578900000000005,103.94215000000003',
          '1.3579700000000006,103.94213000000002',
          '1.3580900000000005,103.94211000000001',
          '1.3581900000000005,103.94210000000001',
          '1.3582900000000004,103.94209000000001',
          '1.3586500000000004,103.94208',
          '1.3589000000000004,103.94208',
          '1.3590100000000005,103.94208',
          '1.3592700000000004,103.94207',
          '1.3596100000000004,103.94206',
          '1.3598800000000004,103.94205',
          '1.3600800000000004,103.94205',
          '1.3603100000000004,103.94203999999999',
          '1.3604400000000003,103.94203999999999',
          '1.3607300000000002,103.94203999999999',
          '1.3608600000000002,103.94203999999999',
          '1.3609300000000002,103.94203999999999',
          '1.36105,103.94205',
          '1.36114,103.94206',
          '1.36125,103.94208',
          '1.36137,103.94211',
          '1.3614899999999999,103.94215',
          '1.3616099999999998,103.9422',
          '1.3617499999999998,103.94227',
          '1.3618499999999998,103.94233',
          '1.3619099999999997,103.94237',
          '1.3619799999999997,103.94242',
          '1.3620399999999997,103.94247',
          '1.3621099999999997,103.94253',
          '1.3621699999999997,103.94259000000001',
          '1.3622499999999997,103.94268000000001',
          '1.3623099999999997,103.94275',
          '1.3623899999999998,103.94286000000001',
          '1.3624799999999997,103.94300000000001',
          '1.3625899999999997,103.94317000000001',
          '1.3626799999999997,103.94333',
          '1.3627599999999997,103.94347',
          '1.3628699999999998,103.94366000000001',
          '1.3628899999999997,103.9437',
          '1.3630499999999997,103.94397000000001',
          '1.3631799999999996,103.94418',
          '1.3633199999999996,103.9444',
          '1.3635199999999996,103.94465',
          '1.3636799999999996,103.94481999999999',
          '1.3637899999999996,103.94493',
          '1.3639399999999997,103.94506',
          '1.3640699999999997,103.94515',
          '1.3642199999999998,103.94524',
          '1.3643899999999998,103.94532',
          '1.3645599999999998,103.94538999999999',
          '1.3649599999999997,103.94551999999999',
          '1.3652799999999998,103.94561999999999',
          '1.3655499999999998,103.94569999999999',
          '1.36577,103.94578999999999',
          '1.36604,103.9459',
          '1.3663299999999998,103.94601',
          '1.3668099999999999,103.94616',
          '1.3671399999999998,103.94625',
          '1.3671699999999998,103.94626000000001',
          '1.3672999999999997,103.94630000000001',
          '1.3675299999999997,103.94636000000001',
          '1.3678099999999997,103.94642000000002',
          '1.3683699999999996,103.94652000000002',
          '1.3694299999999997,103.94671000000002',
          '1.3698299999999997,103.94679000000002',
          '1.3701899999999996,103.94685000000003',
          '1.3704299999999996,103.94689000000002',
          '1.3708099999999996,103.94694000000003',
          '1.3708899999999997,103.94695000000003',
          '1.3709599999999997,103.94696000000003',
          '1.3710899999999997,103.94698000000004',
          '1.3712299999999997,103.94701000000003',
          '1.3713399999999998,103.94704000000003',
          '1.3715299999999997,103.94710000000003',
          '1.3716299999999997,103.94714000000003',
          '1.3717099999999998,103.94717000000003',
          '1.3717799999999998,103.94720000000002',
          '1.3719399999999997,103.94728000000002',
          '1.3719899999999998,103.94731000000002',
          '1.3721199999999998,103.94738000000001',
          '1.3722599999999998,103.94745',
          '1.3726199999999997,103.94764',
          '1.3727899999999997,103.94773',
          '1.3728599999999997,103.94777',
          '1.3729199999999997,103.9478',
          '1.3729899999999997,103.94783',
          '1.3730499999999997,103.94785',
          '1.3730899999999997,103.94786',
          '1.3731399999999998,103.94787000000001',
          '1.3731999999999998,103.94788000000001',
          '1.3732699999999998,103.94789000000002',
          '1.3733999999999997,103.94790000000002',
          '1.3735499999999998,103.94790000000002',
          '1.3736399999999998,103.94790000000002',
          '1.3737099999999998,103.94790000000002',
          '1.3739199999999998,103.94790000000002',
          '1.3741199999999998,103.94791000000002',
          '1.3742299999999998,103.94792000000002',
          '1.37431,103.94793000000003',
          '1.37436,103.94794000000003',
          '1.37446,103.94797000000003',
          '1.37456,103.94801000000002',
          '1.37474,103.94810000000003',
          '1.3749500000000001,103.94821000000003',
          '1.3752000000000002,103.94834000000003',
          '1.3753900000000001,103.94843000000003',
          '1.37551,103.94853000000003',
          '1.37546,103.94867000000004',
          '1.3754,103.94884000000003',
          '1.37534,103.94903000000004',
          '1.37527,103.94924000000003',
          '1.37521,103.94942000000003',
          '1.37515,103.94963000000003',
          '1.37511,103.94976000000003',
          '1.37508,103.94987000000003',
          '1.37505,103.95000000000003',
          '1.3750300000000002,103.95009000000003',
          '1.3750200000000001,103.95014000000003',
          '1.3750000000000002,103.95024000000004',
          '1.3749900000000002,103.95031000000003',
          '1.3749700000000002,103.95045000000003',
          '1.3749500000000003,103.95085000000003',
          '1.3749500000000003,103.95093000000003',
          '1.3749500000000003,103.95114000000002',
          '1.3749500000000003,103.95122000000002',
          '1.3748000000000002,103.95122000000002',
          '1.3741700000000003,103.95123000000002',
          '1.3740700000000003,103.95123000000002',
          '1.3739500000000004,103.95123000000002',
          '1.3738600000000005,103.95123000000002',
          '1.3736500000000005,103.95124000000003',
          '1.3735200000000005,103.95124000000003',
          '1.3734400000000004,103.95124000000003',
          '1.3733500000000005,103.95123000000002',
          '1.3733000000000004,103.95122000000002',
          '1.3732000000000004,103.95119000000003',
          '1.3731000000000004,103.95115000000003',
          '1.3730100000000005,103.95110000000003',
          '1.3729000000000005,103.95102000000003',
          '1.3727000000000005,103.95085000000003',
          '1.3725100000000006,103.95068000000003',
          '1.3723200000000007,103.95049000000003',
          '1.3722900000000007,103.95046000000004',
          '1.3721300000000007,103.95030000000004',
          '1.3720800000000006,103.95025000000004',
          '1.3720400000000006,103.95020000000004',
          '1.3719900000000005,103.95012000000004',
          '1.3719600000000005,103.95007000000004',
          '1.3719400000000006,103.95002000000004',
          '1.3719300000000005,103.94999000000004',
          '1.3719200000000005,103.94992000000005',
          '1.3719200000000005,103.94984000000005',
          '1.3719300000000005,103.94976000000005',
          '1.3719400000000006,103.94971000000005',
          '1.3719600000000005,103.94963000000006',
          '1.3719800000000004,103.94955000000006',
          '1.3720300000000005,103.94935000000005',
          '1.3720500000000004,103.94928000000006',
          '1.3720800000000004,103.94918000000006',
          '1.3721100000000004,103.94907000000005',
          '1.3721500000000004,103.94891000000005',
          '1.3721900000000005,103.94879000000006',
          '1.3722400000000006,103.94868000000005',
          '1.3723000000000005,103.94857000000005',
          '1.3724400000000005,103.94834000000004',
          '1.3725500000000006,103.94817000000005',
          '1.3725920000000007,103.94809400000004',
        ],
      },
      {
        value: 1,
        path: [
          '1.372592,103.948094',
          '1.37265,103.94799',
          '1.37273,103.94783000000001',
          '1.37255,103.94774000000001',
          '1.37234,103.94763',
          '1.3720599999999998,103.9475',
          '1.3719299999999999,103.94744',
          '1.3718599999999999,103.9474',
          '1.37177,103.94735',
          '1.3716,103.94727',
          '1.3714499999999998,103.94721',
          '1.3713199999999999,103.94716',
          '1.3712099999999998,103.94712',
          '1.3711499999999999,103.94709999999999',
          '1.3710699999999998,103.94707999999999',
          '1.3710099999999998,103.94706999999998',
          '1.3709399999999998,103.94705999999998',
          '1.3708599999999997,103.94704999999998',
          '1.3706499999999997,103.94701999999998',
          '1.3704199999999997,103.94698999999999',
          '1.3701599999999998,103.94695999999999',
          '1.3699799999999998,103.94693999999998',
          '1.3697199999999998,103.94688999999998',
          '1.3693699999999998,103.94681999999999',
          '1.3687199999999997,103.94669999999999',
          '1.3683299999999998,103.94663',
          '1.3677,103.94651',
          '1.3674899999999999,103.94647',
          '1.36726,103.94641',
          '1.36713,103.94638',
          '1.3666,103.94624',
          '1.36617,103.94611',
          '1.3656400000000002,103.94594000000001',
          '1.3649300000000002,103.94572000000001',
          '1.3645700000000003,103.94562',
          '1.3643800000000004,103.94556',
          '1.3642100000000004,103.9455',
          '1.3640300000000003,103.94541',
          '1.3638400000000004,103.94529999999999',
          '1.3637200000000005,103.94520999999999',
          '1.3636100000000004,103.94510999999999',
          '1.3635000000000004,103.94498999999999',
          '1.3633300000000004,103.94478',
          '1.3631700000000004,103.94454999999999',
          '1.3629900000000004,103.94425',
          '1.3627600000000004,103.94384',
          '1.3625800000000003,103.94351999999999',
          '1.3624500000000004,103.94328999999999',
          '1.3623300000000005,103.94308999999998',
          '1.3622000000000005,103.94287999999999',
          '1.3620900000000005,103.94272999999998',
          '1.3620100000000004,103.94263999999998',
          '1.3619300000000003,103.94255999999999',
          '1.3618500000000002,103.94248999999999',
          '1.3617700000000001,103.94242',
          '1.3616700000000002,103.94236',
          '1.36159,103.94230999999999',
          '1.3615000000000002,103.94227',
          '1.3613800000000003,103.94223',
          '1.3612900000000003,103.94220999999999',
          '1.3612100000000003,103.94219999999999',
          '1.3611300000000002,103.94218999999998',
          '1.3609000000000002,103.94218999999998',
          '1.3606500000000001,103.94218999999998',
          '1.3605200000000002,103.94219999999999',
          '1.36044,103.94219999999999',
          '1.3603100000000001,103.94219999999999',
          '1.35989,103.94219999999999',
          '1.35942,103.94219999999999',
          '1.35901,103.94218999999998',
          '1.3588900000000002,103.94218999999998',
          '1.35851,103.94218999999998',
          '1.3583100000000001,103.94218999999998',
          '1.35817,103.94219999999999',
          '1.35809,103.94220999999999',
          '1.358,103.94223',
          '1.35789,103.94225999999999',
          '1.3578000000000001,103.94228999999999',
          '1.3577000000000001,103.94232999999998',
          '1.3576000000000001,103.94237999999999',
          '1.35752,103.94242999999999',
          '1.3574300000000001,103.94248999999999',
          '1.35732,103.94257999999999',
          '1.35722,103.94266999999999',
          '1.35714,103.94275999999999',
          '1.35709,103.94282',
          '1.3570399999999998,103.94288999999999',
          '1.3569899999999997,103.94296999999999',
          '1.3569299999999997,103.94308',
          '1.3568899999999997,103.94317',
          '1.3568599999999997,103.94327',
          '1.3568399999999998,103.94335',
          '1.3568099999999998,103.94348',
          '1.3567999999999998,103.94354999999999',
          '1.3567899999999997,103.94364999999999',
          '1.3567799999999997,103.94380999999998',
          '1.3567799999999997,103.94403999999999',
          '1.3567799999999997,103.94407999999999',
          '1.3566799999999997,103.94407999999999',
          '1.3565299999999996,103.94407999999999',
          '1.3561199999999995,103.94406999999998',
          '1.3559399999999995,103.94406999999998',
          '1.3558399999999995,103.94406999999998',
          '1.3557299999999994,103.94407999999999',
          '1.3555599999999994,103.94409999999999',
          '1.3554799999999994,103.94412',
          '1.3554799999999994,103.94384',
          '1.3554699999999993,103.94360999999999',
          '1.3554699999999993,103.94344',
          '1.3554499999999994,103.94332',
          '1.3554399999999993,103.94329',
          '1.3554199999999994,103.94319',
          '1.3553499999999994,103.94308',
          '1.3552199999999994,103.94292',
          '1.3551399999999993,103.94286',
          '1.3549299999999993,103.94265999999999',
          '1.3548599999999993,103.94263',
          '1.3548099999999992,103.94260999999999',
          '1.354729999999999,103.94258999999998',
          '1.3546399999999992,103.94257999999998',
          '1.3543599999999991,103.94256999999998',
          '1.354139999999999,103.94255999999997',
          '1.353929999999999,103.94256999999998',
          '1.3538499999999989,103.94259999999997',
          '1.3537799999999989,103.94262999999997',
          '1.353579999999999,103.94279999999996',
          '1.353409999999999,103.94296999999996',
          '1.3533299999999988,103.94304999999996',
          '1.3532499999999987,103.94313999999996',
          '1.3531699999999987,103.94319999999996',
          '1.3530399999999987,103.94324999999996',
          '1.3529599999999986,103.94325999999997',
          '1.3528099999999985,103.94325999999997',
          '1.3525999999999985,103.94325999999997',
          '1.3524099999999986,103.94326999999997',
          '1.3517799999999986,103.94329999999997',
          '1.3517799999999986,103.94374199999997',
          '1.35178,103.943742',
          '1.35178,103.94384',
          '1.35178,103.94398',
          '1.35179,103.94418',
          '1.3518000000000001,103.94429000000001',
          '1.3518100000000002,103.94438000000001',
          '1.3517100000000002,103.94440000000002',
          '1.3516500000000002,103.94441000000002',
          '1.3513900000000003,103.94443000000003',
          '1.3508600000000004,103.94447000000002',
          '1.3508300000000004,103.94422000000003',
          '1.3508000000000004,103.94390000000003',
          '1.3507900000000004,103.94374000000003',
          '1.3507900000000004,103.94355000000003',
          '1.3507900000000004,103.94337000000003',
          '1.3507900000000004,103.94310000000003',
          '1.3508000000000004,103.94281000000002',
          '1.3508000000000004,103.94268000000002',
          '1.3508000000000004,103.94250000000002',
          '1.3506300000000004,103.94252000000003',
          '1.3505400000000005,103.94253000000003',
          '1.3504800000000006,103.94252000000003',
          '1.3504400000000005,103.94249000000003',
          '1.3504100000000006,103.94244000000003',
          '1.3503900000000006,103.94237000000004',
          '1.3503800000000006,103.94229000000004',
          '1.3503700000000005,103.94223000000004',
          '1.3503500000000006,103.94220000000004',
          '1.3503200000000006,103.94218000000004',
          '1.3502300000000007,103.94218000000004',
          '1.3500500000000006,103.94224000000004',
          '1.3500000000000005,103.94204000000003',
          '1.3499600000000005,103.94187000000004',
          '1.3499500000000004,103.94167000000003',
          '1.3497000000000003,103.94173000000004',
          '1.3494400000000004,103.94179000000004',
          '1.3486400000000005,103.94199000000005',
          '1.3482700000000005,103.94208000000005',
          '1.3479000000000005,103.94217000000005',
          '1.3477500000000004,103.94221000000005',
          '1.3476100000000004,103.94225000000004',
          '1.3474700000000004,103.94230000000005',
          '1.3473100000000005,103.94237000000004',
          '1.3471200000000005,103.94247000000004',
          '1.3469100000000005,103.94260000000004',
          '1.3467300000000004,103.94273000000004',
          '1.3465800000000003,103.94285000000004',
          '1.3464500000000004,103.94296000000004',
          '1.3463500000000004,103.94305000000004',
          '1.3462300000000005,103.94293000000005',
          '1.3459300000000005,103.94258000000005',
          '1.3457500000000004,103.94235000000005',
          '1.3455900000000005,103.94211000000004',
          '1.3454400000000004,103.94185000000004',
          '1.3453500000000005,103.94169000000005',
          '1.3452600000000006,103.94152000000005',
          '1.3451400000000007,103.94123000000005',
          '1.3450600000000006,103.94103000000004',
          '1.3450000000000006,103.94086000000004',
          '1.3449600000000006,103.94075000000004',
          '1.3449300000000006,103.94066000000004',
          '1.3448900000000006,103.94052000000003',
          '1.3448400000000005,103.94034000000003',
          '1.3448200000000006,103.94026000000004',
          '1.3447900000000006,103.94011000000003',
          '1.3447700000000007,103.93998000000003',
          '1.3447500000000008,103.93983000000003',
          '1.3447300000000009,103.93963000000002',
          '1.3447200000000008,103.93949000000002',
          '1.3447100000000007,103.93928000000002',
          '1.3447100000000007,103.93914000000002',
          '1.3447100000000007,103.93897000000003',
          '1.3447100000000007,103.93871000000003',
          '1.3446800000000008,103.93863000000003',
          '1.3446600000000009,103.93858000000003',
          '1.344640000000001,103.93854000000003',
          '1.344610000000001,103.93850000000003',
          '1.344570000000001,103.93847000000004',
          '1.3445200000000008,103.93844000000004',
          '1.3444700000000007,103.93842000000004',
          '1.3443900000000006,103.93839000000004',
          '1.3443000000000007,103.93838000000004',
          '1.3442200000000006,103.93837000000003',
          '1.3441600000000007,103.93836000000003',
          '1.3440700000000008,103.93834000000003',
          '1.3439700000000008,103.93831000000003',
          '1.3438500000000009,103.93827000000003',
          '1.3437400000000008,103.93822000000003',
          '1.3436000000000008,103.93815000000004',
          '1.3434200000000007,103.93806000000004',
          '1.3430600000000008,103.93788000000004',
          '1.3424900000000008,103.93758000000004',
          '1.3419400000000008,103.93730000000004',
          '1.3413200000000007,103.93698000000003',
          '1.3411000000000006,103.93687000000003',
          '1.3409700000000007,103.93680000000003',
          '1.3408500000000008,103.93673000000004',
          '1.3407700000000007,103.93668000000004',
          '1.3406400000000007,103.93659000000004',
          '1.3404700000000007,103.93647000000004',
          '1.3402900000000006,103.93634000000004',
          '1.3397400000000006,103.93589000000004',
          '1.3396000000000006,103.93576000000004',
          '1.3394900000000005,103.93565000000004',
          '1.3394200000000005,103.93559000000003',
          '1.3393200000000005,103.93549000000003',
          '1.3392000000000006,103.93537000000003',
          '1.3390300000000006,103.93521000000004',
          '1.3387600000000006,103.93493000000004',
          '1.3387000000000007,103.93486000000004',
          '1.3386300000000007,103.93478000000005',
          '1.3385800000000005,103.93471000000005',
          '1.3385300000000004,103.93464000000006',
          '1.3384800000000003,103.93457000000006',
          '1.3384400000000003,103.93451000000006',
          '1.3383600000000002,103.93437000000006',
          '1.33831,103.93428000000006',
          '1.33826,103.93418000000005',
          '1.33823,103.93411000000006',
          '1.3382100000000001,103.93407000000006',
          '1.3381800000000001,103.93399000000007',
          '1.33814,103.93386000000007',
          '1.3381200000000002,103.93379000000007',
          '1.3380900000000002,103.93368000000007',
          '1.3380600000000002,103.93356000000007',
          '1.3380300000000003,103.93343000000007',
          '1.3379800000000002,103.93323000000007',
          '1.3379400000000001,103.93318000000006',
          '1.3379,103.93315000000007',
          '1.3378400000000001,103.93312000000007',
          '1.33776,103.93309000000008',
          '1.33765,103.93311000000008',
          '1.33755,103.93313000000009',
          '1.33742,103.9331500000001',
          '1.33731,103.9331700000001',
          '1.33719,103.93319000000011',
          '1.3370600000000001,103.93321000000012',
          '1.3369600000000001,103.93323000000012',
          '1.3368000000000002,103.93327000000012',
          '1.3366600000000002,103.93330000000012',
          '1.3365400000000003,103.93333000000011',
          '1.3363700000000003,103.93337000000011',
          '1.3362800000000004,103.93339000000012',
          '1.3361500000000004,103.93342000000011',
          '1.3360800000000004,103.93343000000012',
          '1.3360300000000003,103.93343000000012',
          '1.3359700000000003,103.93342000000011',
          '1.3359100000000004,103.93344000000012',
          '1.3357600000000003,103.93348000000012',
          '1.3354800000000002,103.93353000000012',
          '1.3354100000000002,103.93355000000012',
          '1.3353700000000002,103.93356000000013',
          '1.3353100000000002,103.93359000000012',
          '1.3352100000000002,103.93360000000013',
          '1.3351200000000003,103.93362000000013',
          '1.3348300000000004,103.93367000000013',
          '1.3346600000000004,103.93370000000013',
          '1.3346100000000003,103.93374000000013',
          '1.3345900000000004,103.93375000000013',
          '1.3345600000000004,103.93376000000013',
          '1.3344100000000003,103.93379000000013',
          '1.3341800000000004,103.93383000000013',
          '1.3340000000000003,103.93386000000012',
          '1.3338500000000002,103.93389000000012',
          '1.3337300000000003,103.93391000000013',
          '1.3335800000000002,103.93394000000012',
          '1.3332200000000003,103.93401000000011',
          '1.3330400000000002,103.93404000000011',
          '1.3328800000000003,103.9340700000001',
          '1.3327600000000004,103.93409000000011',
          '1.3325700000000005,103.9341200000001',
          '1.3322500000000004,103.9341600000001',
          '1.3319200000000004,103.9342100000001',
          '1.3317100000000004,103.9342500000001',
          '1.3315700000000004,103.9342900000001',
          '1.3314900000000003,103.93431000000011',
          '1.3313900000000003,103.93433000000012',
          '1.3310800000000003,103.93440000000011',
          '1.3305100000000003,103.9345200000001',
          '1.3299800000000004,103.93463000000011',
          '1.3295700000000004,103.93472000000011',
          '1.3293100000000004,103.93477000000011',
          '1.3289200000000005,103.93484000000011',
          '1.3279100000000006,103.93504000000011',
          '1.3278300000000005,103.93506000000012',
          '1.3276300000000005,103.93510000000012',
          '1.3271400000000004,103.93520000000012',
          '1.3268000000000004,103.93526000000013',
          '1.3265700000000005,103.93530000000013',
          '1.3261100000000006,103.93540000000013',
          '1.3257400000000006,103.93547000000012',
          '1.3254800000000007,103.93552000000012',
          '1.3249700000000006,103.93561000000012',
          '1.3248200000000006,103.93564000000012',
          '1.3247100000000005,103.93566000000013',
          '1.3246800000000005,103.93566000000013',
          '1.3245800000000005,103.93564000000012',
          '1.3245500000000006,103.93535000000011',
          '1.3244800000000005,103.93475000000011',
          '1.3243700000000005,103.9338800000001',
          '1.3243300000000005,103.9336000000001',
          '1.3243100000000005,103.9334700000001',
          '1.3242000000000005,103.9328300000001',
          '1.3241800000000006,103.9327100000001',
          '1.3241600000000007,103.9325700000001',
          '1.3241400000000008,103.9324300000001',
          '1.3241000000000007,103.9320800000001',
          '1.3240800000000008,103.93188000000009',
          '1.3240700000000007,103.93177000000009',
          '1.3240400000000008,103.93145000000008',
          '1.3240200000000009,103.93131000000008',
          '1.324000000000001,103.93117000000008',
          '1.323980000000001,103.93102000000007',
          '1.323930000000001,103.93071000000008',
          '1.323870000000001,103.93033000000007',
          '1.323800000000001,103.92985000000007',
          '1.323740000000001,103.92939000000007',
          '1.323699000000001,103.92901900000007',
          '1.323699,103.929019',
          '1.32369,103.92894',
          '1.3236700000000001,103.92878999999999',
          '1.3236400000000001,103.92859999999999',
          '1.3236,103.92839',
          '1.3235700000000001,103.92823999999999',
          '1.3235400000000002,103.92808',
          '1.3235000000000001,103.92787',
          '1.32342,103.92739999999999',
          '1.3234000000000001,103.92728',
          '1.3233700000000002,103.92707',
          '1.3233300000000001,103.92682',
          '1.3232700000000002,103.92648000000001',
          '1.3232400000000002,103.92630000000001',
          '1.3232000000000002,103.92605000000002',
          '1.32319,103.92600000000002',
          '1.3231600000000001,103.92584000000002',
          '1.32309,103.92540000000002',
          '1.32305,103.92519000000003',
          '1.32299,103.92487000000003',
          '1.32291,103.92450000000002',
          '1.32285,103.92425000000003',
          '1.32277,103.92396000000002',
          '1.3227,103.92371000000003',
          '1.32261,103.92341000000003',
          '1.3224200000000002,103.92286000000003',
          '1.3219100000000001,103.92144000000003',
          '1.32173,103.92092000000004',
          '1.32149,103.92024000000004',
          '1.32143,103.92006000000003',
          '1.32139,103.91995000000003',
          '1.32136,103.91987000000003',
          '1.3213300000000001,103.91977000000003',
          '1.3213000000000001,103.91965000000003',
          '1.3212800000000002,103.91957000000004',
          '1.3212600000000003,103.91946000000003',
          '1.3212200000000003,103.91926000000002',
          '1.3211900000000003,103.91910000000003',
          '1.3211600000000003,103.91895000000002',
          '1.3211200000000003,103.91880000000002',
          '1.3210900000000003,103.91870000000002',
          '1.3210300000000004,103.91851000000001',
          '1.3209600000000004,103.91830000000002',
          '1.3207600000000004,103.91771000000001',
          '1.3206400000000005,103.91739000000001',
          '1.3202700000000005,103.91632000000001',
          '1.3202100000000005,103.91612',
          '1.3201500000000006,103.91594',
          '1.3199900000000007,103.91548',
          '1.3197900000000007,103.91489',
          '1.3197400000000006,103.91474',
          '1.3196400000000006,103.91444999999999',
          '1.3195300000000005,103.91414999999999',
          '1.3195000000000006,103.91408',
          '1.3192600000000005,103.9134',
          '1.3192300000000006,103.91328999999999',
          '1.3191600000000006,103.91301999999999',
          '1.3190800000000005,103.91263999999998',
          '1.3190700000000004,103.91258999999998',
          '1.3189400000000004,103.91190999999998',
          '1.3189100000000005,103.91176999999998',
          '1.3188500000000005,103.91148999999997',
          '1.3188000000000004,103.91122999999997',
          '1.3187600000000004,103.91101999999998',
          '1.3187200000000003,103.91078999999998',
          '1.3187000000000004,103.91066999999998',
          '1.3186700000000005,103.91048999999998',
          '1.3186500000000005,103.91037999999998',
          '1.3186300000000006,103.91027999999997',
          '1.3185800000000005,103.91001999999997',
          '1.3185200000000006,103.90967999999998',
          '1.3184900000000006,103.90953999999998',
          '1.3184100000000005,103.90915999999997',
          '1.3183800000000006,103.90901999999997',
          '1.3183200000000006,103.90873999999997',
          '1.3182800000000006,103.90856999999997',
          '1.3182300000000005,103.90836999999996',
          '1.3181800000000004,103.90814999999996',
          '1.3181400000000003,103.90795999999996',
          '1.3180000000000003,103.90723999999996',
          '1.3179700000000003,103.90708999999995',
          '1.3179500000000004,103.90695999999996',
          '1.3178900000000004,103.90664999999996',
          '1.3178500000000004,103.90639999999996',
          '1.3178100000000004,103.90617999999996',
          '1.3177700000000003,103.90595999999996',
          '1.3177500000000004,103.90582999999997',
          '1.3177200000000004,103.90565999999997',
          '1.3177000000000005,103.90552999999997',
          '1.3176200000000005,103.90516999999997',
          '1.3175900000000005,103.90501999999996',
          '1.3175000000000006,103.90454999999996',
          '1.3174600000000005,103.90436999999996',
          '1.3174200000000005,103.90415999999996',
          '1.3173500000000005,103.90381999999997',
          '1.3173200000000005,103.90366999999996',
          '1.3172800000000005,103.90346999999996',
          '1.3172300000000003,103.90319999999996',
          '1.3171100000000004,103.90255999999995',
          '1.3170300000000004,103.90213999999995',
          '1.3170000000000004,103.90197999999995',
          '1.3169800000000005,103.90187999999995',
          '1.3168800000000005,103.90134999999995',
          '1.3168200000000005,103.90102999999995',
          '1.3167700000000004,103.90075999999995',
          '1.3167300000000004,103.90055999999994',
          '1.3166600000000004,103.90020999999994',
          '1.3166000000000004,103.89992999999994',
          '1.3165700000000005,103.89975999999994',
          '1.3165400000000005,103.89958999999995',
          '1.3164900000000004,103.89929999999994',
          '1.3163800000000003,103.89864999999995',
          '1.3162900000000004,103.89817999999994',
          '1.3161700000000005,103.89753999999994',
          '1.3160400000000005,103.89686999999994',
          '1.3158600000000005,103.89593999999994',
          '1.3158200000000004,103.89572999999994',
          '1.3157400000000004,103.89531999999994',
          '1.3156900000000002,103.89506999999995',
          '1.3156500000000002,103.89485999999995',
          '1.3156300000000003,103.89475999999995',
          '1.3155600000000003,103.89441999999995',
          '1.3155200000000002,103.89417999999995',
          '1.3155000000000003,103.89406999999994',
          '1.3154400000000004,103.89377999999994',
          '1.3153800000000004,103.89349999999993',
          '1.3153300000000003,103.89325999999993',
          '1.3152900000000003,103.89306999999992',
          '1.3152500000000003,103.89287999999992',
          '1.3152200000000003,103.89274999999992',
          '1.3151900000000003,103.89260999999992',
          '1.3151300000000004,103.89231999999991',
          '1.3151200000000003,103.89227999999991',
          '1.3150900000000003,103.89213999999991',
          '1.3153800000000002,103.89217999999991',
          '1.3155000000000001,103.89219999999992',
          '1.31559,103.89221999999992',
          '1.3156700000000001,103.89223999999993',
          '1.3158,103.89226999999993',
          '1.3159,103.89228999999993',
          '1.31604,103.89230999999994',
          '1.31623,103.89231999999994',
          '1.3162800000000001,103.89231999999994',
          '1.31634,103.89231999999994',
          '1.31638,103.89231999999994',
          '1.3164600000000002,103.89230999999994',
          '1.3165200000000001,103.89229999999993',
          '1.3165900000000001,103.89228999999993',
          '1.3167000000000002,103.89226999999993',
          '1.3168600000000001,103.89223999999993',
          '1.31698,103.89221999999992',
          '1.31709,103.89219999999992',
          '1.3172300000000001,103.89216999999992',
          '1.31769,103.89205999999992',
          '1.31778,103.89203999999991',
          '1.31786,103.8920199999999',
          '1.318,103.89198999999991',
          '1.31799,103.89185999999991',
          '1.31799,103.89181999999991',
          '1.31799,103.89182',
          '1.31799,103.89182',
          '1.31793,103.89179',
          '1.3179100000000001,103.89177',
          '1.3179,103.89168',
          '1.3179100000000001,103.89165',
          '1.3179400000000001,103.89162999999999',
          '1.31797,103.89162999999999',
          '1.318,103.89162999999999',
          '1.31802,103.89165999999999',
          '1.31802,103.89175999999999',
          '1.31799,103.89182',
          '1.31799,103.89186',
          '1.318,103.89198999999999',
          '1.3183,103.89192999999999',
          '1.31863,103.89186',
          '1.31877,103.89183',
          '1.31894,103.89179',
          '1.3191,103.89175',
          '1.31933,103.89169',
          '1.31944,103.89166',
          '1.31952,103.89158',
          '1.31958,103.89152',
          '1.31963,103.89147',
          '1.3196700000000001,103.89143',
          '1.3197100000000002,103.89138',
          '1.3197500000000002,103.89133',
          '1.3197800000000002,103.89129',
          '1.3198200000000002,103.89122',
          '1.31977,103.89101000000001',
          '1.31956,103.89015',
          '1.31952,103.88999000000001',
          '1.31949,103.88985000000001',
          '1.31945,103.88965',
          '1.31929,103.88883',
          '1.31919,103.88832',
          '1.31902,103.88747',
          '1.31892,103.88696999999999',
          '1.31888,103.88676999999998',
          '1.31885,103.88661999999998',
          '1.31877,103.88621999999998',
          '1.31869,103.88580999999998',
          '1.3185799999999999,103.88581999999998',
          '1.3183999999999998,103.88582999999998',
          '1.3182399999999999,103.88583999999999',
          '1.31811,103.88583999999999',
          '1.3179999999999998,103.88581999999998',
          '1.3178999999999998,103.88577999999998',
          '1.3178599999999998,103.88572999999998',
          '1.31767,103.88562999999998',
          '1.31751,103.88554999999998',
          '1.31744,103.88550999999998',
          '1.31721,103.88539999999998',
          '1.31715,103.88536999999998',
          '1.3169600000000001,103.88528999999998',
          '1.31682,103.88522999999998',
          '1.3166600000000002,103.88515999999998',
          '1.3164500000000001,103.88505999999998',
          '1.31638,103.88503999999998',
          '1.3162600000000002,103.88503999999998',
          '1.3159300000000003,103.88495999999998',
          '1.3157600000000003,103.88489999999997',
          '1.3157100000000002,103.88488999999997',
          '1.3156200000000002,103.88487999999997',
          '1.3155300000000003,103.88489999999997',
          '1.3154600000000003,103.88490999999998',
          '1.3154300000000003,103.88491999999998',
          '1.3154000000000003,103.88491999999998',
          '1.3153300000000003,103.88490999999998',
          '1.3150200000000003,103.88484999999997',
          '1.3148300000000004,103.88481999999998',
          '1.3140000000000003,103.88466999999997',
          '1.3135900000000003,103.88455999999996',
          '1.3134800000000002,103.88399999999997',
          '1.31343,103.88374999999998',
          '1.3133400000000002,103.88329999999998',
          '1.3133100000000002,103.88313999999998',
          '1.31326,103.88288999999999',
          '1.31321,103.88265999999999',
          '1.31315,103.88236999999998',
          '1.3131,103.88214999999998',
          '1.31308,103.88205999999998',
          '1.3130600000000001,103.88195999999998',
          '1.3130300000000001,103.88179999999998',
          '1.3129700000000002,103.88149999999999',
          '1.31292,103.88125',
          '1.3129000000000002,103.88113999999999',
          '1.3128700000000002,103.88096999999999',
          '1.3128100000000003,103.88056999999999',
          '1.3127700000000002,103.88025999999999',
          '1.3127300000000002,103.87997999999999',
          '1.3127000000000002,103.87977',
          '1.3126400000000003,103.87938999999999',
          '1.3125900000000001,103.87909999999998',
          '1.3125600000000002,103.87889999999997',
          '1.3125000000000002,103.87850999999998',
          '1.3124500000000001,103.87816999999998',
          '1.31238,103.87776999999998',
          '1.3123500000000001,103.87760999999999',
          '1.3123300000000002,103.87749999999998',
          '1.3123000000000002,103.87733999999999',
          '1.3122700000000003,103.87719999999999',
          '1.3122400000000003,103.87707999999999',
          '1.3121900000000002,103.87692',
          '1.31214,103.87678',
          '1.31209,103.87665',
          '1.31197,103.87635',
          '1.31186,103.87606',
          '1.31178,103.87585',
          '1.31174,103.87575',
          '1.31171,103.87567',
          '1.31168,103.87559',
          '1.31164,103.87548',
          '1.3116299999999999,103.87545',
          '1.3115999999999999,103.87537',
          '1.31157,103.87531',
          '1.3115199999999998,103.87519',
          '1.3114099999999997,103.87491',
          '1.3113599999999996,103.87478',
          '1.3112899999999996,103.87459',
          '1.3111799999999996,103.87431',
          '1.3110599999999997,103.87402999999999',
          '1.3109799999999996,103.87383999999999',
          '1.3108699999999995,103.87357999999999',
          '1.3107499999999996,103.87329999999999',
          '1.3106099999999996,103.87297999999998',
          '1.3104899999999997,103.87268999999998',
          '1.3104399999999996,103.87257999999997',
          '1.3104199999999997,103.87252999999997',
          '1.3102999999999998,103.87224999999997',
          '1.3102299999999998,103.87206999999997',
          '1.3101899999999997,103.87194999999997',
          '1.3101599999999998,103.87184999999997',
          '1.3101399999999999,103.87176999999997',
          '1.31012,103.87166999999997',
          '1.3101,103.87154999999997',
          '1.31009,103.87144999999997',
          '1.31008,103.87133999999996',
          '1.31008,103.87123999999996',
          '1.31008,103.87112999999995',
          '1.31009,103.87078999999996',
          '1.3101,103.87065999999996',
          '1.3101,103.87062999999996',
          '1.31011,103.87053999999996',
          '1.3101200000000002,103.87041999999997',
          '1.3101300000000002,103.87000999999997',
          '1.3101300000000002,103.86982999999996',
          '1.3101100000000003,103.86963999999996',
          '1.3100900000000004,103.86951999999997',
          '1.3100700000000005,103.86938999999997',
          '1.3100400000000005,103.86924999999997',
          '1.3100100000000006,103.86912999999997',
          '1.3099500000000006,103.86893999999997',
          '1.3098900000000007,103.86875999999997',
          '1.3098100000000006,103.86853999999997',
          '1.3097300000000005,103.86831999999997',
          '1.3096300000000005,103.86802999999996',
          '1.3095700000000006,103.86782999999996',
          '1.3095100000000006,103.86761999999996',
          '1.3094600000000005,103.86743999999996',
          '1.3094000000000006,103.86719999999995',
          '1.3093500000000005,103.86697999999996',
          '1.3093000000000004,103.86675999999996',
          '1.3091800000000005,103.86620999999995',
          '1.3090900000000005,103.86581999999996',
          '1.3090700000000006,103.86574999999996',
          '1.3090200000000005,103.86553999999997',
          '1.3089300000000006,103.86516999999996',
          '1.3088800000000005,103.86496999999996',
          '1.3088500000000005,103.86485999999995',
          '1.3088200000000005,103.86475999999995',
          '1.3087900000000006,103.86466999999995',
          '1.3087600000000006,103.86459999999995',
          '1.3087200000000005,103.86451999999996',
          '1.3086800000000005,103.86443999999996',
          '1.3086100000000005,103.86432999999995',
          '1.3085300000000004,103.86420999999996',
          '1.3084300000000004,103.86407999999996',
          '1.3083200000000004,103.86392999999995',
          '1.3081300000000005,103.86369999999995',
          '1.3079100000000004,103.86345999999995',
          '1.3076900000000002,103.86321999999994',
          '1.3075200000000002,103.86303999999994',
          '1.3074000000000003,103.86290999999994',
          '1.3072500000000002,103.86274999999995',
          '1.3071300000000003,103.86261999999995',
          '1.3070100000000004,103.86247999999995',
          '1.3068400000000004,103.86229999999995',
          '1.3067100000000005,103.86214999999994',
          '1.3066100000000005,103.86202999999995',
          '1.3064300000000004,103.86182999999994',
          '1.3063600000000004,103.86175999999995',
          '1.3062400000000005,103.86162999999995',
          '1.3062100000000005,103.86158999999995',
          '1.3059800000000006,103.86134999999994',
          '1.3057800000000006,103.86113999999995',
          '1.3055500000000007,103.86088999999996',
          '1.3053600000000007,103.86067999999996',
          '1.3050700000000008,103.86033999999997',
          '1.3050300000000008,103.86028999999996',
          '1.3049500000000007,103.86018999999996',
          '1.3047800000000007,103.85998999999995',
          '1.3045300000000006,103.85968999999996',
          '1.3041100000000005,103.85919999999996',
          '1.3039400000000005,103.85899999999995',
          '1.3037500000000006,103.85878999999996',
          '1.3035800000000006,103.85860999999996',
          '1.3034300000000005,103.85845999999995',
          '1.3032700000000006,103.85828999999995',
          '1.3031200000000005,103.85814999999995',
          '1.3029600000000006,103.85799999999995',
          '1.3027700000000006,103.85781999999995',
          '1.3027100000000007,103.85775999999994',
          '1.3026300000000006,103.85767999999995',
          '1.3025400000000007,103.85759999999995',
          '1.3024500000000008,103.85752999999995',
          '1.3023100000000007,103.85741999999995',
          '1.3021900000000008,103.85731999999994',
          '1.3020200000000008,103.85716999999994',
          '1.3017200000000009,103.85688999999994',
          '1.3013400000000008,103.85653999999994',
          '1.3011900000000007,103.85639999999994',
          '1.3010600000000008,103.85627999999994',
          '1.3009300000000008,103.85616999999993',
          '1.3006700000000009,103.85594999999994',
          '1.3006000000000009,103.85588999999993',
          '1.3003800000000008,103.85570999999993',
          '1.3001500000000008,103.85549999999994',
          '1.2999800000000008,103.85535999999993',
          '1.2998900000000009,103.85527999999994',
          '1.299760000000001,103.85516999999993',
          '1.2995880000000009,103.85501999999993',
          '1.299588,103.85502',
          '1.29938,103.85484',
          '1.29909,103.85459',
          '1.29891,103.85445',
          '1.29863,103.85422',
          '1.2985499999999999,103.85415',
          '1.29849,103.8541',
          '1.2984399999999998,103.85406',
          '1.2981899999999997,103.85387',
          '1.2981099999999997,103.8538',
          '1.2979699999999996,103.85369',
          '1.2977699999999996,103.85353',
          '1.2975699999999997,103.85337000000001',
          '1.2973699999999997,103.85322000000001',
          '1.2969399999999998,103.85288000000001',
          '1.2968499999999998,103.85280000000002',
          '1.2966699999999998,103.85266000000001',
          '1.2965099999999998,103.85253000000002',
          '1.2964299999999997,103.85246000000002',
          '1.2962599999999997,103.85230000000003',
          '1.2961499999999997,103.85219000000002',
          '1.2959899999999998,103.85204000000002',
          '1.2959399999999996,103.85199000000001',
          '1.2958399999999997,103.85189000000001',
          '1.2955399999999997,103.85159000000002',
          '1.2953899999999996,103.85145000000001',
          '1.2953599999999996,103.85142000000002',
          '1.2951099999999995,103.85118000000001',
          '1.2950199999999996,103.85109000000001',
          '1.2947299999999997,103.85084000000002',
          '1.2945599999999997,103.85071000000002',
          '1.2944699999999998,103.85065000000002',
          '1.2942899999999997,103.85054000000001',
          '1.2936599999999998,103.85019000000001',
          '1.29354,103.85012000000002',
          '1.2934299999999999,103.85006000000001',
          '1.2933199999999998,103.85000000000001',
          '1.2931899999999998,103.84994',
          '1.2929599999999999,103.84984',
          '1.2925799999999998,103.84968',
          '1.2924999999999998,103.84965000000001',
          '1.2921699999999998,103.84951000000001',
          '1.2920199999999997,103.84945',
          '1.2918999999999998,103.8494',
          '1.29181,103.84936',
          '1.29172,103.84932',
          '1.29146,103.84918',
          '1.29138,103.84914',
          '1.29114,103.84901',
          '1.29105,103.84896',
          '1.29101,103.84894',
          '1.29089,103.84888',
          '1.29075,103.84881',
          '1.2906300000000002,103.84874',
          '1.2905100000000003,103.84866000000001',
          '1.2903800000000003,103.84857000000001',
          '1.2902100000000003,103.84845000000001',
          '1.2900600000000002,103.84834000000001',
          '1.2899900000000002,103.84829',
          '1.2899200000000002,103.84824',
          '1.2898800000000001,103.84821000000001',
          '1.2895900000000002,103.84799000000001',
          '1.2895300000000003,103.84794000000001',
          '1.2893700000000003,103.84781000000001',
          '1.2892200000000003,103.84769000000001',
          '1.2890700000000002,103.84757000000002',
          '1.2888300000000001,103.84738000000002',
          '1.2886700000000002,103.84725000000002',
          '1.2885500000000003,103.84715000000001',
          '1.2883400000000003,103.84697000000001',
          '1.2879800000000003,103.84666000000001',
          '1.2878500000000004,103.84654000000002',
          '1.2877700000000003,103.84647000000002',
          '1.2876700000000003,103.84639000000003',
          '1.2875400000000004,103.84629000000002',
          '1.2873800000000004,103.84616000000003',
          '1.2872800000000004,103.84608000000003',
          '1.2870500000000005,103.84588000000002',
          '1.2869100000000004,103.84576000000003',
          '1.2866500000000005,103.84554000000003',
          '1.2864400000000005,103.84537000000003',
          '1.2862700000000005,103.84524000000003',
          '1.2860600000000004,103.84508000000004',
          '1.2857700000000005,103.84485000000004',
          '1.2855900000000005,103.84470000000003',
          '1.2854400000000004,103.84458000000004',
          '1.2853000000000003,103.84446000000004',
          '1.2848800000000002,103.84411000000004',
          '1.2848300000000001,103.84407000000004',
          '1.28458,103.84388000000004',
          '1.2844200000000001,103.84375000000004',
          '1.2842500000000001,103.84361000000004',
          '1.2840900000000002,103.84347000000004',
          '1.2839500000000001,103.84335000000004',
          '1.2837600000000002,103.84318000000005',
          '1.2835500000000002,103.84300000000005',
          '1.2831700000000001,103.84268000000004',
          '1.2830000000000001,103.84254000000004',
          '1.28292,103.84247000000005',
          '1.28284,103.84240000000005',
          '1.28272,103.84230000000005',
          '1.2824900000000001,103.84211000000005',
          '1.2822300000000002,103.84189000000005',
          '1.2819900000000002,103.84170000000005',
          '1.2817900000000002,103.84153000000005',
          '1.2816800000000002,103.84143000000005',
          '1.28147,103.84126000000005',
          '1.28113,103.84098000000004',
          '1.28113,103.84098',
          '1.28113,103.84098',
          '1.2810000000000001,103.84087',
          '1.2808700000000002,103.84075999999999',
          '1.2807100000000002,103.84062999999999',
          '1.2806200000000003,103.84055',
          '1.2804900000000004,103.84043999999999',
          '1.2802500000000003,103.84022999999999',
          '1.2800100000000003,103.84002',
          '1.2797700000000003,103.83981999999999',
          '1.2796500000000004,103.83971999999999',
          '1.2795500000000004,103.83963999999999',
          '1.2796600000000005,103.83959999999999',
          '1.2797600000000005,103.83957',
          '1.2800600000000004,103.83946999999999',
          '1.2802200000000004,103.83940999999999',
          '1.2804300000000004,103.83931999999999',
          '1.2805300000000004,103.83926999999998',
          '1.2806200000000003,103.83921999999998',
          '1.2807300000000004,103.83915999999998',
          '1.2808500000000003,103.83907999999998',
          '1.2810500000000002,103.83889999999998',
          '1.2811600000000003,103.83879999999998',
          '1.2813800000000004,103.83853999999998',
          '1.2814700000000003,103.83843999999998',
          '1.2815300000000003,103.83834999999998',
          '1.2816100000000004,103.83821999999998',
          '1.2817000000000003,103.83805999999998',
          '1.2818200000000002,103.83784999999999',
          '1.2820700000000003,103.83742',
          '1.2821700000000003,103.83722999999999',
          '1.2823700000000002,103.83689',
          '1.2825100000000003,103.83664999999999',
          '1.2826100000000002,103.83649',
          '1.2826900000000003,103.83636',
          '1.2827800000000003,103.83623',
          '1.2828100000000002,103.83615',
          '1.2828300000000001,103.8361',
          '1.2828400000000002,103.83607',
          '1.2828600000000001,103.83601',
          '1.2828700000000002,103.83596',
          '1.2828800000000002,103.83587',
          '1.2828800000000002,103.83584',
          '1.2828800000000002,103.83576000000001',
          '1.2828800000000002,103.83565',
          '1.2828500000000003,103.83558000000001',
          '1.2827100000000002,103.83522',
          '1.2825500000000003,103.83470000000001',
          '1.2824800000000003,103.83447000000001',
          '1.2823900000000004,103.83415000000001',
          '1.2823500000000003,103.83399000000001',
          '1.2823200000000003,103.83381000000001',
          '1.2823000000000004,103.83364000000002',
          '1.2822300000000004,103.83344000000001',
          '1.2821200000000004,103.83316',
          '1.2819800000000003,103.83281000000001',
          '1.2818400000000003,103.83250000000001',
          '1.2816900000000002,103.83219000000001',
          '1.2815400000000001,103.83191000000001',
          '1.2813800000000002,103.83165000000001',
          '1.2812800000000002,103.83149000000002',
          '1.2811000000000001,103.83122000000002',
          '1.2808700000000002,103.83090000000001',
          '1.2806600000000001,103.83063000000001',
          '1.2804700000000002,103.83039000000001',
          '1.2803300000000002,103.83024',
          '1.2799600000000002,103.82987',
          '1.2797000000000003,103.82963',
          '1.2794300000000003,103.82938999999999',
          '1.2790800000000002,103.82909999999998',
          '1.2789500000000003,103.82901999999999',
          '1.2786800000000003,103.82884999999999',
          '1.2785200000000003,103.82874999999999',
          '1.2783900000000004,103.82865999999999',
          '1.2783000000000004,103.82858999999999',
          '1.2781800000000005,103.82848999999999',
          '1.2780700000000005,103.82838999999998',
          '1.2780200000000004,103.82833999999998',
          '1.2779200000000004,103.82822999999998',
          '1.2778400000000003,103.82813999999998',
          '1.2777700000000003,103.82804999999998',
          '1.2777100000000003,103.82795999999998',
          '1.2776300000000003,103.82781999999997',
          '1.2775500000000002,103.82765999999998',
          '1.2774900000000002,103.82751999999998',
          '1.2774400000000001,103.82736999999997',
          '1.2774100000000002,103.82725999999997',
          '1.2773900000000002,103.82717999999997',
          '1.2773700000000003,103.82707999999997',
          '1.2773500000000004,103.82692999999996',
          '1.2773400000000004,103.82676999999997',
          '1.2773400000000004,103.82668999999997',
          '1.2773500000000004,103.82650999999997',
          '1.2773700000000003,103.82633999999997',
          '1.2773800000000004,103.82626999999998',
          '1.2774000000000003,103.82617999999998',
          '1.2774300000000003,103.82606999999997',
          '1.2774700000000003,103.82594999999998',
          '1.2775200000000004,103.82581999999998',
          '1.2775700000000005,103.82569999999998',
          '1.2776300000000005,103.82556999999998',
          '1.2777200000000004,103.82538999999998',
          '1.2778100000000003,103.82521999999999',
          '1.2779200000000004,103.82500999999999',
          '1.2779800000000003,103.82486999999999',
          '1.2780200000000004,103.82473999999999',
          '1.2780500000000004,103.82463999999999',
          '1.2782300000000004,103.82427999999999',
          '1.2783300000000004,103.82407999999998',
          '1.2783900000000004,103.82393999999998',
          '1.2784500000000003,103.82378999999997',
          '1.2785100000000003,103.82360999999997',
          '1.2785500000000003,103.82347999999998',
          '1.2786000000000004,103.82331999999998',
          '1.2786300000000004,103.82319999999999',
          '1.2786600000000004,103.82307999999999',
          '1.2786900000000003,103.82293999999999',
          '1.2787100000000002,103.82283999999999',
          '1.2787300000000001,103.82272999999998',
          '1.27875,103.82257999999997',
          '1.2787600000000001,103.82244999999998',
          '1.2787700000000002,103.82215999999997',
          '1.2787800000000002,103.82175999999997',
          '1.2788000000000002,103.82144999999997',
          '1.27882,103.82117999999997',
          '1.27884,103.82090999999997',
          '1.2788599999999999,103.82070999999996',
          '1.2788799999999998,103.82054999999997',
          '1.2789099999999998,103.82038999999997',
          '1.2789299999999997,103.82030999999998',
          '1.2789599999999997,103.82020999999997',
          '1.2790199999999996,103.82003999999998',
          '1.2790699999999997,103.81990999999998',
          '1.2791099999999997,103.81980999999998',
          '1.2791699999999997,103.81965999999997',
          '1.2792199999999998,103.81953999999998',
          '1.27927,103.81942999999997',
          '1.27932,103.81932999999997',
          '1.2793700000000001,103.81922999999996',
          '1.27946,103.81906999999997',
          '1.27955,103.81891999999996',
          '1.27966,103.81873999999996',
          '1.2801,103.81801999999996',
          '1.28019,103.81787999999996',
          '1.2802499999999999,103.81778999999996',
          '1.2803799999999999,103.81757999999996',
          '1.2804699999999998,103.81744999999997',
          '1.2806099999999998,103.81722999999997',
          '1.2809499999999998,103.81668999999997',
          '1.2813099999999997,103.81611999999997',
          '1.2816199999999998,103.81562999999997',
          '1.2819099999999997,103.81516999999997',
          '1.2819999999999996,103.81501999999996',
          '1.2820699999999996,103.81490999999995',
          '1.2821299999999995,103.81480999999995',
          '1.2821999999999996,103.81468999999996',
          '1.2822699999999996,103.81455999999996',
          '1.2823399999999996,103.81442999999996',
          '1.2824099999999996,103.81429999999996',
          '1.2824699999999996,103.81417999999996',
          '1.2825299999999995,103.81404999999997',
          '1.2825899999999995,103.81391999999997',
          '1.2826499999999994,103.81377999999997',
          '1.2827099999999994,103.81363999999996',
          '1.2827799999999994,103.81346999999997',
          '1.2828299999999995,103.81333999999997',
          '1.2828999999999995,103.81314999999996',
          '1.2829699999999995,103.81293999999997',
          '1.2830199999999996,103.81278999999996',
          '1.2830699999999997,103.81262999999997',
          '1.2831099999999998,103.81247999999997',
          '1.2831499999999998,103.81232999999996',
          '1.2831799999999998,103.81220999999996',
          '1.2832199999999998,103.81203999999997',
          '1.2832599999999998,103.81184999999996',
          '1.2832999999999999,103.81163999999997',
          '1.2833299999999999,103.81146999999997',
          '1.2833599999999998,103.81126999999996',
          '1.2833899999999998,103.81103999999996',
          '1.2834099999999997,103.81085999999996',
          '1.2834299999999996,103.81063999999996',
          '1.2834499999999995,103.81034999999996',
          '1.2834699999999994,103.81009999999996',
          '1.2835199999999996,103.80927999999996',
          '1.2835499999999995,103.80879999999996',
          '1.2835799999999995,103.80823999999997',
          '1.2835899999999996,103.80806999999997',
          '1.2835999999999996,103.80792999999997',
          '1.2836899999999996,103.80646999999998',
          '1.2837699999999996,103.80520999999997',
          '1.2839099999999997,103.80295999999997',
          '1.2839499999999997,103.80224999999997',
          '1.2839799999999997,103.80183999999997',
          '1.2839899999999997,103.80172999999996',
          '1.2839999999999998,103.80160999999997',
          '1.2840299999999998,103.80139999999997',
          '1.2840599999999998,103.80122999999998',
          '1.2840899999999997,103.80107999999997',
          '1.2841299999999998,103.80089999999997',
          '1.2841599999999997,103.80077999999997',
          '1.2841999999999998,103.80063999999997',
          '1.2842399999999998,103.80050999999997',
          '1.2842799999999999,103.80038999999998',
          '1.28433,103.80025999999998',
          '1.28439,103.80011999999998',
          '1.2844499999999999,103.79997999999998',
          '1.2845199999999999,103.79983999999997',
          '1.2845799999999998,103.79971999999998',
          '1.28466,103.79956999999997',
          '1.28473,103.79944999999998',
          '1.2848199999999999,103.79930999999998',
          '1.2849899999999999,103.79905999999998',
          '1.2851299999999999,103.79885999999998',
          '1.2857299999999998,103.79798999999997',
          '1.2858499999999997,103.79781999999997',
          '1.2859699999999996,103.79764999999998',
          '1.2862699999999996,103.79719999999998',
          '1.2865899999999997,103.79668999999997',
          '1.2868099999999998,103.79632999999997',
          '1.2870099999999998,103.79598999999997',
          '1.2871899999999998,103.79567999999998',
          '1.2872499999999998,103.79557999999997',
          '1.2873299999999999,103.79542999999997',
          '1.2877299999999998,103.79473999999996',
          '1.2878399999999999,103.79454999999996',
          '1.28795,103.79435999999995',
          '1.2880399999999999,103.79420999999995',
          '1.2881599999999997,103.79402999999995',
          '1.2882699999999998,103.79386999999996',
          '1.2883799999999999,103.79371999999995',
          '1.2885699999999998,103.79346999999996',
          '1.2887399999999998,103.79324999999996',
          '1.2888499999999998,103.79311999999996',
          '1.2890099999999998,103.79292999999996',
          '1.2890999999999997,103.79281999999995',
          '1.2892599999999996,103.79262999999995',
          '1.2894199999999996,103.79242999999994',
          '1.2895599999999996,103.79224999999994',
          '1.2896799999999995,103.79208999999994',
          '1.2898499999999995,103.79184999999994',
          '1.2899999999999996,103.79162999999994',
          '1.2901799999999997,103.79135999999994',
          '1.2903499999999997,103.79107999999994',
          '1.2906399999999996,103.79058999999994',
          '1.2908099999999996,103.79031999999994',
          '1.2908999999999995,103.79017999999994',
          '1.2910599999999994,103.78993999999993',
          '1.2911999999999995,103.78972999999993',
          '1.2914399999999995,103.78935999999993',
          '1.2916099999999995,103.78909999999993',
          '1.2918299999999996,103.78873999999993',
          '1.2920599999999995,103.78836999999993',
          '1.2921899999999995,103.78815999999993',
          '1.2923399999999996,103.78793999999994',
          '1.2923899999999997,103.78780999999994',
          '1.2924199999999997,103.78772999999994',
          '1.2924499999999997,103.78765999999995',
          '1.2924899999999997,103.78758999999995',
          '1.2925399999999998,103.78750999999995',
          '1.29259,103.78743999999996',
          '1.29264,103.78736999999997',
          '1.2926900000000001,103.78729999999997',
          '1.29275,103.78720999999997',
          '1.2928000000000002,103.78712999999998',
          '1.2928400000000002,103.78704999999998',
          '1.2928700000000002,103.78697999999999',
          '1.2929000000000002,103.78688999999999',
          '1.29292,103.78680999999999',
          '1.2929300000000001,103.78674999999998',
          '1.2929400000000002,103.78668999999998',
          '1.2929500000000003,103.78660999999998',
          '1.2929500000000003,103.78655999999998',
          '1.2929500000000003,103.78650999999998',
          '1.2929400000000002,103.78643999999998',
          '1.2929300000000001,103.78636999999999',
          '1.29292,103.7863',
          '1.29291,103.78622',
          '1.292905,103.786184',
          '1.292905,103.786184',
          '1.2929,103.78614',
          '1.2928899999999999,103.78604',
          '1.2928899999999999,103.78599',
          '1.2928899999999999,103.78591',
          '1.2928899999999999,103.78585',
          '1.2929,103.78578',
          '1.29291,103.78574',
          '1.29293,103.78569',
          '1.2929499999999998,103.78565',
          '1.2929899999999999,103.78559',
          '1.2930499999999998,103.78552',
          '1.2931,103.78546',
          '1.29315,103.78541',
          '1.29321,103.78535',
          '1.29331,103.78524999999999',
          '1.29338,103.78531999999998',
          '1.29344,103.78537999999999',
          '1.29349,103.78542999999999',
          '1.2935400000000001,103.78546999999999',
          '1.2935800000000002,103.78549999999998',
          '1.2936200000000002,103.78551999999999',
          '1.2937700000000003,103.78562',
          '1.2938200000000004,103.78564999999999',
          '1.2938900000000004,103.78567999999999',
          '1.2940000000000005,103.78571999999998',
          '1.2940900000000004,103.78574999999998',
          '1.2942100000000003,103.78577999999997',
          '1.2943000000000002,103.78579999999998',
          '1.2943600000000002,103.78580999999998',
          '1.29445,103.78581999999999',
          '1.2945200000000001,103.78581999999999',
          '1.29464,103.78581999999999',
          '1.29493,103.78580999999998',
          '1.29548,103.78577999999999',
          '1.29553,103.78577999999999',
          '1.2956400000000001,103.78576999999999',
          '1.2957400000000001,103.78575999999998',
          '1.2958200000000002,103.78574999999998',
          '1.2959500000000002,103.78573999999998',
          '1.2961200000000002,103.78572999999997',
          '1.2963400000000003,103.78570999999997',
          '1.2966000000000002,103.78567999999997',
          '1.2968700000000002,103.78563999999997',
          '1.2971300000000001,103.78558999999997',
          '1.29733,103.78554999999997',
          '1.29793,103.78542999999998',
          '1.29807,103.78539999999998',
          '1.29817,103.78537999999998',
          '1.29838,103.78534999999998',
          '1.29854,103.78532999999997',
          '1.29868,103.78531999999997',
          '1.29881,103.78531999999997',
          '1.29892,103.78532999999997',
          '1.29901,103.78533999999998',
          '1.29912,103.78535999999998',
          '1.29921,103.78537999999999',
          '1.29928,103.78535',
          '1.29933,103.78532999999999',
          '1.2993800000000002,103.78530999999998',
          '1.2994400000000002,103.78527999999999',
          '1.2995200000000002,103.78523999999999',
          '1.2996200000000002,103.78514999999999',
          '1.2997500000000002,103.78503999999998',
          '1.2999800000000001,103.78482999999999',
          '1.3004000000000002,103.78443999999999',
          '1.3004200000000001,103.78441999999998',
          '1.30061,103.78424999999999',
          '1.3007600000000001,103.78411999999999',
          '1.3008400000000002,103.78405',
          '1.3011000000000001,103.78381999999999',
          '1.30126,103.78368999999999',
          '1.30138,103.78358999999999',
          '1.30175,103.78329999999998',
          '1.30223,103.78293999999998',
          '1.30314,103.78227999999999',
          '1.30396,103.78170999999999',
          '1.30443,103.78138999999999',
          '1.30471,103.78118999999998',
          '1.30494,103.78102999999999',
          '1.3051,103.78091999999998',
          '1.3052599999999999,103.78080999999997',
          '1.3056299999999998,103.78053999999997',
          '1.30608,103.78021999999997',
          '1.30616,103.78015999999997',
          '1.30623,103.78009999999996',
          '1.30631,103.78002999999997',
          '1.30638,103.77995999999997',
          '1.3064500000000001,103.77987999999998',
          '1.3064900000000002,103.77982999999998',
          '1.30655,103.77973999999998',
          '1.3066000000000002,103.77965999999998',
          '1.3066400000000002,103.77957999999998',
          '1.3067100000000003,103.77942999999998',
          '1.3067900000000003,103.77924999999998',
          '1.3068600000000004,103.77906999999998',
          '1.3070400000000004,103.77861999999998',
          '1.3071200000000005,103.77841999999997',
          '1.3072000000000006,103.77822999999997',
          '1.3072800000000007,103.77803999999996',
          '1.3073600000000007,103.77786999999996',
          '1.3074700000000008,103.77763999999996',
          '1.3076700000000008,103.77718999999996',
          '1.3079700000000007,103.77652999999997',
          '1.3081400000000007,103.77614999999996',
          '1.3082700000000007,103.77583999999996',
          '1.3084700000000007,103.77535999999996',
          '1.3085600000000006,103.77512999999996',
          '1.3086600000000006,103.77487999999997',
          '1.3087200000000005,103.77472999999996',
          '1.3087800000000005,103.77454999999996',
          '1.3088200000000005,103.77439999999996',
          '1.3088400000000004,103.77427999999996',
          '1.3088500000000005,103.77417999999996',
          '1.3088700000000004,103.77395999999996',
          '1.3088900000000003,103.77356999999996',
          '1.3089000000000004,103.77343999999997',
          '1.3089200000000003,103.77320999999996',
          '1.3089200000000003,103.77316999999996',
          '1.3089300000000004,103.77302999999996',
          '1.3089400000000004,103.77294999999997',
          '1.3089600000000003,103.77284999999996',
          '1.3089900000000003,103.77273999999996',
          '1.3090300000000004,103.77263999999995',
          '1.3090700000000004,103.77256999999996',
          '1.3091200000000005,103.77248999999996',
          '1.3091600000000005,103.77243999999996',
          '1.3091900000000005,103.77239999999996',
          '1.3092600000000005,103.77231999999997',
          '1.3094200000000005,103.77214999999997',
          '1.3095200000000005,103.77201999999997',
          '1.3095800000000004,103.77194999999998',
          '1.3096000000000003,103.77191999999998',
          '1.3096900000000002,103.77178999999998',
          '1.3097300000000003,103.77179999999998',
          '1.3098100000000004,103.77181999999999',
          '1.3100300000000005,103.77186999999999',
          '1.3102300000000004,103.77190999999999',
          '1.3103800000000005,103.77193999999999',
          '1.3107500000000005,103.77199999999999',
          '1.3109100000000005,103.77202999999999',
          '1.3110800000000005,103.77205999999998',
          '1.3113200000000005,103.77205999999998',
          '1.3114900000000005,103.77205999999998',
          '1.3116300000000005,103.77204999999998',
          '1.3117200000000004,103.77203999999998',
          '1.3117700000000005,103.77202999999997',
          '1.3118100000000006,103.77201999999997',
          '1.3118500000000006,103.77199999999996',
          '1.3118800000000006,103.77197999999996',
          '1.3119000000000005,103.77195999999995',
          '1.3119200000000004,103.77192999999995',
          '1.3119500000000004,103.77186999999995',
          '1.3120000000000005,103.77174999999995',
          '1.3119900000000004,103.77161999999996',
          '1.3119800000000004,103.77147999999995',
          '1.3119600000000005,103.77120999999995',
          '1.3119400000000006,103.77097999999995',
          '1.3118600000000005,103.77017999999995',
          '1.3118300000000005,103.76991999999996',
          '1.3118100000000006,103.76973999999996',
          '1.3117900000000007,103.76953999999995',
          '1.3117700000000008,103.76933999999994',
          '1.3117600000000007,103.76919999999994',
          '1.3117600000000007,103.76907999999995',
          '1.3117600000000007,103.76890999999995',
          '1.3117600000000007,103.76882999999995',
          '1.3117700000000008,103.76869999999995',
          '1.3117800000000008,103.76859999999995',
          '1.3118000000000007,103.76846999999995',
          '1.3118200000000007,103.76835999999994',
          '1.3118400000000006,103.76825999999994',
          '1.3118600000000005,103.76817999999994',
          '1.3118900000000004,103.76806999999994',
          '1.3119200000000004,103.76797999999994',
          '1.3119600000000005,103.76786999999993',
          '1.3119900000000004,103.76777999999993',
          '1.3120300000000005,103.76767999999993',
          '1.3120800000000006,103.76756999999992',
          '1.3122400000000005,103.76729999999992',
          '1.3123400000000005,103.76712999999992',
          '1.3124300000000004,103.76698999999992',
          '1.3125500000000003,103.76681999999992',
          '1.3126500000000003,103.76668999999993',
          '1.3128100000000003,103.76649999999992',
          '1.3128800000000003,103.76641999999993',
          '1.3131000000000004,103.76619999999993',
          '1.3132200000000003,103.76608999999992',
          '1.3133500000000002,103.76597999999991',
          '1.3134900000000003,103.76586999999991',
          '1.3136200000000002,103.7657799999999',
          '1.3138100000000001,103.76565999999991',
          '1.31393,103.76558999999992',
          '1.31405,103.76552999999991',
          '1.3142399999999999,103.76543999999991',
          '1.3145699999999998,103.76528999999991',
          '1.3150749999999998,103.7650719999999',
          '1.315075,103.765072',
          '1.31515,103.76504',
          '1.31534,103.76496',
          '1.31539,103.76494',
          '1.31556,103.76487',
          '1.3157800000000002,103.76478',
          '1.3159300000000003,103.76472',
          '1.3160400000000003,103.76468',
          '1.3161300000000002,103.76465',
          '1.3163100000000003,103.76458000000001',
          '1.3164400000000003,103.76453000000001',
          '1.3165500000000003,103.76448',
          '1.3166900000000004,103.76441000000001',
          '1.3167600000000004,103.76437000000001',
          '1.3168300000000004,103.76433000000002',
          '1.3169200000000003,103.76428000000001',
          '1.3170300000000004,103.76422000000001',
          '1.3171000000000004,103.76418000000001',
          '1.3172000000000004,103.76412',
          '1.3172700000000004,103.76408',
          '1.3174000000000003,103.76399',
          '1.3175800000000004,103.76387000000001',
          '1.3177000000000003,103.76379000000001',
          '1.3178200000000002,103.76372000000002',
          '1.3179500000000002,103.76364000000002',
          '1.3181100000000001,103.76353000000002',
          '1.3182800000000001,103.76341000000002',
          '1.31851,103.76325000000003',
          '1.3187200000000001,103.76310000000002',
          '1.3188000000000002,103.76304000000002',
          '1.3188800000000003,103.76298000000001',
          '1.3189800000000003,103.76291000000002',
          '1.3194000000000004,103.76262000000001',
          '1.3199000000000003,103.76229000000001',
          '1.3203000000000003,103.76203000000001',
          '1.3206300000000002,103.76182000000001',
          '1.3210700000000002,103.76156000000002',
          '1.32136,103.76138000000002',
          '1.3216100000000002,103.76123000000001',
          '1.3217800000000002,103.76113000000001',
          '1.3219900000000002,103.76099',
          '1.3222800000000001,103.76079',
          '1.32254,103.76061',
          '1.32312,103.76022999999999',
          '1.32331,103.7601',
          '1.32353,103.75993',
          '1.3237500000000002,103.75976',
          '1.3239000000000003,103.75963',
          '1.3240600000000002,103.75949',
          '1.3241900000000002,103.75936',
          '1.3243000000000003,103.75925',
          '1.3244000000000002,103.75914999999999',
          '1.3244700000000003,103.75907',
          '1.3245700000000002,103.75895999999999',
          '1.3246600000000002,103.75884999999998',
          '1.3247300000000002,103.75875999999998',
          '1.3248100000000003,103.75865999999998',
          '1.3249000000000002,103.75853999999998',
          '1.3249800000000003,103.75842999999998',
          '1.3250300000000004,103.75835999999998',
          '1.3251000000000004,103.75824999999998',
          '1.3251600000000003,103.75814999999997',
          '1.3252200000000003,103.75804999999997',
          '1.3253900000000003,103.75771999999996',
          '1.3254900000000003,103.75751999999996',
          '1.3255600000000003,103.75737999999996',
          '1.3256500000000002,103.75718999999995',
          '1.3256900000000003,103.75709999999995',
          '1.3257600000000003,103.75692999999995',
          '1.3258400000000004,103.75672999999995',
          '1.3259000000000003,103.75656999999995',
          '1.3259500000000004,103.75642999999995',
          '1.3260100000000004,103.75625999999995',
          '1.3261600000000004,103.75582999999996',
          '1.3263700000000005,103.75520999999996',
          '1.3264900000000004,103.75484999999996',
          '1.3265400000000005,103.75469999999996',
          '1.3265800000000005,103.75456999999996',
          '1.3266500000000006,103.75433999999996',
          '1.3267400000000005,103.75404999999995',
          '1.3268400000000005,103.75372999999995',
          '1.3269400000000005,103.75342999999995',
          '1.3270400000000004,103.75313999999995',
          '1.3271200000000005,103.75291999999995',
          '1.3272100000000004,103.75267999999994',
          '1.3272800000000005,103.75249999999994',
          '1.3273500000000005,103.75232999999994',
          '1.3274100000000004,103.75218999999994',
          '1.3274600000000005,103.75207999999994',
          '1.3275100000000006,103.75196999999993',
          '1.3276600000000007,103.75166999999993',
          '1.3277700000000008,103.75145999999994',
          '1.3278500000000009,103.75130999999993',
          '1.3279400000000008,103.75114999999994',
          '1.3280200000000009,103.75100999999994',
          '1.3281100000000008,103.75085999999993',
          '1.3282100000000008,103.75069999999994',
          '1.3282600000000009,103.75061999999994',
          '1.3283200000000008,103.75052999999994',
          '1.3283600000000009,103.75046999999994',
          '1.328410000000001,103.75039999999994',
          '1.328490000000001,103.75028999999994',
          '1.328560000000001,103.75019999999994',
          '1.3286400000000012,103.75008999999993',
          '1.328700000000001,103.75000999999993',
          '1.3287800000000012,103.74990999999993',
          '1.3288800000000012,103.74978999999993',
          '1.328970000000001,103.74968999999993',
          '1.329040000000001,103.74959999999993',
          '1.3291500000000012,103.74946999999993',
          '1.3293200000000012,103.74927999999993',
          '1.3294700000000013,103.74910999999993',
          '1.3296200000000014,103.74894999999994',
          '1.3297200000000013,103.74883999999993',
          '1.3301600000000013,103.74836999999992',
          '1.3304500000000012,103.74803999999992',
          '1.3305300000000013,103.74794999999992',
          '1.3308000000000013,103.74763999999992',
          '1.3309500000000014,103.74745999999992',
          '1.3310500000000014,103.74733999999992',
          '1.3314300000000014,103.74686999999992',
          '1.3314700000000015,103.74681999999991',
          '1.3315800000000015,103.74668999999992',
          '1.3318500000000015,103.74637999999992',
          '1.3319600000000016,103.74625999999992',
          '1.3322400000000016,103.74592999999992',
          '1.3325000000000016,103.74561999999992',
          '1.3326700000000016,103.74541999999991',
          '1.3328400000000016,103.74520999999991',
          '1.3330000000000015,103.74500999999991',
          '1.3330800000000016,103.7449099999999',
          '1.3331400000000015,103.74483999999991',
          '1.3333000000000015,103.74464999999991',
          '1.3334000000000015,103.7445399999999',
          '1.3335100000000015,103.7444199999999',
          '1.3336200000000016,103.7443099999999',
          '1.3337300000000016,103.74419999999989',
          '1.3338300000000016,103.74410999999989',
          '1.3339100000000017,103.7440399999999',
          '1.3340300000000016,103.7439399999999',
          '1.3341300000000016,103.7438599999999',
          '1.3342300000000016,103.7437799999999',
          '1.3343700000000016,103.7436799999999',
          '1.3345200000000017,103.7435799999999',
          '1.3350100000000018,103.74325999999989',
          '1.335430000000002,103.74298999999989',
          '1.335600000000002,103.74287999999989',
          '1.335750000000002,103.74277999999988',
          '1.336040000000002,103.74258999999988',
          '1.3361400000000019,103.74251999999989',
          '1.336220000000002,103.74244999999989',
          '1.336300000000002,103.7423799999999',
          '1.336390000000002,103.7422899999999',
          '1.336460000000002,103.7421999999999',
          '1.336520000000002,103.7421099999999',
          '1.336560000000002,103.74204999999989',
          '1.336590000000002,103.74198999999989',
          '1.336640000000002,103.74188999999988',
          '1.336680000000002,103.74179999999988',
          '1.3367300000000022,103.74166999999989',
          '1.3368300000000022,103.74139999999989',
          '1.3369700000000022,103.74099999999989',
          '1.3370700000000022,103.74069999999989',
          '1.3371400000000022,103.7404899999999',
          '1.3371700000000022,103.7403999999999',
          '1.337190000000002,103.74033999999989',
          '1.3372300000000021,103.74020999999989',
          '1.337290000000002,103.7400499999999',
          '1.337360000000002,103.73984999999989',
          '1.3374400000000022,103.7396399999999',
          '1.3375100000000022,103.7394799999999',
          '1.3376000000000021,103.7392999999999',
          '1.3376800000000022,103.7391599999999',
          '1.3377500000000022,103.73904999999989',
          '1.3378200000000022,103.73894999999989',
          '1.3378900000000022,103.73885999999989',
          '1.3381100000000024,103.73857999999989',
          '1.3392300000000024,103.73718999999988',
          '1.3397400000000024,103.73653999999989',
          '1.3399700000000023,103.7362399999999',
          '1.3402800000000024,103.73581999999989',
          '1.3405200000000024,103.73548999999988',
          '1.3406800000000023,103.73526999999989',
          '1.3409300000000024,103.73493999999988',
          '1.3410400000000025,103.73479999999988',
          '1.3412000000000024,103.73458999999988',
          '1.3415000000000024,103.73417999999988',
          '1.3419000000000023,103.73365999999989',
          '1.3421400000000023,103.73333999999988',
          '1.3424400000000023,103.73292999999988',
          '1.3432600000000023,103.73182999999989',
          '1.3433500000000023,103.73169999999989',
          '1.3434100000000022,103.73160999999989',
          '1.3434600000000023,103.73152999999989',
          '1.3435400000000024,103.73138999999989',
          '1.3436200000000025,103.73124999999989',
          '1.3436700000000026,103.73115999999989',
          '1.3437300000000025,103.7310399999999',
          '1.3437900000000025,103.7309199999999',
          '1.3438600000000025,103.7307599999999',
          '1.3439100000000026,103.73063999999991',
          '1.3439800000000026,103.7304499999999',
          '1.3440700000000025,103.7301699999999',
          '1.3441000000000025,103.7300699999999',
          '1.3441300000000025,103.7299499999999',
          '1.3441700000000025,103.7297799999999',
          '1.3441900000000024,103.7296899999999',
          '1.3442200000000024,103.7295399999999',
          '1.3442400000000023,103.7294199999999',
          '1.3442600000000022,103.7292799999999',
          '1.3442700000000023,103.7291699999999',
          '1.3442800000000024,103.72901999999989',
          '1.3442900000000024,103.72882999999989',
          '1.3442900000000024,103.72837999999989',
          '1.3442900000000024,103.72833999999989',
          '1.3442900000000024,103.72796999999989',
          '1.3442900000000024,103.72792999999989',
          '1.3442900000000024,103.72772999999988',
          '1.3442900000000024,103.72760999999988',
          '1.3442900000000024,103.72728999999988',
          '1.3443000000000025,103.72712999999989',
          '1.3443100000000026,103.72698999999989',
          '1.3443300000000025,103.7268299999999',
          '1.3443500000000024,103.72668999999989',
          '1.3443700000000023,103.72657999999988',
          '1.3444000000000023,103.72645999999989',
          '1.3444600000000022,103.72622999999989',
          '1.3447000000000022,103.72531999999988',
          '1.3447500000000023,103.72512999999988',
          '1.3447800000000023,103.72498999999988',
          '1.3448100000000023,103.72483999999987',
          '1.3448300000000022,103.72473999999987',
          '1.344850000000002,103.72462999999986',
          '1.344880000000002,103.72446999999987',
          '1.344900000000002,103.72434999999987',
          '1.344920000000002,103.72421999999987',
          '1.3449400000000018,103.72407999999987',
          '1.3449500000000019,103.72397999999987',
          '1.344960000000002,103.72382999999986',
          '1.344960000000002,103.72367999999986',
          '1.344960000000002,103.72346999999986',
          '1.3449500000000019,103.72326999999986',
          '1.3449500000000019,103.72312999999986',
          '1.3449400000000018,103.72287999999986',
          '1.344920000000002,103.72256999999986',
          '1.344900000000002,103.72234999999986',
          '1.344870000000002,103.72210999999986',
          '1.344840000000002,103.72190999999985',
          '1.3448200000000021,103.72180999999985',
          '1.3447900000000022,103.72164999999985',
          '1.3447700000000022,103.72153999999985',
          '1.3447500000000023,103.72141999999985',
          '1.3447400000000023,103.72135999999985',
          '1.3447200000000024,103.72125999999984',
          '1.3446900000000024,103.72113999999985',
          '1.3446300000000024,103.72091999999985',
          '1.3445600000000024,103.72067999999985',
          '1.3444900000000024,103.72045999999985',
          '1.3444000000000025,103.72018999999985',
          '1.3443000000000025,103.71989999999984',
          '1.3441600000000025,103.71951999999983',
          '1.3438600000000025,103.71872999999984',
          '1.3432400000000024,103.71709999999983',
          '1.3431200000000025,103.71677999999983',
          '1.3430100000000025,103.71648999999982',
          '1.3428300000000024,103.71602999999982',
          '1.3427900000000024,103.71592999999982',
          '1.3427300000000024,103.71574999999982',
          '1.3426200000000024,103.71543999999982',
          '1.3425600000000024,103.71526999999982',
          '1.3425100000000023,103.71512999999982',
          '1.3422700000000023,103.71446999999982',
          '1.3417000000000023,103.71293999999982',
          '1.3412200000000023,103.71165999999982',
          '1.3412100000000022,103.71162999999983',
          '1.3411700000000022,103.71151999999982',
          '1.3410700000000022,103.71124999999982',
          '1.3409500000000023,103.71092999999982',
          '1.3408100000000023,103.71056999999982',
          '1.3407200000000024,103.71032999999981',
          '1.3405900000000024,103.70998999999982',
          '1.3404800000000023,103.70967999999982',
          '1.3404200000000024,103.70951999999983',
          '1.3403700000000023,103.70937999999983',
          '1.3401700000000023,103.70887999999982',
          '1.3400800000000024,103.70861999999983',
          '1.3400000000000023,103.70841999999982',
          '1.3399200000000022,103.70823999999982',
          '1.3398300000000023,103.70805999999982',
          '1.3397700000000023,103.70795999999982',
          '1.3396900000000023,103.70783999999982',
          '1.3396200000000023,103.70774999999982',
          '1.3395200000000023,103.70762999999982',
          '1.3394200000000023,103.70752999999982',
          '1.3393300000000024,103.70744999999982',
          '1.3392500000000023,103.70737999999983',
          '1.3391500000000023,103.70730999999984',
          '1.3390500000000023,103.70724999999983',
          '1.3388900000000024,103.70716999999983',
          '1.3387800000000023,103.70712999999984',
          '1.3386500000000023,103.70706999999983',
          '1.3385100000000023,103.70701999999983',
          '1.3381700000000023,103.70691999999983',
          '1.3380600000000022,103.70687999999983',
          '1.3375100000000022,103.70669999999983',
          '1.3369100000000023,103.70647999999983',
          '1.3365400000000023,103.70632999999982',
          '1.3364900000000022,103.70630999999982',
          '1.3363500000000021,103.70625999999982',
          '1.3364000000000023,103.70613999999982',
          '1.3365300000000022,103.70585999999982',
          '1.3367700000000022,103.70527999999982',
          '1.3368400000000022,103.70518399999982',
          '1.33684,103.705184',
          '1.33702,103.70494000000001',
          '1.3372000000000002,103.70483',
          '1.3375500000000002,103.7047',
          '1.3376100000000002,103.70463000000001',
          '1.3378700000000001,103.70450000000001',
          '1.33803,103.70438000000001',
          '1.33806,103.70434000000002',
          '1.33809,103.70422000000002',
          '1.33778,103.70318000000002',
          '1.33768,103.70284000000002',
          '1.33761,103.70260000000002',
          '1.33758,103.70249000000001',
          '1.33754,103.70232000000001',
          '1.33752,103.70208000000001',
          '1.33752,103.70175',
          '1.33751,103.70138',
          '1.33747,103.69924',
          '1.3374599999999999,103.69888',
          '1.3374599999999999,103.69879',
          '1.3374599999999999,103.69845000000001',
          '1.3374599999999999,103.69817',
          '1.3374599999999999,103.69751000000001',
          '1.3374499999999998,103.697',
          '1.3374499999999998,103.69698',
          '1.3374499999999998,103.6969',
          '1.3374499999999998,103.69685',
          '1.3374499999999998,103.69681',
          '1.3374499999999998,103.69678',
          '1.3374499999999998,103.6964',
          '1.3374099999999998,103.69633999999999',
          '1.3372999999999997,103.69618',
          '1.3372199999999996,103.69613',
          '1.3369299999999997,103.69619999999999',
          '1.3365099999999996,103.69627999999999',
          '1.3360899999999996,103.69635999999998',
          '1.3358399999999995,103.69640999999999',
          '1.3356599999999994,103.69643999999998',
          '1.3353999999999995,103.69647999999998',
          '1.3351699999999995,103.69650999999998',
          '1.3348799999999996,103.69653999999997',
          '1.3347299999999995,103.69654999999997',
          '1.3343599999999995,103.69656999999998',
          '1.3327299999999995,103.69658999999999',
          '1.3321499999999995,103.69658999999999',
          '1.3314699999999995,103.69658999999999',
          '1.3313399999999995,103.69658999999999',
          '1.3308099999999996,103.69658999999999',
          '1.3305399999999996,103.69658999999999',
          '1.3298299999999996,103.69658999999999',
          '1.3290399999999996,103.69658999999999',
          '1.3286199999999995,103.69657999999998',
          '1.3282599999999996,103.69657999999998',
          '1.3278299999999996,103.69658999999999',
          '1.3276999999999997,103.69658999999999',
          '1.3271499999999996,103.69659999999999',
          '1.3264899999999995,103.69659999999999',
          '1.3257899999999996,103.69658999999999',
          '1.3253099999999995,103.69658999999999',
          '1.3252299999999995,103.69658999999999',
          '1.3245899999999995,103.69658999999999',
          '1.3238799999999995,103.69658999999999',
          '1.3235599999999994,103.69660999999999',
          '1.3234199999999994,103.69663',
          '1.3233299999999995,103.69665',
          '1.3232199999999994,103.69669',
          '1.3230999999999995,103.69675000000001',
          '1.3230799999999996,103.69678',
          '1.3230499999999996,103.69682',
          '1.3230299999999997,103.69684000000001',
          '1.3229999999999997,103.69687',
          '1.3229599999999997,103.6969',
          '1.3228999999999997,103.69693',
          '1.3228599999999997,103.69694',
          '1.3227899999999997,103.69695',
          '1.3227399999999996,103.69695',
          '1.3226799999999996,103.69695',
          '1.3226199999999997,103.69694',
          '1.3225799999999996,103.69693',
          '1.3225499999999997,103.69691999999999',
          '1.3225199999999997,103.69690999999999',
          '1.3224999999999998,103.69689999999999',
          '1.3224699999999998,103.69687999999998',
          '1.3224299999999998,103.69684999999998',
          '1.3223899999999997,103.69680999999999',
          '1.3223599999999998,103.69676999999999',
          '1.3223399999999998,103.69673999999999',
          '1.3223299999999998,103.69671999999998',
          '1.3223099999999999,103.69666999999998',
          '1.3222999999999998,103.69662999999998',
          '1.3222899999999997,103.69658999999999',
          '1.3222899999999997,103.69655999999999',
          '1.3222899999999997,103.69653',
          '1.3222899999999997,103.6965',
          '1.3222999999999998,103.69644',
          '1.3223099999999999,103.6964',
          '1.32232,103.69637',
          '1.3223399999999998,103.69633',
          '1.3223699999999998,103.69628',
          '1.3223899999999997,103.69625',
          '1.3224099999999996,103.69623',
          '1.3224399999999996,103.6962',
          '1.3224699999999996,103.69608000000001',
          '1.3224899999999995,103.69602',
          '1.3225099999999994,103.69595000000001',
          '1.3225199999999995,103.69589',
          '1.3225299999999995,103.6958',
          '1.3225299999999995,103.69567',
          '1.3225299999999995,103.69499',
          '1.3225299999999995,103.69473',
          '1.3225299999999995,103.69458',
          '1.3225299999999995,103.69449',
          '1.3225299999999995,103.69375000000001',
          '1.3225399999999996,103.69340000000001',
          '1.3225499999999997,103.69316',
          '1.3225599999999997,103.6928',
          '1.3225699999999998,103.69244',
          '1.3225799999999999,103.69221',
          '1.32259,103.69211',
          '1.3226,103.69205',
          '1.32261,103.692',
          '1.3226200000000001,103.69194999999999',
          '1.32265,103.69181999999999',
          '1.32265,103.69032999999999',
          '1.32265,103.68988999999999',
          '1.32265,103.68813999999999',
          '1.32265,103.68616999999999',
          '1.32265,103.68580999999999',
          '1.32265,103.68565',
          '1.32265,103.68511',
          '1.32265,103.68469999999999',
          '1.32265,103.68395',
          '1.32265,103.68216',
          '1.32265,103.68106999999999',
          '1.32265,103.68027',
          '1.32265,103.67975',
          '1.32264,103.67933',
          '1.32264,103.67921999999999',
          '1.32264,103.67913999999999',
          '1.32264,103.67905999999999',
          '1.32264,103.67896999999999',
          '1.3226200000000001,103.67866',
          '1.3226000000000002,103.67837999999999',
          '1.3225800000000003,103.67809999999999',
          '1.3225500000000003,103.67768999999998',
          '1.3224600000000004,103.67644999999999',
          '1.3223500000000004,103.67491999999999',
          '1.3223100000000003,103.67437999999999',
          '1.3222300000000002,103.67329999999998',
          '1.3222100000000003,103.67295999999999',
          '1.3221200000000004,103.67173999999999',
          '1.3220900000000004,103.67129999999999',
          '1.3220500000000004,103.67072999999999',
          '1.3220300000000005,103.67043999999999',
          '1.3220100000000006,103.67011999999998',
          '1.3219800000000006,103.66973999999998',
          '1.3219200000000007,103.66904999999997',
          '1.3217900000000007,103.66762999999997',
          '1.3217600000000007,103.66670999999998',
          '1.3217500000000006,103.66634999999998',
          '1.3217400000000006,103.66600999999999',
          '1.3217500000000006,103.66571999999998',
          '1.3217600000000007,103.66550999999998',
          '1.3217800000000006,103.66533999999999',
          '1.3218100000000006,103.66517999999999',
          '1.3218300000000005,103.66506999999999',
          '1.3218700000000005,103.66490999999999',
          '1.3219100000000006,103.66478',
          '1.3219600000000007,103.66465',
          '1.3220200000000006,103.66449999999999',
          '1.3221100000000006,103.66431999999999',
          '1.3221900000000006,103.66416999999998',
          '1.3223000000000007,103.66397999999998',
          '1.3224000000000007,103.66383999999998',
          '1.3225100000000007,103.66370999999998',
          '1.3226100000000007,103.66357999999998',
          '1.3227100000000007,103.66345999999999',
          '1.3227700000000007,103.66338999999999',
          '1.3228400000000007,103.66332',
          '1.3229000000000006,103.66327',
          '1.3230600000000006,103.66314',
          '1.3234800000000007,103.66275',
          '1.3240100000000006,103.66229',
          '1.3241900000000006,103.66211',
          '1.3247000000000007,103.66162',
          '1.3250000000000006,103.6613',
          '1.3254200000000007,103.66084',
          '1.3256200000000007,103.66062',
          '1.3258300000000007,103.66038999999999',
          '1.3262200000000006,103.65997999999999',
          '1.3266800000000005,103.6595',
          '1.3269900000000006,103.65917999999999',
          '1.3272600000000006,103.65889999999999',
          '1.3277600000000005,103.65834999999998',
          '1.3284300000000004,103.65760999999999',
          '1.3288000000000004,103.65720999999999',
          '1.3290200000000005,103.65696999999999',
          '1.3296900000000005,103.65625999999999',
          '1.3299000000000005,103.65601999999998',
          '1.3301200000000006,103.65578999999998',
          '1.3303400000000007,103.65555999999998',
          '1.3305400000000007,103.65535999999997',
          '1.3307900000000008,103.65510999999998',
          '1.3310200000000008,103.65487999999998',
          '1.3313400000000009,103.65453999999998',
          '1.3324100000000008,103.65341999999998',
          '1.3335900000000007,103.65217999999999',
          '1.3355300000000008,103.65018999999998',
          '1.3366200000000008,103.64905999999998',
          '1.337110000000001,103.64852999999998',
          '1.338300000000001,103.64728999999998',
          '1.338780000000001,103.64679999999998',
          '1.338870000000001,103.64670999999998',
          '1.3389000000000009,103.64667999999999',
          '1.3389900000000008,103.64656999999998',
          '1.3391400000000009,103.64641999999998',
          '1.339560000000001,103.64595999999997',
          '1.339840000000001,103.64563999999997',
          '1.340480000000001,103.64494999999997',
          '1.340850000000001,103.64456999999996',
          '1.341070000000001,103.64432999999995',
          '1.341170000000001,103.64421999999995',
          '1.3412800000000011,103.64409999999995',
          '1.3416500000000011,103.64366999999996',
          '1.3419700000000012,103.64330999999996',
          '1.3421900000000013,103.64306999999995',
          '1.3425600000000013,103.64269999999995',
          '1.3427000000000013,103.64255999999995',
          '1.3428300000000013,103.64242999999995',
          '1.3429200000000012,103.64230999999995',
          '1.3429900000000012,103.64221999999995',
          '1.3430400000000013,103.64215999999995',
          '1.3430800000000014,103.64210999999995',
          '1.3431300000000015,103.64204999999994',
          '1.3432000000000015,103.64195999999994',
          '1.3432600000000015,103.64188999999995',
          '1.3433400000000015,103.64179999999995',
          '1.3436500000000016,103.64145999999995',
          '1.3440300000000016,103.64103999999995',
          '1.3448400000000016,103.64008999999994',
          '1.3452900000000017,103.63958999999994',
          '1.3453000000000017,103.63946999999995',
          '1.3452900000000017,103.63940999999994',
          '1.3452700000000017,103.63934999999994',
          '1.3452400000000018,103.63927999999994',
          '1.3451300000000017,103.63910999999995',
          '1.3450200000000017,103.63900999999994',
          '1.3448700000000016,103.63887999999994',
          '1.3446800000000017,103.63871999999995',
          '1.3445200000000017,103.63858999999995',
          '1.3440300000000016,103.63827999999995',
          '1.3430600000000017,103.63789999999995',
          '1.3425500000000017,103.63770999999994',
          '1.3420800000000017,103.63753999999994',
          '1.3420500000000017,103.63752999999994',
          '1.3420200000000018,103.63751999999994',
          '1.3413200000000018,103.63726999999994',
          '1.3410000000000017,103.63716999999994',
          '1.3408800000000018,103.63712999999994',
          '1.340650000000002,103.63705999999995',
          '1.340507000000002,103.63701399999995',
        ],
      },
    ],
  },
  stops: [
    {
      direction: 1,
      stop: [
        {
          stopSequence: '1',
          stopCode: 'GS1',
          index: 0,
        },
        {
          stopSequence: '2',
          stopCode: 'GS2',
          index: 131,
        },
        {
          stopSequence: '3',
          stopCode: 'GS3',
          index: 360,
        },
        {
          stopSequence: '4',
          stopCode: 'GS4',
          index: 509,
        },
        {
          stopSequence: '5',
          stopCode: 'GS5',
          index: 881,
        },
        {
          stopSequence: '6',
          stopCode: 'GS6',
          index: 995,
        },
        {
          stopSequence: '7',
          stopCode: 'GS7',
          index: 1238,
        },
        {
          stopSequence: '8',
          stopCode: 'GS8',
          index: 1430,
        },
        {
          stopSequence: '9',
          stopCode: 'GS9',
          index: 1794,
        },
        {
          stopSequence: '10',
          stopCode: 'GS10',
          index: 2187,
        },
      ],
    },
    {
      direction: 2,
      stop: [
        {
          stopSequence: '1',
          stopCode: 'GS10',
          index: 0,
        },
        {
          stopSequence: '2',
          stopCode: 'GS9',
          index: 392,
        },
        {
          stopSequence: '3',
          stopCode: 'GS8',
          index: 757,
        },
        {
          stopSequence: '4',
          stopCode: 'GS7',
          index: 948,
        },
        {
          stopSequence: '5',
          stopCode: 'GS6',
          index: 1192,
        },
        {
          stopSequence: '6',
          stopCode: 'GS5',
          index: 1306,
        },
        {
          stopSequence: '7',
          stopCode: 'GS4',
          index: 1677,
        },
        {
          stopSequence: '8',
          stopCode: 'GS3',
          index: 1826,
        },
        {
          stopSequence: '9',
          stopCode: 'GS2',
          index: 2054,
        },
        {
          stopSequence: '10',
          stopCode: 'GS1',
          index: 2187,
        },
      ],
    },
  ],
  color: '#038803',
  colorAlt: '#086608',
  colorAlt2: '#064A06',
  busCode: 'G',
  busCount: 6,
  busId: 7002,
  timeStop: 5,
  busIdStr: '7002',
  busArr: ['SG6042C', 'SG1713H', 'SG5031R', 'SG1894T', 'SG5115H', 'SG6184B'],
};

const redRoute = {
  ladder: {
    isVisible: 'true',
    routeId: ['7001'],
    routeName: ['7001'],
    direction: [
      {
        value: '2',
        stops: [
          {
            streetName: 'Red Route',
            stopCode: 'RS7',
            stopName: 'Lancaster Loop',
            latitude: 1.425144,
            longitude: 103.76106,
            stopSequence: '1',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS6',
            stopName: 'Victoria Vault',
            latitude: 1.363013,
            longitude: 103.767414,
            stopSequence: '2',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS5',
            stopName: 'Preston Plaza',
            latitude: 1.3409,
            longitude: 103.775838,
            stopSequence: '3',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS4',
            stopName: 'Hastings Harbor',
            latitude: 1.3225964,
            longitude: 103.8150076,
            stopSequence: '4',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS3',
            stopName: 'Greenwich Grove',
            latitude: 1.3029758,
            longitude: 103.8319635,
            stopSequence: '5',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS2',
            stopName: 'Chester Chase',
            latitude: 1.293306,
            longitude: 103.852408,
            stopSequence: '6',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS1',
            stopName: 'Kingston Quay',
            latitude: 1.2774322,
            longitude: 103.8554115,
            stopSequence: '7',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
        ],
      },
      {
        value: '1',
        stops: [
          {
            streetName: 'Red Route',
            stopCode: 'RS1',
            stopName: 'Kingston Quay',
            latitude: 1.2774322,
            longitude: 103.8554115,
            stopSequence: '1',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS2',
            stopName: 'Chester Chase',
            latitude: 1.293306,
            longitude: 103.852408,
            stopSequence: '2',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS3',
            stopName: 'Greenwich Grove',
            latitude: 1.3029758,
            longitude: 103.8319635,
            stopSequence: '3',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS4',
            stopName: 'Hastings Harbor',
            latitude: 1.3225964,
            longitude: 103.8150076,
            stopSequence: '4',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS5',
            stopName: 'Preston Plaza',
            latitude: 1.3409,
            longitude: 103.775838,
            stopSequence: '5',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS6',
            stopName: 'Victoria Vault',
            latitude: 1.363013,
            longitude: 103.767414,
            stopSequence: '6',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Red Route',
            stopCode: 'RS7',
            stopName: 'Lancaster Loop',
            latitude: 1.425144,
            longitude: 103.76106,
            stopSequence: '7',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
        ],
      },
    ],
  },
  route: {
    route: ['7001'],
    direction: [
      {
        value: 2,
        path: [
          '1.424975,103.761164',
          '1.4249100000000001,103.76106999999999',
          '1.42483,103.76095',
          '1.4247400000000001,103.76080999999999',
          '1.4246400000000001,103.76065',
          '1.4245800000000002,103.76055',
          '1.4244900000000003,103.76039999999999',
          '1.4244300000000003,103.76028999999998',
          '1.4243600000000003,103.76015999999998',
          '1.4243200000000003,103.76007999999999',
          '1.4242600000000003,103.75994999999999',
          '1.4242300000000003,103.75988',
          '1.4242000000000004,103.7598',
          '1.4241500000000002,103.75965',
          '1.4240900000000003,103.75944',
          '1.4240400000000002,103.75923999999999',
          '1.4239500000000003,103.75885999999998',
          '1.4239000000000002,103.75865999999998',
          '1.4238600000000001,103.75851999999998',
          '1.4238300000000002,103.75841999999997',
          '1.4237700000000002,103.75823999999997',
          '1.42372,103.75809999999997',
          '1.42368,103.75797999999998',
          '1.42357,103.75766999999998',
          '1.42349,103.75745999999998',
          '1.4234399999999998,103.75734999999997',
          '1.4233799999999999,103.75722999999998',
          '1.4233099999999999,103.75710999999998',
          '1.4232599999999997,103.75703999999999',
          '1.4232099999999996,103.75697999999998',
          '1.4231399999999996,103.75689999999999',
          '1.4230499999999997,103.75680999999999',
          '1.4229599999999998,103.75671999999999',
          '1.4228499999999997,103.75661999999998',
          '1.4227099999999997,103.75649999999999',
          '1.4226299999999996,103.75643999999998',
          '1.4225499999999995,103.75637999999998',
          '1.4223699999999995,103.75625999999998',
          '1.4221799999999996,103.75613999999999',
          '1.4213599999999995,103.75564999999999',
          '1.4211799999999994,103.75554999999999',
          '1.4208899999999995,103.75538999999999',
          '1.4205999999999996,103.75523999999999',
          '1.4203999999999997,103.75513999999998',
          '1.4201999999999997,103.75504999999998',
          '1.4200799999999998,103.75499999999998',
          '1.4199199999999998,103.75494999999998',
          '1.41976,103.75490999999998',
          '1.41963,103.75488999999997',
          '1.41944,103.75486999999997',
          '1.41903,103.75483999999997',
          '1.41886,103.75481999999997',
          '1.41865,103.75478999999997',
          '1.41841,103.75475999999998',
          '1.4183,103.75474999999997',
          '1.41791,103.75472999999997',
          '1.41758,103.75471999999996',
          '1.41723,103.75471999999996',
          '1.41681,103.75472999999997',
          '1.41645,103.75473999999997',
          '1.41625,103.75474999999997',
          '1.41611,103.75475999999998',
          '1.41593,103.75477999999998',
          '1.41576,103.75480999999998',
          '1.4156,103.75483999999997',
          '1.41531,103.75491999999997',
          '1.4151200000000002,103.75497999999997',
          '1.41497,103.75502999999998',
          '1.41482,103.75508999999998',
          '1.41468,103.75514999999999',
          '1.4144599999999998,103.75525999999999',
          '1.4142299999999999,103.7554',
          '1.41386,103.7556',
          '1.41373,103.75566',
          '1.41359,103.75572000000001',
          '1.4134799999999998,103.75576000000001',
          '1.41339,103.75579',
          '1.41329,103.75582',
          '1.41315,103.75586',
          '1.41306,103.75588',
          '1.41299,103.75589000000001',
          '1.41292,103.75590000000001',
          '1.41282,103.75591000000001',
          '1.4127,103.75592000000002',
          '1.4126100000000001,103.75592000000002',
          '1.4124800000000002,103.75592000000002',
          '1.41226,103.75592000000002',
          '1.4121400000000002,103.75592000000002',
          '1.4114900000000001,103.75591000000001',
          '1.41118,103.75589000000001',
          '1.4108800000000001,103.75587',
          '1.4106200000000002,103.75585',
          '1.4104500000000002,103.75583999999999',
          '1.4101900000000003,103.75582999999999',
          '1.4099700000000002,103.75582999999999',
          '1.4098800000000002,103.75582999999999',
          '1.4097100000000002,103.75583999999999',
          '1.4095400000000002,103.75586',
          '1.4093400000000003,103.75589',
          '1.4091000000000002,103.75592999999999',
          '1.4088600000000002,103.75596999999999',
          '1.4085300000000003,103.75601999999999',
          '1.4077200000000003,103.75614999999999',
          '1.4075700000000002,103.75617',
          '1.4074000000000002,103.75619',
          '1.4072000000000002,103.75621000000001',
          '1.4070200000000002,103.75622000000001',
          '1.4067500000000002,103.75623000000002',
          '1.4061100000000002,103.75625000000002',
          '1.4053300000000002,103.75627000000003',
          '1.4051900000000002,103.75627000000003',
          '1.40501,103.75627000000003',
          '1.4045500000000002,103.75626000000003',
          '1.4044600000000003,103.75626000000003',
          '1.4041600000000003,103.75626000000003',
          '1.4039100000000002,103.75625000000002',
          '1.4036200000000003,103.75623000000002',
          '1.4033700000000002,103.75621000000001',
          '1.4030800000000003,103.75618000000001',
          '1.4028400000000003,103.75615000000002',
          '1.4026900000000002,103.75613000000001',
          '1.4026200000000002,103.75612000000001',
          '1.4024300000000003,103.75609000000001',
          '1.4022400000000004,103.75606000000002',
          '1.4020600000000003,103.75603000000002',
          '1.4019000000000004,103.75600000000003',
          '1.4014800000000003,103.75592000000003',
          '1.3998700000000004,103.75562000000004',
          '1.3997200000000003,103.75559000000004',
          '1.3995100000000003,103.75554000000004',
          '1.3992800000000003,103.75548000000003',
          '1.3991100000000003,103.75543000000003',
          '1.3989300000000002,103.75537000000003',
          '1.3986500000000002,103.75527000000002',
          '1.3981500000000002,103.75509000000002',
          '1.3977600000000003,103.75495000000002',
          '1.3968600000000004,103.75464000000002',
          '1.3964900000000005,103.75451000000002',
          '1.3961000000000006,103.75437000000002',
          '1.3953100000000005,103.75409000000002',
          '1.3952500000000005,103.75407000000001',
          '1.3951800000000005,103.75405',
          '1.3951000000000005,103.75403',
          '1.3950100000000005,103.75401',
          '1.3949500000000006,103.75399999999999',
          '1.3948600000000007,103.75398999999999',
          '1.3947100000000006,103.75397999999998',
          '1.3946200000000006,103.75397999999998',
          '1.3943800000000006,103.75397999999998',
          '1.3942600000000007,103.75397999999998',
          '1.3940900000000007,103.75398999999999',
          '1.3939600000000008,103.75399999999999',
          '1.3937000000000008,103.75402',
          '1.3935200000000008,103.75404',
          '1.3933700000000007,103.75406000000001',
          '1.3931700000000007,103.75409',
          '1.3929100000000008,103.75413',
          '1.3925100000000008,103.7542',
          '1.3914600000000008,103.75439',
          '1.391270000000001,103.75443',
          '1.3911200000000008,103.75446',
          '1.3909800000000008,103.75448999999999',
          '1.3908400000000007,103.75451999999999',
          '1.3906700000000007,103.75455999999998',
          '1.3905000000000007,103.75460999999999',
          '1.3903400000000008,103.75465999999999',
          '1.3901200000000007,103.75472999999998',
          '1.3898000000000006,103.75482999999998',
          '1.3896800000000007,103.75486999999998',
          '1.3895900000000008,103.75489999999998',
          '1.3894800000000007,103.75493999999998',
          '1.3893800000000007,103.75497999999997',
          '1.3892300000000006,103.75503999999998',
          '1.3891200000000006,103.75508999999998',
          '1.3889000000000005,103.75517999999998',
          '1.3888100000000005,103.75521999999998',
          '1.3886600000000004,103.75528999999997',
          '1.3884800000000004,103.75536999999997',
          '1.3883700000000003,103.75541999999997',
          '1.3882600000000003,103.75546999999997',
          '1.3881800000000002,103.75550999999997',
          '1.3880500000000002,103.75557999999997',
          '1.3879400000000002,103.75563999999997',
          '1.3878200000000003,103.75570999999997',
          '1.3877100000000002,103.75577999999996',
          '1.3876200000000003,103.75583999999996',
          '1.3875500000000003,103.75588999999997',
          '1.3868700000000003,103.75631999999996',
          '1.3867600000000002,103.75638999999995',
          '1.3866700000000003,103.75644999999996',
          '1.3865800000000004,103.75650999999996',
          '1.3863300000000003,103.75667999999996',
          '1.3860400000000004,103.75687999999997',
          '1.3859400000000004,103.75694999999996',
          '1.3858000000000004,103.75704999999996',
          '1.3857000000000004,103.75711999999996',
          '1.3854600000000004,103.75730999999996',
          '1.3852000000000004,103.75752999999996',
          '1.3849600000000004,103.75774999999996',
          '1.3845400000000003,103.75813999999995',
          '1.3843300000000003,103.75835999999995',
          '1.3841800000000002,103.75851999999995',
          '1.3840400000000002,103.75866999999995',
          '1.3839200000000003,103.75879999999995',
          '1.3838100000000002,103.75890999999996',
          '1.3836900000000003,103.75901999999996',
          '1.3836000000000004,103.75909999999996',
          '1.3834900000000003,103.75918999999996',
          '1.3833900000000003,103.75926999999996',
          '1.3833100000000003,103.75932999999996',
          '1.3832100000000003,103.75939999999996',
          '1.3830600000000002,103.75949999999996',
          '1.3828900000000002,103.75960999999997',
          '1.3824600000000002,103.75983999999997',
          '1.3821800000000002,103.75998999999997',
          '1.3818200000000003,103.76016999999997',
          '1.3813700000000002,103.76041999999997',
          '1.3810300000000002,103.76060999999997',
          '1.3806200000000002,103.76082999999997',
          '1.3801500000000002,103.76107999999996',
          '1.3798100000000002,103.76124999999996',
          '1.3796500000000003,103.76133999999996',
          '1.3793200000000003,103.76151999999996',
          '1.3790600000000004,103.76166999999997',
          '1.3788700000000005,103.76176999999997',
          '1.3787500000000006,103.76182999999997',
          '1.3785100000000006,103.76192999999998',
          '1.3782500000000006,103.76201999999998',
          '1.3780800000000006,103.76206999999998',
          '1.3779000000000006,103.76211999999998',
          '1.3776800000000005,103.76217999999999',
          '1.3775900000000005,103.76219999999999',
          '1.3774900000000005,103.76222',
          '1.3773300000000006,103.76225',
          '1.3772200000000006,103.76227',
          '1.3770400000000005,103.7623',
          '1.3769100000000005,103.76232',
          '1.3767100000000005,103.76235',
          '1.3764700000000005,103.76239',
          '1.3749800000000005,103.76265',
          '1.3747900000000006,103.76267999999999',
          '1.3746200000000006,103.76270999999998',
          '1.3745000000000007,103.76272999999999',
          '1.3741200000000007,103.76279999999998',
          '1.3736100000000007,103.76289999999999',
          '1.3730900000000006,103.76299999999999',
          '1.3727600000000006,103.76306999999998',
          '1.3724200000000006,103.76313999999998',
          '1.3720100000000006,103.76322999999998',
          '1.3718800000000007,103.76325999999997',
          '1.3717600000000008,103.76328999999997',
          '1.3716500000000007,103.76331999999996',
          '1.3714100000000007,103.76339999999996',
          '1.3713000000000006,103.76343999999996',
          '1.3712400000000007,103.76345999999997',
          '1.3711600000000006,103.76348999999996',
          '1.3710500000000005,103.76352999999996',
          '1.3708700000000005,103.76359999999995',
          '1.3706700000000005,103.76368999999995',
          '1.3705100000000006,103.76376999999995',
          '1.3703600000000005,103.76384999999995',
          '1.3702500000000004,103.76390999999995',
          '1.3701800000000004,103.76394999999995',
          '1.3701100000000004,103.76398999999995',
          '1.3700600000000003,103.76401999999995',
          '1.3699700000000004,103.76407999999995',
          '1.3696400000000004,103.76430999999995',
          '1.3694700000000004,103.76442999999995',
          '1.3693600000000004,103.76450999999994',
          '1.3692000000000004,103.76461999999995',
          '1.3687300000000004,103.76493999999995',
          '1.3684900000000004,103.76510999999995',
          '1.3680300000000005,103.76542999999995',
          '1.3677400000000006,103.76562999999996',
          '1.3675400000000006,103.76576999999996',
          '1.3674400000000007,103.76583999999995',
          '1.3672800000000007,103.76594999999996',
          '1.3671900000000008,103.76604999999996',
          '1.3671500000000008,103.76608999999996',
          '1.3670600000000008,103.76616999999996',
          '1.3669800000000008,103.76623999999995',
          '1.3668800000000008,103.76631999999995',
          '1.3667600000000009,103.76640999999995',
          '1.366600000000001,103.76651999999996',
          '1.3661500000000009,103.76681999999995',
          '1.3658100000000009,103.76703999999995',
          '1.365520000000001,103.76721999999995',
          '1.365390000000001,103.76729999999995',
          '1.365270000000001,103.76736999999994',
          '1.365130000000001,103.76744999999994',
          '1.3650100000000012,103.76750999999994',
          '1.3649000000000011,103.76755999999995',
          '1.3647800000000012,103.76760999999995',
          '1.3646200000000013,103.76766999999995',
          '1.3644700000000012,103.76771999999995',
          '1.3644000000000012,103.76773999999996',
          '1.364320000000001,103.76775999999997',
          '1.3642300000000012,103.76777999999997',
          '1.3641000000000012,103.76779999999998',
          '1.3639800000000013,103.76780999999998',
          '1.3638900000000014,103.76780999999998',
          '1.3638300000000014,103.76780999999998',
          '1.3635100000000013,103.76779999999998',
          '1.3634700000000013,103.76779999999998',
          '1.3634400000000013,103.76779999999998',
          '1.3633600000000012,103.76778999999998',
          '1.3633000000000013,103.76777999999997',
          '1.3632400000000013,103.76776999999997',
          '1.3631600000000013,103.76774999999996',
          '1.3630500000000012,103.76771999999997',
          '1.3628600000000013,103.76765999999996',
          '1.3628060000000013,103.76764199999997',
          '1.362806,103.767642',
          '1.36265,103.76759',
          '1.3623399999999999,103.76749',
          '1.36224,103.76746',
          '1.36211,103.76742',
          '1.36175,103.7673',
          '1.36166,103.76727000000001',
          '1.36158,103.76725',
          '1.36153,103.76724',
          '1.36141,103.76722',
          '1.3613,103.76720999999999',
          '1.36117,103.76719999999999',
          '1.36094,103.76718999999999',
          '1.36072,103.76719999999999',
          '1.36052,103.76722',
          '1.36029,103.76724999999999',
          '1.35993,103.76729999999999',
          '1.35956,103.76735',
          '1.3592300000000002,103.76738999999999',
          '1.3584200000000002,103.76746999999999',
          '1.3580500000000002,103.76750999999999',
          '1.3578900000000003,103.76753',
          '1.3577200000000003,103.76755',
          '1.3576200000000003,103.76757',
          '1.3575200000000003,103.76759000000001',
          '1.3574200000000003,103.76762000000001',
          '1.3573300000000004,103.76765',
          '1.3572500000000003,103.76769',
          '1.3571800000000003,103.76773',
          '1.3571000000000002,103.76778',
          '1.3570100000000003,103.76785',
          '1.3568700000000002,103.76796',
          '1.3566900000000002,103.76811000000001',
          '1.3565000000000003,103.76826000000001',
          '1.3563100000000003,103.76841000000002',
          '1.3561800000000004,103.76849000000001',
          '1.3560000000000003,103.76859000000002',
          '1.3558200000000002,103.76869000000002',
          '1.3555100000000002,103.76883000000002',
          '1.35529,103.76892000000002',
          '1.35505,103.76901000000002',
          '1.35497,103.76904000000002',
          '1.35453,103.76920000000001',
          '1.35423,103.76931000000002',
          '1.35407,103.76937000000002',
          '1.3537700000000001,103.76947000000003',
          '1.3535000000000001,103.76956000000003',
          '1.3531300000000002,103.76967000000003',
          '1.3529400000000003,103.76972000000004',
          '1.3527400000000003,103.76977000000004',
          '1.3524700000000003,103.76983000000004',
          '1.3523300000000003,103.76986000000004',
          '1.3521700000000003,103.76989000000003',
          '1.3519900000000002,103.76992000000003',
          '1.3517900000000003,103.76995000000002',
          '1.3515700000000002,103.76998000000002',
          '1.3513000000000002,103.77002000000002',
          '1.3510100000000003,103.77006000000002',
          '1.3502600000000002,103.77016000000002',
          '1.3500200000000002,103.77019000000001',
          '1.3497900000000003,103.77022000000001',
          '1.3496600000000003,103.77024000000002',
          '1.3494800000000002,103.77027000000001',
          '1.3493300000000001,103.7703',
          '1.34925,103.77032000000001',
          '1.34918,103.77034000000002',
          '1.34912,103.77036000000003',
          '1.34907,103.77038000000003',
          '1.34896,103.77043000000003',
          '1.3488499999999999,103.77049000000004',
          '1.34873,103.77056000000003',
          '1.34853,103.77070000000003',
          '1.34826,103.77090000000004',
          '1.34806,103.77104000000004',
          '1.34791,103.77114000000005',
          '1.3477599999999998,103.77124000000005',
          '1.3474,103.77146000000005',
          '1.3472499999999998,103.77155000000005',
          '1.3471199999999999,103.77163000000004',
          '1.3469799999999998,103.77172000000004',
          '1.3468399999999998,103.77182000000005',
          '1.3467399999999998,103.77190000000004',
          '1.3466099999999999,103.77201000000005',
          '1.3464999999999998,103.77211000000005',
          '1.3463999999999998,103.77221000000006',
          '1.34631,103.77231000000006',
          '1.34625,103.77238000000006',
          '1.34616,103.77249000000006',
          '1.34611,103.77256000000006',
          '1.3460699999999999,103.77263000000005',
          '1.3459199999999998,103.77289000000005',
          '1.3458699999999997,103.77298000000005',
          '1.3458199999999996,103.77307000000005',
          '1.3456899999999996,103.77329000000005',
          '1.3455999999999997,103.77345000000004',
          '1.3453999999999997,103.77380000000004',
          '1.3453599999999997,103.77387000000003',
          '1.3450999999999997,103.77433000000003',
          '1.3449499999999996,103.77462000000004',
          '1.3448799999999996,103.77475000000004',
          '1.3448099999999996,103.77487000000004',
          '1.3447599999999995,103.77496000000004',
          '1.3446799999999994,103.77511000000004',
          '1.3446099999999994,103.77523000000004',
          '1.3443899999999993,103.77529000000004',
          '1.3438399999999993,103.77538000000004',
          '1.3435699999999993,103.77543000000004',
          '1.3434099999999993,103.77546000000004',
          '1.3430899999999992,103.77552000000004',
          '1.3428699999999991,103.77556000000004',
          '1.342659999999999,103.77560000000004',
          '1.3424999999999991,103.77563000000004',
          '1.3423299999999991,103.77566000000003',
          '1.3421599999999991,103.77569000000003',
          '1.341879999999999,103.77574000000003',
          '1.341669999999999,103.77578000000003',
          '1.341419999999999,103.77583000000003',
          '1.341009999999999,103.77592000000003',
          '1.340896999999999,103.77594500000002',
          '1.340897,103.775945',
          '1.34074,103.77597999999999',
          '1.34061,103.77600999999999',
          '1.34051,103.77603999999998',
          '1.3404500000000001,103.77605999999999',
          '1.3404,103.77608',
          '1.3403100000000001,103.77611999999999',
          '1.3401900000000002,103.77618999999999',
          '1.3400800000000002,103.77626999999998',
          '1.33997,103.77634999999998',
          '1.33966,103.77657999999998',
          '1.33959,103.77662999999998',
          '1.33939,103.77677999999999',
          '1.33908,103.77699999999999',
          '1.33873,103.77723999999999',
          '1.3385799999999999,103.77735',
          '1.33846,103.77744',
          '1.33827,103.77761',
          '1.3382,103.77767',
          '1.33815,103.77772',
          '1.3380999999999998,103.77778',
          '1.3380499999999997,103.77785',
          '1.3380199999999998,103.7779',
          '1.3379799999999997,103.77798',
          '1.3379599999999998,103.77802',
          '1.3378799999999997,103.77822',
          '1.3378199999999998,103.77838',
          '1.3377699999999997,103.77853',
          '1.3376899999999996,103.77876',
          '1.3376499999999996,103.77887000000001',
          '1.3376199999999996,103.77895000000001',
          '1.3375899999999996,103.77905000000001',
          '1.3375199999999996,103.77926000000001',
          '1.3373599999999997,103.77973000000001',
          '1.3373099999999996,103.77988000000002',
          '1.3372399999999995,103.78010000000002',
          '1.3371499999999996,103.78037000000002',
          '1.3370199999999997,103.78077000000002',
          '1.3369699999999995,103.78095000000002',
          '1.3369299999999995,103.78109000000002',
          '1.3368399999999996,103.78137000000002',
          '1.3367199999999997,103.78172000000002',
          '1.3365699999999996,103.78216000000002',
          '1.3362199999999995,103.78316000000002',
          '1.3361699999999994,103.78330000000003',
          '1.3360899999999993,103.78353000000003',
          '1.3360099999999993,103.78376000000003',
          '1.3359599999999991,103.78390000000003',
          '1.335849999999999,103.78421000000003',
          '1.335439999999999,103.78537000000003',
          '1.335169999999999,103.78614000000003',
          '1.335059999999999,103.78646000000003',
          '1.335029999999999,103.78654000000003',
          '1.334919999999999,103.78685000000003',
          '1.334829999999999,103.78711000000003',
          '1.334749999999999,103.78734000000003',
          '1.334689999999999,103.78750000000002',
          '1.334629999999999,103.78766000000002',
          '1.334459999999999,103.78814000000001',
          '1.3341299999999991,103.78909000000002',
          '1.3337999999999992,103.79006000000001',
          '1.3334699999999993,103.79106000000002',
          '1.3327299999999993,103.79325000000001',
          '1.3323799999999992,103.79426000000001',
          '1.3322899999999993,103.79452',
          '1.3322099999999992,103.79475000000001',
          '1.3319499999999993,103.79549',
          '1.3318599999999994,103.79581',
          '1.3318199999999993,103.79593',
          '1.3317499999999993,103.79614',
          '1.3316699999999992,103.79638',
          '1.3315699999999993,103.79666',
          '1.3314699999999993,103.79693',
          '1.3313399999999993,103.79728',
          '1.3311899999999992,103.79765',
          '1.3310899999999992,103.7979',
          '1.3309799999999992,103.79822',
          '1.330899999999999,103.79846',
          '1.3307799999999992,103.79879000000001',
          '1.3306399999999992,103.79918',
          '1.3305399999999992,103.79947000000001',
          '1.3303999999999991,103.79986000000001',
          '1.330179999999999,103.80049000000001',
          '1.330049999999999,103.80086000000001',
          '1.3298199999999991,103.80150000000002',
          '1.329679999999999,103.80190000000002',
          '1.329539999999999,103.80230000000002',
          '1.329479999999999,103.80247000000001',
          '1.3293799999999991,103.80275000000002',
          '1.329309999999999,103.80295000000002',
          '1.329259999999999,103.80309000000003',
          '1.329219999999999,103.80319000000003',
          '1.3291699999999989,103.80330000000004',
          '1.3291199999999987,103.80341000000004',
          '1.3290299999999988,103.80358000000004',
          '1.328939999999999,103.80374000000003',
          '1.3288699999999989,103.80385000000004',
          '1.3287999999999989,103.80396000000005',
          '1.328669999999999,103.80417000000004',
          '1.328469999999999,103.80448000000004',
          '1.328179999999999,103.80494000000004',
          '1.327919999999999,103.80536000000005',
          '1.3275899999999992,103.80586000000005',
          '1.3275299999999992,103.80595000000005',
          '1.3274099999999993,103.80613000000005',
          '1.3271799999999994,103.80647000000005',
          '1.3269799999999994,103.80676000000005',
          '1.3268699999999993,103.80692000000005',
          '1.3265399999999994,103.80741000000005',
          '1.3262099999999994,103.80789000000004',
          '1.3259299999999994,103.80830000000005',
          '1.3258699999999994,103.80839000000005',
          '1.3257499999999995,103.80857000000005',
          '1.3252099999999996,103.80936000000004',
          '1.3249199999999997,103.80977000000004',
          '1.3248199999999997,103.80991000000004',
          '1.3247199999999997,103.81005000000005',
          '1.3246099999999996,103.81020000000005',
          '1.3245099999999996,103.81034000000005',
          '1.3244499999999997,103.81052000000005',
          '1.3243599999999998,103.81076000000006',
          '1.3243099999999997,103.81090000000006',
          '1.3242599999999995,103.81105000000007',
          '1.3242199999999995,103.81118000000006',
          '1.3241799999999995,103.81133000000007',
          '1.3241099999999995,103.81163000000006',
          '1.3240599999999993,103.81184000000006',
          '1.3240199999999993,103.81202000000006',
          '1.3239899999999993,103.81216000000006',
          '1.3239699999999994,103.81228000000006',
          '1.3239599999999994,103.81236000000006',
          '1.3239499999999993,103.81250000000006',
          '1.3239399999999992,103.81264000000006',
          '1.3239299999999992,103.81293000000007',
          '1.3239299999999992,103.81306000000006',
          '1.3239299999999992,103.81315000000006',
          '1.323919999999999,103.81333000000006',
          '1.323909999999999,103.81341000000006',
          '1.3238899999999991,103.81352000000007',
          '1.3238699999999992,103.81361000000007',
          '1.3238399999999992,103.81370000000007',
          '1.3237999999999992,103.81380000000007',
          '1.323749999999999,103.81392000000007',
          '1.3237199999999991,103.81398000000007',
          '1.323669999999999,103.81408000000008',
          '1.323619999999999,103.81418000000008',
          '1.323559999999999,103.81430000000007',
          '1.323529999999999,103.81437000000007',
          '1.323499999999999,103.81445000000006',
          '1.323479999999999,103.81451000000007',
          '1.3234599999999992,103.81459000000007',
          '1.3234399999999993,103.81470000000007',
          '1.3234299999999992,103.81476000000008',
          '1.3233999999999992,103.81500000000008',
          '1.3233799999999993,103.81517000000008',
          '1.3233499999999994,103.81545000000008',
          '1.3233199999999994,103.81569000000009',
          '1.3232999999999995,103.81587000000009',
          '1.3232679999999994,103.81616900000009',
          '1.323268,103.816169',
          '1.32326,103.81624000000001',
          '1.3232400000000002,103.81644000000001',
          '1.3232100000000002,103.81670000000001',
          '1.3231500000000003,103.81701000000001',
          '1.3231400000000002,103.81724000000001',
          '1.3231200000000003,103.81753000000002',
          '1.3231000000000004,103.81773000000003',
          '1.3230700000000004,103.81801000000003',
          '1.3230400000000004,103.81829000000003',
          '1.3230100000000005,103.81857000000004',
          '1.3229900000000006,103.81872000000004',
          '1.3229700000000006,103.81884000000004',
          '1.3229500000000007,103.81895000000004',
          '1.3229200000000008,103.81909000000005',
          '1.3228300000000008,103.81944000000004',
          '1.3227800000000007,103.81963000000005',
          '1.3227100000000007,103.81989000000004',
          '1.3225800000000008,103.82039000000005',
          '1.3224800000000008,103.82077000000005',
          '1.3224100000000008,103.82105000000006',
          '1.3222800000000008,103.82154000000006',
          '1.3221900000000009,103.82185000000005',
          '1.322130000000001,103.82207000000005',
          '1.322040000000001,103.82236000000006',
          '1.321970000000001,103.82257000000006',
          '1.3219200000000009,103.82279000000005',
          '1.321890000000001,103.82296000000005',
          '1.3218400000000008,103.82312000000005',
          '1.3217500000000009,103.82343000000004',
          '1.3217000000000008,103.82359000000004',
          '1.3215100000000009,103.82418000000004',
          '1.3213800000000009,103.82460000000005',
          '1.321290000000001,103.82487000000005',
          '1.3212400000000009,103.82502000000005',
          '1.3211900000000008,103.82517000000006',
          '1.3211000000000008,103.82544000000006',
          '1.3210600000000008,103.82555000000006',
          '1.3209600000000008,103.82552000000007',
          '1.320840000000001,103.82550000000006',
          '1.320740000000001,103.82549000000006',
          '1.320650000000001,103.82549000000006',
          '1.320530000000001,103.82549000000006',
          '1.320460000000001,103.82549000000006',
          '1.320380000000001,103.82549000000006',
          '1.320060000000001,103.82552000000005',
          '1.3198800000000008,103.82554000000006',
          '1.3195600000000007,103.82558000000006',
          '1.3194200000000007,103.82560000000007',
          '1.3189800000000007,103.82566000000007',
          '1.3186400000000007,103.82571000000007',
          '1.3184400000000007,103.82574000000007',
          '1.3179400000000008,103.82582000000006',
          '1.3177700000000008,103.82585000000006',
          '1.3176000000000008,103.82588000000005',
          '1.3173700000000008,103.82592000000005',
          '1.3169500000000007,103.82599000000005',
          '1.3166700000000007,103.82604000000005',
          '1.3162600000000007,103.82610000000005',
          '1.3161900000000006,103.82611000000006',
          '1.3159900000000007,103.82614000000005',
          '1.3155600000000007,103.82620000000006',
          '1.3153800000000007,103.82623000000005',
          '1.3152000000000006,103.82626000000005',
          '1.3150600000000006,103.82629000000004',
          '1.3149600000000006,103.82632000000004',
          '1.3148600000000006,103.82636000000004',
          '1.3147600000000006,103.82641000000004',
          '1.3146800000000005,103.82646000000004',
          '1.3146100000000005,103.82651000000004',
          '1.3145500000000006,103.82656000000004',
          '1.3144600000000006,103.82664000000004',
          '1.3143800000000005,103.82673000000004',
          '1.3143000000000005,103.82682000000004',
          '1.3142100000000005,103.82693000000005',
          '1.3141100000000006,103.82706000000005',
          '1.3140300000000005,103.82717000000005',
          '1.3139500000000004,103.82728000000006',
          '1.3138700000000003,103.82739000000007',
          '1.3137900000000002,103.82751000000006',
          '1.3137300000000003,103.82760000000006',
          '1.3136800000000002,103.82768000000006',
          '1.3136100000000002,103.82780000000005',
          '1.31356,103.82790000000006',
          '1.31352,103.82799000000006',
          '1.31348,103.82809000000006',
          '1.3134299999999999,103.82823000000006',
          '1.3133899999999998,103.82839000000006',
          '1.3133499999999998,103.82855000000005',
          '1.3133099999999998,103.82871000000004',
          '1.3132499999999998,103.82894000000005',
          '1.3131599999999999,103.82926000000005',
          '1.3130499999999998,103.82966000000005',
          '1.31303,103.82974000000004',
          '1.3130199999999999,103.82979000000005',
          '1.313,103.82987000000004',
          '1.31296,103.83002000000005',
          '1.3128499999999999,103.83045000000004',
          '1.31279,103.83072000000004',
          '1.3127199999999999,103.83103000000004',
          '1.3126699999999998,103.83124000000004',
          '1.3125599999999997,103.83170000000004',
          '1.3124899999999997,103.83199000000005',
          '1.3124399999999996,103.83219000000005',
          '1.3124099999999996,103.83231000000005',
          '1.3123699999999996,103.83244000000005',
          '1.3123299999999996,103.83255000000005',
          '1.3122899999999995,103.83264000000005',
          '1.3122499999999995,103.83271000000005',
          '1.3121999999999994,103.83279000000005',
          '1.3121699999999994,103.83283000000004',
          '1.3121099999999994,103.83290000000004',
          '1.3120799999999995,103.83293000000003',
          '1.3120099999999995,103.83299000000004',
          '1.3119299999999994,103.83305000000004',
          '1.3118699999999994,103.83309000000004',
          '1.3118399999999995,103.83311000000005',
          '1.3117899999999993,103.83314000000004',
          '1.3117499999999993,103.83316000000005',
          '1.3116999999999992,103.83318000000006',
          '1.3116199999999991,103.83321000000005',
          '1.3115299999999992,103.83324000000005',
          '1.3114299999999992,103.83327000000004',
          '1.3113399999999993,103.83329000000005',
          '1.3112799999999993,103.83330000000005',
          '1.3112199999999994,103.83331000000005',
          '1.3111199999999994,103.83332000000006',
          '1.3108899999999994,103.83335000000005',
          '1.3105899999999995,103.83340000000005',
          '1.3101099999999994,103.83347000000005',
          '1.3099599999999993,103.83349000000005',
          '1.3097399999999992,103.83352000000005',
          '1.3095299999999992,103.83354000000006',
          '1.3091399999999993,103.83360000000006',
          '1.3090599999999992,103.83361000000006',
          '1.3089699999999993,103.83362000000007',
          '1.3088499999999994,103.83363000000007',
          '1.3087699999999993,103.83363000000007',
          '1.3086899999999992,103.83363000000007',
          '1.3083899999999993,103.83351000000008',
          '1.3081199999999993,103.83340000000007',
          '1.3078499999999993,103.83328000000007',
          '1.3076199999999993,103.83318000000007',
          '1.3074799999999993,103.83312000000006',
          '1.3073199999999994,103.83305000000007',
          '1.3071199999999994,103.83296000000007',
          '1.3069099999999993,103.83287000000007',
          '1.3068599999999992,103.83285000000006',
          '1.3062999999999994,103.83259000000007',
          '1.3061899999999993,103.83254000000007',
          '1.3058299999999994,103.83237000000007',
          '1.3057399999999995,103.83233000000007',
          '1.3056599999999994,103.83229000000007',
          '1.3055799999999993,103.83225000000007',
          '1.3055099999999993,103.83221000000007',
          '1.3054299999999992,103.83216000000007',
          '1.3053299999999992,103.83209000000008',
          '1.3052699999999993,103.83209000000008',
          '1.3051799999999993,103.83209000000008',
          '1.3050899999999994,103.83210000000008',
          '1.3049699999999995,103.83212000000009',
          '1.3048399999999996,103.83215000000008',
          '1.3046699999999996,103.83241000000008',
          '1.3045499999999997,103.83263000000008',
          '1.3044089999999997,103.83290700000008',
          '1.304409,103.832907',
          '1.3043099999999999,103.8331',
          '1.30419,103.83334',
          '1.30396,103.83379000000001',
          '1.30384,103.83401',
          '1.30373,103.83424000000001',
          '1.3037,103.83430000000001',
          '1.3036800000000002,103.83434000000001',
          '1.3035500000000002,103.83457000000001',
          '1.3033000000000001,103.83503000000002',
          '1.30285,103.83578000000001',
          '1.3027900000000001,103.83590000000001',
          '1.30271,103.83606',
          '1.30261,103.83624',
          '1.3024900000000001,103.83647',
          '1.30238,103.83667000000001',
          '1.30217,103.8371',
          '1.30199,103.83745',
          '1.30197,103.83749',
          '1.30192,103.83759',
          '1.30166,103.83812',
          '1.3015700000000001,103.83832000000001',
          '1.3014700000000001,103.83855000000001',
          '1.3014000000000001,103.83872000000001',
          '1.3013100000000002,103.83895000000001',
          '1.3012700000000001,103.83907',
          '1.3011800000000002,103.83935000000001',
          '1.3011300000000001,103.83951',
          '1.30106,103.83975000000001',
          '1.3009600000000001,103.84007000000001',
          '1.3008600000000001,103.84040000000002',
          '1.3007600000000001,103.84074000000001',
          '1.30058,103.8413',
          '1.3005,103.84158000000001',
          '1.30038,103.84197',
          '1.3001900000000002,103.84252000000001',
          '1.30004,103.84294000000001',
          '1.3000200000000002,103.84300000000002',
          '1.2999000000000003,103.84335000000002',
          '1.2998500000000002,103.84350000000002',
          '1.2998300000000003,103.84355000000002',
          '1.2997700000000003,103.84374000000003',
          '1.2997500000000004,103.84381000000002',
          '1.2997200000000004,103.84390000000002',
          '1.2996200000000004,103.84420000000001',
          '1.2995700000000003,103.84435000000002',
          '1.2995200000000002,103.84450000000002',
          '1.2994800000000002,103.84462000000002',
          '1.29943,103.84474000000002',
          '1.2993700000000001,103.84490000000001',
          '1.29922,103.84537000000002',
          '1.29917,103.84552000000002',
          '1.2991,103.84574000000002',
          '1.29908,103.84580000000003',
          '1.29899,103.84608000000003',
          '1.2989300000000001,103.84627000000003',
          '1.2989100000000002,103.84637000000004',
          '1.2988400000000002,103.84657000000004',
          '1.2987700000000002,103.84678000000004',
          '1.2987400000000002,103.84689000000004',
          '1.2987200000000003,103.84701000000004',
          '1.2987100000000003,103.84721000000005',
          '1.2987100000000003,103.84747000000004',
          '1.2987100000000003,103.84757000000005',
          '1.2987300000000002,103.84784000000005',
          '1.2987400000000002,103.84801000000004',
          '1.2987400000000002,103.84818000000004',
          '1.2987400000000002,103.84823000000004',
          '1.2987300000000002,103.84830000000004',
          '1.29872,103.84837000000003',
          '1.2986900000000001,103.84848000000004',
          '1.2986600000000001,103.84857000000004',
          '1.2986300000000002,103.84863000000004',
          '1.2985900000000001,103.84870000000004',
          '1.2985600000000002,103.84875000000004',
          '1.29851,103.84882000000003',
          '1.29847,103.84887000000003',
          '1.29832,103.84907000000004',
          '1.29812,103.84933000000004',
          '1.29798,103.84950000000003',
          '1.2979399999999999,103.84955000000004',
          '1.29788,103.84962000000003',
          '1.29781,103.84970000000003',
          '1.29774,103.84978000000002',
          '1.29728,103.85032000000002',
          '1.29707,103.85059000000003',
          '1.2969199999999999,103.85077000000003',
          '1.2966399999999998,103.85111000000002',
          '1.29635,103.85147000000002',
          '1.29606,103.85184000000002',
          '1.29603,103.85188000000002',
          '1.29594,103.85199000000003',
          '1.2957400000000001,103.85226000000003',
          '1.2956200000000002,103.85243000000003',
          '1.2954600000000003,103.85265000000003',
          '1.2951100000000002,103.85312000000003',
          '1.2949700000000002,103.85329000000003',
          '1.29489,103.85340000000004',
          '1.29464,103.85372000000004',
          '1.29443,103.85398000000004',
          '1.29425,103.85421000000004',
          '1.2941999999999998,103.85428000000003',
          '1.2940599999999998,103.85446000000003',
          '1.2940099999999997,103.85452000000004',
          '1.2938999999999996,103.85465000000003',
          '1.2938399999999997,103.85473000000003',
          '1.2935999999999996,103.85508000000003',
          '1.2933599999999996,103.85548000000003',
          '1.2932499999999996,103.85566000000003',
          '1.2931599999999996,103.85582000000002',
          '1.2930899999999996,103.85596000000002',
          '1.2925199999999997,103.85562000000003',
          '1.2922899999999997,103.85548000000003',
          '1.2920999999999998,103.85537000000002',
          '1.2919299999999998,103.85528000000002',
          '1.2917399999999999,103.85518000000002',
          '1.2913299999999999,103.85500000000002',
          '1.2914499999999998,103.85486000000002',
          '1.2914999999999999,103.85479000000002',
          '1.29154,103.85474000000002',
          '1.2915699999999999,103.85471000000003',
          '1.29161,103.85467000000003',
          '1.2917399999999999,103.85456000000002',
          '1.29192,103.85441000000002',
          '1.292,103.85434000000002',
          '1.29211,103.85423000000002',
          '1.29223,103.85411000000002',
          '1.29226,103.85408000000002',
          '1.2923499999999999,103.85397000000002',
          '1.2925099999999998,103.85377000000001',
          '1.2925999999999997,103.85364000000001',
          '1.2926299999999997,103.85359000000001',
          '1.2926599999999997,103.85355000000001',
          '1.2927699999999998,103.85338000000002',
          '1.2928699999999997,103.85322000000002',
          '1.2930799999999998,103.85289000000002',
          '1.29313,103.85282000000002',
          '1.29324,103.85264000000002',
          '1.29331,103.85253000000002',
          '1.29336,103.85245000000002',
          '1.29336,103.85245',
          '1.29336,103.85245',
          '1.2934700000000001,103.85226',
          '1.2936200000000002,103.85205',
          '1.2933300000000003,103.85188000000001',
          '1.2930700000000004,103.85172000000001',
          '1.2929600000000003,103.85165000000002',
          '1.2928500000000003,103.85158000000003',
          '1.2927500000000003,103.85152000000002',
          '1.2924900000000004,103.85136000000003',
          '1.2924400000000003,103.85133000000003',
          '1.2922200000000001,103.85120000000003',
          '1.2919900000000002,103.85106000000003',
          '1.2918600000000002,103.85098000000004',
          '1.2917900000000002,103.85094000000004',
          '1.2915400000000001,103.85081000000004',
          '1.2914,103.85073000000004',
          '1.2912800000000002,103.85066000000005',
          '1.2911900000000003,103.85061000000005',
          '1.2909900000000003,103.85050000000004',
          '1.2908000000000004,103.85039000000003',
          '1.2906600000000004,103.85031000000004',
          '1.2902900000000004,103.85009000000004',
          '1.2901800000000003,103.85002000000004',
          '1.2898700000000003,103.84982000000004',
          '1.2896700000000003,103.84969000000004',
          '1.2895400000000004,103.84961000000004',
          '1.2894100000000004,103.84953000000004',
          '1.2893300000000003,103.84948000000004',
          '1.2892500000000002,103.84943000000004',
          '1.2892000000000001,103.84940000000005',
          '1.2890400000000002,103.84930000000004',
          '1.2889100000000002,103.84922000000005',
          '1.2888600000000001,103.84919000000005',
          '1.28879,103.84915000000005',
          '1.28874,103.84912000000006',
          '1.28867,103.84908000000006',
          '1.28847,103.84896000000006',
          '1.28828,103.84885000000006',
          '1.2881500000000001,103.84877000000006',
          '1.2879800000000001,103.84867000000006',
          '1.2877,103.84851000000006',
          '1.28756,103.84843000000006',
          '1.28742,103.84835000000007',
          '1.28733,103.84830000000007',
          '1.2872100000000002,103.84823000000007',
          '1.2870900000000003,103.84816000000008',
          '1.2865100000000003,103.84782000000008',
          '1.2864400000000002,103.84778000000009',
          '1.2861300000000002,103.84760000000009',
          '1.2858600000000002,103.84744000000009',
          '1.2857100000000001,103.84735000000009',
          '1.28546,103.8471900000001',
          '1.28528,103.8475200000001',
          '1.28509,103.8478600000001',
          '1.2848600000000001,103.8482600000001',
          '1.28479,103.84838000000009',
          '1.28472,103.84850000000009',
          '1.28462,103.84867000000008',
          '1.28455,103.84879000000008',
          '1.28448,103.84890000000009',
          '1.2844200000000001,103.84899000000009',
          '1.2843600000000002,103.84907000000008',
          '1.28428,103.84917000000009',
          '1.28421,103.84925000000008',
          '1.2841500000000001,103.84932000000008',
          '1.2840600000000002,103.84942000000008',
          '1.28401,103.84947000000008',
          '1.2839500000000001,103.84953000000009',
          '1.28387,103.84960000000008',
          '1.2838,103.84966000000009',
          '1.28369,103.84974000000008',
          '1.28363,103.84978000000008',
          '1.28352,103.84985000000007',
          '1.2834,103.84992000000007',
          '1.2832800000000002,103.84999000000006',
          '1.2830300000000001,103.85013000000006',
          '1.2829400000000002,103.85018000000007',
          '1.2828600000000001,103.85023000000007',
          '1.2828300000000001,103.85025000000007',
          '1.2828000000000002,103.85027000000008',
          '1.2827600000000001,103.85030000000008',
          '1.2827400000000002,103.85032000000008',
          '1.2827200000000003,103.85035000000008',
          '1.2827100000000002,103.85037000000008',
          '1.2826900000000003,103.85041000000008',
          '1.2826800000000003,103.85044000000008',
          '1.2826700000000002,103.85049000000008',
          '1.2826700000000002,103.85054000000008',
          '1.2826700000000002,103.85060000000009',
          '1.2826800000000003,103.85094000000008',
          '1.2826400000000002,103.85110000000007',
          '1.2826100000000002,103.85121000000008',
          '1.2825700000000002,103.85134000000008',
          '1.2825400000000002,103.85143000000008',
          '1.2825100000000003,103.85152000000008',
          '1.2824800000000003,103.85159000000007',
          '1.2824400000000002,103.85167000000007',
          '1.2823800000000003,103.85177000000007',
          '1.2823100000000003,103.85188000000008',
          '1.2822200000000004,103.85202000000008',
          '1.2820500000000004,103.85228000000008',
          '1.2817800000000004,103.85269000000008',
          '1.2815700000000003,103.85300000000008',
          '1.2814200000000002,103.85323000000008',
          '1.2813500000000002,103.85334000000009',
          '1.2812900000000003,103.85343000000009',
          '1.2812500000000002,103.8534900000001',
          '1.2809800000000002,103.8539100000001',
          '1.2808100000000002,103.8541800000001',
          '1.2806400000000002,103.8544300000001',
          '1.2801900000000002,103.8551100000001',
          '1.27994,103.8554900000001',
          '1.27986,103.8556100000001',
          '1.27975,103.8557800000001',
          '1.2792999999999999,103.8564700000001',
          '1.27917,103.85667000000011',
          '1.279,103.8569300000001',
          '1.2789,103.85708000000011',
          '1.27883,103.8572000000001',
          '1.27863,103.8570700000001',
          '1.27849,103.8569800000001',
          '1.27823,103.85681000000011',
          '1.278,103.8566600000001',
          '1.2778100000000001,103.85654000000011',
          '1.2773100000000002,103.8562100000001',
          '1.27726,103.85618000000011',
          '1.27733,103.8560700000001',
          '1.27753,103.8557500000001',
          '1.277655,103.8555540000001',
        ],
      },
      {
        value: 1,
        path: [
          '1.277655,103.855554',
          '1.27783,103.85528',
          '1.27807,103.85490999999999',
          '1.27814,103.85479999999998',
          '1.2782200000000001,103.85468999999998',
          '1.2782900000000001,103.85458999999997',
          '1.2787700000000002,103.85384999999998',
          '1.2789000000000001,103.85364999999997',
          '1.2792400000000002,103.85314999999997',
          '1.2792800000000002,103.85308999999997',
          '1.2793100000000002,103.85303999999996',
          '1.2794800000000002,103.85278999999997',
          '1.2795800000000002,103.85263999999997',
          '1.2796500000000002,103.85252999999996',
          '1.2797100000000001,103.85243999999996',
          '1.27977,103.85234999999996',
          '1.2798200000000002,103.85226999999996',
          '1.2799900000000002,103.85200999999996',
          '1.2800500000000001,103.85192999999997',
          '1.28007,103.85189999999997',
          '1.2801200000000001,103.85182999999998',
          '1.28018,103.85174999999998',
          '1.2802200000000001,103.85169999999998',
          '1.2802900000000002,103.85161999999998',
          '1.2803600000000002,103.85153999999999',
          '1.2804200000000001,103.85147999999998',
          '1.2805000000000002,103.85139999999998',
          '1.2805700000000002,103.85132999999999',
          '1.2806200000000003,103.85126999999999',
          '1.2806800000000003,103.85118999999999',
          '1.2807400000000002,103.85110999999999',
          '1.2807800000000003,103.85104999999999',
          '1.2808400000000002,103.85095999999999',
          '1.2809000000000001,103.85085999999998',
          '1.2810500000000002,103.85058999999998',
          '1.2811900000000003,103.85033999999999',
          '1.2812400000000004,103.85038999999999',
          '1.2812900000000005,103.85043999999999',
          '1.2813400000000006,103.85047999999999',
          '1.2814600000000005,103.85056999999999',
          '1.2815300000000005,103.85061999999999',
          '1.2816200000000004,103.85068',
          '1.2816800000000004,103.85072',
          '1.2817600000000005,103.85077',
          '1.2818400000000005,103.85082',
          '1.2820400000000005,103.85094',
          '1.2823200000000006,103.85110999999999',
          '1.2824000000000007,103.85116',
          '1.2824300000000006,103.85118',
          '1.2824800000000007,103.85122',
          '1.2825100000000007,103.85125',
          '1.2825400000000007,103.85128999999999',
          '1.2825700000000007,103.85134',
          '1.2826000000000006,103.8514',
          '1.2826200000000005,103.85145',
          '1.2826400000000004,103.85149',
          '1.2826700000000004,103.85154',
          '1.2826700000000004,103.85165',
          '1.2826700000000004,103.85174',
          '1.2826800000000005,103.85184000000001',
          '1.2826900000000006,103.85191',
          '1.2827000000000006,103.85195',
          '1.2827100000000007,103.85198',
          '1.2827200000000007,103.85200999999999',
          '1.2827400000000007,103.85206',
          '1.2827600000000006,103.8521',
          '1.2827900000000005,103.85215',
          '1.2828300000000006,103.85221',
          '1.2828700000000006,103.85226',
          '1.2828900000000005,103.85228000000001',
          '1.2829100000000004,103.85230000000001',
          '1.2829500000000005,103.85234000000001',
          '1.2829900000000005,103.85238000000001',
          '1.2830400000000006,103.85242000000001',
          '1.2830800000000007,103.85245',
          '1.2831100000000006,103.85247000000001',
          '1.2831500000000007,103.85249000000002',
          '1.2831900000000007,103.85251000000002',
          '1.2832400000000008,103.85253000000003',
          '1.283320000000001,103.85256000000003',
          '1.283400000000001,103.85259000000002',
          '1.283540000000001,103.85264000000002',
          '1.283790000000001,103.85273000000002',
          '1.283880000000001,103.85276000000002',
          '1.284080000000001,103.85283000000001',
          '1.284280000000001,103.8529',
          '1.284460000000001,103.85297',
          '1.284720000000001,103.85308',
          '1.284810000000001,103.85312',
          '1.284950000000001,103.85318000000001',
          '1.2850700000000008,103.85323000000001',
          '1.285320000000001,103.85333000000001',
          '1.285470000000001,103.85339000000002',
          '1.285660000000001,103.85347000000002',
          '1.285900000000001,103.85357000000002',
          '1.2860200000000008,103.85362000000002',
          '1.2861300000000009,103.85366000000002',
          '1.2862200000000008,103.85369000000001',
          '1.2863100000000007,103.85372000000001',
          '1.2864100000000007,103.85375',
          '1.2864800000000007,103.85377000000001',
          '1.2865600000000008,103.85379000000002',
          '1.2866500000000007,103.85381000000002',
          '1.2867000000000008,103.85382000000003',
          '1.2867600000000008,103.85383000000003',
          '1.2868700000000008,103.85385000000004',
          '1.286980000000001,103.85387000000004',
          '1.2870400000000009,103.85388000000005',
          '1.288000000000001,103.85405000000004',
          '1.288890000000001,103.85421000000004',
          '1.289050000000001,103.85424000000003',
          '1.289160000000001,103.85426000000004',
          '1.289340000000001,103.85429000000003',
          '1.2895600000000011,103.85433000000003',
          '1.289720000000001,103.85436000000003',
          '1.2898300000000011,103.85438000000003',
          '1.2899300000000011,103.85440000000004',
          '1.290020000000001,103.85442000000005',
          '1.290150000000001,103.85445000000004',
          '1.290320000000001,103.85449000000004',
          '1.290480000000001,103.85453000000004',
          '1.290680000000001,103.85459000000004',
          '1.290920000000001,103.85467000000004',
          '1.2914500000000009,103.85486000000004',
          '1.291500000000001,103.85479000000005',
          '1.291540000000001,103.85474000000005',
          '1.291570000000001,103.85471000000005',
          '1.291610000000001,103.85467000000006',
          '1.291740000000001,103.85456000000005',
          '1.291920000000001,103.85441000000004',
          '1.2920000000000011,103.85434000000005',
          '1.2921100000000012,103.85423000000004',
          '1.292230000000001,103.85411000000005',
          '1.292260000000001,103.85408000000005',
          '1.292350000000001,103.85397000000005',
          '1.292510000000001,103.85377000000004',
          '1.2926000000000009,103.85364000000004',
          '1.2926300000000008,103.85359000000004',
          '1.2926600000000008,103.85355000000004',
          '1.2927700000000009,103.85338000000004',
          '1.2928700000000009,103.85322000000005',
          '1.293080000000001,103.85289000000004',
          '1.293130000000001,103.85282000000005',
          '1.293240000000001,103.85264000000005',
          '1.293310000000001,103.85253000000004',
          '1.2933600000000012,103.85245000000005',
          '1.29336,103.85245',
          '1.29336,103.85245',
          '1.2934700000000001,103.85226',
          '1.2936200000000002,103.85205',
          '1.2937400000000001,103.85185',
          '1.2940800000000001,103.85132',
          '1.2942200000000001,103.85109',
          '1.2944300000000002,103.85072',
          '1.2944700000000002,103.85065',
          '1.2945300000000002,103.85055',
          '1.2948100000000002,103.85007',
          '1.2949600000000003,103.84981',
          '1.2951900000000003,103.84941',
          '1.2954700000000003,103.84897000000001',
          '1.2956000000000003,103.84878',
          '1.2956700000000003,103.84868',
          '1.2958500000000004,103.84845',
          '1.2959300000000005,103.84834',
          '1.2960400000000005,103.84821',
          '1.2962400000000005,103.84797999999999',
          '1.2962700000000005,103.84794',
          '1.2963500000000006,103.84786',
          '1.2964300000000006,103.84778',
          '1.2964500000000005,103.84776',
          '1.2965400000000005,103.84765999999999',
          '1.2967300000000004,103.84745999999998',
          '1.2969200000000003,103.84724999999999',
          '1.2971200000000003,103.84704999999998',
          '1.2972800000000002,103.84688999999999',
          '1.2975300000000003,103.84664',
          '1.2976100000000004,103.84655',
          '1.2976500000000004,103.84651',
          '1.2976900000000005,103.84647',
          '1.2977700000000005,103.84637',
          '1.2978500000000006,103.84625999999999',
          '1.2979000000000007,103.84617999999999',
          '1.2979600000000007,103.84606',
          '1.2980200000000006,103.84593',
          '1.2980700000000007,103.84581',
          '1.2981500000000008,103.8456',
          '1.2981600000000009,103.84557000000001',
          '1.2982200000000008,103.84540000000001',
          '1.2982500000000008,103.84530000000001',
          '1.298300000000001,103.84514000000001',
          '1.298340000000001,103.84502000000002',
          '1.298420000000001,103.84476000000002',
          '1.298510000000001,103.84449000000002',
          '1.298570000000001,103.84432000000002',
          '1.2986300000000008,103.84418000000002',
          '1.298680000000001,103.84407000000002',
          '1.298710000000001,103.84401000000001',
          '1.298820000000001,103.84378000000001',
          '1.298910000000001,103.84361000000001',
          '1.2990000000000008,103.84347000000001',
          '1.299150000000001,103.84323',
          '1.299260000000001,103.84306000000001',
          '1.299370000000001,103.84288000000001',
          '1.299440000000001,103.84275000000001',
          '1.299510000000001,103.84261000000001',
          '1.2995600000000012,103.8425',
          '1.2996000000000012,103.84241',
          '1.299620000000001,103.84236',
          '1.299640000000001,103.8423',
          '1.299670000000001,103.84217',
          '1.299730000000001,103.84188999999999',
          '1.299780000000001,103.84164999999999',
          '1.2997900000000011,103.84159999999999',
          '1.2998600000000011,103.84125999999999',
          '1.299920000000001,103.84097999999999',
          '1.299990000000001,103.84066999999999',
          '1.300020000000001,103.84056999999999',
          '1.3000700000000012,103.84034999999999',
          '1.3000800000000012,103.84027999999999',
          '1.3001100000000012,103.84012',
          '1.3001400000000012,103.83994',
          '1.3001800000000012,103.83973999999999',
          '1.3002300000000013,103.83948',
          '1.3002900000000013,103.83919999999999',
          '1.3003300000000013,103.83899999999998',
          '1.3004000000000013,103.83863999999998',
          '1.3004200000000012,103.83852999999998',
          '1.3004500000000012,103.83836999999998',
          '1.3004600000000013,103.83830999999998',
          '1.3004900000000013,103.83815999999997',
          '1.3005200000000012,103.83801999999997',
          '1.3005700000000013,103.83779999999997',
          '1.3005900000000012,103.83770999999997',
          '1.3006100000000012,103.83761999999997',
          '1.3006600000000013,103.83738999999997',
          '1.3006900000000012,103.83724999999997',
          '1.3007700000000013,103.83688999999997',
          '1.3008100000000014,103.83669999999996',
          '1.3008400000000013,103.83657999999997',
          '1.3008700000000013,103.83646999999996',
          '1.3007700000000013,103.83636999999996',
          '1.3006900000000012,103.83628999999996',
          '1.3004100000000012,103.83600999999996',
          '1.3003100000000012,103.83589999999995',
          '1.3002700000000011,103.83584999999995',
          '1.300230000000001,103.83578999999995',
          '1.300180000000001,103.83569999999995',
          '1.300120000000001,103.83557999999995',
          '1.300150000000001,103.83547999999995',
          '1.300220000000001,103.83526999999995',
          '1.3003000000000011,103.83508999999995',
          '1.3003700000000011,103.83494999999995',
          '1.300430000000001,103.83483999999994',
          '1.300500000000001,103.83471999999995',
          '1.3005800000000012,103.83458999999995',
          '1.3006600000000013,103.83446999999995',
          '1.3007700000000013,103.83433999999995',
          '1.3009400000000013,103.83415999999995',
          '1.3011200000000014,103.83397999999995',
          '1.3018800000000015,103.83326999999996',
          '1.3022200000000015,103.83293999999995',
          '1.3022800000000014,103.83287999999995',
          '1.3028700000000015,103.83222999999995',
          '1.3029700000000015,103.83211999999995',
          '1.3030500000000016,103.83203199999994',
          '1.30305,103.832032',
          '1.30307,103.83201',
          '1.30317,103.83189',
          '1.30331,103.83174',
          '1.30344,103.83158',
          '1.30361,103.83136',
          '1.30368,103.83123',
          '1.30378,103.831',
          '1.30386,103.83081',
          '1.30392,103.83069',
          '1.30402,103.83076',
          '1.30409,103.83081',
          '1.30418,103.83088',
          '1.30432,103.83099999999999',
          '1.30447,103.83111999999998',
          '1.30463,103.83124999999998',
          '1.3049199999999999,103.83148999999999',
          '1.3051199999999998,103.83169',
          '1.3052499999999998,103.83183',
          '1.3053699999999997,103.83194999999999',
          '1.3054399999999997,103.83201',
          '1.3054799999999998,103.83203999999999',
          '1.3055599999999998,103.83209',
          '1.30564,103.83214',
          '1.30571,103.83218',
          '1.3057699999999999,103.83220999999999',
          '1.3058699999999999,103.83225999999999',
          '1.3062299999999998,103.83241999999998',
          '1.3063499999999997,103.83246999999999',
          '1.3065499999999997,103.83255999999999',
          '1.3067399999999996,103.83264999999999',
          '1.3068799999999996,103.83271999999998',
          '1.3070099999999996,103.83277999999999',
          '1.3071899999999996,103.83285999999998',
          '1.3073699999999997,103.83293999999998',
          '1.3075099999999997,103.83299999999998',
          '1.3076599999999998,103.83305999999999',
          '1.3077699999999999,103.83309999999999',
          '1.3078599999999998,103.83312999999998',
          '1.3079599999999998,103.83315999999998',
          '1.3080299999999998,103.83317999999998',
          '1.3081899999999997,103.83321999999998',
          '1.3082699999999998,103.83323999999999',
          '1.3083399999999998,103.83326',
          '1.3084099999999999,103.83328',
          '1.3084799999999999,103.83327',
          '1.3085699999999998,103.83326',
          '1.3086099999999998,103.83326',
          '1.3086499999999999,103.83326',
          '1.30869,103.83326',
          '1.30877,103.83326',
          '1.3088899999999999,103.83326',
          '1.30894,103.83326',
          '1.30899,103.83326',
          '1.3090300000000001,103.83326',
          '1.3090800000000002,103.83326',
          '1.3092400000000002,103.83326',
          '1.3094200000000003,103.83324999999999',
          '1.3097900000000002,103.83322',
          '1.3105600000000002,103.83315999999999',
          '1.3109300000000002,103.83313',
          '1.31116,103.83312',
          '1.3114000000000001,103.83309999999999',
          '1.3115,103.83308999999998',
          '1.3116100000000002,103.83306999999998',
          '1.3116500000000002,103.83305999999997',
          '1.3116800000000002,103.83304999999997',
          '1.3117100000000002,103.83303999999997',
          '1.3117400000000001,103.83302999999997',
          '1.3118100000000001,103.83299999999997',
          '1.3118600000000002,103.83296999999997',
          '1.3119200000000002,103.83292999999998',
          '1.3119800000000001,103.83287999999997',
          '1.31204,103.83281999999997',
          '1.3121,103.83274999999998',
          '1.31213,103.83270999999998',
          '1.3121800000000001,103.83262999999998',
          '1.31221,103.83255999999999',
          '1.3122500000000001,103.83244999999998',
          '1.3122800000000001,103.83234999999998',
          '1.3123200000000002,103.83218999999998',
          '1.31238,103.83193999999999',
          '1.3124500000000001,103.83165999999999',
          '1.3125600000000002,103.83122999999999',
          '1.3126200000000001,103.83098',
          '1.3126900000000001,103.83068999999999',
          '1.31275,103.83045999999999',
          '1.31282,103.83018999999999',
          '1.3128700000000002,103.82998999999998',
          '1.3129200000000003,103.82978999999997',
          '1.3130100000000002,103.82942999999997',
          '1.3131300000000001,103.82896999999997',
          '1.3131800000000002,103.82875999999997',
          '1.3132400000000002,103.82850999999998',
          '1.3132800000000002,103.82834999999999',
          '1.3133300000000003,103.82815999999998',
          '1.3133600000000003,103.82805999999998',
          '1.3133900000000003,103.82796999999998',
          '1.3134200000000003,103.82788999999998',
          '1.3134600000000003,103.82778999999998',
          '1.3135300000000003,103.82765999999998',
          '1.3136100000000004,103.82752999999998',
          '1.3136500000000004,103.82746999999998',
          '1.3137100000000004,103.82737999999998',
          '1.3137800000000004,103.82728999999998',
          '1.3139000000000003,103.82712999999998',
          '1.3140300000000003,103.82695999999999',
          '1.3141500000000002,103.82680999999998',
          '1.31424,103.82670999999998',
          '1.31434,103.82659999999997',
          '1.31446,103.82648999999996',
          '1.31452,103.82643999999996',
          '1.31457,103.82639999999996',
          '1.31466,103.82633999999996',
          '1.31473,103.82629999999996',
          '1.31481,103.82625999999996',
          '1.31494,103.82620999999996',
          '1.3151,103.82615999999996',
          '1.31523,103.82612999999996',
          '1.3153499999999998,103.82610999999996',
          '1.3155599999999998,103.82607999999996',
          '1.3160899999999998,103.82599999999996',
          '1.3161699999999998,103.82598999999996',
          '1.3166299999999997,103.82592999999996',
          '1.3169099999999998,103.82588999999996',
          '1.3172099999999998,103.82583999999996',
          '1.3173299999999997,103.82581999999995',
          '1.3174899999999996,103.82578999999996',
          '1.3175999999999997,103.82576999999995',
          '1.3177099999999997,103.82574999999994',
          '1.3179899999999998,103.82569999999994',
          '1.3186399999999998,103.82559999999994',
          '1.3189799999999998,103.82554999999994',
          '1.3193899999999998,103.82548999999993',
          '1.3195199999999998,103.82546999999992',
          '1.3198499999999997,103.82541999999992',
          '1.3202199999999997,103.82535999999992',
          '1.3202799999999997,103.82530999999992',
          '1.3203399999999996,103.82525999999991',
          '1.3203999999999996,103.82520999999991',
          '1.3204699999999996,103.82513999999992',
          '1.3205199999999997,103.82508999999992',
          '1.3206999999999998,103.82454999999992',
          '1.3207699999999998,103.82431999999991',
          '1.3208499999999999,103.82407999999991',
          '1.3209399999999998,103.82381999999991',
          '1.3210699999999997,103.82342999999992',
          '1.3211999999999997,103.82307999999992',
          '1.3212799999999998,103.82289999999992',
          '1.3213899999999998,103.82272999999992',
          '1.32147,103.82259999999992',
          '1.32161,103.82209999999992',
          '1.32176,103.82153999999993',
          '1.3218,103.82135999999993',
          '1.3218400000000001,103.82121999999993',
          '1.3219900000000002,103.82062999999992',
          '1.3220800000000001,103.82025999999992',
          '1.3222,103.81981999999992',
          '1.32222,103.81972999999992',
          '1.32233,103.81931999999992',
          '1.32245,103.81882999999992',
          '1.32253,103.81836999999992',
          '1.32264,103.81742999999992',
          '1.32266,103.81717999999992',
          '1.32269,103.81679999999992',
          '1.3227099999999998,103.81648999999992',
          '1.3227099999999998,103.81610999999991',
          '1.32272,103.8157399999999',
          '1.3227499999999999,103.8153799999999',
          '1.3227699999999998,103.8151799999999',
          '1.3227899999999997,103.8150199999999',
          '1.32279,103.81502',
          '1.32279,103.81502',
          '1.3228099999999998,103.81488',
          '1.3228499999999999,103.81456',
          '1.3228799999999998,103.81434',
          '1.32296,103.8137',
          '1.3229799999999998,103.81353',
          '1.3229899999999999,103.81344',
          '1.323,103.81335',
          '1.32301,103.81324',
          '1.32301,103.81321999999999',
          '1.32302,103.81315',
          '1.3230600000000001,103.81277999999999',
          '1.3231100000000002,103.81235',
          '1.3231300000000001,103.81214',
          '1.32315,103.812',
          '1.32318,103.81183',
          '1.32321,103.81167',
          '1.32326,103.81151000000001',
          '1.3233000000000001,103.81139000000002',
          '1.3233500000000002,103.81127000000002',
          '1.3233900000000003,103.81119000000002',
          '1.3234200000000003,103.81114000000002',
          '1.3234800000000002,103.81105000000002',
          '1.3235400000000002,103.81096000000002',
          '1.3236,103.81087000000002',
          '1.3237800000000002,103.81062000000003',
          '1.3239100000000001,103.81043000000003',
          '1.3241500000000002,103.81009000000003',
          '1.32427,103.80992000000003',
          '1.3243500000000001,103.80982000000003',
          '1.3243800000000001,103.80978000000003',
          '1.3244200000000002,103.80974000000003',
          '1.3244700000000003,103.80971000000004',
          '1.3245200000000004,103.80969000000003',
          '1.3246000000000004,103.80957000000004',
          '1.3248700000000004,103.80917000000004',
          '1.3249900000000003,103.80899000000004',
          '1.3251500000000003,103.80875000000003',
          '1.3252900000000003,103.80855000000003',
          '1.3254000000000004,103.80839000000003',
          '1.3256500000000004,103.80802000000003',
          '1.3257500000000004,103.80787000000002',
          '1.3258400000000004,103.80773000000002',
          '1.3262600000000004,103.80710000000002',
          '1.3267500000000005,103.80638000000002',
          '1.3270800000000005,103.80590000000002',
          '1.3273100000000004,103.80556000000003',
          '1.3276800000000004,103.80501000000002',
          '1.3283200000000004,103.80407000000002',
          '1.3284300000000004,103.80390000000003',
          '1.3285300000000004,103.80374000000003',
          '1.3286300000000004,103.80356000000003',
          '1.3287500000000003,103.80334000000003',
          '1.3288200000000003,103.80320000000003',
          '1.3289300000000004,103.80297000000003',
          '1.3289900000000003,103.80281000000004',
          '1.3290500000000003,103.80265000000004',
          '1.3291100000000002,103.80249000000005',
          '1.3291800000000003,103.80230000000005',
          '1.3292500000000003,103.80210000000004',
          '1.3293600000000003,103.80178000000004',
          '1.3294500000000002,103.80153000000004',
          '1.3296500000000002,103.80099000000004',
          '1.3300300000000003,103.79990000000004',
          '1.3302200000000002,103.79938000000004',
          '1.3303800000000001,103.79892000000004',
          '1.33041,103.79883000000004',
          '1.33064,103.79815000000004',
          '1.33073,103.79788000000003',
          '1.33077,103.79776000000004',
          '1.33081,103.79765000000003',
          '1.3308600000000002,103.79751000000003',
          '1.3310000000000002,103.79713000000002',
          '1.3311500000000003,103.79670000000003',
          '1.3312100000000002,103.79654000000004',
          '1.3314300000000003,103.79598000000004',
          '1.3314900000000003,103.79581000000005',
          '1.3315400000000004,103.79565000000005',
          '1.3315800000000004,103.79554000000005',
          '1.3316800000000004,103.79525000000004',
          '1.3318700000000003,103.79466000000004',
          '1.3319400000000003,103.79445000000004',
          '1.3319600000000003,103.79439000000004',
          '1.3320500000000002,103.79414000000004',
          '1.3321800000000001,103.79377000000004',
          '1.3325000000000002,103.79287000000004',
          '1.3327800000000003,103.79210000000003',
          '1.3330400000000002,103.79140000000004',
          '1.3331100000000002,103.79119000000004',
          '1.3331800000000003,103.79098000000005',
          '1.3335700000000001,103.78987000000005',
          '1.3337500000000002,103.78936000000004',
          '1.3339800000000002,103.78871000000005',
          '1.3343800000000001,103.78761000000006',
          '1.3344500000000001,103.78740000000006',
          '1.3345000000000002,103.78726000000006',
          '1.3347800000000003,103.78646000000006',
          '1.3349500000000003,103.78597000000006',
          '1.3350500000000003,103.78569000000006',
          '1.3351500000000003,103.78541000000006',
          '1.3353000000000004,103.78499000000005',
          '1.3355300000000003,103.78434000000006',
          '1.3357800000000004,103.78369000000006',
          '1.3358400000000004,103.78352000000007',
          '1.3359100000000004,103.78332000000006',
          '1.3361500000000004,103.78263000000005',
          '1.3362700000000003,103.78228000000006',
          '1.3363700000000003,103.78200000000005',
          '1.3365200000000004,103.78159000000005',
          '1.3366600000000004,103.78121000000004',
          '1.3366800000000003,103.78116000000004',
          '1.3367100000000003,103.78109000000005',
          '1.3367500000000003,103.78097000000005',
          '1.3368500000000003,103.78069000000005',
          '1.3370100000000003,103.78022000000004',
          '1.3371300000000002,103.77989000000004',
          '1.3372800000000002,103.77944000000004',
          '1.3373700000000002,103.77914000000004',
          '1.3374200000000003,103.77898000000005',
          '1.3374500000000002,103.77889000000005',
          '1.3374800000000002,103.77881000000005',
          '1.3375200000000003,103.77870000000004',
          '1.3376000000000003,103.77849000000005',
          '1.3376500000000004,103.77835000000005',
          '1.3376900000000005,103.77825000000004',
          '1.3377600000000005,103.77810000000004',
          '1.3378000000000005,103.77802000000004',
          '1.3378400000000006,103.77795000000005',
          '1.3378800000000006,103.77788000000005',
          '1.3379300000000007,103.77780000000006',
          '1.3380300000000007,103.77767000000006',
          '1.3380800000000008,103.77761000000005',
          '1.3381400000000008,103.77755000000005',
          '1.3382200000000009,103.77748000000005',
          '1.3382800000000008,103.77743000000005',
          '1.338330000000001,103.77739000000005',
          '1.338370000000001,103.77736000000006',
          '1.338420000000001,103.77732000000006',
          '1.3385000000000011,103.77726000000006',
          '1.3385700000000011,103.77721000000005',
          '1.3387500000000012,103.77709000000006',
          '1.3388900000000012,103.77699000000005',
          '1.3390300000000013,103.77689000000005',
          '1.3393300000000012,103.77667000000005',
          '1.3395100000000013,103.77653000000005',
          '1.3395900000000014,103.77647000000005',
          '1.3397300000000014,103.77636000000004',
          '1.3398800000000015,103.77625000000003',
          '1.3399500000000015,103.77620000000003',
          '1.3400200000000015,103.77615000000003',
          '1.3401000000000016,103.77610000000003',
          '1.3401700000000016,103.77606000000003',
          '1.3402900000000015,103.77600000000002',
          '1.3403700000000016,103.77597000000003',
          '1.3404600000000015,103.77594000000003',
          '1.3405900000000015,103.77590000000004',
          '1.3407200000000015,103.77587000000004',
          '1.3408990000000014,103.77583200000004',
          '1.340899,103.775832',
          '1.341,103.77580999999999',
          '1.3412,103.77577',
          '1.3414,103.77573',
          '1.34165,103.77568',
          '1.34191,103.77562999999999',
          '1.34231,103.77556',
          '1.34272,103.77548',
          '1.343,103.77543',
          '1.34322,103.77539',
          '1.34349,103.77534',
          '1.34396,103.77525',
          '1.3441100000000001,103.77522',
          '1.34417,103.7752',
          '1.34423,103.77517999999999',
          '1.3442800000000001,103.77515999999999',
          '1.3443300000000002,103.77513999999998',
          '1.3443700000000003,103.77511999999997',
          '1.3444100000000003,103.77509999999997',
          '1.3444600000000004,103.77506999999997',
          '1.3444900000000004,103.77504999999996',
          '1.3445600000000004,103.77498999999996',
          '1.3446300000000004,103.77486999999996',
          '1.3447300000000004,103.77469999999997',
          '1.3448500000000003,103.77449999999996',
          '1.3452600000000003,103.77383999999996',
          '1.3453000000000004,103.77375999999997',
          '1.3455000000000004,103.77340999999997',
          '1.3455900000000003,103.77324999999998',
          '1.3456700000000004,103.77310999999997',
          '1.3457400000000004,103.77298999999998',
          '1.3458200000000005,103.77284999999998',
          '1.3459700000000006,103.77257999999998',
          '1.3460500000000006,103.77243999999997',
          '1.3461300000000007,103.77230999999998',
          '1.3462000000000007,103.77220999999997',
          '1.3463000000000007,103.77207999999997',
          '1.3463800000000008,103.77199999999998',
          '1.3464500000000008,103.77192999999998',
          '1.3465500000000008,103.77184999999999',
          '1.3466400000000007,103.77177999999999',
          '1.3467800000000008,103.77168999999999',
          '1.3469200000000008,103.77161',
          '1.3471200000000008,103.77149',
          '1.3477900000000007,103.77104',
          '1.3481800000000006,103.77074999999999',
          '1.3485000000000007,103.7705',
          '1.3485500000000008,103.77046',
          '1.3486900000000008,103.77035',
          '1.348770000000001,103.77029999999999',
          '1.3488600000000008,103.77024999999999',
          '1.3489600000000008,103.77019999999999',
          '1.3490300000000008,103.77017',
          '1.3491200000000008,103.77014',
          '1.3492000000000008,103.77011999999999',
          '1.349250000000001,103.77010999999999',
          '1.349330000000001,103.77009999999999',
          '1.349450000000001,103.77008999999998',
          '1.3497100000000009,103.77006999999998',
          '1.3499400000000008,103.77004999999997',
          '1.3501500000000008,103.77002999999996',
          '1.3504100000000008,103.76999999999997',
          '1.3506500000000008,103.76996999999997',
          '1.3508400000000007,103.76994999999997',
          '1.3517000000000008,103.76983999999996',
          '1.3518000000000008,103.76982999999996',
          '1.3520500000000009,103.76978999999996',
          '1.352260000000001,103.76974999999996',
          '1.3523800000000008,103.76972999999995',
          '1.3527900000000008,103.76963999999995',
          '1.3533400000000009,103.76948999999995',
          '1.3537400000000008,103.76935999999995',
          '1.353920000000001,103.76929999999994',
          '1.354140000000001,103.76921999999995',
          '1.354510000000001,103.76907999999995',
          '1.354610000000001,103.76903999999995',
          '1.3547300000000009,103.76899999999995',
          '1.355010000000001,103.76888999999994',
          '1.3553000000000008,103.76879999999994',
          '1.355480000000001,103.76872999999995',
          '1.355650000000001,103.76865999999995',
          '1.355800000000001,103.76858999999996',
          '1.355930000000001,103.76851999999997',
          '1.356020000000001,103.76846999999997',
          '1.3561200000000009,103.76840999999996',
          '1.356230000000001,103.76833999999997',
          '1.356370000000001,103.76823999999996',
          '1.356530000000001,103.76811999999997',
          '1.3567200000000008,103.76797999999997',
          '1.3569000000000009,103.76783999999996',
          '1.3570700000000009,103.76771999999997',
          '1.357150000000001,103.76766999999997',
          '1.357230000000001,103.76762999999997',
          '1.357300000000001,103.76759999999997',
          '1.357390000000001,103.76756999999998',
          '1.357490000000001,103.76753999999998',
          '1.357620000000001,103.76750999999999',
          '1.3577400000000008,103.76748999999998',
          '1.3579500000000009,103.76745999999999',
          '1.358190000000001,103.76741999999999',
          '1.3584800000000008,103.76735999999998',
          '1.3587700000000007,103.76728999999999',
          '1.3589300000000006,103.76722',
          '1.3590000000000007,103.76719',
          '1.3590800000000007,103.76716',
          '1.3591500000000007,103.76714',
          '1.3592300000000008,103.76711999999999',
          '1.359310000000001,103.76709999999999',
          '1.3596000000000008,103.76703999999998',
          '1.3598800000000009,103.76698999999998',
          '1.360130000000001,103.76694999999998',
          '1.360450000000001,103.76690999999998',
          '1.360550000000001,103.76689999999998',
          '1.360720000000001,103.76687999999997',
          '1.3608700000000011,103.76686999999997',
          '1.360990000000001,103.76686999999997',
          '1.361130000000001,103.76687999999997',
          '1.361260000000001,103.76689999999998',
          '1.3614100000000011,103.76692999999997',
          '1.3615200000000012,103.76695999999997',
          '1.3616600000000012,103.76700999999997',
          '1.3618200000000011,103.76705999999997',
          '1.361910000000001,103.76708999999997',
          '1.362180000000001,103.76717999999997',
          '1.362420000000001,103.76725999999996',
          '1.362620000000001,103.76731999999997',
          '1.362750000000001,103.76735999999997',
          '1.362980000000001,103.76741999999997',
          '1.363010000000001,103.76742699999997',
          '1.36301,103.767427',
          '1.3631600000000001,103.76746',
          '1.3634000000000002,103.76748',
          '1.3636800000000002,103.76749000000001',
          '1.3638200000000003,103.76749000000001',
          '1.3639700000000003,103.76748',
          '1.3641300000000003,103.76746',
          '1.3642600000000003,103.76744',
          '1.3643900000000002,103.76741',
          '1.3645300000000002,103.76737',
          '1.3646700000000003,103.76732',
          '1.3648700000000002,103.76723',
          '1.3650000000000002,103.76716',
          '1.3651900000000001,103.76705',
          '1.3654300000000001,103.76689999999999',
          '1.36603,103.76652999999999',
          '1.3664100000000001,103.76629999999999',
          '1.36674,103.76610999999998',
          '1.36688,103.76602999999999',
          '1.36708,103.76591999999998',
          '1.36725,103.76582999999998',
          '1.36768,103.76553999999997',
          '1.36795,103.76534999999997',
          '1.36866,103.76484999999997',
          '1.36927,103.76442999999996',
          '1.3694,103.76433999999996',
          '1.36992,103.76397999999996',
          '1.3700700000000001,103.76387999999996',
          '1.37019,103.76380999999996',
          '1.37028,103.76375999999996',
          '1.3703999999999998,103.76368999999997',
          '1.3704999999999998,103.76363999999997',
          '1.3706299999999998,103.76356999999997',
          '1.3707399999999998,103.76351999999997',
          '1.3708399999999998,103.76347999999997',
          '1.37102,103.76340999999998',
          '1.3711499999999999,103.76336999999998',
          '1.3712799999999998,103.76332999999998',
          '1.3713899999999999,103.76329999999999',
          '1.3715,103.76326999999999',
          '1.37163,103.76323',
          '1.37173,103.7632',
          '1.37181,103.76317999999999',
          '1.37208,103.76311999999999',
          '1.37242,103.76304999999999',
          '1.37277,103.76298',
          '1.37307,103.76293',
          '1.37361,103.76281999999999',
          '1.37442,103.76266999999999',
          '1.375,103.76256999999998',
          '1.37516,103.76253999999999',
          '1.37544,103.76248999999999',
          '1.37576,103.76242999999998',
          '1.3764200000000002,103.76230999999999',
          '1.3765700000000003,103.76228999999998',
          '1.3767500000000004,103.76225999999998',
          '1.3769700000000005,103.76221999999999',
          '1.3772300000000004,103.76216999999998',
          '1.3775200000000003,103.76211999999998',
          '1.3776800000000002,103.76208999999999',
          '1.3778800000000002,103.76203999999998',
          '1.3781500000000002,103.76194999999998',
          '1.3784000000000003,103.76185999999998',
          '1.3786200000000004,103.76175999999998',
          '1.3788100000000003,103.76166999999998',
          '1.3789700000000003,103.76158999999998',
          '1.3792700000000002,103.76142999999999',
          '1.3796100000000002,103.76126',
          '1.3797800000000002,103.76118',
          '1.3801300000000003,103.76097999999999',
          '1.3806400000000003,103.76070999999999',
          '1.3809900000000004,103.76051999999999',
          '1.3813100000000005,103.76033999999999',
          '1.3817900000000005,103.76007999999999',
          '1.3826600000000004,103.75959999999999',
          '1.3828400000000005,103.75949999999999',
          '1.3829800000000005,103.75941999999999',
          '1.3830900000000006,103.75935',
          '1.3831800000000005,103.75929',
          '1.3832700000000004,103.75922999999999',
          '1.3834000000000004,103.75913999999999',
          '1.3834700000000004,103.75908999999999',
          '1.3835100000000005,103.75905999999999',
          '1.3835600000000006,103.75901999999999',
          '1.3835900000000005,103.75899',
          '1.3836200000000005,103.75895',
          '1.3836400000000004,103.75892',
          '1.3836600000000003,103.75886',
          '1.3837400000000004,103.75879',
          '1.3838800000000004,103.75866',
          '1.3842500000000004,103.7583',
          '1.3845200000000004,103.75803',
          '1.3848100000000003,103.75774',
          '1.3850600000000004,103.75751',
          '1.3852100000000005,103.75738',
          '1.3854000000000004,103.75722',
          '1.3855500000000005,103.75710000000001',
          '1.3856800000000005,103.757',
          '1.3858800000000004,103.75685',
          '1.3860400000000004,103.75673',
          '1.3862600000000005,103.75658',
          '1.3865300000000005,103.75639',
          '1.3866800000000006,103.75627999999999',
          '1.3868100000000005,103.75618999999999',
          '1.3872900000000006,103.75587999999999',
          '1.3875500000000005,103.75572',
          '1.3877300000000006,103.75562',
          '1.3879300000000006,103.75550999999999',
          '1.3881100000000006,103.75540999999998',
          '1.3883600000000007,103.75528999999999',
          '1.3885400000000008,103.75519999999999',
          '1.3886600000000007,103.75514999999999',
          '1.3888100000000008,103.75507999999999',
          '1.3891000000000007,103.75496',
          '1.3892000000000007,103.75492',
          '1.3893600000000006,103.75486',
          '1.3895600000000006,103.75479',
          '1.3897800000000007,103.75472',
          '1.3900400000000006,103.75464000000001',
          '1.3903400000000006,103.75455000000001',
          '1.3906500000000006,103.75446000000001',
          '1.3907800000000006,103.75442000000001',
          '1.3908900000000006,103.75439000000001',
          '1.3910900000000006,103.75434000000001',
          '1.3913100000000007,103.75429000000001',
          '1.3918300000000008,103.75418',
          '1.3921500000000009,103.75412',
          '1.3923400000000008,103.75409',
          '1.3925400000000008,103.75406000000001',
          '1.3937100000000007,103.75391',
          '1.3939200000000007,103.75388000000001',
          '1.3940500000000007,103.75386',
          '1.3942600000000007,103.75384',
          '1.3943800000000006,103.75383',
          '1.3945800000000006,103.75383',
          '1.3946400000000005,103.75383',
          '1.3947500000000006,103.75384',
          '1.3948700000000005,103.75386',
          '1.3949700000000005,103.75388000000001',
          '1.3950500000000006,103.75390000000002',
          '1.3951900000000006,103.75394000000001',
          '1.3953300000000006,103.75398000000001',
          '1.3957600000000006,103.75412000000001',
          '1.3965400000000006,103.75439000000001',
          '1.3967500000000006,103.75446000000001',
          '1.3969100000000005,103.75451000000001',
          '1.3972100000000005,103.75461000000001',
          '1.3974200000000006,103.75468000000001',
          '1.3976600000000006,103.75476',
          '1.3978100000000007,103.75481',
          '1.3981400000000006,103.75491000000001',
          '1.3982600000000005,103.75495000000001',
          '1.3984300000000005,103.75501000000001',
          '1.3985600000000005,103.75506000000001',
          '1.3987100000000006,103.75512000000002',
          '1.3989400000000005,103.75521000000002',
          '1.3990900000000006,103.75526000000002',
          '1.3992500000000005,103.75531000000002',
          '1.3994900000000006,103.75538000000002',
          '1.3996700000000006,103.75543000000002',
          '1.3998200000000007,103.75547000000002',
          '1.3999400000000006,103.75550000000001',
          '1.4000700000000006,103.75553000000001',
          '1.4002200000000007,103.75556',
          '1.4007000000000007,103.75565',
          '1.4015200000000008,103.75580000000001',
          '1.4022700000000008,103.75594000000001',
          '1.402590000000001,103.75600000000001',
          '1.4027200000000009,103.75602000000002',
          '1.4028600000000009,103.75604000000003',
          '1.402940000000001,103.75605000000003',
          '1.403120000000001,103.75607000000004',
          '1.403300000000001,103.75609000000004',
          '1.4035400000000011,103.75611000000005',
          '1.4037800000000011,103.75612000000005',
          '1.403940000000001,103.75612000000005',
          '1.404280000000001,103.75612000000005',
          '1.404610000000001,103.75611000000005',
          '1.405010000000001,103.75610000000005',
          '1.405100000000001,103.75610000000005',
          '1.4052200000000008,103.75610000000005',
          '1.4057800000000007,103.75610000000005',
          '1.4066900000000007,103.75610000000005',
          '1.4070000000000007,103.75610000000005',
          '1.4072400000000007,103.75609000000004',
          '1.4073500000000008,103.75608000000004',
          '1.4074500000000008,103.75607000000004',
          '1.4075300000000008,103.75606000000003',
          '1.4076600000000008,103.75604000000003',
          '1.4078400000000009,103.75601000000003',
          '1.4079600000000008,103.75599000000003',
          '1.4081400000000008,103.75596000000003',
          '1.4084500000000009,103.75591000000003',
          '1.4088800000000008,103.75584000000003',
          '1.4091600000000009,103.75579000000003',
          '1.4093500000000008,103.75576000000004',
          '1.4094900000000008,103.75574000000003',
          '1.4096600000000008,103.75572000000003',
          '1.4097900000000008,103.75571000000002',
          '1.4099500000000007,103.75570000000002',
          '1.4101200000000007,103.75569000000002',
          '1.4103700000000008,103.75568000000001',
          '1.4105200000000009,103.75568000000001',
          '1.410830000000001,103.75568000000001',
          '1.410970000000001,103.75568000000001',
          '1.4112600000000008,103.75569000000002',
          '1.411510000000001,103.75570000000002',
          '1.411810000000001,103.75572000000003',
          '1.4119700000000008,103.75573000000003',
          '1.4121600000000007,103.75574000000003',
          '1.4122900000000007,103.75575000000003',
          '1.4124000000000008,103.75576000000004',
          '1.4125500000000009,103.75577000000004',
          '1.4127500000000008,103.75578000000004',
          '1.4128400000000008,103.75578000000004',
          '1.4129400000000008,103.75578000000004',
          '1.4130400000000007,103.75578000000004',
          '1.4131500000000008,103.75577000000004',
          '1.4132100000000007,103.75576000000004',
          '1.4132600000000008,103.75575000000003',
          '1.4133000000000009,103.75574000000003',
          '1.413370000000001,103.75572000000003',
          '1.413440000000001,103.75570000000002',
          '1.413550000000001,103.75566000000002',
          '1.413700000000001,103.75560000000002',
          '1.4138500000000012,103.75553000000002',
          '1.4142000000000012,103.75534000000002',
          '1.4144300000000012,103.75521000000002',
          '1.414620000000001,103.75511000000002',
          '1.4147700000000012,103.75504000000002',
          '1.4149200000000013,103.75498000000002',
          '1.4150700000000014,103.75493000000002',
          '1.4153500000000014,103.75484000000002',
          '1.4155700000000015,103.75478000000001',
          '1.4157800000000016,103.75474000000001',
          '1.4160200000000016,103.75470000000001',
          '1.4163100000000015,103.75467000000002',
          '1.4165300000000016,103.75466000000002',
          '1.4168100000000017,103.75465000000001',
          '1.4180000000000017,103.75465000000001',
          '1.4184500000000018,103.75465000000001',
          '1.4188200000000017,103.75465000000001',
          '1.4190000000000018,103.75465000000001',
          '1.4191400000000018,103.75465000000001',
          '1.4193400000000018,103.75467000000002',
          '1.4195300000000017,103.75470000000001',
          '1.4196800000000018,103.75473000000001',
          '1.419860000000002,103.75478000000001',
          '1.420000000000002,103.75483000000001',
          '1.420130000000002,103.75489000000002',
          '1.420340000000002,103.75500000000002',
          '1.420740000000002,103.75522000000002',
          '1.421150000000002,103.75545000000002',
          '1.4222300000000019,103.75605000000003',
          '1.4227300000000018,103.75632000000003',
          '1.422910000000002,103.75643000000004',
          '1.423020000000002,103.75651000000003',
          '1.423100000000002,103.75658000000003',
          '1.4231500000000021,103.75663000000003',
          '1.4232500000000021,103.75674000000004',
          '1.423340000000002,103.75685000000004',
          '1.4234200000000021,103.75695000000005',
          '1.4235000000000022,103.75706000000005',
          '1.4235700000000022,103.75718000000005',
          '1.4236200000000023,103.75728000000005',
          '1.4236500000000023,103.75735000000005',
          '1.4236800000000023,103.75744000000005',
          '1.4237700000000022,103.75776000000005',
          '1.4238200000000023,103.75795000000005',
          '1.4238400000000022,103.75804000000005',
          '1.4238900000000023,103.75827000000005',
          '1.4239500000000023,103.75855000000006',
          '1.4240100000000022,103.75884000000006',
          '1.4240700000000022,103.75910000000006',
          '1.4241000000000021,103.75923000000006',
          '1.4241400000000022,103.75939000000005',
          '1.4241700000000022,103.75950000000006',
          '1.4242100000000022,103.75963000000006',
          '1.4242400000000022,103.75972000000006',
          '1.4242700000000021,103.75980000000006',
          '1.424290000000002,103.75985000000006',
          '1.424330000000002,103.75994000000006',
          '1.4243700000000021,103.76003000000006',
          '1.4244400000000021,103.76017000000006',
          '1.424500000000002,103.76028000000007',
          '1.4245700000000021,103.76040000000006',
          '1.4246400000000021,103.76051000000007',
          '1.4247800000000022,103.76072000000006',
          '1.4248900000000022,103.76088000000006',
          '1.425010000000002,103.76105000000005',
          '1.425065000000002,103.76112100000006',
        ],
      },
    ],
  },
  stops: [
    {
      direction: 1,
      stop: [
        {
          stopSequence: '1',
          stopCode: 'RS1',
          index: 0,
        },
        {
          stopSequence: '2',
          stopCode: 'RS2',
          index: 125,
        },
        {
          stopSequence: '3',
          stopCode: 'RS3',
          index: 250,
        },
        {
          stopSequence: '4',
          stopCode: 'RS4',
          index: 434,
        },
        {
          stopSequence: '5',
          stopCode: 'RS5',
          index: 609,
        },
        {
          stopSequence: '6',
          stopCode: 'RS6',
          index: 742,
        },
        {
          stopSequence: '7',
          stopCode: 'RS7',
          index: 1098,
        },
      ],
    },
    {
      direction: 2,
      stop: [
        {
          stopSequence: '1',
          stopCode: 'RS7',
          index: 0,
        },
        {
          stopSequence: '2',
          stopCode: 'RS6',
          index: 356,
        },
        {
          stopSequence: '3',
          stopCode: 'RS5',
          index: 490,
        },
        {
          stopSequence: '4',
          stopCode: 'RS4',
          index: 665,
        },
        {
          stopSequence: '5',
          stopCode: 'RS3',
          index: 848,
        },
        {
          stopSequence: '6',
          stopCode: 'RS2',
          index: 973,
        },
        {
          stopSequence: '7',
          stopCode: 'RS1',
          index: 1098,
        },
      ],
    },
  ],
  color: '#f03030',
  colorAlt: '#991010',
  colorAlt2: '#600808',
  busCode: 'R',
  busCount: 3,
  busId: 7001,
  busIdStr: '7001',
  timeStop: 5,
  busArr: ['SG1791G', 'SG6186X', 'SG5143B'],
};

const orangeRoute = {
  ladder: {
    isVisible: 'true',
    routeId: ['7003'],
    routeName: ['7003'],
    direction: [
      {
        value: '2',
        stops: [
          {
            streetName: 'Orange Route',
            stopCode: 'OS5',
            stopName: 'Manchester Meadows',
            latitude: 1.4048144,
            longitude: 103.902759321,
            stopSequence: '1',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Orange Route',
            stopCode: 'OS4',
            stopName: 'Docklands Pier',
            latitude: 1.3711822747611109,
            longitude: 103.89246560907316,
            stopSequence: '2',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Orange Route',
            stopCode: 'OS3',
            stopName: 'Stadium',
            latitude: 1.349162,
            longitude: 103.87301,
            stopSequence: '3',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Orange Route',
            stopCode: 'OS2',
            stopName: 'Library Lane',
            latitude: 1.284154,
            longitude: 103.843081,
            stopSequence: '4',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Orange Route',
            stopCode: 'OS1',
            stopName: 'Town Hall',
            latitude: 1.2653656,
            longitude: 103.8216731,
            stopSequence: '5',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
        ],
      },
      {
        value: '1',
        stops: [
          {
            streetName: 'Orange Route',
            stopCode: 'OS1',
            stopName: 'Town Hall',
            latitude: 1.2653656,
            longitude: 103.8216731,
            stopSequence: '1',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Orange Route',
            stopCode: 'OS2',
            stopName: 'Library Lane',
            latitude: 1.284154,
            longitude: 103.843081,
            stopSequence: '2',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Orange Route',
            stopCode: 'OS3',
            stopName: 'Stadium',
            latitude: 1.349162,
            longitude: 103.87301,
            stopSequence: '3',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Orange Route',
            stopCode: 'OS4',
            stopName: 'Docklands Pier',
            latitude: 1.3711822747611109,
            longitude: 103.89246560907316,
            stopSequence: '4',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
          {
            streetName: 'Orange Route',
            stopCode: 'OS5',
            stopName: 'Manchester Meadows',
            latitude: 1.4048144,
            longitude: 103.902759321,
            stopSequence: '5',
            mrtStationCode: '',
            mrtStationName: '',
            ctrCode: null,
          },
        ],
      },
    ],
  },
  route: {
    route: ['7003'],
    direction: [
      {
        value: 2,
        path: [
          '1.404888,103.902802',
          '1.4049999999999998,103.90261',
          '1.4050299999999998,103.90254999999999',
          '1.4050799999999999,103.90244999999999',
          '1.4051399999999998,103.90233999999998',
          '1.4051799999999999,103.90227999999998',
          '1.4052099999999998,103.90222999999997',
          '1.4052699999999998,103.90212999999997',
          '1.4053899999999997,103.90192999999996',
          '1.4054299999999997,103.90186999999996',
          '1.4057299999999997,103.90137999999996',
          '1.4058399999999998,103.90120999999996',
          '1.4058299999999997,103.90105999999996',
          '1.4058099999999998,103.90094999999995',
          '1.4057599999999997,103.90083999999995',
          '1.4056399999999998,103.90067999999995',
          '1.4051399999999998,103.90048999999995',
          '1.40495,103.90041999999995',
          '1.40478,103.90034999999996',
          '1.40452,103.90023999999995',
          '1.40408,103.90005999999995',
          '1.40391,103.90002999999996',
          '1.40351,103.89983999999995',
          '1.40287,103.89959999999995',
          '1.4027800000000001,103.89961999999996',
          '1.40273,103.89962999999996',
          '1.40267,103.89964999999997',
          '1.4026,103.89968999999996',
          '1.40253,103.89973999999997',
          '1.40248,103.89987999999997',
          '1.40242,103.90003999999996',
          '1.40236,103.90018999999997',
          '1.4023,103.90032999999997',
          '1.4021800000000002,103.90062999999996',
          '1.4021000000000001,103.90081999999997',
          '1.40196,103.90113999999997',
          '1.4018300000000001,103.90144999999997',
          '1.40175,103.90163999999997',
          '1.4016600000000001,103.90182999999998',
          '1.4015900000000001,103.90196999999998',
          '1.40155,103.90204999999997',
          '1.40152,103.90209999999998',
          '1.40141,103.90231999999997',
          '1.40101,103.90311999999997',
          '1.40086,103.90340999999998',
          '1.40057,103.90398999999998',
          '1.40052,103.90409999999999',
          '1.40038,103.90437999999999',
          '1.4002,103.90474999999999',
          '1.4001199999999998,103.9049',
          '1.4000599999999999,103.90501',
          '1.3999799999999998,103.90516000000001',
          '1.3999099999999998,103.90513000000001',
          '1.3997899999999999,103.90507000000001',
          '1.3995399999999998,103.90495000000001',
          '1.3988499999999997,103.90462000000001',
          '1.3985199999999998,103.90446000000001',
          '1.3981499999999998,103.90428000000001',
          '1.3979099999999998,103.90416000000002',
          '1.3977199999999999,103.90406000000002',
          '1.3972399999999998,103.90382000000001',
          '1.3969199999999997,103.90367',
          '1.3967799999999997,103.90360000000001',
          '1.3966999999999996,103.90356000000001',
          '1.3965899999999996,103.90350000000001',
          '1.3964699999999997,103.90343000000001',
          '1.3963499999999998,103.90336000000002',
          '1.3962499999999998,103.90330000000002',
          '1.3960699999999997,103.90319000000001',
          '1.3959699999999997,103.90312000000002',
          '1.3958599999999997,103.90303000000002',
          '1.3957699999999997,103.90295000000002',
          '1.3956599999999997,103.90285000000002',
          '1.3955399999999998,103.90274000000001',
          '1.3953699999999998,103.90258000000001',
          '1.3952399999999998,103.90245000000002',
          '1.3950999999999998,103.90231000000001',
          '1.3949899999999997,103.90220000000001',
          '1.3947799999999997,103.90199000000001',
          '1.3946599999999998,103.90187000000002',
          '1.3945299999999998,103.90174000000002',
          '1.39441,103.90164000000001',
          '1.39431,103.90157000000002',
          '1.39427,103.90154000000003',
          '1.3942199999999998,103.90151000000003',
          '1.3941499999999998,103.90147000000003',
          '1.3941099999999997,103.90145000000003',
          '1.3940199999999998,103.90141000000003',
          '1.3938599999999999,103.90135000000002',
          '1.3937199999999998,103.90131000000002',
          '1.3935899999999999,103.90128000000003',
          '1.3934799999999998,103.90126000000002',
          '1.39336,103.90124000000002',
          '1.3932799999999999,103.90123000000001',
          '1.39316,103.90122000000001',
          '1.39302,103.90122000000001',
          '1.39288,103.90122000000001',
          '1.39276,103.90123000000001',
          '1.39259,103.90125000000002',
          '1.39246,103.90127000000003',
          '1.3923,103.90130000000002',
          '1.3920000000000001,103.90136000000003',
          '1.3918100000000002,103.90140000000002',
          '1.3915600000000001,103.90145000000003',
          '1.3913200000000001,103.90150000000003',
          '1.3911,103.90155000000003',
          '1.39101,103.90157000000004',
          '1.3908500000000001,103.90160000000003',
          '1.3906,103.90165000000003',
          '1.39015,103.90175000000004',
          '1.38996,103.90179000000003',
          '1.3898000000000001,103.90182000000003',
          '1.3896800000000002,103.90184000000004',
          '1.3895000000000002,103.90187000000003',
          '1.3894100000000003,103.90188000000003',
          '1.3893100000000003,103.90189000000004',
          '1.3891700000000002,103.90190000000004',
          '1.3890000000000002,103.90191000000004',
          '1.3889000000000002,103.90191000000004',
          '1.3887400000000003,103.90191000000004',
          '1.3882700000000003,103.90190000000004',
          '1.3878700000000004,103.90188000000003',
          '1.3876400000000004,103.90187000000003',
          '1.3873700000000004,103.90185000000002',
          '1.3871500000000003,103.90183000000002',
          '1.3867600000000004,103.90178000000002',
          '1.3866000000000005,103.90176000000001',
          '1.3864700000000005,103.90174',
          '1.3863400000000006,103.90171000000001',
          '1.3862600000000005,103.90169',
          '1.3862300000000005,103.90168',
          '1.3861400000000006,103.90165',
          '1.3860600000000005,103.90162000000001',
          '1.3859400000000006,103.90157',
          '1.3858200000000007,103.90151',
          '1.3856800000000007,103.90143',
          '1.3855200000000008,103.90133',
          '1.3853800000000007,103.90123',
          '1.3852500000000008,103.90111999999999',
          '1.3850900000000008,103.90096999999999',
          '1.3848800000000008,103.90075999999999',
          '1.3845800000000008,103.90046',
          '1.3843300000000007,103.90021',
          '1.3841600000000007,103.90004',
          '1.3840000000000008,103.89987',
          '1.3838300000000008,103.89968',
          '1.3837600000000008,103.8996',
          '1.3836700000000008,103.89949',
          '1.383580000000001,103.89938',
          '1.383440000000001,103.8992',
          '1.383340000000001,103.89907',
          '1.3831600000000008,103.89882999999999',
          '1.382970000000001,103.89858',
          '1.3829300000000009,103.89853',
          '1.3828900000000008,103.89847999999999',
          '1.3827800000000008,103.89832999999999',
          '1.3826800000000008,103.89820999999999',
          '1.3825900000000009,103.89809999999999',
          '1.382500000000001,103.89798999999998',
          '1.382410000000001,103.89788999999998',
          '1.382320000000001,103.89780999999998',
          '1.3822000000000012,103.89771999999998',
          '1.3821400000000013,103.89767999999998',
          '1.3820100000000013,103.89759999999998',
          '1.3818700000000013,103.89752999999999',
          '1.3817300000000012,103.89747999999999',
          '1.3815500000000012,103.89741999999998',
          '1.3813600000000013,103.89736999999998',
          '1.3811200000000012,103.89730999999998',
          '1.3808900000000013,103.89724999999997',
          '1.3806800000000012,103.89719999999997',
          '1.3803800000000013,103.89712999999998',
          '1.3802900000000013,103.89710999999997',
          '1.3801700000000015,103.89708999999996',
          '1.3799000000000015,103.89703999999996',
          '1.3797900000000014,103.89701999999996',
          '1.3796000000000015,103.89698999999996',
          '1.3794800000000016,103.89696999999995',
          '1.3793200000000017,103.89693999999996',
          '1.3790600000000017,103.89688999999996',
          '1.3787600000000018,103.89684999999996',
          '1.3785700000000018,103.89682999999995',
          '1.3783500000000017,103.89681999999995',
          '1.3782700000000017,103.89681999999995',
          '1.3780200000000016,103.89680999999995',
          '1.3775400000000015,103.89679999999994',
          '1.3774100000000016,103.89678999999994',
          '1.3773300000000015,103.89677999999994',
          '1.3772700000000015,103.89676999999993',
          '1.3771800000000016,103.89674999999993',
          '1.3770900000000017,103.89671999999993',
          '1.3769900000000017,103.89667999999993',
          '1.3768300000000018,103.89659999999994',
          '1.3765400000000019,103.89644999999993',
          '1.3764700000000019,103.89643999999993',
          '1.3764200000000018,103.89643999999993',
          '1.3763900000000018,103.89643999999993',
          '1.3763200000000018,103.89644999999993',
          '1.3762800000000017,103.89645999999993',
          '1.3762300000000016,103.89647999999994',
          '1.3761800000000015,103.89650999999994',
          '1.3761400000000015,103.89653999999993',
          '1.3760600000000014,103.89662999999993',
          '1.3759500000000013,103.89674999999993',
          '1.3757700000000013,103.89692999999993',
          '1.3755800000000014,103.89710999999993',
          '1.3753000000000013,103.89735999999992',
          '1.3751700000000013,103.89747999999992',
          '1.3750900000000013,103.89754999999991',
          '1.3750100000000012,103.89762999999991',
          '1.374960000000001,103.89767999999991',
          '1.374890000000001,103.8977599999999',
          '1.374820000000001,103.8978399999999',
          '1.3747300000000011,103.89794999999991',
          '1.3747000000000011,103.89798999999991',
          '1.374590000000001,103.89813999999991',
          '1.374550000000001,103.89819999999992',
          '1.374110000000001,103.89882999999992',
          '1.374000000000001,103.89896999999992',
          '1.373960000000001,103.89901999999992',
          '1.373930000000001,103.89905999999992',
          '1.373850000000001,103.89914999999992',
          '1.3738100000000009,103.89918999999992',
          '1.3737400000000008,103.89924999999992',
          '1.3736900000000007,103.89928999999992',
          '1.3736400000000006,103.89931999999992',
          '1.3736000000000006,103.89933999999992',
          '1.3735200000000005,103.89937999999992',
          '1.3733900000000006,103.89923999999992',
          '1.3731300000000006,103.89893999999993',
          '1.3730400000000007,103.89883999999992',
          '1.3729600000000006,103.89874999999992',
          '1.3726800000000006,103.89842999999992',
          '1.3725400000000005,103.89826999999993',
          '1.3723200000000004,103.89801999999993',
          '1.3721700000000003,103.89784999999993',
          '1.3719200000000003,103.89756999999993',
          '1.3717300000000003,103.89735999999994',
          '1.3712500000000003,103.89683999999994',
          '1.3708100000000003,103.89634999999994',
          '1.3707000000000003,103.89623999999993',
          '1.3704500000000002,103.89596999999993',
          '1.3703100000000001,103.89581999999993',
          '1.3697000000000001,103.89517999999993',
          '1.36942,103.89488999999992',
          '1.36935,103.89481999999992',
          '1.36921,103.89467999999992',
          '1.36927,103.89454999999992',
          '1.36947,103.89438999999993',
          '1.3695599999999999,103.89431999999994',
          '1.3696599999999999,103.89422999999994',
          '1.3697599999999999,103.89414999999994',
          '1.3698599999999999,103.89407999999995',
          '1.36997,103.89401999999994',
          '1.3700899999999998,103.89398999999995',
          '1.3701899999999998,103.89397999999994',
          '1.3703199999999998,103.89396999999994',
          '1.3704599999999998,103.89395999999994',
          '1.3705899999999998,103.89390999999993',
          '1.3707199999999997,103.89380999999993',
          '1.3708599999999997,103.89367999999993',
          '1.3708899999999997,103.89364999999994',
          '1.3710399999999998,103.89349999999993',
          '1.3711699999999998,103.89335999999993',
          '1.3712399999999998,103.89326999999993',
          '1.3712999999999997,103.89311999999993',
          '1.3713199999999997,103.89302999999992',
          '1.3713299999999997,103.89293999999992',
          '1.3713299999999997,103.89278999999992',
          '1.3713299999999997,103.89274999999992',
          '1.3713299999999997,103.89259999999992',
          '1.3713229999999996,103.89245799999992',
          '1.371323,103.892458',
          '1.37132,103.89240000000001',
          '1.37131,103.89213000000001',
          '1.37132,103.89201000000001',
          '1.37132,103.8919',
          '1.37132,103.89179',
          '1.37132,103.89168',
          '1.37131,103.89156',
          '1.3713,103.89149',
          '1.37128,103.89142000000001',
          '1.37124,103.89134000000001',
          '1.37122,103.89131000000002',
          '1.3711000000000002,103.89120000000001',
          '1.3710600000000002,103.89117000000002',
          '1.37101,103.89113000000002',
          '1.3709500000000001,103.89109000000002',
          '1.3708600000000002,103.89099000000002',
          '1.3707500000000001,103.89091000000002',
          '1.37067,103.89084000000003',
          '1.37047,103.89065000000002',
          '1.37025,103.89048000000003',
          '1.37016,103.89041000000003',
          '1.37006,103.89033000000003',
          '1.36992,103.89023000000003',
          '1.36984,103.89018000000003',
          '1.36971,103.89011000000004',
          '1.36963,103.89005000000003',
          '1.36951,103.88994000000002',
          '1.36938,103.88978000000003',
          '1.36922,103.88988000000003',
          '1.36911,103.88995000000003',
          '1.36897,103.89004000000003',
          '1.36883,103.89013000000003',
          '1.36869,103.89023000000003',
          '1.3686,103.89030000000002',
          '1.3685,103.89038000000002',
          '1.3684,103.89047000000002',
          '1.3683,103.89057000000003',
          '1.3681800000000002,103.89070000000002',
          '1.3679800000000002,103.89093000000003',
          '1.3676800000000002,103.89131000000003',
          '1.3674800000000003,103.89157000000003',
          '1.3673200000000003,103.89179000000003',
          '1.3672100000000003,103.89194000000003',
          '1.3669900000000001,103.89223000000004',
          '1.3668700000000003,103.89232000000004',
          '1.3664600000000002,103.89188000000004',
          '1.3660400000000001,103.89143000000004',
          '1.36593,103.89131000000005',
          '1.3657000000000001,103.89107000000004',
          '1.36565,103.89102000000004',
          '1.36558,103.89094000000004',
          '1.36543,103.89079000000004',
          '1.3652799999999998,103.89063000000004',
          '1.3644199999999997,103.88971000000005',
          '1.3635499999999998,103.88883000000006',
          '1.36329,103.88856000000006',
          '1.3627699999999998,103.88800000000006',
          '1.3620999999999999,103.88730000000007',
          '1.36184,103.88702000000006',
          '1.3617599999999999,103.88693000000006',
          '1.36167,103.88684000000006',
          '1.36151,103.88667000000007',
          '1.36135,103.88650000000007',
          '1.36112,103.88629000000007',
          '1.3610200000000001,103.88619000000007',
          '1.36071,103.88586000000006',
          '1.3606,103.88573000000007',
          '1.36055,103.88567000000006',
          '1.36031,103.88538000000005',
          '1.3600299999999999,103.88503000000006',
          '1.35987,103.88485000000006',
          '1.35954,103.88449000000006',
          '1.35936,103.88430000000005',
          '1.35838,103.88329000000006',
          '1.3582999999999998,103.88321000000006',
          '1.3580899999999998,103.88299000000006',
          '1.35797,103.88286000000006',
          '1.35777,103.88265000000007',
          '1.3576199999999998,103.88250000000006',
          '1.35719,103.88202000000007',
          '1.3568799999999999,103.88168000000007',
          '1.3566599999999998,103.88143000000008',
          '1.3565399999999999,103.88129000000008',
          '1.3563999999999998,103.88113000000008',
          '1.3562699999999999,103.88098000000008',
          '1.3561699999999999,103.88087000000007',
          '1.35608,103.88077000000007',
          '1.35599,103.88067000000007',
          '1.35582,103.88046000000007',
          '1.35578,103.88041000000007',
          '1.35558,103.88018000000007',
          '1.35539,103.87996000000007',
          '1.35534,103.87990000000006',
          '1.35507,103.87960000000007',
          '1.35487,103.87936000000006',
          '1.35472,103.87918000000006',
          '1.35462,103.87906000000007',
          '1.35449,103.87893000000007',
          '1.3542699999999999,103.87869000000006',
          '1.35404,103.87843000000007',
          '1.35395,103.87834000000007',
          '1.35387,103.87826000000007',
          '1.3537599999999999,103.87815000000006',
          '1.3536199999999998,103.87801000000006',
          '1.3533799999999998,103.87777000000006',
          '1.3530999999999997,103.87747000000006',
          '1.3529399999999998,103.87729000000006',
          '1.35265,103.87702000000006',
          '1.35249,103.87683000000006',
          '1.3524,103.87672000000005',
          '1.35229,103.87658000000005',
          '1.35208,103.87629000000004',
          '1.3520299999999998,103.87622000000005',
          '1.3518299999999999,103.87596000000005',
          '1.3516499999999998,103.87575000000005',
          '1.35153,103.87561000000005',
          '1.3513499999999998,103.87541000000004',
          '1.3510799999999998,103.87512000000004',
          '1.35075,103.87477000000004',
          '1.3505999999999998,103.87461000000005',
          '1.3504399999999999,103.87445000000005',
          '1.35018,103.87421000000005',
          '1.35002,103.87407000000005',
          '1.34992,103.87399000000005',
          '1.34982,103.87391000000005',
          '1.34961,103.87374000000005',
          '1.34938,103.87355000000005',
          '1.349121,103.87332800000006',
          '1.349121,103.873328',
          '1.34903,103.87325',
          '1.34889,103.87313',
          '1.34883,103.87308',
          '1.34873,103.873',
          '1.34865,103.87294',
          '1.3485699999999998,103.87288',
          '1.3484899999999997,103.87281999999999',
          '1.3483899999999998,103.87275',
          '1.3482299999999998,103.87263999999999',
          '1.3481799999999997,103.87261',
          '1.3480199999999998,103.87250999999999',
          '1.3477399999999997,103.87235',
          '1.3474799999999998,103.87219999999999',
          '1.3473199999999999,103.87210999999999',
          '1.34723,103.87205999999999',
          '1.34711,103.87199999999999',
          '1.34694,103.87191999999999',
          '1.34669,103.87180999999998',
          '1.34615,103.87155999999999',
          '1.34589,103.87142999999999',
          '1.3457000000000001,103.87132999999999',
          '1.3455800000000002,103.87126999999998',
          '1.3453700000000002,103.87116999999998',
          '1.3451700000000002,103.87107999999998',
          '1.3450800000000003,103.87103999999998',
          '1.3449800000000003,103.87099999999998',
          '1.3448500000000003,103.87094999999998',
          '1.3447900000000004,103.87092999999997',
          '1.3445800000000003,103.87086999999997',
          '1.3445000000000003,103.87084999999996',
          '1.3444500000000001,103.87083999999996',
          '1.3443900000000002,103.87082999999996',
          '1.3443200000000002,103.87081999999995',
          '1.3441200000000002,103.87079999999995',
          '1.3439800000000002,103.87078999999994',
          '1.3437500000000002,103.87077999999994',
          '1.3435300000000001,103.87078999999994',
          '1.34336,103.87079999999995',
          '1.34321,103.87080999999995',
          '1.3430900000000001,103.87081999999995',
          '1.3428900000000001,103.87083999999996',
          '1.3428300000000002,103.87072999999995',
          '1.3428000000000002,103.87066999999995',
          '1.3427700000000002,103.87060999999994',
          '1.3427200000000001,103.87049999999994',
          '1.34268,103.87039999999993',
          '1.34264,103.87029999999993',
          '1.34261,103.87020999999993',
          '1.34258,103.87010999999993',
          '1.3425500000000001,103.86999999999992',
          '1.3425300000000002,103.86989999999992',
          '1.3425100000000003,103.86977999999992',
          '1.3425000000000002,103.86965999999993',
          '1.3425000000000002,103.86955999999992',
          '1.3425100000000003,103.86944999999992',
          '1.3425300000000002,103.86932999999992',
          '1.3425500000000001,103.86923999999992',
          '1.34258,103.86912999999991',
          '1.3426200000000001,103.86900999999992',
          '1.34268,103.86883999999992',
          '1.34274,103.86868999999992',
          '1.34281,103.86853999999991',
          '1.34301,103.86814999999991',
          '1.34324,103.86773999999991',
          '1.34345,103.86729999999991',
          '1.3436700000000001,103.86682999999991',
          '1.34373,103.86669999999991',
          '1.34385,103.86644999999992',
          '1.34391,103.86631999999992',
          '1.3439999999999999,103.86611999999991',
          '1.3440699999999999,103.86594999999991',
          '1.3441299999999998,103.86576999999991',
          '1.34418,103.86558999999991',
          '1.34421,103.86543999999991',
          '1.3442299999999998,103.86527999999991',
          '1.3442399999999999,103.86515999999992',
          '1.34425,103.86502999999992',
          '1.34425,103.86491999999991',
          '1.3442399999999999,103.8648099999999',
          '1.34422,103.86463999999991',
          '1.34419,103.86449999999991',
          '1.34417,103.8644099999999',
          '1.3441400000000001,103.86428999999991',
          '1.3441100000000001,103.86418999999991',
          '1.3440400000000001,103.8639599999999',
          '1.3439800000000002,103.86374999999991',
          '1.3438500000000002,103.8633299999999',
          '1.3437500000000002,103.86315999999991',
          '1.3437100000000002,103.86307999999991',
          '1.3436700000000001,103.86298999999991',
          '1.3436500000000002,103.8629299999999',
          '1.3436000000000001,103.8627499999999',
          '1.3435400000000002,103.8625199999999',
          '1.3435000000000001,103.8623399999999',
          '1.34346,103.86212999999991',
          '1.3434300000000001,103.8619399999999',
          '1.34339,103.8616199999999',
          '1.34336,103.8613899999999',
          '1.34331,103.8612899999999',
          '1.34327,103.8612199999999',
          '1.34325,103.86118999999991',
          '1.34319,103.86109999999991',
          '1.34315,103.8610499999999',
          '1.34311,103.86100999999991',
          '1.34307,103.86097999999991',
          '1.34303,103.8609599999999',
          '1.34299,103.8609499999999',
          '1.34293,103.8609399999999',
          '1.34287,103.8609399999999',
          '1.34279,103.8609399999999',
          '1.3426799999999999,103.8609799999999',
          '1.3424999999999998,103.8610399999999',
          '1.3414099999999998,103.8614099999999',
          '1.3407699999999998,103.8616299999999',
          '1.3404999999999998,103.8616699999999',
          '1.3403199999999997,103.86168999999991',
          '1.3399999999999996,103.86172999999991',
          '1.3398099999999997,103.8617599999999',
          '1.3396299999999997,103.86181999999991',
          '1.3393999999999997,103.8618899999999',
          '1.3391399999999998,103.8619599999999',
          '1.3389399999999998,103.8620099999999',
          '1.3387299999999998,103.8620499999999',
          '1.3385599999999998,103.86207999999989',
          '1.3384199999999997,103.8620999999999',
          '1.3382499999999997,103.8621199999999',
          '1.3380699999999996,103.86213999999991',
          '1.3378699999999997,103.86215999999992',
          '1.3376999999999997,103.86216999999992',
          '1.3373299999999997,103.86218999999993',
          '1.3368099999999996,103.86220999999993',
          '1.3362299999999996,103.86222999999994',
          '1.3356199999999996,103.86224999999995',
          '1.3346699999999996,103.86228999999994',
          '1.3342399999999996,103.86230999999995',
          '1.3339599999999996,103.86231999999995',
          '1.3334099999999995,103.86234999999995',
          '1.3331599999999995,103.86235999999995',
          '1.3328999999999995,103.86236999999996',
          '1.3326599999999995,103.86237999999996',
          '1.3322299999999996,103.86239999999997',
          '1.3319399999999997,103.86240999999997',
          '1.3312999999999997,103.86242999999997',
          '1.3306299999999998,103.86244999999998',
          '1.3305099999999999,103.86244999999998',
          '1.3303399999999999,103.86244999999998',
          '1.3301899999999998,103.86244999999998',
          '1.3300999999999998,103.86244999999998',
          '1.3300599999999998,103.86244999999998',
          '1.32977,103.86243999999998',
          '1.3296999999999999,103.86243999999998',
          '1.32951,103.86242999999997',
          '1.32937,103.86241999999997',
          '1.32918,103.86239999999997',
          '1.32903,103.86237999999996',
          '1.3288799999999998,103.86234999999996',
          '1.3287399999999998,103.86231999999997',
          '1.3286299999999998,103.86228999999997',
          '1.3285199999999997,103.86225999999998',
          '1.3283599999999998,103.86220999999998',
          '1.3282099999999997,103.86215999999997',
          '1.3280699999999996,103.86210999999997',
          '1.3279399999999997,103.86205999999997',
          '1.3277699999999997,103.86198999999998',
          '1.3276399999999997,103.86192999999997',
          '1.3274999999999997,103.86185999999998',
          '1.3273799999999998,103.86179999999997',
          '1.3272499999999998,103.86172999999998',
          '1.3271499999999998,103.86166999999998',
          '1.3270399999999998,103.86159999999998',
          '1.3268199999999997,103.86145999999998',
          '1.3266199999999997,103.86130999999997',
          '1.3265099999999996,103.86122999999998',
          '1.3263799999999997,103.86111999999997',
          '1.3262599999999998,103.86100999999996',
          '1.3260999999999998,103.86084999999997',
          '1.3259699999999999,103.86071999999997',
          '1.32584,103.86058999999997',
          '1.32571,103.86044999999997',
          '1.32552,103.86023999999998',
          '1.32537,103.86006999999998',
          '1.3252899999999999,103.85996999999998',
          '1.3252399999999998,103.85990999999997',
          '1.3251699999999997,103.85982999999997',
          '1.3249499999999996,103.85957999999998',
          '1.3246199999999997,103.85921999999998',
          '1.3240399999999997,103.85858999999998',
          '1.3239099999999997,103.85844999999998',
          '1.3238099999999997,103.85833999999997',
          '1.3236899999999998,103.85820999999997',
          '1.3236199999999998,103.85814999999997',
          '1.3235899999999998,103.85811999999997',
          '1.3235,103.85803999999997',
          '1.3234199999999998,103.85796999999998',
          '1.3233,103.85785999999997',
          '1.32317,103.85773999999998',
          '1.3230199999999999,103.85760999999998',
          '1.3228799999999998,103.85748999999998',
          '1.3227399999999998,103.85736999999999',
          '1.3224099999999999,103.85709999999999',
          '1.3221299999999998,103.85685999999998',
          '1.3218699999999999,103.85662999999998',
          '1.32178,103.85653999999998',
          '1.32149,103.85624999999997',
          '1.32118,103.85594999999998',
          '1.32093,103.85568999999998',
          '1.3207499999999999,103.85548999999997',
          '1.3205699999999998,103.85527999999998',
          '1.3203799999999999,103.85503999999997',
          '1.3201599999999998,103.85473999999998',
          '1.3200499999999997,103.85458999999997',
          '1.3199199999999998,103.85439999999997',
          '1.3198099999999997,103.85421999999997',
          '1.3197099999999997,103.85404999999997',
          '1.3195399999999997,103.85374999999998',
          '1.3194599999999996,103.85360999999997',
          '1.3193899999999996,103.85347999999998',
          '1.3193599999999996,103.85342999999997',
          '1.3192799999999996,103.85329999999998',
          '1.3191499999999996,103.85302999999998',
          '1.3190599999999997,103.85282999999997',
          '1.3189399999999998,103.85257999999997',
          '1.3186399999999998,103.85190999999998',
          '1.31801,103.85048999999998',
          '1.3178299999999998,103.85010999999997',
          '1.3177799999999997,103.84999999999997',
          '1.3177299999999996,103.84989999999996',
          '1.3174899999999996,103.84938999999996',
          '1.3172899999999996,103.84892999999995',
          '1.3172299999999997,103.84879999999995',
          '1.3170699999999997,103.84846999999995',
          '1.3170199999999996,103.84837999999995',
          '1.3169299999999997,103.84820999999995',
          '1.3168099999999998,103.84800999999995',
          '1.3167099999999998,103.84784999999995',
          '1.31659,103.84767999999995',
          '1.31646,103.84750999999996',
          '1.3162399999999999,103.84722999999995',
          '1.31615,103.84711999999995',
          '1.31588,103.84682999999994',
          '1.3155599999999998,103.84652999999994',
          '1.3153299999999999,103.84630999999995',
          '1.3152599999999999,103.84624999999994',
          '1.3151,103.84612999999995',
          '1.31494,103.84601999999994',
          '1.31476,103.84589999999994',
          '1.31464,103.84581999999995',
          '1.31451,103.84573999999995',
          '1.3144200000000001,103.84568999999995',
          '1.31437,103.84565999999995',
          '1.3142800000000001,103.84560999999995',
          '1.3142,103.84556999999995',
          '1.3140800000000001,103.84550999999995',
          '1.3139800000000001,103.84545999999995',
          '1.3138900000000002,103.84541999999995',
          '1.3138200000000002,103.84538999999995',
          '1.3137100000000002,103.84533999999995',
          '1.3135700000000001,103.84527999999995',
          '1.31342,103.84521999999994',
          '1.31328,103.84516999999994',
          '1.31305,103.84508999999994',
          '1.3128900000000001,103.84503999999994',
          '1.31278,103.84500999999995',
          '1.31265,103.84497999999995',
          '1.3125200000000001,103.84494999999995',
          '1.3124200000000001,103.84492999999995',
          '1.3120500000000002,103.84485999999995',
          '1.3119600000000002,103.84483999999995',
          '1.3118300000000003,103.84480999999995',
          '1.3116000000000003,103.84474999999995',
          '1.3114200000000003,103.84469999999995',
          '1.3112900000000003,103.84465999999995',
          '1.3111700000000004,103.84461999999995',
          '1.3110600000000003,103.84457999999995',
          '1.3109600000000003,103.84453999999995',
          '1.3108600000000004,103.84449999999995',
          '1.3107400000000005,103.84444999999995',
          '1.3106300000000004,103.84439999999995',
          '1.3106100000000005,103.84438999999995',
          '1.3105700000000005,103.84436999999994',
          '1.3104700000000005,103.84431999999994',
          '1.3103300000000004,103.84421999999994',
          '1.3102700000000005,103.84417999999994',
          '1.3101400000000005,103.84410999999994',
          '1.3099900000000004,103.84401999999994',
          '1.3098600000000005,103.84392999999994',
          '1.3097000000000005,103.84380999999995',
          '1.3095700000000006,103.84369999999994',
          '1.3095100000000006,103.84364999999994',
          '1.3094100000000006,103.84355999999994',
          '1.3092300000000006,103.84337999999994',
          '1.3091800000000005,103.84332999999994',
          '1.3090900000000005,103.84322999999993',
          '1.3089700000000006,103.84307999999993',
          '1.3086100000000007,103.84260999999992',
          '1.3085100000000007,103.84245999999992',
          '1.3084200000000008,103.84232999999992',
          '1.3078400000000008,103.84152999999992',
          '1.3076500000000009,103.84128999999992',
          '1.3075400000000008,103.84115999999992',
          '1.3074600000000007,103.84106999999992',
          '1.3073800000000007,103.84098999999992',
          '1.3072900000000007,103.84089999999992',
          '1.3071600000000008,103.84078999999991',
          '1.3069100000000007,103.84059999999991',
          '1.3068000000000006,103.84052999999992',
          '1.3066900000000006,103.84046999999991',
          '1.3065700000000007,103.8404099999999',
          '1.3064300000000006,103.84033999999991',
          '1.3063100000000007,103.84029999999991',
          '1.3061700000000007,103.84025999999992',
          '1.3060500000000008,103.84022999999992',
          '1.3059100000000008,103.84019999999992',
          '1.3057500000000009,103.84016999999993',
          '1.3056000000000008,103.84014999999992',
          '1.3054300000000008,103.84012999999992',
          '1.3052300000000008,103.84011999999991',
          '1.3050900000000007,103.84011999999991',
          '1.3048600000000008,103.84013999999992',
          '1.3047200000000008,103.84015999999993',
          '1.3046200000000008,103.84017999999993',
          '1.3045000000000009,103.84020999999993',
          '1.304370000000001,103.84024999999993',
          '1.304250000000001,103.84028999999992',
          '1.304140000000001,103.84032999999992',
          '1.304000000000001,103.84038999999993',
          '1.303900000000001,103.84043999999993',
          '1.303800000000001,103.84048999999993',
          '1.303690000000001,103.84054999999994',
          '1.3035800000000008,103.84061999999993',
          '1.303490000000001,103.84067999999994',
          '1.303400000000001,103.84074999999993',
          '1.303330000000001,103.84080999999993',
          '1.303250000000001,103.84087999999993',
          '1.303150000000001,103.84096999999993',
          '1.303060000000001,103.84105999999993',
          '1.302920000000001,103.84119999999993',
          '1.302810000000001,103.84130999999994',
          '1.3027300000000008,103.84139999999994',
          '1.3025000000000009,103.84166999999994',
          '1.302440000000001,103.84174999999993',
          '1.3023300000000009,103.84187999999993',
          '1.301790000000001,103.84252999999993',
          '1.3016500000000009,103.84270999999993',
          '1.3015500000000009,103.84281999999993',
          '1.301460000000001,103.84290999999993',
          '1.3013800000000009,103.84298999999993',
          '1.3013100000000009,103.84304999999993',
          '1.3012000000000008,103.84313999999993',
          '1.3011500000000007,103.84317999999993',
          '1.3011100000000007,103.84320999999993',
          '1.3010600000000005,103.84324999999993',
          '1.3009900000000005,103.84329999999993',
          '1.3009300000000006,103.84332999999992',
          '1.3008600000000006,103.84336999999992',
          '1.3007300000000006,103.84344999999992',
          '1.3006300000000006,103.84349999999992',
          '1.3005400000000007,103.84354999999992',
          '1.3004800000000007,103.84357999999992',
          '1.3003700000000007,103.84361999999992',
          '1.3002700000000007,103.84364999999991',
          '1.3001900000000006,103.84366999999992',
          '1.3000800000000006,103.84368999999992',
          '1.2997700000000005,103.84373999999993',
          '1.2996300000000005,103.84375999999993',
          '1.2995200000000005,103.84376999999994',
          '1.2994400000000004,103.84376999999994',
          '1.2992200000000003,103.84377999999994',
          '1.2991800000000002,103.84377999999994',
          '1.2991500000000002,103.84377999999994',
          '1.2988200000000003,103.84377999999994',
          '1.2980700000000003,103.84379999999994',
          '1.2975000000000003,103.84382999999994',
          '1.2971700000000004,103.84384999999995',
          '1.2970600000000003,103.84385999999995',
          '1.2969200000000003,103.84387999999996',
          '1.2966300000000004,103.84392999999996',
          '1.2964600000000004,103.84394999999996',
          '1.2962300000000004,103.84401999999996',
          '1.2961300000000004,103.84404999999995',
          '1.2960200000000004,103.84406999999996',
          '1.2958200000000004,103.84410999999996',
          '1.2956000000000003,103.84414999999996',
          '1.2954200000000002,103.84417999999995',
          '1.2952200000000003,103.84420999999995',
          '1.2950500000000003,103.84422999999995',
          '1.2947600000000004,103.84425999999995',
          '1.2946200000000003,103.84426999999995',
          '1.2945100000000003,103.84426999999995',
          '1.2944200000000003,103.84426999999995',
          '1.2943200000000004,103.84425999999995',
          '1.2941400000000003,103.84422999999995',
          '1.2939800000000004,103.84421999999995',
          '1.2938200000000004,103.84422999999995',
          '1.2937500000000004,103.84422999999995',
          '1.2936800000000004,103.84421999999995',
          '1.2936200000000004,103.84420999999995',
          '1.2933900000000005,103.84416999999995',
          '1.2932400000000004,103.84413999999995',
          '1.2931100000000004,103.84410999999996',
          '1.2930000000000004,103.84407999999996',
          '1.2929000000000004,103.84404999999997',
          '1.2927800000000005,103.84399999999997',
          '1.2926700000000004,103.84395999999997',
          '1.2925100000000005,103.84390999999997',
          '1.2923900000000006,103.84386999999997',
          '1.2922800000000005,103.84382999999997',
          '1.2922300000000004,103.84380999999996',
          '1.2921900000000004,103.84378999999996',
          '1.2920700000000005,103.84372999999995',
          '1.2917900000000004,103.84359999999995',
          '1.2917100000000004,103.84355999999995',
          '1.2916600000000003,103.84352999999996',
          '1.2915600000000003,103.84349999999996',
          '1.2914400000000004,103.84346999999997',
          '1.2913600000000003,103.84344999999996',
          '1.2913000000000003,103.84342999999996',
          '1.2912000000000003,103.84338999999996',
          '1.2910200000000003,103.84329999999996',
          '1.2906300000000004,103.84309999999995',
          '1.2904100000000003,103.84297999999995',
          '1.2899000000000003,103.84266999999996',
          '1.2898700000000003,103.84264999999995',
          '1.2897600000000002,103.84257999999996',
          '1.2896800000000002,103.84252999999995',
          '1.2894400000000001,103.84237999999995',
          '1.28939,103.84234999999995',
          '1.2893000000000001,103.84228999999995',
          '1.2892100000000002,103.84222999999994',
          '1.2891400000000002,103.84219999999995',
          '1.28906,103.84217999999994',
          '1.28896,103.84211999999994',
          '1.28889,103.84206999999994',
          '1.2887700000000002,103.84196999999993',
          '1.2886500000000003,103.84189999999994',
          '1.2885900000000003,103.84186999999994',
          '1.2885200000000003,103.84184999999994',
          '1.2884500000000003,103.84183999999993',
          '1.2884000000000002,103.84183999999993',
          '1.2883400000000003,103.84184999999994',
          '1.2882700000000002,103.84186999999994',
          '1.2882100000000003,103.84188999999995',
          '1.2881400000000003,103.84191999999994',
          '1.2880800000000003,103.84195999999994',
          '1.2880300000000002,103.84199999999994',
          '1.2879800000000001,103.84205999999995',
          '1.28793,103.84212999999994',
          '1.2879,103.84217999999994',
          '1.28787,103.84224999999994',
          '1.2878500000000002,103.84232999999993',
          '1.2878300000000003,103.84244999999993',
          '1.2878100000000003,103.84258999999993',
          '1.2877900000000004,103.84272999999993',
          '1.2877800000000004,103.84280999999993',
          '1.2877700000000003,103.84287999999992',
          '1.2877500000000004,103.84296999999992',
          '1.2877300000000005,103.84303999999992',
          '1.2876800000000004,103.84319999999991',
          '1.2876400000000003,103.84328999999991',
          '1.2876100000000004,103.84336999999991',
          '1.2875900000000005,103.84345999999991',
          '1.2875800000000004,103.8435399999999',
          '1.2875500000000004,103.8435899999999',
          '1.2874500000000004,103.8437599999999',
          '1.2872700000000004,103.84404999999991',
          '1.2870900000000003,103.84433999999992',
          '1.2870100000000002,103.84446999999992',
          '1.2869800000000002,103.84451999999992',
          '1.2869000000000002,103.84464999999992',
          '1.2866700000000002,103.84501999999992',
          '1.2865700000000002,103.84517999999991',
          '1.2865100000000003,103.84526999999991',
          '1.2864400000000002,103.84536999999992',
          '1.2862700000000002,103.84523999999992',
          '1.2860600000000002,103.84507999999992',
          '1.2857700000000003,103.84484999999992',
          '1.2855900000000002,103.84469999999992',
          '1.2854400000000001,103.84457999999992',
          '1.2853,103.84445999999993',
          '1.2853,103.84446',
          '1.2853,103.84446',
          '1.28488,103.84411',
          '1.28483,103.84407',
          '1.2845799999999998,103.84388',
          '1.28442,103.84375',
          '1.28425,103.84361',
          '1.28409,103.84347',
          '1.28395,103.84335',
          '1.28376,103.84318',
          '1.28355,103.843',
          '1.28317,103.84268',
          '1.283,103.84254',
          '1.2829199999999998,103.84247',
          '1.2828399999999998,103.84240000000001',
          '1.2827199999999999,103.84230000000001',
          '1.28249,103.84211',
          '1.28223,103.84189',
          '1.28199,103.8417',
          '1.28179,103.84153',
          '1.28168,103.84143',
          '1.2814699999999999,103.84126',
          '1.2811299999999999,103.84098',
          '1.281,103.84087',
          '1.28087,103.84075999999999',
          '1.28071,103.84062999999999',
          '1.28062,103.84055',
          '1.2804900000000001,103.84043999999999',
          '1.28025,103.84022999999999',
          '1.28001,103.84002',
          '1.27977,103.83981999999999',
          '1.2796500000000002,103.83971999999999',
          '1.2795500000000002,103.83963999999999',
          '1.2793700000000001,103.83948999999998',
          '1.27926,103.83939999999998',
          '1.27915,103.83928999999998',
          '1.27904,103.83916999999998',
          '1.2789599999999999,103.83907999999998',
          '1.2789,103.83900999999999',
          '1.27884,103.83892999999999',
          '1.27878,103.83885',
          '1.27868,103.83870999999999',
          '1.27862,103.83861999999999',
          '1.27847,103.83838999999999',
          '1.27829,103.83809999999998',
          '1.2782,103.83795999999998',
          '1.27808,103.83775999999997',
          '1.2779200000000002,103.83748999999997',
          '1.2777600000000002,103.83724999999997',
          '1.2777000000000003,103.83715999999997',
          '1.2776500000000002,103.83707999999997',
          '1.2776100000000001,103.83700999999998',
          '1.2774400000000001,103.83675999999998',
          '1.2773700000000001,103.83667999999999',
          '1.2772700000000001,103.83655999999999',
          '1.27709,103.83635',
          '1.2769300000000001,103.83617',
          '1.27662,103.83583',
          '1.27652,103.83572',
          '1.2764000000000002,103.83558',
          '1.2763700000000002,103.83554',
          '1.2763000000000002,103.83545',
          '1.2762200000000001,103.83532',
          '1.27617,103.83521999999999',
          '1.2761500000000001,103.83516999999999',
          '1.2761200000000001,103.83506999999999',
          '1.2761000000000002,103.83497999999999',
          '1.2760800000000003,103.83487999999998',
          '1.2760700000000003,103.83478999999998',
          '1.2760700000000003,103.83473999999998',
          '1.2760800000000003,103.83462999999998',
          '1.2760900000000004,103.83454999999998',
          '1.2761100000000003,103.83444999999998',
          '1.2761400000000003,103.83434999999997',
          '1.2762100000000003,103.83415999999997',
          '1.2762400000000003,103.83400999999996',
          '1.2762700000000002,103.83383999999997',
          '1.2763200000000003,103.83355999999996',
          '1.2763400000000003,103.83341999999996',
          '1.2763600000000002,103.83327999999996',
          '1.27635,103.83323999999996',
          '1.2763200000000001,103.83312999999995',
          '1.27631,103.83305999999996',
          '1.2763,103.83295999999996',
          '1.27629,103.83280999999995',
          '1.27629,103.83265999999995',
          '1.27629,103.83260999999995',
          '1.2762799999999999,103.83242999999995',
          '1.2762699999999998,103.83231999999994',
          '1.2762699999999998,103.83224999999995',
          '1.2762599999999997,103.83213999999994',
          '1.2762499999999997,103.83200999999994',
          '1.2762399999999996,103.83189999999993',
          '1.2762299999999995,103.83179999999993',
          '1.2761999999999996,103.83158999999993',
          '1.2761699999999996,103.83140999999993',
          '1.2761299999999995,103.83114999999994',
          '1.2760899999999995,103.83087999999994',
          '1.2760499999999995,103.83061999999994',
          '1.2760299999999996,103.83048999999994',
          '1.2760099999999996,103.83036999999995',
          '1.2759899999999997,103.83025999999994',
          '1.2759699999999998,103.83016999999994',
          '1.2759399999999999,103.83006999999994',
          '1.2758999999999998,103.82995999999993',
          '1.2758599999999998,103.82987999999993',
          '1.2758299999999998,103.82982999999993',
          '1.2757699999999998,103.82974999999993',
          '1.2757299999999998,103.82969999999993',
          '1.2756799999999997,103.82963999999993',
          '1.2756099999999997,103.82956999999993',
          '1.2753699999999997,103.82936999999993',
          '1.2752599999999996,103.82927999999993',
          '1.2751299999999997,103.82916999999992',
          '1.2749999999999997,103.82905999999991',
          '1.2747899999999996,103.82886999999991',
          '1.2747399999999995,103.82882999999991',
          '1.2746299999999995,103.82873999999991',
          '1.2745799999999994,103.82870999999992',
          '1.2745199999999994,103.82867999999992',
          '1.2744799999999994,103.82866999999992',
          '1.2744299999999993,103.82865999999991',
          '1.2743699999999993,103.82864999999991',
          '1.2742999999999993,103.82864999999991',
          '1.2742399999999994,103.82865999999991',
          '1.2741899999999993,103.82866999999992',
          '1.2740599999999993,103.82869999999991',
          '1.2739099999999992,103.82873999999991',
          '1.2736999999999992,103.8288099999999',
          '1.2735299999999992,103.8288599999999',
          '1.273419999999999,103.8288899999999',
          '1.273339999999999,103.8288999999999',
          '1.273279999999999,103.8288999999999',
          '1.273209999999999,103.8288899999999',
          '1.2731199999999991,103.8288699999999',
          '1.2730199999999992,103.8288299999999',
          '1.272969999999999,103.8287999999999',
          '1.272899999999999,103.8287499999999',
          '1.272819999999999,103.8286699999999',
          '1.272479999999999,103.8282799999999',
          '1.2724299999999988,103.8282199999999',
          '1.2721899999999988,103.8279499999999',
          '1.2721299999999989,103.82787999999991',
          '1.2720899999999988,103.8278299999999',
          '1.2718099999999988,103.82752999999991',
          '1.2714999999999987,103.82717999999991',
          '1.2712499999999987,103.8268899999999',
          '1.2711699999999986,103.8267899999999',
          '1.2710699999999986,103.8266599999999',
          '1.2710099999999986,103.8265699999999',
          '1.2709499999999987,103.8264699999999',
          '1.2708699999999986,103.8263299999999',
          '1.2707799999999987,103.8261499999999',
          '1.2707299999999986,103.82603999999989',
          '1.2706799999999985,103.82592999999989',
          '1.2706299999999984,103.82580999999989',
          '1.2705999999999984,103.82571999999989',
          '1.2705199999999983,103.8256499999999',
          '1.2704699999999982,103.8255999999999',
          '1.270419999999998,103.8255499999999',
          '1.2703599999999982,103.82548999999989',
          '1.2702999999999982,103.82543999999989',
          '1.270249999999998,103.82539999999989',
          '1.2701899999999982,103.82534999999989',
          '1.2701199999999981,103.82528999999988',
          '1.2698499999999981,103.82525999999989',
          '1.2696599999999982,103.82524999999988',
          '1.2695599999999982,103.82524999999988',
          '1.2694099999999982,103.82524999999988',
          '1.2692899999999983,103.82525999999989',
          '1.2691899999999983,103.82526999999989',
          '1.2691199999999982,103.82527999999989',
          '1.2690299999999983,103.8252999999999',
          '1.2688999999999984,103.8253399999999',
          '1.2688099999999984,103.8253699999999',
          '1.2687099999999985,103.82540999999989',
          '1.2686099999999985,103.82544999999989',
          '1.2684799999999985,103.8255099999999',
          '1.2684199999999985,103.82553999999989',
          '1.2683299999999986,103.82558999999989',
          '1.2682599999999986,103.82562999999989',
          '1.2681099999999985,103.82570999999989',
          '1.2679699999999985,103.82577999999988',
          '1.2678499999999986,103.82583999999989',
          '1.2677099999999986,103.82592999999989',
          '1.2675199999999986,103.82604999999988',
          '1.2673199999999987,103.82619999999989',
          '1.2672599999999987,103.82624999999989',
          '1.2672199999999987,103.82628999999989',
          '1.2671499999999987,103.82634999999989',
          '1.2670499999999987,103.82641999999989',
          '1.2669999999999986,103.82647999999989',
          '1.2669799999999987,103.82650999999989',
          '1.2668599999999988,103.82661999999989',
          '1.2667599999999988,103.82650999999989',
          '1.2666399999999989,103.82636999999988',
          '1.2664999999999988,103.82619999999989',
          '1.2664299999999988,103.82609999999988',
          '1.2663699999999989,103.82600999999988',
          '1.266309999999999,103.82590999999988',
          '1.266269999999999,103.82583999999989',
          '1.266249999999999,103.82579999999989',
          '1.266219999999999,103.8257299999999',
          '1.266179999999999,103.8255999999999',
          '1.266099999999999,103.8254699999999',
          '1.2660299999999989,103.8253499999999',
          '1.265999999999999,103.8252799999999',
          '1.265969999999999,103.8252199999999',
          '1.265909999999999,103.82505999999991',
          '1.2658599999999989,103.8248699999999',
          '1.2658199999999988,103.82469999999991',
          '1.265799999999999,103.82453999999991',
          '1.265779999999999,103.82436999999992',
          '1.265759999999999,103.82413999999991',
          '1.2657399999999992,103.82388999999992',
          '1.2657299999999991,103.82373999999992',
          '1.265719999999999,103.82361999999992',
          '1.265689999999999,103.82342999999992',
          '1.265679999999999,103.82334999999992',
          '1.265669999999999,103.82316999999992',
          '1.265659999999999,103.82292999999991',
          '1.265599999999999,103.82224999999991',
          '1.265579999999999,103.82172999999992',
          '1.265574999999999,103.82165699999992',
        ],
      },
      {
        value: 1,
        path: [
          '1.265575,103.821657',
          '1.26555,103.82133',
          '1.26555,103.82121000000001',
          '1.2655399999999999,103.82104000000001',
          '1.2655299999999998,103.82091000000001',
          '1.2655299999999998,103.82074000000001',
          '1.2655499999999997,103.82055000000001',
          '1.2655599999999998,103.82046000000001',
          '1.2655799999999997,103.82032000000001',
          '1.2655999999999996,103.82020000000001',
          '1.2656299999999996,103.82008000000002',
          '1.2656799999999997,103.81993000000001',
          '1.2657199999999997,103.81981000000002',
          '1.2657799999999997,103.81965000000002',
          '1.2658399999999996,103.81952000000003',
          '1.2658799999999997,103.81944000000003',
          '1.2659699999999996,103.81927000000003',
          '1.2661199999999997,103.81901000000003',
          '1.2661799999999996,103.81892000000003',
          '1.2663199999999997,103.81870000000004',
          '1.2664899999999997,103.81843000000003',
          '1.2665499999999996,103.81834000000003',
          '1.2666499999999996,103.81818000000004',
          '1.2669899999999996,103.81765000000004',
          '1.2671999999999997,103.81729000000004',
          '1.2673599999999996,103.81702000000004',
          '1.2677199999999995,103.81640000000004',
          '1.2677899999999995,103.81627000000005',
          '1.2679999999999996,103.81588000000005',
          '1.2681299999999995,103.81563000000006',
          '1.2683099999999996,103.81529000000006',
          '1.2685199999999996,103.81489000000006',
          '1.2685899999999997,103.81476000000006',
          '1.2686799999999996,103.81460000000007',
          '1.2687099999999996,103.81455000000007',
          '1.2688399999999995,103.81433000000007',
          '1.2689799999999996,103.81411000000007',
          '1.2691199999999996,103.81390000000007',
          '1.2692399999999995,103.81372000000007',
          '1.2694299999999994,103.81345000000007',
          '1.2695099999999995,103.81334000000007',
          '1.2695499999999995,103.81329000000007',
          '1.2696099999999995,103.81322000000007',
          '1.2696799999999995,103.81315000000008',
          '1.2698799999999995,103.81285000000008',
          '1.2700199999999995,103.81265000000008',
          '1.2701799999999994,103.81243000000008',
          '1.2703599999999995,103.81218000000008',
          '1.2705199999999994,103.81192000000009',
          '1.2706099999999994,103.81175000000009',
          '1.2706899999999994,103.81157000000009',
          '1.2707499999999994,103.81140000000009',
          '1.2707999999999995,103.81121000000009',
          '1.2709099999999995,103.8112300000001',
          '1.2714899999999996,103.8113600000001',
          '1.2716799999999995,103.8114100000001',
          '1.2718299999999996,103.8114600000001',
          '1.2721199999999995,103.8115900000001',
          '1.2723399999999996,103.81171000000009',
          '1.2724399999999996,103.8117700000001',
          '1.2725099999999996,103.8118200000001',
          '1.2727299999999997,103.81198000000009',
          '1.2728799999999998,103.81210000000009',
          '1.2729899999999998,103.81220000000009',
          '1.2730599999999999,103.81227000000008',
          '1.27317,103.81239000000008',
          '1.27331,103.81257000000008',
          '1.27348,103.81281000000008',
          '1.2737,103.81313000000009',
          '1.27401,103.81356000000008',
          '1.27417,103.81377000000008',
          '1.27428,103.81391000000008',
          '1.2744,103.81405000000008',
          '1.2745,103.81416000000009',
          '1.27459,103.81425000000009',
          '1.27467,103.81433000000008',
          '1.27476,103.81441000000008',
          '1.2748899999999999,103.81451000000008',
          '1.27507,103.81464000000008',
          '1.27529,103.81478000000008',
          '1.27547,103.81490000000008',
          '1.2757500000000002,103.81508000000008',
          '1.2759900000000002,103.81525000000008',
          '1.2761300000000002,103.81535000000008',
          '1.2762400000000003,103.81543000000008',
          '1.2765000000000002,103.81561000000008',
          '1.2767400000000002,103.81577000000007',
          '1.2769100000000002,103.81587000000007',
          '1.2770900000000003,103.81597000000008',
          '1.2773300000000003,103.81610000000008',
          '1.2775900000000002,103.81622000000007',
          '1.2779600000000002,103.81640000000007',
          '1.2784900000000001,103.81665000000007',
          '1.27858,103.81669000000007',
          '1.27904,103.81688000000007',
          '1.27931,103.81700000000006',
          '1.27944,103.81706000000007',
          '1.2795699999999999,103.81712000000007',
          '1.27995,103.81731000000008',
          '1.2800799999999999,103.81738000000007',
          '1.28015,103.81742000000007',
          '1.2802699999999998,103.81750000000007',
          '1.2803799999999999,103.81758000000006',
          '1.2804999999999997,103.81767000000006',
          '1.2806299999999997,103.81776000000006',
          '1.2807599999999997,103.81786000000007',
          '1.2808999999999997,103.81798000000006',
          '1.2810899999999996,103.81816000000006',
          '1.2812599999999996,103.81833000000006',
          '1.2814199999999996,103.81850000000006',
          '1.2817299999999996,103.81881000000006',
          '1.2818999999999996,103.81896000000006',
          '1.2819399999999996,103.81900000000006',
          '1.2822199999999997,103.81925000000005',
          '1.2824299999999997,103.81944000000006',
          '1.2827099999999998,103.81966000000006',
          '1.2828399999999998,103.81976000000006',
          '1.2830199999999998,103.81990000000006',
          '1.2831799999999998,103.82002000000006',
          '1.2833199999999998,103.82011000000006',
          '1.2834599999999998,103.82018000000005',
          '1.2834199999999998,103.82030000000005',
          '1.2833099999999997,103.82061000000004',
          '1.2831399999999997,103.82103000000005',
          '1.2830699999999997,103.82120000000005',
          '1.2829299999999997,103.82152000000005',
          '1.2828299999999997,103.82176000000005',
          '1.2827299999999997,103.82198000000005',
          '1.2825899999999997,103.82233000000005',
          '1.2825599999999997,103.82240000000004',
          '1.2824799999999996,103.82257000000004',
          '1.2823699999999996,103.82282000000004',
          '1.2823299999999995,103.82292000000004',
          '1.2821999999999996,103.82322000000003',
          '1.2819699999999996,103.82375000000003',
          '1.2819499999999997,103.82379000000003',
          '1.2818799999999997,103.82395000000002',
          '1.2818199999999997,103.82409000000003',
          '1.2817499999999997,103.82425000000002',
          '1.2815499999999997,103.82465000000002',
          '1.2813499999999998,103.82506000000002',
          '1.2812999999999997,103.82516000000003',
          '1.2811899999999996,103.82538000000002',
          '1.2809199999999996,103.82589000000003',
          '1.2807599999999997,103.82619000000003',
          '1.2807199999999996,103.82627000000002',
          '1.2806899999999997,103.82633000000003',
          '1.2806299999999997,103.82645000000002',
          '1.2805599999999997,103.82658000000002',
          '1.2805099999999996,103.82667000000002',
          '1.2804699999999996,103.82675000000002',
          '1.2804199999999994,103.82685000000002',
          '1.2801999999999993,103.82727000000003',
          '1.2801299999999993,103.82740000000003',
          '1.2800599999999993,103.82753000000002',
          '1.2800199999999993,103.82760000000002',
          '1.2799699999999992,103.82768000000002',
          '1.2798999999999991,103.82779000000002',
          '1.2797799999999993,103.82798000000003',
          '1.2796799999999993,103.82815000000002',
          '1.2795999999999992,103.82828000000002',
          '1.2795299999999992,103.82840000000002',
          '1.2794299999999992,103.82856000000001',
          '1.2793599999999992,103.82867000000002',
          '1.279279999999999,103.82879000000001',
          '1.2791899999999992,103.82893000000001',
          '1.279139999999999,103.82901000000001',
          '1.279079999999999,103.82910000000001',
          '1.278969999999999,103.82929000000001',
          '1.2787799999999991,103.82960000000001',
          '1.2786799999999992,103.82976000000001',
          '1.2785899999999992,103.82990000000001',
          '1.2785099999999991,103.83004000000001',
          '1.278429999999999,103.83018000000001',
          '1.278379999999999,103.83028000000002',
          '1.2783299999999989,103.83040000000001',
          '1.2782899999999988,103.83051000000002',
          '1.2782599999999988,103.83062000000002',
          '1.278239999999999,103.83071000000002',
          '1.278179999999999,103.83092000000002',
          '1.2781299999999989,103.83107000000003',
          '1.278069999999999,103.83122000000003',
          '1.277999999999999,103.83137000000004',
          '1.2778899999999989,103.83159000000003',
          '1.277799999999999,103.83177000000003',
          '1.277779999999999,103.83181000000003',
          '1.277739999999999,103.83189000000003',
          '1.277649999999999,103.83206000000003',
          '1.277619999999999,103.83212000000003',
          '1.2775599999999991,103.83224000000003',
          '1.277509999999999,103.83234000000003',
          '1.277309999999999,103.83274000000003',
          '1.277209999999999,103.83294000000004',
          '1.277099999999999,103.83315000000003',
          '1.277039999999999,103.83325000000004',
          '1.2769799999999991,103.83335000000004',
          '1.276909999999999,103.83345000000004',
          '1.276829999999999,103.83355000000005',
          '1.276749999999999,103.83364000000005',
          '1.276729999999999,103.83366000000005',
          '1.276659999999999,103.83374000000005',
          '1.276569999999999,103.83384000000005',
          '1.276519999999999,103.83390000000006',
          '1.276409999999999,103.83403000000006',
          '1.276349999999999,103.83411000000005',
          '1.276309999999999,103.83417000000006',
          '1.276279999999999,103.83423000000006',
          '1.276249999999999,103.83430000000006',
          '1.276219999999999,103.83438000000005',
          '1.276189999999999,103.83447000000005',
          '1.2761699999999991,103.83455000000005',
          '1.2761499999999992,103.83465000000005',
          '1.2761399999999992,103.83474000000005',
          '1.2761399999999992,103.83479000000005',
          '1.2761499999999992,103.83487000000005',
          '1.2761599999999993,103.83492000000005',
          '1.2761799999999992,103.83500000000005',
          '1.2762199999999992,103.83514000000005',
          '1.2762399999999992,103.83519000000005',
          '1.276259999999999,103.83523000000005',
          '1.276289999999999,103.83529000000006',
          '1.2763399999999991,103.83537000000005',
          '1.2763899999999992,103.83544000000005',
          '1.2764499999999992,103.83552000000005',
          '1.2765199999999992,103.83561000000005',
          '1.2766899999999992,103.83579000000005',
          '1.2769899999999992,103.83611000000005',
          '1.2771499999999991,103.83629000000005',
          '1.277269999999999,103.83642000000005',
          '1.277369999999999,103.83654000000004',
          '1.2774199999999991,103.83660000000005',
          '1.277479999999999,103.83667000000004',
          '1.277669999999999,103.83689000000004',
          '1.277719999999999,103.83695000000004',
          '1.277749999999999,103.83699000000004',
          '1.277809999999999,103.83708000000004',
          '1.277859999999999,103.83716000000004',
          '1.2779299999999991,103.83727000000005',
          '1.2780299999999991,103.83743000000004',
          '1.278189999999999,103.83768000000003',
          '1.278319999999999,103.83788000000004',
          '1.278599999999999,103.83830000000005',
          '1.278739999999999,103.83851000000004',
          '1.278869999999999,103.83870000000005',
          '1.278929999999999,103.83878000000004',
          '1.279009999999999,103.83888000000005',
          '1.2790899999999992,103.83897000000005',
          '1.279179999999999,103.83906000000005',
          '1.2793899999999991,103.83927000000004',
          '1.2797599999999991,103.83957000000004',
          '1.2798699999999992,103.83966000000004',
          '1.2801199999999993,103.83986000000004',
          '1.2802899999999993,103.84000000000005',
          '1.2805099999999994,103.84017000000004',
          '1.2806799999999994,103.84030000000004',
          '1.2807999999999993,103.84039000000004',
          '1.2808499999999994,103.84043000000004',
          '1.2813199999999993,103.84083000000004',
          '1.2815599999999994,103.84103000000005',
          '1.2817599999999993,103.84120000000004',
          '1.2818299999999994,103.84126000000005',
          '1.2818999999999994,103.84132000000005',
          '1.2821899999999993,103.84155000000005',
          '1.2824099999999994,103.84172000000005',
          '1.2826399999999993,103.84191000000006',
          '1.2828499999999994,103.84209000000006',
          '1.2831699999999995,103.84236000000006',
          '1.2833199999999996,103.84249000000005',
          '1.2834999999999996,103.84264000000006',
          '1.2839899999999997,103.84306000000007',
          '1.2840959999999997,103.84315000000007',
          '1.284096,103.84315',
          '1.28412,103.84317',
          '1.2843099999999998,103.84333',
          '1.2844399999999998,103.84344',
          '1.2845799999999998,103.84356',
          '1.28473,103.84369',
          '1.2848899999999999,103.84383',
          '1.2849599999999999,103.84389',
          '1.2851899999999998,103.84361',
          '1.28534,103.84343',
          '1.28576,103.84295',
          '1.28582,103.84288000000001',
          '1.28605,103.84263000000001',
          '1.28612,103.84257000000001',
          '1.28617,103.84253000000001',
          '1.28623,103.84249000000001',
          '1.28629,103.84246000000002',
          '1.28636,103.84243000000002',
          '1.2864499999999999,103.84240000000003',
          '1.28656,103.84237000000003',
          '1.28663,103.84236000000003',
          '1.28668,103.84236000000003',
          '1.28675,103.84236000000003',
          '1.28679,103.84237000000003',
          '1.2869000000000002,103.84240000000003',
          '1.2869700000000002,103.84242000000003',
          '1.2870300000000001,103.84244000000004',
          '1.28706,103.84245000000004',
          '1.2871100000000002,103.84247000000005',
          '1.2871700000000001,103.84249000000005',
          '1.2872000000000001,103.84250000000006',
          '1.2872500000000002,103.84251000000006',
          '1.2873100000000002,103.84252000000006',
          '1.2873400000000002,103.84252000000006',
          '1.28746,103.84252000000006',
          '1.2875100000000002,103.84252000000006',
          '1.2875600000000003,103.84251000000006',
          '1.2875900000000002,103.84250000000006',
          '1.2876300000000003,103.84248000000005',
          '1.2876600000000002,103.84245000000006',
          '1.2876800000000002,103.84241000000006',
          '1.2878600000000002,103.84198000000006',
          '1.2878900000000002,103.84191000000007',
          '1.2879300000000002,103.84184000000008',
          '1.2879600000000002,103.84179000000007',
          '1.2879900000000002,103.84175000000008',
          '1.2880200000000002,103.84171000000008',
          '1.2880600000000002,103.84166000000008',
          '1.2881000000000002,103.84161000000007',
          '1.2881400000000003,103.84157000000008',
          '1.2881900000000004,103.84153000000008',
          '1.2882200000000004,103.84151000000007',
          '1.2882500000000003,103.84149000000006',
          '1.2882900000000004,103.84147000000006',
          '1.2883100000000003,103.84146000000005',
          '1.2883600000000004,103.84145000000005',
          '1.2883800000000003,103.84145000000005',
          '1.2884000000000002,103.84145000000005',
          '1.2885300000000002,103.84146000000005',
          '1.2886600000000001,103.84146000000005',
          '1.2886900000000001,103.84146000000005',
          '1.2887400000000002,103.84147000000006',
          '1.2887700000000002,103.84148000000006',
          '1.2888200000000003,103.84150000000007',
          '1.2888700000000004,103.84153000000006',
          '1.2889000000000004,103.84155000000007',
          '1.2890800000000004,103.84168000000007',
          '1.2893300000000005,103.84183000000007',
          '1.2898900000000004,103.84219000000007',
          '1.2899600000000004,103.84223000000007',
          '1.2900600000000004,103.84230000000007',
          '1.2903700000000005,103.84249000000007',
          '1.2905200000000006,103.84258000000007',
          '1.2910000000000006,103.84286000000007',
          '1.2910700000000006,103.84291000000007',
          '1.2914500000000007,103.84323000000008',
          '1.2915500000000006,103.84328000000008',
          '1.2919800000000006,103.84349000000007',
          '1.2921700000000005,103.84358000000007',
          '1.2922800000000005,103.84363000000008',
          '1.2923200000000006,103.84365000000008',
          '1.2923600000000006,103.84367000000009',
          '1.2924600000000006,103.84371000000009',
          '1.2925700000000007,103.84375000000009',
          '1.2927500000000007,103.84381000000009',
          '1.2928800000000007,103.84385000000009',
          '1.2930200000000007,103.84389000000009',
          '1.2931000000000008,103.8439100000001',
          '1.2932700000000008,103.84395000000009',
          '1.2936400000000008,103.84402000000009',
          '1.2938100000000008,103.84405000000008',
          '1.2940000000000007,103.84408000000008',
          '1.2940800000000008,103.84409000000008',
          '1.2942000000000007,103.84410000000008',
          '1.2943200000000006,103.84410000000008',
          '1.2944700000000007,103.84409000000008',
          '1.2945700000000007,103.84408000000008',
          '1.2949400000000006,103.84405000000008',
          '1.2950600000000005,103.84404000000008',
          '1.2951500000000005,103.84403000000007',
          '1.2954100000000004,103.84399000000008',
          '1.2956500000000004,103.84395000000008',
          '1.2962500000000003,103.84387000000008',
          '1.2964700000000005,103.84384000000009',
          '1.2965400000000005,103.84383000000008',
          '1.2967600000000006,103.84380000000009',
          '1.2970800000000007,103.84374000000008',
          '1.2973200000000007,103.84369000000008',
          '1.2975800000000006,103.84367000000007',
          '1.2980900000000006,103.84364000000008',
          '1.2983800000000005,103.84363000000008',
          '1.2986000000000006,103.84362000000007',
          '1.2989100000000007,103.84361000000007',
          '1.2992100000000006,103.84359000000006',
          '1.2992500000000007,103.84359000000006',
          '1.2992800000000007,103.84359000000006',
          '1.2993000000000006,103.84359000000006',
          '1.2993200000000005,103.84359000000006',
          '1.2995100000000004,103.84358000000006',
          '1.2995700000000003,103.84358000000006',
          '1.2996800000000004,103.84357000000006',
          '1.2998300000000005,103.84355000000005',
          '1.2999100000000006,103.84354000000005',
          '1.3000300000000005,103.84352000000004',
          '1.3001600000000004,103.84349000000005',
          '1.3002700000000005,103.84346000000005',
          '1.3003700000000005,103.84343000000005',
          '1.3004300000000004,103.84341000000005',
          '1.3005100000000005,103.84338000000005',
          '1.3006000000000004,103.84334000000005',
          '1.3007400000000005,103.84327000000006',
          '1.3008600000000003,103.84321000000006',
          '1.3009100000000005,103.84318000000006',
          '1.3009600000000006,103.84315000000007',
          '1.3012100000000006,103.84297000000007',
          '1.3013700000000006,103.84283000000006',
          '1.3015100000000006,103.84268000000006',
          '1.3015800000000006,103.84260000000006',
          '1.3016500000000006,103.84252000000006',
          '1.3017700000000005,103.84238000000006',
          '1.3025600000000006,103.84142000000006',
          '1.3027400000000007,103.84120000000006',
          '1.3028000000000006,103.84113000000006',
          '1.3029500000000007,103.84096000000007',
          '1.3030300000000008,103.84088000000007',
          '1.3031400000000009,103.84078000000007',
          '1.303250000000001,103.84069000000007',
          '1.303350000000001,103.84061000000007',
          '1.303460000000001,103.84053000000007',
          '1.303570000000001,103.84046000000008',
          '1.303680000000001,103.84039000000008',
          '1.303780000000001,103.84033000000008',
          '1.303870000000001,103.84028000000008',
          '1.303980000000001,103.84023000000008',
          '1.304080000000001,103.84019000000008',
          '1.304240000000001,103.84013000000007',
          '1.3043600000000009,103.84009000000007',
          '1.304470000000001,103.84006000000008',
          '1.3045600000000008,103.84004000000007',
          '1.3046700000000009,103.84002000000007',
          '1.3047300000000008,103.84001000000006',
          '1.3048600000000008,103.83999000000006',
          '1.305010000000001,103.83997000000005',
          '1.3051400000000009,103.83996000000005',
          '1.3052800000000009,103.83996000000005',
          '1.305490000000001,103.83997000000005',
          '1.3056100000000008,103.83998000000005',
          '1.305760000000001,103.84000000000006',
          '1.305870000000001,103.84002000000007',
          '1.305970000000001,103.84004000000007',
          '1.306100000000001,103.84007000000007',
          '1.306230000000001,103.84011000000007',
          '1.306340000000001,103.84015000000007',
          '1.306470000000001,103.84020000000007',
          '1.3066000000000009,103.84026000000007',
          '1.3067000000000009,103.84031000000007',
          '1.3068300000000008,103.84038000000007',
          '1.306980000000001,103.84047000000007',
          '1.3071100000000009,103.84056000000007',
          '1.3072300000000008,103.84065000000007',
          '1.3073400000000008,103.84074000000007',
          '1.3075100000000008,103.84089000000007',
          '1.3076000000000008,103.84100000000008',
          '1.3077100000000008,103.84113000000008',
          '1.3078100000000008,103.84125000000007',
          '1.3079100000000008,103.84138000000007',
          '1.3081900000000009,103.84179000000007',
          '1.3086300000000008,103.84240000000007',
          '1.308910000000001,103.84276000000007',
          '1.3092100000000009,103.84311000000007',
          '1.309290000000001,103.84320000000007',
          '1.309360000000001,103.84328000000006',
          '1.309520000000001,103.84343000000007',
          '1.309670000000001,103.84356000000007',
          '1.309870000000001,103.84372000000006',
          '1.310000000000001,103.84382000000006',
          '1.310140000000001,103.84392000000007',
          '1.310270000000001,103.84400000000007',
          '1.3103600000000009,103.84406000000007',
          '1.3104200000000008,103.84410000000007',
          '1.310470000000001,103.84413000000006',
          '1.310520000000001,103.84416000000006',
          '1.310580000000001,103.84419000000005',
          '1.310620000000001,103.84421000000006',
          '1.3106700000000011,103.84423000000007',
          '1.3107200000000012,103.84425000000007',
          '1.3108100000000011,103.84429000000007',
          '1.310900000000001,103.84433000000007',
          '1.311020000000001,103.84438000000007',
          '1.311130000000001,103.84442000000007',
          '1.311310000000001,103.84448000000008',
          '1.311440000000001,103.84452000000007',
          '1.311540000000001,103.84455000000007',
          '1.311660000000001,103.84458000000006',
          '1.311790000000001,103.84461000000006',
          '1.3120800000000008,103.84467000000006',
          '1.3123400000000007,103.84472000000007',
          '1.3125700000000007,103.84477000000007',
          '1.3127800000000007,103.84482000000007',
          '1.3131100000000007,103.84490000000007',
          '1.3132700000000006,103.84496000000007',
          '1.3134300000000005,103.84502000000008',
          '1.3136000000000005,103.84509000000007',
          '1.3137700000000005,103.84517000000007',
          '1.3140300000000005,103.84530000000007',
          '1.3141600000000004,103.84537000000006',
          '1.3143600000000004,103.84547000000006',
          '1.3146400000000005,103.84563000000006',
          '1.3147900000000006,103.84572000000006',
          '1.3151600000000006,103.84598000000005',
          '1.3154200000000005,103.84618000000006',
          '1.3156700000000006,103.84640000000006',
          '1.3159600000000005,103.84665000000005',
          '1.3161800000000006,103.84686000000005',
          '1.3163500000000006,103.84705000000005',
          '1.3164800000000005,103.84720000000006',
          '1.3165800000000005,103.84733000000006',
          '1.3167500000000005,103.84757000000006',
          '1.3169200000000005,103.84782000000006',
          '1.3171000000000006,103.84813000000005',
          '1.3172200000000005,103.84835000000005',
          '1.3173300000000006,103.84858000000006',
          '1.3174600000000005,103.84886000000006',
          '1.3176500000000004,103.84929000000005',
          '1.3179100000000004,103.84986000000005',
          '1.3179500000000004,103.84996000000005',
          '1.3179800000000004,103.85004000000005',
          '1.3180100000000003,103.85011000000004',
          '1.3180800000000004,103.85027000000004',
          '1.3185200000000004,103.85124000000003',
          '1.3187900000000004,103.85185000000003',
          '1.3190600000000003,103.85244000000003',
          '1.3193600000000003,103.85305000000002',
          '1.3194300000000003,103.85319000000003',
          '1.3194900000000003,103.85332000000002',
          '1.3195600000000003,103.85346000000003',
          '1.3196500000000002,103.85364000000003',
          '1.3197800000000002,103.85386000000003',
          '1.3199500000000002,103.85413000000003',
          '1.3201200000000002,103.85438000000002',
          '1.3202600000000002,103.85459000000002',
          '1.3203500000000001,103.85472000000001',
          '1.3204500000000001,103.85486000000002',
          '1.32057,103.85502000000001',
          '1.32067,103.85514',
          '1.32096,103.85548',
          '1.3212,103.85574',
          '1.32157,103.85611',
          '1.3216899999999998,103.85622000000001',
          '1.3218099999999997,103.85634',
          '1.3221999999999996,103.85668',
          '1.3225599999999995,103.85698',
          '1.3228499999999994,103.85722',
          '1.3230999999999995,103.85745',
          '1.3233299999999995,103.85766',
          '1.3235999999999994,103.85790999999999',
          '1.3236999999999994,103.85801',
          '1.3238499999999995,103.85816',
          '1.3240399999999994,103.85836',
          '1.3241499999999995,103.85848',
          '1.3247099999999994,103.85909',
          '1.3250599999999995,103.85947',
          '1.3252899999999994,103.85972',
          '1.3255299999999994,103.85998',
          '1.3258699999999994,103.86035',
          '1.3260499999999995,103.86054',
          '1.3262099999999994,103.8607',
          '1.3263099999999994,103.8608',
          '1.3264699999999994,103.86095',
          '1.3265999999999993,103.86106000000001',
          '1.3268099999999994,103.86122',
          '1.3269499999999994,103.86132',
          '1.3270899999999994,103.86141',
          '1.3272699999999995,103.86152000000001',
          '1.3274099999999995,103.86160000000001',
          '1.3275699999999995,103.86169000000001',
          '1.3277299999999994,103.86177',
          '1.3279599999999994,103.86187000000001',
          '1.3281499999999993,103.86195000000001',
          '1.3283099999999992,103.86201000000001',
          '1.3284799999999992,103.86207000000002',
          '1.3286399999999992,103.86212000000002',
          '1.328799999999999,103.86216000000002',
          '1.328989999999999,103.86220000000002',
          '1.329159999999999,103.86223000000001',
          '1.329289999999999,103.86225000000002',
          '1.329439999999999,103.86227000000002',
          '1.3296599999999992,103.86229000000003',
          '1.3297899999999991,103.86230000000003',
          '1.330089999999999,103.86230000000003',
          '1.330149999999999,103.86230000000003',
          '1.3302299999999991,103.86230000000003',
          '1.3303699999999992,103.86231000000004',
          '1.3305399999999992,103.86230000000003',
          '1.330659999999999,103.86230000000003',
          '1.330899999999999,103.86229000000003',
          '1.3314899999999992,103.86227000000002',
          '1.3319799999999993,103.86225000000002',
          '1.3326899999999993,103.86221000000002',
          '1.3329299999999993,103.86219000000001',
          '1.3331699999999993,103.86218000000001',
          '1.3333399999999993,103.86218000000001',
          '1.3340699999999992,103.86215000000001',
          '1.3342299999999991,103.86214000000001',
          '1.334459999999999,103.86213000000001',
          '1.3355499999999991,103.86209000000001',
          '1.336079999999999,103.86198',
          '1.336529999999999,103.86189',
          '1.3366699999999991,103.86186000000001',
          '1.3367699999999991,103.86184',
          '1.336829999999999,103.86183',
          '1.3369099999999992,103.86182',
          '1.336999999999999,103.86180999999999',
          '1.337119999999999,103.86179999999999',
          '1.337329999999999,103.86177999999998',
          '1.337459999999999,103.86176999999998',
          '1.337679999999999,103.86174999999997',
          '1.337769999999999,103.86173999999997',
          '1.3379899999999991,103.86170999999997',
          '1.3383999999999991,103.86164999999997',
          '1.338729999999999,103.86159999999997',
          '1.338919999999999,103.86156999999997',
          '1.339149999999999,103.86152999999997',
          '1.3394099999999989,103.86147999999997',
          '1.3395699999999988,103.86144999999998',
          '1.3397399999999988,103.86141999999998',
          '1.340059999999999,103.86130999999997',
          '1.340479999999999,103.86115999999997',
          '1.340729999999999,103.86106999999997',
          '1.3409399999999991,103.86098999999997',
          '1.341069999999999,103.86093999999997',
          '1.341189999999999,103.86088999999997',
          '1.341339999999999,103.86082999999996',
          '1.3414799999999991,103.86076999999996',
          '1.341609999999999,103.86070999999995',
          '1.3417199999999991,103.86065999999995',
          '1.3418599999999992,103.86058999999996',
          '1.3421799999999993,103.86042999999997',
          '1.3424399999999992,103.86029999999997',
          '1.3427199999999992,103.86015999999996',
          '1.3428199999999992,103.86011999999997',
          '1.3428799999999992,103.86009999999996',
          '1.3429299999999993,103.86008999999996',
          '1.3429699999999993,103.86008999999996',
          '1.3430099999999994,103.86009999999996',
          '1.3430599999999995,103.86011999999997',
          '1.3430899999999995,103.86013999999997',
          '1.3431199999999994,103.86015999999998',
          '1.3431499999999994,103.86018999999997',
          '1.3431699999999993,103.86021999999997',
          '1.3431899999999992,103.86024999999997',
          '1.3432099999999991,103.86027999999996',
          '1.3432499999999992,103.86033999999997',
          '1.343269999999999,103.86036999999996',
          '1.3433199999999992,103.86042999999997',
          '1.3433699999999993,103.86047999999997',
          '1.3433999999999993,103.86051999999997',
          '1.3434299999999992,103.86055999999996',
          '1.3434699999999993,103.86063999999996',
          '1.3434899999999992,103.86067999999996',
          '1.343509999999999,103.86072999999996',
          '1.3435199999999992,103.86076999999996',
          '1.3435299999999992,103.86080999999996',
          '1.3435399999999993,103.86085999999996',
          '1.3435699999999993,103.86101999999995',
          '1.3435899999999992,103.86115999999996',
          '1.343609999999999,103.86131999999995',
          '1.3436199999999991,103.86139999999995',
          '1.3436299999999992,103.86149999999995',
          '1.3436399999999993,103.86163999999995',
          '1.3436499999999993,103.86174999999996',
          '1.3436699999999993,103.86192999999996',
          '1.3436899999999992,103.86206999999996',
          '1.343709999999999,103.86220999999996',
          '1.343729999999999,103.86232999999996',
          '1.343759999999999,103.86248999999995',
          '1.343789999999999,103.86265999999995',
          '1.343819999999999,103.86282999999995',
          '1.3438399999999988,103.86301999999995',
          '1.3438699999999988,103.86310999999995',
          '1.3438999999999988,103.86318999999995',
          '1.3440399999999988,103.86358999999995',
          '1.3441899999999989,103.86402999999994',
          '1.3442199999999989,103.86412999999995',
          '1.344259999999999,103.86426999999995',
          '1.3442899999999989,103.86437999999995',
          '1.3443199999999988,103.86450999999995',
          '1.344329999999999,103.86456999999996',
          '1.344339999999999,103.86462999999996',
          '1.344349999999999,103.86473999999997',
          '1.3443599999999991,103.86488999999997',
          '1.3443599999999991,103.86501999999997',
          '1.3443599999999991,103.86512999999998',
          '1.344349999999999,103.86526999999998',
          '1.3443299999999991,103.86543999999998',
          '1.3443099999999992,103.86554999999998',
          '1.3442699999999992,103.86571999999998',
          '1.3442299999999991,103.86585999999998',
          '1.344179999999999,103.86599999999999',
          '1.344079999999999,103.86624999999998',
          '1.343949999999999,103.86657999999998',
          '1.3438199999999991,103.86690999999999',
          '1.3436199999999991,103.86739999999999',
          '1.343509999999999,103.86765999999999',
          '1.343439999999999,103.86781999999998',
          '1.343399999999999,103.86804999999998',
          '1.343369999999999,103.86817999999998',
          '1.343319999999999,103.86831999999998',
          '1.3432699999999989,103.86843999999998',
          '1.3432199999999987,103.86855999999997',
          '1.3431699999999986,103.86867999999997',
          '1.3431199999999985,103.86879999999996',
          '1.3430699999999984,103.86893999999997',
          '1.3430299999999984,103.86906999999997',
          '1.3429999999999984,103.86917999999997',
          '1.3429799999999985,103.86926999999997',
          '1.3429699999999984,103.86934999999997',
          '1.3429599999999984,103.86943999999997',
          '1.3429599999999984,103.86950999999996',
          '1.3429599999999984,103.86959999999996',
          '1.3429699999999984,103.86971999999996',
          '1.3429799999999985,103.86978999999995',
          '1.3429899999999986,103.86983999999995',
          '1.3430099999999985,103.86991999999995',
          '1.3430299999999984,103.86998999999994',
          '1.3430499999999983,103.87004999999995',
          '1.3430799999999983,103.87012999999995',
          '1.3431099999999982,103.87020999999994',
          '1.3431799999999983,103.87037999999994',
          '1.3432299999999984,103.87041999999994',
          '1.3432799999999985,103.87045999999994',
          '1.3433199999999985,103.87048999999993',
          '1.3433899999999985,103.87053999999993',
          '1.3434899999999985,103.87054999999994',
          '1.3437699999999986,103.87058999999994',
          '1.3438999999999985,103.87060999999994',
          '1.3440799999999986,103.87063999999994',
          '1.3442499999999986,103.87066999999993',
          '1.3443999999999987,103.87069999999993',
          '1.3445299999999987,103.87072999999992',
          '1.3446799999999988,103.87076999999992',
          '1.3448099999999987,103.87080999999992',
          '1.3449499999999988,103.87085999999992',
          '1.3452399999999987,103.87096999999993',
          '1.3454299999999986,103.87104999999993',
          '1.3456799999999987,103.87115999999993',
          '1.3457999999999986,103.87120999999993',
          '1.3459999999999985,103.87129999999993',
          '1.3461199999999984,103.87135999999994',
          '1.3462199999999984,103.87140999999994',
          '1.3465799999999983,103.87158999999994',
          '1.3470699999999984,103.87182999999995',
          '1.3472799999999985,103.87192999999995',
          '1.3473799999999985,103.87197999999995',
          '1.3475099999999984,103.87204999999994',
          '1.3476999999999983,103.87214999999995',
          '1.3480099999999984,103.87231999999995',
          '1.3482499999999984,103.87245999999995',
          '1.3483099999999983,103.87249999999995',
          '1.3485299999999985,103.87264999999995',
          '1.3486799999999985,103.87275999999996',
          '1.3488499999999986,103.87288999999996',
          '1.3490799999999985,103.87307999999996',
          '1.3490959999999985,103.87309299999995',
          '1.349096,103.873093',
          '1.3493300000000001,103.87328',
          '1.3495000000000001,103.87342',
          '1.3497700000000001,103.87362',
          '1.34996,103.87377000000001',
          '1.35006,103.87386000000001',
          '1.3502800000000001,103.87407',
          '1.3506000000000002,103.87440000000001',
          '1.3511700000000002,103.87501',
          '1.3513900000000003,103.87524',
          '1.3516100000000004,103.87546',
          '1.3518200000000005,103.87566000000001',
          '1.3519600000000005,103.87579000000001',
          '1.3520800000000004,103.87590000000002',
          '1.3522300000000005,103.87604000000002',
          '1.3523600000000005,103.87616000000001',
          '1.3525000000000005,103.87629000000001',
          '1.3525300000000005,103.87632',
          '1.3526500000000004,103.87643000000001',
          '1.3528000000000004,103.87657000000002',
          '1.3530000000000004,103.87677000000002',
          '1.3530700000000004,103.87685000000002',
          '1.3532100000000005,103.87701000000001',
          '1.3533800000000005,103.87721000000002',
          '1.3534500000000005,103.87729000000002',
          '1.3535600000000005,103.87742000000001',
          '1.3538800000000006,103.87780000000002',
          '1.3540900000000007,103.87806000000002',
          '1.3542500000000006,103.87831000000001',
          '1.3543700000000005,103.87849000000001',
          '1.3544900000000004,103.87865000000001',
          '1.3545700000000005,103.87879000000001',
          '1.3546900000000004,103.87895',
          '1.3547700000000005,103.87905',
          '1.3548600000000004,103.87916000000001',
          '1.3549600000000004,103.87928000000001',
          '1.3551300000000004,103.87947000000001',
          '1.3554500000000005,103.87982000000001',
          '1.3556700000000006,103.88005000000001',
          '1.3558100000000006,103.88020000000002',
          '1.3559400000000006,103.88034000000002',
          '1.3560900000000007,103.88051000000002',
          '1.3562000000000007,103.88064000000001',
          '1.3562900000000007,103.88075000000002',
          '1.3563800000000006,103.88086000000003',
          '1.3565000000000005,103.88100000000003',
          '1.3566300000000004,103.88115000000003',
          '1.3567700000000005,103.88132000000003',
          '1.3569600000000004,103.88156000000004',
          '1.3570400000000005,103.88166000000004',
          '1.3571400000000005,103.88178000000003',
          '1.3572400000000004,103.88190000000003',
          '1.3573600000000003,103.88203000000003',
          '1.3576900000000003,103.88238000000003',
          '1.3579000000000003,103.88259000000002',
          '1.3580800000000004,103.88278000000003',
          '1.3581900000000005,103.88290000000002',
          '1.3583900000000004,103.88313000000002',
          '1.3584900000000004,103.88325000000002',
          '1.3585200000000004,103.88328000000001',
          '1.3594600000000003,103.88423000000002',
          '1.3596400000000004,103.88441000000002',
          '1.3599700000000003,103.88477000000002',
          '1.3601100000000004,103.88492000000002',
          '1.3603100000000004,103.88515000000002',
          '1.3605200000000004,103.88539000000003',
          '1.3605900000000004,103.88547000000003',
          '1.3607500000000003,103.88565000000003',
          '1.3608000000000005,103.88571000000003',
          '1.3611600000000004,103.88612000000003',
          '1.3614800000000005,103.88646000000003',
          '1.3616900000000005,103.88668000000003',
          '1.3617800000000004,103.88678000000003',
          '1.3618800000000004,103.88688000000003',
          '1.3621900000000005,103.88721000000004',
          '1.3628600000000004,103.88792000000004',
          '1.3630200000000003,103.88809000000003',
          '1.3635700000000004,103.88866000000003',
          '1.3644400000000003,103.88956000000003',
          '1.3652800000000003,103.89045000000003',
          '1.3654600000000003,103.89064000000003',
          '1.3656600000000003,103.89086000000003',
          '1.3657400000000004,103.89094000000003',
          '1.3659500000000004,103.89115000000002',
          '1.3665500000000004,103.89178000000003',
          '1.3666700000000003,103.89191000000002',
          '1.3668600000000002,103.89210000000003',
          '1.3669200000000001,103.89216000000003',
          '1.3669900000000001,103.89223000000003',
          '1.3671200000000001,103.89237000000003',
          '1.3672300000000002,103.89249000000002',
          '1.3672900000000001,103.89255000000003',
          '1.3674600000000001,103.89272000000003',
          '1.36783,103.89310000000003',
          '1.3678800000000002,103.89315000000003',
          '1.3687100000000003,103.89399000000003',
          '1.3689500000000003,103.89423000000004',
          '1.3690900000000004,103.89437000000004',
          '1.3692000000000004,103.89441000000004',
          '1.3692700000000004,103.89442000000004',
          '1.3693600000000004,103.89441000000004',
          '1.3694700000000004,103.89439000000003',
          '1.3695600000000003,103.89432000000004',
          '1.3696600000000003,103.89423000000004',
          '1.3697600000000003,103.89415000000004',
          '1.3698600000000003,103.89408000000005',
          '1.3699700000000004,103.89402000000004',
          '1.3700900000000003,103.89399000000004',
          '1.3701900000000002,103.89398000000004',
          '1.3703200000000002,103.89397000000004',
          '1.3704600000000002,103.89396000000004',
          '1.3705900000000002,103.89391000000003',
          '1.3707200000000002,103.89381000000003',
          '1.3708600000000002,103.89368000000003',
          '1.3708900000000002,103.89365000000004',
          '1.3710400000000003,103.89350000000003',
          '1.3711700000000002,103.89336000000003',
          '1.3712400000000002,103.89327000000003',
          '1.3713000000000002,103.89312000000002',
          '1.37132,103.89303000000002',
          '1.3713300000000002,103.89294000000002',
          '1.3713300000000002,103.89279000000002',
          '1.3713300000000002,103.89275000000002',
          '1.3713300000000002,103.89260000000002',
          '1.37132,103.89240000000001',
          '1.37131,103.89213000000001',
          '1.37103,103.89214000000001',
          '1.371026,103.89234700000002',
          '1.371026,103.892347',
          '1.3710200000000001,103.89275',
          '1.3710200000000001,103.89288',
          '1.3710000000000002,103.89291',
          '1.3709800000000003,103.89292',
          '1.3708000000000002,103.89292',
          '1.3708000000000002,103.89273',
          '1.3708400000000003,103.89201',
          '1.3710300000000002,103.89202',
          '1.3710300000000002,103.89214',
          '1.3713100000000003,103.89213',
          '1.3714000000000002,103.89213',
          '1.3714100000000002,103.89222',
          '1.3714100000000002,103.89263',
          '1.3714100000000002,103.89278999999999',
          '1.3714100000000002,103.89294999999998',
          '1.3713800000000003,103.89312999999999',
          '1.3713100000000003,103.89331999999999',
          '1.3712300000000002,103.89341999999999',
          '1.3709400000000003,103.89371',
          '1.3709900000000004,103.89376',
          '1.3711000000000004,103.89387',
          '1.3711400000000005,103.89386',
          '1.3711600000000004,103.89386',
          '1.3712200000000003,103.89388000000001',
          '1.3712200000000003,103.89393000000001',
          '1.3712200000000003,103.89394000000001',
          '1.3712000000000004,103.89397000000001',
          '1.3711600000000004,103.89399000000002',
          '1.3711100000000003,103.89399000000002',
          '1.3710700000000002,103.89402000000001',
          '1.3710100000000003,103.89409',
          '1.3709900000000004,103.89411000000001',
          '1.3709900000000004,103.89415000000001',
          '1.3710800000000003,103.89426000000002',
          '1.3711000000000002,103.89429000000001',
          '1.3710900000000001,103.89436',
          '1.3711300000000002,103.89441000000001',
          '1.3712300000000002,103.89452000000001',
          '1.37125,103.89454000000002',
          '1.3712900000000001,103.89459000000002',
          '1.37149,103.89476000000002',
          '1.37151,103.89475000000002',
          '1.37153,103.89473000000001',
          '1.3716599999999999,103.89461000000001',
          '1.3718599999999999,103.89444000000002',
          '1.3719799999999998,103.89435000000002',
          '1.3720899999999998,103.89429000000001',
          '1.3722199999999998,103.89425000000001',
          '1.3724099999999997,103.89424000000001',
          '1.3727399999999996,103.89424000000001',
          '1.3729299999999995,103.89425000000001',
          '1.3729499999999994,103.89425000000001',
          '1.3729999999999996,103.89425000000001',
          '1.3732299999999995,103.89424000000001',
          '1.3732999999999995,103.89424000000001',
          '1.3735399999999995,103.89425000000001',
          '1.3737399999999995,103.89427000000002',
          '1.3738599999999994,103.89431000000002',
          '1.3739299999999994,103.89435000000002',
          '1.3744499999999995,103.89479000000001',
          '1.3746399999999994,103.89495000000001',
          '1.3752499999999994,103.89546000000001',
          '1.3755599999999994,103.89569000000002',
          '1.3757399999999995,103.89581000000001',
          '1.3759399999999995,103.89594000000001',
          '1.3762399999999995,103.89615',
          '1.3762899999999996,103.89618',
          '1.3763799999999995,103.89623',
          '1.3764799999999995,103.89628',
          '1.3765599999999996,103.89632',
          '1.3766999999999996,103.8964',
          '1.3767999999999996,103.89646',
          '1.3768599999999995,103.8965',
          '1.3769599999999995,103.89656000000001',
          '1.3770399999999996,103.8966',
          '1.3770799999999996,103.89662000000001',
          '1.3771599999999997,103.89665000000001',
          '1.3772299999999997,103.89667000000001',
          '1.3772999999999997,103.89669000000002',
          '1.3773599999999997,103.89670000000002',
          '1.3774399999999998,103.89671000000003',
          '1.3775899999999999,103.89672000000003',
          '1.3778199999999998,103.89673000000003',
          '1.3782699999999999,103.89674000000004',
          '1.37835,103.89674000000004',
          '1.37856,103.89675000000004',
          '1.37869,103.89676000000004',
          '1.37878,103.89677000000005',
          '1.37902,103.89680000000004',
          '1.37923,103.89682000000005',
          '1.3793199999999999,103.89683000000005',
          '1.3794799999999998,103.89685000000006',
          '1.3796899999999999,103.89689000000006',
          '1.3799799999999998,103.89694000000006',
          '1.3803199999999998,103.89700000000006',
          '1.3804599999999998,103.89703000000006',
          '1.3805499999999997,103.89705000000006',
          '1.3809099999999996,103.89714000000006',
          '1.3811099999999996,103.89719000000007',
          '1.3812699999999996,103.89723000000006',
          '1.3814199999999996,103.89727000000006',
          '1.3815299999999997,103.89730000000006',
          '1.3816299999999997,103.89733000000005',
          '1.3817499999999996,103.89737000000005',
          '1.3818599999999996,103.89741000000005',
          '1.3819099999999997,103.89743000000006',
          '1.3819799999999998,103.89746000000005',
          '1.3820199999999998,103.89748000000006',
          '1.3820899999999998,103.89752000000006',
          '1.38214,103.89755000000005',
          '1.3821999999999999,103.89759000000005',
          '1.38228,103.89765000000006',
          '1.38234,103.89770000000006',
          '1.3823999999999999,103.89775000000006',
          '1.3824899999999998,103.89783000000006',
          '1.3825599999999998,103.89790000000005',
          '1.3826299999999998,103.89798000000005',
          '1.3827499999999997,103.89813000000005',
          '1.3829599999999997,103.89840000000005',
          '1.3829999999999998,103.89845000000005',
          '1.3830399999999998,103.89850000000006',
          '1.3831799999999999,103.89868000000006',
          '1.3834099999999998,103.89899000000005',
          '1.3835499999999998,103.89918000000006',
          '1.38363,103.89929000000006',
          '1.3837599999999999,103.89945000000006',
          '1.3838899999999998,103.89960000000006',
          '1.3840499999999998,103.89977000000006',
          '1.3842399999999997,103.89997000000007',
          '1.3844299999999996,103.90016000000007',
          '1.3846499999999997,103.90038000000007',
          '1.3850799999999996,103.90081000000006',
          '1.3851999999999995,103.90093000000006',
          '1.3853099999999996,103.90103000000006',
          '1.3853799999999996,103.90109000000007',
          '1.3854699999999995,103.90116000000006',
          '1.3855499999999996,103.90122000000007',
          '1.3856399999999995,103.90128000000007',
          '1.3857099999999996,103.90132000000007',
          '1.3858599999999996,103.90140000000007',
          '1.3859999999999997,103.90147000000006',
          '1.3862499999999998,103.90157000000006',
          '1.3863099999999997,103.90159000000007',
          '1.3863799999999997,103.90161000000008',
          '1.3865099999999997,103.90164000000007',
          '1.3867699999999996,103.90168000000007',
          '1.3870999999999996,103.90172000000007',
          '1.3873799999999996,103.90175000000006',
          '1.3876399999999995,103.90177000000007',
          '1.3878399999999995,103.90178000000007',
          '1.3882699999999994,103.90180000000008',
          '1.3887199999999995,103.90182000000009',
          '1.3888599999999995,103.90182000000009',
          '1.3891699999999996,103.90181000000008',
          '1.3892899999999995,103.90180000000008',
          '1.3894699999999995,103.90178000000007',
          '1.3896399999999995,103.90175000000008',
          '1.3904899999999996,103.90158000000008',
          '1.3906799999999995,103.90154000000008',
          '1.3907599999999996,103.90152000000008',
          '1.3909199999999995,103.90149000000008',
          '1.3910099999999994,103.90147000000007',
          '1.3910999999999993,103.90145000000007',
          '1.3913099999999994,103.90140000000007',
          '1.3915199999999994,103.90136000000007',
          '1.3919899999999994,103.90126000000006',
          '1.3924399999999995,103.90118000000007',
          '1.3925599999999994,103.90116000000006',
          '1.3926199999999993,103.90115000000006',
          '1.3926899999999993,103.90114000000005',
          '1.3927799999999992,103.90113000000005',
          '1.3929899999999993,103.90112000000005',
          '1.3931599999999993,103.90112000000005',
          '1.3932899999999993,103.90113000000005',
          '1.3933899999999992,103.90114000000005',
          '1.3935199999999992,103.90116000000006',
          '1.3935899999999992,103.90117000000006',
          '1.3936799999999991,103.90119000000007',
          '1.3937599999999992,103.90121000000008',
          '1.3938299999999992,103.90123000000008',
          '1.3938899999999992,103.90125000000009',
          '1.3940399999999993,103.9013100000001',
          '1.3941499999999993,103.9013600000001',
          '1.3941899999999994,103.9013800000001',
          '1.3942599999999994,103.9014200000001',
          '1.3943299999999994,103.9014600000001',
          '1.3943999999999994,103.9015100000001',
          '1.3944999999999994,103.9015900000001',
          '1.3946099999999995,103.9016900000001',
          '1.3947199999999995,103.90180000000011',
          '1.3950699999999996,103.9021400000001',
          '1.3951799999999996,103.90225000000011',
          '1.3954199999999997,103.90249000000011',
          '1.3955999999999997,103.90266000000011',
          '1.3957299999999997,103.9027800000001',
          '1.3958899999999996,103.90292000000011',
          '1.3960499999999996,103.9030500000001',
          '1.3962899999999996,103.9032100000001',
          '1.3965199999999995,103.9033500000001',
          '1.3966799999999995,103.9034300000001',
          '1.3968399999999994,103.9035100000001',
          '1.3969799999999994,103.90358000000009',
          '1.3971299999999995,103.90365000000008',
          '1.3972999999999995,103.90372000000008',
          '1.3979699999999995,103.90404000000008',
          '1.3980799999999995,103.90410000000008',
          '1.3981999999999994,103.90416000000009',
          '1.3985899999999993,103.9043500000001',
          '1.3988499999999993,103.90447000000009',
          '1.3990899999999993,103.9045800000001',
          '1.3994399999999994,103.90474000000009',
          '1.3995699999999993,103.9048000000001',
          '1.3996599999999992,103.90484000000009',
          '1.3998299999999992,103.90491000000009',
          '1.3998799999999993,103.90493000000009',
          '1.3999899999999994,103.9049800000001',
          '1.4000599999999994,103.90501000000009',
          '1.4002599999999994,103.9051100000001',
          '1.4007599999999993,103.90533000000009',
          '1.4009999999999994,103.90542000000009',
          '1.4013599999999993,103.9055700000001',
          '1.4017799999999994,103.9057200000001',
          '1.4020399999999993,103.90583000000011',
          '1.4026599999999994,103.90610000000011',
          '1.4027699999999994,103.9060900000001',
          '1.4028399999999994,103.9060800000001',
          '1.4029099999999994,103.9060600000001',
          '1.4030399999999994,103.9060100000001',
          '1.4035199999999994,103.90524000000009',
          '1.4037199999999994,103.9048900000001',
          '1.4038799999999994,103.90460000000009',
          '1.4039299999999995,103.90452000000009',
          '1.4041699999999995,103.90405000000008',
          '1.4042899999999994,103.90383000000008',
          '1.4043499999999993,103.90372000000008',
          '1.4044499999999993,103.90355000000008',
          '1.4045399999999992,103.90340000000008',
          '1.4048879999999992,103.90280200000008',
        ],
      },
    ],
  },
  stops: [
    {
      direction: 1,
      stop: [
        {
          stopSequence: '1',
          stopCode: 'OS1',
          index: 0,
        },
        {
          stopSequence: '2',
          stopCode: 'OS2',
          index: 255,
        },
        {
          stopSequence: '3',
          stopCode: 'OS3',
          index: 712,
        },
        {
          stopSequence: '4',
          stopCode: 'OS4',
          index: 884,
        },
        {
          stopSequence: '5',
          stopCode: 'OS5',
          index: 1156,
        },
      ],
    },
    {
      direction: 2,
      stop: [
        {
          stopSequence: '1',
          stopCode: 'OS5',
          index: 0,
        },
        {
          stopSequence: '2',
          stopCode: 'OS4',
          index: 272,
        },
        {
          stopSequence: '3',
          stopCode: 'OS3',
          index: 444,
        },
        {
          stopSequence: '4',
          stopCode: 'OS2',
          index: 900,
        },
        {
          stopSequence: '5',
          stopCode: 'OS1',
          index: 1156,
        },
      ],
    },
  ],
  color: '#FFA500',
  colorAlt: '#F08000',
  colorAlt2: '#FF7518',
  busCode: 'O',
  busCount: 5,
  busId: 7003,
  busIdStr: '7003',
  timeStop: 5,
  busArr: ['SG1769Y', 'SG1778X', 'SG1784C', 'SG1759B', 'SG1768A'],
};

export const routeData = {
  '7001': redRoute,
  '7002': greenRoute,
  '7003': orangeRoute,
};

export const stopNames = {
  GS1: 'Buckingham Blvd',
  GS2: 'Stratford',
  GS3: 'Windsor Way',
  GS4: 'Oxford Overpass',
  GS5: 'Cambridge Cresent',
  GS6: 'York Yard',
  GS7: 'Leeds Lane',
  GS8: 'Cantebury Court',
  GS9: 'Bristol Bridge',
  GS10: 'Liverpool Landing',
  OS1: 'Town Hall',
  OS2: 'Library Lane',
  OS3: 'Stadium',
  OS4: 'Docklands Pier',
  OS5: 'Manchester Meadows',
  RS1: 'Kingston Quay',
  RS2: 'Chester Chase',
  RS3: 'Greenwich Grove',
  RS4: 'Hastings Harbor',
  RS5: 'Preston Plaza',
  RS6: 'Victoria Vault',
  RS7: 'Lancaster Loop',
};
