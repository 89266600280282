import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { RouteReuseStrategy, DetachedRouteHandle, Router } from '@angular/router';
import { HOME_PATH } from '../shared/others/constant';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VoipService } from '../shared/services/voip.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../shared/services/common.service';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isLoader: boolean = false;
  loginResp: string = '';
  loginBg: string = '';
  
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    // password: new FormControl('')
  });
  error: string;

  constructor(private authService: AuthService,
    private cachedRoute: RouteReuseStrategy, private router: Router,
    private auth: AuthService,
    private _voipService: VoipService,
    private _snackBar: MatSnackBar,
    private _commonService: CommonService) { }

  ngOnInit() {
    if(this.auth.isAuthenticated()) {
      this.router.navigate([HOME_PATH]);
      return;
    }

    this.loginBg = environment.image.login;
    console.log('init login');
    let c: any = this.cachedRoute;
    
    c.storedRouteHandles.forEach((v: DetachedRouteHandle, k, m) => {
      (v as any).componentRef.destroy();
    });

    c.storedRouteHandles.clear();
  }

  onSubmit() {
    this.isLoader = true;

    // this.authService.login(this.form.value)
    //   .subscribe(
    //     data => {
    //       this.isLoader = false;
          var dataBody = {
            "userName" : this.form.value.email
          }
          var urlAction = 'users/v1/sendResetPasswordLink';
          this._commonService.commonPostAction(urlAction, dataBody).subscribe(resp => {
            
              this.isLoader = false;
              // let isErrorExist = resp.response.includes("Reason");

              this.loginResp = resp.response;
              this.form.reset();
              
              // var end = moment(account.lastActiveDateTime);
              // var duration = moment.duration(end.diff(moment()));
              // var seconds = Math.abs(duration.asSeconds());

              // if(account.loggedIn && seconds < environment.idleTimer) {
              //   this.loginResp = 'Account is logged in';
              //   localStorage.clear();
              // }
              // else {
              //     this.validLogin(data);
              //     this.isLoader = true;
        
              //     this._voipService.getAvailableSipNumber().subscribe(
              //       resp => {
              //         let sipNumber = resp.sipId === '-' ? null : resp.sipId;
              //         localStorage.setItem('sipNumber', sipNumber);
              //       }
              //     )
              // }
          },
          error => {
              this.isLoader = false;
              this._snackBar.open(error.error.errorMessage, null, {
                duration: 2000,
              });
              console.log(error);
          });


      //   },
      //   error => {
      //     this.isLoader = false;
      //     this.loginResp = error;
      // });
  }

  
  // validLogin(data) {
  //   localStorage.setItem('refresh_token', data['refresh_token']);
  //   localStorage.setItem('scope', data['scope']);
  //   localStorage.setItem('displayname', data['displayname']);
  //   localStorage.setItem('password', this.form.value.password);
  //   localStorage.setItem('days_before_password_expiry', data.days_before_password_expiry);

  //   this._commonService.createAuthAcvitiy();
  //   this.getFeatureMasterData(data);
    
  //   if(data.days_before_password_expiry < 7) {
  //     this.router.navigate(['/account/password-expiry/1']);
  //   }
  //   else {
  //     this.router.navigate([HOME_PATH]);
  //   }
  // }

  getFeatureMasterData(data) {
    
    var urlAction = 'users/v1/getFeatureMasterData';
    this._commonService.commonPostAction(urlAction, '').subscribe(feature => {
        localStorage.setItem('featureList', JSON.stringify(feature.featureList));
        if(data['has_access']) {
            let hasAccess = [];
            data['has_access'].forEach(element => {
                let featureData = feature.featureList.find(x => x.id === element);
                hasAccess.push(featureData);
            });
            localStorage.setItem('hasAccess', JSON.stringify(hasAccess));
            
            this.auth.initMenu();
        }
    });
  }
}