<div id="role-mgt-group">
  <!-- <div>
    <h3 class="title-header">Role Management<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div> -->
  <ng-container *ngIf="isHasAccess; else noAccess">
    <div
      class="header-group"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div>
          <span class="title-header">Role Access Management</span>
          <sup
            ><i
              *ngIf="!isNewWindow"
              class="fa fa-window-restore open-window"
              (click)="openWindow()"></i
          ></sup>
        </div>
      </div>
      <div *ngIf="dataPage.roleList.length > 0">
        <button
          mat-raised-button
          color="primary"
          type="button"
          *ngIf="!isEdit; else editMode"
          (click)="changeEditMode(true)">
          Edit
        </button>
      </div>
      <ng-template #editMode>
        <div fxLayout="row" fxLayoutGap="4px">
          <button
            mat-stroked-button
            color="warn"
            type="button"
            (click)="cancelEdit()"
            [disabled]="isLoading">
            Cancel
          </button>
          <button
            mat-raised-button
            color="white"
            type="button"
            (click)="editSave()"
            [disabled]="isLoading">
            Save
          </button>
        </div>
      </ng-template>
      <!-- <button mat-raised-button type="button" (click)="onAddRole()" class="float-right">Add Role</button> -->
    </div>

    <div
      class="feature-table"
      *ngIf="dataPage.roleList.length > 0 && dataPage.pageList.length > 0"
      fxLayout="column">
      <div class="feature-table-scroll custom-page-scroll">
        <div class="feature-header" fxLayout="row">
          <div class="table-item" fxFlex="0 0 240px"></div>
          <div
            class="table-item table-item-content"
            *ngFor="let role of dataPage.roleList"
            fxFlex="1 0 300px"
            fxLayout="column">
            <div class="role-title">{{ role.roleName | titlecase }}</div>
            <div
              class="permission-cell"
              fxLayout="row"
              fxLayoutAlign="center space-between">
              <div class="type" fxFlex>Read</div>
              <div class="type" fxFlex>Write</div>
            </div>
          </div>
        </div>
        <div class="feature-content" fxLayout="column">
          <div
            class="feature-content-row"
            *ngFor="
              let page of dataPage.permissionList;
              let first = first;
              let i = index
            "
            fxLayout="row">
            <div class="table-item table-item-name" fxFlex="0 0 240px">
              <div class="feature-number">{{ i + 1 }}</div>
              <b>{{ page.name }}</b>
            </div>
            <div
              class="table-item permission-cell"
              *ngFor="let permissions of page.permissions"
              fxFlex="1 0 300px"
              fxLayout="row"
              fxLayoutAlign="center space-between">
              <div class="type" fxFlex>
                <span *ngIf="!isEdit && permissions.checked" class="checkmark"
                  ><mat-icon>check_small</mat-icon></span
                >
                <mat-checkbox
                  [checked]="permissions.checked"
                  [(ngModel)]="permissions.checked"
                  [ngClass]="{ hidden: !isEdit }"
                  [disabled]="permissions.roleName === 'admin'"></mat-checkbox>
              </div>
              <div
                class="type"
                fxFlex
                [ngClass]="{ edit: isEdit, disabled: isEdit }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <table border="0" class="role-table" *ngIf="false">
      <!-- (psYReachEnd)="onYReachEnd($event)" -->
      <perfect-scrollbar #chatPS [config]="config" [scrollIndicators]="false">
        <!-- <ng-container *ngIf="(selectedServiceData | json) !== '{}'"> -->
        <thead>
          <tr>
            <th>
              Role
              <!-- <mat-form-field appearance="outline">
                  <mat-label>Role</mat-label>
                  <input matInput (keyup)="onFilter($event.target.value)" placeholder="Filter">
                </mat-form-field> -->
            </th>
            <th>Actions</th>
            <th
              class="header"
              *ngFor="
                let page of dataPage.pageList;
                let first = first;
                let i = index
              ">
              <span class="stop-count">{{ i + 1 }}</span>
              <div>{{ page.name }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- <ng-container *ngFor="let direction of roleList"> -->
          <tr *ngFor="let role of dataPage.roleList">
            <td>
              <div fxLayout="row" fxLayoutAlign="space-around center">
                <div>{{ role.roleName | titlecase }}</div>
                <!-- <div *ngIf="role.isEdit" fxLayout="row" fxLayoutAlign="end center" >
                      <mat-icon class="pointer" (click)="onEdit(role, 'cancel')">cancel</mat-icon>
                      <mat-icon class="pointer" (click)="onSave(role)">save</mat-icon>
                    </div>
                    <ng-container *ngIf="!role.isEdit"> 
                      <mat-icon class="pointer" (click)="onEdit(role)">edit</mat-icon>
                    </ng-container> -->
              </div>
            </td>

            <td>
              <div fxLayout="row" fxLayoutAlign="space-around center">
                <div
                  *ngIf="role.isEdit"
                  fxLayout="row"
                  fxLayoutAlign="end center">
                  <mat-icon class="pointer" (click)="onEdit(role, 'cancel')"
                    >cancel</mat-icon
                  >
                  <mat-icon class="pointer" (click)="onSave(role)"
                    >save</mat-icon
                  >
                </div>
                <ng-container *ngIf="!role.isEdit">
                  <mat-icon class="pointer" (click)="onEdit(role)"
                    >edit</mat-icon
                  >
                </ng-container>
              </div>
            </td>

            <td class="td-body" *ngFor="let page of role.pages">
              <!-- <td *ngFor="let page of pageList; let i = index"> -->
              <!-- {{i+1}} {{role.roleName}} -->

              <!-- <ng-container *ngIf="page.name"> -->
              <mat-checkbox
                [disabled]="!role.isEdit"
                [checked]="page.isChecked ? true : false"
                [(ngModel)]="page.isChecked"></mat-checkbox>
              <!-- </ng-container> -->

              <!-- <ng-container *ngIf="role.pages[i]">
                    <ng-container *ngIf="role.pages[i].pageId === page.pageId; else elseRoles">
                      {{role.pages[i].pageName}}
                    </ng-container>

                    <ng-container #elseRoles>
                      cas
                    </ng-container>

                  </ng-container> -->

              <!-- <ng-container *ngIf="!role.pages[i]">
                    cas
                  </ng-container> -->
            </td>
          </tr>
          <!-- </ng-container> -->
        </tbody>
        <!-- </ng-container> -->
      </perfect-scrollbar>
    </table>
  </ng-container>

  <ng-template #noAccess>
    <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
      <h3>{{ rightsError }}</h3>
    </div>
  </ng-template>
</div>
