
<div class="masterdata-driver-group">
  <div>
    <h3 class="title-header">Drivers List<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div>

  <div>
    <div class="text-left">
      <mat-form-field floatLabel="always">
        <input matInput (keyup)="filter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <!-- <button mat-raised-button (click)="exporter.exportTable('xlsx')">Export</button> -->
      <!-- <button class="pull-right" mat-raised-button type="button" (click)="add()">Add</button> -->
    </div>
      
  </div>

  <perfect-scrollbar #chatPS class="ps-drivers-list" [config]="config">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="driverId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver ID</th>
        <td mat-cell *matCellDef="let element"> {{element.driverId}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name | titlecase }} </td>
      </ng-container>

      <ng-container matColumnDef="licenseNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>License Number</th>
        <td mat-cell *matCellDef="let element"> {{element.licenseNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="phone1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone 1</th>
        <td mat-cell *matCellDef="let element"> {{element.phone1}} </td>
      </ng-container>

      <ng-container matColumnDef="phone2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone 2</th>
        <td mat-cell *matCellDef="let element"> {{element.phone2}} </td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
        <td mat-cell *matCellDef="let element"> {{element.company}} </td>
      </ng-container>

      <ng-container matColumnDef="depot">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Depot</th>
        <td mat-cell *matCellDef="let element"> {{element.depot}} </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </perfect-scrollbar>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>