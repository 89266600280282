import { Routes, RouterModule } from '@angular/router';
import { NgModule, Component } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/account/home/home.component';
import { AccountBaseComponent } from './component/account/account-base/account-base.component';
import { AuthGuard } from './component/shared/services/auth-guard.service';
import { SettingsComponent } from './component/account/settings/settings.component';
import { SettingsLadderComponent } from './component/account/settings/settings-ladder/settings-ladder.component';
import { SettingsMapComponent } from './component/account/settings/settings-map/settings-map.component';
import { DataManagementComponent } from './component/account/route-management/data-management/data-management.component';
import { PersonnelManagementComponent } from './component/account/personnel-management/personnel-management.component';
import { PmDriverComponent } from './component/account/personnel-management/pm-driver/pm-driver.component';
import { PmControllerComponent } from './component/account/personnel-management/pm-controller/pm-controller.component';
import { VehicleManagementComponent } from './component/account/vehicle-management/vehicle-management.component';
import { VmDriverComponent } from './component/account/vehicle-management/vm-driver/vm-driver.component';
import { PagerComponent } from './component/account/personnel-management/pager/pager.component';
import { EventsComponent } from './component/account/events/events.component';
import { KpiComponent } from './component/account/kpi/kpi.component';
import { ReportsComponent } from './component/account/reports/reports.component';
import { DispatcherComponent } from './component/account/dispatcher/dispatcher.component';
import { TrafficEventsComponent } from './component/account/traffic-events/traffic-events.component';
import { ScheduleComponent } from './component/account/schedule/schedule.component';
import { BusEventReportComponent } from './component/account/reports/bus-event-report/bus-event-report.component';
import { VehicleDeviceComponent } from './component/account/vehicle-management/vehicle-device/vehicle-device.component';
import { PmDriverMatComponent } from './component/account/personnel-management/pm-driver-mat/pm-driver-mat.component';
import { IncidentManagementComponent } from './component/account/incident-management/incident-management.component';
import { UserManagementComponent } from './component/account/user-management/user-management.component';
import { DeviceMonitorComponent } from './component/account/vehicle-management/device-monitor/device-monitor.component';
import { MessageComponent } from './component/account/message/message.component';
import { StopIntervalReportComponent } from './component/account/reports/stop-interval-report/stop-interval-report.component';
import { VehicleListComponent } from './component/account/vehicle-management/vehicle-list/vehicle-list.component';
import { RouteManagementComponent } from './component/account/route-management/route-management.component';
import { SegmentComponent } from './component/account/route-management/segment/segment.component';
import { StopManagementComponent } from './component/account/stop-management/stop-management.component';
import { SearchStopComponent } from './component/account/stop-management/search-stop/search-stop.component';
import { ServiceManagementComponent } from './component/account/service-management/service-management.component';
import { CreateServiceComponent } from './component/account/service-management/create-service/create-service.component';
import { ScheduleManagementComponent } from './component/account/schedule-management/schedule-management.component';
import { ServiceScheduleComponent } from './component/account/schedule-management/service-schedule/service-schedule.component';
import { AccountManagementComponent } from './component/account/account-management/account-management.component';
import { GroupAccountComponent } from './component/account/account-management/group-account/group-account.component';
import { OrganizationalDataComponent } from './component/account/master-data/organizational-data/organizational-data.component';
import { MasterDataComponent } from './component/account/master-data/master-data.component';
import { MasterDataVehiclesComponent } from './component/account/master-data/vehicle-list/vehicle-list.component';
import { MasterDataDriversComponent } from './component/account/master-data/driver-list/driver-list.component';
import { AlarmManagementComponent } from './component/account/alarm-management/alarm-management.component';
import { DutyRosterComponent } from './component/account/duty-roster/duty-roster.component';
import { TripCauseCodeAnalysisComponent } from './component/account/reports/trip-cause-code-analysis/trip-cause-code-analysis.component';
import { DispatcherAssignComponent } from './component/account/dispatcher-assign/dispatcher-assign.component';
import { TimeTableComponent } from './component/account/time-table/time-table.component';
import { MultiLadderComponent } from './component/account/multi-ladder/multi-ladder.component';
import { PlaybackComponent } from './component/account/playback/playback.component';
import { Home2Component } from './component/account/home2/home2.component';
import { RoleManagementComponent } from './component/account/role-management/role-management.component';
import { ResponsibilityComponent } from './component/account/account-management/responsibility/responsibility.component';
import { DashboardComponent } from './component/account/dashboard/dashboard.component';
import { BusScheduleDataManagementComponent } from './component/account/bus-schedule-data-management/bus-schedule-data-management.component';
import { BusScheduleTableComponent } from './component/account/bus-schedule-data-management/bus-schedule-table/bus-schedule-table.component';
import { PasswordExpirationComponent } from './component/account/password-expiration/password-expiration.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { CommsComponent } from './component/comms/comms.component';
import { PrivateCallComponent } from './component/private-call/private-call.component';
import { MockBusComponent } from './component/test-page/mock-bus/mock-bus.component';
import { PidsComponent } from './component/test-page/pids/pids.component';
import { DutyRosteringComponent } from './component/account/driver-management/duty-rostering/duty-rostering.component';
import { DriverManagementComponent } from './component/account/driver-management/driver-management.component';
import { DriverListComponent } from './component/account/driver-management/driver-list/driver-list.component';
import { MockMapComponent } from './component/test-page/mock-map/mock-map.component';
import { MockTimetableComponent } from './component/test-page/mock-timetable/mock-timetable.component';
import { DemoRouteManagementComponent } from './component/test-page/demo-route-management/demo-route-management.component';
import { DemoStopManagementComponent } from './component/test-page/demo-stop-management/demo-stop-management.component';
// import { AuthGuard } from "./auth/auth-guard.service";

const appRoute: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, title: 'NGBMS' },
  {
    path: 'account',
    canActivateChild: [AuthGuard],
    component: AccountBaseComponent,
    title: 'NGBMS',
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'home2', component: Home2Component },
      { path: 'kpi/:id', component: KpiComponent },
      { path: 'events/:id', component: EventsComponent },
      { path: 'traffic-events/:id', component: TrafficEventsComponent },
      {
        path: 'reports',
        component: ReportsComponent,
        children: [
          { path: '', redirectTo: 'schedule-report', pathMatch: 'full' },
          {
            path: 'schedule-deviation/:id',
            component: BusEventReportComponent,
          },
          { path: 'interval/:id', component: StopIntervalReportComponent },
          {
            path: 'trip-analysis/:id',
            component: TripCauseCodeAnalysisComponent,
          },
        ],
      },
      {
        path: 'personnel-management',
        component: PersonnelManagementComponent,
        children: [
          { path: '', redirectTo: 'driver', pathMatch: 'full' },
          { path: 'driver/:id', component: PmDriverMatComponent },
          { path: 'controller/:id', component: PmControllerComponent },
          { path: 'pager/:id', component: PagerComponent },
        ],
      },
      {
        path: 'vehicle-management',
        component: VehicleManagementComponent,
        children: [
          { path: '', redirectTo: 'vehicle', pathMatch: 'full' },
          { path: 'vehicle/:id', component: VmDriverComponent },
          { path: 'device/:id', component: VehicleDeviceComponent },
          { path: 'device-monitor/:id', component: DeviceMonitorComponent },
          { path: 'vehicle-list/:id', component: VehicleListComponent },
        ],
      },
      { path: 'dispatcher/:id', component: DispatcherComponent },
      { path: 'dispatch-assign/:id', component: DispatcherAssignComponent },
      {
        path: 'route-management',
        component: RouteManagementComponent,
        children: [
          { path: '', redirectTo: 'route', pathMatch: 'full' },
          { path: 'route/:id', component: DataManagementComponent },
          { path: 'segment/:id', component: SegmentComponent },
        ],
      },
      { path: 'schedule/:id', component: ScheduleComponent },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          { path: 'ladder/:id', component: SettingsLadderComponent },
          { path: 'map/:id', component: SettingsMapComponent },
        ],
      },
      {
        path: 'system-configuration',
        component: StopManagementComponent,
        children: [
          { path: '', redirectTo: 'stop-search', pathMatch: 'full' },
          { path: 'stop-search/:id', component: SearchStopComponent },

          {
            path: 'service',
            component: ServiceManagementComponent,
            children: [
              { path: 'create/:id', component: CreateServiceComponent },
            ],
          },
          {
            path: 'schedule',
            component: ScheduleManagementComponent,
            children: [
              { path: 'search/:id', component: ServiceScheduleComponent },
            ],
          },
          {
            path: 'account-management',
            component: AccountManagementComponent,
            children: [
              { path: 'user/:id', component: GroupAccountComponent },
              { path: 'role/:id', component: RoleManagementComponent },
              {
                path: 'responsibility/:id',
                component: ResponsibilityComponent,
              },
            ],
          },
        ],
      },
      // { path: 'system-configuration', children:[
      //     // { path: '', redirectTo: 'vehicle', pathMatch: 'full'},
      // ] },
      { path: 'user-management/:id', component: UserManagementComponent },
      {
        path: 'incident-management/:id',
        component: IncidentManagementComponent,
      },
      { path: 'messages/:id', component: MessageComponent },
      {
        path: 'master-data',
        component: MasterDataComponent,
        children: [
          { path: 'organization/:id', component: OrganizationalDataComponent },
          { path: 'vehicles/:id', component: MasterDataVehiclesComponent },
          { path: 'drivers/:id', component: MasterDataDriversComponent },
        ],
      },
      {
        path: 'driver-management',
        component: DriverManagementComponent,
        children: [
          { path: 'list/:id', component: DriverListComponent },
          { path: 'duty-rostering/:id', component: DutyRosteringComponent },
        ],
      },
      { path: 'alarm/:id', component: AlarmManagementComponent },
      { path: 'duty-roster/:id', component: DutyRosterComponent },
      // { path: 'duty-rostering/:id', component: DutyRosteringComponent },
      { path: 'time-table/:id', component: TimeTableComponent },
      { path: 'multi-ladder/:id', component: MultiLadderComponent },
      { path: 'dashboard/:id', component: DashboardComponent },

      { path: 'playback/:id', component: PlaybackComponent },

      {
        path: 'schedule-data-management/:id',
        component: BusScheduleDataManagementComponent,
      },
      { path: 'schedule-data-table/:id', component: BusScheduleTableComponent },

      { path: 'password-expiry/:id', component: PasswordExpirationComponent },
    ],
    // data: ['password-expiry', 'password-expiry2']
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset', component: ResetPasswordComponent },
  {
    path: 'comms',
    canActivate: [AuthGuard],
    component: CommsComponent,
    title: 'NGBMS: Broadcast',
  },
  {
    path: 'call',
    canActivate: [AuthGuard],
    component: PrivateCallComponent,
    title: 'NGBMS: Call',
  },
  {
    path: 'mock-bus',
    canActivate: [AuthGuard],
    component: MockBusComponent,
    title: 'NGBMS: Test Page',
  },
  { path: 'pids', component: PidsComponent, title: 'ST Electronics PIDS' },
  { path: 'demo', redirectTo: 'demo/timetable', pathMatch: 'full' },
  { path: 'map-demo', redirectTo: 'demo/timetable', pathMatch: 'full' },
  {
    path: 'demo/timetable',
    component: MockTimetableComponent,
    title: 'Timetable Demo',
  },
  { path: 'demo/map-demo', component: MockMapComponent, title: 'NGBMS Demo' },
  {
    path: 'demo/route-mgmt',
    component: DemoRouteManagementComponent,
    title: 'Route Mgmt Demo',
  },
  {
    path: 'demo/stop-mgmt',
    component: DemoStopManagementComponent,
    title: 'Stop Mgmt Demo',
  },
  // {path: '404', component: NotFoundComponent},
  // {path: '**', redirectTo: '/404'}
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoute, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
