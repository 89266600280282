import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTable, MatSnackBar, MatPaginator, MatTableDataSource } from '@angular/material';
import { MessageElement, MessageService } from './message.service';
import { FormGroup, FormControl } from '@angular/forms';
import moment from 'moment';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  dataSource: MatTableDataSource<MessageElement> = new MatTableDataSource<MessageElement>();

  displayedColumns: string[] = ['time', 'from', 'to', 'message'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatTable, { static: true }) table: MatTable<MessageElement>;

  msgForm:any;

  options: any[] = [{value: 'findByBusRegNo', label: 'Bus Reg No'},
  {value: 'findByMsgSource', label: 'Message Source'},
  {value: 'findByRouteId', label: 'Route ID'},
  {value: 'findByTripId', label: 'Trip ID'},
  {value: 'findVehicleMessagesByDate', label: 'Message Date'}];
  
  constructor(private messageService: MessageService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.msgForm = new FormGroup({
      searchBy: new FormControl(this.options[0], []),
      keyword: new FormControl(null, [])
    });
    this.dataSource.paginator = this.paginator;
    let self = this;
    this.msgForm.get('searchBy').valueChanges.subscribe(val => {
      self.msgForm.get('keyword').setValue(null);
    });
  }

  search() {
    this._snackBar.open('Searching...');

    let searchBy = this.msgForm.value.searchBy.value;

    let keyword;

    if (searchBy === 'findVehicleMessagesByDate') {
      keyword = this.msgForm.value.keyword.format('YYYY-MM-DD');
    } else {
      keyword = this.msgForm.value.keyword;
    }
    
    this.messageService.findMsg(searchBy, keyword).subscribe((data: any) => {
      let msgs: MessageElement[] = [];

      data.forEach((x: any) => {
        msgs.push({
          time: x.receivedimestamp,
          from: x.msgSource === 'operator' ? 'operator' : x.busRegNo,
          to: x.msgSource === 'operator' ? x.busRegNo : 'operator',
          message: x.messageText
        });
      });
      this.dataSource.data = msgs;
      this.table.renderRows();

      this._snackBar.dismiss();
    }, error => {
      this._snackBar.open(error.message, null, {
        duration: 2000,
      });
    });
  }

  format(time: string): string {
    return moment(time).format('DD/MM/YYYY HH:mm:ss');
  }
}
