<div class="main">
  <div>
    <!-- <h3>Vehicle Device</h3> -->
    <h3 class="title-header">Vehicle Device<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div>

  <!-- <div class="criteria-row">
    <form [formGroup]="rptForm" (ngSubmit)="run()">
      <mat-form-field floatLabel="always">
        <input type="text" placeholder="Route" aria-label="Route" matInput formControlName="route"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let n of filteredRoutes" [value]="n">
            {{n}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="rptForm.get('route').invalid">Invalid route</mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="always">
        <input matInput [matDatepicker]="startDate" placeholder="Start Date" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error *ngIf="rptForm.get('startDate').invalid">Invalid start date</mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="always">
        <input matInput [matDatepicker]="endDate" placeholder="End Date" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
        <mat-error *ngIf="rptForm.get('endDate').invalid">Invalid end date</mat-error>
      </mat-form-field>
      <button mat-raised-button type="submit" 
        [disabled]="running || !rptForm.valid">Run</button>
      <button type="button" mat-raised-button (click)="exporter.exportTable('xlsx')"  [disabled]="running">Export</button>
    </form>
  </div> -->

  <div class="container no-padding" *ngIf="!authService.isAdmin()">
    <div class="col message">
      <h3>Only administrator has access rights to this module.</h3>  
    </div>
  </div>

  <div class="device-table" *ngIf="authService.isAdmin()">
    <button mat-raised-button type="button" class="pull-right" (click)="add()">Add</button>
    <table mat-table matTableExporter [dataSource]="dataSource" matSort #exporter="matTableExporter">

      <ng-container matColumnDef="busRegNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bus</th>
        <td mat-cell *matCellDef="let element"> {{element.busRegNo}} </td>
      </ng-container>

      <ng-container matColumnDef="deviceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Id</th>
        <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
      </ng-container>

      <ng-container matColumnDef="deviceVoipId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Voip Id</th>
        <td mat-cell *matCellDef="let element"> {{element.deviceVoipId}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="ctryCode">
        <th mat-header-cell *matHeaderCellDef>Country Code</th>
        <td mat-cell *matCellDef="let element"> {{element.ctryCode}} </td>
      </ng-container> -->

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Action </th>   
        <td mat-cell *matCellDef="let element"> 
          <!-- <button mat-raised-button (click)="edit(element)">Edit</button>  -->
          <button (click)="edit(element)" mat-icon-button color="primary">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      
      <!-- <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let element"> <button (click)="edit(element)" mat-icon-button color="primary">
            <mat-icon>edit</mat-icon>
          </button>
      </ng-container> -->

      <!-- <ng-container matColumnDef="manual">
        <th mat-header-cell *matHeaderCellDef>Manual</th>
        <td mat-cell *matCellDef="let element"> {{element.manual}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <ng-container matColumnDef="deviation">
        <th mat-header-cell *matHeaderCellDef>Deviation</th>
        <td mat-cell *matCellDef="let element"> {{element.deviation}} </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

</div>