import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MapService } from 'src/app/component/shared/services/map.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { environment } from 'src/environments/environment';
import {
  CALL_PATH,
  WINDOW_NAME_CALL,
  WINDOW_NAME_MAIN,
} from 'src/app/component/shared/others/constant';
import { MockMapService } from '../mock-map/mock-map-service.service';
import { busRoutes } from '../mock-map/constant/routes';

export interface myinterface {
  removeBusComponent(
    index: number,
    busCode: string,
    stopCode: string,
    key: string,
    route: string
  );
  zoomBlinkBusStop(data);
  zoomBusLocation(data);
  showMessageList(data);
  // initVoip(data);
}

@Component({
  selector: 'app-mock-bus-details',
  templateUrl: './mock-bus-details.component.html',
  styleUrls: ['./mock-bus-details.component.scss'],
})
export class MockBusDetailsComponent {
  constructor(private mockMapService: MockMapService) {}

  @Input() busInputData: any = undefined;
  @Input() busKey = 0;
  @Output() closeDetails: EventEmitter<any> = new EventEmitter<any>();

  busData: any;
  location: string = 'bottom';
  busFeature;
  route;
  routeName;
  showBus = false;
  public selfRef: MockBusDetailsComponent;

  ngOnInit() {
    const { busCode, route } = this.busInputData;
    const findBusIndex = this.mockMapService.busList.findIndex(
      detail => detail.data.busCode === busCode
    );

    if (findBusIndex > -1) {
      const { data, busFeature } = this.mockMapService.busList[findBusIndex];
      this.busData = data;
      this.busFeature = busFeature;
      this.route = route;
      this.routeName =
        busRoutes.find(route => route.id === this.route)?.route ?? this.route;
      this.showBus = true;
    } else {
      this.showBus = false;
    }
  }

  removeBus(index, busCode, stopCode, key) {
    var emittedData = {
      index: index,
      busCode: busCode,
      stopCode: stopCode,
      key: key,
      route: this.route,
    };
    this.showBus = false;
    this.closeDetails.emit(emittedData);
  }

  getStopDetails(stopId) {
    if (!stopId) return '';
    return '';
  }

  zoomBusLocation() {}
}
