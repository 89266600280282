import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatTable, MatDialog, MatDialogConfig, MatSnackBar, MatSort } from '@angular/material';
import {
  DeviceStatusElement,
  DeviceMonitorService,
} from './device-monitor.service';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-device-monitor',
  templateUrl: './device-monitor.component.html',
  styleUrls: ['./device-monitor.component.scss'],
})
export class DeviceMonitorComponent implements OnInit, OnDestroy {
  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;
  dataSource: MatTableDataSource<DeviceStatusElement>;

  displayedColumns: string[] = [
    'busRegNo',
    'deviceId',
    'voipId',
    'ipAddress',
    'deviceStatus',
    'reportedTime',
    'driverId',
    'routeName',
    'tripStatus',
    'swVersion',
    'datasetVersion',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<DeviceStatusElement>;

  isNewWindow: boolean = false;
  intervalDevice;
  allDevice = [];
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  pageData = {
    id: 4,
    name: 'deviceMonitor',
    displayPageName: 'Device Monitoring',
  };
  isHasAccess: boolean = false;
  rightsError: string = '';

  constructor(
    private dialog: MatDialog,
    private drvService: DeviceMonitorService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy() {
    // console.log('clear interval');
    clearInterval(this.intervalDevice);
  }

  ngOnInit() {
    setTimeout(() => {
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      this.rightsError = "You don't have access rights to this module.";

      if (this.route.snapshot.params.id === '2') {
        this.isNewWindow = true;
      }

      this.dataSource = new MatTableDataSource<DeviceStatusElement>();

      this.drvService.getDeviceStatus().subscribe(
        (data: any) => {
          // let allDevice = [];
          data.forEach(element => {
            let status = 'Offline';
            if (element.deviceStatus === 'Active') {
              status = 'Online';
            }
            element.deviceStatusDisplay = status;
            this.allDevice.push(element);
          });

          this.dataSource.data = this.allDevice.sort((a, b) =>
            a.reportedTime > b.reportedTime ? -1 : 1
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (allDevice, attribute) =>
            allDevice[attribute]; //fix for numbers and string sorting
          // this.dataSource.sortingDataAccessor =  (allDevice, property) => {
          //   if (property === 'routeName') {
          //     console.log(allDevice);
          //     return allDevice.routeName;
          //   } else {
          //     return allDevice[property];
          //   }
          // };
          this.table.renderRows();
        },
        error => {
          this._snackBar.open(error.message, null, {
            duration: 2000,
          });
        }
      );
    }, 500);

    var me = this;
    this.intervalDevice = setInterval(function () {
      me.refresh();
      // console.log('device monitoring')
    }, 10000);
  }

  refresh() {
    this._snackBar.open('Refreshing...', null, {
      duration: 2000,
    });

    this.drvService.getDeviceStatus().subscribe(
      (data: any) => {
        // this.dataSource.data = data;
        let allDevice = [];
        data.forEach(element => {
          let status = 'Offline';
          if (element.deviceStatus === 'Active') {
            status = 'Online';
          }
          element.deviceStatusDisplay = status;
          allDevice.push(element);
        });
        this.dataSource.data = allDevice.sort((a, b) =>
          a.reportedTime > b.reportedTime ? -1 : 1
        );
        // this.table.renderRows();
        // this._snackBar.dismiss();
      },
      error => {
        this._snackBar.open(error.message, null, {
          duration: 2000,
        });
      }
    );
  }

  filter(keyword: string) {
    this.dataSource.filter = keyword.trim().toLowerCase();
  }

  format(time: string): string {
    return moment(time).format('DD/MM/YYYY HH:mm:ss');
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/vehicle-management/device-monitor/2',
      'device-monitor',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }
}
