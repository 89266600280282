<div class="password-expiry-group mt-10per" fxLayout="row" fxLayoutAlign="space-around center" >
  <div fxFlex="40">
    <h1 mat-dialog-title>Change Password</h1>
    <div mat-dialog-content class="body-content">
      <form [formGroup]="form" autocomplete="off">
  
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-pass-toggle-visibility #oldToggle matSuffix></mat-pass-toggle-visibility>
          <mat-label>Old Password</mat-label>
          <input matInput #oldPassword formControlName="oldPassword"
                  [type]="oldToggle.type"
                  placeholder="Old Password" name="oldPassword">
        </mat-form-field>
  
        <mat-slide-toggle [checked]="true" #toggle7>Show Password Details</mat-slide-toggle>
  
        <div>
          <!--password input filed-->
          <mat-form-field appearance="outline" style="width: 100%" [color]="passwordComponent7.color">
            <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
            <mat-label>Password</mat-label>
            <input matInput #password formControlName="password" (input)="onPasswordConfirm($event.target.value)"
                   [type]="toggle.type"
                   placeholder="Password">
            <mat-hint align="end" aria-live="polite">
              {{password.value.length}} / {{passwordComponent7.max}}
            </mat-hint>
          </mat-form-field>
  
          <!--@angular-material-extensions/password-strength's main component-->
          <mat-password-strength #passwordComponent7
                                 class="green"
                                 [password]="password.value">
          </mat-password-strength>
          <!--Password's strength info-->
          <mat-password-strength-info
            *ngIf="toggle7.checked"
            [passwordComponent]="passwordComponent7">
          </mat-password-strength-info>
        </div>
  
        <div class="mar-top-10">
  
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-pass-toggle-visibility #confirmToggle matSuffix></mat-pass-toggle-visibility>
            <mat-label>Confirm Password</mat-label>
            <input matInput formControlName="confirmPassword" (input)="onPasswordConfirm($event.target.value)"
                    [type]="confirmToggle.type"
                    placeholder="Confirm Password" name="confirmPassword">
            <mat-error *ngIf="!isPasswordMatch">
              Passwords do not match
            </mat-error>  
          </mat-form-field>
        </div>
  
        <!-- <div>
          <small><i>Changing password requires to log in again.</i></small>
        </div> -->
      </form>
      <div *ngIf="message" snack-bar-timer>{{message}}</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <button mat-button (click)="onSkip()" *ngIf="daysExpiration !== '0'">Skip</button>
      </div>
      <button mat-raised-button type="button" (click)="onSave()" class="float-right" [disabled]="!form.valid">Submit</button>
    </div>
  </div>
</div>