import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatTable, MatDialog, MatDialogConfig, MatSnackBar, MatSort } from '@angular/material';
// import { DeviceStatusElement, DeviceMonitorService } from './vehicle-list.service';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { VmService } from '../vm.service';

@Component({
  selector: 'app-masterdata-vehicles',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss'],
})
export class MasterDataVehiclesComponent implements OnInit {
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = [
    'busId',
    'numberPlate',
    'brand',
    'model',
    'company',
    'depot',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  isNewWindow: boolean = false;

  @ViewChild('chatPS', { static: true }) chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  allVehicles: any = [
    {
      busId: '1300468',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0001',
    },
    {
      busId: '1300475',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0002',
    },
    {
      busId: '1300485',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0003',
    },
    {
      busId: '1300383',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0004',
    },
    {
      busId: '1102317',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0005',
    },
    {
      busId: '1101697',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0006',
    },
    {
      busId: '1101296',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0007',
    },
    {
      busId: '2200465',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0008',
    },
    {
      busId: '2200936',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0009',
    },
    {
      busId: '2200606',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0010',
    },
    {
      busId: '2201288',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0011',
    },
    {
      busId: '2201601',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0012',
    },
    {
      busId: '2200729',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0013',
    },
    {
      busId: '2200666',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0014',
    },
    {
      busId: '2200734',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0015',
    },
    {
      busId: '2200826',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0016',
    },
    {
      busId: '2201224',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0017',
    },
    {
      busId: '1100366',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0018',
    },
    {
      busId: '2200793',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0019',
    },
    {
      busId: '2201092',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0020',
    },
    {
      busId: '1101888',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0021',
    },
    {
      busId: '1102276',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0022',
    },
    {
      busId: '1102641',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0023',
    },
    {
      busId: '1101253',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0024',
    },
    {
      busId: '2201363',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Bishan Depot',
      numberPlate: 'B0025',
    },
    {
      busId: '2200853',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0026',
    },
    {
      busId: '2200390',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0027',
    },
    {
      busId: '2201025',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0028',
    },
    {
      busId: '2200519',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0029',
    },
    {
      busId: '2200575',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0030',
    },
    {
      busId: '2200914',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0031',
    },
    {
      busId: '2200553',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0032',
    },
    {
      busId: '2200867',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0033',
    },
    {
      busId: '2200113',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0034',
    },
    {
      busId: '2200491',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Ulu Pandan Depot',
      numberPlate: 'B0035',
    },
    {
      busId: '2200405',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0036',
    },
    {
      busId: '2200875',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0037',
    },
    {
      busId: '2200964',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0038',
    },
    {
      busId: '1300396',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0039',
    },
    {
      busId: '1102322',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0040',
    },
    {
      busId: '1101460',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0041',
    },
    {
      busId: '2200923',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0042',
    },
    {
      busId: '2200869',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0043',
    },
    {
      busId: '2200501',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0044',
    },
    {
      busId: '2200933',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Changi Depot',
      numberPlate: 'B0045',
    },
    {
      busId: '2201191',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0046',
    },
    {
      busId: '2200934',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0047',
    },
    {
      busId: '1102325',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0048',
    },
    {
      busId: '2200824',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0049',
    },
    {
      busId: '2200673',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0050',
    },
    {
      busId: '2200565',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0051',
    },
    {
      busId: '2200453',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0052',
    },
    {
      busId: '2200469',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0053',
    },
    {
      busId: '2201678',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0054',
    },
    {
      busId: '2201688',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Ten Mile Junction Depot',
      numberPlate: 'B0055',
    },
    {
      busId: '2201691',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0056',
    },
    {
      busId: '2201689',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0057',
    },
    {
      busId: '2202024',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0058',
    },
    {
      busId: '2201916',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0059',
    },
    {
      busId: '2201736',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0060',
    },
    {
      busId: '2201718',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0061',
    },
    {
      busId: '2202027',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0062',
    },
    {
      busId: '2202005',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0063',
    },
    {
      busId: '2201914',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0064',
    },
    {
      busId: '2201961',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0065',
    },
    {
      busId: '2202001',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Tuas Depot',
      numberPlate: 'B0066',
    },
    {
      busId: '2201991',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0067',
    },
    {
      busId: '2201987',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0068',
    },
    {
      busId: '2201731',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0069',
    },
    {
      busId: '2201995',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0070',
    },
    {
      busId: '2201922',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0071',
    },
    {
      busId: '2201772',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0072',
    },
    {
      busId: '2201747',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0073',
    },
    {
      busId: '2201891',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0074',
    },
    {
      busId: '2201973',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0075',
    },
    {
      busId: '2201997',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Sengkang Depot',
      numberPlate: 'B0076',
    },
    {
      busId: '2201785',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      numberPlate: 'B0077',
    },
    {
      busId: '2201789',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      numberPlate: 'B0078',
    },
    {
      busId: '2201975',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      numberPlate: 'B0079',
    },
    {
      busId: '2201984',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      numberPlate: 'B0080',
    },
    {
      busId: '2201841',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      numberPlate: 'B0081',
    },
    {
      busId: '2201828',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Go Ahead Singapore',
      depot: 'Kim Chuan Depot',
      numberPlate: 'B0082',
    },
    {
      busId: '2201958',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      numberPlate: 'B0083',
    },
    {
      busId: '2201094',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      numberPlate: 'B0084',
    },
    {
      busId: '2201004',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      numberPlate: 'B0085',
    },
    {
      busId: '2201067',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      numberPlate: 'B0086',
    },
    {
      busId: '2201327',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      numberPlate: 'B0087',
    },
    {
      busId: '2201251',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'Tower Transit Singapore',
      depot: 'Mandai Depot',
      numberPlate: 'B0088',
    },
    {
      busId: '2201261',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      numberPlate: 'B0089',
    },
    {
      busId: '2201314',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      numberPlate: 'B0090',
    },
    {
      busId: '2201333',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      numberPlate: 'B0091',
    },
    {
      busId: '2201431',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      numberPlate: 'B0092',
    },
    {
      busId: '2201505',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      numberPlate: 'B0093',
    },
    {
      busId: '2202667',
      brand: 'Volvo',
      model: 'FM 330 Euro 6',
      company: 'SMRT Buses',
      depot: 'Gali Batu Depot',
      numberPlate: 'B0094',
    },
    {
      busId: '2201183',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      numberPlate: 'B0095',
    },
    {
      busId: '2200773',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      numberPlate: 'B0096',
    },
    {
      busId: '2201110',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      numberPlate: 'B0097',
    },
    {
      busId: '2201245',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      numberPlate: 'B0098',
    },
    {
      busId: '2201283',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      numberPlate: 'B0099',
    },
    {
      busId: '2201351',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      numberPlate: 'B0100',
    },
    {
      busId: '2201282',
      brand: 'BYD',
      model: 'K9',
      company: 'SBS Transit',
      depot: 'Tai Seng Facility Building',
      numberPlate: 'B0101',
    },
  ];

  constructor(
    private dialog: MatDialog,
    // private vmService: VmService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }

    this.dataSource = new MatTableDataSource<any>();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this._snackBar.open('Loading...');

    // let self = this;
    // this.drvService.getAllDriver().subscribe((data: any) => {
    this.dataSource.data = this.allVehicles;
    // const zeroPad = (num, places) => String(num).padStart(places, '0');
    // this.allDrivers.forEach((element,idx) => {
    //   if(element.company === 'SBS Transit') {
    //     element.brand = 'BYD';
    //     element.model = 'K9';
    //   }
    //   else if(element.company === 'SBS Transit') {
    //     element.brand = 'CaetanoBus';
    //     element.model = 'e.City Gold';
    //   }
    //   else if(element.company === 'SBS Transit') {
    //     element.brand = 'Mercedes-Benz';
    //     element.model = 'Citaro K';
    //   }
    //   else {
    //     element.brand = 'Volvo';
    //     element.model = 'FM 330 Euro 6';
    //   }
    //   element.numberPlate = 'B' + zeroPad(idx+1,4);
    //   // element.licenseNumber = 'LN'+Math.floor(100000 + Math.random() * 900000);

    // });
    // console.log(JSON.stringify(this.allVehicles));
  }

  refresh() {
    this._snackBar.open('Refreshing...');

    // this.vmService.getAllDeviceSim().subscribe((data: any) => {
    //   this.dataSource.data = data;
    //   this.table.renderRows();

    //   this._snackBar.dismiss();
    // }, error => {
    //   this._snackBar.open(error.statusText, null, {
    //     duration: 2000,
    //   });
    // });
  }

  filter(keyword: string) {
    this.dataSource.filter = keyword.trim().toLowerCase();
  }

  format(time: string): string {
    return moment(time).format('DD/MM/YYYY HH:mm:ss');
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/master-data/vehicles/2',
      'vehicles',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }
}
