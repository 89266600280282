<div class="call-window" app-comms fxLayout="row">
    <div class="call-sidebar" fxLayout="column" fxLayoutAlign="space-between">
        <div class="app-logo" fxLayout="column">
            <img src="{{logo}}" />
            <div class="sidebar-icon" (click)="onClickHome()" matTooltip="Home" matTooltipPosition="right"><i class="material-icons">home</i></div>
        </div>
        <div class="bottom-icons">
            <!-- <div class="sidebar-icon" (click)="toggleBroadcast()">
                <i class="material-icons">radio</i>
                <div class="microphone" [class.unmuted]="!broadcastMicMuted">
                    <i class="material-icons" *ngIf="broadcastMicMuted">mic_off</i>
                    <i class="material-icons" *ngIf="!broadcastMicMuted">mic</i>
                </div>
            </div> -->
            <div class="sidebar-icon" [matMenuTriggerFor]="settings" matTooltip="Account" matTooltipPosition="right"><i class="material-icons">person</i></div>
        </div>
    </div>
    <div>
        <mat-menu #settings="matMenu" yPosition="below" class="settings-menu">
            <div mat-menu-item class="current-user" (click)="onShowAccount()">
                {{displayName | titlecase}}
                <div *ngIf="userRole" class="current-user-role">{{ userRole }}</div>
            </div>
            <div mat-menu-item class="current-user" (click)="onChangePassword()">Change Password</div>
            <!-- <button mat-menu-item [routerLink]="['/account/settings/ladder', 1]">Settings</button> -->
            <button mat-menu-item class="current-user" (click)="onLogout()">Logout</button>
        </mat-menu>
    </div>
    <app-broadcast></app-broadcast>
</div>