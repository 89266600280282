<div class="time-bus-details-group">
  <div class="header">
    <div class="title">
      <span class="main-title"
        ><b>Route {{ dialogData.routeId }}:</b> Block
        {{ dialogData.blockId }}</span
      >
      <span class="sub-title">Trip Id: {{ dialogData.tripId }}</span>
    </div>

    <mat-icon class="pointer" matTooltip="Close" (click)="dialogRef.close()"
      >close</mat-icon
    >
  </div>

  <mat-dialog-content>
    <!-- <perfect-scrollbar #chatPS class="bus-details" [config]="config" [scrollIndicators]="false"> -->
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mar-bot-10">
      <div
        *ngIf="dialogData.statusColor"
        class="status {{ dialogData.statusColor }}">
        {{ dialogData.statusColor }}
      </div>

      <div
        class="driver-list"
        *ngIf="dialogData.driverList?.length > 0; else noDriver">
        <div class="driver-title">Assigned Driver:</div>
        <div
          *ngFor="let driver of dialogData.driverList; let i = index"
          class="driver-item">
          <ng-container *ngIf="dialogData.driverList?.length > 1"
            >{{ i + 1 }}.</ng-container
          >
          {{ driver.driver_name }} ({{ driver.driver_id }})
        </div>
      </div>
      <ng-template #noDriver>
        <div>No assigned driver.</div>
      </ng-template>

      <div class="text-center">
        <!-- <div class="icon icon-phone" (click)="onShowVoip()"></div> -->
        <!-- <span class="material-icons" (click)="onCallVoip()">phone</span> -->
        <!-- <div class="icon icon-msg" (click)="showMessageList()"></div> -->

        <!-- <div class="time-table-outgoing-call" [ngClass]="{'d-none': !isOnCall}">
          <div class="icon icon-hangup" (click)="onVoipHangUp()"><i class="fa fa-phone"></i></div>
        </div>
        <div class="time-table-incoming-call d-none" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="icon icon-hangup" (click)="onVoipDecline()"><i class="fa fa-phone"></i></div>
          <div class="icon icon-answer bounce" (click)="onVoipAnswer()"><i class="fa fa-phone"></i></div>
        </div> -->
        <div
          class="time-table-idle-call"
          [ngClass]="{ 'd-none': isOnCall }"
          *ngIf="dialogData.busRegNo">
          <div class="icon icon-answer" (click)="callJitsi()">
            <i class="fa fa-phone"></i>
          </div>
        </div>
        <!-- <div class="time-table-call-status sm-italic">Ready</div> -->
      </div>
    </div>

    <!-- <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="50"><span class="color-brown">Vehicle</span>: {{dialogData.busRegNo}}</div>
        <div fxFlex="50"><span class="color-brown">Trip Id</span>: {{dialogData.tripId}}</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" >
        <div fxFlex="50"><span class="color-brown">Driver Id</span>: {{dialogData.stopId}}</div>
        <div fxFlex="50"><span class="color-brown">Block No</span>: {{dialogData.blockId}}</div>
    </div> -->
    <div class="padding1-10 mar-top-10" *ngIf="dialogData.filteredDispatch">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="color-brown text-left">
        <!-- <div fxFlex="20" *ngIf="dialogData.type !== 'bus'">Start Time</div> -->
        <div>
          <ng-container *ngIf="dialogData.type !== 'bus'">Duty</ng-container>
          <ng-container *ngIf="dialogData.type === 'bus'">Actual</ng-container>
          Vehicle
        </div>
        <div fxFlex="60">
          <ng-container *ngIf="dialogData.type !== 'bus'">Duty</ng-container>
          <ng-container *ngIf="dialogData.type === 'bus'">Actual</ng-container>
          Driver
        </div>
      </div>

      <div
        class="text-left"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngFor="let dispatch of dialogData.filteredDispatch">
        <!-- <div fxFlex="20" *ngIf="dialogData.type !== 'bus'">{{dispatch.plannedStartTime | date:'HH:mm'}}</div> -->
        <ng-container>
          <div>{{ dispatch.busRegNo }}</div>
          <div *ngIf="dispatch.driverName !== ''" fxFlex="60">
            <ng-container *ngIf="dispatch.driverId"
              >({{ dispatch.driverId }})
            </ng-container>
            {{ dispatch.driverName }}
          </div>
        </ng-container>
      </div>
    </div>
    <!-- </perfect-scrollbar> -->
  </mat-dialog-content>

  <!-- <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" 
    >Upload</button>
  </mat-dialog-actions> -->
</div>
