<div class="add-role-group">
  <h2 mat-dialog-title>{{ title }}</h2>

  <mat-dialog-content class="dialog-content">
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="space-around stretch">
        <mat-form-field appearance="outline">
          <mat-label>Display Name</mat-label>
          <input
            matInput
            formControlName="displayName"
            placeholder="Display Name" />
        </mat-form-field>

        <ng-container *ngIf="!isEdit">
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              matInput
              placeholder="Password"
              [type]="isHide ? 'password' : 'text'"
              formControlName="password"
              required />
            <mat-icon class="pointer" matSuffix (click)="isHide = !isHide">{{
              isHide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
            <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
          </mat-form-field>
        </ng-container>

        <mat-form-field appearance="outline">
          <mat-label>Email Address</mat-label>
          <input matInput formControlName="email" placeholder="Email" />
        </mat-form-field>

        <ng-container *ngIf="!isEdit">
          <mat-form-field appearance="outline">
            <mat-label>Agency</mat-label>
            <mat-select formControlName="agency" [(ngModel)]="selectedAgency">
              <mat-option
                *ngFor="let agency of agencyList"
                [value]="agency.value">
                {{ agency.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <mat-form-field appearance="outline">
          <mat-label>Role</mat-label>
          <!-- <input matInput formControlName="role" placeholder="Role"> -->
          <mat-select formControlName="role" [(ngModel)]="selectedRole">
            <mat-option *ngFor="let role of roleList" [value]="role.roleName">
              {{ role.roleName | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="selectedRole === 'controller'">
          <mat-label>Supervisor Name</mat-label>
          <!-- <input matInput formControlName="supervisorName" placeholder="Name"> -->

          <mat-select
            formControlName="supervisorName"
            [(ngModel)]="selectedSup">
            <mat-option *ngFor="let sup of supervisorList" [value]="sup.value">
              {{ sup.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox type="checkbox" formControlName="isAccountDisabled">
          Account Disabled
        </mat-checkbox>

        <!-- <mat-checkbox type="checkbox" formControlName="isAccountLocked"> 
          Account Locked
        </mat-checkbox> -->

        <!-- <mat-checkbox type="checkbox" formControlName="isCredentialExpired"> 
          Credential Expired
        </mat-checkbox> -->

        <!-- <mat-checkbox type="checkbox" formControlName="isAccountDeactivated"> 
          Account Deactivated
        </mat-checkbox> -->
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      (click)="onSaveRole()"
      [disabled]="running">
      Save
    </button>
    <!-- <mat-spinner diameter="50" class="margin-auto"></mat-spinner> -->
  </mat-dialog-actions>
</div>
