import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import moment, { months } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebSocketSubject } from 'rxjs/internal-compatibility';
import { fadeInOut } from 'src/app/component/shared/others/animation/fadeInOut';
import { mqttSendData } from 'src/app/component/shared/others/data-types';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { InfoModalComponent } from 'src/app/component/shared/services/info-modal/info-modal.component';
import { environment } from 'src/environments/environment';
import { DataStatusComponent } from '../data-status/data-status.component';
import { DatesetManualUpdateComponent } from '../dateset-manual-update/dateset-manual-update.component';

@Component({
  selector: 'app-bus-schedule-table',
  templateUrl: './bus-schedule-table.component.html',
  styleUrls: ['./bus-schedule-table.component.scss'],
  animations: [fadeInOut],
})
export class BusScheduleTableComponent implements OnInit {
  constructor(
    private _commonService: CommonService,

    private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public _authService: AuthService,
    private _spinner: NgxSpinnerService
  ) {}

  isNewWindow: boolean = false;
  displayedColumns: string[] = [];

  dataSource = new MatTableDataSource<any>();

  running: boolean = false;
  // schedDate:string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTable) table: MatTable<any>;
  selection = new SelectionModel<any>(true, []);
  pageData = {
    id: 2,
    name: 'userManagement',
    displayPageName: 'User Management',
  };
  isHasAccess: boolean = false;
  ptoList: string[] = [];
  spinnerType: string;
  selectedPTO: string;
  selectedDay: string = '';
  today = moment().format('YYYY-MM-DD');
  tmr = moment().add(1, 'day').format('YYYY-MM-DD');
  dayAftTmr = moment().add(2, 'day').format('YYYY-MM-DD');

  form = new FormGroup({
    // schedDate: new FormControl(moment().format('YYYY-MM-DD'), [Validators.required])
    schedDate: new FormControl('', [Validators.required]),
    pto: new FormControl('', [Validators.required]),
  });
  snackbarList: any = [
    {
      id: 1,
      timer: 5,
      description: 'Vehicle PC7991M software is now updated.',
      status: 'status-success',
    },
    {
      id: 2,
      timer: 4,
      description: 'Vehicle PC7991M failed to update.',
      status: 'status-failed',
    },
    {
      id: 3,
      timer: 3,
      description: 'Vehicle PC7991M started downloading.',
      status: 'status-downloading',
    },
  ];
  snackbarInterval: any;
  manualData = [];
  private $datasetReminder: WebSocketSubject<any>;
  tableData;
  idxDay = [];

  ngOnInit() {
    if (this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }

    this.route.queryParamMap.subscribe(params => {
      // let paramsObject = { ...params.keys, ...params };
      let pto = params.get('pto');
      let date = params.get('date');
      this.selectedDay = params.get('day');

      this.selectedPTO = pto;
      this.form.patchValue({ schedDate: date });
      this.onSearch();
      // this.form.get('schedDate').updateValueAndValidity();
      // this.form.get('pto').updateValueAndValidity();
    });

    this.isHasAccess = this._authService.isHasAccess(this.pageData);
    this.dataSource = new MatTableDataSource<any>();
    this.initPto();
    this.initMqttReminder();
  }

  initPto() {
    let urlAction = 'users/v1/getUserAuthorizedAgencies';
    this._commonService
      .commonGetAction(urlAction)
      .subscribe((respData: any) => {
        this.ptoList = respData.agencies;
      });
  }

  onSearch() {
    this.resetData();
    this.running = true;
    this.spinnerType =
      this._commonService.spinnerType[Math.floor(Math.random() * 52)];
    this._spinner.show();
    let startDate = moment(this.form.value.schedDate).format('YYYY-MM-DD');
    let endDate = moment(this.form.value.schedDate)
      .add(14, 'days')
      .format('YYYY-MM-DD');
    // console.log(startDate, endDate);

    let urlAction =
      'https://ngbms-sg.com:8080/vehicles/v1/getDatasetsProcessStatus?agency=' +
      this.selectedPTO +
      '&startDate=' +
      startDate +
      '&endDate=' +
      endDate;
    this._commonService.commonGetFullUrlAction(urlAction).subscribe(
      (respData: any) => {
        // console.log(respData.length);
        if (respData.length === 0) {
          this.dialog.open(InfoModalComponent, {
            data: {
              header: 'Information',
              message: 'No available data, select other date.',
            },
          });
        }

        for (let index = 0; index < 14; index++) {
          // const element = array[index];
          // console.log(index, moment(this.form.value.schedDate).add(index, 'day').format('YYYY-MM-DD'));
          if (index === 0) {
            this.displayedColumns.push('Bus Reg No');
          }
          this.displayedColumns.push(
            moment(this.form.value.schedDate)
              .add(index, 'days')
              .format('YYYY-MM-DD')
              .toString() +
              ';' +
              moment(this.form.value.schedDate)
                .add(index, 'days')
                .format('dddd')
                .toString()
          );
        }

        this.tableData = respData;
        this.dataSource.data = respData;
        // setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.running = false;
        this._spinner.hide();
        // this.table.renderRows();
        // }, 0);
        this.getIdxDay();
        this.initManualUpdate(respData);
        this.initTimerSnackbar();
      },
      error => {
        this._snackBar.open(error.statusText, null, {
          duration: 2000,
        });
        this.running = false;
        this._spinner.hide();
      }
    );
  }

  resetData() {
    this.displayedColumns = [];
    this.dataSource.data = [];
  }

  onGetDataStatus(busRegNo, column) {
    // console.log(busRegNo, column);
    let newData: any = {
      busRegNo: busRegNo,
      date: column,
    };

    this.dialog.open(DataStatusComponent, {
      data: newData,
      // maxWidth: '100vw',
      width: '50%',
    });
  }

  myFilter = (d: Date | null): boolean => {
    if (d === undefined) return false;

    if (moment(d).format('E') === '7') {
      return true;
    }
    return false;
  };

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month') {
      const date = moment(cellDate).date(); //cellDate.getDate();
      const day = moment(cellDate).format('E');

      if (day === '6') {
        return 'daySat';
      } else if (day === '7') {
        return 'daySun';
      } else {
        return '';
      }
    }

    return '';
  };

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/reports/schedule/2',
      'reports',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  onManualUpdate() {
    // let newData:any = {
    //   pto: this.selectedPTO
    // };
    let newData: any = {
      data: this.manualData,
    };

    this.dialog.open(DatesetManualUpdateComponent, {
      data: newData,
      // maxWidth: '100vw',
      width: '50%',
    });
  }

  initMqttReminder() {
    this.$datasetReminder = new WebSocketSubject(environment.nodeUrlWs); //nodeUrlLocalWs nodeUrlWs

    this.$datasetReminder.subscribe(retData => {
      if (retData.data.response === undefined) {
        return false;
      }
      // {id: 1, timer: 5, description: 'Vehicle PC7991M software is now updated.', status: 'status-success'},
      // {id: 2, timer: 4, description: 'Vehicle PC7991M failed to update.', status: 'status-failed'},
      // {id: 3, timer: 3, description: 'Vehicle PC7991M started downloading.', status: 'status-downloading'},

      let microtime = (Date.now() % 1000) / 1000;
      let stat = 'status-success';
      let desc = '';

      if (retData.data.response === 'offline') {
        desc = 'Vehicle ' + retData.data.vehicle + ' failed to update.';
        stat = 'status-failed';
      } else {
        desc = 'Vehicle ' + retData.data.vehicle + ' started downloading.';
        stat = 'status-downloading';
        let elem = this.tableData.find(
          x => x.busRegNo === retData.data.vehicle
        );
        elem.processStatuses.forEach((element, idx) => {
          if (this.idxDay.includes(idx)) {
            elem.processStatuses[idx] = 2; //downloading status
          }
        });
      }

      this.snackbarList.push({
        id: microtime,
        timer: 5,
        description: desc,
        status: stat,
      });
    });
    this.listtenMqttReminder();
  }

  listtenMqttReminder() {
    const mqttSend: any = {
      type: 'datasetReminder',
      busRegNo: '',
      service: '',
    };
    const message = new mqttSendData(
      mqttSend.type,
      mqttSend.service,
      mqttSend.busRegNo
    );

    this.$datasetReminder.next(message);
  }

  onCloseSnackBar(data) {
    let newData = this.snackbarList.filter(x => x.id !== data.id);
    this.snackbarList = newData;
  }

  initTimerSnackbar() {
    var me = this;
    this.snackbarInterval = setInterval(() => {
      me.snackbarList.forEach(element => {
        element.timer--;
        if (element.timer === 0) {
          me.onCloseSnackBar(element);
        }
      });
    }, 1000);
  }

  getIdxDay() {
    this.displayedColumns.forEach((element, idx) => {
      let date = element.split(';')[0];
      let today = moment().format('YYYY-MM-DD');
      if (date === today) {
        this.idxDay.push(idx - 1);
        this.idxDay.push(idx);
        this.idxDay.push(idx + 1);
      }
    });
  }

  initManualUpdate(data) {
    data.forEach(vehicles => {
      let dates = [];
      vehicles.processStatuses.forEach((element, idx) => {
        if (this.idxDay.includes(idx)) {
          // let newData = {
          //   "busRegNo": "PC7991M"
          // }
          dates.push({
            date: this.displayedColumns[idx + 1].split(';')[0],
            status: element,
          });
        }

        let isExist = this.manualData.find(
          x => x.busRegNo === vehicles.busRegNo
        );
        if (!isExist) {
          this.manualData.push({
            busRegNo: vehicles.busRegNo,
            dates: dates,
            isChecked: true,
          });
        }
      });
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    clearInterval(this.snackbarInterval);
  }
}
