import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
// import { MatSort, MatDialogConfig, MatDialog, MatAutocompleteTrigger } from '@angular/material';
// import moment from 'moment';
import { map, startWith } from 'rxjs/operators';
// import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/component/shared/services/map.service';
import OlMap from 'ol/Map';
import { ScaleLine, defaults as defaultOlControls } from 'ol/control';
// import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
// import Point from 'ol/geom/Point';
// import OlFeature from 'ol/Feature';
import { Vector as VectorSource } from 'ol/source.js';
import {
  Vector as VectorLayer,
  Image as ImageLayer,
  VectorImage as VectorImageLayer,
} from 'ol/layer.js';
// import {Circle, Fill, Icon, Stroke, Style, Text} from 'ol/style.js';
// import {transform} from 'ol/proj.js';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import { AddStopModalComponent } from '../add-stop-modal/add-stop-modal.component';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
import { StopManagementService } from 'src/app/component/shared/services/stop-management.service';
// import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { MenuService } from 'src/app/component/shared/services/menu.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { boundingExtent } from 'ol/extent';

export var OSMBuildings: any;

//google map
import GoogleLayer from 'olgm/layer/Google.js';
import OLGoogleMaps from 'olgm/OLGoogleMaps.js';
import { fadeInOut } from 'src/app/component/shared/others/animation/fadeInOut';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
//end google map

@Component({
  selector: 'app-search-stop',
  templateUrl: './search-stop.component.html',
  styleUrls: ['./search-stop.component.scss'],
  animations: [fadeInOut],
})
export class SearchStopComponent implements OnInit {
  isNewWindow: boolean = false;
  map: OlMap;
  modalAddStopData = {};

  @ViewChild(MatSort) sort: MatSort;
  filteredOptions: Observable<any[]>;

  searchStreetStop: string = '';
  allStops: any;
  allTrainStation: any;

  constructor(
    // private homeService: HomeService,
    // private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    private mapService: MapService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    // private formBuilder: FormBuilder,
    public stopManagementService: StopManagementService,
    private commonService: CommonService,
    public authService: AuthService,
    private menuService: MenuService,
    private _spinner: NgxSpinnerService
  ) {}

  form = new FormGroup({
    streetNameBusStop: new FormControl('', [
      // Validators.required
    ]), // refactor to hide invalid form and disable submit..
  });

  running: boolean = false;
  // allStops: any;
  // featureList=[];
  isPanel: boolean = false;
  panelData: any;
  streetNameBusStop: any = '';
  options: any = [];
  isSelected: boolean = false;

  boundingExtent = boundingExtent([
    fromLonLat(environment.map.boundingExtent.min),
    fromLonLat(environment.map.boundingExtent.max),
  ]);

  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {};
  viewAnimate: OlView = new OlView({
    center: fromLonLat(environment.map.locationLonLat),
    zoom: environment.map.defaultZoom,
    minZoom: environment.map.minZoom,
    maxZoom: environment.map.maxZoom,
    projection: 'EPSG:900913',
    // extent: this.boundingExtent,
  });
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputSearchStreet: MatAutocompleteTrigger;
  isBusCodeSearch: boolean = false;

  pageData = {
    id: 1,
    name: 'stopManagement',
    displayPageName: 'Stop Management',
  };
  isHasAccess: boolean = false;

  mapOptions: any = {
    isIncident: false,
    isLadder: true,
    isMapLayer: false,
  };
  provider = this.mapService.mapProvider;
  currGoogleLayer: string = '';
  OSMLayer: OlTileLayer<any>;
  rightsError: string = '';

  ngOnInit() {
    this._spinner.hide();
    setTimeout(() => {
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      if (this.route.snapshot.params.id === '2') {
        this.isNewWindow = true;
      }

      this.rightsError = "You don't have access rights to this module.";
    }, 500);

    setTimeout(() => {
      // this.initStopModal();
      this.initMap();
      this.initTrainStations();
    }, 600);
  }

  initMap() {
    this.OSMLayer = new OlTileLayer({
      // source: new OSM(),

      source: new OSM({
        // url: 'assets/maps/Tiles/{z}/{x}/{y}.png',
        // url: 'https://agilfms.org/assets/maps/Tiles/{z}/{x}/{y}.png',
        // url: environment.nodeUrl + 'public/map/tiles/{z}/{x}/{y}.png',
        // url: 'C:/Users/Casper Macatangay/Documents/offline-map/Tiles/{z}/{x}/{y}.png',
        url: `${environment.map.staticMap}/{z}/{x}/{y}.png`,
        crossOrigin: null,
      }),
    });

    // var middlePath = Math.ceil(this.mapData.path.length / 2);
    const scaleLine = new ScaleLine({
      units: 'metric',
    });

    this.map = new OlMap({
      controls: defaultOlControls().extend([scaleLine]),
      target: 'map-stop',
      view: this.viewAnimate,
      // new OlView({
      //   // center: fromLonLat([this.mapData.path[middlePath].longitude, this.mapData.path[middlePath].latitude]),
      //   center: fromLonLat(environment.map.locationLonLat),
      //   zoom: 16,
      //   maxZoom: 20
      // }),

      // renderer: (['webgl', 'canvas'])
    });

    this.map.addLayer(this.OSMLayer);

    //google layer top most -> tempoary removed because of google issue
    // this.onChangeProvider('GoogleMap');
    // this.trafficToggle({'checked':true});

    setTimeout(() => {
      this.map.updateSize();
      this.watchMenuChange();
    }, 200);
  }

  initMapInteraction() {
    var me = this;
    this.map.on('pointermove', e => {
      if (e.dragging) {
        return;
      }
      var pixel = e.map.getEventPixel(e.originalEvent);
      var hit = false;
      var dataObj = undefined;

      e.map.forEachFeatureAtPixel(
        pixel,
        function (feature, layer) {
          let layerName = layer.get('name');
          let featureName = feature.get('name');
          // let routeName = me.ladders.routeId === undefined ? me.ladders.route[0] : me.ladders.routeId[0];

          if (layerName && layerName.includes('allStops')) {
            hit = true;
            dataObj = feature.get('dataObj');
          }
        },
        {
          hitTolerance: 3,
        }
      );
      e.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    this.map.on('singleclick', evt => {
      let feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        },
        {
          hitTolerance: 3,
        }
      );
      if (feature) {
        let featureName = feature.get('name');

        if (featureName.includes('stop')) {
          me.isPanel = true;
          me.panelData = feature.get('dataObj');
          this.openBusStopEvent(me.panelData);
        }
      }
      evt.preventDefault(); //add this to avoid bubling
    });
  }

  openPanelStreet(data) {
    // console.log('openPanelStreet', data);
    // this.isPanel = true;
    this.panelData = data;
    this.openBusStopEvent(this.panelData);
    this.viewAnimate.animate({
      center: fromLonLat([data.stopLon, data.stopLat]),
      duration: 1000,
      zoom: 16,
    });
  }

  addMapStopIcon(stops) {
    // let featureList = [];

    var vectorLayer = new VectorImageLayer({
      source: new VectorSource({
        features: [],
      }),
      className: 'allStops',
      properties: {
        name: 'allStops',
      },
    });

    stops.forEach((stop, index) => {
      let count = index + 1;

      // let mapData = {
      stop.imgSrc = 'assets/images/icon/marker-location-green.svg';
      stop.lat = stop.stopLat;
      stop.lon = stop.stopLon;
      stop.name = 'stop-' + stop.stopCode;
      stop.message = count.toString();
      // stop.message = count < 10 ? '0' + count.toString() : count.toString();
      // }
      this.mapService.addPinLocation(vectorLayer, stop);

      // let source = vectorLayer.getSource();
      // let iconFeature = new OlFeature({
      //   geometry: new Point(transform([parseFloat(stop.stopLon), parseFloat(stop.stopLat)], 'EPSG:4326', 'EPSG:3857')),
      //   name: 'stop-'+stop.stopCode,
      //   dataObj: stop
      // });

      // let iconStyle = new Style({
      //   image: new Icon(/** @type {module:ol/style/Icon~Options} */ ({
      //     anchor: [0.5, 25],
      //     anchorXUnits: 'fraction',
      //     anchorYUnits: 'pixels',
      //     opacity: 1,
      //     src: 'assets/images/icon/map-marker-green.png'
      //   })),
      //   text: new Text({
      //     offsetY: -37,
      //     // offsetX: 5,
      //     text: count.toString(),
      //     fill: new Fill({
      //       color: '#fff'
      //     }),
      //     backgroundFill: new Fill({
      //       color: '#2a2b30'
      //     }),
      //     font: '16px Calibri,sans-serif',
      //     padding: [2, 5, 2, 5]
      //   })
      // });

      // iconFeature.setStyle(iconStyle);
      // featureList.push(iconFeature);
    });

    // var vectorSource = new VectorSource({
    //   features: featureList //add an array of features
    // });

    // var vectorLayer = new VectorLayer({
    //   source: vectorSource,
    //   name: 'allStops'
    // });

    this.map.addLayer(vectorLayer);
    this.mapService.centerLayerMap(this.map, vectorLayer);
    // var extent = vectorLayer.getSource().getExtent();
    // this.map.getView().fit(extent, { duration: 1000 });
  }

  getMiddlePath() {
    var mid = Math.ceil(this.allStops.length / 2);
    return this.allStops[mid];
  }

  private _filter(value: any): any[] {
    if (!value) {
      return;
    }

    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.streetName.toLowerCase().includes(filterValue)
    );
  }

  // private _filterStop(value: any): any[] {
  //   if(!value){
  //     return;
  //   }

  //   const filterValue = value.toLowerCase();
  //   return this.options.flat().filter((option:any) => {option.stopCode.toLowerCase().includes(filterValue)});
  // }

  searchStop(evt) {
    // console.log('this.streetNameBusStop', this.streetNameBusStop);
    if (!(this.form.value.streetNameBusStop?.length > 0)) {
      this._snackBar.open('Street Name must not be empty.', null, {
        duration: 3000,
      });
      return;
    }

    this.removeLayerByLayerName('allStops');

    // var isAllStopLayer = this.mapService.isLayerExist(this.map, 'allStops');
    // if(isAllStopLayer) {
    //   let features = isAllStopLayer.getSource().getFeatures();
    //   features.forEach(element => {
    //     this.mapService.removeLayerFeature(isAllStopLayer, element.get('name'));
    //   });

    //   this.mapService.removeLayerByLayerName(this.map, 'allStops');
    // }

    this.options = [];
    this.streetNameBusStop = this.form.value.streetNameBusStop;

    this.formReset();
    this.isSelected = false;
    // if(Math.sign(this.streetNameBusStop) === 1) {
    //   this.stopManagementService.getStopByCode(this.streetNameBusStop).subscribe(
    //     (data:any) => {
    //       this.isBusCodeSearch = true;
    //       if (data.length == 0) {
    //         this._snackBar.open('No record found', null, {
    //           duration: 2000,
    //         });
    //         this.running = false;
    //       } else {
    //         // let stops = data[0].stops;
    //         // me.stopManagementService.allStops = stops; //assign stop

    //         // this.viewAnimate.animate({
    //         //   center: fromLonLat([stops[0].stopLon, stops[0].stopLat]),
    //         //   duration: 1000,
    //         //   zoom: 16
    //         // });

    //         // me.removeLayerByLayerName('allStops');
    //         // me.addMapStopIcon(stops);
    //         // me.initMapInteraction();

    //         if(data.length === 0) {
    //           this.options.push({'streetNameBusStop': 'No stops available', 'isDisable': true});
    //         }

    //         data.forEach(element => {
    //           this.options.push(element.stops);
    //           element.stops.forEach(element2 => {
    //             element2.streetName = element.streetName;
    //           });
    //         });

    //         this.filteredOptions = this.form.get('streetNameBusStop').valueChanges.pipe(
    //           startWith(''),
    //           map(value => value !== '' ? this._filterStop(value) : this.options.flat().slice())
    //         );
    //         evt.stopPropagation();
    //         this.inputSearchStreet.openPanel();
    //         this.running = false;
    //         this.snackbarEnd();
    //       }
    //     }
    //   )
    // }
    // else {
    // let url = this.configService.apiServerUrl + 'gtfsupload/v1/stops/getStopsByStreetName/'+this.streetNameBusStop;
    // let body = {
    //   routeId: this.form.value.route,
    //   startDate: moment(this.form.value.startDate).format('YYYY-MM-DD'),
    //   endDate: moment(this.form.value.endDate).format('YYYY-MM-DD')
    // };
    this.snackbarStart('Searching...');

    var urlAction =
      'gtfsupload/v1/stops/getStopDetail/' + this.streetNameBusStop;
    this.commonService
      .commonPostAction(urlAction, '')
      .pipe(
        // this.httpClient.post(url, body).pipe(
        map((data: any) => {
          if (data.length == 0) {
            throw new Error('No record found');
          } else {
            return data;
          }
        })
      )
      .subscribe(
        (data: any) => {
          this.isBusCodeSearch = false;

          if (data.length === 0) {
            this.options.push({
              streetNameBusStop: 'No street available',
              isDisable: true,
            });
          }

          //sort data
          data.sort((a, b) => (a.streetName > b.streetName ? 1 : -1));
          data.forEach(element => {
            this.options.push(element);
          });

          if (data.length > 1) {
            this.filteredOptions = this.form
              .get('streetNameBusStop')
              .valueChanges.pipe(
                startWith(''),
                map(value =>
                  value !== '' ? this._filter(value) : this.options.slice()
                )
              );
            evt.stopPropagation();
            this.inputSearchStreet.openPanel();
          } else if (data[0] !== undefined) {
            //if result is one default add
            this.selectedStreet(data[0]);
            // let checkboxDefault = {
            //   checked: true
            // }
            data[0].stops[0].isChecked = true;
            // this.addSelectedCount(checkboxDefault, data[0].stops[0]);
          } else {
            this._snackBar.open('No available street or bus stop.', null, {
              duration: 2000,
            });
          }

          // this.filteredOptions = this.form.get('streetNameBusStop').valueChanges.pipe(
          //   startWith(''),
          //   map(value => value !== '' ? this._filter(value) : this.options.slice())
          // );
          // evt.stopPropagation();
          // this.inputSearchStreet.openPanel();
          this.running = false;
          this.snackbarEnd();
        },
        (error: any) => {
          this._snackBar.open('No record found', null, {
            duration: 2000,
          });
          this.running = false;
        }
      );
    // }
  }

  removeLayerByLayerName(dataLayerName) {
    // this.map.getLayers().forEach(layer => {
    //   if (layer && layer.get('name') === dataLayerName) {
    //     this.map.removeLayer(layer);
    //   }
    // });
    var isAllStopLayer = this.mapService.isLayerExist(this.map, dataLayerName);
    if (isAllStopLayer) {
      let features = isAllStopLayer.getSource().getFeatures();
      features.forEach(element => {
        this.mapService.removeLayerFeature(isAllStopLayer, element.get('name'));
      });

      this.mapService.removeLayerByLayerName(this.map, dataLayerName);
    }
  }

  snackbarStart(message: string) {
    this.running = true;

    this._snackBar.open(message);
  }

  snackbarEnd() {
    this._snackBar.dismiss();

    this.running = false;
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/system-configuration/stop-search/2',
      'Stop Management',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  initStopModal() {
    this.modalAddStopData = {
      modalTarget: 'modalStop',
      title: 'Add | Edit Stop',
      body: '<app-add-stop-modal></app-add-stop-modal>',
    };
  }

  addStop() {
    // var allStopLayer2 = this.mapService.isLayerExist(this.map, 'allStops');
    // var middlePath;
    // if(this.allStops !== undefined) {
    //   // var middlePath = Math.ceil(this.allStops.length / 2);
    //   middlePath = this.getMiddlePath();
    // }
    // lon = 103.831435, lat = 1.303312 ORCHARD MRT
    let drv = {
      // streetName: this.form.value.streetNameBusStop,
      // stopCode: null,
      // stopName: null,
      // trainStation: null,
      // lat: this.allStops === undefined ? environment.map.map3dLocation[1] : this.allStops[0].stopLat,
      // lon: this.allStops === undefined ? environment.map.map3dLocation[0] : this.allStops[0].stopLon,
      // lat: 25.284843,
      // lon: 51.53645,
      // busTerminal: null,
      // allTrainStation: this.allTrainStation,
      action: 'add',
    };

    // const dialogConfig = new MatDialogConfig();

    // dialogConfig.autoFocus = true;
    // dialogConfig.data = drv;

    var dialogData = {
      autoFocus: true,
      data: drv,
    };

    // dialogConfig.width = '50vh';
    // dialogConfig.height = '50vh';
    // this.dialog.open(AddStopModalComponent, dialogConfig);

    const dialogRef = this.dialog.open(AddStopModalComponent, dialogData);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && (result?.stopId || result?.stopCode)) {
        let message = this.allStops.length + 1;

        // this.updateListStops(result);
        if (result?.stopId) {
          result.stopCode = result.stopId;
        }

        result.imgSrc = 'assets/images/icon/map-marker-green.png';
        result.lat = result.stopLat;
        result.lon = result.stopLon;
        result.name = 'stop-' + result.stopCode;
        // result.message = message.toString();
        result.message =
          parseInt(message) < 10 && message.toString().length < 2
            ? '0' + message.toString()
            : message.toString();
        this.allStops.push(result);

        let allStopLayer = this.mapService.isLayerExist(this.map, 'allStops');
        this.mapService.addPinLocation(allStopLayer, result);
        this.mapService.centerLayerMap(this.map, allStopLayer);
      }
    });
  }

  saveStop(event) {
    console.log('Save Stop: ', event);
  }

  editStop(data) {
    console.log('stop data', data);
    let drv = {
      // streetName: this.form.value.streetNameBusStop,
      stopCode: data.stopCode,
      // stopName: data.stopName,
      // trainStation: data.trainStation,
      // lat: data.stopLat,
      // lon: data.stopLon,
      // busTerminal: data.busTerminal,
      // stationCode: data.mrtStationCode,
      // stationName: data.mrtStationName,
      message: data.message,
      // allTrainStation: this.allTrainStation,
      action: 'edit',
    };

    // const dialogConfig = new MatDialogConfig();

    // dialogConfig.autoFocus = true;
    // dialogConfig.data = drv;

    // dialogConfig.width = '50vh';
    // dialogConfig.height = '50vh';
    var dialogData = {
      autoFocus: true,
      data: drv,
    };

    const dialogRef = this.dialog.open(AddStopModalComponent, dialogData);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // let result = retData.stops[0];
        this.allStops.forEach(element => {
          if (element.stopCode === result.stopCode) {
            element.busTerminal = result.busTerminal;
            element.imgSrc = 'assets/images/icon/map-marker-green.png';
            element.lat = result.stopLat;
            element.lon = result.stopLon;
            element.name = 'stop-' + result.stopCode;
            element.message = result.message;
            element.mrtStationCode = result.mrtStationCode;
            element.mrtStationName = result.mrtStationName;
            element.stopCode = result.stopCode;
            element.stopDesc = result.stopDesc;
            element.stopLat = result.stopLat;
            element.stopLon = result.stopLon;
            element.stopName = result.stopName;
            element.stopStatus = result.stopStatus;
          }
        });

        let allStopLayer = this.mapService.isLayerExist(this.map, 'allStops');
        this.mapService.removeLayerFeature(
          allStopLayer,
          'stop-' + result.stopCode
        );

        this.viewAnimate.animate({
          center: fromLonLat([
            parseFloat(result.stopLon),
            parseFloat(result.stopLat),
          ]),
          duration: 1000,
          zoom: 16,
        });

        result.imgSrc = 'assets/images/icon/map-marker-green.png';
        result.lat = result.stopLat;
        result.lon = result.stopLon;
        result.name = 'stop-' + result.stopCode;
        // result.message = result.message;
        result.message =
          parseInt(result.message) < 10 && result.message.length < 2
            ? '0' + result.message.toString()
            : result.message.toString();
        this.mapService.addPinLocation(allStopLayer, result);
        this.mapService.centerLayerMap(this.map, allStopLayer);
      }
    });
  }

  ngOnChanges() {
    console.log('on change');
  }

  formReset() {
    // this.isvalid = false;
    // this.form = this.formBuilder.group({
    //   streetName: ['', Validators.required]
    // });
    this.allStops = [];
    this.closePanel();
  }

  closePanel() {
    this.isPanel = false;
  }

  closeAction(event) {
    console.log(event);
  }

  openBusStopEvent(featureData) {
    this.stopManagementService
      .getRoutesByStopCode(featureData.stopCode)
      .subscribe((data: any) => {
        console.log(featureData);
        this.panelData.busService = data.busService;
      });
  }

  selectedStreet(data) {
    this.isSelected = true;
    // console.log('Selected Street', data);
    if (this.isBusCodeSearch) {
      let allStopByStopCode = [];
      allStopByStopCode.push(data);
      this.allStops = allStopByStopCode; //assign stop

      this.viewAnimate.animate({
        center: fromLonLat([data.stopLon, data.stopLat]),
        duration: 1000,
        zoom: 16,
      });
      this.streetNameBusStop = data.streetName;
      this.removeLayerByLayerName('allStops');
      this.addMapStopIcon(allStopByStopCode);
      this.initMapInteraction();
    } else {
      if (data.streetName === 'No street available') {
        return false;
      }
      let stops = data.stops;
      this.allStops = stops; //assign stop

      this.streetNameBusStop = data.streetName;
      this.removeLayerByLayerName('allStops');
      this.addMapStopIcon(stops);
      this.initMapInteraction();
      // let middlePath = this.getMiddlePath();

      // this.viewAnimate.animate({
      //   center: fromLonLat([middlePath.stopLon, middlePath.stopLat]),
      //   duration: 1000,
      //   zoom: 16
      // });
    }
    // if(this.currDirection === 1) {
    //   this.selectedStreetDirection1Data = data;
    // }
    // else {
    //   this.selectedStreetDirection2Data = data;
    // }

    // this.isSearchStreet = true;
    // this.initMapInteraction();

    this.closePanel();
  }

  initTrainStations() {
    var urlAction = 'gtfsupload/v1/stops/getMRTStationList';
    this.commonService
      .commonPostAction(urlAction, '')
      .subscribe((dataRes: any) => {
        this.allTrainStation = dataRes;
      });
  }

  //start map options
  //TODO: move to service
  glRoadmap: any;
  glTerrain: any;
  glSatellite: any;
  glHybrid: any;
  trafficLayer;
  olGM;
  onChangeProvider(provider) {
    if (provider === 'GoogleMap' && this.currGoogleLayer === '') {
      this.initGoogleMap();
    }
    this.provider = provider;
    this.layerMapSetVisibility(provider);
  }

  initGoogleMap() {
    this.currGoogleLayer = 'roadmap';
    if (!this.glRoadmap) {
      this.glRoadmap = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        name: 'gm-roadmap',
        visible: true,
      });
      this.map.addLayer(this.glRoadmap);
    }

    if (!this.glTerrain) {
      this.glTerrain = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        name: 'gm-terrain',
        visible: false,
      });
      this.map.addLayer(this.glTerrain);
    }

    if (!this.glHybrid) {
      this.glHybrid = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.HYBRID,
        name: 'gm-hybrid',
        visible: false,
      });
      this.map.addLayer(this.glHybrid);
    }

    if (!this.glSatellite) {
      this.glSatellite = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        name: 'gm-satellite',
        visible: false,
      });
      this.map.addLayer(this.glSatellite);
    }

    if (!this.trafficLayer) {
      this.trafficLayer = new google.maps.TrafficLayer();
    }

    if (!this.olGM) {
      this.olGM = new OLGoogleMaps({
        map: this.map,
      }); // map is the Map instance

      this.olGM.activate();
    }
  }

  layerMapSetVisibility(mapProvider) {
    if (mapProvider === 'GoogleMap' && this.currGoogleLayer === 'roadmap') {
      this.glRoadmap.setVisible(true);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = true;
      // this.isMapType = true;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'terrain'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(true);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = true;
      // this.isMapType = false;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'satellite'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(true);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = false;
      // this.isMapType = false;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'hybrid'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(true);

      // this.isTrafficLayer = true;
      // this.isMapType = false;
    }

    if (this.OSMLayer && mapProvider !== 'OpenStreetMap') {
      this.OSMLayer.setVisible(false);
    } else if (this.OSMLayer && mapProvider === 'OpenStreetMap') {
      this.OSMLayer.setVisible(true);
    }
  }

  trafficToggle(e) {
    var isTraffic = e.checked; //!this.isTraffic;

    const gmap = this.olGM.getGoogleMapsMap();
    if (isTraffic) {
      this.trafficLayer.setMap(gmap);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  onChangeMapType(data) {
    this.currGoogleLayer = data;

    let layers = this.map.getLayers().getArray().slice();

    for (let row of layers) {
      let layerName = row.get('name');
      // this.map.removeLayer(row);
      if (row instanceof GoogleLayer) {
        if (layerName === 'gm-roadmap' && data === 'roadmap') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-terrain' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-terrain' && data === 'terrain') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-satellite' && data === 'satellite') {
          row.setVisible(true);
          // this.isTrafficLayer = false;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-terrain'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-hybrid' && data === 'hybrid') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-terrain' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else {
          row.setVisible(false);
        }
      }
    }
  }

  onUpload() {
    console.log('upload');
  }

  onExport() {
    console.log('export');
  }

  menuSubscription: Subscription;
  watchMenuChange() {
    const me = this;
    this.menuSubscription = this.menuService.getPinned().subscribe(value => {
      me._spinner.show();
      setTimeout(function () {
        if (me?.map) {
          me.map.updateSize();
        }
      }, 150);
      setTimeout(() => me._spinner.hide(), 350);
    });
  }

  clearStreetSearch(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.form.controls.streetNameBusStop.reset();
  }

  ngOnDestroy() {
    if (this.menuSubscription) {
      this.menuSubscription?.unsubscribe?.();
    }
  }
}
