<h3>Candle Chart</h3>

<div class="chart-box" [formGroup]="form">
  
  <mat-form-field >
    <mat-label>Vehicle</mat-label>
    <mat-select formControlName="vehicle">
        <mat-option value="all">All</mat-option>
        <mat-option *ngFor="let vehicle of vehicleList" [value]="vehicle">
          {{vehicle}}
        </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="startDate" placeholder="Start Date" formControlName="startDate">
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="endDate" placeholder="End Date" formControlName="endDate">
    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate></mat-datepicker>
  </mat-form-field>

  <div *ngIf="!isRunning">
    <div id="chart-candlestick">
      <apx-chart
        #chartCandle
        [series]="chartCandleOptions.series"
        [chart]="chartCandleOptions.chart"
        [xaxis]="chartCandleOptions.xaxis"
        [plotOptions]="chartCandleOptions.plotOptions"
      ></apx-chart>
    </div>
    <div id="chart-bar">
      <apx-chart
        #chartBar
        [series]="chartBarOptions.series"
        [chart]="chartBarOptions.chart"
        [xaxis]="chartBarOptions.xaxis"
        [yaxis]="chartBarOptions.yaxis"
        [dataLabels]="chartBarOptions.dataLabels"
        [stroke]="chartBarOptions.stroke"
        [plotOptions]="chartBarOptions.plotOptions"
      ></apx-chart>
    </div>
  </div>
</div>
