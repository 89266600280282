<div class="div-container">
    <h2>Mock Bus</h2>
    <div>
        <button mat-button (click)="initBus()">
            Add Bus
        </button>
    </div>
    <div class="list-bus-container">
        <div *ngFor="let bus of listBus" class="bus-item">
            {{bus.busName}}
            <div [id]="bus.busName"></div>
        </div>
    </div>
</div>