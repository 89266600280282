<div class="call-panel" app-broadcast fxLayout="column">
  <div class="call-title">
    <button
      *ngIf="_jitsiService.status === 'fail'"
      mat-icon-button
      (click)="reloadJitsi()"
      class="icon-button">
      <mat-icon matTooltip="Reload" [class.icon-spinner]="loading"
        >refresh</mat-icon
      >
    </button>
    <span>{{ agencyName }} Broadcast Channel</span>
  </div>
  <div class="call-body">
    <div class="call-menu">
      <div *ngIf="isJitsiHidden; else participantList" class="loading-menu">
        <ngx-spinner
          bdColor="transparent"
          size="default"
          color="#fff"
          type="ball-clip-rotate"
          [fullScreen]="false"></ngx-spinner>
      </div>
      <ng-template #participantList>
        <div class="call-header">
          <ng-container *ngIf="roomTitle === LOBBY; else altTitle">{{
            roomTitle
          }}</ng-container>
          <ng-template #altTitle>{{
            _currentRoomName !== '' ? _currentRoomName : roomTitle
          }}</ng-template>
          ({{ (_participants | keyvalue)?.length }})
        </div>
        <div class="call-list">
          <perfect-scrollbar #callPS [config]="config">
            <ng-container
              *ngFor="
                let participant of _participants | keyvalue: sortSelfFirst;
                let j = index
              ">
              <div
                class="call-item"
                *ngIf="participant?.value?.displayName"
                [class.call-item-selection]="
                  roomTitle === LOBBY &&
                  isSelecting &&
                  !participant?.value?.displayName.includes('(me)')
                "
                [class.action-hover]="
                  roomTitle === BREAKOUT &&
                  isRoomCreator &&
                  !participant?.value?.displayName.includes('(me)')
                "
                (click)="toggleSelection(participant)"
                (mouseenter)="mainHoverIndex = j"
                (mouseleave)="mainHoverIndex = -1">
                <ng-container
                  *ngIf="
                    roomTitle === LOBBY &&
                    isSelecting &&
                    !participant?.value?.displayName.includes('(me)')
                  ">
                  <mat-icon
                    color="white"
                    *ngIf="!selectedUsers.includes(participant.key)"
                    >check_box_outline_blank</mat-icon
                  >
                  <mat-icon
                    color="white"
                    *ngIf="selectedUsers.includes(participant.key)"
                    >check_box</mat-icon
                  >
                </ng-container>
                <div
                  *ngIf="
                    !isSelecting ||
                    participant?.value?.displayName.includes('(me)')
                  "
                  class="user-icon">
                  <img
                    *ngIf="participant?.value?.displayName?.includes('BUS')"
                    [src]="
                      participant?.value?.avatarURL
                        ? participant?.value?.avatarURL
                        : 'assets/images/icon/bus.png'
                    " />
                  <img
                    *ngIf="participant?.value?.displayName?.includes('OPS')"
                    [src]="
                      participant?.value?.avatarURL
                        ? participant?.value?.avatarURL
                        : 'assets/images/icon/operator.png'
                    " />
                </div>
                <span
                  class="ml-2"
                  [class.call-item-selected]="
                    selectedUsers.includes(participant.key)
                  "
                  >{{ participant.value.displayName }}</span
                >
                <button
                  *ngIf="
                    roomTitle === BREAKOUT &&
                    isRoomCreator &&
                    !participant?.value?.displayName.includes('(me)')
                  "
                  mat-button
                  class="list-button kick-button"
                  [class.hidden]="mainHoverIndex !== j"
                  (click)="kickBreakoutRoom(participant?.key)">
                  <mat-icon inline>person_remove</mat-icon>
                </button>
              </div>
            </ng-container>
            <ng-container
              *ngFor="
                let breakoutRoomItem of _breakoutRooms
                  | keyvalue: sortAnnouncementFirst;
                trackBy: trackByKey
              ">
              <ng-container
                *ngIf="
                  breakoutRoomItem?.key !== _currentRoomId &&
                    !!breakoutRoomItem?.value?.isMainRoom;
                  else otherRooms
                ">
                <mat-expansion-panel class="collapsible-item">
                  <mat-expansion-panel-header
                    matRipple
                    class="panel-header"
                    collapsedHeight="auto"
                    expandedHeight="auto">
                    <mat-panel-title class="other-call-header">
                      <div class="other-call-header">
                        {{ lobbyTitle }} ({{
                          (breakoutRoomItem?.value?.participants | keyvalue)
                            ?.length ?? 0
                        }})
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container
                    *ngFor="
                      let participant of breakoutRoomItem?.value?.participants
                        | keyvalue: sortAlphabetical;
                      trackBy: trackByKey;
                      let i = index
                    ">
                    <div
                      class="other-call-item"
                      [class.action-hover]="
                        roomTitle === BREAKOUT && isRoomCreator
                      "
                      *ngIf="participant?.value?.displayName"
                      (mouseenter)="hoverIndex = i"
                      (mouseleave)="hoverIndex = -1">
                      <span>{{ participant?.value?.displayName }}</span>
                      <button
                        *ngIf="isRoomCreator"
                        mat-button
                        class="list-button invite-button"
                        [class.hidden]="hoverIndex !== i"
                        (click)="inviteBreakoutRoom(participant?.value)">
                        <mat-icon inline>person_add</mat-icon>
                      </button>
                    </div>
                  </ng-container>
                </mat-expansion-panel>
                <!-- <div class="other-call-header">
                                    {{ breakoutRoomItem?.value?.name }}
                                    <span> ({{(breakoutRoomItem?.value?.participants | keyvalue)?.length ?? 0}})</span>
                                </div>
                                <ng-container *ngFor="let participant of breakoutRoomItem?.value?.participants | keyvalue: sortAlphabetical; let i = index">
                                    <div 
                                        class="other-call-item"
                                        [class.action-hover]="roomTitle === BREAKOUT && isRoomCreator"
                                        *ngIf="participant?.value?.displayName" 
                                        (mouseenter)="hoverIndex = i" 
                                        (mouseleave)="hoverIndex = -1"
                                    >
                                        <span>{{ participant?.value?.displayName }}</span>
                                        <button *ngIf="isRoomCreator" mat-button class="list-button invite-button" [class.hidden]="hoverIndex !== i" (click)="inviteBreakoutRoom(participant?.value)">
                                            <mat-icon inline>person_add</mat-icon> 
                                        </button>
                                    </div>
                                </ng-container> -->
              </ng-container>
              <ng-template #otherRooms>
                <ng-container
                  *ngIf="
                    breakoutRoomItem?.key !== _currentRoomId &&
                    (breakoutRoomItem?.value?.participants | keyvalue)?.length
                  ">
                  <!-- <div class="other-call-header" [class.clickable-header]="roomTitle === LOBBY && !isSelecting" (click)="joinBreakoutRoom(breakoutRoomItem?.value)"> -->

                  <mat-expansion-panel class="collapsible-item">
                    <mat-expansion-panel-header
                      matRipple
                      class="panel-header"
                      collapsedHeight="auto"
                      expandedHeight="auto">
                      <mat-panel-title class="other-call-header">
                        {{ breakoutRoomItem?.value?.name }}
                        <ng-container
                          *ngIf="
                            (breakoutRoomItem?.value?.participants | keyvalue)
                              ?.length
                          ">
                          ({{
                            (breakoutRoomItem?.value?.participants | keyvalue)
                              ?.length
                          }})</ng-container
                        >
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container
                      *ngFor="
                        let participant of breakoutRoomItem?.value?.participants
                          | keyvalue: sortAlphabetical;
                        trackBy: trackByKey
                      ">
                      <div
                        class="other-call-item"
                        *ngIf="participant?.value?.displayName">
                        <span>{{ participant?.value?.displayName }}</span>
                      </div>
                    </ng-container>
                  </mat-expansion-panel>
                  <!-- <div class="other-call-header">
                                        {{ breakoutRoomItem?.value?.name }}
                                        <span *ngIf="(breakoutRoomItem?.value?.participants | keyvalue)?.length"> ({{(breakoutRoomItem?.value?.participants | keyvalue)?.length}})</span>
                                    </div> -->
                </ng-container>
              </ng-template>
            </ng-container>
          </perfect-scrollbar>
        </div>

        <ng-container *ngIf="roomTitle === LOBBY">
          <button
            mat-button
            class="call-button action-button"
            *ngIf="hasOngoingRoomCall && !isJitsiHidden && !isSelecting"
            (click)="rejoinOwnBreakoutRoom()">
            <span class="mr-2">Re-join Room</span>
            <mat-icon color="white">meeting_room</mat-icon>
          </button>
          <button
            mat-button
            class="command-button action-button"
            *ngIf="!isJitsiHidden && !isSelecting"
            (click)="startSelection()">
            <span class="mr-2">Invite to Room</span>
            <mat-icon color="white">person_add</mat-icon>
          </button>
          <div
            class="call-footer"
            *ngIf="!isJitsiHidden && !inCall && isSelecting">
            <button
              mat-button
              class="cancel-button mr-2"
              (click)="cancelSelection()"
              [disabled]="inCall">
              <span class="mr-2">Cancel</span>
              <mat-icon color="white">cancel</mat-icon>
            </button>
            <button
              mat-button
              class="call-button ml-2"
              (click)="startCall()"
              [disabled]="inCall || this.selectedUsers?.length === 0">
              <span class="mr-2">Create</span>
              <mat-icon color="white">meeting_room</mat-icon>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="roomTitle === BREAKOUT">
          <button
            mat-button
            class="cancel-button action-button"
            *ngIf="isRoomCreator"
            (click)="endBreakout()">
            <span class="mr-2">End</span>
            <mat-icon color="white">no_meeting_room</mat-icon>
          </button>
          <button
            mat-button
            class="cancel-button action-button"
            *ngIf="!isRoomCreator"
            (click)="leaveBreakoutRoom()">
            <span class="mr-2">Leave Room</span>
            <mat-icon color="white">no_meeting_room</mat-icon>
          </button>
        </ng-container>
      </ng-template>
    </div>
    <div class="call-container">
      <div *ngIf="jitsiError" class="error-message">
        Initialization Error. Please refresh the page.
      </div>
      <div
        id="broadcastcallframe"
        class="call-frame"
        #broadcastcallframe
        [class.hidden]="isJitsiHidden"></div>
    </div>
  </div>
</div>
