import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent implements OnInit {
  @Input() data:any;
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() okayAction: EventEmitter<any> = new EventEmitter<any>();
  $timerObj;
  timer: number = 60;

  constructor(
    public dialogRef: MatDialogRef<TimeoutModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any) {}

  ngOnInit() {
    // console.log(this.data);
    // console.log(this.dataDialog);
    // this.modalId = this.data.target;
    // this.bodyModal = this.data.body;
    var me = this;
    me.$timerObj = setInterval(function() {
      if(me.timer > 0) {
        me.timer--;
      }
      // console.log(me.timer);
    }, 1000);
  }

  onCloseModal(): void {
    this.dialogRef.close(false);
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
 
  // onDismiss(): void {
  //   // Close the dialog, return false
  //   this.dialogRef.close(false);
  // }
  ngOnDestroy() {
    clearInterval(this.$timerObj);
  }
}