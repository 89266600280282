import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Driver,
  Contact,
} from 'src/app/component/shared/services/openapi/roster';
import {
  STATUS_LIST,
  IDENTIFICATION_TYPES,
  CONTACT_TYPES,
} from '../driver-list.constants';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmModalComponent } from 'src/app/component/shared/services/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['./driver-detail.component.scss'],
})
export class DriverDetailComponent {
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}
  statusList = STATUS_LIST;
  identificationList = IDENTIFICATION_TYPES;
  contactList = CONTACT_TYPES;
  driverDetailData: Driver = {};
  @Input() isLoading: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() parentForm: FormGroup;
  @Output() closeDrawer: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() saveDriver: EventEmitter<Driver> = new EventEmitter<Driver>();
  @Output() deleteDriver: EventEmitter<Driver> = new EventEmitter<Driver>();

  @Input() set driverDetails(value: Driver) {
    // console.log('update detail', value);
    this.driverDetailData = { ...value };
    this.resetDriverForm(value);
  }

  get driverDetails(): Driver {
    return this.driverDetailData;
  }

  get contactDetails(): FormArray {
    return this.parentForm.get('contact_numbers') as FormArray;
  }

  onAddContactDetail() {
    this.addContactDetail(this.contactDetails);
  }

  onRemoveContactDetail(index: number) {
    this.contactDetails.removeAt(index);
  }

  addContactDetail(contactDetail: FormArray, value?: Contact) {
    if (value) {
      const { type, value: contactValue } = value;
      contactDetail.push(
        this.fb.group({
          type: [type, Validators.required],
          value: [contactValue, Validators.required],
        })
      );
    } else {
      contactDetail.push(
        this.fb.group({
          type: ['', Validators.required],
          value: ['', Validators.required],
        })
      );
    }
  }

  resetDriverForm(driverDetail: Driver) {
    if (this.parentForm) {
      const {
        userid,
        family_name,
        first_name,
        middle_name,
        status,
        residential_address,
        mailing_address,
        country_code,
        identification,
        contact_numbers,
      } = driverDetail || {};
      const { identification: identificationId, type: identificationType } =
        identification || {};
      const contactDetailsFormArray = this.parentForm.get(
        'contact_numbers'
      ) as FormArray;
      if (userid) {
        this.parentForm.patchValue({
          userid,
          family_name,
          first_name,
          middle_name,
          status,
          residential_address,
          mailing_address,
          country_code,
          identification: {
            identification: identificationId,
            type: identificationType,
          },
        });
        contactDetailsFormArray.clear();
        contact_numbers.forEach(contact =>
          contactDetailsFormArray.push(this.fb.group(contact))
        );
      } else {
        this.parentForm.patchValue({
          userid: '',
          family_name: '',
          first_name: '',
          middle_name: '',
          status: '',
          residential_address: '',
          mailing_address: '',
          country_code: '',
          identification: {
            identification: '',
            type: '',
          },
          contact_numbers: [],
        });
        contactDetailsFormArray.clear();
      }
      this.parentForm.markAsPristine();
    }
  }

  clickEdit(value: boolean) {
    this.toggleEdit.emit(value);
  }

  clickDelete() {
    const driverData: Driver = this.getFormData();
    this.deleteDriver.emit(driverData);
  }

  clickCancel() {
    if (this.parentForm.dirty) {
      this.onCancelEdit();
    } else {
      this.resetDriverForm(this.driverDetailData);
      this.clickEdit(false);
    }
  }

  onCancelEdit(isClose?: boolean) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        header: 'Discard Changes',
        message: `Are you sure you want to discard your changes?`,
        textConfirm: 'Discard',
        textCancel: 'Cancel',
        mode: 'warn',
      },
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.resetDriverForm(this.driverDetailData);
        this.clickEdit(false);
        if (isClose) {
          this.closeDrawer.emit();
        }
      }
    });
  }

  clickSave() {
    const saveFormData: Driver = this.getFormData();
    this.saveDriver.emit(saveFormData);
  }

  getFormData(): Driver {
    const formData: Driver = this.parentForm.value;
    const { first_name, middle_name, family_name } = formData;
    const formattedData: Driver = {
      ...formData,
      full_name: `${first_name}${
        middle_name?.length > 0 ? ` ${middle_name}` : ''
      }${family_name?.length > 0 ? ` ${family_name}` : ''}`,
    };
    return formattedData;
  }

  clickClose() {
    if (this.isEdit && this.parentForm.dirty) {
      this.onCancelEdit(true);
    } else {
      this.closeDrawer.emit();
    }
  }
}
