<div class="main">
  <div>
    <h3 class="title-header">Trip Cause Code Analysis<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div>

  <div class="criteria-row" *ngIf="routeList">
    <form [formGroup]="routeForm" (ngSubmit)="onSearch()" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <!-- <mat-form-field floatLabel="always">
          <input matInput [matDatepicker]="startDate" placeholder="Start Date" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
          <mat-error *ngIf="rptForm.get('startDate').invalid">Invalid start date</mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <input matInput [matDatepicker]="endDate" placeholder="End Date" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
          <mat-error *ngIf="rptForm.get('endDate').invalid">Invalid end date</mat-error>
        </mat-form-field> -->
        <mat-form-field floatLabel="always">
          <mat-label>Route</mat-label>
          <mat-select formControlName="route">
            <mat-option *ngFor="let service of routeList.routeList" [value]="service"
            (click)="onRouteChange(service)">
              {{service.routeId}} <span class="sm-italic">(Direction {{service.direction}})</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="routeForm.get('route').invalid">Invalid route</mat-error>
        </mat-form-field>
        <!-- <mat-form-field>
          <mat-label>Direction</mat-label>
          <mat-select>
            <mat-option *ngFor="let direction of directionList" [value]="direction.value">
              {{direction.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <button mat-raised-button type="submit" [disabled]="running || !routeForm.valid">Search</button>
        <!-- <button type="button" mat-raised-button (click)="exporter.exportTable('xlsx')"  [disabled]="running">Export</button> -->
      </div>
      <div>
        <button mat-raised-button type="button" (click)="onUploadFile()" class="float-right">Upload</button>
      </div>
    </form>
  </div>

  <div class="table-wrapper">
    <!-- <table mat-table matTableExporter [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="routeId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Route ID</th>
        <td mat-cell *matCellDef="let element"> {{element.routeId}} </td>
      </ng-container>

      <ng-container matColumnDef="busRegNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bus</th>
        <td mat-cell *matCellDef="let element"> {{element.busRegNo}} </td>
      </ng-container>

      <ng-container matColumnDef="driverId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver Id</th>
        <td mat-cell *matCellDef="let element"> {{element.driverId}} </td>
      </ng-container>

      <ng-container matColumnDef="travelledStops">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Stops</th>
        <td mat-cell *matCellDef="let element"> {{element.travelledStops}} </td>
      </ng-container>

      <ng-container matColumnDef="exittime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Exit time</th>
        <td mat-cell *matCellDef="let element"> {{element.exittime}} </td>
      </ng-container>

      <ng-container matColumnDef="entertime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Enter time</th>
        <td mat-cell *matCellDef="let element"> {{element.entertime}} </td>
      </ng-container>

      <ng-container matColumnDef="traveltime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Travel time (mm:ss)</th>
        <td mat-cell *matCellDef="let element"> {{element.traveltime}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator> -->
    
    <!-- (psScrollRight)="onScrollRight($event)" (psScrollLeft)="onScrollRight($event)" (psYReachEnd)="onYReachEnd()" -->
    <table border="0" *ngIf="tripRecords">
      <perfect-scrollbar #chatPS class="ps-tcc" [config]="config" [scrollIndicators]="true" 
      >
        <thead>
          <tr>
            <th class="td-sched" style="z-index: 3;"></th>
            <th class="td-sched" style="z-index: 3;">Trip Number</th>
            <th class="td-sched" style="z-index: 3;">Trip Date</th>
            <th class="td-sched" style="z-index: 3;">Vehicle Id</th>
            <th class="td-sched hori-stop-header" *ngFor="let headerSched of tripRecords.stops; let first = first; let i = index">
              <span class="stop-count">{{i+1}}</span>
              <div>{{headerSched}}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let tripRecords of tripRecords.tripRecords">
          <tr *ngFor="let trip of tripRecords.trips">
              
              <td class="td-sched">
                <span class="material-icons pointer" *ngIf="!trip.isInlineEdit">info</span>
                <span class="material-icons pointer" matTooltip="Edit" matTooltipPosition="above" (click)="trip.isInlineEdit = !trip.isInlineEdit" *ngIf="!trip.isInlineEdit">edit</span>
                <span class="material-icons pointer" matTooltip="Cancel" matTooltip Position="above" (click)="trip.isInlineEdit = !trip.isInlineEdit" *ngIf="trip.isInlineEdit">cancel</span>
                <span class="material-icons pointer" matTooltip="Save" matTooltipPosition="above" (click)="onSaveTrip(tripRecords.vehicleRegNo, trip)" *ngIf="trip.isInlineEdit">save</span>
              </td>
              <td>{{trip.tripId}}</td>
              <td>{{trip.tripRecord[0].fullDate | date: 'MMM d, yy'}}</td>
              <td>{{tripRecords.vehicleRegNo}}</td>
              <td class="td-sched" *ngFor="let colData of trip.tripRecord; let first = first; let idx = index">
                
                <ng-container *ngIf="trip.isInlineEdit">
                  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!colData.isDateTime">
                    <!-- <input (blur)="colData.isChanged = true" [ngClass]="{'inline-textbox': trip.isInlineEdit}" matInput 
                    
                    [(ngModel)]="colData.dateTime"
                    > -->
                    
                    <mat-form-field>
                      <mat-select [(ngModel)]="colData.dateTime" (selectionChange)="colData.isChanged = true">
                        <mat-option *ngFor="let source of tccList" [value]="source.detailCode" 
                        
                        matTooltip="{{source.causeDetails}}">
                          {{source.detailCode}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- [(ngModel)]="colData.dateTime" -->
                    <!-- (ngModelChange)="colData.dateTime = $event" -->
                    <!-- [value]="colData.dateTime | date: 'hh:mm'"  -->
                    <span class="material-icons pointer apply-right" 
                    *ngIf="colData.isChanged" (click)="onApplyRight(trip, colData, idx)"
                    matTooltip="Apply to the right">
                      play_arrow
                    </span>
                    <!-- <button mat-mini-fab *ngIf="colData.isChanged" (click)="onApplyRight(trip, colData, idx)"> -->
                      <!-- <mat-icon *ngIf="colData.isChanged" (click)="onApplyRight(trip, colData, idx)">play_arrow</mat-icon> -->
                    <!-- </button> -->
                  </div>
                </ng-container>
                <ng-container *ngIf="!trip.isInlineEdit || colData.isDateTime">
                  <div class="{{colData.dateTime | slice:0:2 | lowercase}}">{{colData.dateTime}}</div>
                </ng-container>
                <!-- <ng-container *ngIf="trip.isInlineEdit && !colData.isDateTime">
                  {{colData.dateTime}}
                </ng-container> -->
              </td>
            </tr>
            </ng-container>
        </tbody>
      </perfect-scrollbar>
    </table>
  </div>

</div>