import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";

export const darkTimepickerTheme: NgxMaterialTimepickerTheme = {
    container: {
        bodyBackgroundColor: '#252a36',
        buttonColor: '#fff'
    },
    dial: {
        dialBackgroundColor: '#252a36',
    },
    clockFace: {
        clockFaceBackgroundColor: '#252a36',
        clockHandColor: '#b3d7ef',
        clockFaceTimeInactiveColor: '#fff'
    },
};
