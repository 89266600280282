<div
  class="login-group"
  [ngStyle]="{
    'background-image': 'url(../../../assets/images/' + loginBg + ')'
  }"
  fxLayout="row"
  fxLayoutAlign="center center">
  <mat-card appearance="raised" fxFlex="17" fxFlex.sm="40" fxFlex.xs="80">
    <mat-card-header>
      <mat-card-title class="login-title">Login</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSignIn()">
        <p>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Email Address</mat-label>
            <input
              type="text"
              matInput
              formControlName="email"
              name="username" />
          </mat-form-field>
        </p>

        <p>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Password</mat-label>
            <input
              type="password"
              matInput
              formControlName="password"
              name="password"
              [type]="isPasswordShow ? 'password' : 'text'" />
            <button
              mat-icon-button
              matSuffix
              (click)="isPasswordShow = !isPasswordShow"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="isPasswordShow"
              type="button">
              <mat-icon>{{
                isPasswordShow ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
          </mat-form-field>
        </p>

        <p *ngIf="loginError" class="error">
          {{ loginError }}
        </p>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <a href="/forgot-password" class="textBlack">Can't login?</a>
          </div>
          <div class="button">
            <button
              type="submit"
              class="btn-sign"
              mat-button
              [class.spinner]="isLoader"
              [disabled]="isLoader">
              Login
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <app-notification-permission></app-notification-permission>
</div>
