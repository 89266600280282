import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, Validators, ValidatorFn, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { environment } from 'src/environments/environment';
// import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
// import { UserElement, USER_STATUS } from 'src/app/component/shared/others/constant';
// import { DRIVER_STATUS, DriverElement, PmDriverService } from '../pm-driver.service';

export interface UserElement {
  busRegNo: string,
  date: string
}

@Component({
  selector: 'app-dateset-manual-update',
  templateUrl: './dateset-manual-update.component.html',
  styleUrls: ['./dateset-manual-update.component.scss']
})
export class DatesetManualUpdateComponent implements OnInit {

  // driverStatuses: any = USER_STATUS;
  // form: FormGroup;
  data: any;
  title: string;
  subTitle: string;
  displayedColumns: string[] = ['Select', 'Bus Reg No'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DatesetManualUpdateComponent>, 
    @Inject(MAT_DIALOG_DATA) data: UserElement, 
    private _commonService: CommonService,
    private _snackBar: MatSnackBar
    ) {
    
    this.data = data;
  }

  ngOnInit() {
    this.title = 'Manual Updates';
    this.subTitle = undefined;
    this.displayedColumns.push(moment().format('YYYY-MM-DD'));
    this.displayedColumns.push(moment().add(1, 'day').format('YYYY-MM-DD'));
    this.displayedColumns.push(moment().add(2, 'day').format('YYYY-MM-DD'));
    this.dataSource.data = this.data.data;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 10);
  }

  onSubmit() {
    var newData = [];
    this.data.data.forEach(element => {
      if(element.isChecked) {
        newData.push({
          busRegNo: element.busRegNo,
          dates: element.dates
        });
      }
    });
    let urlAction = 'gtfsupload/v1/dataset/sendDatasetUpdateReminderToBusList';
    this._commonService.commonPostAction(urlAction, newData).subscribe(
      (respData:any) => {
        this._snackBar.open(respData.response, null, {
          duration: 2000,
        });
        this.onClose();
      }, 
      error => {
        this._snackBar.open(error.statusText, null, {
          duration: 2000,
        });
      }
    )
  }

  onClose() {
    this.dialogRef.close();
  }

  onCheckAll(data) {
    if(data.checked) {
      this.data.data.forEach(element => {
        element.isChecked = true;
      });
    }
    else {
      this.data.data.forEach(element => {
        element.isChecked = false;
      });
    }
  }

  onCheck(data, event) {
    var elem = this.data.data.find(x=> x.busRegNo === data.busRegNo);
    elem.isChecked = event.checked;
  }

}
