import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DataManagementService } from 'src/app/component/shared/services/data-management.service';
import { PmService } from '../../../personnel-management/pm.service';
import { LazyLoadEvent } from 'src/app/component/common/lazyloadevent';
import { Table } from 'primeng/table';

//start map
import OlMap from 'ol/Map';
import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import { Vector as VectorLayer } from 'ol/layer.js';
import OlView from 'ol/View';
import { fromLonLat } from 'ol/proj';
import {transform} from 'ol/proj.js';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {defaults as defaultInteractions, Pointer as PointerInteraction} from 'ol/interaction.js';
import { Icon, Style } from 'ol/style.js';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorSource } from 'ol/source.js';
//end map

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss']
})
export class StopsComponent implements OnInit {
  stopsData = [];
  totalRecords: number;
  cols: any[];
  isGetAllStops:boolean = false;

  // @ViewChild('dt') dt: Table;
  // datasource = [];
  // loading: boolean;
  selectedStop;

  newStop: boolean;
  stop = {};
  displayDialog: boolean;

  //start map
  map: OlMap;
  source: OlXYZ;
  OSMLayer: OlTileLayer<any>;
  mapLayer: VectorLayer<any>[] = [];
  //end map

  stopForm:FormGroup = null;
  isvalid:boolean = false;

  constructor(private dmService: DataManagementService, private pmService: PmService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    let currPage = 1;
    this.dmService.getAllStops(currPage).then(
      (ret:any) => {
        this.stopsData = ret;
        this.isGetAllStops = true;
        // console.log(this.stopsData);
        // this.datasource = ret;
        // this.totalRecords = this.datasource.length;
        // console.log(this.stopsData);
      }
    ); 

    this.cols = [
      { field: 'busStopName', header: 'Stop Name'}
    ];

    this.formReset();
    // this.loading = true;

    // this.initMap();
    
    // this.source = 

    // let OSMLayer =
    
    // this.mapLayer.push(OSMLayer);

    // this.map.getTargetElement().hidden = false;
    // this.map.updateSize();
  }

  onRowSelect(event) {
    this.newStop = false;
    this.stop = event;//this.cloneCar(event.data);
    this.displayDialog = true;

    setTimeout(() => {
      // let markList = [];
      var markStyle = new Style({
        image: new Icon(/** @type {module:ol/style/Icon~Options} */ ({
          anchor: [0.5, 30],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'assets/images/icon/map-marker-blue.png',
        }))
      })

      let markFeature = new Feature({
        type: 'icon',
        geometry: new Point(transform([event.busStopLon, event.busStopLat], 'EPSG:4326', 'EPSG:3857')),
      });

      markFeature.setStyle(markStyle);
      let currLayer = 'mark-stop';

      if(this.map) {
        this.map.getView().setCenter(transform([event.busStopLon, event.busStopLat], 'EPSG:4326', 'EPSG:3857'));
        this.map.getView().setMaxZoom(19);
        
        let layers = this.map.getLayers().getArray().slice();
        
        for (const rowLayer of layers) {
          let layerName = rowLayer.get('name'); 
          if(currLayer === layerName && rowLayer instanceof VectorLayer) {
            let source = rowLayer.getSource();
            let features = source.getFeatures();
            source.removeFeature(features[0]);
            source.addFeature(markFeature);
          }
        }

      }
      else {
        const drag = this.initDrag();

        this.map = new OlMap({
          target: 'map1',
          interactions: defaultInteractions().extend([new drag()]),
          layers: [
            new OlTileLayer({
              source: new OlXYZ({
                url: 'https://tile.osm.org/{z}/{x}/{y}.png'
                // url: 'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}'
              }),
            }),
            new VectorLayer({
              source: new VectorSource({
                features: [markFeature]
              }),
              className: currLayer,
              properties: {
                name: currLayer
              }
            })
          ],
          view: new OlView({
            // center: fromLonLat([event.busStopLon, event.busStopLat]),
            center: fromLonLat([event.busStopLon, event.busStopLat]),
            zoom: 18,
            // minZoom: 19,
            maxZoom: 19
          })
        });
      }
    }, 100);
  }

  // convenience getter for easy access to form fields
  get f() { return this.stopForm.controls; }

  formReset() {
    this.isvalid = false;
    this.stopForm = this.formBuilder.group({
      busStopName: ['', Validators.required],
      busStopLon: ['', Validators.required],
      busStopLat: ['', Validators.required]
    });
  }

  onSubmit() {
    this.isvalid = true;
    // const stopForm = this.stopForm.value;

    // if(stopForm.isTemporary === 'N') {
    //   this.stopForm.controls.lastDutyDate.disable();
    // }

    // stop here if form is invalid
    if (this.stopForm.invalid) {
      console.log('invalid');
      return;
    }
    console.log('valid');
  }

  initMap() {
  }

  initDrag() {
    const me = this;
      /**
       * @constructor
       * @extends {module:ol/interaction/Pointer}
       */
      return (function (PointerInteraction) {
        function Drag() {
          PointerInteraction.call(this, {
            handleDownEvent: handleDownEvent,
            handleDragEvent: handleDragEvent,
            handleMoveEvent: handleMoveEvent,
            handleUpEvent: handleUpEvent
          });

          /**
           * @type {module:ol/pixel~Pixel}
           * @private
           */
          this.coordinate_ = null;

          /**
           * @type {string|undefined}
           * @private
           */
          this.cursor_ = 'pointer';

          /**
           * @type {module:ol/Feature~Feature}
           * @private
           */
          this.feature_ = null;

          /**
           * @type {string|undefined}
           * @private
           */
          this.previousCursor_ = undefined;
        }

        if ( PointerInteraction ) Drag.__proto__ = PointerInteraction;
        Drag.prototype = Object.create( PointerInteraction && PointerInteraction.prototype );
        Drag.prototype.constructor = Drag;

        return Drag;
      }(PointerInteraction));
      
      /**
       * @param {module:ol/MapBrowserEvent~MapBrowserEvent} evt Map browser event.
       * @return {boolean} `true` to start the drag sequence.
       */
      function handleDownEvent(evt) {
        var map = evt.map;

        var feature = map.forEachFeatureAtPixel(evt.pixel,
          function(feature) {
            return feature;
          });

        if (feature) {
          this.coordinate_ = evt.coordinate;
          this.feature_ = feature;
        }

        return !!feature;
      }

      /**
       * @param {module:ol/MapBrowserEvent~MapBrowserEvent} evt Map browser event.
       */
      function handleDragEvent(evt) {
        var deltaX = evt.coordinate[0] - this.coordinate_[0];
        var deltaY = evt.coordinate[1] - this.coordinate_[1];

        var geometry = this.feature_.getGeometry();
        geometry.translate(deltaX, deltaY);

        this.coordinate_[0] = evt.coordinate[0];
        this.coordinate_[1] = evt.coordinate[1];
      }

      /**
       * @param {module:ol/MapBrowserEvent~MapBrowserEvent} evt Event.
       */
      function handleMoveEvent(evt) {
        if (this.cursor_) {
          var map = evt.map;
          var feature = map.forEachFeatureAtPixel(evt.pixel,
            function(feature) {
              return feature;
            });
          var element = evt.map.getTargetElement();
          if (feature) {
            if (element.style.cursor != this.cursor_) {
              this.previousCursor_ = element.style.cursor;
              element.style.cursor = this.cursor_;
            }
          } else if (this.previousCursor_ !== undefined) {
            element.style.cursor = this.previousCursor_;
            this.previousCursor_ = undefined;
          }
        }
      }

      /**
       * @return {boolean} `false` to stop the drag sequence.
       */
      function handleUpEvent(evt) {
        var lonlat = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
        var lon = lonlat[0];
        var lat = lonlat[1];
        for (const attrIndex in me.stop) {
          me.stop[attrIndex] = me.stop[attrIndex];
          if(attrIndex === 'busStopLat') {
            me.stop[attrIndex] = lat;
          }
          else if(attrIndex === 'busStopLon') {
            me.stop[attrIndex] = lon;
          }
        }
        this.coordinate_ = null;
        this.feature_ = null;
        return false;
      }
  }

  // cloneCar(c) {
  //     let stop = {};
  //     for (let prop in c) {
  //         stop[prop] = c[prop];
  //     }
  //     return stop;
  // }

  // clonedStops = {};
  // onRowEditInit(stop) {
  //   console.log(stop);
  //   this.clonedStops[stop.stopId] = {...stop};
  // }

  // onRowEditSave(stop) {
  //   if (stop) {
  //       delete this.clonedStops[stop.stopId];
  //       // this.messageService.add({severity:'success', summary: 'Success', detail:'Car is updated'});
  //   }
  //   else {
  //       // this.messageService.add({severity:'error', summary: 'Error', detail:'Year is required'});
  //   }
  // }

  // onRowEditCancel(stop, index: number) {
  //   this.stopsData[index] = this.clonedStops[stop.stopId];
  //   delete this.clonedStops[stop.stopId];
  // }

  // columnFilter(event: any, field) {
  //   this.dt.filter(event.target.value, field, 'contains');
  // }

  loading: boolean;
  loadCarsLazy(event: LazyLoadEvent) {
    console.log(event);
      this.loading = true;

      //in a real application, make a remote request to load data using state metadata from event
      //event.first = First row offset
      //event.rows = Number of rows per page
      //event.sortField = Field name to sort with
      //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
      //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

      //imitate db connection over a network
      // setTimeout(() => {
          // if (this.datasource) {
          //     this.stopsData = this.datasource.slice(event.first, (event.first + event.rows));
          //     this.loading = false;
          //     this.changeDetectorRef.detectChanges();
          // }
      // }, 0);
  }

  onPaginate(event: LazyLoadEvent) {
    // let page = event.first/event.rows + 1;
    console.log(event);
    const page = event.first/event.rows + 1;
    console.log(page);
    
    this.dmService.getAllStops(page).then(
      (ret:any) => {
        this.stopsData = ret;
        // this.trips = ret;
      }
    );
  }
}