import { Component, OnInit, ViewChild } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MatTableDataSource, MatPaginator, MatTable, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
// import moment, { weekdays } from 'moment';
// import { map, delay } from 'rxjs/operators';
// import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
// import { MapService } from 'src/app/component/shared/services/map.service';
// import OlMap from 'ol/Map';
// import OlZoom from 'ol/control/Zoom';
// import OlXYZ from 'ol/source/XYZ';
// import OlTileLayer from 'ol/layer/Tile';
// import OlView from 'ol/View';
// import Point from 'ol/geom/Point';
// import OlFeature from 'ol/Feature';
// import {Vector as VectorSource} from 'ol/source.js';
// import {Vector as VectorLayer, Image as ImageLayer} from 'ol/layer.js';
// import {Circle, Fill, Icon, Stroke, Style, Text} from 'ol/style.js';
// import {transform} from 'ol/proj.js';
// import { fromLonLat } from 'ol/proj';
// import OSM from 'ol/source/OSM';
// import { AddStopModalComponent } from '../add-stop-modal/add-stop-modal.component';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
// import { StopManagementService } from 'src/app/component/shared/services/stop-management.service';
import { CommonService } from 'src/app/component/shared/services/common.service';
// import { UploadScheduleComponent } from '../upload-schedule/upload-schedule.component';
// import { MatDialogConfig, MatDialog } from '@angular/material';
export var OSMBuildings: any;
import moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { UploadScheduleComponent } from '../schedule-management/upload-schedule/upload-schedule.component';

// const ELEMENT_DATA: any[] = [
//   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', stopCode: '540019'},
//   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He', stopCode: '540020'},
//   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li', stopCode: '540021'},
//   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be', stopCode: '540022'},
//   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B', stopCode: '540023'},
//   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C', stopCode: '540024'},
//   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N', stopCode: '540025'},
//   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O', stopCode: '540026'},
//   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F', stopCode: '540027'},
//   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne', stopCode: '540028'},
// ];

@Component({
  selector: 'app-duty-roster',
  templateUrl: './duty-roster.component.html',
  styleUrls: ['./duty-roster.component.scss'],
})
export class DutyRosterComponent implements OnInit {
  isNewWindow: boolean = false;
  // map: OlMap;
  // modalAddStopData = {};
  panelOpenState = false;

  // @ViewChild(MatSort) sort: MatSort;

  selectedDay = '01';
  selectedDirection = '1';
  selectedServiceData: any = {};
  days: any[] = [
    // {value: 'weekdays', viewValue: 'Weekdays'},
    // {value: 'weekends', viewValue: 'Saturdays'},
    // {value: 'holidays', viewValue: 'Suns/P.Hs'}
  ];
  directions: any[] = [
    // {value: '1', viewValue: '1'}
    // {value: '2', viewValue: '2'}
  ];
  routeId: any = '';
  isDisablePage: boolean = true;
  isBiDirection: boolean = false;
  // routeFilter: string;

  // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  // dataSource = ELEMENT_DATA;

  constructor(
    // private homeService: HomeService,
    // private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    // private mapService: MapService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    // private formBuilder: FormBuilder,
    // public stopManagementService: StopManagementService,
    private commonService: CommonService
  ) {}

  form = new FormGroup({
    streetNameBusStop: new FormControl(null, [Validators.required]),
  });

  running: boolean = false;
  isServiceSchedule: boolean = false;

  @ViewChild('chatPS', { static: true }) chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  @ViewChild('chatPSVert') chatPSVert: PerfectScrollbarComponent;
  public configVert: PerfectScrollbarConfigInterface = {};
  scheduleData: any = {
    detailedData: [
      {
        direction: 1,
        daysType: [
          {
            type: 'weekdays',
            details: {
              scheduleData: [
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21001' },
                    { schedId: '12', arrivalTime: 'B0097' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201282) Noor Salam Piao Mir',
                    },
                    { schedId: '7', arrivalTime: '2101' },
                    { schedId: '4', arrivalTime: '03:30 - 13:50' },
                    { schedId: '8', arrivalTime: '04:00' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '13:19' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '4:00',
                      '5:39',
                      '5:49',
                      '7:17',
                      '7:20',
                      '8:59',
                      'blank',
                      '10:09',
                      '11:37',
                      '11:40',
                      '13:19',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21101' },
                    { schedId: '12', arrivalTime: 'B0098' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201351) Fazal Rehman Almar Khan',
                    },
                    { schedId: '7', arrivalTime: '2102' },
                    { schedId: '4', arrivalTime: '03:40 - 13:50' },
                    { schedId: '8', arrivalTime: '04:09' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '13:17' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '4:09',
                      '5:37',
                      '5:40',
                      '7:19',
                      '7:29',
                      '8:57',
                      'blank',
                      '10:00',
                      '11:39',
                      '11:49',
                      '13:17',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21102' },
                    { schedId: '12', arrivalTime: 'B0099' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201283) Wahid Zaman Said Faqir',
                    },
                    { schedId: '7', arrivalTime: '2103' },
                    { schedId: '4', arrivalTime: '03:50 - 14:10' },
                    { schedId: '8', arrivalTime: '04:20' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '13:39' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '4:29',
                      '5:57',
                      '6:00',
                      '7:39',
                      '7:49',
                      '9:17',
                      'blank',
                      '10:20',
                      '11:59',
                      '12:09',
                      '13:37',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21002' },
                    { schedId: '12', arrivalTime: 'B0100' },
                    { schedId: '3', arrivalTime: '(2201245) Mirza Talib Beg' },
                    { schedId: '7', arrivalTime: '2104' },
                    { schedId: '4', arrivalTime: '04:00 - 14:10' },
                    { schedId: '8', arrivalTime: '04:29' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '13:37' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '4:20',
                      '5:59',
                      '6:09',
                      '7:37',
                      '7:40',
                      '9:19',
                      'blank',
                      '10:29',
                      '11:57',
                      '12:00',
                      '13:39',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21003' },
                    { schedId: '12', arrivalTime: 'B0101' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201110) Khair Mohammad Allah Wasaya',
                    },
                    { schedId: '7', arrivalTime: '2105' },
                    { schedId: '4', arrivalTime: '04:10 - 14:30' },
                    { schedId: '8', arrivalTime: '04:40' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '13:59' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '4:40',
                      '6:19',
                      '6:29',
                      '7:57',
                      '8:00',
                      '9:39',
                      'blank',
                      '10:49',
                      '12:17',
                      '12:20',
                      '13:59',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21103' },
                    { schedId: '12', arrivalTime: 'B0096' },
                    {
                      schedId: '3',
                      arrivalTime: '(2200773) Riaz Hussain Nabi Bakhsh',
                    },
                    { schedId: '7', arrivalTime: '2106' },
                    { schedId: '4', arrivalTime: '04:20 - 14:30' },
                    { schedId: '8', arrivalTime: '04:49' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '13:57' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '4:49',
                      '6:17',
                      '6:20',
                      '7:59',
                      '8:09',
                      '9:37',
                      'blank',
                      '10:40',
                      '12:19',
                      '12:29',
                      '13:57',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21004' },
                    { schedId: '12', arrivalTime: 'B0095' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201183) Gul Sher Khan Sharin',
                    },
                    { schedId: '7', arrivalTime: '2107' },
                    { schedId: '4', arrivalTime: '04:30 - 14:50' },
                    { schedId: '8', arrivalTime: '05:00' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '14:19' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '5:00',
                      '6:39',
                      '6:49',
                      '8:17',
                      '8:20',
                      '9:59',
                      'blank',
                      '11:09',
                      '12:37',
                      '12:40',
                      '14:19',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21104' },
                    { schedId: '12', arrivalTime: 'B0094' },
                    {
                      schedId: '3',
                      arrivalTime: '(2202667) Mujahid Ali Khalid Khan',
                    },
                    { schedId: '7', arrivalTime: '2108' },
                    { schedId: '4', arrivalTime: '04:40 - 14:50' },
                    { schedId: '8', arrivalTime: '05:09' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '14:17' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '5:09',
                      '6:37',
                      '6:40',
                      '8:19',
                      '8:29',
                      '9:57',
                      'blank',
                      '11:00',
                      '12:39',
                      '12:49',
                      '14:17',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21005' },
                    { schedId: '12', arrivalTime: 'B0093' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201505) Abdul Ghafoor Mir Sanad Khan',
                    },
                    { schedId: '7', arrivalTime: '2109' },
                    { schedId: '4', arrivalTime: '04:50 - 15:10' },
                    { schedId: '8', arrivalTime: '05:20' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '14:39' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '5:20',
                      '6:59',
                      '7:09',
                      '8:37',
                      '8:40',
                      '10:19',
                      'blank',
                      '11:29',
                      '12:57',
                      '13:00',
                      '14:39',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21105' },
                    { schedId: '12', arrivalTime: 'B0094' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201431) Eid Muhammad Khan Azmat Khan',
                    },
                    { schedId: '7', arrivalTime: '2110' },
                    { schedId: '4', arrivalTime: '05:00 - 15:10' },
                    { schedId: '8', arrivalTime: '05:29' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '14:37' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'AM SHIFT',
                    shiftData: [
                      '5:29',
                      '6:57',
                      '7:00',
                      '8:39',
                      '8:49',
                      '10:17',
                      'blank',
                      '11:20',
                      '12:59',
                      '13:09',
                      '14:37',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21101' },
                    { schedId: '12', arrivalTime: 'B0093' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201333) Muhammad Ali Khan Jafar',
                    },
                    { schedId: '7', arrivalTime: '2111' },
                    { schedId: '4', arrivalTime: '12:50 - 23:10' },
                    { schedId: '8', arrivalTime: '13:20' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '22:39' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '13:20',
                      '14:59',
                      '15:09',
                      '16:37',
                      '16:40',
                      '18:19',
                      'blank',
                      '19:29',
                      '20:57',
                      '21:00',
                      '22:39',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21001' },
                    { schedId: '12', arrivalTime: 'B0092' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201314) Bakht Rahim Abdul Azim',
                    },
                    { schedId: '7', arrivalTime: '2112' },
                    { schedId: '4', arrivalTime: '13:00 - 23:10' },
                    { schedId: '8', arrivalTime: '1:29' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '22:37' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '13:29',
                      '14:57',
                      '15:00',
                      '16:39',
                      '16:49',
                      '18:17',
                      'blank',
                      '19:20',
                      '20:59',
                      '21:09',
                      '22:37',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21102' },
                    { schedId: '12', arrivalTime: 'B0091' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201261) Muhammad Israr Khan',
                    },
                    { schedId: '7', arrivalTime: '2113' },
                    { schedId: '4', arrivalTime: '13:10 - 23:30' },
                    { schedId: '8', arrivalTime: '13:40' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '22:59' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '13:40',
                      '15:19',
                      '15:29',
                      '16:57',
                      '17:00',
                      '18:39',
                      'blank',
                      '19:49',
                      '21:17',
                      '21:20',
                      '22:59',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21002' },
                    { schedId: '12', arrivalTime: 'B0090' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201251) Fazal Mir Allah Khan',
                    },
                    { schedId: '7', arrivalTime: '2114' },
                    { schedId: '4', arrivalTime: '13:20 - 23:30' },
                    { schedId: '8', arrivalTime: '13:49' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '22:57' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '13:04',
                      '15:17',
                      '15:20',
                      '16:59',
                      '17:09',
                      '18:37',
                      'blank',
                      '19:40',
                      '21:19',
                      '21:29',
                      '22:57',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21103' },
                    { schedId: '12', arrivalTime: 'B0089' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201327) Gulzar Nabi Mohd Nazir',
                    },
                    { schedId: '7', arrivalTime: '2115' },
                    { schedId: '4', arrivalTime: '13:30 - 23:50' },
                    { schedId: '8', arrivalTime: '14:00' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '23:19' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '14:00',
                      '15:39',
                      '15:49',
                      '17:17',
                      '17:20',
                      '18:59',
                      'blank',
                      '20:09',
                      '21:37',
                      '21:40',
                      '23:19',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21003' },
                    { schedId: '12', arrivalTime: 'B0088' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201067) Karamat Ullah Gul Amin',
                    },
                    { schedId: '7', arrivalTime: '2116' },
                    { schedId: '4', arrivalTime: '13:40 - 23:50' },
                    { schedId: '8', arrivalTime: '14:09' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '23:17' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '14:09',
                      '15:37',
                      '15:40',
                      '17:19',
                      '17:29',
                      '18:57',
                      'blank',
                      '20:00',
                      '21:39',
                      '21:49',
                      '23:17',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21104' },
                    { schedId: '12', arrivalTime: 'B0087' },
                    { schedId: '3', arrivalTime: '(2201004) Sajid Ali' },
                    { schedId: '7', arrivalTime: '2117' },
                    { schedId: '4', arrivalTime: '13:50 - 0:10' },
                    { schedId: '8', arrivalTime: '14:20' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '23:39' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '14:20',
                      '15:59',
                      '16:09',
                      '17:37',
                      '17:40',
                      '19:19',
                      'blank',
                      '20:29',
                      '21:57',
                      '22:00',
                      '23:39',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21004' },
                    { schedId: '12', arrivalTime: 'B0086' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201094) Mohd Mawaj Sher Zada',
                    },
                    { schedId: '7', arrivalTime: '2118' },
                    { schedId: '4', arrivalTime: '14:00 - 0:10' },
                    { schedId: '8', arrivalTime: '14:29' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '23:37' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '14:29',
                      '15:57',
                      '16:00',
                      '17:39',
                      '17:49',
                      '19:17',
                      'blank',
                      '20:20',
                      '21:59',
                      '22:09',
                      '23:37',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21105' },
                    { schedId: '12', arrivalTime: 'B0085' },
                    {
                      schedId: '3',
                      arrivalTime: '(2201958) Muhammed Sharif Mauhmmed',
                    },
                    { schedId: '7', arrivalTime: '2119' },
                    { schedId: '4', arrivalTime: '14:10 - 0:30' },
                    { schedId: '8', arrivalTime: '14:40' },
                    { schedId: '9', arrivalTime: 'Al Ghanim Bus Station' },
                    { schedId: '10', arrivalTime: '23:59' },
                    { schedId: '5', arrivalTime: 'Street 23 Stop 2' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '14:40',
                      '16:19',
                      '16:29',
                      '17:57',
                      '18:00',
                      '19:39',
                      'blank',
                      '20:49',
                      '22:17',
                      '22:20',
                      '23:59',
                    ],
                  },
                },
                {
                  stpSchedule: [
                    { schedId: '1', arrivalTime: '21' },
                    { schedId: '11', arrivalTime: '21005' },
                    { schedId: '12', arrivalTime: 'B0084' },
                    { schedId: '3', arrivalTime: '(2201828) Naseer Zaman Mir' },
                    { schedId: '7', arrivalTime: '2120' },
                    { schedId: '4', arrivalTime: '14:20 - 0:30' },
                    { schedId: '8', arrivalTime: '14:49' },
                    { schedId: '9', arrivalTime: 'Street 23 Stop 2' },
                    { schedId: '10', arrivalTime: '23:57' },
                    { schedId: '5', arrivalTime: 'Al Ghanim Bus Station' },
                  ],
                  shift: {
                    dayTime: 'PM SHIFT',
                    shiftData: [
                      '14:49',
                      '16:17',
                      '16:20',
                      '17:59',
                      '18:09',
                      '19:37',
                      'blank',
                      '20:40',
                      '22:19',
                      '22:29',
                      '23:57',
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    ],
  };
  eachStopWidth = 86.8;
  isScrollX: boolean = false;
  summaryData: any = {};
  dialogConfig = new MatDialogConfig();
  transportServed: any[] = [];
  scheduleOrigData;
  lazyLoadingIndexStart: number = 0;
  lazyLoadingIndexEnd: number = 20;
  vehicleList = [
    { value: 'B0076', viewValue: 'B0076' },
    { value: 'B0075', viewValue: 'B0075' },
    { value: 'B0074', viewValue: 'B0074' },
    { value: 'B0073', viewValue: 'B0073' },
    { value: 'B0071', viewValue: 'B0072' },
    { value: 'B0070', viewValue: 'B0070' },
  ];
  driverList = [
    {
      value: '(1101697) Bashir H.s. Ali',
      viewValue: '(1101697) Bashir H.s. Ali',
    },
    {
      value: '(1101296) Muhammad Iqbal',
      viewValue: '(1101296) Muhammad Iqbal',
    },
    {
      value: '(2200465) Inayat Khan Gul Bad Shah',
      viewValue: '(2200465) Inayat Khan Gul Bad Shah',
    },
    {
      value: '(2200936) Asmat Ali Ali Noor',
      viewValue: '(2200936) Asmat Ali Ali Noor',
    },
    {
      value: '(2200606) Rafiq Ahmad Abdul Rehman',
      viewValue: '(2200606) Rafiq Ahmad Abdul Rehman',
    },
    {
      value: '(2201288) Rukedin Sarwar Din',
      viewValue: '(2201288) Rukedin Sarwar Din',
    },
    // {value: '(2200773) Riaz Hussain Nabi Bakhsh', viewValue: '(2200773) Riaz Hussain Nabi Bakhsh'},
    // {value: '(2201183) Gul Sher Khan Sharin', viewValue: '(2201183) Gul Sher Khan Sharin'},
    // {value: '(2202667) Mujahid Ali Khalid Khan', viewValue: '(2202667) Mujahid Ali Khalid Khan'},
    // {value: '(2201505) Abdul Ghafoor Mir Sanad Khan', viewValue: '(2201505) Abdul Ghafoor Mir Sanad Khan'},
    // {value: '(2201431) Eid Muhammad Khan Azmat Khan', viewValue: '(2201431) Eid Muhammad Khan Azmat Khan'},
    // {value: '(2201333) Muhammad Ali Khan Jafar', viewValue: '(2201333) Muhammad Ali Khan Jafar'},
    // {value: '(2201314) Bakht Rahim Abdul Azim', viewValue: '(2201314) Bakht Rahim Abdul Azim'},
    // {value: '(2201261) Muhammad Israr Khan', viewValue: '(2201261) Muhammad Israr Khan'},
    // {value: '(2201251) Fazal Mir Allah Khan', viewValue: '(2201251) Fazal Mir Allah Khan'},
    // {value: '(2201327) Gulzar Nabi Mohd Nazir', viewValue: '(2201327) Gulzar Nabi Mohd Nazir'},
    // {value: '(2201067) Karamat Ullah Gul Amin', viewValue: '(2201067) Karamat Ullah Gul Amin'},
    // {value: '(2201004) Sajid Ali', viewValue: '(2201004) Sajid Ali'},
    // {value: '(2201094) Mohd Mawaj Sher Zada', viewValue: '(2201094) Mohd Mawaj Sher Zada'},
    // {value: '(2201958) Muhammed Sharif Mauhmmed', viewValue: '(2201958) Muhammed Sharif Mauhmmed'},
    // {value: '(2201828) Naseer Zaman Mir', viewValue: '(2201828) Naseer Zaman Mir'}
  ];
  routeList = [
    { value: '', viewValue: 'All' },
    { value: '21', viewValue: '21' },
    { value: '41', viewValue: '41' },
    { value: '76', viewValue: '76' },
    { value: '101', viewValue: '101' },
    { value: '172', viewValue: '172' },
  ];

  dutyForm = new FormGroup({
    route: new FormControl('', null),
    filterDate: new FormControl(moment().format('YYYY-MM-DD'), [
      Validators.required,
    ]),
  });

  ngOnInit() {
    this.scheduleData.detailedData.forEach(element => {
      element.daysType.forEach(element2 => {
        element2.details.scheduleData.forEach((element3: any) => {
          element3.stpSchedule.forEach((element4: any) => {
            element4.isInlineEdit = false;
          });
        });
        // element2.details.header.forEach((element3:any) => {
        //   if(element3.trainLines.length > 0) {
        //     element3.trainLines.forEach(lines => {
        //       if (this.transportServed.some(e => e.code === lines.code)) {
        //       }
        //       else {
        //         this.transportServed.push(lines);
        //       }
        //     });
        //   }
        // });
      });
    });

    if (this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
    this.searchSchedule();
    // var urlAction = 'gtfsupload/v1/getDayTypes';
    // this.commonService.commonPostAction(urlAction, '').subscribe(
    //   (data:any) => {
    //     this.days = data;
    //   }
    // );
  }

  searchSchedule(dayType: string = '01', direction: string = '1') {
    this.commonService.spin$.next(true);
    this.routeId = this.form.value.streetNameBusStop;

    // console.log(this.summaryData.origin)
    var clonedSched =
      this.scheduleData.detailedData[0].daysType[0].details.scheduleData.slice();
    // clonedSched.splice(1,5);
    // clonedSched.filter((val,idx) => {
    //   console.log(idx);
    // });

    this.selectedServiceData.header =
      this.scheduleData.detailedData[0].daysType[0].details.header;
    this.selectedServiceData.scheduleData = clonedSched; //this.scheduleData.detailedData[0].daysType[0].details.scheduleData;
    this.isServiceSchedule = true;
    this.isDisablePage = false;

    // this.getSummary();
    this.commonService.spin$.next(false);
    console.log(this.selectedServiceData);
    return false;

    this.selectedDay = dayType;
    this.selectedDirection = direction;
    // this.selectedServiceData.header = [];
    // this.selectedServiceData.scheduleData = [];
    this.selectedServiceData = {};
    this.summaryData = {};
    this.transportServed = [];
    this.isServiceSchedule = false;
    this.isDisablePage = false;
    this.lazyLoadingIndexStart = 0;
    this.lazyLoadingIndexEnd = 20;

    // console.log(this.selectedServiceData);
    var postData = {
      dayTypeCode: dayType,
      direction: direction,
      routeId: this.routeId,
      schDate: moment().format('YYYY-MM-DD'),
    };
    var urlAction = 'gtfsupload/v1/getDayTypeStopTimes';
    this.commonService.commonPostAction(urlAction, postData).subscribe(
      (data: any) => {
        if (data.stops === undefined) {
          // this.selectedServiceData = {};
          // console.log(data);
          // console.log(this.selectedServiceData);
          this.isServiceSchedule = false;
          this.commonService.spin$.next(false);

          this._snackBar.open('No schedule found.', null, {
            duration: 2000,
          });
          return false;
        }
        let clonedSched = data.stpScheduleRecord.slice();
        let clonedSchedSpliced = clonedSched.splice(
          this.lazyLoadingIndexStart,
          this.lazyLoadingIndexEnd
        );
        let transportData = [];

        this.selectedServiceData.header = data.stops;
        this.selectedServiceData.scheduleData = clonedSchedSpliced; //data.stpScheduleRecord;
        this.scheduleOrigData = data.stpScheduleRecord;

        // this.scheduleData.detailedData.forEach(element => {
        //   element.daysType.forEach(element2 => {
        this.selectedServiceData.scheduleData.forEach((element3: any) => {
          // element3.stpSchedule.forEach((element4:any) => {
          element3.isInlineEdit = false;
          // })
        });
        this.selectedServiceData.header.forEach((element3: any) => {
          if (element3.mrtStationCode) {
            let trainLines = element3.mrtStationCode.split(',');
            trainLines.forEach(lines => {
              let line2 = lines.replace(/'/g, '').replace(/ /g, '');
              lines = line2;
              // element3.mrtStationCode.push(line2);
              // console.log(line2);
              if (transportData.some(e => e.mrtStationCode === line2)) {
              } else {
                transportData.push({
                  mrtStationCode: line2,
                  mrtStationName: element3.mrtStationName,
                });
              }
            });
          }
        });
        //   });
        // });

        //remove single quote
        this.selectedServiceData.header.forEach((element: any) => {
          if (element.mrtStationCode) {
            let line2 = element.mrtStationCode
              .replace(/'/g, '')
              .replace(/ /g, '');
            element.mrtStationCode = line2;
          }
        });

        //remove duplicates
        let keys = ['mrtStationCode', 'mrtStationName'];
        let filtered = transportData.filter(
          (
            s => o =>
              (k => !s.has(k) && s.add(k))(keys.map(k => o[k]).join('|'))
          )(new Set())
        );

        this.transportServed = filtered;

        this.isServiceSchedule = true;
        let psContent: HTMLElement =
          this.chatPS.directiveRef.elementRef.nativeElement.children[0];
        psContent.setAttribute('style', 'display:initial');

        this.getSummary();
        this.commonService.spin$.next(false);
      },
      error => {
        console.log(error);
      }
    );
  }

  getSummary() {
    var urlAction = 'gtfsupload/v1/routes/getRouteSummary/' + this.routeId;
    this.commonService
      .commonPostAction(urlAction, '')
      .subscribe((data: any) => {
        this.directions = [];
        this.summaryData = {};
        // this.summaryData.origin = this.selectedServiceData.header[0].stopDesc;
        // this.summaryData.destination = this.selectedServiceData.header[this.selectedServiceData.header.length - 1].stopDesc;
        this.directions.push({ value: '1', viewValue: '1' });
        this.isBiDirection = false;

        if (this.summaryData.origin !== this.summaryData.destination) {
          this.directions.push({ value: '2', viewValue: '2' });
          this.isBiDirection = true;
        }
        if (data.length === 0) {
          return false;
        }
        this.summaryData = data[0];
      });
  }

  daySelected(data) {
    // this.scheduleData.detailedData.forEach(element => {
    //   if(parseInt(element.direction) === parseInt(this.selectedDirection)){
    //     element.daysType.forEach(element2 => {
    //       if(element2.type === data) {
    //         this.selectedServiceData = element2.details;
    //       }
    //     });
    //   }
    // });
    // searchSchedule(dayType:string = "01", direction:string = "1") {
    // console.log(this.selectedDay, this.selectedDirection);
    this.searchSchedule(this.selectedDay, this.selectedDirection);
  }

  // directionSelected(data) {
  // this.scheduleData.detailedData.forEach(element => {
  //   if(parseInt(element.direction) === parseInt(data)){
  //     element.daysType.forEach(element2 => {
  //       if(element2.type === this.selectedDay) {
  //         console.log(element2);
  //         this.selectedServiceData = element2.details;
  //       }
  //     });
  //   }
  // });
  // }

  snackbarStart(message: string) {
    this.running = true;

    this._snackBar.open(message);
  }

  snackbarEnd() {
    this._snackBar.dismiss();
    this.running = false;
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/system-configuration/schedule/search/2',
      'Service Schedule',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  ngOnChanges() {
    console.log('on change');
  }

  // formReset() {
  // this.isvalid = false;
  // this.form = this.formBuilder.group({
  //   streetName: ['', Validators.required]
  // });
  // this.stopManagementService.allStops = [];
  // }

  inlineEdit(sched) {
    sched.isInlineEdit = !sched.isInlineEdit;
  }

  onSaveSchedule(sched) {
    this._snackBar.open('Saving...');
    var stopList: any = [];
    // console.log(sched);
    sched.stpSchedule.forEach((element, idx) => {
      // console.log(sched.stpSchedule[idx]);
      // sched[idx].trip = "";
      // stopList.trip = sched.trip;
      if (element.isChanged) {
        stopList.push({
          trip: sched.trip,
          stopTime: element,
        });
      }
    });
    // console.log(stopList);
    var postData = {
      dayTypeCode: this.selectedDay,
      direction: this.selectedDirection,
      routeId: this.form.value.streetNameBusStop,
      stopTimeList: stopList,
    };
    var urlAction = 'gtfsupload/v1/editDayTypeStopTimes';
    // console.log(postData);
    // console.log(JSON.stringify(postData));
    sched.isInlineEdit = !sched.isInlineEdit;
    this._snackBar.open('Duty updated successfully.', null, {
      duration: 2000,
    });
    return false;

    this.commonService
      .commonPostAction(urlAction, postData)
      .subscribe((data: any) => {
        // console.log(data);
        sched.isInlineEdit = !sched.isInlineEdit;
        this._snackBar.open('Schedule updated.', null, {
          duration: 2000,
        });
      });
  }

  onBlurSched(sched) {
    sched.isChanged = true;
  }

  getRatioY() {
    var ratioWidth = Math.round(window.devicePixelRatio * 100);
    var ratioMultiplier: number = 51.1;

    if (ratioWidth === 100) {
      ratioMultiplier = 51.1;
    } else if (ratioWidth === 110 || ratioWidth === 125) {
      ratioMultiplier = 48.7;
    }

    return (Math.round(window.devicePixelRatio * 100) / 100) * ratioMultiplier;
  }

  getRatioX() {
    var ratioWidth = Math.round(window.devicePixelRatio * 100);
    var ratioMultiplier: number = 68.8;

    if (ratioWidth === 100) {
      ratioMultiplier = 68.8;
    } else if (ratioWidth === 110) {
      ratioMultiplier = 59.5;
    } else if (ratioWidth === 125) {
      ratioMultiplier = 46.5;
    }

    return (Math.round(window.devicePixelRatio * 100) / 100) * ratioMultiplier;
  }

  onScrollUp(event) {
    if (!this.isScrollX) {
      return false;
    }
    var ratio = this.getRatioY();

    var leftPos: any = this.chatPSVert.directiveRef.position(true).y;
    var YYScrollBarHeight =
      this.chatPSVert.directiveRef.elementRef.nativeElement.children[3]
        .children[0].style.height;
    var YYTotalHeight = this.selectedServiceData.header.length * ratio;
    var scrollPercentageHeight =
      (100 * (leftPos - YYScrollBarHeight.replace('px', ''))) /
      (YYScrollBarHeight.replace('px', '') - YYTotalHeight);

    var scrollBarWidthXX =
      this.chatPS.directiveRef.elementRef.nativeElement.children[2].children[0]
        .style.width;
    var totalWidthXX =
      this.selectedServiceData.header.length * this.eachStopWidth;

    var scrollValueForAnother =
      scrollBarWidthXX.replace('px', '') -
      totalWidthXX * (scrollPercentageHeight / 100);
    this.chatPS.directiveRef.scrollToLeft(scrollValueForAnother, 0);
    event.stopPropagation();
  }

  onScrollRight(event) {
    if (this.isScrollX) {
      return false;
    }

    var ratioX = this.getRatioX();

    var leftPos: any = this.chatPS.directiveRef.position(true).x;
    var scrollBarWidth =
      this.chatPS.directiveRef.elementRef.nativeElement.children[2].children[0]
        .style.width;
    var totalHeight = this.selectedServiceData.header.length * ratioX;
    var scrollPercentageWidth =
      (100 * (leftPos - scrollBarWidth.replace('px', ''))) /
      (scrollBarWidth.replace('px', '') - totalHeight);

    var scrollBarWidthXX =
      this.chatPSVert.directiveRef.elementRef.nativeElement.children[3]
        .children[0].style.height;
    var totalWidthXX = this.selectedServiceData.header.length * 39.7;

    var scrollValueForAnother =
      scrollBarWidthXX.replace('px', '') -
      totalWidthXX * (scrollPercentageWidth / 100);

    this.chatPSVert.directiveRef.scrollToY(scrollValueForAnother, 0);
    event.stopPropagation();
  }

  onRightSideHover() {
    this.isScrollX = true;
  }

  onLeftSideHover() {
    this.isScrollX = false;
  }

  onSaveSummary() {
    this._snackBar.open('Saving summary...');
    var summaryDataArr = [];

    console.log(JSON.stringify(this.summaryData));

    var summaryParseData = {
      routeId: this.form.value.streetNameBusStop,
      origin: this.summaryData.origin,
      destination: this.summaryData.destination,
      originWeekday: this.summaryData.originWeekday,
      originSaturday: this.summaryData.originSaturday,
      originSunday: this.summaryData.originSunday,
      destWeekday: this.summaryData.destWeekday,
      destSaturday: this.summaryData.destSaturday,
      destSunday: this.summaryData.destSunday,
      firstDirectionPeriod1: this.summaryData.firstDirectionPeriod1,
      firstDirectionPeriod2: this.summaryData.firstDirectionPeriod2,
      firstDirectionPeriod3: this.summaryData.firstDirectionPeriod3,
      firstDirectionPeriod4: this.summaryData.firstDirectionPeriod4,
      secondDirectionPeriod1: this.summaryData.secondDirectionPeriod1,
      secondDirectionPeriod2: this.summaryData.secondDirectionPeriod2,
      secondDirectionPeriod3: this.summaryData.secondDirectionPeriod3,
      secondDirectionPeriod4: this.summaryData.secondDirectionPeriod4,
      otherTransport: '',
      // "isInlineEdit": true
    };

    summaryDataArr.push(summaryParseData);
    var urlAction = 'gtfsupload/v1/routes/saveRouteSummary';

    this.commonService.commonPostAction(urlAction, summaryDataArr).subscribe(
      (data: any) => {
        this.summaryData.isInlineEdit = false;
        this._snackBar.dismiss();
        this._snackBar.open('Summary updated.', null, {
          duration: 2000,
        });
      },
      error => {
        this._snackBar.dismiss();
        this._snackBar.open('Error encoutered, please try again later.', null, {
          duration: 2000,
        });
      }
    );
  }

  onYReachEnd() {
    console.log('y reach end');

    if (this.scheduleOrigData === undefined || !this.isServiceSchedule) {
      return false;
    }
    // return false;
    this.lazyLoadingIndexStart += 20;
    this.lazyLoadingIndexEnd += 20;

    // var clonedSched = this.scheduleOrigData.slice();
    // var clonedSchedSpliced = clonedSched.splice(this.lazyLoadingIndex, this.lazyLoadingIndex + 20);
    var clonedSchedSpliced = this.scheduleOrigData.slice(
      this.lazyLoadingIndexStart,
      this.lazyLoadingIndexEnd
    );
    // console.log(clonedSchedSpliced);
    // this.lazyLoadingIndex += 20;

    // console.log('nextIndex ' + nextIndex);
    // console.log('this.lazyLoadingIndexStart ' + this.lazyLoadingIndexStart);
    // console.log('this.lazyLoadingIndexEnd ' + this.lazyLoadingIndexEnd);
    // let clonedSchedSpliced = clonedSched.splice(0,this.lazyLoadingIndex);

    clonedSchedSpliced.forEach(element => {
      this.selectedServiceData.scheduleData.push(element);
    });

    // this.selectedServiceData.scheduleData = clonedSchedSpliced;
    // console.log(this.selectedServiceData.scheduleData);
    // console.log(this.selectedServiceData.scheduleData);
  }

  // uploadFile() {
  //   this.dialog.open(UploadScheduleComponent, this.dialogConfig);
  // }
  onFilter() {
    var route = this.dutyForm.value.route;
    var date = this.dutyForm.value.filterDate;

    // var selectedValue = event.value;
    // console.log(event);
    // return false;
    // var allData = this.selectedServiceData.scheduleData;
    var schedData =
      this.scheduleData.detailedData[0].daysType[0].details.scheduleData;
    var newData = [];
    // console.log(this.selectedServiceData);
    // return false;

    schedData.forEach(element => {
      let arrTime = element.stpSchedule[0].arrivalTime;
      // console.log(element);
      if (arrTime.includes(route)) {
        // console.log(element)
        newData.push(element);
      }
    });
    this.selectedServiceData.scheduleData = newData;
    // this.scheduleData.detailedData[0].daysType[0].details.scheduleData = [];
    // console.log(newData);
    // console.log(allData);
  }
}
