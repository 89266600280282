<h3>{{stopData.service}}</h3>
<div class="single-ladder-group">
  <div fxLayout="row" fxLayoutAlign="space-between center" >
    <div fxFlex="5">{{direction1[0].stopCode}}</div>
    <div fxFlex="90" fxLayout="column" fxLayoutAlign="space-between center" >
      <div>direction1 bus</div>
      <div class="width100" fxLayout="row" fxLayoutAlign="space-between center">
        <span *ngFor="let stop of direction1; let last = last">
          <!-- {{stop}} -->
          <span class="material-icons font-sm stop-icon">
            stop_circle
          </span>
          <span *ngIf="!last" class="line-stop" [ngStyle]="{'width':stop1Percentage+'%'}"></span>
        </span>
      </div>
      <div class="width100" fxLayout="row" fxLayoutAlign="space-between center">
        <span *ngFor="let stop of direction2; let last = last">
          <!-- {{stop}} -->
          <span class="material-icons font-sm stop-icon">
            stop_circle
          </span>
          <span *ngIf="!last" class="line-stop" [ngStyle]="{'width':stop2Percentage+'%'}"></span>
        </span>
      </div>
      <div>direction2 bus</div>
    </div>
    <div fxFlex="5" class="text-right">{{direction1[direction1.length-1].stopCode}}</div>

  </div>
</div>