import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Home2Service {

  constructor(private httpClient: HttpClient
    ) { }
  
  getBbox(coords) {
    let url = environment.nodeUrl + 'commonGet';
    // var postData = {
    //   "url": 'https://browse.search.hereapi.com/v1/browse?at=1.352,103.8198&categories=400-4100-0042&in=bbox:103.81887,1.30359,103.82948,1.30767&apiKey='+environment.hereApiKey+''
    // }
    
    var postData = {
      "url": 'https://browse.search.hereapi.com/v1/browse?at=1.352,103.8198&categories=400-4100-0042&in=bbox:'+coords+'&apiKey='+environment.hereApiKey+''
    }
    // console.log(postData.url);

    const promise = new Promise(
      (resolve, reject) => {

          this.httpClient.post(url, postData)
          .subscribe(
              (data:any) => {
                  resolve(data);
              },
              err => console.log(err),
              () => console.log('done bbox')
          )
      }
    );
    
    return promise;
  }
}