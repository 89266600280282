
  <!-- <mat-spinner *ngIf="show"></mat-spinner> -->
  <div class="center" >
    <!-- <mat-progress-spinner diameter=100 
	                      mode="determinate"
						  color="accent">
	</mat-progress-spinner>  -->
  <!-- <mat-spinner></mat-spinner> -->
  
  <!-- <div class="loader"></div> -->
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.5)" size = "large" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
</div>