<div class="message-group">
  <div>
    <h3 class="title-header">Messages</h3>
  </div>

  <div [formGroup]="msgForm">
    <mat-form-field>
      <mat-label>Search by</mat-label>
      <mat-select formControlName="searchBy">
        <mat-option *ngFor="let option of options" [value]="option">
          {{option.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="msgForm.get('searchBy').value.value !== 'findVehicleMessagesByDate'" >
      <input type="text" matInput formControlName="keyword">
    </mat-form-field>
    <mat-form-field *ngIf="msgForm.get('searchBy').value.value === 'findVehicleMessagesByDate'">
      <input matInput [matDatepicker]="msgDate" formControlName="keyword">
      <mat-datepicker-toggle matSuffix [for]="msgDate"></mat-datepicker-toggle>
      <mat-datepicker #msgDate></mat-datepicker>
    </mat-form-field>
    <button mat-raised-button type="button" (click)="search()">Search</button>
  </div>


  <div>
    <!-- <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter"> -->
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef>Time</th>
        <td mat-cell *matCellDef="let element"> {{format(element.time)}} </td>
      </ng-container>

      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef>From</th>
        <td mat-cell *matCellDef="let element"> {{element.from}} </td>
      </ng-container>

      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef>To</th>
        <td mat-cell *matCellDef="let element"> {{element.to}} </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let element"> {{element.message}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

</div>