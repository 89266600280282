<div class="search-stop-group">
  <ng-container *ngIf="isHasAccess; else noAccess">
    <div class="header-group" fxLayout="row">
      <span fxFlex="50" class="title-header"
        >Bus Stop Management<sup
          ><i
            *ngIf="!isNewWindow"
            class="fa fa-window-restore open-window"
            (click)="openWindow()"></i></sup
      ></span>
      <!-- <span fxFlex="50" fxLayoutAlign="end start">
      <button mat-raised-button type="button" (click)="onExport()" class="float-right">Export</button>
      <button mat-raised-button type="button" (click)="onUpload()" class="float-right">Upload</button>
    </span> -->
    </div>

    <div fxLayout="row" class="stop-group">
      <div class="stop-list" fxFlex="30" [formGroup]="form">
        <div>
          <button
            class="btn-add-stop btn-med btn-white"
            mat-raised-button
            (click)="addStop()"
            [disabled]="running">
            Add Stop
          </button>
        </div>
        <div
          fxLayout="row wrap"
          fxLayoutAlign="flex-start center"
          class="bus-search">
          <!-- <span fxFlex="82"> -->
          <!-- <mat-form-field appearance="outline" fxFlex="100"> -->
          <!-- <input matInput placeholder="Search" formControlName="streetNameBusStop">
          </mat-form-field> -->
          <mat-form-field
            fxFlex="1 0 40%"
            subscriptSizing="dynamic"
            hideRequiredMarker
            appearance="outline">
            <mat-label>Street Name / Bus Stop Code</mat-label>
            <input
              type="text"
              aria-label="Number"
              matInput
              formControlName="streetNameBusStop"
              name="streetNameBusStop"
              [matAutocomplete]="auto"
              class="classic-input1"
              (keyup.enter)="searchStop($event)"
              #searchStreetMatInput="matInput"
              #inputSearchStreet />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option.streetName"
                (click)="selectedStreet(option)"
                [disabled]="option.isDisable">
                <ng-container *ngIf="!isBusCodeSearch">{{
                  option.streetName.length > 0
                    ? option.streetName
                    : 'Unknown street name'
                }}</ng-container>
                <ng-container *ngIf="isBusCodeSearch">{{
                  option.stopCode
                }}</ng-container>
              </mat-option>
              <!-- <mat-option *ngIf="!filteredOptions" disabled>
                <span>No tests available.</span>
              </mat-option> -->
            </mat-autocomplete>
            <button
              [disabled]="running || !form.valid"
              mat-icon-button
              matSuffix
              (click)="clearStreetSearch($event)"
              *ngIf="inputSearchStreet.value"
              aria-label="Clear">
              <mat-icon
                [color]="
                  searchStreetMatInput.focused
                    ? 'primary'
                    : searchStreetMatInput.invalid
                      ? 'error'
                      : ''
                "
                >close</mat-icon
              >
            </button>
            <button
              mat-icon-button
              matSuffix
              [disabled]="running || !form.valid"
              matTooltip="Search"
              (click)="searchStop($event)"
              type="submit">
              <mat-icon
                [color]="
                  searchStreetMatInput.focused
                    ? 'primary'
                    : searchStreetMatInput.invalid
                      ? 'error'
                      : ''
                "
                >search</mat-icon
              >
            </button>
          </mat-form-field>
          <!-- </span> -->
          <!-- <button
            class="btn-med"
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="running || !form.valid"
            (click)="searchStop($event)">
            Search
          </button> -->
        </div>

        <ng-container *ngIf="isSelected && !running">
          <div class="stop-title">
            <span>{{ streetNameBusStop }}</span
            ><i> (Total {{ allStops.length }} stops)</i>
          </div>
          <div class="stop-content">
            <div class="header" fxLayout="row">
              <div fxFlex="15"></div>
              <div fxFlex="25">Stop Code</div>
              <div fxFlex="50">Stop Name</div>
            </div>
            <mat-list>
              <perfect-scrollbar
                #chatPS
                class="ps-all-stops"
                [config]="config"
                [scrollIndicators]="true">
                <ng-container *ngFor="let stop of allStops; let i = index">
                  <!-- (click)="openPanelStreet(stop)"  -->
                  <mat-list-item
                    class="pointer"
                    matTooltip="Click for more details"
                    (click)="editStop(stop)">
                    <div fxFlex="40">
                      <!-- <mat-checkbox class="stop-cb float-left"></mat-checkbox> -->
                      <span
                        class="material-icons float-left edit-icon pointer"
                        matTooltip="Edit"
                        >edit</span
                      >
                      <div class="sequence">{{ i + 1 }}</div>
                      <span class="color-dadada">{{ stop.stopCode }}</span>
                    </div>
                    <div fxFlex="50" class="color-dadada">
                      {{ stop.stopName }}
                    </div>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </perfect-scrollbar>
            </mat-list>
          </div>
        </ng-container>
      </div>

      <div fxFlex="70" class="map-container">
        <ngx-spinner
          bdColor="rgba(27, 30, 39, 1)"
          size="medium"
          color="#fff"
          type="ball-clip-rotate"
          [fullScreen]="false"
          [disableAnimation]="true"></ngx-spinner>
        <div class="map-panel" *ngIf="isPanel">
          <div class="text-right">
            <span
              class="material-icons pointer"
              matTooltip="Close"
              (click)="closePanel()"
              >close</span
            >
          </div>
          <div class="panel-header">
            <h5>
              {{ panelData.stopDesc
              }}<sup
                class="material-icons pointer edit-icon-sup"
                matTooltip="Edit"
                (click)="editStop(panelData)"
                >edit</sup
              >
            </h5>
            <div class="mar-top-10">
              <div>Street Name: {{ streetNameBusStop }}</div>
            </div>

            <div class="mar-top-10">
              <div>Stop Name: {{ panelData.stopName }}</div>
            </div>

            <div class="mar-top-10">
              <div>
                Stop Code: <span class="link1">{{ panelData.stopCode }}</span>
              </div>
            </div>
          </div>
          <div class="panel-buses mar-top-10">
            <div>Buses:</div>
            <ng-container *ngFor="let bus of panelData.busService">
              <a
                [routerLink]="[
                  '/account/system-configuration/service/create/1'
                ]"
                [queryParams]="{ busService: bus.routeId }"
                mat-raised-button
                class="minWidthAuto btn-bus"
                >{{ bus.routeId }}</a
              >
            </ng-container>
          </div>
          <!-- <div class="text-right"> -->
          <!-- <button mat-raised-button class="btn-bus text-left" (click)="editStop(panelData)">Edit Stop</button> -->
          <!-- <button mat-raised-button class="btn-bus text-right" (click)="addStop()">Add Stop</button>
        </div> -->
        </div>
        <div id="map-stop"></div>

        <!-- tempoary removed because of google issue -->
        <!-- <div class="map-layer-group">
        <div fxLayout="row" fxLayoutAlign="space-between center" >
          <div *ngIf="mapOptions.isMapLayer" [@fadeInOut] class="map-layers">
            <mat-radio-group aria-label="Select an option" fxLayout="column"> 
              <mat-radio-button [checked]="provider === 'GoogleMap'" value="GoogleMap" (change)="onChangeProvider('GoogleMap')">Google Map</mat-radio-button>
              <div class="map-types" *ngIf="provider === 'GoogleMap'">
                <mat-slide-toggle [checked]="true" (change)="trafficToggle($event)">Traffic Layer</mat-slide-toggle>
                <mat-radio-group class="mar-top-10" aria-label="Select an option" fxLayout="column"> 
                  <mat-radio-button [checked]="currGoogleLayer === 'roadmap'" value="roadmap" (change)="onChangeMapType('roadmap')">Road</mat-radio-button>
                  <mat-radio-button [checked]="currGoogleLayer === 'terrain'" value="terrain" (change)="onChangeMapType('terrain')">Terrain</mat-radio-button>
                  <mat-radio-button [checked]="currGoogleLayer === 'satellite'" value="satellite" (change)="onChangeMapType('satellite')">Satelite</mat-radio-button>
                  <mat-radio-button [checked]="currGoogleLayer === 'hybrid'" value="hybrid" (change)="onChangeMapType('hybrid')">Hybrid</mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-radio-button [checked]="provider === 'OpenStreetMap'" value="OpenStreetMap" (change)="onChangeProvider('OpenStreetMap')">Open Street Map</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="ladder-group-option">
            <button mat-icon-button (click)="mapOptions.isMapLayer = !mapOptions.isMapLayer">
              <mat-icon *ngIf="!mapOptions.isMapLayer">layers</mat-icon>
              <mat-icon *ngIf="mapOptions.isMapLayer">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </ng-container>

  <ng-template #noAccess>
    <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
      <h3>{{ rightsError }}</h3>
    </div>
  </ng-template>
</div>
<!-- <app-add-stop-modal [data]=modalAddStopData (closeAction)="closeAction($event)" (saveAction)="addStop($event)"></app-add-stop-modal> -->
