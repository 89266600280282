import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MatTableDataSource, MatPaginator, MatTable, MatSort } from '@angular/material';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/component/shared/services/map.service';
import { environment } from 'src/environments/environment';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface BusEventElement {
  schedTime: string
  stopCode: string;
  status: string;
  deviation: string;
  manual: string;
  eventCode: string;
}

@Component({
  selector: 'app-stop-interval-report',
  templateUrl: './stop-interval-report.component.html',
  styleUrls: ['./stop-interval-report.component.scss']
})
export class StopIntervalReportComponent implements OnInit {

  isNewWindow: boolean = false;

  constructor(private homeService: HomeService, private httpClient: HttpClient, private _snackBar: MatSnackBar, 
    private router: Router, private mapService: MapService, private route: ActivatedRoute) { 
  }

  rptForm = new FormGroup({
    route: new FormControl(null, [Validators.required]),
    startDate: new FormControl(null, [Validators.required]),
    endDate: new FormControl(null, [Validators.required])
  });

  allRoutes = [];
  // filteredRoutes: string[] = [];

  displayedColumns: string[] = ['routeId', 'busRegNo', 'driverId', 'travelledStops', 'exittime', 'entertime', 'traveltime'];

  dataSource = new MatTableDataSource<BusEventElement>();

  running: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  
  ngOnInit() {
    
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
    this.mapService.getInitSwitch().then(
      (data:any) => {
        this.dataSource.paginator = this.paginator;
        for (const routeType in data) {
          for (const route of data[routeType]) {
            // console.log(route);
            this.allRoutes.push({"routeName":route.routeName, "routeId":route.service});
          }
        }
        // console.log(this.allRoutes);
        this.rptForm.controls['route'].setValidators(null);
        this.rptForm.controls['route'].updateValueAndValidity();
        // this.homeService.responsibilities['primary'].forEach(e => {
        //   this.allRoutes.push(e.routeName);
        // });

        // this.filteredRoutes = this.filterRoutes('');

        // this.rptForm.get('route').valueChanges.subscribe(newValue=>{
        //   this.filteredRoutes = this.filterRoutes(newValue);
        // });
      }
    );
  }

  // filterRoutes(search: string) {
  //   return this.allRoutes.filter(value => value.toLowerCase().indexOf(search.toLowerCase()) === 0);
  // }

  run() {
    this.exportStarted('Generating report...');

    let url = environment.apiServerUrl + 'performance/getStopIntervalByDateRange';
    let body = {
      // routeId: this.rptForm.value.route,
      startDate: moment(this.rptForm.value.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.rptForm.value.endDate).format('YYYY-MM-DD')
    };

    this.httpClient.post(url, body).pipe(
      map((data: any) => {
        if (data.length == 0) {
          throw new Error('No record found');
        } else {
          return data;
        }
      })
    )
      .subscribe(
        (data: any) => {
          let t = [];
          
          data.forEach(trip => {
            let busRegNo = trip.busRegNo;
            let routeId = trip.routeId;
            let tripId = trip.tripId;
            let driverId = trip.driverId;
            
            if(trip.stops.length <= 0) {
              return false;
            }

            for (const e of trip.stops) {
              
              let dev = Math.abs(e.traveltime);
              let formattedDeviation = moment.utc(moment.duration(dev, "seconds").asMilliseconds()).format("mm:ss");
              // let actualTimeCompute:any;
              // if(e.status === '1') {
              //   actualTimeCompute = moment(e.schedTime).subtract(e.schDeviation, "seconds").format('DD/MM/YYYY HH:mm:ss');
              // }
              // else if(e.status === '-1') {
              //   actualTimeCompute = moment(e.schedTime).add(e.schDeviation, "seconds").format('DD/MM/YYYY HH:mm:ss');
              // }
              // else {
              //   actualTimeCompute = moment(e.schedTime).format('DD/MM/YYYY HH:mm:ss');
              // }
              
              t.push({
                routeId: routeId,
                busRegNo: busRegNo,
                tripId: tripId,
                driverId: driverId,
                travelledStops: e.travelledStops,
                entertime: e.entertime === 0 || e.entertime === undefined ? '-' : moment(e.entertime).format('DD/MM/YYYY HH:mm:ss'),
                exittime: e.exittime === 0 || e.exittime === undefined ? '-' : moment(e.exittime).format('DD/MM/YYYY HH:mm:ss'),
                traveltime: formattedDeviation
            //     // schedTime: moment(e.schedTime).format('DD/MM/YYYY HH:mm:ss'),
            //     stopEnterTime: moment(e.stopEnterTime).format('DD/MM/YYYY HH:mm:ss'),
            //     stopName: e.stopName,
            //     // status: e.status === null ? null : e.status === '-1' ? 'Late' : e.status === '0' ? 'On time' : e.status === '1' ? 'Ahead' : 'Unknown',
            //     deviation: e.schDeviationAtStopEnter //e.schDeviation === null ? null : moment.utc(e.schDeviation * 1000).format('mm:ss'),
            //     // manual: e.isManual === null ? null : e.isManual === 1 ? 'Yes' : 'No',
            //     // eventCode: e.eventCode
              });
            }
          });
 
          this.dataSource.data = t;
          this.table.renderRows();
          
          this.exportCompleted();

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        (error: any) => {
          this._snackBar.open(error, null, {
            duration: 2000,
          });
          this.running = false;
        }
      );
  }

  exportStarted(message: string) {
    this.running = true;

    this._snackBar.open(message);
  }

  exportCompleted() {
    this._snackBar.dismiss();

    this.running = false;
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/reports/interval/2','reports','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}
