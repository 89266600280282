import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';
import { VmService } from '../../vm.service';

@Component({
  selector: 'app-vm-driver-registration',
  templateUrl: './vm-driver-registration.component.html',
  styleUrls: ['./vm-driver-registration.component.scss'],
})
export class VmDriverRegistrationComponent implements OnInit {
  @Input() data: any;
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveAction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  public config: PerfectScrollbarConfigInterface = {};

  modalId: string = null;
  bodyModal: string;
  driverForm: FormGroup = null;
  isvalid: boolean = false;
  listDriverStatus = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private pmService: VmService
  ) {}

  ngOnInit() {
    this.modalId = this.data.modalTarget;
    this.bodyModal = this.data.body;
    this.initForm();
  }

  initForm() {
    this.listDriverStatus = [
      {
        listCode: 'DIS',
        listName: 'DISMISSED',
        listDesc: 'DRIVER_STATUS',
      },
      {
        listCode: 'LVE',
        listName: 'ON-LEAVE',
        listDesc: 'DRIVER_STATUS',
      },
      {
        listCode: 'NEW',
        listName: 'NEW',
        listDesc: 'DRIVER_STATUS',
      },
      {
        listCode: 'RES',
        listName: 'RESIGNED',
        listDesc: 'DRIVER_STATUS',
      },
      {
        listCode: 'SUS',
        listName: 'SUSPENDED',
        listDesc: 'DRIVER_STATUS',
      },
    ];
    this.formReset();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.driverForm.controls;
  }

  formReset() {
    this.isvalid = false;
    this.driverForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      driverStatus: ['NEW', Validators.required],
      driverId: ['', Validators.required],
      address: this.formBuilder.group({
        block: ['', Validators.required],
        street: ['', Validators.required],
        floorUnit: ['', Validators.required],
        postal: ['', Validators.required],
      }),
      primaryPhone: ['', Validators.required],
      secondaryPhone: ['', Validators.required],
      identificationDoc: ['', Validators.required],
      identificationId: ['', Validators.required],
      joiningDate: ['', Validators.required],
      isTemporary: ['N', Validators.required],
      lastDutyDate: ['', Validators.required],
    });
  }

  onSubmit() {
    console.log(this.driverForm);
    this.isvalid = true;
    const driverForm = this.driverForm.value;

    if (driverForm.isTemporary === 'N') {
      this.driverForm.controls.lastDutyDate.disable();
    }

    // stop here if form is invalid
    if (this.driverForm.invalid) {
      return;
    }

    const address =
      driverForm.address.block +
      ' ' +
      driverForm.address.street +
      ' ' +
      driverForm.address.floorUnit +
      ' ' +
      driverForm.address.postal;
    const joiningDate =
      driverForm.joiningDate.year +
      '-' +
      driverForm.joiningDate.month +
      '-' +
      driverForm.joiningDate.day +
      ' 12:00:00';
    let lastDutyDate = '';
    if (driverForm.lastDutyDate) {
      lastDutyDate =
        driverForm.lastDutyDate.year +
        '-' +
        driverForm.lastDutyDate.month +
        '-' +
        driverForm.lastDutyDate.day +
        ' 12:00:00';
    }
    const sendData = [
      {
        address: address,
        driverId: driverForm.driverId,
        driverStatus: driverForm.driverStatus,
        firstName: driverForm.firstName,
        identificationDoc: driverForm.identificationDoc,
        identificationId: driverForm.identificationId,
        isTemporary: driverForm.isTemporary,
        joiningDate: joiningDate,
        lastDutyDate: lastDutyDate,
        lastName: driverForm.lastName,
        middleName: driverForm.middleName,
        primaryPhone: driverForm.primaryPhone,
        recId: 0,
        secondaryPhone: driverForm.secondaryPhone,
      },
    ];

    this.pmService.setDriver(sendData).then(ret => {
      console.log(ret);

      this.btnClose.nativeElement.click();
      this.saveAction.emit(this.driverForm.value);
      this.formReset();
      // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.driverForm.value))
    });
  }

  closeModal() {
    this.closeAction.emit(this.modalId);
  }

  saveModal() {}
}
