import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import { HomeComponent } from '../home.component';
import { StopDetailsAllComponent } from '../stop-details-all/stop-details-all.component';
import { HomeService } from 'src/app/component/shared/services/home.service';
import { Subscription } from 'rxjs';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
import { slideUpDown } from 'src/app/component/shared/others/slideInUp';

@Component({
  selector: 'app-stop-details-panel',
  templateUrl: './stop-details-panel.component.html',
  styleUrls: ['./stop-details-panel.component.scss'],
  animations: [slideUpDown],
})
export class StopDetailsPanelComponent implements OnInit {
  @ViewChildren(StopDetailsAllComponent)
  private stopComponents: QueryList<StopDetailsAllComponent>;

  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild('stopDetailsAll', { static: true })
  stopDetailsAll: PerfectScrollbarComponent;

  public stops: any = [];

  private sub: Subscription;
  isMinimize = 'in';

  constructor(
    private homeCom: HomeComponent,
    private homeSvc: HomeService
  ) {}

  ngOnInit() {
    let _this = this;

    this.sub = this.homeSvc.subscribe((x: any) => {
      if (x.type === 'stop') {
        _this.stopComponents.forEach((y: StopDetailsAllComponent) => {
          if (y.stop === x.stop) {
            if (y.isMinimize === 'out' && y.isMinimizeEstimate === 'out') {
              y.showMinimizeEstimate();

              this.stopDetailsAll.directiveRef.scrollToTop(
                y.elementRef.nativeElement.offsetTop
              );
            }
          } else {
            y.isMinimize = 'out';
            y.isMinimizeEstimate = 'out';
          }
        });
      } else if (x.type === 'open') {
        let route = this.homeCom.ladders;
        let stopDirection = route.direction[x.direction - 1];
        if (stopDirection === undefined) {
          stopDirection = route.direction[x.direction - 2];
        }
        this.stops = stopDirection.stops;
      } else if (x.type === 'close') {
        this.stops = [];
        if (x.src === 'switch') {
          this.homeCom.ladders = {};
        }
      }
    });
  }

  showMinimize() {
    this.isMinimize = this.isMinimize === 'in' ? 'out' : 'in';
  }
  closeMessagePanel() {
    // this.stops = [];
    // this.homeSvc.publish({'type': 'close'})
    this.homeCom.closeAllRoutes(1);
  }

  ngOnDestroy() {
    this.homeSvc.unsubscribe(this.sub);
  }
}
