<div class="route-details">
  <div class="ro-header">
    <div class="icon-cancel" (click)="removeBus(index, 'null', 'null', route.key)"></div>
    <div>Route {{route.key}}</div>
  </div>
  <div class="ro-body">
    <div class="row">
      <div class="col">
        <div class="row stop-header">
          <div class="col no-padding">
             Summary
          </div>
          <!-- <div class="col no-padding">
            Actual Time
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row per-arrival no-padding no-margin">
          <div class="col no-padding">
            Bus on road
          </div>
          <div class="col-4 no-padding">
            <div class="actual-time">
              {{busCount | number:'1.'}}
            </div>
          </div>
        </div>
        <div class="row per-arrival no-padding no-margin">
          <div class="col no-padding">
            Bus on trip
          </div>
          <div class="col-4 no-padding">
            <div class="actual-time">
              {{busOnTrip | number:'1.'}}
            </div>
          </div>
        </div>
        
        <ng-container *ngFor="let row of data.value">
          <div class="row per-arrival no-padding no-margin" *ngIf="row.status !== '2'">
            <div class="col no-padding">
              <!-- {{row.statusName}} -->
              <div *ngIf="row.status === '0' || row.status === null">On time</div>
              <div *ngIf="row.status === '-1'">Early</div>
              <div *ngIf="row.status === '1'">Late</div>
            </div>
            <div class="col-4 no-padding">
              <div class="actual-time"
              [ngClass]="{
                'bgYellow textBlack': row.status === '-1',
                'bgRed': row.status === '1',
                'bgGreen': row.status === '0' || row.status === null
              }">
                {{row.count | number:'1.'}}
              </div>
            </div>
          </div>
        </ng-container>
        
        <!-- <div class="row per-arrival">
          <div class="col">
            Early
          </div>
          <div class="col low-padding">
            <div class="actual-time bgRed">
              {{busEarly}}
            </div>
          </div>
        </div>
        
        <div class="row per-arrival">
          <div class="col">
            On time
          </div>
          <div class="col low-padding">
            <div class="actual-time bgGreen">
              {{busOnTime}}
            </div>
          </div>
        </div>
        
        <div class="row per-arrival">
          <div class="col">
            Breakdown
          </div>
          <div class="col low-padding">
            <div class="actual-time bgOrange">
              {{busBreakdown}}
            </div>
          </div>
        </div>
        
        <div class="row per-arrival">
          <div class="col">
            Bus bunching
          </div>
          <div class="col low-padding">
            <div class="actual-time bgOrange">
              {{busBunching}}
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</div>