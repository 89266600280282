import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
// import { MatTableDataSource, MatPaginator, MatTable, MatSort } from '@angular/material';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/component/shared/services/map.service';
import { CommonService } from 'src/app/component/shared/services/common.service';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// export interface BusEventElement {
//   schedTime: string
//   stopCode: string;
//   status: string;
//   deviation: string;
//   manual: string;
//   eventCode: string;
// }

@Component({
  selector: 'app-trip-cause-code-analysis',
  templateUrl: './trip-cause-code-analysis.component.html',
  styleUrls: ['./trip-cause-code-analysis.component.scss'],
})
export class TripCauseCodeAnalysisComponent implements OnInit {
  isNewWindow: boolean = false;

  constructor(
    // private homeService: HomeService,
    // private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    // private mapService: MapService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder
  ) {}

  routeForm = this.formBuilder.group({
    route: new FormControl(null, [Validators.required]),
    // startDate: new FormControl(null, [Validators.required]),
    // endDate: new FormControl(null, [Validators.required])
  });

  // allRoutes = [];
  // filteredRoutes: string[] = [];

  displayedColumns: string[] = [
    'routeId',
    'busRegNo',
    'driverId',
    'travelledStops',
    'exittime',
    'entertime',
    'traveltime',
  ];

  dataSource = new MatTableDataSource<any>();

  running: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  routeList: any;
  directionList: any;
  isBiDirection: boolean = false;
  tripRecords: any;
  // tccList: any = [
  //   {detailCode: 'OP98', causeCode: 'OP', causeDetails:'Valid Outside Schedule Trip'},
  //   {detailCode: 'OD04', causeCode: 'OD', causeDetails:'Deductibles to be appealed'}
  // ]
  tccList: any = [
    {
      detailCode: 'OP98',
      causeCode: 'OP',
      causeDetails: 'Valid Outside Schedule Trip',
    },
    {
      detailCode: 'OD04',
      causeCode: 'OD',
      causeDetails: 'Deductibles to be appealed',
    },
    {
      detailCode: 'OP00',
      causeCode: 'OP',
      causeDetails: 'CFMS validated trip DEFAULT',
    },
    { detailCode: 'OP01', causeCode: 'OP', causeDetails: 'Recovered Mileage' },
    {
      detailCode: 'OP02',
      causeCode: 'OP',
      causeDetails: 'CFMS data not downloaded',
    },
    {
      detailCode: 'OP03',
      causeCode: 'OP',
      causeDetails: 'CFMS Technical Errors but Service operated',
    },
    { detailCode: 'OP04', causeCode: 'OP', causeDetails: 'Other' },
    {
      detailCode: 'MC01',
      causeCode: 'MC',
      causeDetails: 'On-road breakdown (due to incident)',
    },
    { detailCode: 'MC02', causeCode: 'MC', causeDetails: 'No serviceable bus' },
    { detailCode: 'MC03', causeCode: 'MC', causeDetails: 'Contractual' },
    { detailCode: 'MC04', causeCode: 'MC', causeDetails: 'Other' },
    {
      detailCode: 'OD00',
      causeCode: 'OD',
      causeDetails: 'Default Loss Mileage code',
    },
    { detailCode: 'OD01', causeCode: 'OD', causeDetails: 'Staff error' },
    {
      detailCode: 'OD02',
      causeCode: 'OD',
      causeDetails: 'Bus Blocked in Bus Depot/interchange',
    },
    {
      detailCode: 'OD03',
      causeCode: 'OD',
      causeDetails: 'Other Mileage deductibles',
    },
    {
      detailCode: 'ON01',
      causeCode: 'ON',
      causeDetails:
        'Incident (With evidence supported by LTA incident Mgmt System)',
    },
    { detailCode: 'ON02', causeCode: 'ON', causeDetails: 'Disaster' },
    {
      detailCode: 'ON04',
      causeCode: 'ON',
      causeDetails: 'Security Related Incidents',
    },
    {
      detailCode: 'ON05',
      causeCode: 'ON',
      causeDetails: 'Other Mileage Non Deductibles',
    },
    {
      detailCode: 'ST01',
      causeCode: 'ST',
      causeDetails: 'Absence / Late / Sickness',
    },
    { detailCode: 'ST02', causeCode: 'ST', causeDetails: 'Shortage of driver' },
    { detailCode: 'ST03', causeCode: 'ST', causeDetails: 'Staff Dispute' },
    { detailCode: 'ST04', causeCode: 'ST', causeDetails: 'Staff Strike' },
    { detailCode: 'ST05', causeCode: 'ST', causeDetails: 'Other' },
    { detailCode: 'TR01', causeCode: 'TR', causeDetails: 'Traffic congestion' },
    {
      detailCode: 'TR02',
      causeCode: 'TR',
      causeDetails: 'Bus Stop obstruction',
    },
    {
      detailCode: 'TR03',
      causeCode: 'TR',
      causeDetails: 'Unplanned diversion (Road closure)',
    },
    { detailCode: 'TR04', causeCode: 'TR', causeDetails: 'Planned Diversion' },
    {
      detailCode: 'AC01',
      causeCode: 'OP',
      causeDetails: 'AUTO coded missing stops AT trip START',
    },
    {
      detailCode: 'AC02',
      causeCode: 'OP',
      causeDetails: 'AUTO coded missing stops AT trip END',
    },
    {
      detailCode: 'AC03',
      causeCode: 'OP',
      causeDetails:
        'Missing data in middle of trip with certain amount of data before and after',
    },
    {
      detailCode: 'NV00',
      causeCode: 'NV',
      causeDetails: 'CFMS non-validated trip DEFAULT',
    },
    { detailCode: 'TD01', causeCode: 'TD', causeDetails: 'Bus Bridging' },
    {
      detailCode: 'OP99',
      causeCode: 'OP',
      causeDetails: 'Operated and Valid Outside Schedule Trip',
    },
  ];

  ngOnInit() {
    if (this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
    this.onInitRoute();
  }

  onInitRoute() {
    this.routeList = {
      routeList: [
        { routeId: '3', direction: '1' },
        { routeId: '50', direction: '1' },
        { routeId: '50', direction: '2' },
        { routeId: '106', direction: '1' },
        { routeId: '106', direction: '2' },
      ],
    };
    // var urlAction = 'https://icabstaging.smartsp.cloud/getRoutesList';
    // this.commonService.commonPostFullUrlAction(urlAction, '').subscribe(
    //   (data:any) => {
    //     this.routeList = data;
    //   }
    // )
  }

  onSearch() {
    // var route = this.routeForm.value.route;
    console.log('on saarch');
    this.commonService.spin$.next(true);
    var urlAction = 'https://icabstaging.smartsp.cloud/getRouteRecords';
    var apiData = this.routeForm.value.route;
    // {
    //   "routeId": "59",
    //   "direction": "2"
    // }
    this.commonService
      .commonPostFullUrlAction(urlAction, apiData)
      .subscribe((data: any) => {
        // console.log(data.stops);
        data.tripRecords.forEach((element: any) => {
          element.trips.forEach(trips => {
            trips.isInlineEdit = false;

            // trips.tripRecord.forEach(record => {
            //   record.isDateTime = false;
            //   let dateTime = moment(record.dateTime);
            //   if(dateTime.isValid()) {
            //     record.isDateTime = true;
            //   }
            // })

            for (let index = 0; index < data.stops.length; index++) {
              let record = trips.tripRecord[index];
              // console.log(index,record);
              if (record) {
                record.isDateTime = false;
                record.currentStop = data.stops[index];
                record.stopSequence = index;
                let dateTime = moment(record.dateTime);
                if (dateTime.isValid()) {
                  record.fullDate = record.dateTime;
                  record.dateTime = moment(record.dateTime).format('HH:mm');
                  record.isDateTime = true;
                }
              } else {
                trips.tripRecord[index] = {
                  currentStop: data.stops[index],
                  dateTime: null,
                  destinationStop: null,
                  isDateTime: false,
                  latitude: null,
                  longitude: null,
                  nextStop: null,
                  originStop: null,
                  previousStop: null,
                  speed: null,
                  stopSequence: index,
                  vehicleLoad: null,
                  vehicleTypeDesc: null,
                  vehicleTypeSpecSeat: null,
                };
              }
            }
          });
        });

        this.tripRecords = data;
        // console.log(this.tripRecords);

        //render first
        setTimeout(() => {
          //first row set style
          let psContent: HTMLElement =
            this.chatPS.directiveRef.elementRef.nativeElement.children[0];
          psContent.setAttribute('style', 'display:initial');
          this.commonService.spin$.next(false);
        }, 1000);
      });
  }
  // ngOnChanges() {
  // console.log('test1');
  // }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/reports/trip-analysis/2',
      'reports',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  onRouteChange(route) {
    this.directionList = [];
    this.directionList.push({ value: '1', viewValue: '1' });

    if (route.direction === '2') {
      this.directionList.push({ value: '2', viewValue: '2' });
    }
  }

  onSaveTrip(vehicleRegNo, data) {
    this._snackBar.open('Trip saving.');
    var reasonData = [];
    console.log(data);
    data.tripRecord.forEach(element => {
      if (element.dateTime && !element.fullDate) {
        reasonData.push({
          vehicleRegNo: vehicleRegNo,
          routeId: this.routeForm.value.route.routeId,
          tripId: data.tripId,
          currentStop: element.currentStop,
          reasonCode: element.dateTime,

          direction: this.routeForm.value.route.direction,
          stopSequence: 3,
        });
      }
    });
    // console.log(JSON.stringify(reasonData));
    var urlAction = 'https://icabstaging.smartsp.cloud/updateReasonCode';
    this.commonService.commonPostFullUrlAction(urlAction, reasonData).subscribe(
      (respData: any) => {
        console.log(respData);
        data.isInlineEdit = false;
        this._snackBar.dismiss();
        this._snackBar.open('Trip saved.', null, {
          duration: 2000,
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  onApplyRight(trip, colData, idx) {
    // console.log(trip);
    // console.log(colData);
    // console.log(idx);
    trip.tripRecord.forEach((element, tripIdx) => {
      // console.log(element.fullDate);
      // let fullDate = moment(element.fullDate);
      // console.log(fullDate.isValid());
      if (tripIdx > idx && !element.fullDate) {
        element.dateTime = colData.dateTime;
      }
    });
  }

  onUploadFile() {
    console.log('on upload');
    this._snackBar.open('File uploading.');
    var urlAction = 'https://icabstaging.smartsp.cloud/runReportGeneration';
    this.commonService.commonPostFullUrlAction(urlAction, '').subscribe(
      (respData: any) => {
        console.log(respData);
        this._snackBar.dismiss();
        this._snackBar.open('File uploaded.', null, {
          duration: 2000,
        });
      },
      error => {
        this._snackBar.dismiss();
        this._snackBar.open('Something went wrong, try again later.', null, {
          duration: 2000,
        });
      }
    );
  }
}
