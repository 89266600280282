import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { myinterface } from '../bus-details/bus-details.component';
import { MapService } from 'src/app/component/shared/services/map.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-route-details',
  templateUrl: './route-details.component.html',
  styleUrls: ['./route-details.component.scss']
})
export class RouteDetailsComponent implements OnInit {
  
  @Input() data:any = null;
  // @Output() busData: EventEmitter<any> = new EventEmitter<any>();

  public index: number;
  public selfRef: RouteDetailsComponent;

  public compInteraction: myinterface;

  busCount:number = 0;
  busOnTrip:number = 0;
  // busLate:number = 0;
  // busEarly:number = 0;
  // busOnTime:number = 0;
  // busBreakdown:number = 0;
  // busBunching:number = 0;
  route: any = null;
  refreshInterval:any;

  constructor(private mapService: MapService) { }

  ngOnInit() {
    this.route = this.data.value;
    var idleBus = 0;

    for (const row of this.data.value) {
      this.busCount+=parseInt(row.count);
      if(row.status === '2') {
        idleBus+=parseInt(row.count);
      }
    }
    this.busOnTrip = this.busCount - idleBus;
    // this.initBusCount();
    // this.busListCount();
    
    var self = this;
    this.refreshInterval = setInterval(function() {
      self.mapService.getRouteDetailsByRoute(self.data.key).then(
      (routeData:any) => {
        self.busOnTrip = 0;
        self.busCount = 0;
        idleBus = 0;

        for (const row of routeData) {
          self.busCount+=parseInt(row.count);
          if(row.status === '2') {
            idleBus+=parseInt(row.count);
          }
        }
        self.busOnTrip = self.busCount - idleBus;
      });
    }, environment.bus.clearInterval);

  }

  // busListCount(){
  //   let bus = this.route.buses;
  //   // let busB = this.route.busesB;
  //   for (const row of bus) {
  //     if(row.statusCode === '1') {
  //       this.busOnTime++;
  //     }
  //     else if(row.statusCode === '2') {
  //       this.busEarly++;
  //     }
  //     else if(row.statusCode === '3') {
  //       this.busLate++;
  //     }
  //     else if(row.statusCode === '4') {
  //       this.busBreakdown++;
  //     }
  //     else if(row.statusCode === '5') {
  //       this.busBunching++;
  //     }
  //   }
    // for (const row of busB) {
    //   if(row.statusCode === '1') {
    //     this.busOnTime++;
    //   }
    //   else if(row.statusCode === '2') {
    //     this.busEarly++;
    //   }
    //   else if(row.statusCode === '3') {
    //     this.busLate++;
    //   }
    //   else if(row.statusCode === '4') {
    //     this.busBreakdown++;
    //   }
    //   else if(row.statusCode === '5') {
    //     this.busBunching++;
    //   }
    // }
  // }

  // initBusCount(){
  //   let busA = this.route.buses;
  //   // let busB = this.route.busesB;
  //   this.busCount = busA.length;
  // }

  removeBus(index, busCode, stopCode, key) {
    // console.log(index);
    // this.busData.emit(this.route);
    
    this.compInteraction.removeBusComponent(index,busCode,stopCode,key,this.route);
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }
}
