import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/component/shared/services/common.service';
// import { MatDialogConfig, MatDialog } from '@angular/material';
// export var OSMBuildings:any;
import moment from 'moment';
import { UploadTimeTableComponent } from './upload-time-table/upload-time-table.component';
import { TimeBusDetailsComponent } from './time-bus-details/time-bus-details.component';
import { VoipService } from '../../shared/services/voip.service';
import { MqttType } from '../../shared/others/constant';
import { WebSocketSubject } from 'rxjs/internal-compatibility';
import { mqttSendData } from '../../shared/others/data-types';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/services/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
// import { TripCauseCodeAnalysisComponent } from '../reports/trip-cause-code-analysis/trip-cause-code-analysis.component';
import { darkTimepickerTheme } from '../../shared/others/timepicker-field-theme';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MapService } from '../../shared/services/map.service';
import { RosterService } from '../../shared/services/openapi/roster';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';

@Component({
  selector: 'app-time-table',
  templateUrl: './time-table.component.html',
  styleUrls: ['./time-table.component.scss'],
})
export class TimeTableComponent implements OnInit, OnDestroy {
  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private commonService: CommonService,
    private _mapService: MapService,
    // private ren: Renderer2,
    private voipService: VoipService,
    public authService: AuthService,
    private _spinner: NgxSpinnerService,
    private _rosterService: RosterService
  ) {}

  isNewWindow: boolean = false;
  selectedDirection = '1';
  selectedServiceData: any = {};
  cloneSelectedServiceData: any = {};
  routeId: any = '189';
  // isBiDirection: boolean = false;
  blockListData: any[] = [];
  timePickerTheme = darkTimepickerTheme;

  form = new FormGroup({
    service: new FormControl('', [Validators.required]),
    serviceDay: new FormControl(moment().utcOffset(environment.timezone), [
      Validators.required,
    ]),
    timeAfter: new FormControl('05:00', [Validators.required]),
  });

  running: boolean = false;
  isLazyLoading: boolean = false;
  lazyLoadingMessage: string = 'Load more...';

  dialogConfig = new MatDialogConfig();
  lazyLoadingIndexStart: number = 0;
  countLoad = 20;
  lazyLoadingIndexEnd: number = this.countLoad;
  countLoadedData: number = this.countLoad;

  $timeTableEvent: WebSocketSubject<any>;
  directionListData = [
    // {'direction': 1},
    // {'direction': 2}
  ];
  isHeadway: boolean = false;
  pageData = { id: 5, name: 'timeTable', displayPageName: 'Time Table' };
  isHasAccess: boolean = false;
  spinnerType: string;
  rightsError: string = '';
  @ViewChild('serviceAutocompleteTrigger')
  routeAutoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('blockSearch') searchTextBox: ElementRef;

  // select filtering
  separatorKeysCodes: number[] = [ENTER, COMMA];
  blockControl = new FormControl('');
  filteredBlocks: Observable<string[]>;
  selectedBlocks: string[] = [];
  @ViewChild('blockAutocompleteTrigger')
  blockAutocompleteTrigger: MatAutocompleteTrigger;

  ngOnInit() {
    // this._spinner.show();
    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this._spinner.hide();
    // }, 10000);

    setTimeout(() => {
      if (this.route.snapshot.params.id === '2') {
        this.isNewWindow = true;
      }
      // this.initVoip();
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      this.rightsError = "You don't have access rights to this module.";
    }, 500);

    this.initRouteSelection();

    this.filteredBlocks = this.blockControl.valueChanges.pipe(
      startWith<string>(''),
      map((block: string | null) => this.blockFilter(block))
    );
  }

  routeList = [];
  initRouteSelection() {
    this._mapService
      .getInitSwitch()
      .then((data: any) => {
        const { primary, secondary } = data || {};
        const routeList = [];
        if (primary) {
          primary.forEach(route => {
            const { service } = route || {};
            if (service) {
              if (routeList.indexOf(service) < 0) {
                routeList.push(service);
              }
            }
          });
        }
        if (secondary) {
          secondary.forEach(route => {
            const { service } = route || {};
            if (service) {
              if (routeList.indexOf(service) < 0) {
                routeList.push(service);
              }
            }
          });
        }
        this.routeList = [...routeList];
        this.initRouteAutocomplete();
        console.log('responsibilities', data, routeList);
      })
      .catch(e => {
        this.routeList = [];
        console.log('Roster: Route Responsibility error: ', e);
        this._snackBar.open(
          'Server error. Failed to get routes list. Please refresh the page and try again.',
          null,
          {
            duration: 2000,
          }
        );
      });
  }

  /**
   * setup service autocomplete field
   */
  filteredOptions: Observable<string[]>;
  initRouteAutocomplete() {
    this.filteredOptions = this.form.controls.service.valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value || ''))
    );
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.routeList.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  selectRoute(event: MatAutocompleteSelectedEvent): void {
    event.option.deselect();
  }

  initVoip() {
    this.voipService.initVoip();
  }

  blockFilter(value: string): string[] {
    const sortedList = [...this.blockListData].sort((a, b) => {
      if (
        this.selectedBlocks.indexOf(a) > -1 &&
        this.selectedBlocks.indexOf(b) < 0
      )
        return -1;
      if (
        this.selectedBlocks.indexOf(a) < 0 &&
        this.selectedBlocks.indexOf(b) > -1
      )
        return 1;
      return 0;
    });

    if (!value) return sortedList;
    const filterValue = value.toLowerCase();
    const filteredList = sortedList.filter(block =>
      block.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  addBlock(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && this.blockListData.indexOf(value) > -1) {
      this.selectedBlocks.push(value);
    }
    event.chipInput!.clear();
    this.searchTextBox.nativeElement.value = '';
    this.blockControl.setValue(null);
  }

  removeBlock(block: string): void {
    const index = this.selectedBlocks.indexOf(block);
    if (index >= 0) {
      this.selectedBlocks.splice(index, 1);
    }
  }

  selectBlock(event: MatAutocompleteSelectedEvent): void {
    console.log('autocomplete select: ', event);

    const index = this.selectedBlocks.indexOf(event.option.value);

    if (index >= 0) {
      this.selectedBlocks.splice(index, 1);
    } else {
      this.selectedBlocks.push(event.option.value);
    }

    this.searchTextBox.nativeElement.value = '';
    this.blockControl.setValue('');
    event.option.deselect();

    this.filterList();
  }

  clearBlocks(event) {
    event.stopPropagation();
    this.selectedBlocks = [];
    this.searchTextBox.nativeElement.value = '';
    this.blockControl.setValue('');
    this.filterList();
  }

  displayBlocks() {
    return this.selectedBlocks.join(', ');
  }

  filterList() {
    // filter table list
    const direction1 = this.cloneSelectedServiceData.timetable[0].trips;
    if (this.selectedBlocks.length > 0) {
      let filteredBlock = [];
      this.selectedBlocks.forEach(block => {
        let arrBlock = direction1.filter(
          item => item.blockId.indexOf(block) !== -1
        );
        arrBlock.forEach(block => {
          filteredBlock.push(block);
        });
      });

      this.selectedServiceData.timetable[0].trips = filteredBlock;
    } else {
      this.lazyLoadingIndexStart = 0;
      let clonedSched = direction1.slice(); //direction 1
      let clonedSchedSpliced = clonedSched.splice(0, this.countLoadedData);

      this.selectedServiceData.timetable[0].trips = clonedSchedSpliced;
    }
  }

  isBlockSelected(value) {
    return this.selectedBlocks.indexOf(value) >= 0;
  }

  onSearchSchedule(dayType: string = '01', selectedDirection: string = '1') {
    // this.commonService.spin$.next(true);
    // this.isSpinner = true;
    this.spinnerType =
      this.commonService.spinnerType[Math.floor(Math.random() * 52)];
    this._spinner.show();
    // this.commonService.openOverlay();

    this.routeId = this.form.value.service;
    var serviceDay = this.form.value.serviceDay;
    var selectedDirectionIndex = parseInt(selectedDirection) - 1;

    this.selectedDirection = selectedDirection;

    this.selectedServiceData = {};
    this.cloneSelectedServiceData = {};
    this.lazyLoadingIndexStart = 0;
    this.lazyLoadingIndexEnd = this.countLoad;
    this.countLoadedData = this.countLoad;
    this.selectedBlocks = [];
    this.blockListData = [];
    this.directionListData = [];
    this.lazyLoadingMessage = 'Load more...';

    var postData = {
      routeId: this.routeId,
      reqDate: moment(serviceDay).format('YYYY-MM-DD'),
      direction: this.selectedDirection,
    };
    var urlAction = 'gtfsupload/v3/getTimetable';
    this.commonService.commonPostAction(urlAction, postData).subscribe(
      (dataResp: any) => {
        if (
          dataResp.timetableList[0] === null ||
          (dataResp.length > 0 && dataResp.includes('Unable'))
        ) {
          // this.commonService.spin$.next(false);
          // this.commonService.closeOverlay();
          this._spinner.hide();

          this._snackBar.open('No schedule found.', null, {
            duration: 2000,
          });
          return false;
        }
        var data = dataResp.timetableList[0];
        let stopList =
          dataResp.timetableList[0].stopList[selectedDirectionIndex];

        if (dataResp.timetableList === undefined || stopList === undefined) {
          // this.commonService.spin$.next(false);
          // this.commonService.closeOverlay();
          this._spinner.hide();

          this._snackBar.open('No schedule found.', null, {
            duration: 2000,
          });
          return false;
        }

        // this.selectedServiceData = data;
        this.selectedServiceData.dispatchList = data.dispatchList;
        this.selectedServiceData.stopList = stopList;
        this.selectedServiceData.timetable = [];

        this.cloneSelectedServiceData.timetable = [];

        //push direction
        data.timetable.forEach(elem => {
          this.directionListData.push({ direction: elem.direction });
        });

        var direction = data.timetable[selectedDirectionIndex];
        // console.log(direction);
        // var filteredTrips = [];
        var filteredTrips = this.filterByTime(direction.trips).then(
          (respData: any) => {
            // if(respData.length > 0){
            // console.log(respData);
            // }
            return respData;
          }
        );

        filteredTrips.then(
          respData => {
            // })

            let clonedSched = respData.slice(); //direction 1
            let clonedSchedSpliced = clonedSched.splice(
              this.lazyLoadingIndexStart,
              this.lazyLoadingIndexEnd
            );

            this.selectedServiceData.timetable.push({
              // direction.trips.push({
              direction: direction.direction,
              trips: clonedSchedSpliced,
            });

            this.cloneSelectedServiceData.timetable.push({
              // direction.trips.push({
              direction: direction.direction,
              trips: respData,
            });

            // var reconArr2 = [];
            // var bar = new Promise((resolve, reject) => {
            respData.forEach((trip, index, array) => {
              if (!trip.isTimeFiltered) {
                return false;
              }
              // console.log(index, new Date(Date.now()), Date.now(), trip);

              if (this.blockListData.indexOf(trip.blockId) === -1) {
                this.blockListData.push(trip.blockId);
              }

              var reconArr = [];
              // if(trip.tripId === '5251305239') {
              //   console.log(stop);
              //   console.log(data.stopList[0]);
              // }

              for (let index = 0; index < stopList.stops.length; index++) {
                let stopId = stopList.stops[index].split(';')[0];
                let skipIndex = 0;

                if (stopList.stops.length - 1 === index) {
                  skipIndex = 1;
                }

                // if(trip.tripId === '5416750486') {
                // console.log(stopId);
                // }

                let arrBlock = trip.stops.filter(x => x.stopId === stopId);
                let stop: any = arrBlock[skipIndex];

                //if index 1 undefined try index 0
                if (stop === undefined) {
                  let arrBlock2 = trip.stops.filter(x => x.stopId === stopId);
                  stop = arrBlock2[0];
                }

                //overwrite first stop
                if (index === 0 && stopId !== trip.stops[0].stopId) {
                  stop = {};
                }

                if (
                  stop !== undefined &&
                  stopId === stop.stopId &&
                  !stop.isAdded
                ) {
                  var retPlanTime = this.commonService.formatPlanTime(stop);
                  stop.schArrTime = retPlanTime.schArrTime;

                  var retActualTime = this.commonService.formatActualTime(stop);
                  stop.obsArrTime = retActualTime.obsArrTime;
                  // console.log(stop);
                  stop.statusColor = retActualTime.statusColor;

                  let retHeadway = this.commonService.formatHeadwayTime(stop);
                  stop.headwayStatusColor = retHeadway.headwayStatusColor;
                  stop.planHeadway =
                    stop.plannedHeadway === 'null' ||
                    stop.plannedHeadway === null ||
                    stop.plannedHeadway === '0' ||
                    stop.plannedHeadway === ''
                      ? '-'
                      : Math.round(stop.plannedHeadway / 60) + 'min';
                  stop.actualHeadway =
                    stop.observedHeadway === 'null' ||
                    stop.observedHeadway === null ||
                    stop.observedHeadway === '0' ||
                    stop.observedHeadway === ''
                      ? '-'
                      : Math.round(stop.observedHeadway / 60) + 'min';
                  stop.deviationHeadway =
                    stop.headwayDeviation === 'null' ||
                    stop.headwayDeviation === null ||
                    stop.headwayDeviation === '0' ||
                    stop.headwayDeviation === ''
                      ? '-'
                      : Math.round(stop.headwayDeviation / 60) + 'min';
                  stop.isAdded = true;
                  reconArr.push(stop);
                } else {
                  reconArr.push({});
                }
              }
              // console.log(reconArr);
              // let filteredTime = this.filterByTime(reconArr);
              // this.filterByTime(reconArr).then((respData:any) => {
              //   if(respData.length > 0){
              //     // console.log(respData);
              //     trip.stops = respData;
              //     reconArr2.push(respData);
              //   }
              // })
              trip.stops = reconArr;

              // if (index === array.length -1) resolve(reconArr);
              // });
            });
            // });

            // bar.then((dataTrip:any) => {

            // let clonedSched = direction.slice(); //direction 1
            // let clonedSchedSpliced = clonedSched.splice(this.lazyLoadingIndexStart,this.lazyLoadingIndexEnd);

            // this.selectedServiceData.timetable.push({
            // // direction.trips.push({
            //   'direction': direction.direction,
            //   'trips': clonedSchedSpliced
            // });
            // console.log('All done!');
            // console.log(this.cloneSelectedServiceData);
            // console.log(this.selectedServiceData);

            //       this.cloneSelectedServiceData = this.selectedServiceData//JSON.parse(JSON.stringify(data));
            // this.blockListData.sort();

            //mqttt init
            this.initTableEvent();

            this.blockListData = this.blockListData.sort();

            // this.getSummary();
            // this.commonService.spin$.next(false);
            // this.commonService.closeOverlay();
            this._spinner.hide();
          },
          errorRes => {
            console.log(errorRes);
            this._snackBar.open(errorRes.error.errorMessage, null, {
              duration: 2000,
            });
            // this.commonService.spin$.next(false);
            // this.commonService.closeOverlay();
            this._spinner.hide();
          }
        );
      },
      err => {
        console.error('Time Table Error', err);
        const { error } = err || {};
        const { response } = error || {};
        this._spinner.hide();
        this._snackBar.open(
          response || 'An error occurred. Please try again.',
          null,
          {
            duration: 5000,
          }
        );
        return false;
      }
    );
  }

  filterByTime(data) {
    return new Promise(resolve => {
      var dataFiltered = [];
      var timeAfter = moment(this.form.value.timeAfter, 'HH:mm'); //moment(this.form.value.timeAfter).format('HH:MM');

      // // var bar = new Promise((resolve, reject) => {
      //   data.forEach((element, index, array) => {
      //     let schedTime =  moment(element.schArrTime, 'HH:mm');//moment(element.schArrTime).format('HH:MM');
      //     if(schedTime.isSameOrAfter(timeAfter)) {
      //       console.log(element);
      //       dataFiltered.push({
      //         // 'blockId'
      //         'stops': element
      //       });
      //     }
      //     // if (index === array.length -1) resolve(dataFiltered);
      //   });

      var bar = new Promise((resolve, reject) => {
        data.forEach((element, index, array) => {
          element.isTimeFiltered = false;
          // element.stops.forEach(block => {
          // block.stops.forEach(stops => {
          // });
          let firstStop = element.stops[0];
          let schedTime = moment(firstStop.schArrTime, 'HH:mm'); //moment(element.schArrTime).format('HH:MM');
          if (schedTime.isSameOrAfter(timeAfter)) {
            element.isTimeFiltered = true;
            // console.log(element);
            dataFiltered.push(element);
          }

          if (index === array.length - 1) resolve(dataFiltered);
        });
      });

      bar.then(dataTrip => {
        // console.log(dataTrip);
        // console.log(data);
        resolve(dataTrip);
      });
    });

    // bar.then((respdata) => {
    //   console.log(respdata);
    //   console.log('All done!');
    //   console.log(dataFiltered)
    //   return dataFiltered;
    // });
  }

  snackbarStart(message: string) {
    this.running = true;

    this._snackBar.open(message);
  }

  snackbarEnd() {
    this._snackBar.dismiss();
    this.running = false;
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/time-table/2',
      'Time Table',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  onUploadFile() {
    this.dialog.open(UploadTimeTableComponent, this.dialogConfig);
  }

  onBusDetails(trip, sched) {
    console.log({ trip, sched });
    // var dispatchData = this.selectedServiceData.dispatchList;
    // var filteredDispatch = dispatchData.find(x => x.blockId === trip.blockId);
    var dispatchData = [];
    dispatchData.push(sched);

    var dialogData = {
      data: {
        title: 'Block Number',
        tripId: trip.tripId,
        blockId: trip.blockId,
        routeId: this.routeId,
        filteredDispatch: dispatchData,
        type: 'bus',
        stopId: sched.stopId,
        stopSequence: sched.stopSequence,
        schArrTime: sched.schArrTime,
        obsArrTime: sched.obsArrTime,
        busRegNo: sched.busRegNo,
        driverId: sched.driverId,
        driverName: sched.driverName,
      },
    };

    const dialogRef = this.dialog.open(TimeBusDetailsComponent, dialogData);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  async onDispatchDetails(trip) {
    var dispatchData = this.selectedServiceData.dispatchList;
    let filteredDispatch = dispatchData?.find(x => x.blockId === trip.blockId);

    // if (!filteredDispatch) {
    //   console.log('Missing Dispatch Data: ', dispatchData);
    //   return;
    // }
    const startDate = this.form.controls.serviceDay.value
      .clone()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format();
    const endDate = this.form.controls.serviceDay.value
      .clone()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .add(1, 'day')
      .format();

    const rosterResult = await this._rosterService
      .getrosters(undefined, startDate, endDate, this.routeId, trip.tripId)
      .toPromise();
    // console.log('detail', startDate, endDate, rosterResult);

    const driverList = rosterResult.map(({ driver_id, driver_name }) => ({
      driver_id,
      driver_name,
    }));
    var dialogData = {
      data: {
        title: 'Block Number',
        tripId: trip.tripId,
        blockId: trip.blockId,
        routeId: this.routeId,
        filteredDispatch:
          filteredDispatch === undefined ? null : filteredDispatch.dispatchList,
        type: 'dispatch',
        stopId: '17179',
        stopSequence: 1,
        schArrTime: '05:58:00',
        obsArrTime: null,
        busRegNo: null,
        driverId: '',
        driverName: '',
        driverList,
      },
    };

    const dialogRef = this.dialog.open(TimeBusDetailsComponent, dialogData);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  onYReachEnd() {
    if (
      this.selectedBlocks.length > 0 ||
      Object.keys(this.cloneSelectedServiceData.timetable)?.length === 0 ||
      this.cloneSelectedServiceData.timetable === undefined
    ) {
      return false;
    }

    this.isLazyLoading = true;
    this.lazyLoadingMessage = 'Loading...';
    this.spinnerType =
      this.commonService.spinnerType[Math.floor(Math.random() * 52)];
    this._spinner.show();
    setTimeout(() => {
      // console.log(this.lazyLoadingIndexStart, this.lazyLoadingIndexEnd);
      // this.isLazyLoading = false;
      // return false;

      this.lazyLoadingIndexStart += this.countLoad;
      this.countLoadedData += this.countLoad;
      // this.lazyLoadingIndexEnd += 4;

      // this.cloneSelectedServiceData.timetable.forEach((direction, idx) => {

      //any direction only 0 index
      var currDirection = 0; //this.selectedDirection === '1' ? 0 : 1; //parseInt(this.selectedDirection)-1;
      var direction = this.cloneSelectedServiceData.timetable[currDirection];
      var tripLen = direction.trips.length;

      if (tripLen > this.lazyLoadingIndexEnd) {
        let newData = JSON.parse(JSON.stringify(direction.trips));
        let clonedSched = newData.slice(); //direction 1

        // clonedSched.forEach((element, idx) => {
        //   if(this.lazyLoadingIndexStart+2 > idx) {
        //     console.log(idx, element);
        //   }
        // });

        let clonedSchedSpliced = clonedSched.splice(
          this.lazyLoadingIndexStart,
          this.lazyLoadingIndexEnd
        );

        let selectedServiceDataDirection =
          this.selectedServiceData.timetable.find(
            x => x.direction === direction.direction
          );

        // console.log(this.lazyLoadingIndexStart, this.lazyLoadingIndexEnd);
        // console.log(selectedServiceDataDirection);
        // console.log(clonedSchedSpliced);
        clonedSchedSpliced.forEach(element => {
          selectedServiceDataDirection.trips.push(element);
        });
        this.lazyLoadingMessage = 'Load more...';

        //max number of data
        if (this.countLoadedData >= direction.trips.length) {
          this.countLoadedData = direction.trips.length;
          this.lazyLoadingMessage = 'All Data Loaded';
          // console.log(direction)
        }

        this.isLazyLoading = false;
        this._spinner.hide();
      }
      // });
    }, 100);
  }

  initTableEvent() {
    this.listenTimeTable();
    var data = {
      type: MqttType.etimeTable,
      busRegNo: '',
      service: this.routeId,
    };
    this.mqttRequest(data);
  }

  listenTimeTable() {
    //test start
    // setTimeout(() => {
    //   this.selectedServiceData.timetable.forEach(element => {
    //     let data = {
    //       'stopId' : '17009',
    //       'plannedheadway': '720',
    //       'observedheadway': '70000',
    //       'headwaydeviation': '-90000',
    //       'busRegNo': 'busRegNo'
    //     }

    //     let trip = element.trips.find(x => x.tripId === '5401115888');
    //     let stop:any = trip.stops.find(x => x.stopId === data.stopId && parseInt(x.stopSequence) === parseInt('1'));
    //     let retHeadway = this.commonService.formatHeadwayTime(stop);

    //     stop.headwayStatusColor = retHeadway.headwayStatusColor;
    //     stop.planHeadway = data.plannedheadway === 'null' || data.plannedheadway === null || data.plannedheadway === '0' || data.plannedheadway === '' ? '-' : Math.round(parseInt(data.plannedheadway) / 60) + 'min';

    //     stop.actualHeadway = data.observedheadway === 'null' || data.observedheadway === null || data.observedheadway === '0' || data.observedheadway === '' ? '-' : Math.round(parseInt(data.observedheadway) / 60) + 'min';

    //     stop.deviationHeadway = data.headwaydeviation === 'null' || data.headwaydeviation === null || data.headwaydeviation === '0' || data.headwaydeviation === '' ? '-' : Math.round(parseInt(data.headwaydeviation) / 60) + 'min';
    //     stop.busRegNo = data.busRegNo;
    //     console.log(stop);
    //   });
    // }, 5000);
    //end start

    this.$timeTableEvent = new WebSocketSubject(environment.nodeUrlWs); //nodeUrlLocalWs nodeUrlWs
    this.$timeTableEvent.subscribe(
      returnData => {
        var data = JSON.parse(returnData.data);
        if (!data || data.event !== 'enter') {
          return false;
        }

        this.selectedServiceData.timetable.forEach(element => {
          let trip = element.trips.find(x => x.tripId === data.tripid);
          // console.log(trip);
          if (trip === undefined) {
            return false;
          }
          let stop = trip.stops.find(
            x =>
              x.stopId === data.stopid &&
              parseInt(x.stopSequence) === parseInt(data.stopsequence)
          );
          if (stop) {
            let stopRequest = {
              schArrTime: stop.schArrTime,
              obsArrTime: data.eventtime,
            };

            var retActualTime =
              this.commonService.formatActualTime(stopRequest);
            stop.obsArrTime = retActualTime.obsArrTime;
            stop.statusColor = retActualTime.statusColor;
            stop.busRegNo = data.busno;

            stop.headwayDeviation = data.headwaydeviation;
            let retHeadway = this.commonService.formatHeadwayTime(stop);
            stop.headwayStatusColor = retHeadway.headwayStatusColor;
            stop.planHeadway =
              data.plannedheadway === undefined ||
              data.plannedheadway === 'null' ||
              data.plannedheadway === null ||
              data.plannedheadway === '0' ||
              data.plannedheadway === ''
                ? '-'
                : Math.round(parseInt(data.plannedheadway) / 60) + 'min';
            stop.actualHeadway =
              data.observedheadway === undefined ||
              data.observedheadway === 'null' ||
              data.observedheadway === null ||
              data.observedheadway === '0' ||
              data.observedheadway === ''
                ? '-'
                : Math.round(parseInt(data.observedheadway) / 60) + 'min';
            stop.deviationHeadway =
              data.headwaydeviation === undefined ||
              data.headwaydeviation === 'null' ||
              data.headwaydeviation === null ||
              data.headwaydeviation === '0' ||
              data.headwaydeviation === ''
                ? '-'
                : Math.round(parseInt(data.headwaydeviation) / 60) + 'min';
            // console.log(stop);
          }
        });
      },
      error => {
        // console.log(error);
        this.initTableEvent();
      }
    );
  }

  mqttRequest(data): void {
    const mqttSend: mqttSendData = {
      type: data.type,
      busRegNo: data.busRegNo === undefined ? '' : data.busRegNo,
      service: data.service === undefined ? '' : data.service,
    };
    const message = new mqttSendData(
      mqttSend.type,
      mqttSend.service,
      mqttSend.busRegNo
    );

    if (MqttType.etimeTable === mqttSend.type) {
      this.$timeTableEvent.next(message);
    }
  }

  onChangeDirection() {
    if (this.selectedDirection === '1') {
      this.selectedDirection = '2';
    } else if (this.selectedDirection === '2') {
      this.selectedDirection = '1';
    }
    // this.selectedDirection === '1' ? '2' : '1';
    // console.log(this.selectedDirection);
    this.onSearchSchedule('', this.selectedDirection);
  }

  onDeviationChange(eventData) {
    this.isHeadway = eventData.checked;
  }

  /** bugged on angular material 15. need to manually deselect option */
  clearServiceSearch(event) {
    event.stopPropagation();
    this.form.controls.service.patchValue('');
    this.routeAutoCompleteTrigger.activeOption.deselect();
  }

  ngOnDestroy() {
    console.log('destroy ' + this.$timeTableEvent);
    if (this.$timeTableEvent) {
      this.$timeTableEvent.unsubscribe();
    }
  }
}
