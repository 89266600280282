import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common.service';

// import { AuthenticationService } from '@/_services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    public auth: AuthService,
    public _commonService: CommonService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('oauth')) {
      return next.handle(request);
    } else {
      request = this.addAuthenticationToken(request);
      // console.log('Interceptor: ', request.headers, request.urlWithParams);
      return next.handle(request).pipe(
        catchError(error => {
          console.log('Auth error: ', error?.status, error);
          // If error status is different than 401 we want to skip refresh token
          // So we check that and throw the error if it's the case
          if (error.status !== 401) {
            return throwError(error);
          }

          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // – which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );
          } else {
            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            // If refresh token is expired, user will be logged out
            const currentRefreshToken = localStorage.getItem('refresh_token');
            if (currentRefreshToken) {
              this.refreshTokenInProgress = true;

              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);
              return this.auth.refreshAccessToken().pipe(
                switchMap((token: any) => {
                  //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(token);

                  return next.handle(this.addAuthenticationToken(request));
                }),
                catchError((err: any) => {
                  this.refreshTokenInProgress = false;
                  this._commonService.logout();
                  return throwError(error);
                })
              );
            } else {
              this._commonService.logout();
              return throwError(error);
            }

            // var bodyData = {
            //     'password': localStorage.getItem('password'),
            //     'email': localStorage.getItem('username')
            // }
            // return this.auth
            //     .login(bodyData).pipe(
            //     switchMap((data: any) => {
            //         const { access_token } = data;
            //         localStorage.setItem('access_token', data['access_token']);
            //         localStorage.setItem('refresh_token', data['refresh_token']);
            //         localStorage.setItem('scope', data['scope']);
            //         localStorage.setItem('displayname', data['displayname']);
            //         localStorage.setItem('days_before_password_expiry', data.days_before_password_expiry);
            //         this.refreshTokenInProgress = false;
            //         this.refreshTokenSubject.next(access_token);

            //         return next.handle(this.addAuthenticationToken(request));
            //     }),
            //     catchError((err: any) => {
            //         this.refreshTokenInProgress = false;

            //         this._commonService.logout();
            //         return throwError(error);
            //     })
            // );
          }
        })
      );
    }
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.auth.getAccessToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    //this special header for uploading files
    if (
      request.url === environment.apiUrl.uploadSchedule ||
      request.url === environment.apiUrl.uploadTimeTableVdv ||
      request.url === environment.apiUrl.uploadDispatchDat
    ) {
      // We clone the request, because the original request is immutable
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.auth.getAccessToken(),
          // 'Content-Type': 'application/json',
          'ctry-code': environment.countryCode,
          'logged-in-user': localStorage.getItem('username'),
        },
      });
    } else if (
      request.url ===
      environment.apiServerUrl + 'operatormessage/v1/voipLog'
    ) {
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.auth.getAccessToken(),
          // 'Content-Type': 'application/json',
          'ctry-code': environment.countryCode,
          'logged-in-user': localStorage.getItem('username'),
          'msg-source': 'WEBUI',
          'msg-event': request.body.msgEvent,
        },
      });
    } else {
      // We clone the request, because the original request is immutable
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.auth.getAccessToken(),
          'Content-Type': 'application/json',
          'ctry-code': environment.countryCode,
          'logged-in-user': localStorage.getItem('username'),
        },
      });
    }
  }
}
