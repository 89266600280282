import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, ValidatorFn, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/component/shared/services/common.service';
// import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
// import { UserElement, USER_STATUS } from 'src/app/component/shared/others/constant';
// import { DRIVER_STATUS, DriverElement, PmDriverService } from '../pm-driver.service';

export interface UserElement {
  busRegNo: string,
  date: string
}

@Component({
  selector: 'app-data-status',
  templateUrl: './data-status.component.html',
  styleUrls: ['./data-status.component.scss']
})
export class DataStatusComponent implements OnInit {

  // driverStatuses: any = USER_STATUS;
  // form: FormGroup;
  data: any;
  title: string;
  subTitle: string;
  displayedColumns: string[] = ['fileName', 'requestedDateTime', 'downloadStatus', 'downloadedDateTime', 'processStatus'];
  dataSource = new MatTableDataSource<any>();

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DataStatusComponent>, 
    @Inject(MAT_DIALOG_DATA) data: UserElement, 
    // private drvService: PmDriverService, 
    // private _snackBar: MatSnackBar
    private _commonService: CommonService
    ) {
    
    this.data = data;
  }

  ngOnInit() {
    this.title = this.data.busRegNo;
    this.subTitle = this.data.date.split(';')[0] + ' (' + this.data.date.split(';')[1] + ')';
    
    let urlAction = 'vehicles/v1/getDatasetsStatuses?busRegNo='+this.data.busRegNo+'&date='+this.data.date.split(';')[0];
    this._commonService.commonGetAction(urlAction).subscribe(
      (respData:any) => {
        let newData = [];

        respData.files.forEach(element => {
          newData.push({
            'fileName': element.fileName === undefined ? 'N/A' : element.fileName,
            'requestedDateTime': element.requestedDateTime === undefined ? 'N/A' : element.requestedDateTime,
            'downloadStatus': element.downloadStatus === undefined ? 'N/A' : element.downloadStatus,
            'downloadedDateTime': element.downloadedDateTime === undefined ? 'N/A' : element.downloadedDateTime,
            'processStatus': element.processStatus === undefined ? 'N/A' : element.processStatus
          });
        });
        this.dataSource.data = newData;
      }
    )
    console.log(this.data);
  }

  save() {
    // if (this.form.invalid) {
    //   return;
    // }

    // this._snackBar.open('Saving...');

    // this.form.value.isTemporary = this.form.value.temporary ? 'Y' : 'N';

    // this.drvService.setDriver(this.form.value).subscribe((data: any) => {

    //   this._snackBar.dismiss();

    //   this.dialogRef.close(this.form.value);
    // }, error => {
    //   this._snackBar.open(error.message, null, {
    //     duration: 2000,
    //   });
    // });

  }

  onClose() {
    this.dialogRef.close();
  }

}
