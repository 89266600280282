export const demoStreetLines = [
  {
    streetName: 'Orange Route',
    routeId: '7003',
    stops: [
      {
        stopCode: 'PS1',
        streetName: 'Orange Route',
        stopName: 'Town Hall',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.2653656',
        stopLon: '103.8216731',
        busTerminal: false,
      },
      {
        stopCode: 'PS2',
        streetName: 'Orange Route',
        stopName: 'Library Lane',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.284154',
        stopLon: '103.843081',
        busTerminal: false,
      },
      {
        stopCode: 'PS3',
        streetName: 'Orange Route',
        stopName: 'Stadium',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.349162',
        stopLon: '103.873010',
        busTerminal: false,
      },
      {
        stopCode: 'PS4',
        streetName: 'Orange Route',
        stopName: 'Docklands Pier',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.3711822747611109',
        stopLon: '103.89246560907316',
        busTerminal: false,
      },
      {
        stopCode: 'PS5',
        streetName: 'Orange Route',
        stopName: 'Manchester Meadows',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.4048144',
        stopLon: '103.902759321',
        busTerminal: false,
      },
      // {
      //   stopCode: '0PS21',
      //   stopName: 'Library Lane',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.2852843389890296',
      //   stopLon: '103.84446493333166',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: '0PS31',
      //   stopName: 'Stadium',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.3491057123295747',
      //   stopLon: '103.87334636019847',
      //   busTerminal: false,
      // },
    ],
  },
  {
    streetName: 'Green Route',
    routeId: '7002',
    stops: [
      {
        stopCode: 'GS1',
        streetName: 'Green Route',
        stopName: 'Buckingham Blvd',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.3725841',
        stopLon: '103.9480903',
        busTerminal: false,
      },
      {
        stopCode: 'GS2',
        streetName: 'Green Route',
        stopName: 'Stratford',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.3518124',
        stopLon: '103.9437422',
        busTerminal: false,
      },
      {
        stopCode: 'GS3',
        streetName: 'Green Route',
        stopName: 'Windsor Way',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.3237203',
        stopLon: '103.9290162',
        busTerminal: false,
      },
      {
        stopCode: 'GS4',
        streetName: 'Green Route',
        stopName: 'Oxford Overpass',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.3179272',
        stopLon: '103.891838',
        busTerminal: false,
      },
      {
        stopCode: 'GS5',
        streetName: 'Green Route',
        stopName: 'Cambridge Cresent',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.2996007',
        stopLon: '103.8550052',
        busTerminal: false,
      },
      {
        stopCode: 'GS6',
        streetName: 'Green Route',
        stopName: 'York Yard',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.2811824',
        stopLon: '103.8409212',
        busTerminal: false,
      },
      {
        stopCode: 'GS7',
        streetName: 'Green Route',
        stopName: 'Leeds Lane',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.2927635057238263',
        stopLon: '103.78620150994891',
        busTerminal: false,
      },
      {
        stopCode: 'GS8',
        streetName: 'Green Route',
        stopName: 'Cantebury Court',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.315043',
        stopLon: '103.764998',
        busTerminal: false,
      },
      {
        stopCode: 'GS9',
        streetName: 'Green Route',
        stopName: 'Bristol Bridge',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.336771',
        stopLon: '103.705133',
        busTerminal: false,
      },
      {
        stopCode: 'GS10',
        streetName: 'Green Route',
        stopName: 'Liverpool Landing',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.340476',
        stopLon: '103.637113',
        busTerminal: false,
      },
      // {
      //   stopCode: 'GS31',
      //   stopName: 'Windsor Way',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.3241635655208341',
      //   stopLon: '103.92994736630442',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: 'GS51',
      //   stopName: 'Cambridge Cresent',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.3000470001300783',
      //   stopLon: '103.8552104674261',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: 'GS61',
      //   stopName: 'York Yard',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.2814036145423273',
      //   stopLon: '103.84082778171864',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: 'GS71',
      //   stopName: 'Leeds Lane',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.2940875561883587',
      //   stopLon: '103.78665807977336',
      //   busTerminal: false,
      // },
    ],
  },
  {
    streetName: 'Red Route',
    routeId: '7001',
    stops: [
      {
        stopCode: 'RS1',
        streetName: 'Red Route',
        stopName: 'Kingston Quay',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.2774322',
        stopLon: '103.8554115',
        busTerminal: false,
      },
      {
        stopCode: 'RS2',
        streetName: 'Red Route',
        stopName: 'Chester Chase',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.293306',
        stopLon: '103.852408',
        busTerminal: false,
      },
      {
        stopCode: 'RS3',
        streetName: 'Red Route',
        stopName: 'Greenwich Grove',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.3029758',
        stopLon: '103.8319635',
        busTerminal: false,
      },
      {
        stopCode: 'RS4',
        streetName: 'Red Route',
        stopName: 'Hastings Harbor',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.3225964',
        stopLon: '103.8150076',
        busTerminal: false,
      },
      {
        stopCode: 'RS5',
        streetName: 'Red Route',
        stopName: 'Preston Plaza',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.340900',
        stopLon: '103.775838',
        busTerminal: false,
      },
      {
        stopCode: 'RS6',
        streetName: 'Red Route',
        stopName: 'Victoria Vault',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.363013',
        stopLon: '103.767414',
        busTerminal: false,
      },
      {
        stopCode: 'RS7',
        streetName: 'Red Route',
        stopName: 'Lancaster Loop',
        mrtStationCode: '',
        mrtStationName: '',
        stopLat: '1.425144',
        stopLon: '103.761060',
        busTerminal: false,
      },
      // {
      //   stopCode: '0RS11',
      //   stopName: 'Kingston Quay',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.2774322',
      //   stopLon: '103.8554115',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: '0RS21',
      //   stopName: 'Chester Chase',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.2933666025756594',
      //   stopLon: '103.8524266064698',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: '0RS31',
      //   stopName: 'Orchard',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.3044229515236765',
      //   stopLon: '103.83291428102387',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: '0RS41',
      //   stopName: 'Opp Hastings Harbor',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.3232738476218344',
      //   stopLon: '103.81616942025522',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: '0RS51',
      //   stopName: 'Opp Preston Plaza',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.3409171193123939',
      //   stopLon: '103.77603703782842',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: '0RS61',
      //   stopName: 'Opp Victoria Vault',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.3627948771001002',
      //   stopLon: '103.7676739777749',
      //   busTerminal: false,
      // },
      // {
      //   stopCode: '0RS71',
      //   stopName: 'Opp Lancaster Loop',
      //   mrtStationCode: '',
      //   mrtStationName: '',
      //   stopLat: '1.4249584231649806',
      //   stopLon: '103.76117547307763',
      //   busTerminal: false,
      // },
    ],
  },
];
