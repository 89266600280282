import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
// import { MatTreeNestedDataSource } from '@angular/material';
// import { MatTreeNestedDataSource } from '@angular/material/tree';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Singapore Land Transport Authority',
    children: [
      {
        name: 'SBS Transit',
        children: [
          {name: 'Bishan Depot'},
          {name: 'Ulu Pandan Depot'},
          {name: 'Changi Depot'}
        ]
      }, 
      {
        name: 'SMRT Buses',
        children: [
          {name: 'Tuas Depot'},
          {name: 'Ten Mile Junction Depot'}
        ]
      },
      {
        name: 'Tower Transit Singapore',
        children: [
          {name: 'Sengkang Depot'},
          {name: 'Kim Chuan Depot'},
          {name: 'Mandai Depot'}
        ]
      },
      {
        name: 'Go Ahead Singapore',
        children: [
          {name: 'Gali Batu Depot'},
          {name: 'Tai Seng Facility Building'}
        ]
      },
    ]
  }
  // {
  //   name: 'Mass Rapid Transit',
  //   children: [
  //     {name: 'Train'},
  //     {name: 'LRT'},
  //     {name: 'Bus'}
  //   ]
  // }
];

@Component({
  selector: 'app-organizational-data',
  templateUrl: './organizational-data.component.html',
  styleUrls: ['./organizational-data.component.scss']
})
export class OrganizationalDataComponent implements OnInit {

  isNewWindow: boolean = false;
  treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  // dataSource = new MatTreeNestedDataSource<FoodNode>();
  
  constructor(private route: ActivatedRoute, private router: Router) { 
    // this.dataSource.data = TREE_DATA;
  }
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  ngOnInit() {
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/master-data/organization/2','organization','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }

}
