import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PmDriverService {

  constructor(private httpClient: HttpClient
  ) { }

  getAllDriver() {
    return this.httpClient.post(environment.apiServerUrl + 'drivers/listAllDrivers', null);
  }

  setDriver(driverData) {
    return this.httpClient.post(environment.apiServerUrl + 'drivers/addDrivers', driverData);
  }
}

export interface DriverElement {
  driverId: string
  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  identificationId: string;
  identificationDocType: string;
  primaryPhone: string;
  secondaryPhone: string;
  address: string;
  joiningDate: string;
  isTemporary: string;
  lastDutyDate: string;
  driverStatus: string;
}

export const DRIVER_STATUS: any = [
  {
      "listCode": "DIS",
      "listName": "DISMISSED",
      "listDesc": "DRIVER_STATUS"
  },
  {
      "listCode": "LVE",
      "listName": "ON-LEAVE",
      "listDesc": "DRIVER_STATUS"
  },
  {
      "listCode": "NEW",
      "listName": "NEW",
      "listDesc": "DRIVER_STATUS"
  },
  {
      "listCode": "RES",
      "listName": "RESIGNED",
      "listDesc": "DRIVER_STATUS"
  },
  {
      "listCode": "SUS",
      "listName": "SUSPENDED",
      "listDesc": "DRIVER_STATUS"
  }
];