import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
// import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-schedule',
  templateUrl: './upload-schedule.component.html',
  styleUrls: ['./upload-schedule.component.scss']
})
export class UploadScheduleComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder, 
    private httpClient: HttpClient, 
  ) { }

  files: any = null;
  form: FormGroup;
  running: boolean = false;
  isUploaded: boolean = false;
  uploadedMessage: String = '';
  isGTFS:boolean = false;

  ngOnInit() {
    this.form = this.formBuilder.group({
      // file: ['', Validators.required],
      effectiveFromDate: ['', Validators.required],
      effectiveTillDate: ['', Validators.required]
    });
  }
  
  uploadFile(event) {
    // for (let index = 0; index < event.length; index++) {
    //   const element = event[index];
    //   this.files = element;
    // }
    // console.log(event.item(0));
    // var selectedFiles = event.target.files;

    this.isUploaded = false
    this.files = event.item(0);//selectedFiles.item(0);;
  }

  deleteAttachment() {
    this.files = null;
    // this.files.splice(index, 1)
  }

  btnUploadFile() {
    this.running = true;
    
    if (this.files === null || !this.form.valid) {
      this.running = false;
      return;
    }
    var body = new FormData();
    
    var urlUploadFile = environment.apiUrl.uploadSchedule;

    // if(this.files.type === 'application/x-zip-compressed') {
    //   urlUploadFile = environment.apiUrl.uploadScheduleGTFS;
    // }
    
    if(this.isGTFS) {
      body.append("isGTFS", 'Y');
      // urlUploadFile = environment.apiUrl.uploadScheduleGTFS;
    }
    else {
      body.append("isGTFS", 'N');  
    }

    // body.append('file',this.files);
    body.append('file', this.files);
    body.append("effectiveFromDate", moment(this.form.value.effectiveFromDate).format('YYYY-MM-DD'));
    body.append("effectiveTillDate", moment(this.form.value.effectiveTillDate).format('YYYY-MM-DD'));

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>"
    //   })
    // };
    
    // const headers = new HttpHeaders();
    // headers.set('Content-Type', undefined);
    
    // this.httpClient.post(this.configService.apiServerUrl + 'gtfsupload/uploadClarkScheduleFile', body).pipe(
    // this.httpClient.post(this.configService.apiServerUrl + 'gtfsupload/v1/uploadXLSScheduleZipFile', body).pipe(
    this.httpClient.post(urlUploadFile, body).pipe(
      map((data: any) => {
        console.log(data);
        // if (data.length == 0) {
        //   throw new Error('No record found');
        // } else {
        //   return data;
        // }
      })
    )
    .subscribe(
      (data: any) => {
        this.form.reset();
        this.deleteAttachment();
        this.running = false;
        this.isUploaded = true;
        this.uploadedMessage = 'Uploading Successful';
      },
      (error: any) => {
        console.log(error);
        this.running = false;
        this.isUploaded = true;
        this.uploadedMessage = error.error.message;
      }
    );
  }
}
