import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { ServiceWorkerService } from './service-worker.service';
import { environment } from 'src/environments/environment';

export type JitsiServiceAction = {
  name: string;
  value: string | boolean;
};

type Status = 'init' | 'ready' | 'fail';

@Injectable({
  providedIn: 'root',
})
export class JitsiService {
  swMessagingSubscription: Subscription;
  jitsiBroadcastInstance: any = undefined;
  broadcastMuted: boolean = false;
  broadcastMuteChange: Subject<boolean> = new Subject<boolean>();
  privateCall$: Subject<string> = new Subject<string>();
  status: Status = 'init';
  jaasToken: string = '';
  domain: string = '';
  appId: string = '';

  constructor(
    private _serviceWorkerService: ServiceWorkerService,
    private httpClient: HttpClient
  ) {
    this.broadcastMuteChange.subscribe(async (value: boolean) => {
      this.broadcastMuted = value;
      // localStorage.setItem("broadcastMuted", value ? "true" : "false");
    });
  }

  setStatus(newStatus: Status) {
    this.status = newStatus;
  }

  postFullUrlAction(urlAction, data) {
    // let url = this.configService.apiServerUrl + urlAction;
    return this.httpClient.post(urlAction, data).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  init(forceGenerateToken: boolean = false) {
    this.generateJaasToken(forceGenerateToken);
    this.listenSwMessaging();
  }

  generateJaasToken(forceGenerateToken: boolean = false) {
    if (!forceGenerateToken) {
      const localJaasToken = localStorage.getItem('jaasToken');
      const localJaasDomain = localStorage.getItem('jaasDomain');
      const localJaasAppId = localStorage.getItem('jaasAppId');
      if (localJaasToken) {
        // reuse localstorage token
        const decodedToken: any = jwt_decode(localJaasToken);
        const { exp } = decodedToken || {};
        const dateExpiringInOneDay = moment().add(1, 'd');
        const expDate = moment(exp * 1000);
        const isExpiring = expDate.isSameOrBefore(dateExpiringInOneDay);
        if (!isExpiring) {
          this.jaasToken = localJaasToken;
          this.domain = localJaasDomain;
          this.appId = localJaasAppId;
          return;
        }
      }
    }

    const decodedToken: any = jwt_decode(localStorage.getItem('access_token'));
    const displayName = localStorage['displayname'];
    const email = localStorage['email'];
    const { agency } = decodedToken || {};
    const reqUrl = environment.firebase.nodeUrl + 'auth-jaas';
    const body = {
      role: 'OPS',
      displayName,
      email,
      agency,
      apiKey: environment.jitsi.apiKey,
    };
    this.postFullUrlAction(reqUrl, body).subscribe(
      (respData: any) => {
        const { token, domain } = respData;
        this.jaasToken = token;
        this.domain = domain;
        localStorage.setItem('jaasToken', token);
        localStorage.setItem('jaasDomain', domain);
        const decodedJitsiToken: any = jwt_decode(token);
        const { sub } = decodedJitsiToken || {};
        this.appId = sub || '';
        localStorage.setItem('jaasAppId', sub);
      },
      async error => {
        console.error('Call services initialization error: ', error);
        throw error;
        // this._snackBar.open('There was an error connecting to the notification service. Please refresh the page', null, {
        //   duration: 5000,
        // });
      }
    );
  }

  setJitsiBroadcastInstance(jitsiInstance) {
    this.jitsiBroadcastInstance = jitsiInstance;
  }

  getJitsiBroadcastInstance() {
    return this.jitsiBroadcastInstance;
  }

  deleteJitsiBroadcastInstance() {
    this.jitsiBroadcastInstance?.executeCommand('hangup');
    this.jitsiBroadcastInstance?.dispose();
    this.jitsiBroadcastInstance = undefined;
  }

  setBroadcastMuteStatus(
    isMuted: boolean,
    shouldBroadcastToSW: boolean = true
  ) {
    this.broadcastMuteChange.next(isMuted);
    if (shouldBroadcastToSW) {
      this._serviceWorkerService.message({
        target: 'sw',
        component: 'comms',
        action: 'mute',
        value: isMuted,
      });
    }
  }

  getBroadcastMute(): Observable<any> {
    return this.broadcastMuteChange.asObservable();
  }

  callPrivateBus(busNo: string) {
    this._serviceWorkerService.message({
      target: 'sw',
      component: 'comms',
      action: 'private-call',
      value: busNo,
    });
  }

  getPrivateCalls(): Observable<any> {
    return this.privateCall$.asObservable();
  }

  getDomain() {
    return this.domain || localStorage.getItem('jaasDomain');
  }

  getToken() {
    return this.jaasToken || localStorage.getItem('jaasToken');
  }

  listenSwMessaging() {
    this.swMessagingSubscription = this._serviceWorkerService
      .onMessage()
      .subscribe(message => {
        const { data } = message || {};
        if (data) {
          const { component, action } = data || {};
          if (component === 'comms' && action === 'mute') {
            console.log('jitsi service messaging: ', message);
            const { value } = data || {};
            this.setBroadcastMuteStatus(value, false);
          }

          if (component === 'comms' && action === 'private-call') {
            if (this.jitsiBroadcastInstance) {
              const { value } = data || {};
              console.log('call', message);
              this.privateCall$.next(value);
            }
          }
        }
      });
  }
}
