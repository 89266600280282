import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() data:any;
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() okayAction: EventEmitter<any> = new EventEmitter<any>();

  modalId:string = null;
  bodyModal: string;

  constructor(config: NgbModalConfig, public modal: NgbActiveModal) { 
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    console.log(this.data);
    // this.modalId = this.data.target;
    // this.bodyModal = this.data.body;
    
  }

  // closeModal() {
  //   this.closeAction.emit(this.modalId);
  // }
  
  // okayModal() {
  //   this.okayAction.emit();
  // }
}