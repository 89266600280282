import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TripCompletionCalcService {
  routes = new Map();
  stops = new Map();

  constructor() { }

  addRoute(route: any): void {
    let routeId = route.routeId === undefined ? route.route[0] : route.routeId[0];

    if (!this.routes.has(routeId)) {
      this.indexRoute(route);
      this.routes.set(routeId, route);

      route.direction.forEach((x: any) => {
        x.stops.forEach((y: any) => {
          let stopId = y.stopCode;
          this.stops.set(stopId, y);
        });
      });
    }
  }

  indexRoute(route: any): void {
    route.direction.forEach((x: any) => {
      x.stopIdx = [];

      x.stops.sort((x, y) => parseInt(x.stopSequence) - parseInt(y.stopSequence)).forEach((y: any) => {
        let stopId = y.stopCode;
        x.stopIdx.push(stopId);
      });
    });
  }

  hasStopId(direction: any, stopId: any): any {
    return direction.stopIdx.includes(stopId);
  }

  findDirection(route: any, prevStopId: any, nextStopId: any): any {
    let result = undefined;

    route.direction.forEach((x: any) => {
      if (this.hasStopId(x, prevStopId) && this.hasStopId(x, nextStopId)) {
        result = x;
      }
    });

    return result;
  }

  isFirstStop(dir: any, stopId: any): any {
    return dir.stopIdx.indexOf(stopId) == 0;
  }

  isLastStop(dir: any, stopId: any): any {
    return dir.stopIdx.indexOf(stopId) == dir.stopIdx.length - 1;
  }

  calc(routeId: any, geoloc: any): any {
    let route = this.routes.get(routeId);

    let dir = this.findDirection(route, geoloc.prevStopId, geoloc.nextStopId);

    let result = undefined;

    if (this.isFirstStop(dir, geoloc.nextStopId)) {
      result = 0;
    } else if (this.isLastStop(dir, geoloc.prevStopId)) {
      result = 1;
    } else {
      result = dir.stopIdx.indexOf(geoloc.prevStopId) / dir.stopIdx.length + geoloc.distToPrev / (geoloc.distToPrev + geoloc.distToNext) / dir.stopIdx.length;
    }

    return result;
  }

  findDirectionId(routeId: any, geoloc: any): any {
    let route = this.routes.get(routeId);

    let dir = this.findDirection(route, geoloc.prevStopId, geoloc.nextStopId);
    return dir.value;
  }

  findStop(stopId: any): any {
    return this.stops.get(stopId);
  }
}
