<div class="stop-modal-group">
  <form [formGroup]="form" (ngSubmit)="saveStop()">
    <div fxLayout="row">
      <h3 class="title-header" fxFlex="50">
        {{ dialogData.action | titlecase }} Stop
      </h3>
      <div class="text-right" fxFlex="50">
        <!-- <span fxLayoutAlign="end start" class="material-icons pointer" mat-dialog-close>close</span> -->
        <button mat-icon-button (click)="onCloseModal()" [disabled]="running">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div fxLayout="row">
      <!-- <div class="details" fxFlex="20" [formGroup]="form"> -->
      <div fxFlex="30" class="field-group" fxLayout="column">
        <div>
          <mat-form-field
            appearance="outline"
            [hideRequiredMarker]="dialogData.action === 'edit'"
            [ngClass]="{ 'read-only': dialogData.action === 'edit' }">
            <mat-label>Stop Code</mat-label>
            <input
              type="text"
              matInput
              formControlName="stopCode"
              [readonly]="dialogData.action === 'edit' || running"
              aria-div="Stop Code"
              name="Stop Code" />
            <mat-error *ngIf="form.get('stopCode').invalid"
              >This is required.</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Street Name</mat-label>
            <input
              type="text"
              matInput
              formControlName="streetName"
              [readonly]="running"
              aria-div="Street Name"
              name="Street Name" />
            <mat-error *ngIf="form.get('streetName').invalid"
              >This is required.</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Stop Name</mat-label>
            <input
              type="text"
              matInput
              formControlName="stopName"
              aria-div="Stop Name"
              [readonly]="running"
              name="Stop Name" />
            <mat-error *ngIf="form.get('stopName').invalid"
              >This is required.</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Latitude</mat-label>
            <input
              type="number"
              matInput
              formControlName="lat"
              aria-div="Latitude"
              [readonly]="running"
              name="Latitude" />
            <!-- <mat-error *ngIf="form.get('lat').errors?.required">Invalid latitude</mat-error> -->
            <!-- <mat-error *ngIf="form.get('lat').errors?.minlength">Atleast 18 in length</mat-error> -->
            <mat-error *ngIf="form.get('lat').errors?.required"
              >This is required.</mat-error
            >
            <mat-error *ngIf="form.get('lat').errors?.min">
              <!-- Min latitude must be {{ form.get('lat').errors?.min?.min }}. -->
              Invalid latitude.
            </mat-error>
            <mat-error *ngIf="form.get('lat').errors?.max">
              <!-- Max latitude must be {{ form.get('lat').errors?.max?.max }}. -->
              Invalid latitude.
            </mat-error>
            <!-- <mat-error *ngIf="form.get('lat').hasError('minlength')">
            Atleast 18 in length
          </mat-error> -->
            <!-- <mat-error *ngIf="form.get('lat').hasError('maxlength')">
            Atleast 18 in length
          </mat-error> -->
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Longitude</mat-label>
            <input
              type="number"
              matInput
              formControlName="lon"
              aria-div="Longitude"
              [readonly]="running"
              name="Longitude" />
            <mat-error *ngIf="form.get('lon').errors?.required"
              >This is required.</mat-error
            >
            <mat-error *ngIf="form.get('lon').errors?.min">
              <!-- Min longitude must be {{ form.get('lon').errors?.min?.min }}. -->
              Invalid longitude.
            </mat-error>
            <mat-error *ngIf="form.get('lon').errors?.max">
              <!-- Max longitude must be {{ form.get('lon').errors?.max?.max }}. -->
              Invalid longitude.
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <!-- <div>Bus Terminal</div> -->
          <mat-checkbox formControlName="busTerminal"
            >Bus Terminal</mat-checkbox
          >
          <!-- <mat-form-field>
          <input type="text" matInput formControlName="isTerminal" aria-div="Bus Terminal"/>
          <mat-error *ngIf="form.get('isTerminal').invalid">Bus Terminal</mat-error>
        </mat-form-field> -->
        </div>

        <div class="field-footer">
          <button
            class="btn-med"
            mat-stroked-button
            (click)="onCloseModal()"
            [disabled]="running">
            Cancel
          </button>
          <button class="btn-med btn-white" mat-raised-button [disabled]="true">
            Save
          </button>
        </div>
      </div>

      <div fxFlex="70" class="map-group">
        <!-- <div class="edit-map" id="edit-map"></div>  -->
        <mat-tab-group (selectedTabChange)="tabChanged($event)">
          <mat-tab label="Mapbox">
            <div class="edit-map" id="edit-map"></div>
          </mat-tab>
          <mat-tab label="Here">
            <!-- <canvas #map style="width: 100vw; height: 100vh"></canvas> -->
            <div #mapHere class="mapHere" id="mapHere"></div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </form>
</div>
