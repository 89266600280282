import { DataId } from 'src/app/component/shared/pipes/getById.pipe';
import {
  Driver,
  Contact,
  Identity,
} from 'src/app/component/shared/services/openapi/roster';

export const searchKeysList = ['userid', 'full_name'];

export interface StatusData {
  id: Driver.StatusEnum;
  name: string;
}

export const STATUS_LIST: StatusData[] = [
  {
    id: 'ACTIVE',
    name: 'Active',
  },
  // {
  //   id: 'DELETED',
  //   name: 'Deleted',
  // },
  {
    id: 'SUSPENDED',
    name: 'Suspended',
  },
  {
    id: 'ONLEAVE',
    name: 'On Leave',
  },
  {
    id: 'DISMISSED',
    name: 'Dismissed',
  },
  {
    id: 'RESIGNED',
    name: 'Resigned',
  },
  {
    id: 'MEDICAL_LEAVE',
    name: 'Medical Leave',
  },
];

export const COLUMN_LIST: DataId[] = [
  {
    id: 'userid',
    name: 'User ID',
  },
  {
    id: 'full_name',
    name: 'Full Name',
  },
  {
    id: 'identification',
    name: 'Identification',
  },
  {
    id: 'status',
    name: 'Status',
  },
  {
    id: 'date_joined',
    name: 'Date Joined',
  },
  {
    id: 'Actions',
    name: 'Actions',
  },
];

export interface ContactData {
  id: Contact.TypeEnum;
  name: string;
}

export const CONTACT_TYPES: ContactData[] = [
  {
    id: 'EMAIL',
    name: 'E-mail',
  },
  {
    id: 'HOME',
    name: 'Home Number',
  },
  {
    id: 'LINE',
    name: 'LINE',
  },
  {
    id: 'MOBILE',
    name: 'Mobile Number',
  },
  {
    id: 'OFFICE',
    name: 'Office Number',
  },
  {
    id: 'TELEGRAM',
    name: 'Telegram',
  },
  {
    id: 'WECHAT',
    name: 'WeChat',
  },
  {
    id: 'WHATSAPP',
    name: 'WhatsApp',
  },
];

export interface IdentificationData {
  id: Identity.TypeEnum;
  name: string;
}

export const IDENTIFICATION_TYPES: IdentificationData[] = [
  {
    id: 'NRIC',
    name: 'NRIC',
  },
  {
    id: 'BirthCert',
    name: 'Birth Certificate',
  },
  {
    id: 'DrivingLicense',
    name: 'Driving License',
  },
  {
    id: 'Passport',
    name: 'Passport',
  },
  {
    id: 'SocialSecurityCard',
    name: 'Social Security',
  },
  {
    id: 'WorkPermit',
    name: 'Work Permit',
  },
  {
    id: 'EmploymentPass',
    name: 'Employment Pass',
  },
  {
    id: 'Others',
    name: 'Other',
  },
];
