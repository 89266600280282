import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent implements OnInit {
  // running: boolean = false;
  isNewWindow: boolean = false;

  // rptForm = new FormGroup({
  //   schedDate: new FormControl(null, [Validators.required])
  // });
  segmentData = {
    "stopCode": "51",
    "operator": "SBS Transit",
    "schedule": [
      {
        "stopName": "Hougang Ctrl Int",
        "direction": "1",
        "weekdays": {
            "firstBus": "0530",
            "lastBus": "2330"
        },
        "saturday": {
            "firstBus": "0530",
            "lastBus": "2330"
        },
        "sundayHoliday": {
            "firstBus": "0530",
            "lastBus": "2330"
        }
      },
      {
        "stopName": "Jurong East Temp Int",
        "direction": "2",
        "weekdays": {
            "firstBus": "0530",
            "lastBus": "2330"
        },
        "saturday": {
            "firstBus": "0530",
            "lastBus": "2330"
        },
        "sundayHoliday": {
            "firstBus": "0530",
            "lastBus": "2330"
        }
      }
    ],
    "frequency": [
      {
        "name": "Direction 1",
        "period": [
          {
            "time": "10-11 minutes"
          },
          {
            "time": "08-19 minutes"
          },
          {
            "time": "11-13 minutes"
          },
          {
            "time": "12-15 minutes"
          }
        ]
      },
      {
        "name": "Direction 2",
        "period": [
          {
            "time": "12-14 minutes"
          },
          {
            "time": "08-16 minutes"
          },
          {
            "time": "09-16 minutes"
          },
          {
            "time": "10-16 minutes"
          }
        ]
      }
    ],
    "directions": [
      {
        "direction": "1",
        "stops": [
          {
            "distance": "0.0",
            "stopCode": "64009",
            "stopName": "Hougang Ctrl Int",
            "travellingPeriod": "180"
          },
          {
            "distance": "0.7",
            "stopCode": "64019",
            "stopName": "Blk 302",
            "travellingPeriod": "150"
          }
        ]
      },
      {
        "direction": "2",
        "stops": [
          {
            "distance": "0.0",
            "stopCode": "28009",
            "stopName": "Jurong East Temp Int",
            "travellingPeriod": "120"
          },
          {
            "distance": "0.7",
            "stopCode": "28301",
            "stopName": "Blk 131",
            "travellingPeriod": "110"
          }
        ]
      }
    ]
  };

  constructor(
    private httpClient: HttpClient, 
    private _snackBar: MatSnackBar, 
    private router: Router, 
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/route-management/segment/2','segment','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}