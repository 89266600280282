import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {
  @Input() data:any;
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() okayAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any) {}

  ngOnInit() {
    console.log(this.data);
    console.log(this.dataDialog);
    // this.modalId = this.data.target;
    // this.bodyModal = this.data.body;
    
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }
}