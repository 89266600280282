import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stop-management',
  templateUrl: './stop-management.component.html',
  styleUrls: ['./stop-management.component.scss']
})
export class StopManagementComponent implements OnInit {

  constructor() { }
  
  ngOnInit() {
  }
}
