import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
// import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
// import { forEach } from '@angular/router/src/utils/collection';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { slideInRight } from 'src/app/component/shared/others/animation/slide';

const getTimeString = unixTimestamp =>
  moment.unix(unixTimestamp).format('MMMM D, YYYY HH:mm:ss');

@Component({
  selector: 'app-playback-sidebar',
  templateUrl: './playback-sidebar.component.html',
  styleUrls: ['./playback-sidebar.component.scss'],
  animations: [slideInRight],
})
export class PlaybackSidebarComponent implements OnInit {
  @Input() playbackData: any = [];
  @Input() searchData: any = {};
  @Input() vehicleList: any = {};
  // @Output() changeService: EventEmitter<any> = new EventEmitter<any>();
  isMaximize: boolean = false;

  // selectedVehicle: any[] = [];
  selectedVehicle: string = '';
  vehicleListData: any[] = [];

  selectedEvent: any[] = [];
  eventListData: any[] = [];

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['timeStamp', 'vehicle', 'events'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {};

  clonedOrigData: any = [];
  allEvents: any = [];

  @Output() locateEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _commonService: CommonService) {}

  ngOnInit(): void {
    // console.log(this.playbackData);
    this.dataSource = new MatTableDataSource<any>();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.formatEventData();
  }

  formatEventData(vehicle?: string, eventTypes?: string[]) {
    this.eventListData = [];
    this.allEvents = [];

    this.playbackData.forEach(element => {
      this.eventListData.push(this.formatEventName(element.eventType));

      element.eventData.forEach(data => {
        data.playbackData.forEach(event => {
          event.timeStamp = data.timestamp / 1000;
          event.eventName = element.eventType;

          let busRegNo =
            event.busRegNo === undefined ? event.busNo : event.busRegNo;
          event.showBusRegNo = busRegNo;
          event.isSelected = false;
          this.allEvents.push(event);
          // this.allEvents.push(element.eventType);
          // if(busRegNo && !Object.values(this.vehicleListData).includes(busRegNo)) {
          //   this.vehicleListData.push(busRegNo);
          // }
        });
      });
    });
    console.log(this.allEvents);
    if (vehicle) {
      this.allEvents = this.allEvents.filter(event => event.busNo === vehicle);
    }
    if (eventTypes && eventTypes?.length > 0) {
      this.allEvents = this.allEvents.filter(
        event => eventTypes?.includes(event.eventName)
      );
    }
    this.clonedOrigData = JSON.parse(JSON.stringify(this.allEvents));
    // this.dataSource.data = this.allEvents;

    this.dataSource.data = this.allEvents.sort((a, b) =>
      a.timeStamp > b.timeStamp ? 1 : -1
    );
  }

  formatEventName(eventName) {
    var formatName = '';
    switch (eventName) {
      case 'tripstart':
        formatName = 'Trip Start';
        break;
      case 'tripend':
        formatName = 'Trip End';
        break;
      case 'tripstartend':
        formatName = 'Trip Start End';
        break;
      case 'driverlogon':
        formatName = 'Driver Logon/Logoff';
        break;
      case 'busbunching':
        formatName = 'Bus Bunching';
        break;

      case 'textcomms':
        formatName = 'Text Comms';
        break;

      case 'voiplog':
        formatName = 'Voip Log';
        break;

      case 'missedStops':
        formatName = 'Missed Stops';
        break;

      case 'Enter':
        formatName = 'Enter';
        break;

      case 'Exit':
        formatName = 'Exit';
        break;

      case 'BlockLogin':
        formatName = 'Login';
        break;

      case 'offroute':
        formatName = 'Off Route';
        break;

      default:
        formatName = eventName;
        break;
    }
    return { formatName, eventName };
  }

  onChangeSize() {
    this.isMaximize = !this.isMaximize;
  }

  onFilterEVent(event) {
    // console.log(this.allEvents);
    // console.log(this.selectedEvent);
    // console.log(this.selectedVehicle);

    if (this.selectedEvent.length > 0 && this.selectedVehicle.length < 0) {
      // console.log(11);
      let filteredEvent = [];
      this.selectedEvent.forEach(element => {
        let arrEvent = this.allEvents.filter(
          item => item.eventName.indexOf(element) !== -1
        );
        arrEvent.forEach(eventData => {
          filteredEvent.push(eventData);
        });
      });
      this.dataSource.data = filteredEvent;
    } else if (
      this.selectedEvent.length > 0 &&
      this.selectedVehicle.length > 0
    ) {
      // console.log(22);
      let filteredEvent = [];
      this.selectedEvent.forEach(element => {
        // console.log(element);
        let arrEvent = this.allEvents.filter(
          item => item.eventName === element
        );
        arrEvent.forEach(eventData => {
          filteredEvent.push(eventData);
        });
      });
      // console.log(filteredEvent);
      this.dataSource.data = filteredEvent;

      if (this.selectedVehicle !== 'all') {
        let filteredVehicle = [];
        // this.selectedVehicle.forEach(element => {

        let arrEvent = filteredEvent.filter(
          item => item.showBusRegNo === this.selectedVehicle
        );
        arrEvent.forEach(eventData => {
          filteredVehicle.push(eventData);
        });
        // });
        // console.log(filteredVehicle);

        let sortedData = filteredVehicle.sort((a, b) =>
          a.timeStamp > b.timeStamp ? 1 : -1
        );
        this.dataSource.data = sortedData;
      }
    } else if (
      this.selectedEvent.length < 1 &&
      this.selectedVehicle.length > 0
    ) {
      console.log(33);

      // let filteredEvent = [];
      // let arrEvent = this.allEvents.filter(item => item.showBusRegNo === this.selectedVehicle);
      //   arrEvent.forEach(eventData => {
      //     filteredEvent.push(eventData);
      // });
      // this.dataSource.data = filteredEvent;
      this.dataSource.data = this.allEvents;
    } else if (
      this.selectedEvent.length > 0 &&
      this.selectedVehicle.length < 1
    ) {
      console.log(44);
      let filteredEvent = [];
      this.selectedEvent.forEach(element => {
        let arrEvent = this.allEvents.filter(
          item => item.eventName.indexOf(element) !== -1
        );
        arrEvent.forEach(eventData => {
          filteredEvent.push(eventData);
        });
      });
      this.dataSource.data = filteredEvent;
    } else {
      console.log(55);
      this.dataSource.data = this.allEvents;
    }
  }

  onFilterPlayback() {
    if (this.selectedVehicle === 'all') {
      // this.dataSource.data = this.allEvents;
      this.formatEventData();
    } else {
      this.formatEventData(this.selectedVehicle);
    }
  }

  onFilterEvents() {
    console.log(this.selectedEvent);
    if (this.selectedVehicle === 'all') {
      // this.dataSource.data = this.allEvents;
      this.formatEventData(undefined, this.selectedEvent);
    } else {
      this.formatEventData(this.selectedVehicle, this.selectedEvent);
    }
  }

  showTimeString(timestamp) {
    return getTimeString(timestamp);
  }

  onFilterVehicle(vehicleNumber) {
    // this.selectedEvent = [];
    // this.searchData.vehRegNo =
    // this.searchData.vehRegNo = this.selectedVehicle;
    // this.formatEventData();
    if (this.selectedVehicle === 'all') {
      // this.dataSource.data = this.allEvents;
      this.formatEventData();
      return false;
    }
    // this.formatEventData();

    var urlAction = 'playback/v1/ngbms/stoptransitplayback';
    var body = {
      routeId: this.searchData.routeId,
      vehRegNo: this.selectedVehicle,
      serviceDate: this.searchData.serviceDate,
      startTime: this.searchData.startTime,
      endTime: this.searchData.endTime,
      includeMissedStopInfo: true,
    };

    this._commonService.commonPostAction(urlAction, body).subscribe(
      (respData: any) => {
        console.log(respData);
        var test2 = [];
        console.log(respData.missedStops);
        if (
          respData.missedStops &&
          respData.missedStops[this.selectedVehicle]
        ) {
          respData.missedStops[this.selectedVehicle].forEach(element => {
            if (element.busRegNo === this.selectedVehicle) {
              test2.push({
                timeStamp: element.schTime,
                // 'busNo': element.busRegNo,
                busRegNo: element.busRegNo,
                eventName: 'missedStops',
                showBusRegNo: element.busRegNo,
                stopId: element.stopId,
              });
            }
          });
        }

        // if(respData.missedStops[this.selectedVehicle]) {
        // respData.missedStops[this.selectedVehicle].forEach((element,index) => {
        //   test2.push({
        //     'timeStamp': element.schTime,
        //     // 'busNo': element.busRegNo,
        //     'busRegNo': element.busRegNo,
        //     'eventName': 'missedStops',
        //     'showBusRegNo': element.busRegNo,
        //     'stopId': element.stopId
        //   });
        // });
        // }

        // this.dataSource.data.forEach(element => {
        //   test2.push(element);
        // });

        this.clonedOrigData.forEach(element => {
          test2.push(element);
        });

        if (!this.eventListData.includes('missedStops')) {
          this.eventListData.push('missedStops');
        }

        let test3 = test2.filter(
          vehicle => vehicle.showBusRegNo === this.selectedVehicle
        );

        let stopTransit = respData.playback.playbackResp;
        let test4 = [];
        stopTransit.forEach(transit => {
          transit.eventData.forEach(element => {
            // if(element.showBusRegNo === this.selectedVehicle) {
            element.timeStamp = element.rcvdTime;
            element.playbackData.forEach(playback => {
              element.eventName = this.formatEventName(playback.event);
              element.event = playback.event;
              element.showBusRegNo = playback.busNo;
              element.busRegNo = playback.busNo;
              element.stopId = playback.stopId;
              element.stopName = playback.stopName;
              element.latitude = playback.latitude;
              element.longitude = playback.longitude;
              element.tripId = playback.tripId;
              test4.push(element);

              if (!this.eventListData.includes(playback.event)) {
                this.eventListData.push(playback.event);
              }
            });
            // }
          });
        });

        // test3.push(test4);
        let test5 = test3.concat(test4);

        let sortedData = test5.sort((a, b) =>
          a.timeStamp > b.timeStamp ? 1 : -1
        );
        this.dataSource.data = sortedData;
        this.allEvents = sortedData;
        // this.dataSource.data = test5;
        // this.allEvents = test5;
      },
      error => {
        console.log(error);
      }
    );
  }

  onLocate(data) {
    // console.log(this.allEvents);
    this.allEvents.forEach(element => {
      element.isSelected = false;
    });

    data.isSelected = true;
    this.locateEvent.emit(data);
  }

  onLocateStop(data) {
    var urlAction = 'gtfsupload/v1/stops/getStopDetailByCode/' + data.stopId;
    var body = '';

    this._commonService
      .commonPostAction(urlAction, body)
      .subscribe((respData: any) => {
        let stopData = respData[0].stops[0];
        stopData.latitude = stopData.stopLat;
        stopData.longitude = stopData.stopLon;
        console.log(stopData);
        // this.onLocate(respData[0].stops[0]);

        this.allEvents.forEach(element => {
          element.isSelected = false;
        });

        let stopFind = this.allEvents.find(x => x.stopId === stopData.stopCode);
        stopFind.isSelected = true;
        this.locateEvent.emit(stopData);
      });
  }

  isOperator(data) {
    if (data.includes('operator')) {
      return true;
    } else {
      return false;
    }
  }
  // resetEventFilter() {
  //   this.eventListData = [];
  //   this.playbackData.forEach(element => {
  //     this.eventListData.push(element.eventType);
  //   });
  // }
}
