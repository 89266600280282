import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable()
export class VmService {
    constructor(private httpClient: HttpClient
    ){}
    
    getAllVehicle() {
        const promise = new Promise(
            (resolve, reject) => {
                this.httpClient.post(environment.apiServerUrl + 'vehicles/listAllVehicles', null)
                .subscribe(
                    (data:any) => {
                        resolve(data);
                    },
                    err => console.log(err),
                    () => console.log('done get all vehicle')
                );

            }
        );
        return promise;
    }

    getAllVehicleOwner() {
        const promise = new Promise(
            (resolve, reject) => {
                this.httpClient.post(environment.apiServerUrl + 'vehicles/listAllVehicleOwners', null)
                .subscribe(
                    (data:any) => {
                        resolve(data);
                    },
                    err => console.log(err),
                    () => console.log('done get all vehicle owner')
                );

            }
        );
        return promise;
    }

    getAllVehicleTypes() {
        const promise = new Promise(
            (resolve, reject) => {
                this.httpClient.post(environment.apiServerUrl + 'vehicles/listAllVehicleTypes', null)
                .subscribe(
                    (data:any) => {
                        resolve(data);
                    },
                    err => console.log(err),
                    () => console.log('done get all vehicle types')
                );

            }
        );
        return promise;
    }

    getAllVehicleProperty() {
        const promise = new Promise(
            (resolve, reject) => {
                this.httpClient.post(environment.apiServerUrl + 'vehicles/listAllVehicleProperty', null)
                .subscribe(
                    (data:any) => {
                        resolve(data);
                    },
                    err => console.log(err),
                    () => console.log('done get all vehicle property')
                );

            }
        );
        return promise;
    }

    setDriver(driverData) {
      const promise = new Promise(
          (resolve, reject) => {
            this.httpClient.post(environment.apiServerUrl + 'drivers/addDrivers', driverData, {responseType: 'text'})
            .subscribe(
              (data:any) => {
                      
              resolve(data);
            },
            err => console.log(err),
            () => console.log('done set driver')
          );
        }
      );
      return promise;
    }
    
    getAllDeviceSim() {
        return this.httpClient.post(environment.apiServerUrl + 'devices/getDeviceSimCardRecords', null);
    }
    
    getDeviceDataByDeviceId(months) {
        return this.httpClient.post(environment.apiServerUrl + 'devices/getDeviceDataByDeviceId', months);
    }
}