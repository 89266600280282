import {
  Timetable,
  Driver,
  Roster,
} from '../../../shared/services/openapi/roster';

export const sampleScheduleList = [
  [
    {
      firstTripService: '285',
      firstTripStart: '6:03',
      firstTripEnd: '6:54',
      lastTripStart: '14:03',
      lastTripEnd: '14:54',
      lastTripService: '285',
    },
    undefined,
    undefined,
    {
      firstTripService: '285',
      firstTripStart: '6:03',
      firstTripEnd: '6:54',
      lastTripStart: '15:19',
      lastTripEnd: '16:10',
      lastTripService: '285',
    },
    {
      firstTripService: '285',
      firstTripStart: '6:03',
      firstTripEnd: '6:54',
      lastTripStart: '15:19',
      lastTripEnd: '16:10',
      lastTripService: '285',
    },
    {
      firstTripService: '285',
      firstTripStart: '6:03',
      firstTripEnd: '6:54',
      lastTripStart: '15:19',
      lastTripEnd: '16:10',
      lastTripService: '285',
    },
    {
      firstTripService: '285',
      firstTripStart: '6:03',
      firstTripEnd: '6:54',
      lastTripStart: '15:19',
      lastTripEnd: '16:10',
      lastTripService: '285',
    },
  ],
  [
    undefined,
    {
      firstTripService: '41',
      firstTripStart: '7:20',
      firstTripEnd: '8:00',
      lastTripStart: '16:20',
      lastTripEnd: '17:00',
      lastTripService: '41',
    },
    {
      firstTripService: '41',
      firstTripStart: '7:20',
      firstTripEnd: '8:00',
      lastTripStart: '16:20',
      lastTripEnd: '17:00',
      lastTripService: '41',
    },
    {
      firstTripService: '41',
      firstTripStart: '7:20',
      firstTripEnd: '8:00',
      lastTripStart: '16:20',
      lastTripEnd: '17:00',
      lastTripService: '41',
    },
    {
      firstTripService: '41',
      firstTripStart: '7:20',
      firstTripEnd: '8:00',
      lastTripStart: '16:20',
      lastTripEnd: '17:00',
      lastTripService: '41',
    },
    {
      firstTripService: '41',
      firstTripStart: '7:20',
      firstTripEnd: '8:00',
      lastTripStart: '16:20',
      lastTripEnd: '17:00',
      lastTripService: '41',
    },
    undefined,
  ],
  [
    {
      firstTripService: '106',
      firstTripStart: '10:00',
      firstTripEnd: '10:30',
      lastTripStart: '17:00',
      lastTripEnd: '17:30',
      lastTripService: '106',
    },
    {
      firstTripService: '106',
      firstTripStart: '10:00',
      firstTripEnd: '10:30',
      lastTripStart: '17:00',
      lastTripEnd: '17:30',
      lastTripService: '106',
    },
    undefined,
    {
      firstTripService: '106',
      firstTripStart: '10:00',
      firstTripEnd: '10:30',
      lastTripStart: '17:00',
      lastTripEnd: '17:30',
      lastTripService: '106',
    },
    {
      firstTripService: '106',
      firstTripStart: '10:00',
      firstTripEnd: '10:30',
      lastTripStart: '17:00',
      lastTripEnd: '17:30',
      lastTripService: '106',
    },
    undefined,
    {
      firstTripService: '106',
      firstTripStart: '10:00',
      firstTripEnd: '10:30',
      lastTripStart: '17:00',
      lastTripEnd: '17:30',
      lastTripService: '106',
    },
  ],
];

export const sampleDetailSchedule = [
  {
    start: '6:03',
    end: '6:54',
    service: '285',
    block: '32850001',
    trip: '14111003285',
    type: 'duty',
  },
  {
    start: '7:03',
    end: '7:54',
    service: '285',
    block: '32850001',
    trip: '14112003285',
    type: 'duty',
  },
  {
    start: '8:03',
    end: '8:54',
    service: '285',
    block: '32850001',
    trip: '14113003285',
    type: 'duty',
  },
  {
    start: '9:03',
    end: '9:54',
    service: '285',
    block: '32850001',
    trip: '14114003285',
    type: 'duty',
  },
  {
    start: '10:03',
    end: '11:00',
    service: 'break',
    block: undefined,
    type: 'break',
  },
  {
    start: '11:00',
    end: '12:00',
    service: undefined,
    block: undefined,
    trip: undefined,
    type: 'standby',
  },
  {
    start: '12:03',
    end: '12:54',
    service: '285',
    block: '32850001',
    trip: '14115003285',
    type: 'duty',
  },
  {
    start: '13:03',
    end: '13:54',
    service: '285',
    block: '32850001',
    trip: '14116003285',
    type: 'duty',
  },
  {
    start: '14:03',
    end: '14:54',
    service: '285',
    block: '32850001',
    trip: '14117003285',
    type: 'duty',
  },
];

export const dummySchedule1: Roster[] = [
  {
    block_id: 32850001,
    driver_id: '7790302',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-13T08:00:00+08:00',
    end_datetime: '2023-09-13T09:00:00+08:00',
    service_day: '2023-09-13T00:00:00+08:00',
    type: 'ONDUTY',
  },
  {
    block_id: 32850001,
    driver_id: '7790302',
    start_datetime: '2023-09-13T09:00:00+08:00',
    end_datetime: '2023-09-13T10:00:00+08:00',
    service_day: '2023-09-13T00:00:00+08:00',
    type: 'BREAK',
  },
  {
    block_id: 32850001,
    driver_id: '7790302',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-13T10:00:00+08:00',
    end_datetime: '2023-09-13T11:00:00+08:00',
    service_day: '2023-09-13T00:00:00+08:00',
    type: 'ONDUTY',
  },
  {
    block_id: 32850001,
    driver_id: '7790302',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-14T08:00:00+08:00',
    end_datetime: '2023-09-14T09:00:00+08:00',
    service_day: '2023-09-14T00:00:00+08:00',
    type: 'ONDUTY',
  },
  {
    block_id: 32850001,
    driver_id: '7790302',
    start_datetime: '2023-09-14T09:00:00+08:00',
    end_datetime: '2023-09-14T10:00:00+08:00',
    service_day: '2023-09-14T00:00:00+08:00',
    type: 'BREAK',
  },
  {
    block_id: 32850001,
    driver_id: '7790302',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-14T10:00:00+08:00',
    end_datetime: '2023-09-14T11:00:00+08:00',
    service_day: '2023-09-14T00:00:00+08:00',
    type: 'ONDUTY',
  },
  {
    block_id: 32850001,
    driver_id: '7790302',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-15T08:00:00+08:00',
    end_datetime: '2023-09-15T09:00:00+08:00',
    service_day: '2023-09-15T00:00:00+08:00',
    type: 'ONDUTY',
  },
  {
    block_id: 32850001,
    driver_id: '7790302',
    start_datetime: '2023-09-15T09:00:00+08:00',
    end_datetime: '2023-09-15T10:00:00+08:00',
    service_day: '2023-09-15T00:00:00+08:00',
    type: 'BREAK',
  },
  {
    block_id: 32850001,
    driver_id: '7790302',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-15T10:00:00+08:00',
    end_datetime: '2023-09-15T11:00:00+08:00',
    service_day: '2023-09-15T00:00:00+08:00',
    type: 'ONDUTY',
  },
];

export const dummySchedule2: Roster[] = [
  {
    block_id: 32850002,
    driver_id: '7790501',
    start_datetime: '2023-09-11T14:00:00+08:00',
    end_datetime: '2023-09-11T15:00:00+08:00',
    service_day: '2023-09-11T00:00:00+08:00',
    type: 'STANDBY',
  },
  {
    block_id: 32850002,
    driver_id: '7790501',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-13T11:00:00+08:00',
    end_datetime: '2023-09-13T12:00:00+08:00',
    service_day: '2023-09-13T00:00:00+08:00',
    type: 'ONDUTY',
  },
  {
    block_id: 32850002,
    driver_id: '7790501',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-13T12:00:00+08:00',
    end_datetime: '2023-09-13T13:00:00+08:00',
    service_day: '2023-09-13T00:00:00+08:00',
    type: 'ONDUTY',
  },
  {
    block_id: 32850002,
    driver_id: '7790501',
    starting_stop: 17009,
    ending_stop: 17009,
    route_id: '285',
    start_datetime: '2023-09-13T13:00:00+08:00',
    end_datetime: '2023-09-13T14:00:00+08:00',
    service_day: '2023-09-13T00:00:00+08:00',
    type: 'ONDUTY',
  },
  {
    block_id: 32850002,
    driver_id: '7790501',
    start_datetime: '2023-09-13T14:00:00+08:00',
    end_datetime: '2023-09-13T15:00:00+08:00',
    service_day: '2023-09-13T00:00:00+08:00',
    type: 'STANDBY',
  },
];

export const dummyRoster: Timetable[] = [
  {
    schedule: dummySchedule1,
    driver_id: '7790302',
    driver_name: 'John Doe',
  },
  {
    schedule: dummySchedule2,
    driver_id: '7790501',
    driver_name: 'Jane Doe',
  },
];
