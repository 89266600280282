<div class="bus-sched-table-group">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.5)"
    size="large"
    color="#fff"
    type="{{ spinnerType }}"
    [fullScreen]="true"
    ><p style="color: white; font-size: 18px; margin-top: 20px">
      Loading...
    </p></ngx-spinner
  >
  <ng-container *ngIf="isHasAccess; else noAccess">
    <div class="container no-padding" [formGroup]="form" fxLayout="column">
      <div>
        <div>
          <h3 class="title-header">
            Bus Dataset Management<sup
              ><i
                *ngIf="!isNewWindow"
                class="fa fa-window-restore open-window"
                (click)="openWindow()"></i
            ></sup>
          </h3>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="mar-bot-10">
        <div>
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always">
            <mat-label>PTO</mat-label>
            <mat-select formControlName="pto" [(ngModel)]="selectedPTO">
              <mat-option *ngFor="let pto of ptoList" [value]="pto">
                {{ pto }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always">
            <mat-label>Choose a date</mat-label>
            <input
              matInput
              [matDatepickerFilter]="myFilter"
              [matDatepicker]="picker"
              formControlName="schedDate" />

            <mat-datepicker-toggle
              matSuffix
              [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
          </mat-form-field>

          <button
            mat-raised-button
            type="button"
            (click)="onSearch()"
            [disabled]="running || !form.valid">
            Search
          </button>
        </div>

        <div>
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" />
          </mat-form-field>
          <button
            mat-raised-button
            type="button"
            (click)="onManualUpdate()"
            [disabled]="idxDay.length > 0 ? false : true">
            Manual Updates
          </button>
        </div>
      </div>

      <div
        fxFlex
        class="table-wrapper custom-page-scroll"
        *ngIf="dataSource.data.length !== 0">
        <table
          mat-table
          matTableExporter
          [dataSource]="dataSource"
          matSort
          #exporter="matTableExporter"
          (exportStarted)="exportStarted('Exporting...')"
          (exportCompleted)="exportCompleted()">
          <ng-container
            [matColumnDef]="column"
            *ngFor="let column of displayedColumns; let idx = index">
            <th
              class="p-10"
              [ngClass]="{
                selectedDay: column.split(';')[0] === selectedDay,
                rangeDay:
                  column.split(';')[0] === today ||
                  column.split(';')[0] === tmr ||
                  column.split(';')[0] === dayAftTmr
              }"
              mat-header-cell
              *matHeaderCellDef>
              {{ column.split(';')[0] }}<br />{{ column.split(';')[1] }}
            </th>
            <ng-container *ngIf="idx === 0; else dataCell">
              <td mat-cell *matCellDef="let element">{{ element.busRegNo }}</td>
            </ng-container>

            <ng-template #dataCell>
              <td
                class="p-10"
                [ngClass]="{
                  weekends: idx === 1 || idx === 7 || idx === 8 || idx === 14,
                  selectedDay: column.split(';')[0] === selectedDay,
                  rangeDay:
                    column.split(';')[0] === today ||
                    column.split(';')[0] === tmr ||
                    column.split(';')[0] === dayAftTmr
                }"
                mat-cell
                *matCellDef="let element">
                <ng-container *ngIf="element.processStatuses[idx - 1] === 1">
                  <mat-icon
                    class="statusGreen pointer"
                    matTooltip="View Datasets"
                    (click)="onGetDataStatus(element.busRegNo, column)"
                    >check_circle</mat-icon
                  >
                </ng-container>

                <ng-container *ngIf="element.processStatuses[idx - 1] === 0">
                  <mat-icon
                    class="statusRed pointer"
                    matTooltip="View Datasets"
                    (click)="onGetDataStatus(element.busRegNo, column)"
                    >cancel-circle</mat-icon
                  >
                </ng-container>

                <ng-container *ngIf="element.processStatuses[idx - 1] === 2">
                  <mat-icon
                    class="statusRed pointer textYellow"
                    matTooltip="View Datasets"
                    (click)="onGetDataStatus(element.busRegNo, column)"
                    >download</mat-icon
                  >
                </ng-container>
              </td>
            </ng-template>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
              highlighted: row.highlighted
            }"></tr>
        </table>
      </div>

      <div
        [ngClass]="{
          'd-none': dataSource.data.length === 0
        }">
        <mat-paginator
          #paginator
          [pageIndex]="0"
          [pageSize]="100"
          [pageSizeOptions]="[100, 500, 1000]"></mat-paginator>
      </div>
    </div>
  </ng-container>

  <ng-template #noAccess>
    <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
      <h3>You don't have access rights to this module.</h3>
    </div>
  </ng-template>
  <div id="snackbar-container">
    <div
      [@fadeInOut]
      class="{{ snackbar.status }} snackbar show"
      *ngFor="let snackbar of snackbarList"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <span class="mar-right-10">{{ snackbar.description }}</span>
      <mat-icon (click)="onCloseSnackBar(snackbar)">close</mat-icon>
    </div>
  </div>
</div>
