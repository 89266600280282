import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { freeTextMessageData } from '../others/data-types';
// import { Subject, Observable, Subscription } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
// import { MatSpinner } from '@angular/material';
import { ComponentPortal } from '@angular/cdk/portal';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FilePreviewOverlayRef } from './file-preview-overlay-ref';
import { SpinnerComponent } from '../../common/spinner/spinner.component';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './auth.service';
// import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { VoipService } from './voip.service';
import { Router } from '@angular/router';
import { HOME_PATH, LOGIN_PATH } from '../others/constant';
import { TimeoutModalComponent } from './timeout-modal/timeout-modal.component';
import { FirebaseService } from './firebase.service';
import { PushNotificationService } from './push-notification.service';
import { LocalForageService } from './local-forage.service';
interface FilePreviewDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}
const DEFAULT_CONFIG: FilePreviewDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
};

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private spinnerTopRef = this.cdkSpinnerCreate();
  spin$: Subject<boolean> = new Subject();
  spinnerType: any = [
    'ball-8bits',
    'ball-atom',
    'ball-beat',
    'ball-circus',
    'ball-climbing-dot',
    'ball-clip-rotate',
    'ball-clip-rotate-multiple',
    'ball-clip-rotate-pulse',
    'ball-elastic-dots',
    'ball-fall',
    'ball-fussion',
    'ball-grid-beat',
    'ball-grid-pulse',
    'ball-newton-cradle',
    'ball-pulse',
    'ball-pulse-rise',
    'ball-pulse-sync',
    'ball-rotate',
    'ball-running-dots',
    'ball-scale',
    'ball-scale-multiple',
    'ball-scale-pulse',
    'ball-scale-ripple',
    'ball-scale-ripple-multiple',
    'ball-spin',
    'ball-spin-clockwise',
    'ball-spin-clockwise-fade',
    'ball-spin-clockwise-fade-rotating',
    'ball-spin-fade',
    'ball-spin-fade-rotating',
    'ball-spin-rotate',
    'ball-square-clockwise-spin',
    'ball-square-spin',
    'ball-triangle-path',
    'ball-zig-zag',
    'ball-zig-zag-deflect',
    'cog',
    'cube-transition',
    'fire',
    'line-scale',
    'line-scale-party',
    'line-scale-pulse-out',
    'line-scale-pulse-out-rapid',
    'line-spin-clockwise-fade',
    'line-spin-clockwise-fade-rotating',
    'line-spin-fade',
    'line-spin-fade-rotating',
    'pacman',
    'square-jelly-box',
    'square-loader',
    'square-spin',
    'timer',
    'triangle-skew-spin',
  ];

  constructor(
    private httpClient: HttpClient,
    private overlay: Overlay,
    private idle: Idle,
    private keepalive: Keepalive,
    private _dialog: MatDialog,
    private _firebaseService: FirebaseService,
    private _notification: PushNotificationService,
    // private auth: AuthService,
    // private _voipService: VoipService,
    private _router: Router,
    private forageService: LocalForageService
  ) {
    this.onIdle();
    this.initFirebaseNotification();
    this.forageService.init();
    this.spin$
      .asObservable()
      .pipe(
        map(val => (val ? 1 : -1)),
        scan((acc, one) => (acc + one >= 0 ? acc + one : 0), 0)
      )
      .subscribe(res => {
        if (res === 1) {
          this.showSpinner();
        } else if (res == 0) {
          this.spinnerTopRef.hasAttached() ? this.stopSpinner() : null;
        }
      });
  }

  initFirebaseNotification() {
    let token = localStorage.getItem('access_token');

    if (token) {
      const { agency = '' } = jwt_decode<any>(token);
      // console.log('firebase - logged-in user init token');
      this._firebaseService.init(agency);
      this._notification.init();
    }
  }

  commonPostAction(urlAction, data, responseType = 'json') {
    let url = environment.apiServerUrl + urlAction;

    return this.httpClient
      .post(url, data, { responseType: responseType as 'json' })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  commonGetAction(urlAction) {
    let url = environment.apiServerUrl + urlAction;

    return this.httpClient.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  commonPostFullUrlAction(urlAction, data) {
    // let url = this.configService.apiServerUrl + urlAction;
    return this.httpClient.post(urlAction, data).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  commonGetFullUrlAction(urlAction) {
    // let url = this.configService.apiServerUrl + urlAction;
    return this.httpClient.get(urlAction).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  commonDeleteAction(urlAction, data) {
    let url = environment.apiServerUrl + urlAction;

    return this.httpClient.request('delete', url, { body: data }).pipe(
      map((data: any) => {
        return data;
      })
    );
    // return this.httpClient.delete(url, data).pipe(
    //   map((data: any) => {
    //     return data;
    //   })
    // );
  }

  private cdkSpinnerCreate() {
    console.log('overlay');
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  private showSpinner() {
    console.log('show spinner');
    this.spinnerTopRef.attach(new ComponentPortal(MatProgressSpinner));
  }

  private stopSpinner() {
    this.spinnerTopRef.detach();
  }

  private createOverlay(config: FilePreviewDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: FilePreviewDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  }

  overlayRef = this.createOverlay({ ...DEFAULT_CONFIG, ...{} });
  openOverlay() {
    console.log('open overlay');
    // Override default configuration
    // const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    // const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new FilePreviewOverlayRef(this.overlayRef);

    // Create ComponentPortal that can be attached to a PortalHost
    const filePreviewPortal = new ComponentPortal(SpinnerComponent);

    // Attach ComponentPortal to PortalHost
    this.overlayRef.attach(filePreviewPortal);

    this.overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  closeOverlay() {
    console.log('close overlay');
    this._dialog.closeAll();
    this.overlayRef.detach();
  }

  commonDataMallAction(endPoint) {
    var url = environment.nodeUrl + 'datamall';
    var bodyData = {
      url: environment.apiDataMallUrl + endPoint,
    };

    return this.httpClient.post(url, bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  formatPlanTime(stop) {
    var schArrTime;
    let plan = moment(stop.schArrTime, 'H:mm');
    //start invalid dates like 24-28hrs
    if (!plan.isValid()) {
      let planTimeInvalid = stop.schArrTime.split(':');
      schArrTime = planTimeInvalid[0] + ':' + planTimeInvalid[1];
    } else {
      schArrTime =
        stop.schArrTime === null
          ? ''
          : moment(stop.schArrTime, 'HH:mm:ss').format('HH:mm'); //plan.format("HH:MM");
    }

    if (schArrTime === '00:00') {
      schArrTime = '24:00';
    }
    return {
      schArrTime: schArrTime,
    };
  }

  formatActualTime(stop) {
    var actualTime = '';
    var obsArrTime = '';

    if (stop.obsArrTime) {
      if (stop.obsArrTime.split(' ')[1]) {
        actualTime = stop.obsArrTime.split(' ')[1];
      } else {
        actualTime = stop.obsArrTime;
      }
    }

    var plan = moment(stop.schArrTime, 'H:mm');
    var actual = moment(actualTime, 'H:mm');
    var ss = moment(actual).diff(moment(plan), 'seconds');

    if (!plan.isValid() && !actual.isValid() && stop.obsArrTime !== null) {
      let actualTimeInvalid = stop.obsArrTime.split(':');
      let planTimeInvalid = stop.schArrTime.split(':');
      let actTime = moment(
        actualTimeInvalid[0] - 24 + ':' + actualTimeInvalid[1],
        'H:mm'
      );
      let plaTime = moment(
        planTimeInvalid[0] - 24 + ':' + planTimeInvalid[1],
        'H:mm'
      );

      ss = moment(actTime).diff(moment(plaTime), 'seconds');

      // console.log(stop.stopId, plaTime, ss, actTime);
      obsArrTime = actualTimeInvalid[0] + ':' + actualTimeInvalid[1];
    } else if (stop.obsArrTime !== null) {
      if (stop.obsArrTime.split(' ')[1]) {
        let actualTimeFormat = stop.obsArrTime.split(' ')[1].split(':');
        // obsArrTime = stop.obsArrTime === null ? '' : moment(stop.obsArrTime).format("HH:mm");
        obsArrTime = actualTimeFormat[0] + ':' + actualTimeFormat[1];
      } else {
        obsArrTime = stop.obsArrTime;
      }
    }

    let statusColor;
    if (ss <= -180) {
      //-240
      statusColor = 'early';
    } else if (ss >= -179 && ss <= 179) {
      //else if(ss >= -239 && ss <= 239) {
      statusColor = 'on-time';
    } else if (ss >= 180) {
      //240
      statusColor = 'late';
    }

    return {
      obsArrTime: obsArrTime,
      statusColor: statusColor,
    };
  }

  formatHeadwayTime(stop) {
    // var headwayPlanTime = 6;
    // var headwayActualTime = 5;
    // var diffValue = headwayPlanTime - headwayActualTime;

    if (stop.headwayDeviation === null) {
      return {
        headwayDeviation: headwayDeviation,
        headwayStatusColor: 'bgGray',
      };
    }

    var headwayDeviation = parseInt(stop.headwayDeviation) / 60;
    let statusColor;
    // if(headwayDeviation <= -3) {
    //   statusColor = 'early';
    // }
    // else if(headwayDeviation >= -3 && headwayDeviation < 3) {
    //   statusColor = 'on-time';
    // }
    // else if(headwayDeviation >= 3) {
    //   statusColor = 'late';
    // }

    if (stop.headwayDeviation <= -180) {
      //240
      statusColor = 'late';
    } else if (stop.headwayDeviation >= -179 && stop.headwayDeviation <= 179) {
      //else if(stop.headwayDeviation >= -239 && stop.headwayDeviation <= 239) {
      statusColor = 'on-time';
    } else if (stop.headwayDeviation >= 180) {
      //240
      statusColor = 'early';
    }

    return {
      headwayDeviation: headwayDeviation,
      headwayStatusColor: statusColor,
    };
  }

  IsValidJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  idleEnd;
  idleTimeout;
  idleStart;
  idleTimeoutWarning;
  idleKeepAlive;
  onIdle() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(21600); // 6hrs
    // this.idle.setIdle(1800); // 30 minutes
    // this.idle.setIdle(5);

    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // this.idle.setTimeout(21600); // 6hrs
    // this.idle.setTimeout(10);

    // Keep Alive
    if (!this.idleKeepAlive || this.idleKeepAlive?.closed) {
      // sets the ping interval to 2 minutes
      this.keepalive.interval(120);
      // this.keepalive.interval(15);
      this.idleKeepAlive = this.keepalive.onPing.subscribe(() => {
        if (this.isAuthenticated()) {
          this.lastPing = new Date();
          // console.log('Idle Service: KeepAlive: ', this.lastPing);
          // this.createAuthAcvitiy();
        }
      });
    }

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();

    if (!this.idleTimeout || this.idleTimeout?.closed) {
      this.idleTimeout = this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;

        this.logout();
      });
    }

    if (!this.idleEnd || this.idleEnd?.closed) {
      this.idleEnd = this.idle.onIdleEnd.subscribe(() => {
        // console.log('idle end: ', this.idleState, this.timedOut);
        this.idleState = 'No longer idle.';
        this._dialog.closeAll();
        this.idle.stop();
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
        // this._appRef.tick();
      });
    }

    if (!this.idleStart || this.idleStart?.closed) {
      this.idleStart = this.idle.onIdleStart.subscribe(() => {
        if (!this.isAuthenticated()) {
          return false;
        }

        // disable interrupts - resume interrupt once we click continue
        this.idle.clearInterrupts();

        // this.idle.watch();
        this.idleState = "You've gone idle! onIdle func";

        // console.log('idle start: ', this.idleState, this.timedOut);

        this._dialog.closeAll();

        const dialogRef = this._dialog.open(TimeoutModalComponent, {
          data: {
            header: 'Session Timeout',
            message:
              'You want to continue your session? Automatically logout in 60 seconds.',
            textConfirm: 'Continue',
            textCancel: 'Logout',
          },
          disableClose: true,
          // height: '400px',
          width: '350px',
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.logout();
            this._dialog.open(InfoModalComponent, {
              data: {
                header: 'Session Timeout',
                message: 'Please relogin',
              },
              disableClose: true,
              // height: '400px',
              width: '250px',
            });
          } else if (result === false) {
            // this.reset();
            this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
            this.idle.watch();
            this.idleState = 'Started.';
            this.timedOut = false;
            // this.createAuthAcvitiy();
          }
        });
      });
    }

    if (!this.idleTimeoutWarning || this.idleTimeoutWarning?.closed) {
      this.idleTimeoutWarning = this.idle.onTimeoutWarning.subscribe(
        countdown => {
          this.idleState = 'You will time out in ' + countdown + ' seconds!';
          console.log(this.idleState);
        }
      );
    }
  }

  createAuthAcvitiy() {
    var urlAction =
      environment.apiServerUrl + 'users/updateUserLastActiveDateTime';
    this.commonPostFullUrlAction(urlAction, '').subscribe(
      account => {},
      error => {
        console.log(error);
      }
    );
  }

  logout() {
    this.closeOverlay();
    this.logoutUser();

    let urlPath = this._router.url;
    console.log('url path', urlPath, urlPath.indexOf('account/home'));
    localStorage.clear();
    this.forageService.clear();

    this.idleStart?.unsubscribe?.();
    this.idleEnd?.unsubscribe?.();
    this.idleTimeout?.unsubscribe?.();
    this.idleTimeoutWarning?.unsubscribe?.();
    this.idleKeepAlive?.unsubscribe?.();

    if (urlPath === '/' || urlPath.indexOf(HOME_PATH) > -1) {
      this._router.navigate([LOGIN_PATH]);
    } else {
      this._router.navigate([LOGIN_PATH], {
        queryParams: { redirect: urlPath },
      });
    }

    // var bodyOptions = {
    //   "sipId": localStorage.getItem('sipNumber')
    // }

    // this._voipService.logOffSipNumber(bodyOptions).subscribe(
    //   resp => {
    //     // console.log(resp);
    //     // this.commonService.spin$.next(false);
    //     this.closeOverlay();
    //     this.logoutUser();
    //     localStorage.clear();

    //     this.idleStart?.unsubscribe?.();
    //     this.idleEnd?.unsubscribe?.();
    //     this.idleTimeout?.unsubscribe?.();
    //     this.idleTimeoutWarning?.unsubscribe?.();
    //     this.idleKeepAlive?.unsubscribe?.();

    //     this._router.navigate([LOGIN_PATH])
    //     .then(() => {
    //       // window.location.reload();
    //     });
    //   },
    //   (error) => {
    //     console.log(error.error.error);
    //     if(error.error.error === "invalid_token") {
    //       console.log(error.error);
    //       this.closeOverlay();
    //       this.logoutUser();
    //       localStorage.clear();

    //       this.idleStart?.unsubscribe?.();
    //       this.idleEnd?.unsubscribe?.();
    //       this.idleTimeout?.unsubscribe?.();
    //       this.idleTimeoutWarning?.unsubscribe?.();
    //       this.idleKeepAlive?.unsubscribe?.();

    //       this._router.navigate([LOGIN_PATH])
    //       .then(() => {
    //         window.location.reload();
    //       });
    //     }
    //   //   localStorage.clear();
    //   //   this.closeOverlay();
    //   //   this._router.navigate([LOGIN_PATH])
    //   //   .then(() => {
    //   //     window.location.reload();
    //   //   });
    //   }
    // );
  }

  logoutUser() {
    const decodedToken: any =
      jwt_decode(localStorage.getItem('access_token')) || {};
    const { agency } = decodedToken;
    this._firebaseService.unsubscribe(agency);
    // this._firebaseService.deleteFirebaseInstance();
    this._notification.onExit();
    var urlAction = environment.apiServerUrl + 'users/logoutUser';
    this.commonPostFullUrlAction(urlAction, '').subscribe(
      account => {
        window.name = '';
        localStorage.clear();
      },
      error => {
        console.log('Error logout: ', error);
      }
    );
  }

  isAuthenticated() {
    return localStorage.getItem('access_token') != undefined;
  }

  // opening window as blank URL will prevent refresh if window is already open.
  // window object will not persist on refresh, and is not shared between tabs.
  // function will only re-open same window if its the child window of this page.
  // we update the URL if location.hostname is not defined
  // use newWindowName = '_blank' to open a new window everytime
  windows = {};
  openWindow(
    url,
    currentWindowName,
    newWindowName,
    windowFeatures?: string,
    forceOpen?: boolean
  ) {
    if (
      !this.windows[newWindowName] ||
      this.windows[newWindowName].closed ||
      forceOpen
    ) {
      this.windows[newWindowName] = window.open(
        '',
        newWindowName,
        windowFeatures
      );
      window.name = currentWindowName;
    }
    this.windows[newWindowName]?.focus();
    if (!this.windows[newWindowName].location.hostname) {
      this.windows[newWindowName].location.href = url;
    }
  }
}
