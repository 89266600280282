<div class="add-role-group">
  <h2 mat-dialog-title>Add Role</h2>

  <mat-dialog-content>
    
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="space-around stretch" >
        <mat-form-field>
          <mat-label>Role Name</mat-label>
          <input matInput formControlName="roleName" placeholder="Filter">
        </mat-form-field>

        <!-- <mat-form-field *ngFor="let page of dialogData.data.pageList">
          <mat-checkbox >{{page.pageName}}</mat-checkbox>
        </mat-form-field> -->
        
        <label formArrayName="orders" *ngFor="let order of ordersFormArray.controls; let i = index">
          <mat-checkbox  type="checkbox" [formControlName]="i"> 
            <!-- {{webData[i].name}}  -->
            {{pageList[i].name}} 
          </mat-checkbox>
        </label>
        
        <!-- <mat-form-field formArrayName="orders"  *ngFor="let page of ordersFormArray.controls; let i = index">
          <mat-checkbox [formControlName]="i" >{{page[i].pageName}}</mat-checkbox>
        </mat-form-field> -->

      </div>
    </form>
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="onSaveRole()" [disabled]="running">Save</button>
    <!-- <mat-spinner diameter="50" class="margin-auto"></mat-spinner> -->
  </mat-dialog-actions>
</div>