export const environment = {
  production: true,
  sw: true,
  // hereApiKey: "GMwaocRFd7MYjesozSLak2VKgHQaxP0O43GpShenrvg", // current api key is not working
  // hereApiKey: '7C9usjIdAKFv1IwcnXUNqQQpOaU0pDtekWNv39Xvl_E', // temporary free account key
  // hereApiKey: '-5z5Zt-FDUkYNC9sMeALrUJjapnm5YeR-4VVv_0p-aw', // alt key
  hereApiKey: '7C9usjIdAKFv1IwcnXUNqQQpOaU0pDtekWNv39Xvl_E', // platform API key
  disableTraffic: false,
  esriApiKey:
    'AAPK630f50cb7bcc4fb4b404ed6b9a6db9bfUO6g6qOkzBj_z0BI7WwcpAH2Mqy57uOC-fLB6QuOlL3kMxFAHQD3XvEEnikwpD-W',

  jitsi: {
    // appId: 'vpaas-magic-cookie-252ba2c221224f128aa9cfdfbb199b5d', // marron
    appId: 'vpaas-magic-cookie-e2cbd04eae0d4ba18488663ae3ee2459', // mathew
    domain: '8x8.vc',
    sub: 'vpaas-magic-cookie-e2cbd04eae0d4ba18488663ae3ee2459',
    iss: 'chat',
    apiKey: 'ax0wsko00UoMugkjHMMjEfbKzIAx6slROlId7AuExDPo1k1b0r',
  },

  firebase: {
    nodeUrl: 'https://ngbms-service.agilfms.org:5010/',
    TTS: {
      appId: '1:87009280389:web:6d54ca6f0e718fa16c04ec',
      measurementId: 'G-3XR0DC6M90',
    },
    SBST: {
      appId: '1:87009280389:web:76d816c44c3e2ce06c04ec',
      measurementId: 'G-DY1JTFKD6D',
    },
    SMRT: {
      appId: '1:87009280389:web:b81b75ac966a726c6c04ec',
      measurementId: 'G-W8KML95S76',
    },
    GAS: {
      appId: '1:87009280389:web:6058d9de82ca6f7d6c04ec',
      measurementId: 'G-VBBYHTBPYS',
    },
    ADMIN: {
      appId: '1:87009280389:web:e9020f960019768a6c04ec',
      measurementId: 'G-5ZQCVW6XPP',
    },
    common: {
      apiKey: 'AIzaSyDUrJYWUcrzsM3tAVj-sCg1Doa3JnLBi1Y',
      authDomain: 'st-ngbms-uat.firebaseapp.com',
      projectId: 'st-ngbms-uat',
      storageBucket: 'st-ngbms-uat.appspot.com',
      messagingSenderId: '87009280389',
      vapidKey:
        'BKrhsSX1szGR2Pmz1sE_nZKXNZcAJ5FZ_ACrzOVNBgN-PIdj81flW4kuKC6EUtS7GPkBNcaqGGEHQZnas7tnzsA',
    },
  },

  project: 'cetrac',
  countryCode: 'SG',
  timezone: '+0800',
  apiServerUrl: 'https://ngbms-sg.com:8080/',
  nodeUrlLocal: 'http://localhost:3000/',
  nodeUrl: 'https://agilfms.org:444/',
  nodeUrlWs: 'wss://agilfms.org:8080/',
  nodeUrlLocalWs: 'ws://localhost:8998/',
  apiServerGtfsUrl: 'https://ngbms-sg.com:8080/gtfsupload/',
  apiRouteSummary: 'https://ngbms-sg.com:8080/gtfsupload/getRouteSummary/',
  apiServerMapBoxUrl: 'https://api.mapbox.com/directions/v5/mapbox/',
  apiDataMallUrl: 'http://datamall2.mytransport.sg/ltaodataservice/',
  apiServerRosterUrl: 'https://ngbms-sg.com:8080/rostering',

  isBusStopCodeShow: true,
  isRouteNameUse: false,

  image: {
    login: 'sg.jpg',
    logo: 'st-logo.png',
    wordLogo: 'cetracLogo.png',
  },
  map: {
    staticMap: 'https://map-tiles.agilfms.org',
    osmLayer:
      'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    pathColor: '#002fff',
    pathOtherDirectionColor: '#be0af5',
    pathWidth: 4,
    pathWidthHover: 6,
    locationLonLat: [103.8198, 1.3521],
    mapBoxAccessToken:
      'pk.eyJ1IjoiY2FzcGVybWFjYXRhbmdheSIsImEiOiJjanN2bTR1cnEwNHI5NDNvZnJhd2FkcmpoIn0.DkVZXWivnqAUOgBEGjQ5pA',
    map3dLocation: [103.831435, 1.303312],
    minZoom: 10,
    defaultZoom: 12,
    maxZoom: 17,
    boundingExtent: {
      min: [103.59, 1.13],
      max: [104.1, 1.49],
    },
  },
  menu: {
    home: { isVisible: true, id: 3 },
    kpi: { isVisible: false, id: '' },
    event: { isVisible: false, id: '' },
    trafficCongestion: { isVisible: false, id: '' },
    reports: {
      schedule: { isVisible: false, id: '' },
      interval: { isVisible: false, id: '' },
      tripCauseCode: { isVisible: false, id: '' },
    },
    personnelManagement: {
      drivers: { isVisible: false, id: '' },
      controllers: { isVisible: false, id: '' },
    },
    vehicleManagement: {
      vehicles: {
        isVisible: false,
        id: '',
        route: ['/account/vehicle-management/vehicle', 1],
        title: 'Vehicles',
      },
      devices: {
        isVisible: false,
        id: '',
        route: ['/account/vehicle-management/device', 1],
        title: 'Devices',
      },
      deviceMonitor: {
        isVisible: true,
        id: 4,
        route: ['/account/vehicle-management/device-monitor', 1],
        title: 'Device Monitoring',
      },
      vehicleList: {
        isVisible: false,
        id: '',
        route: ['/account/vehicle-management/vehicle-list', 1],
        title: 'Vehicle List',
      },
    },
    accountManagement: {
      userManagement: {
        isVisible: true,
        id: 9,
        route: ['/account/system-configuration/account-management/user', 1],
        title: 'User Management',
      },
      roleManagement: {
        isVisible: true,
        id: 8,
        route: ['/account/system-configuration/account-management/role', 1],
        title: 'Role Access Management',
      },
      responsibility: {
        isVisible: true,
        id: 10,
        route: [
          '/account/system-configuration/account-management/responsibility',
          1,
        ],
        title: 'User Responsibility',
      },
    },
    systemConfiguration: {
      stopManagement: {
        isVisible: true,
        id: 1,
        route: ['/account/system-configuration/stop-search', 1],
        title: 'Stop Management',
      },
      serviceManagement: {
        isVisible: true,
        id: 2,
        route: ['/account/system-configuration/service/create', 1],
        title: 'Service Route Management',
      },
      scheduleManagement: {
        isVisible: false,
        id: '',
        route: ['/account/system-configuration/schedule/search', 1],
        title: 'Schedule Management',
      },
    },
    dispatcher: { isVisible: false, id: '' },
    routeManagement: {
      route: { isVisible: false, id: '' },
      segment: { isVisible: false, id: '' },
    },
    schedule: { isVisible: false, id: '' },
    //   "userManagement": { "isVisible": false },
    incidentManagement: { isVisible: false, id: '' },
    messages: { isVisible: false, id: '' },
    masterdata: {
      vehicleList: {
        isVisible: false,
        id: '',
        route: ['', 1],
        title: 'Vehicles List',
      },
      driverList: {
        isVisible: false,
        id: '',
        route: ['', 1],
        title: 'Drivers List',
      },
      organization: {
        isVisible: false,
        id: '',
        route: ['', 1],
        title: 'Organizational Data',
      },
    },
    driverManagement: {
      driverList: {
        isVisible: true,
        id: '',
        route: ['/account/driver-management/list/', 1],
        title: 'Driver List',
      },
      dutyRoster: {
        isVisible: true,
        id: '',
        route: ['/account/driver-management/duty-rostering/', 1],
        title: 'Driver Duty Roster',
      },
    },
    timeTable: { isVisible: true, id: 5 },
    multiLadder: { isVisible: false, id: '' },
    playback: { isVisible: true, id: 7 },
    alarmManagement: { isVisible: false, id: '' },
    dispatch: { isVisible: false, id: '' },
    driverBehaviour: { isVisible: false, id: '' },
    dutyRoster: { isVisible: true, id: '' },
    fleetHealth: { isVisible: false, id: '' },
    fleetMaintenance: { isVisible: false, id: '' },
    fuelConsumption: { isVisible: false, id: '' },
    managementDashboard: { isVisible: false, id: '' },
    busScheduleDataManagement: { isVisible: true, id: '11' },
  },

  home: {
    busDetailIsRouteName: false,
  },

  bus: {
    clearInterval: 60000,
    ETARefreshTime: 60000,
  },

  apiUrl: {
    uploadSchedule:
      'https://ngbms-sg.com:8080/gtfsupload/v1/uploadScheduleZipFile',
    uploadTimeTableVdv: 'https://ngbms-sg.com:8080/vdv/v1/uploadTTSVDVZipFile',
    uploadDispatchDat:
      'https://ngbms-sg.com:8080/dispatch/v1/uploadDispatchFile',
  },

  featureListSorting: [
    {
      id: 3,
      priorityNumber: 1,
      name: 'Home',
    },
    {
      id: 10,
      priorityNumber: 2,
      name: 'User Management',
    },
    {
      id: 8,
      priorityNumber: 3,
      name: 'Role Management',
    },
    {
      id: 9,
      priorityNumber: 4,
      name: 'User Responsibility',
    },
    {
      id: 4,
      priorityNumber: 5,
      name: 'Device Monitoring',
    },
    {
      id: 1,
      priorityNumber: 6,
      name: 'Stop Management',
    },
    {
      id: 2,
      priorityNumber: 7,
      name: 'Service Management',
    },
    {
      id: 5,
      priorityNumber: 8,
      name: 'Time Table',
    },
    {
      id: 7,
      priorityNumber: 9,
      name: 'Playback',
    },
    {
      id: 6,
      priorityNumber: 10,
      name: 'Account Management',
    },
  ],

  // idleTimer: 1800,
  idleTimer: 21600,

  accountMgmt: {
    agencyList: [
      { value: 'SBST', viewValue: 'SBST' },
      { value: 'SMRT', viewValue: 'SMRT' },
      { value: 'TTS', viewValue: 'TTS' },
      { value: 'GAS', viewValue: 'GAS' },
    ],
  },

  serviceRouteMgmt: {
    distance: false,
  },
};
