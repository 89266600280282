import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  cars = [];
  cols: any[];
  isNewWindow: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
    this.cars = [
      {"routeId":"25","busNumber":"SBS 2480R","eventTypeId":"3","eventType":"Break down","location":"1.368973, 103.867356","prevStopId":"54281","prevStopName":"Blk 570","nextStopId":"54291","nextStopName":"Blk 564","eventTime":"10:25:48"},
      {"routeId":"25","busNumber":"SBS 7594R","eventTypeId":"1","eventType":"Road closure ahead","location":"1.368973, 103.867356","prevStopId":"54281","prevStopName":"Ang Mo Kio Stn","nextStopId":"54291","nextStopName":"Blk 586","eventTime":"10:10:26"},
      {"routeId":"25","busNumber":"SBS 7594R","eventTypeId":"1","eventType":"Vehicle off course","location":"1.368973, 103.867356","prevStopId":"54281","prevStopName":"Ang Mo Kio Stn","nextStopId":"54291","nextStopName":"Blk 586","eventTime":"10:16:32"},
      {"routeId":"25","busNumber":"SBS 2687R","eventTypeId":"1","eventType":"Accident ahead","location":"1.368973, 103.867356","prevStopId":"54281","prevStopName":"Blk 516","nextStopId":"54291","nextStopName":"Blk 586","eventTime":"10:15:56"},
      {"routeId":"197","busNumber":"SBS 3852R","eventTypeId":"2","eventType":"Fallen tree ahead","location":"1.368973, 103.867356","prevStopId":"54281","prevStopName":"Panasonic","nextStopId":"54291","nextStopName":"Fujitec S'pore Ltd","eventTime":"09:13:27"},
      {"routeId":"197","busNumber":"SBS 3852R","eventTypeId":"2","eventType":"Roadworks ahead","location":"1.368973, 103.867356","prevStopId":"54281","prevStopName":"Opp SIT Dover","nextStopId":"54291","nextStopName":"Blk 610","eventTime":"08:46:19"}
    ];
    this.cols = [
        { field: 'routeId', header: 'Route' },
        { field: 'busNumber', header: 'Bus number' },
        { field: 'eventType', header: 'Event type' },
        { field: 'location', header: 'Location' },
        { field: 'prevStopName', header: 'Previous Stop' },
        { field: 'nextStopName', header: 'Next Stop' },
        { field: 'eventTime', header: 'Event time' }
    ];
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/events/2','events','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }

}
