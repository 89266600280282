import { ActivatedRouteSnapshot } from '@angular/router';

export enum ColorData {
  eGreen = '#029002',
  eYellow = '#ffff00',
  eRed = '#ff0000',
  eGray = '#939393',
  eOrange = '#df5E42',
}

export enum MapType {
  OSM = 'OSM',
  Navigation = 'ArcGIS:Navigation',
  Satellite = 'ArcGIS:Imagery:Standard',
}

export enum MqttType {
  eVehiclePosition = 'vehiclePosition',
  ePredefinedMessages = 'preDefinedMessage',
  eOffRoute = 'offRoute',
  etimeTable = 'timeTable',
}

export const LOGIN_PATH: string = '/login';
export const HOME_PATH: string = '/account/home';
export const DEMO_PATH: string = '/map-demo';
export const DEMO_ROOT = '/demo';
export const COMMS_PATH: string = '/comms';
export const CALL_PATH: string = '/call';

export const getPath = function (route: ActivatedRouteSnapshot): string {
  return (
    '/' +
    route.pathFromRoot
      .filter(v => v.routeConfig)
      .map(v => v.routeConfig!.path)
      .join('/')
  );
};

export const CountryCode: any = [
  {
    listCode: 'SG',
    listName: 'Singapore',
    listDesc: 'Singapore',
  },
  {
    listCode: 'PH',
    listName: 'Clark',
    listDesc: 'Clark, Pampanga',
  },
];

export interface UserElement {
  userId: string;
  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  identificationId: string;
  identificationDocType: string;
  primaryPhone: string;
  secondaryPhone: string;
  address: string;
  joiningDate: string;
  isTemporary: string;
  lastDutyDate: string;
  driverStatus: string;
}

export const USER_STATUS: any = [
  {
    listCode: 'DIS',
    listName: 'DISMISSED',
    listDesc: 'USER_STATUS',
  },
  {
    listCode: 'LVE',
    listName: 'ON-LEAVE',
    listDesc: 'USER_STATUS',
  },
  {
    listCode: 'NEW',
    listName: 'NEW',
    listDesc: 'USER_STATUS',
  },
  {
    listCode: 'RES',
    listName: 'RESIGNED',
    listDesc: 'USER_STATUS',
  },
  {
    listCode: 'SUS',
    listName: 'SUSPENDED',
    listDesc: 'USER_STATUS',
  },
];

export type OverlayLayer = {
  name: string;
  layer: any;
  enabled: boolean;
  icon: any;
};

// categorize local storage items so we can selectively clear storage items - need to keep storage for allowed permissions etc
export const userStorage = [
  'hasAccess',
  'currentLadder',
  'displayname',
  'access_token',
  'password',
  'refresh_token',
  'commsStatus',
  'fcmToken',
  'jaasToken',
  'jaasDomain',
  'jaasAppId',
  'sipNumber',
  'days_before_password_expiry',
  'scope',
  'username',
  'email',
  'featureList',
  'routeList',
];

export const idleStorage = ['ng2idle.main.idling', 'ng2idle.main.expiry'];

export const appStorage = ['notification_allowed', 'mic_allowed'];

export const WINDOW_NAME_MAIN = 'NGBMS_MAIN_WINDOW';
export const WINDOW_NAME_COMMS = 'NGBMS_COMMS_WINDOW';
export const WINDOW_NAME_CALL = 'NGBMS_CALL_WINDOW';
