<!-- <div class="personnel-mgnt-group">
  <div class="container">
    <h3 class="title-header">Route Management<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
     
    <div class="row">
      <div class="col pm-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div> -->
<div class="personnel-mgnt-group">
  <div class="container no-padding">
    <div class="row">
      <div class="col pm-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>