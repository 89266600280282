import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  Router,
} from '@angular/router';
import {
  HOME_PATH,
  COMMS_PATH,
  WINDOW_NAME_MAIN,
  WINDOW_NAME_COMMS,
} from '../shared/others/constant';
import { FormGroup, FormControl } from '@angular/forms';
import { VoipService } from '../shared/services/voip.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../shared/services/common.service';
import { PushNotificationService } from '../shared/services/push-notification.service';
import { FirebaseService } from '../shared/services/firebase.service';
import moment from 'moment';
import { LocalForageService } from '../shared/services/local-forage.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // @Input() data:any = null;
  // @Input() email:string = null;
  // @Input() password:string = null;
  isLoader: boolean = false;
  loginError: string = '';
  loginBg: string = '';
  isPasswordShow: boolean = true;

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  error: string;

  constructor(
    private authService: AuthService,
    private cachedRoute: RouteReuseStrategy,
    private router: Router,
    private auth: AuthService,
    private _voipService: VoipService,
    private _commonService: CommonService,
    private _firebase: FirebaseService,
    private _notification: PushNotificationService,
    private forageService: LocalForageService
  ) {}

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      const hasRedirect = false;
      if (environment.sw && hasRedirect) {
        // window.open("/comms", "comms", "popup,location=no,"+"width="+screen.availWidth+",height="+screen.availHeight);
        this._commonService.openWindow(
          HOME_PATH,
          WINDOW_NAME_COMMS,
          WINDOW_NAME_MAIN
        );
        this.router.navigate([COMMS_PATH]);
      } else {
        this.router.navigate([HOME_PATH]);
      }
      return;
    }

    this.loginBg = environment.image.login;
    console.log('init login');
    let c: any = this.cachedRoute;

    c.storedRouteHandles.forEach((v: DetachedRouteHandle, k, m) => {
      (v as any).componentRef.destroy();
    });

    c.storedRouteHandles.clear();

    // check redirect url
    let redirect = new URLSearchParams(window.location.search).get('redirect');
    if (redirect) {
      const isLogin = redirect?.includes('login');
      if (isLogin) {
        this.router.navigate([], {
          queryParams: {
            redirect: null,
          },
        });
      }
    }
  }

  onSignIn() {
    this.isLoader = true;

    this.authService.login(this.form.value).subscribe(
      data => {
        // delete firebase token
        // this._firebase.deleteToken();

        localStorage.setItem('access_token', data['access_token']);
        localStorage.setItem('email', data['email']);
        localStorage.setItem('username', this.form.value.email);

        this.isLoader = false;

        var urlAction = environment.apiServerUrl + 'users/getUserLoginStatus';
        this._commonService.commonGetFullUrlAction(urlAction).subscribe(
          account => {
            // if(account.data.isValidSession && account.data.isLogin) {

            var end = moment(account.lastActiveDateTime);
            var duration = moment.duration(end.diff(moment()));
            var seconds = Math.abs(duration.asSeconds());

            // if(account.data.isValidSession && account.data.isLogin) {
            if (account.loggedIn && seconds < environment.idleTimer) {
              // if(account.loggedIn) {
              this.loginError = 'Account is logged in';
              localStorage.clear();
            } else {
              this.validLogin(data);
              this.isLoader = true;

              // this._commonService.createAuthAcvitiy();
              // this.router.navigate([HOME_PATH]);
              // console.log('data token', data);
              const { agency } = data;
              // initialize push
              this._firebase.init(agency);
              this._notification.init();

              // this._voipService.getAvailableSipNumber().subscribe(
              //   resp => {
              //     let sipNumber = resp.sipId === '-' ? null : resp.sipId;
              //     localStorage.setItem('sipNumber', sipNumber);
              //   }
              // )
            }
          },
          error => {
            this.isLoader = false;
            console.log(error);
          }
        );
      },
      error => {
        this.isLoader = false;
        this.loginError = error;
      }
    );
  }

  validLogin(data) {
    //   // var dataBody = {
    //   //   username: localStorage.setItem('email', data['email'])

    // let dataBody = {
    //   username: this.loginForm.value.username,
    // }

    localStorage.setItem('refresh_token', data['refresh_token']);
    localStorage.setItem('scope', data['scope']);
    localStorage.setItem('displayname', data['displayname']);
    // localStorage.setItem('password', this.form.value.password); // do not store password in storage.
    localStorage.setItem(
      'days_before_password_expiry',
      data.days_before_password_expiry
    );

    const { exp } = jwt_decode<any>(data['refresh_token']);

    this.forageService.setItem('session_exp', exp);
    // this.forageService.setItem('refresh_token', data['refresh_token']);

    // this._commonService.createAuthAcvitiy(dataBody);
    // this._router.navigate(['/tracking']);

    this._commonService.createAuthAcvitiy();
    this.getFeatureMasterData(data);

    if (data.days_before_password_expiry < 7) {
      this.router.navigate(['/account/password-expiry/1']);
    } else {
      // open broadcasts
      // disable opening broadcasts if no service worker
      const hasRedirect = false;
      if (environment.sw && hasRedirect) {
        // window.open("/comms", "comms", "popup,location=no,"+"width="+screen.availWidth+",height="+screen.availHeight);
        this._commonService.openWindow(
          HOME_PATH,
          WINDOW_NAME_COMMS,
          WINDOW_NAME_MAIN,
          undefined,
          true
        );
        this.router.navigate([COMMS_PATH]);
      } else {
        let redirectUrl = new URLSearchParams(window.location.search).get(
          'redirect'
        );
        if (redirectUrl) {
          const isLogin = redirectUrl?.includes('login');
          if (isLogin) {
            this.router.navigate([HOME_PATH]);
          } else {
            this.router.navigate([redirectUrl]).catch(e => {
              this.router.navigate([HOME_PATH]);
            });
          }
        } else {
          this.router.navigate([HOME_PATH]);
        }

        // this._commonService.openWindow(COMMS_PATH, WINDOW_NAME_MAIN, WINDOW_NAME_COMMS);
      }
    }
  }

  getFeatureMasterData(data) {
    var urlAction = 'users/v1/getFeatureMasterData';
    this._commonService.commonPostAction(urlAction, '').subscribe(feature => {
      localStorage.setItem('featureList', JSON.stringify(feature.featureList));
      if (data['has_access']) {
        let hasAccess = [];
        data['has_access'].forEach(element => {
          let featureData = feature.featureList.find(x => x.id === element);
          hasAccess.push(featureData);
        });
        localStorage.setItem('hasAccess', JSON.stringify(hasAccess));

        this.auth.initMenu();
      }
    });
  }

  // }
}
