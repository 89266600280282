export class mqttData {
  public trip: string;
  public busCode: string;
  public lat: number;
  public lon: number;
  public drivenPercentage: number;
  public direction: string;
  public nextStopId: string;
  public prevStopId: string;
  public status: string;
  public isSelected: boolean;
  public degrees: string;
  public isBusBunching: boolean;
  public driverId: string;
  public blockId: string;
  public nextstopeta: string;
  public secondnextstopId: string;
  public secondnextstopeta: string;
  public thirdnextstopId: string;
  public thirdnextstopeta: string;
  constructor() {
    this.trip = '';
    this.busCode = '';
    this.lat = 0;
    this.lon = 0;
    this.drivenPercentage = 0;
    this.direction = '';
    this.nextStopId = '';
    this.prevStopId = '';
    this.status = '';
    this.isSelected = false;
    this.degrees = '';
    this.isBusBunching = false;
    this.driverId = '';
    this.blockId = '';
    this.nextstopeta = '';
    this.secondnextstopId = '';
    this.secondnextstopeta = '';
    this.thirdnextstopId = '';
    this.secondnextstopeta = '';
    this.thirdnextstopeta = '';
  }
}

export class mqttSendData {
  constructor(
    public type: string,
    public service: string,
    public busRegNo: string
  ) {}
}

export class predefinedMessageData {
  public busRegNo: string;
  public trip: string;
  // public driverId    : string;
  public routeId: string;
  public latitude: string;
  public longitude: string;
  public messageType: string;
  public messageNo: string;
  public messageText: string;
  public messageTime: string;
  public from: string;
  public isBlinkRedYellow: boolean;
  public isVisible: boolean;
  constructor() {
    this.busRegNo = '';
    this.trip = '';
    // this.driverId    = '';
    this.routeId = '';
    this.latitude = '';
    this.longitude = '';
    this.messageType = '';
    this.messageNo = '';
    this.messageText = '';
    this.messageTime = '';
    this.from = '';
    this.isBlinkRedYellow = true;
    this.isVisible = false;
  }
}

export class offRouteData {
  public service: string;
  public busRegNo: string;
  public offRouteStatus: string;
  public latitude: string;
  public longitude: string;
  public timeStamp: string;
  public driverName: string;
  constructor() {
    this.service = '';
    this.busRegNo = '';
    this.offRouteStatus = '';
    this.latitude = '';
    this.longitude = '';
    this.timeStamp = '';
    this.driverName = '';
  }
}

export class freeTextMessageData {
  public busRegNo: string;
  public tripId: string;
  public routeId: string;
  public messageText: string;
  public isBroadcast: boolean;
  public isPIDSBroadcast: boolean;
  // public messageTime : string;
  constructor() {
    this.busRegNo = '';
    this.tripId = '';
    this.routeId = '';
    this.messageText = '';
    this.isBroadcast = false;
    this.isPIDSBroadcast = false;
    // this.messageTime = '';
  }
}

export interface VehicleDeviceData {
  busRegNo: string;
  deviceId: string;
  voipId: string;
  ctryCode: string;
}

export interface ScheduleData {
  tripId: string;
  tripHeadSign: string;
  busStopName: string;
  arrivalTimeToStop: string;
  trafficMode: string;
}

export interface LogsData {
  busRegNo: string;
  routeId: string;
  tripId: string;
  latitude: number;
  longitude: number;
  timeInSeconds: number;
  msgEvent: string;
}
