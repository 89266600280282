<div class="upload-time-table-group">
  <h2 mat-dialog-title>Upload File</h2>

  <mat-dialog-content>
    <div>
      <mat-radio-group [disabled]="true" aria-label="Select an option" fxLayout="row" fxLayoutAlign="flex-start center" class="upload-file-type">
        <mat-radio-button [checked]="fileFormat === 'vdv'" value="vdv" (click)="fileFormat = 'vdv'">VDV File</mat-radio-button>
        <mat-radio-button [checked]="fileFormat === 'dat'" value="dat" (click)="fileFormat = 'dat'">Dispatch File</mat-radio-button>
        <mat-radio-button [checked]="fileFormat === 'zip'" value="zip" (click)="fileFormat = 'zip'">GTFS</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="!running" fxLayout="column" fxLayoutAlign="start center" >
      <div class="mar-top-10" *ngIf="fileFormat === 'zip'" [formGroup]="uploadForm" fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>From</mat-label>
          <input matInput [matDatepicker]="fromDate" placeholder="From" formControlName="fromDate">
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
        </mat-form-field>
        
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>To</mat-label>
          <input matInput [matDatepicker]="toDate" placeholder="To" formControlName="toDate" (dateChange)="onDateChange($event)">
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
        <mat-error *ngIf="isRangeError">Invalid range date</mat-error>
      </div>

      <div>
        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
          <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
          <div class="drag-drop">
            <div class="centered">Drag & drop files here</div>
            <!-- <div class="centered-sub">Excel format is suggested.</div> -->
          </div>
        </div>
        <mat-error *ngIf="files === null && running">No file existing</mat-error>
      </div>

      <div fxFlex="100" class="width100">
        <div fxFlex="100" class="files-list" *ngIf="files">
          <span> {{ files.name }} </span>
          <i class="material-icons delete-file" (click)="deleteAttachment(0)">
            delete_forever
          </i>
        </div>
      </div>
    </div>

    <div class="row no-margin mar-top-bot-2vh loader-group" *ngIf="running">
      <mat-spinner diameter="50" class="margin-auto"></mat-spinner>
    </div>
    
    <div class="message-group" *ngIf="isUploaded">
      <div class="row no-margin" [innerHTML]="uploadedMessage"></div>
    </div>

  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <button mat-stroked-button  mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="btnUploadFile()" 
        [disabled]="running || this.files === null || isRangeError">Upload</button>
  </mat-dialog-actions>
</div>