import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable()
export class PmService {
    constructor(private httpClient: HttpClient
    ){}
    
    getAllDriver() {
        const promise = new Promise(
            (resolve, reject) => {
                this.httpClient.post(environment.apiServerUrl + 'drivers/listAllDrivers', null)
                .subscribe(
                    (data:any) => {
                        resolve(data);
                    },
                    err => console.log(err),
                    () => console.log('done get all driver')
                );

            }
        );
        return promise;
      //   const promise = new Promise(
      //     (resolve, reject) => {
      //       const drivers = [
      //         {
      //           "address": "Singapore, Singapore",
      //           "driverId": "1",
      //           "driverStatus": "new",
      //           "firstName": "Justin",
      //           "identificationDoc": "doc 1",
      //           "identificationId": "id 1",
      //           "isTemporary": "false",
      //           "joiningDate": "2019-05-08T14:05:15.953Z",
      //           "lastDutyDate": "2019-05-09T14:15:25.123Z",
      //           "lastName": "West",
      //           "middleName": "East",
      //           "primaryPhone": "123",
      //           "recId": '1',
      //           "secondaryPhone": "8901234",
      //           'fullName' : 'Justin West'
      //         },
      //         {
      //           "address": "Singapore, Singapore",
      //           "driverId": "1",
      //           "driverStatus": "new",
      //           "firstName": "Justin",
      //           "identificationDoc": "doc 1",
      //           "identificationId": "id 1",
      //           "isTemporary": "false",
      //           "joiningDate": "2019-05-08T14:05:15.953Z",
      //           "lastDutyDate": "2019-05-09T14:15:25.123Z",
      //           "lastName": "West",
      //           "middleName": "East",
      //           "primaryPhone": "123",
      //           "recId": '1',
      //           "secondaryPhone": "8901234",
      //           'fullName' : 'Justin West'
      //         }
      //       ]
      //       resolve(drivers);
      //     }
      // );
      // return promise;
    }
    
    setDriver(driverData) {
      const promise = new Promise(
          (resolve, reject) => {
            this.httpClient.post(environment.apiServerUrl + 'drivers/addDrivers', driverData, {responseType: 'text'})
            .subscribe(
              (data:any) => {
                      
              resolve(data);
            },
            err => console.log(err),
            () => console.log('done set driver')
          );
        }
      );
      return promise;
    }
}