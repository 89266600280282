import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataManagementService {
  public gtfsData:any;

  constructor(private httpClient: HttpClient
  ) {
  }

  // addGtfsData(data) {
  //   this.gtfsData = data;
  // }

  getAllStops(page) {
    const routeList = localStorage.getItem('routeList').split(",");
    let bodyJson = {
      "index": page,
      "routes" : ['3']
    }
    const promise = new Promise(
      (resolve, reject) => {

          this.httpClient.post(environment.apiServerUrl + 'gtfsupload/busfleetdetails/getPagedTransitDataSet/', bodyJson)
          // this.httpClient.post(this.configService.apiServerUrl + 'gtfsupload/stops/listAllByStops/', null)
          .subscribe(
              // (data:any) => {
              //   console.log(data);
              // }
              (data:any) => {
                  resolve(data);
              },
              err => console.log(err),
              () => console.log('done get all stops')
          )
      }
    );
    
    return promise;
  }

  getFindStopsByRoute(stops, page = 1) {
    // const routeList = localStorage.getItem('routeList').split(",");
    let bodyJson = {
      "index": page,
      "routes" : [stops]
    }
    const promise = new Promise(
      (resolve, reject) => {

          this.httpClient.post(environment.apiServerUrl + 'gtfsupload/busfleetdetails/getPagedTransitDataSet/', bodyJson)
          // this.httpClient.post(this.configService.apiServerUrl + 'gtfsupload/stops/listAllByStops/', null)
          .subscribe(
              // (data:any) => {
              //   console.log(data);
              // }
              (data:any) => {
                  resolve(data);
              },
              err => console.log(err),
              () => console.log('done find stops')
          )
      }
    );
    
    return promise;
  }

  getTripByRoutes(page) {
    // const routeList = localStorage.getItem('routeList').split(",");
    let bodyJson = {
      "index": 1,
      "routes" : ['3']
    }
    const promise = new Promise(
      (resolve, reject) => {

          this.httpClient.post(environment.apiServerUrl + 'gtfsupload/busfleetdetails/getPagedTransitDataSet/', bodyJson)
          .subscribe(
              (data:any) => {
                let routes:any = [];
                let routesTrips:any = {};

                for (const trip of data) {
                  routes[trip.routeId] = routes[trip.routeId] ? routes[trip.routeId] : [];
                  routes[trip.routeId].push(trip);
                }
                
                for (const route in routes) {
                  if(route) {
                    let trips = [];
                    for (const row of routes[route]) {
                      trips[row.direction] = trips[row.direction] ? trips[row.direction] : [];
                      trips[row.direction].push(row);
                    }
                    routesTrips[route] = trips;
                  }
                }
                resolve(routesTrips);
              },
              err => console.log(err),
              () => console.log('done get all trips')
          )
      }
    );
    
    return promise;
  }
}