<app-demo-sidebar></app-demo-sidebar>
<div class="map-container">
  <div id="map" class="map">
    <div
      id="info"
      class="mat-elevation-z1"
      *ngIf="showInfo"
      [style.left.px]="infoCoordinates?.x"
      [style.top.px]="infoCoordinates?.y">
      {{ infoData }}
    </div>
    <app-panel-layer-control
      position="top-right"
      [selectedBasemap]="selectedBasemap"
      (onClickMapTypeEvent)="applyMapStyle($event)"
      [listOverlayLayers]="listOverlayLayers"
      (onClickOverlayEvent)="toggleOverlayLayer($event)">
    </app-panel-layer-control>
  </div>

  <div class="bus-details" fxLayout="row" fxLayoutAlign="flex-start center">
    <div *ngFor="let busDetails of busDetailsList; index as i" @quickFade>
      <app-mock-bus-details
        [busInputData]="busDetails"
        [busKey]="index"
        (closeDetails)="removeBusComponent($event)"></app-mock-bus-details>
    </div>
  </div>

  <div class="bus-routes mat-elevation-z4">
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <mat-label>Route</mat-label>
      <mat-select
        [(value)]="selectedRoute"
        (selectionChange)="onSelectRoute($event)">
        <mat-option *ngFor="let route of routes" [value]="route.id">
          {{ route.route }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div
    class="ladder-container mat-elevation-z4"
    [@fadeInOut]
    *ngIf="showLadder">
    <div class="ladder">
      <div class="container-row">
        <div class="col route-buses">
          <div class="row row-between">
            <div
              class="icon-bus-a bus-a-desc"
              (click)="openAllRoutes(1)"
              (dblclick)="closeAllRoutes(1)">
              {{ ladders.from.location }}
              <i
                class="fa fa-arrow-right gray"
                *ngIf="
                  orientation === 'bottom' ||
                  orientation === 'middle' ||
                  orientation === 'top'
                "></i>

              <i
                class="fa fa-arrow-down gray"
                *ngIf="orientation === 'right'"></i>
            </div>
            <div
              class="col route bus-a-route"
              [ngClass]="{ 'route-loop': !isBirectional }"
              *ngIf="
                orientation === 'bottom' ||
                orientation === 'middle' ||
                orientation === 'top'
              ">
              <ng-container *ngFor="let direction of ladders.direction">
                <ng-container *ngIf="direction.value === '1'">
                  <div
                    class="ladderStopToolTip"
                    *ngFor="
                      let ladder of direction.stops;
                      let isLast = last;
                      let isEven = even;
                      let index = index
                    "
                    [id]="
                      'dir' + direction.value + '-stop-marker' + ladder.stopCode
                    "
                    [ngStyle]="{
                      width: !isLast ? direction.routeWidth + '%' : 0
                    }">
                    <!-- start vehicle by stop -->
                    <div class="buses-a" *ngIf="busMqtt?.length > 0">
                      <ng-container *ngFor="let bus of busMqtt">
                        <ng-container
                          *ngIf="
                            bus.direction === '1' &&
                            bus.prevStopId === ladder.stopCode
                          ">
                          <div class="bus">
                            <!-- not last stop -->
                            <div
                              class="stop-marker"
                              [style.left.%]="
                                bus.prevStopId === bus.nextStopId
                                  ? 0
                                  : bus.drivenPercentage
                              "
                              *ngIf="
                                (direction.stops[index + 1] !== undefined &&
                                  bus.nextStopId ===
                                    direction.stops[index + 1].stopCode) ||
                                (bus.prevStopId === bus.nextStopId &&
                                  ladder.stopSequence === '1')
                              ">
                              <i
                                [ngClass]="{
                                  statusGreen: bus.status === '0',
                                  statusYellow: bus.status === '-1',
                                  statusRed: bus.status === '1',
                                  statusGray: bus.status === '2',
                                  statusOrange: bus.status === '3'
                                }"
                                class="fa fa-caret-down bus-bunched1 bus-data"
                                (click)="
                                  onBusAction(
                                    $event,
                                    bus,
                                    1,
                                    orientation,
                                    bus.direction
                                  )
                                ">
                                <div
                                  class="ladder-bus-name"
                                  [ngClass]="{
                                    'bus-selected': false,
                                    bgGreen: bus.status === '0',
                                    'bgYellow textBlack': bus.status === '-1',
                                    bgRed: bus.status === '1',
                                    bgGray: bus.status === '2',
                                    bgOrange: bus.status === '3'
                                  }">
                                  {{ bus.busCode }}
                                </div>
                              </i>
                            </div>

                            <!-- last stop -->
                            <div
                              class="stop-marker"
                              [style.left.%]="bus.drivenPercentage"
                              *ngIf="
                                direction.stops[index + 1] === undefined &&
                                bus.nextStopId === ''
                              ">
                              <i
                                [ngClass]="{
                                  statusGreen: bus.status === '0',
                                  statusYellow: bus.status === '-1',
                                  statusRed: bus.status === '1',
                                  statusGray: bus.status === '2',
                                  statusOrange: bus.status === '3'
                                }"
                                class="fa fa-caret-down bus-bunched1 bus-data"
                                (click)="
                                  onBusAction(
                                    $event,
                                    bus,
                                    1,
                                    orientation,
                                    bus.direction
                                  )
                                ">
                                <div
                                  class="ladder-bus-name"
                                  [ngClass]="{
                                    'bus-selected': false,
                                    bgGreen: bus.status === '0',
                                    'bgYellow textBlack': bus.status === '-1',
                                    bgRed: bus.status === '1',
                                    bgGray: bus.status === '2',
                                    bgOrange: bus.status === '3'
                                  }"
                                  (mouseenter)="zoomInToBus(bus)"
                                  (mouseleave)="cancelZoomInToBus()">
                                  {{ bus.busCode }}
                                </div>
                              </i>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                    <!-- end vehicle by stop -->

                    <i
                      [ngClass]="{
                        isEven: isEven,
                        'featured-stop': ladder.isMajorStop !== 'true'
                      }"
                      class="fa fa-circle gray"
                      (click)="
                        onStopDetails(ladder, orientation, direction.value)
                      "
                      (mouseenter)="highlightBusStopOnLadderAndMap(ladder)"
                      (mouseleave)="unhighlightBusStopOnLadderAndMap(ladder)">
                      <div class="stop-code">{{ ladder.stopCode }}</div>
                    </i>
                    <div class="route-line" *ngIf="!isLast"></div>
                    <span
                      class="ladderStopToolTipText direction1 mat-elevation-z2"
                      [ngClass]="{
                        ladderStopToolTipVisible: ladder.highlight === true
                      }">
                      {{ ladder.stopName }}
                    </span>
                  </div>

                  <div class="bus-not-found" *ngIf="busMqtt.length < 1">
                    No vehicle on road
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div class="">
              <div class="bus-b-desc visibility-hidden" *ngIf="ladders.to">
                <i
                  class="fa fa-arrow-left gray padding-b-icon"
                  *ngIf="
                    orientation === 'bottom' ||
                    orientation === 'middle' ||
                    orientation === 'top'
                  "></i>
                <i
                  class="fa fa-arrow-up gray"
                  *ngIf="orientation === 'right'"></i>
                {{ ladders.to.location }}
              </div>
            </div>
          </div>

          <div class="row row-between">
            <div class="icon-bus-a visibility-hidden bus-a-desc">
              {{ ladders.from.location }}
              <i
                class="fa fa-arrow-right gray"
                *ngIf="
                  orientation === 'bottom' ||
                  orientation === 'middle' ||
                  orientation === 'top'
                "></i>
            </div>
            <div
              class="col route bus-b-route"
              *ngIf="
                orientation === 'bottom' ||
                orientation === 'middle' ||
                orientation === 'top'
              ">
              <ng-container *ngFor="let direction of ladders.direction">
                <ng-container *ngIf="direction.value === '2'">
                  <div
                    class="ladderStopToolTip"
                    *ngFor="
                      let ladder of direction.stops.slice().reverse();
                      let isFirst = first;
                      let isLast = last;
                      let isEven = even;
                      let index = index
                    "
                    [id]="
                      'dir' + direction.value + '-stop-marker' + ladder.stopCode
                    "
                    [ngStyle]="{
                      width: !isLast ? direction.routeWidth + '%' : 0
                    }">
                    <!-- start vehicle by stop -->
                    <div class="buses-b" *ngIf="busMqtt?.length > 0">
                      <div
                        class="bus"
                        *ngFor="let bus of busMqtt"
                        [id]="'bus-' + bus.direction + '-' + bus.busCode">
                        <ng-container
                          *ngIf="
                            bus.direction === '2' &&
                            bus.nextStopId === ladder.stopCode
                          ">
                          <div
                            class="stop-marker"
                            [style.right.%]="
                              bus.prevStopId === bus.nextStopId
                                ? 0
                                : bus.drivenPercentage
                            ">
                            <!-- <i class="fa fa-caret-right statusRed bus-data"></i> -->
                            <i
                              [ngClass]="{
                                statusGreen: bus.status === '0',
                                statusYellow: bus.status === '-1',
                                statusRed: bus.status === '1',
                                statusGray: bus.status === '2',
                                statusOrange: bus.status === '3'
                              }"
                              class="fa fa-caret-down bus-bunched1 bus-data"
                              (click)="
                                onBusAction(
                                  $event,
                                  bus,
                                  1,
                                  orientation,
                                  bus.value
                                )
                              ">
                              <div
                                class="ladder-bus-name direction-b"
                                [ngClass]="{
                                  'bus-selected': false,
                                  bgGreen: bus.status === '0',
                                  'bgYellow textBlack': bus.status === '-1',
                                  bgRed: bus.status === '1',
                                  bgGray: bus.status === '2',
                                  bgOrange: bus.status === '3'
                                }">
                                {{ bus.busCode }}
                              </div>
                            </i>
                          </div>

                          <!-- last stop -->
                          <div
                            class="stop-marker"
                            [style.right.%]="bus.drivenPercentage"
                            *ngIf="
                              direction.stops[index + 1] === undefined &&
                              bus.nextStopId === ''
                            ">
                            <!-- <i class="fa fa-caret-right statusRed bus-data"></i> -->
                            <i
                              [ngClass]="{
                                statusGreen: bus.status === '0',
                                statusYellow: bus.status === '-1',
                                statusRed: bus.status === '1',
                                statusGray: bus.status === '2',
                                statusOrange: bus.status === '3'
                              }"
                              class="fa fa-caret-down bus-bunched1 bus-data"
                              (click)="
                                onBusAction(
                                  $event,
                                  bus,
                                  1,
                                  orientation,
                                  bus.direction
                                )
                              ">
                              <div
                                class="ladder-bus-name direction-b"
                                [ngClass]="{
                                  'bus-selected': false,
                                  bgGreen: bus.status === '0',
                                  'bgYellow textBlack': bus.status === '-1',
                                  bgRed: bus.status === '1',
                                  bgGray: bus.status === '2',
                                  bgOrange: bus.status === '3'
                                }">
                                {{ bus.busCode }}
                              </div>
                            </i>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <!-- end vehicle by stop -->

                    <i
                      [ngClass]="{
                        isEven: isEven,
                        'featured-stop': ladder.isMajorStop !== 'true'
                      }"
                      class="fa fa-circle gray"
                      (click)="
                        onStopDetails(ladder, orientation, direction.value)
                      "
                      (mouseenter)="highlightBusStopOnLadderAndMap(ladder)"
                      (mouseleave)="unhighlightBusStopOnLadderAndMap(ladder)"
                      ><div class="stop-code stop-direction2">
                        {{ ladder.stopCode }}
                      </div></i
                    >
                    <div class="route-line" *ngIf="!isLast"></div>
                    <span
                      class="ladderStopToolTipText direction2 mat-elevation-z2"
                      [ngClass]="{
                        ladderStopToolTipVisible: ladder.highlight === true
                      }">
                      {{ ladder.stopName }}
                    </span>
                  </div>

                  <div class="bus-not-found" *ngIf="busMqtt.length < 1">
                    No vehicle on road
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div class="col icon-bus-b">
              <div
                class="bus-b-desc"
                *ngIf="ladders.to"
                (click)="openAllRoutes(2)"
                (dblclick)="closeAllRoutes(2)">
                <i
                  class="fa fa-arrow-left gray padding-b-icon"
                  *ngIf="
                    orientation === 'bottom' ||
                    orientation === 'middle' ||
                    orientation === 'top'
                  "></i>
                <i
                  class="fa fa-arrow-up gray"
                  *ngIf="orientation === 'right'"></i>
                {{ ladders.to.location }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
