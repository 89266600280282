import { Injectable } from '@angular/core';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import moment from 'moment';

import {
  availableBlockIds,
  createTimeTables,
} from '../demo-utils/demo-timetable';
import {
  compareDates,
  getNearestTimeSlot,
  isStartTimeAfterCurrentTime,
  isWithinTime,
  timeStringToMinutes,
} from '../demo-utils/demo-timer';
import { CommonService } from 'src/app/component/shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class DemoBusStopTimingService {
  stopAreaLayer: VectorImageLayer<any>;
  timetable;
  blockIds;
  constructor(private commonService: CommonService) {
    this.timetable = createTimeTables();
    this.blockIds = availableBlockIds;
    console.log('demo trip timetable: ', this.timetable, this.blockIds);
  }

  init() {
    const currTime = moment();
    const currTimeSlot = getNearestTimeSlot(currTime, 15);

    const upcomingTrip = this.findTrip(currTimeSlot);
    console.log('time', currTime, currTimeSlot, upcomingTrip);
  }

  findTrip(timeslot, route = '7002', direction = 1, nthStop = 0) {
    const currTimetable = this.timetable.find(item => item.routeId === route);

    if (currTimetable) {
      const { timetable } = currTimetable;
      const currDirection = timetable.find(
        item => item.direction === `${direction}`
      );
      if (!currDirection) return undefined;
      const { trips } = currDirection;

      const tripObj = trips.find(trip => {
        const { stops } = trip;
        const tripStart = stops[nthStop];
        if (tripStart?.schArrTime === timeslot) return true;
        return false;
      });
      return tripObj;
    }
    return undefined;
  }

  findTripWithTimeslot(timeslot, route = '7002', direction = 1) {
    const currTimetable = this.timetable.find(
      item => item.routeId === `${route}`
    );
    // console.log('findTripWithTimeslot', { timeslot, route, direction });
    if (currTimetable) {
      const { timetable } = currTimetable;
      const currDirection = timetable.find(
        item => item.direction === `${direction}`
      );
      // console.log(
      //   'findTripWithTimeslot timetable',
      //   direction,
      //   timetable,
      //   currDirection
      // );
      if (!currDirection) return undefined;
      const { trips } = currDirection;
      // console.log('findTripWithTimeslot trips', direction, timeslot, trips);
      const tripObj = trips.find(trip => {
        const { stops } = trip;
        const hasTrip =
          stops.some(
            (stop, index) =>
              timeslot?.includes(stop?.schArrTime) && index < stops.length - 1
          ) ?? false;
        return hasTrip;
      });
      return tripObj;
    }
    return undefined;
  }

  // findNearest Trips based on blockIds
  getNextBlockTrips(routeId, numTrips, blockTimeDiff = 15, timeStop = 5) {
    const currTimetable = this.timetable.find(item => item.routeId === routeId);
    const currBlockIds = this.blockIds[routeId];
    const newTimeCurrent = moment();
    const timeSlot = getNearestTimeSlot(newTimeCurrent, timeStop);
    const nearestTrip = this.findTripWithTimeslot(timeSlot, routeId);
    const nearestTrip2 = this.findTripWithTimeslot(timeSlot, routeId, 2);
    console.log('init trip 1: ', timeSlot, nearestTrip, nearestTrip2);
    const {
      blockId: blockId1,
      tripId: tripId1,
      direction: direction1,
    } = nearestTrip;
    const {
      blockId: blockId2,
      tripId: tripId2,
      direction: direction2,
    } = nearestTrip2;
    const blockIndex1 = currBlockIds.findIndex(block => block === blockId1);
    const blockIndex2 = currBlockIds.findIndex(block => block === blockId2);
    // console.log('init trip block ids', {
    //   currTimetable,
    //   currBlockIds,
    //   numTrips,
    //   blockId1,
    //   blockIndex1,
    //   blockId2,
    //   blockIndex2,
    // });
    const assignedTrips = [];
    const { timetable } = currTimetable;
    // console.log('init trip timetable', timetable);
    let currDir = direction1;
    let currDirectionTimeTable = timetable.find(
      item => item.direction === `${currDir}`
    );
    let currTripIdIndex = currDirectionTimeTable.trips.findIndex(
      trip => trip.tripId === tripId1
    );

    for (
      let i = currTripIdIndex;
      i < currDirectionTimeTable.trips.length - 1;
      i++
    ) {
      if (assignedTrips.length >= Math.ceil(numTrips / 2)) {
        break;
      }
      const newTrip = currDirectionTimeTable.trips[i];
      // console.log(
      //   'init trip new',
      //   i,
      //   currDirectionTimeTable.trips.length,
      //   newTrip,
      //   assignedTrips
      // );
      const isBlockIdAssigned = assignedTrips.some(
        trip => trip.blockId === newTrip.blockId
      );
      if (!isBlockIdAssigned) {
        assignedTrips.push(newTrip);
      }
    }
    currDir = 2;
    currDirectionTimeTable = timetable.find(
      item => item.direction === `${currDir}`
    );
    currTripIdIndex = currDirectionTimeTable.trips.findIndex(
      trip => trip.tripId === tripId2
    );
    for (
      let i = currTripIdIndex;
      i < currDirectionTimeTable.trips.length - 1;
      i++
    ) {
      if (assignedTrips.length >= numTrips) {
        break;
      }
      const newTrip = currDirectionTimeTable.trips[i];
      const isBlockIdAssigned = assignedTrips.some(
        trip => trip.blockId === newTrip.blockId
      );
      if (!isBlockIdAssigned) {
        assignedTrips.push(newTrip);
      }
    }
    console.log(
      'init trip initial trips',
      numTrips,
      assignedTrips,
      currBlockIds
    );
    return assignedTrips;
  }

  getBlockTrips(routeId, blockTimeDiff = 15, timeStop = 5, limitTrips = false) {
    const currTimetable = this.timetable.find(item => item.routeId === routeId);
    let currBlockIds = this.blockIds[routeId];
    const newTimeCurrent = moment();
    const timeSlot = getNearestTimeSlot(newTimeCurrent, timeStop);
    const timeSlot2 = getNearestTimeSlot(newTimeCurrent, blockTimeDiff);
    const { timetable: timeTable, numBus } = currTimetable;

    // console.log('zxcy', currTimetable);

    const flattenedTimeTableTrips = timeTable.reduce((prev, curr) => {
      const { trips } = curr;
      return [...prev, ...trips];
    }, []);

    const sortedFlatTimeTableTrips = flattenedTimeTableTrips.sort((a, b) =>
      compareDates(a, b)
    );

    if (limitTrips) {
      currBlockIds = currBlockIds.slice(0, numBus);
    }

    const blockTrips = currBlockIds.map(blockId => {
      const filteredTrips = sortedFlatTimeTableTrips.filter(
        trip => trip.blockId === blockId
      );

      // get trip for timeslot
      const tripObj = filteredTrips.find(trip => {
        const { stops } = trip;
        const hasTrip =
          stops.some((stop, index) => {
            if (
              timeSlot?.includes(stop?.schArrTime) ||
              timeSlot2?.includes(stop?.schArrTime)
            )
              return true;

            const isStartAfterCurr = isStartTimeAfterCurrentTime(
              stop.schArrTime,
              timeSlot
            );

            const isStartAfterCurr2 = isStartTimeAfterCurrentTime(
              stop.schArrTime,
              timeSlot2
            );
            return isStartAfterCurr || isStartAfterCurr2 || false;
          }) ?? false;

        const firstStop = stops[0];
        const lastStop = stops[stops.length - 1];
        const withinTime = isWithinTime(
          firstStop.schArrTime,
          lastStop.schArrTime,
          timeSlot
        );
        const withinTime2 = isWithinTime(
          firstStop.schArrTime,
          lastStop.schArrTime,
          timeSlot2
        );

        // check trip start if same
        const isStartAfterCurr = isStartTimeAfterCurrentTime(
          firstStop.schArrTime,
          timeSlot2
        );

        return hasTrip || withinTime || withinTime2 || isStartAfterCurr;
      });

      return tripObj;
    });

    console.log(
      'blockTrips',
      routeId,
      {
        flattenedTimeTableTrips,

        currBlockIds,
        blockTrips,
        newTimeCurrent,
        timeSlot,
        timeSlot2,
      },
      blockTrips
    );

    return blockTrips;
  }

  getPastNumTrips(timeslot, route, numTrips) {
    if (numTrips > 3) {
      const firstTrips = this.getNTrips(timeslot, route, 3, 1);
      const nextTrips = this.getNTrips(timeslot, route, numTrips - 3, 2);
      const newTrips = [...firstTrips, ...nextTrips];
      return newTrips;
    }
    const genTrips = this.getNTrips(timeslot, route, numTrips, 1);
    return [...genTrips];
  }

  getNTrips(timeslot, route, numTrips, direction = 1) {
    const firstTrip = this.findTrip(timeslot, route, direction);
    const { tripId } = firstTrip;
    const currTimetable = this.timetable.find(item => item.routeId === route);
    const { timetable } = currTimetable;
    const currDirection = timetable.find(
      item => item.direction === `${direction}`
    );
    if (!currDirection) return [];
    const { trips } = currDirection;
    let tripIndex = trips.findIndex(trip => trip.tripId === tripId);
    if (tripIndex > -1) {
      tripIndex += 1;
      const selectedTrips = trips.slice(tripIndex - 2, tripIndex + 1);
      return selectedTrips;
    }
    return [];
  }

  initStopLayer(busService) {
    let busStopLayer = new VectorImageLayer({
      source: new VectorSource({
        features: [],
      }),
      className: 'BusStopArea-' + busService,
      properties: {
        name: 'BusStopArea-' + busService,
      },
    });
  }

  getTimeTableByBlock(routeId, blockId, tripId) {
    const currTimeTable = this.timetable.find(data => data.routeId === routeId);
    // console.log(
    //   'init trip timetable block',
    //   this.timetable,
    //   routeId,
    //   currTimeTable
    // );
    // console.log('init trip timetable gettimetableblock', tripId);
    let tripIndex = -1;
    let tripDirection;
    const { stopList, timetable } = currTimeTable;
    const filteredTimeTable = timetable.map(dirTable => {
      const { direction, trips } = dirTable;
      let filteredTrips = trips.filter((trip, index) => {
        const { tripId: currTripId } = trip;
        if (tripId === currTripId) {
          // tripIndex = index;
          tripDirection = direction;
        }
        return trip.blockId === blockId;
      });
      // console.log('init trip timetable trips', routeId, filteredTrips);
      // const tempIndex = filteredTrips.findIndex((trip, index) => {
      //   if (tripId === trip.tripId) {
      //     tripIndex = index;
      //     tripDirection = direction;
      //   }
      // });
      // if (tempIndex > -1) {
      //   tripIndex = tempIndex;
      // }
      return { direction, trips: filteredTrips };
    });

    const flattenedTable = filteredTimeTable.reduce((prev, acc) => {
      const { trips } = acc;
      return [...prev, ...trips];
    }, []);

    const sortedFlatTable = flattenedTable.sort((a, b) => {
      const minA = timeStringToMinutes(a.stops[0].schArrTime);
      const minB = timeStringToMinutes(b.stops[0].schArrTime);
      return minA - minB;
    });

    tripIndex = sortedFlatTable.findIndex((trip, index) => {
      if (trip.tripId === tripId) {
        tripDirection = trip.direction;
        return true;
      }
      return false;
    });

    console.log(
      'new timetable',
      filteredTimeTable,
      flattenedTable,
      sortedFlatTable
    );

    return {
      currentTripData: { index: tripIndex, direction: tripDirection },
      timeTableData: {
        routeId,
        stopList,
        timetable: filteredTimeTable,
        flatTimeTable: sortedFlatTable,
      },
    };
  }

  getNextTripByTimeslot(timeslot, tripTable) {}

  unload() {
    this.stopAreaLayer = undefined;
  }

  timetableArrival({
    routeId,
    direction,
    tripId,
    stopId,
    obsArrTime,
    busRegNo,
  }) {
    try {
      const currTimetable = this.timetable.find(
        timetable => timetable.routeId === `${routeId}`
      );
      const { timetable } = currTimetable;
      const timetableDir = timetable.find(
        ttable => ttable.direction === `${direction}`
      );
      const { trips } = timetableDir;
      console.log({ timetableDir });
      const currTrip = trips.find(item => item.tripId === tripId);
      const { stops } = currTrip;
      const currStop = stops.find(stop => stop.stopId === stopId);
      if (currStop) {
        // const formatPlanTime = this.commonService.formatPlanTime(currStop);

        // currStop.schArrTime = formatPlanTime;
        currStop.obsArrTime = obsArrTime;

        const formatActualTime = this.commonService.formatActualTime(currStop);
        const { obsArrTime: formatObsArrTime, statusColor } = formatActualTime;

        currStop.statusColor = statusColor;

        const retHeadway = this.commonService.formatHeadwayTime(currStop);
        currStop.headwayStatusColor = retHeadway.headwayStatusColor;
        currStop.planHeadway = '15min';
        currStop.actualHeadway =
          currStop.observedHeadway === 'null' ||
          currStop.observedHeadway === null ||
          currStop.observedHeadway === '0' ||
          currStop.observedHeadway === ''
            ? '-'
            : Math.round(currStop.observedHeadway / 60) + 'min';
        currStop.deviationHeadway =
          currStop.headwayDeviation === 'null' ||
          currStop.headwayDeviation === null ||
          currStop.headwayDeviation === '0' ||
          currStop.headwayDeviation === ''
            ? '-'
            : Math.round(currStop.headwayDeviation / 60) + 'min';

        currStop.busRegNo = busRegNo;
      }
    } catch (e) {
      console.log('timetable arrival error', e);
    }
  }
}
