import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MatTableDataSource, MatPaginator, MatTable, MatSort } from '@angular/material';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/component/shared/services/map.service';
import { environment } from 'src/environments/environment';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

export interface BusEventElement {
  schedTime: string
  stopCode: string;
  status: string;
  deviation: string;
  manual: string;
  eventCode: string;
}

@Component({
  selector: 'app-bus-event-report',
  templateUrl: './bus-event-report.component.html',
  styleUrls: ['./bus-event-report.component.scss']
})
export class BusEventReportComponent implements OnInit {

  isNewWindow: boolean = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private homeService: HomeService, private httpClient: HttpClient, private _snackBar: MatSnackBar, 
    private router: Router, private mapService: MapService, private route: ActivatedRoute) { 
  }

  rptForm = new FormGroup({
    route: new FormControl(null, [Validators.required]),
    startDate: new FormControl(null, [Validators.required]),
    endDate: new FormControl(null, [Validators.required])
  });

  allRoutes = [];
  filteredRoutes: string[] = [];

  displayedColumns: string[] = ['tripId', 'busRegNo', 'stopEnterTime', 'stopName', 'status', 'dwellTime', 'deviation'];

  dataSource = new MatTableDataSource<BusEventElement>();

  running: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  
  ngOnInit() {
    
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }

    this.mapService.getInitSwitch().then(
      (data:any) => {
        for (const routeType in data) {
          for (const route of data[routeType]) {
            this.allRoutes.push({"routeName":route.routeName, "routeId":route.service});
          }
        }
        this.rptForm.controls['route'].setValidators(null);
        this.rptForm.controls['route'].updateValueAndValidity();
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        // this.homeService.responsibilities['primary'].forEach(e => {
        //   this.allRoutes.push(e.routeName);
        // });

        // this.filteredRoutes = this.filterRoutes('');

        // this.rptForm.get('route').valueChanges.subscribe(newValue=>{
        //   this.filteredRoutes = this.filterRoutes(newValue);
        // });
      }
    );
  }

  filterRoutes(search: string) {
    return this.allRoutes.filter(value => value.toLowerCase().indexOf(search.toLowerCase()) === 0);
  }

  run() {
    this.exportStarted('Generating report...');

    let url = environment.apiServerUrl + 'performance/getScheduleDeviation';
    let body = {
      routeId: this.rptForm.value.route,
      startDate: moment(this.rptForm.value.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.rptForm.value.endDate).format('YYYY-MM-DD')
    };

    this.httpClient.post(url, body).pipe(
      map((data: any) => {
        if (data.length == 0) {
          throw new Error('No record found');
        } else {
          return data;
        }
      })
    )
      .subscribe(
        (data: any) => {
          let t = [];
          if(data instanceof Array) {
            data.forEach(e => {
              // for (const e of trip) {
                
                let statusName:any = '-';
                if(e.schDeviationAtStopEnter === 0) {
                  statusName = 'On Time';
                }
                else if(e.schDeviationAtStopEnter < 0) {
                  statusName = 'Early';
                }
                else if(e.schDeviationAtStopEnter !== undefined){
                  statusName = 'Late';
                }
                let dev = Math.abs(e.schDeviationAtStopEnter);
                let formattedDeviation = moment.utc(moment.duration(dev, "seconds").asMilliseconds()).format("HH:mm:ss");
                
                t.push({
                  tripId: e.tripId,
                  busRegNo: e.busRegNo,
                  // schedTime: moment(e.schedTime).format('DD/MM/YYYY HH:mm:ss'),
                  stopEnterTime: moment(e.stopEnterTime).format('DD/MM/YYYY HH:mm:ss'),
                  stopName: e.stopName === '' ? '-' : e.stopName,
                  status: statusName,
                  dwellTime: Number.isInteger(e.dwellTime) ? moment.utc(moment.duration(e.dwellTime, "seconds").asMilliseconds()).format("HH:mm:ss") : '-',
                  // status: e.status === null ? null : e.status === '-1' ? 'Late' : e.status === '0' ? 'On time' : e.status === '1' ? 'Ahead' : 'Unknown',
                  deviation: Number.isInteger(e.schDeviationAtStopEnter) ? formattedDeviation : '-',
                  // manual: e.isManual === null ? null : e.isManual === 1 ? 'Yes' : 'No',
                  // eventCode: e.eventCode
                });
              // }
            });
            
            this.dataSource.data = t;
            this.table.renderRows();
            
            this.exportCompleted();
          }
          else {
            this._snackBar.open(data.Response, null, {
              duration: 2000,
            });
            this.running = false;
          }
        },
        (error: any) => {
          this._snackBar.open(error.statusText, null, {
            duration: 2000,
          });
          this.running = false;
        }
      );
  }

  exportStarted(message: string) {
    this.running = true;

    this._snackBar.open(message);
  }

  exportCompleted() {
    this._snackBar.dismiss();

    this.running = false;
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/reports/schedule/2','reports','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}
