<div class="schedule-group">
  <div>
    <h3 class="title-header">Schedule<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div>

  <div class="criteria-row">
    <form [formGroup]="rptForm" (ngSubmit)="run()">
      <!-- <mat-form-field style="width:400px !important">
        <input type="text" placeholder="Route" aria-label="Route" matInput formControlName="route"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let n of filteredRoutes" [value]="n">
            {{n}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="rptForm.get('route').invalid">Invalid route</mat-error>
      </mat-form-field> -->
      
      <!-- <mat-form-field floatLabel="always">
        <mat-label class="select-label">Select route</mat-label>
        <mat-select formControlName="route">
          <mat-option *ngFor="let n of allRoutes" [value]="n.routeId">
            {{n.routeName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="rptForm.get('route').invalid">Invalid route</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always">
        <input matInput [matDatepicker]="startDate" placeholder="Start Date" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error *ngIf="rptForm.get('startDate').invalid">Invalid start date</mat-error>
      </mat-form-field> -->

      <mat-form-field floatLabel="always">
        <input matInput [matDatepicker]="schedDate" placeholder="Schedule Date" formControlName="schedDate">
        <mat-datepicker-toggle matSuffix [for]="schedDate"></mat-datepicker-toggle>
        <mat-datepicker #schedDate></mat-datepicker>
        <mat-error *ngIf="rptForm.get('schedDate').invalid">Invalid schedule date</mat-error>
      </mat-form-field>

      <button mat-raised-button type="submit" [disabled]="running || !rptForm.valid">Run</button>
      <button mat-raised-button type="button" (click)="uploadFile()" class="float-right">Upload</button>
      <!-- <button type="button" mat-raised-button (click)="exporter.exportTable('xlsx')"  [disabled]="running">Export</button> -->
    </form>
  </div>

  <div class="table-wrapper">
    <!-- <table mat-table matTableExporter [dataSource]="dataSource" matSort #exporter="matTableExporter" (exportStarted)="exportStarted('Exporting...')" (exportCompleted)="exportCompleted()">

      <ng-container matColumnDef="tripId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trip ID</th>
        <td mat-cell *matCellDef="let element"> {{element.tripId}} </td>
      </ng-container>

      <ng-container matColumnDef="tripHeadSign">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Head Sign</th>
        <td mat-cell *matCellDef="let element"> {{element.tripHeadSign}} </td>
      </ng-container>

      <ng-container matColumnDef="busStopName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Stop Name</th>
        <td mat-cell *matCellDef="let element"> {{element.busStopName}} </td>
      </ng-container>

      <ng-container matColumnDef="arrivalTimeToStop">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Arrival Time (hh:mm)</th>
        <td mat-cell *matCellDef="let element"> {{element.arrivalTimeToStop}} </td>
      </ng-container>

      <ng-container matColumnDef="trafficMode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Traffic Mode</th>
        <td mat-cell *matCellDef="let element"> {{element.trafficMode}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
      [ngClass]="{
        'row-green': row.trafficMode === 'OFFPEAK',
        'row-pink': row.trafficMode === 'AMPEAK',
        'row-dark-blue': row.trafficMode === 'SECONDTRANSITION',
        'row-dark-green': row.trafficMode === 'NORMAL' || row.trafficMode === '' || row.trafficMode === null,
        'row-darker-blue': row.trafficMode === 'THIRDTRANSITION',
        'row-dark-pink': row.trafficMode === 'PMPEAK',
        'row-blue': row.trafficMode === 'FIRSTTRANSITION'
      }">
      </tr>
    </table> -->
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i = index">
        <th mat-header-cell *matHeaderCellDef> {{ column }} </th>
        <td mat-cell *matCellDef="let element"> 
          <ng-container *ngIf="i === 0">{{ element[i]['blockId'] }}</ng-container>
          <ng-container *ngIf="i === 1">{{ element[i-1]['tripId'] }}</ng-container>
          <ng-container *ngIf="i >= 2 && i <= 11">
            <div class="row">
              <div class="col-2">{{ element[i-2]['arrivalTimeToStop'] }} </div>
              <div class="col text-center travel-time" *ngIf="element[i-1] !== undefined">{{element[i-2]['nextStopTimeDiff']}} mins</div>
            </div>
          </ng-container>
          <ng-container *ngIf="i === 12">{{ element['totalStopTimeDiff'] }} mins</ng-container>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
      [ngClass]="{
        'row-green': row[0].trafficMode === 'OFFPEAK',
        'row-pink': row[0].trafficMode === 'AMPEAK',
        'row-dark-blue': row[0].trafficMode === 'SECONDTRANSITION' || row[0].trafficMode === 'FOURTHTRANSITION',
        'row-dark-green': row[0].trafficMode === 'NORMAL' || row[0].trafficMode === '' || row[0].trafficMode === null,
        'row-darker-blue': row[0].trafficMode === 'THIRDTRANSITION' || row[0].trafficMode === 'FIFTHTRANSITION',
        'row-dark-pink': row[0].trafficMode === 'PMPEAK',
        'row-blue': row[0].trafficMode === 'FIRSTTRANSITION'
      }">
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

</div>