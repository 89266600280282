<!-- <div style="height:100vh; overflow-y:auto;padding-top: 5vh;">
  <iframe src="https://addtransit.com/index.php" style="display: block;height:100%; width:100%;" frameborder="0" allowtransparency="true"></iframe>
</div> -->
<!-- <div *ngIf="!isNewWindow" >
    <app-account-header></app-account-header>
</div> -->
<!-- <div class="data-mgnt-group">
  <div class="container">
    <h3>Route Management<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3> -->
    
<!-- <p-autoComplete [styleClass]="form-control" [(ngModel)]="brand" [suggestions]="filteredBrands" (completeMethod)="filterBrands($event)" [size]="30" [immutable]="false"
[minLength]="1" placeholder="Find stops by routes" [dropdown]="false" (onDropdownClick)="filterBrands($event)">
  <ng-template let-brand pTemplate="item">
      <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5"> -->
          <!-- <img src="assets/showcase/images/demo/car/{{brand}}.png" style="width:32px;display:inline-block;margin:5px 0 2px 5px"/> -->
          <!-- <div style="font-size:18px;margin:10px 10px 0 0">{{brand}}</div>
      </div>
  </ng-template>
</p-autoComplete>
<span style="margin-left:50px">Brand: {{brand||'none'}}</span> -->


    <nav class="dm-nav">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-item nav-link active" id="nav-tab-routes" data-toggle="tab" href="#nav-routes" role="tab" aria-controls="nav-routes" aria-selected="true" (click)="tabEvent('routes')">Routes</a>
        <a class="nav-item nav-link" id="nav-tab-stops" data-toggle="tab" href="#nav-stops" role="tab" aria-controls="nav-stops" aria-selected="false" (click)="tabEvent('stops')">Stops</a>
        <a class="nav-item nav-link" id="nav-tab-services" data-toggle="tab" href="#nav-services" role="tab" aria-controls="nav-services" aria-selected="false" (click)="tabEvent('services')">Services</a>
      </div>
    </nav>
    <div class="row">
      <div class="col">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-routes" role="tabpanel" aria-labelledby="nav-tab-routes">
            <app-routes *ngIf="currTab === 'routes'"></app-routes>
          </div>
          <div class="tab-pane fade" id="nav-stops" role="tabpanel" aria-labelledby="nav-tab-stops">
            <app-stops *ngIf="currTab === 'stops'"></app-stops>
          </div>
          <div class="tab-pane fade" id="nav-services" role="tabpanel" aria-labelledby="nav-tab-services">
            <app-service *ngIf="currTab === 'services'"></app-service>
          </div>
        </div>
      </div>
    </div>
  <!-- </div>
</div> -->