import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
export const quickFade = trigger('quickFade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('100ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('100ms', style({ opacity: 0 })),
  ]),
]);
