import { trigger, transition, style, group, animate, state } from "@angular/animations";

export const slideInRight = trigger('slideInRight', [
  state('void', style({
      transform: 'translateX(100%)',
  })),
  state('*', style({
      transform: 'translateX(0)',
  })),
  transition('void => *', animate('200ms cubic-bezier(0.33, 1, 0.68, 1)')),
  transition('* => void', animate('200ms cubic-bezier(0.33, 1, 0.68, 1)'))
]);

export const slideInLeft = trigger('slideInLeft', [
  state('void', style({
      transform: 'translateX(-100%)',
  })),
  state('*', style({
      transform: 'translateX(0)',
  })),
  transition('void => *', animate('200ms cubic-bezier(0.33, 1, 0.68, 1)')),
  transition('* => void', animate('200ms cubic-bezier(0.33, 1, 0.68, 1)'))
]);

export const slideInLeftBoolean = trigger('slideInLeftBoolean', [
  state('false', style({
      transform: 'translateX(-100%)',
      overflow: 'hidden',
      width: 0,
      flex: 0,
      opacity: 0
  })),
  state('true', style({
      transform: 'translateX(0)',
      overflow: 'hidden',
      width: '*',
      opacity: 1
  })),
  transition('true => false', animate('150ms cubic-bezier(0.33, 1, 0.68, 1)')),
  transition('false => true', animate('150ms cubic-bezier(0.33, 1, 0.68, 1)'))
]);