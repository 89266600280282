import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { map, startWith } from 'rxjs/operators';
// import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/component/shared/services/map.service';
// import OpenLayers from 'ol';
import OlMap from 'ol/Map';
// import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
import Point from 'ol/geom/Point';
import OlFeature from 'ol/Feature';
import { ScaleLine, defaults as defaultOlControls } from 'ol/control';
import { Vector as VectorSource } from 'ol/source.js';
import {
  Vector as VectorLayer,
  VectorImage as VectorImageLayer,
  Image as ImageLayer,
} from 'ol/layer.js';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style.js';
import { transform } from 'ol/proj.js';
import { fromLonLat } from 'ol/proj';
import LineString from 'ol/geom/LineString.js';
import OSM from 'ol/source/OSM';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { StopManagementService } from 'src/app/component/shared/services/stop-management.service';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
// export var OSMBuildings:any;
// import { MapModeModalComponent } from '../map-mode-modal/map-mode-modal.component';
import { InfoModalComponent } from 'src/app/component/shared/services/info-modal/info-modal.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfirmModalComponent } from 'src/app/component/shared/services/confirm-modal/confirm-modal.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { MenuService } from 'src/app/component/shared/services/menu.service';
import { NgxSpinnerService } from 'ngx-spinner';

import GoogleLayer from 'olgm/layer/Google.js';
import OLGoogleMaps from 'olgm/OLGoogleMaps.js';
import { fadeInOut } from 'src/app/component/shared/others/animation/fadeInOut';
import { AddStopModalComponent } from '../../stop-management/add-stop-modal/add-stop-modal.component';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { MatSort } from '@angular/material/sort';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { slideInLeft } from 'src/app/component/shared/others/animation/slide';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { boundingExtent } from 'ol/extent';

declare var decode: any;
// import { copyStyles } from '@angular/animations/browser/src/util';
// import {
//   Select,
//   Translate,
//   defaults as defaultInteractions,
// } from 'ol/interaction';
declare var H: any;

@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.scss'],
  animations: [fadeInOut, slideInLeft],
})
export class CreateServiceComponent implements OnInit {
  isNewWindow: boolean = false;
  map: OlMap;
  modalAddStopData = {};
  // rptForm = new FormGroup({
  //   route: new FormControl(null, [Validators.required]),
  //   startDate: new FormControl(null, [Validators.required]),
  //   endDate: new FormControl(null, [Validators.required])
  // });

  running: boolean = false; // doing some blocking action
  isPanel: boolean = false; // stop detail panel is displayed
  routeServiceDisplay: 'map' | 'table' = 'table';
  mode: 'reset' | 'new' | 'view' | 'edit' = 'reset';
  isMapModeInit: boolean = false;
  isServiceExist: boolean = false;
  selectedServiceData: any = {};
  get isModify() {
    return this.mode === 'new' || this.mode === 'edit';
  }

  panelData: any;
  isLoop: boolean = true; // service route - loop mode
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  @ViewChild('chatPS2') chatPS2: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild(MatSort) sort: MatSort;
  currDirection: number = 1;
  direction1Data: any = [];
  direction2Data = [];
  direction1DataPath = [];
  direction2DataPath = [];
  direction1Distance = [];
  direction2Distance = [];

  // myControl = new FormControl();
  searchForm = new FormGroup({
    searchService: new FormControl(null, []),
    newService: new FormControl(null, []),
    // serviceName: new FormControl(null),
    startPoint: new FormControl(null, []),
    endPoint: new FormControl(null, []),
    streetName: new FormControl(null, [Validators.required]),
  });

  options: any = [];
  // optionsService: any[] = [];
  filteredOptions: Observable<any[]>;
  // filteredOptionsService: Observable<any[]>;
  selectedStreetData1: any = {};
  selectedStopCount: number = 0;
  isSearchStreet: boolean = false;

  showDistance = environment.serviceRouteMgmt.distance ?? false;

  boundingExtent = boundingExtent([
    fromLonLat(environment.map.boundingExtent.min),
    fromLonLat(environment.map.boundingExtent.max),
  ]);

  viewAnimate: OlView = new OlView({
    center: fromLonLat(environment.map.locationLonLat),
    zoom: environment.map.defaultZoom,
    minZoom: environment.map.minZoom,
    maxZoom: environment.map.maxZoom,
    projection: 'EPSG:900913',
    extent: this.boundingExtent,
  });
  busIntTer: any[] = [
    {
      stopName: "S'pore Indoor Stadium",
      stopCode: '80199',
      stopLat: '1.3016',
      stopLon: '103.8751',
    },
    {
      stopName: 'Ang Mo Kio Bus Interchange',
      stopCode: '54009',
      stopLat: '1.369841',
      stopLon: '103.848094',
    },
    {
      stopName: 'Bedok Bus Interchange',
      stopCode: '84009',
      stopLat: '1.324553',
      stopLon: '103.929143',
    },
    {
      stopName: 'Bishan Bus Interchange',
      stopCode: '53009',
      stopLat: '1.35047',
      stopLon: '103.84978',
    },
    {
      stopName: 'Boon Lay Bus Interchange',
      stopCode: '22009',
      stopLat: '1.339132',
      stopLon: '103.705296',
    },
    {
      stopName: 'Bukit Batok Bus Interchange',
      stopCode: '43009',
      stopLat: '1.350008',
      stopLon: '103.750774',
    },
    {
      stopName: 'Bukit Merah Bus Interchange',
      stopCode: '10009',
      stopLat: '1.28208',
      stopLon: '103.817215',
    },
    {
      stopName: 'Bukit Panjang Bus Interchange',
      stopCode: '45009',
      stopLat: '1.378445',
      stopLon: '103.76284',
    },
    {
      stopName: 'Choa Chu Kang Bus Interchange',
      stopCode: '44009',
      stopLat: '1.385647',
      stopLon: '103.7453',
    },
    {
      stopName: 'Clementi Bus Interchange',
      stopCode: '17009',
      stopLat: '1.314793',
      stopLon: '103.764205',
    },
    {
      stopName: 'Compassvale Bus Interchange',
      stopCode: '67759',
      stopLat: '1.39122',
      stopLon: '103.89712',
    },
    {
      stopName: 'Eunos Bus Interchange',
      stopCode: '82009',
      stopLat: '1.31948',
      stopLon: '103.90166',
    },
    {
      stopName: 'HarbourFront Bus Interchange',
      stopCode: '14009',
      stopLat: '1.266777',
      stopLon: '103.819546',
    },
    {
      stopName: 'Hougang Central Bus Interchange',
      stopCode: '64009',
      stopLat: '1.37055',
      stopLon: '103.8923',
    },
    {
      stopName: 'Joo Koon Bus Interchange',
      stopCode: '24009',
      stopLat: '1.32697',
      stopLon: '103.6784',
    },
    {
      stopName: 'Jurong East Temporary Bus Interchange',
      stopCode: '28009',
      stopLat: '1.33325',
      stopLon: '103.742',
    },
    {
      stopName: 'Pasir Ris Bus Interchange',
      stopCode: '77009',
      stopLat: '1.373745',
      stopLon: '103.949681',
    },
    {
      stopName: 'Punggol Temporary Bus Interchange',
      stopCode: '65009',
      stopLat: '1.40443',
      stopLon: '103.90244',
    },
    {
      stopName: 'Sembawang Bus Interchange',
      stopCode: '58009',
      stopLat: '1.447427',
      stopLon: '103.819953',
    },
    {
      stopName: 'Sengkang Bus Interchange',
      stopCode: '67009',
      stopLat: '1.39174',
      stopLon: '103.89572',
    },
    {
      stopName: 'Serangoon Bus Interchange',
      stopCode: '66009',
      stopLat: '1.350799',
      stopLon: '103.872143',
    },
    {
      stopName: 'Tampines Bus Interchange',
      stopCode: '75009',
      stopLat: '1.354341',
      stopLon: '103.943616',
    },
    {
      stopName: 'Tampines Concourse Bus Interchange',
      stopCode: '75019',
      stopLat: '1.357283',
      stopLon: '103.941798',
    },
    {
      stopName: 'Toa Payoh Bus Interchange',
      stopCode: '52009',
      stopLat: '1.331623',
      stopLon: '103.847519',
    },
    {
      stopName: 'Woodlands Temporary Bus Interchange',
      stopCode: '46010',
      stopLat: '1.43739',
      stopLon: '103.78583',
    },
    {
      stopName: 'Yio Chu Kang Bus Interchange',
      stopCode: '55509',
      stopLat: '1.382573',
      stopLon: '103.844619',
    },
    {
      stopName: 'Yishun Bus Interchange',
      stopCode: '59008',
      stopLat: '1.427485',
      stopLon: '103.836293',
    },
    {
      stopName: 'Buona Vista Bus Terminal',
      stopCode: '11379',
      stopLat: '1.309416',
      stopLon: '103.79208',
    },
    {
      stopName: 'Changi Airport Bus Terminal',
      stopCode: '95129',
      stopLat: '1.356985',
      stopLon: '103.989906',
    },
    {
      stopName: 'Changi Business Park Bus Terminal',
      stopCode: '97009',
      stopLat: '1.341888',
      stopLon: '103.967137',
    },
    {
      stopName: 'Changi Village Bus Terminal',
      stopCode: '99009',
      stopLat: '1.38949',
      stopLon: '103.98791',
    },
    {
      stopName: 'Ghim Moh Bus Terminal',
      stopCode: '11009',
      stopLat: '1.31099',
      stopLon: '103.78924',
    },
    {
      stopName: 'Kampong Bahru Bus Terminal',
      stopCode: '10499',
      stopLat: '1.275969',
      stopLon: '103.83346',
    },
    {
      stopName: 'Kent Ridge Bus Terminal',
      stopCode: '16009',
      stopLat: '1.29444',
      stopLon: '103.76991',
    },
    {
      stopName: 'Lorong 1 Geylang Bus Terminal',
      stopCode: '80009',
      stopLat: '1.310304',
      stopLon: '103.871817',
    },
    {
      stopName: 'Marina Centre Bus Terminal',
      stopCode: '2099',
      stopLat: '1.29224',
      stopLon: '103.86166',
    },
    {
      stopName: 'Queen Street Bus Terminal',
      stopCode: '1109',
      stopLat: '1.303571',
      stopLon: '103.856509',
    },
    {
      stopName: 'Shenton Way Bus Terminal',
      stopCode: '3239',
      stopLat: '1.27429',
      stopLon: '103.84756',
    },
    {
      stopName: 'Sims Place Bus Terminal',
      stopCode: '80289',
      stopLat: '1.31608',
      stopLon: '103.87933',
    },
    {
      stopName: "St. Michael/'s Bus Terminal",
      stopCode: '52491',
      stopLat: '1.32597',
      stopLon: '103.855314',
    },
    {
      stopName: 'Tuas Bus Terminal',
      stopCode: '25009',
      stopLat: '1.34173',
      stopLon: '103.63953',
    },
    {
      stopName: 'Upper East Coast Bus Terminal',
      stopCode: '94009',
      stopLat: '1.317946',
      stopLon: '103.952805',
    },
  ];

  @ViewChild('mapElementRef') mapElementRef: ElementRef;
  @ViewChild('inputSearchStreet', { read: MatAutocompleteTrigger })
  inputSearchStreet: MatAutocompleteTrigger;

  @ViewChild(MatAutocomplete) inputSearchStreetAutoComplete: MatAutocomplete;
  // @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputSearchService: MatAutocompleteTrigger;

  streetNameBusStop: any = '';
  isBusCodeSearch: boolean = false;
  isEverySearchLock: boolean = false;

  OSMLayer: OlTileLayer<any>;
  mapOptions: any = {
    isIncident: false,
    isLadder: true,
    isMapLayer: false,
    isDirection: false,
  };
  allTrainStation: any;
  isStopZoom: boolean = false;
  // hereMapName:string = '';
  routeType: string = 'motorway';
  directionProvider: string = '';
  isExpand: boolean = false;
  // private routerHere: any;
  // private platform: any;
  // platform: any;
  // ui: any;
  pageData = {
    id: 2,
    name: 'serviceManagement',
    displayPageName: 'Service Management',
  };
  isHasAccess: boolean = false;
  rightsError: string = '';

  constructor(
    // private homeService: HomeService,
    // private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    private mapService: MapService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private stopManagementService: StopManagementService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    public authService: AuthService,
    private menuService: MenuService,
    private _spinner: NgxSpinnerService,
    private _mapService: MapService
  ) {
    // this.platform = new H.service.Platform({
    //     "apikey": environment.hereApiKey
    // });
  }

  ngOnInit() {
    this._spinner.hide();
    setTimeout(() => {
      if (this.route.snapshot.params.id === '2') {
        this.isNewWindow = true;
      }

      this.initMap();
      this.initAllowedRouteSelection();
      // this.initHereMap();

      this.activatedRoute.queryParams.subscribe(params => {
        let busService = params['busService'];
        if (busService) {
          this.searchForm.controls['searchService'].setValue(busService);
          this.searchServiceRoute();
        }
      });
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      this.rightsError = "You don't have access rights to this module.";
    }, 500);

    setTimeout(() => {
      this.initTrainStations();
    }, 600);
  }

  // public ngAfterViewInit() {
  //   let defaultLayers = this.platform.createDefaultLayers();
  //   this.map = new H.Map(
  //       this.map.nativeElement,
  //       defaultLayers.vector.normal.map,
  //       {
  //           zoom: 12,
  //           center: { lat: 1.3521, lng: 103.8198 },
  //           pixelRatio: window.devicePixelRatio || 1
  //       }
  //   );
  //   window.addEventListener('resize', () => this.map.getViewPort().resize());

  //   const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
  //   this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
  //   this.ui.removeControl('mapsettings');
  //   var ms = new H.ui.MapSettingsControl({
  //       layers: [
  //       {
  //           label: 'Incidents', layer: defaultLayers.vector.normal.trafficincidents
  //       },
  //       {
  //           label: 'Traffic Layer', layer: defaultLayers.vector.normal.traffic
  //       }]
  //   });
  //   this.ui.addControl('customized', ms);
  // }

  routeHereDirection(selectedStops) {
    const platform = new H.service.Platform({
      apikey: environment.hereApiKey,
    });

    //start v7
    // var routerHere = platform.getRoutingService();
    // var waypoints = '{';
    // selectedStops.forEach((element,index) => {
    //   waypoints += '"waypoint'+index+'":"' + element.stopLat + ',' + element.stopLon +'",'
    // });
    // waypoints += '"mode": "fastest;publicTransport", "representation": "display"}';
    // var waypointsParsed = JSON.parse(waypoints);
    //end v7
    // console.log(selectedStops);
    //start v8
    const routerHere = platform.getRoutingService(null, 8);
    const firstStop = JSON.parse(JSON.stringify(selectedStops[0]));
    const lastStop = JSON.parse(
      JSON.stringify(selectedStops[selectedStops.length - 1])
    );
    const waypoints = [];
    const selectedStopsOrig = JSON.parse(JSON.stringify(selectedStops));
    selectedStops.shift(); //remove first array
    selectedStops.pop(); //remove last array

    selectedStops.forEach(element => {
      // console.log(element);
      // waypoints.push(element.stopLat+','+element.stopLon)
      if (element.nameHint) {
        waypoints.push(
          element.stopLat +
            ',' +
            element.stopLon +
            ';nameHint=' +
            element.nameHint
        );
      } else {
        waypoints.push(element.stopLat + ',' + element.stopLon);
      }
    });
    const waypointsParsed = {
      transportMode: 'bus',
      // 'return': 'polyline,summary,actions,instructions',
      departureTime: 'any',
      return: 'polyline,summary',
      routingMode: 'fast',
      origin: firstStop.stopLat + ',' + firstStop.stopLon,
      destination: lastStop.stopLat + ',' + lastStop.stopLon,
      via: new H.service.Url.MultiValueQueryParameter(waypoints),
    };
    //end v8
    // console.log(waypointsParsed);

    routerHere.calculateRoute(
      waypointsParsed,
      data => {
        /**
         * Heremap V7
         */
        // console.log(data);
        // if(data.response) {
        //   data = data.response.route[0];
        //   let latlon = {
        //     path: [],
        //     direction: 1
        //   };

        //   data.shape.forEach(point => {
        //     let pointLoc = point.split(',');
        //     latlon.path.push(pointLoc);
        //   })
        //   this.drawStPathMapManually(latlon, null);
        //   this.addMarkStopManually(selectedStops, this.currDirection);
        //   if(this.currDirection === 1) {
        //     this.direction1DataPath = latlon.path;
        //   }
        //   else {
        //     this.direction2DataPath = latlon.path;
        //   }
        // }
        //end v7

        //start v8
        if (data.routes[0] !== undefined) {
          let sections = data.routes[0].sections;
          let latlon = {
            path: [],
            direction: 1,
          };
          const distances = [];
          sections.forEach(element => {
            const { summary } = element || {};
            const { length } = summary;
            distances.push(length);
            // latlon.path.push([element.arrival.place.location.lat, element.arrival.place.location.lng]);
            decode(element.polyline).polyline.forEach(polyline => {
              latlon.path.push(polyline);
            });
            // console.log(decode(element.polyline));
          });

          // console.log('HERE distance: ', distances);

          //remove layer
          this.removeLayer('pathStreet-1');
          this.removeLayer('pathStreet-2');
          this.removeLayer('stopOtherDirection-1');
          this.removeLayer('stopOtherDirection-2');

          // console.log(latlon);
          this.drawStPathMapManually(latlon, null);
          this.addMarkStopManually(selectedStopsOrig, this.currDirection);

          if (distances?.length > 1) {
            distances.unshift(0);
          } else if (distances?.length > 0 && distances[0] !== 0) {
            distances.unshift(0);
          }

          // console.log('distances', sections, distances);

          if (this.currDirection === 1) {
            this.direction1DataPath = latlon.path;
            this.direction1Distance = [...distances];
          } else {
            this.direction2DataPath = latlon.path;
            this.direction2Distance = [...distances];
          }
        } else {
          this._snackBar.open(data.notices[0].title, null, {
            duration: 2000,
          });
        }
        //end v8

        // draw other direction
        // var otherStops = this.getOtherStopDirection();
        // this.addOtherMarkStop(otherStops);
      },
      error => {
        console.log(error);
      }
    );
  }

  updateRouteHere(selectedStops, direction: 1 | 2) {
    const platform = new H.service.Platform({
      apikey: environment.hereApiKey,
    });
    const routerHere = platform.getRoutingService(null, 8);
    const firstStop = JSON.parse(JSON.stringify(selectedStops[0]));
    const lastStop = JSON.parse(
      JSON.stringify(selectedStops[selectedStops.length - 1])
    );
    const waypoints = [];
    const selectedStopsOrig = JSON.parse(JSON.stringify(selectedStops));
    selectedStops.shift(); //remove first array
    selectedStops.pop(); //remove last array

    selectedStops.forEach(element => {
      // console.log(element);
      if (element.nameHint) {
        waypoints.push(
          element.stopLat +
            ',' +
            element.stopLon +
            ';nameHint=' +
            element.nameHint
        );
      } else {
        waypoints.push(element.stopLat + ',' + element.stopLon);
      }
    });
    const waypointsParsed = {
      transportMode: 'bus',
      // 'return': 'polyline,summary,actions,instructions',
      departureTime: 'any',
      return: 'polyline,summary',
      routingMode: 'fast',
      origin: firstStop.stopLat + ',' + firstStop.stopLon,
      destination: lastStop.stopLat + ',' + lastStop.stopLon,
      via: new H.service.Url.MultiValueQueryParameter(waypoints),
    };

    const distances = [0];

    routerHere.calculateRoute(
      waypointsParsed,
      data => {
        //start v8
        if (data.routes[0] !== undefined) {
          let sections = data.routes[0].sections;
          let latlon = {
            path: [],
            direction: 1,
          };

          sections.forEach(element => {
            const { summary } = element || {};
            const { length } = summary;
            distances.push(length);
            // latlon.path.push([element.arrival.place.location.lat, element.arrival.place.location.lng]);
            decode(element.polyline).polyline.forEach(polyline => {
              latlon.path.push(polyline);
            });
            // console.log(decode(element.polyline));
          });

          //remove layer
          this.removePathLayers();

          // console.log(latlon);
          this.drawStPathMapManually(latlon, null);
          this.addMarkStopManually(selectedStopsOrig, direction);

          if (direction === 1) {
            this.direction1DataPath = latlon.path;
            this.direction1Distance = [...distances];
          } else {
            this.direction2DataPath = latlon.path;
            this.direction2Distance = [...distances];
          }
        } else {
          this._snackBar.open(data.notices[0].title, null, {
            duration: 2000,
          });
        }
        //end v8
        // var otherStops = this.getOtherStopDirection();
        // this.addOtherMarkStop(otherStops);
      },
      error => {
        console.log(error);
      }
    );
  }

  getOtherStopDirection() {
    let otherDirection = [];

    if (this.currDirection === 1) {
      this.direction2Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            otherDirection.push(stop);
          }
        });
      });
    } else {
      this.direction1Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            otherDirection.push(stop);
          }
        });
      });
    }
    return otherDirection;
  }

  // initHereMap() {

  //   let defaultLayers = this.platform.createDefaultLayers();
  //   this.map = new H.Map(
  //       this.mapElementRef.nativeElement,
  //       defaultLayers.vector.normal.map,
  //       {
  //           zoom: 12,
  //           center: { lat: 1.3521, lng: 103.8198 },
  //           pixelRatio: window.devicePixelRatio || 1
  //       }
  //   );
  //   window.addEventListener('resize', () => this.map.getViewPort().resize());

  //   const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

  //   this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
  //   this.ui.removeControl('mapsettings');
  //   var ms = new H.ui.MapSettingsControl({
  //       layers: [
  //       {
  //           label: 'Incidents', layer: defaultLayers.vector.normal.trafficincidents
  //       },
  //       {
  //           label: 'Traffic Layer', layer: defaultLayers.vector.normal.traffic
  //       }]
  //   });
  //   this.ui.addControl('customized', ms);
  // }

  initMap() {
    this.OSMLayer = new OlTileLayer({
      source: new OSM(),
      className: 'OSMLayer',
      properties: {
        name: 'OSMLayer',
      },
    });

    const scaleLine = new ScaleLine({
      units: 'metric',
    });

    this.map = new OlMap({
      controls: defaultOlControls().extend([scaleLine]),
      target: 'map-mode',
      view: this.viewAnimate,
    });

    this.map.addLayer(this.OSMLayer);

    //google layer top most
    // this.onChangeProvider('GoogleMap');
    // this.trafficToggle({'checked':true});

    setTimeout(() => {
      this.map.updateSize();
      this.watchMenuChange();
    }, 200);
    this.isMapModeInit = true;
  }

  changeMode() {
    this.routeServiceDisplay =
      this.routeServiceDisplay === 'table' ? 'map' : 'table';
    this.isPanel = false; //close stop details panel

    setTimeout(() => {
      if (!this.isMapModeInit) {
        this.initMap();
      } else if (this.routeServiceDisplay === 'map') {
        this.map
          .getLayers()
          .getArray()
          .filter(
            layer =>
              layer.get('name') === 'pathStreet-1' ||
              layer.get('name') === 'pathStreet-2' ||
              layer.get('name') === 'stopOtherDirection-1' ||
              layer.get('name') === 'stopOtherDirection-2'
          )
          .forEach((layer2: VectorLayer<any> | VectorImageLayer<any>) => {
            // this.map.removeLayer(layer)
            let features = layer2.getSource().getFeatures();
            features.forEach(element => {
              this.mapService.removeLayerFeature(layer2, element.get('name'));
            });
          });

        //re-render map
        this.map.setTarget(this.mapElementRef.nativeElement);

        //center layer
        // let currLayer = this.map.getLayers().getArray()
        // .filter(layer => layer.get('name') === 'pathStreet-'+this.currDirection);

        // var extent = currLayer[0].getSource().getExtent();
        // this.map.getView().fit(extent, { duration: 1000 });
        //end center layer

        let selectedStops = [];
        let otherDirection = [];
        if (this.currDirection === 1) {
          this.direction1Data.forEach(element => {
            element.stops.forEach(stop => {
              if (stop.isChecked) {
                selectedStops.push(stop);
              }
            });
            // listStopCode.push(element.stops.map(a => a.stopCode));
          });
          this.direction2Data.forEach(element => {
            element.stops.forEach(stop => {
              if (stop.isChecked) {
                otherDirection.push(stop);
              }
            });
          });
        } else {
          this.direction2Data.forEach(element => {
            element.stops.forEach(stop => {
              if (stop.isChecked) {
                selectedStops.push(stop);
              }
            });
            // listStopCode.push(element.stops.map(a => a.stopCode));
          });
          this.direction1Data.forEach(element => {
            element.stops.forEach(stop => {
              if (stop.isChecked) {
                otherDirection.push(stop);
              }
            });
          });
        }

        var directionProviderMap =
          this.directionProvider === '' ? 'heremap' : this.directionProvider;
        // console.log('change mode', selectedStops);
        // console.log(this.directionProvider);
        //redraw map with current algo
        this.onChangeDirectionProvider(this.directionProvider);

        this.initMapInteraction();

        // draw other mark direction
        // this.addOtherMarkStop(otherDirection);

        // this.routeHereDirection(selectedStops);
      } else if (this.routeServiceDisplay === 'table') {
        this.isExpand = false;
      }
    }, 100);
  }

  reRenderMap(exclude) {
    this.running = true;
    this.map.setTarget(this.mapElementRef.nativeElement);

    let selectedStops = [];
    let otherDirection = [];

    if (this.currDirection === 1) {
      this.direction1Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            selectedStops.push(stop);
          }
        });
      });
      this.direction2Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            otherDirection.push(stop);
          }
        });
      });
    } else {
      this.direction2Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            selectedStops.push(stop);
          }
        });
      });
      this.direction1Data.forEach(element => {
        element.stops.forEach(stop => {
          if (stop.isChecked) {
            otherDirection.push(stop);
          }
        });
      });
    }

    if (this.directionProvider === 'heremap') {
      this.routeHereDirection(
        this.getSelectedFlatStops(this.getCurrentDirectionData().directionData)
          .listStop
      );
    } else {
      this.drawStPathMapBox(selectedStops, exclude);
    }
    this.initMapInteraction();
    // this.addOtherMarkStop(otherDirection);
  }

  loopService(e) {
    // if (this.resultServiceSearch.navigation === undefined) {
    //   return false;
    // }

    if (this.resultServiceSearch.navigation !== undefined) {
      let headSign =
        this.resultServiceSearch.navigation[0].routeHeadSign.split('-');
      let startPoint = this.busIntTer.find(
        x => x.stopName === headSign[0].trim()
      );
      this.searchForm.controls['startPoint'].setValue(startPoint);
      if (e.checked) {
        this.searchForm.controls['endPoint'].setValue(startPoint);
      } else {
        let endPoint = this.busIntTer.find(
          x => x.stopName === headSign[1]?.trim()
        );
        this.searchForm.controls['endPoint'].setValue(endPoint);
      }
    }

    console.log('is loop', e.checked);
    console.log('dir', this.currDirection);
    this.changeDirection(1);
    this.isLoop = e.checked;

    // remove dir2 data
    if (this.isLoop) {
      this.direction2Data = [];
      this.direction2DataPath = [];
      this.selectedStreetDirection2PathData = {};
      this.direction2Count = 0;
    }

    //remove layer
    this.removeLayer('pathStreet-1');
    this.removeLayer('pathStreet-2');
    this.removeLayer('stopOtherDirection-1');
    this.removeLayer('stopOtherDirection-2');

    this.drawStPathMapManually(
      this.selectedStreetDirection1PathData,
      this.direction1Data
    );
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/system-configuration/service/create/2',
      'Service Management',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  closePanel() {
    this.isPanel = false;
  }

  closeModalAction(event) {
    console.log(event);
  }

  private _filter(value: any): any[] {
    // console.log('filter: ', value);
    if (!value) {
      return;
    }
    const { streetName } = value || {};
    const filterValue = streetName
      ? streetName?.toLowerCase()
      : value?.toLowerCase() ?? undefined;
    if (filterValue) {
      return this.options.filter(option =>
        option.streetName.toLowerCase().includes(filterValue)
      );
    }
    return this.options;
  }

  // private _filterStop(value: any): any[] {
  //   if(!value){
  //     return;
  //   }

  //   const filterValue = value.toLowerCase();
  //   return this.options.flat().filter((option:any) => {option.stopCode.toLowerCase().includes(filterValue)});
  // }

  chooseFirstStreetOption(evt): void {
    // console.log(
    //   'First Street',
    //   evt,
    //   this.inputSearchStreetAutoComplete.options
    // );
    // console.log('choose option', this.inputSearchStreetAutoComplete.options);
    const autoCompleteOptions = this.inputSearchStreetAutoComplete.options;
    if (autoCompleteOptions?.length > 0) {
      this.inputSearchStreetAutoComplete.options.first.select();
    } else {
      const searchValue = this.searchForm.value.streetName.trim();
      this.searchStreet();
    }
  }

  // TO DO: select already selected streets
  searchStreet(evt?) {
    console.log('search street', evt);
    this.running = true;
    this.options = [];
    this.streetNameBusStop = this.searchForm.value.streetName.trim();
    // console.log(isNaN(this.streetNameBusStop));
    if (
      !this.streetNameBusStop ||
      (!isNaN(this.streetNameBusStop) && this.streetNameBusStop.length !== 5)
    ) {
      this._snackBar.open('Invalid street name or Bus Code.', null, {
        duration: 2000,
      });
      this.running = false;
      return false;
    }

    var urlAction =
      'gtfsupload/v1/stops/getStopDetail/' + this.streetNameBusStop;
    this.commonService
      .commonPostAction(urlAction, '')
      .subscribe((data: any) => {
        this.isBusCodeSearch = false;
        if (data.length === 0) {
          this.options.push({
            streetName: 'No street/stop available',
            isDisable: true,
          });
        }

        //sort data
        data.sort((a, b) => (a.streetName > b.streetName ? 1 : -1));
        data.forEach(element => {
          this.options.push(element);
        });

        if (data.length > 1) {
          this.filteredOptions = this.searchForm
            .get('streetName')
            .valueChanges.pipe(
              startWith(''),
              map(value =>
                value !== '' ? this._filter(value) : this.options.slice()
              )
            );
          evt?.stopPropagation();
          this.inputSearchStreet.openPanel();
        } else if (data[0] !== undefined) {
          //if result is one default add
          this.selectedStreet(data[0]);
          let checkboxDefault = {
            checked: true,
          };
          data[0].stops[0].isChecked = true;
          this.addSelectedCount(checkboxDefault, data[0].stops[0]);
        } else {
          this._snackBar.open('No available street or bus stop.', null, {
            duration: 2000,
          });
        }

        this.running = false;
      });
  }

  drawStPathMapBox(selectedStops, excludes: string = null) {
    // var stopsLonLat2:string='';

    // selectedStops.forEach((element,index) => {
    //   stopsLonLat2 += element.stopLon +','+ element.stopLat+';';
    // });

    this.mapService.removeLayerByLayerName(
      this.map,
      'pathStreet-' + this.currDirection
    );
    var result = this.chunkedByGroup(selectedStops);

    var stopsLonLat: any = [];
    var replaceStr = /undefined/gi;

    result.forEach((element, index) => {
      element.forEach(element2 => {
        stopsLonLat[index] += element2.stopLon + ',' + element2.stopLat + ';';
      });
      if (stopsLonLat[index]) {
        stopsLonLat[index] = stopsLonLat[index]
          .replace(replaceStr, '')
          .slice(0, -1);
      }
    });

    // console.log(result);
    // console.log(stopsLonLat);
    // return false;
    var delayTimer2 = stopsLonLat.length * 500;

    if (selectedStops.length <= 1) {
      return false;
    }

    this._snackBar.open('Map rendering...');
    // this.stopManagementService.getDirection2('driving', stopsLonLat2).then(
    //   (directionRes2:any) => {
    //     setTimeout(() => {
    //     },delayTimer2)
    //   });

    this.stopManagementService
      .getDirection2('driving', stopsLonLat, excludes)
      .then(
        (data: any) => {
          setTimeout(() => {
            var allCoords = [];
            var allCoordsOrig = [];
            const distance = [0];

            if (data[0].code === 'NoRoute') {
              this.running = false;
              // this._snackBar.dismiss();
              this._snackBar.open('Map render error.', null, {
                duration: 2000,
              });
              return false;
            }

            if (data[0]) {
              data.forEach((element: any) => {
                let coords = element.routes[0].geometry.coordinates;
                coords.forEach((element2: any) => {
                  allCoords.push([
                    parseFloat(element2[0]),
                    parseFloat(element2[1]),
                  ]);
                  allCoordsOrig.push([
                    parseFloat(element2[1]),
                    parseFloat(element2[0]),
                  ]);
                });

                const { routes } = element || {};

                if (routes[0]) {
                  const { legs } = routes[0];
                  legs.forEach(leg => {
                    distance.push(leg?.distance ?? undefined);
                  });
                }
              });
            }
            console.log('Mapbox distance: ', distance);

            // stopsLonLat = data.routes[0].geometry.coordinates;

            // var coordsArray = [];
            let arrPaths = [];
            // allCoords.forEach((element:any) => {
            //   coordsArray.push([parseFloat(element[0]),parseFloat(element[1])]);
            // });
            // console.log(coordsArray);

            //assign coords for saving
            // if(this.currDirection === 1) {
            //   this.direction1DataPath = allCoords;
            // }
            // else {
            //   this.direction2DataPath = allCoords;
            // }

            var routeGeom = new LineString(allCoords).transform(
              'EPSG:4326',
              'EPSG:3857'
            );
            var routeFeature = new OlFeature({
              type: 'route',
              geometry: routeGeom,
            });

            var style = new Style({
              stroke: new Stroke({
                color: this.currDirection === 1 ? '#111e6c' : '#3C3105',
                width: 4,
              }),
            });
            routeFeature.setStyle(style);
            arrPaths.push(routeFeature);
            let featursList = [];

            for (const path of arrPaths) {
              featursList.push(path); //route
            }

            let vectorLayer = new VectorImageLayer({
              source: new VectorSource({
                features: featursList,
              }),
              className: 'pathStreet-' + this.currDirection,
              properties: {
                name: 'pathStreet-' + this.currDirection,
              },
            });

            // this.removeLayer('pathStreet-'+this.currDirection);

            var isAllStopLayer = this.mapService.isLayerExist(
              this.map,
              'pathStreet-' + this.currDirection
            );
            if (isAllStopLayer) {
              let features = isAllStopLayer.getSource().getFeatures();
              features.forEach(element => {
                this.mapService.removeLayerFeature(
                  isAllStopLayer,
                  element.get('name')
                );
              });
              // console.log(isAllStopLayer);
            }

            this.map.addLayer(vectorLayer);

            if (this.isStopZoom) {
              this.isStopZoom = false;
            } else {
              var extent = vectorLayer.getSource().getExtent();
              this.map
                .getView()
                .fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
            }

            // this.addMarkStop(selectedStops);
            this.addMarkStopManually(selectedStops, this.currDirection);

            this.running = false;
            this._snackBar.dismiss();
            this._snackBar.open('Map rendered.', null, {
              duration: 2000,
            });

            if (this.currDirection === 1) {
              // console.log('direction 1');
              // console.log(allCoordsOrig);
              this.direction1DataPath = allCoordsOrig;
              this.direction1Distance = [...distance];
              // console.log(this.direction1DataPath);
              // console.log(this.getCurrentDirectionData())
            } else {
              this.direction2DataPath = allCoordsOrig;
              this.direction2Distance = [...distance];
            }
          }, delayTimer2);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  selectedStreet(data) {
    // console.log('selected street', data);
    var listStop: any = [];

    if (data.streetName === 'No street available' || !data) {
      return false;
    }

    if (this.isBusCodeSearch) {
      listStop.streetName = data.streetName;
      listStop.stops = [];
      listStop.stops.push(data);
    }

    if (this.isBusCodeSearch && this.currDirection === 1) {
      // console.log(11, listStop);
      this.selectedStreetDirection1Data = listStop;
    } else if (!this.isBusCodeSearch && this.currDirection === 1) {
      // console.log(22, listStop)
      this.selectedStreetDirection1Data = data;
    } else if (this.isBusCodeSearch && this.currDirection === 2) {
      // console.log(33, listStop)
      this.selectedStreetDirection2Data = listStop;
    } else {
      // console.log(44, listStop)
      this.selectedStreetDirection2Data = data;
    }

    this.isSearchStreet = true;
    // if(this.routeServiceDisplay === 'map') {
    // this.drawStPathMapBox(data);
    // this.initMapInteraction();
    // }
  }

  /**
   * add / remove stops to tentative bus stop list
   * @param event checkbox event
   * @param stop bus stop information
   */
  addSelectedCount(event, stop) {
    var selectedStreetData: any = {};
    let count = 1;
    let dirData = [];
    if (this.currDirection === 1) {
      selectedStreetData = this.selectedStreetDirection1Data;
      dirData = this.getSelectedFlatStops(this.direction1Data).listStopCode;
      count = dirData?.length + 1 ?? 1;
    } else {
      selectedStreetData = this.selectedStreetDirection2Data;
      dirData = this.getSelectedFlatStops(this.direction2Data).listStopCode;
      count = dirData?.length + 1 ?? 1;
    }

    if (event.checked) {
      selectedStreetData.stops.forEach(element => {
        if (Number.isInteger(element.sequence)) {
          count++;
          // element.sequence = count;
        }
      });
      stop.sequence = count;
    } else {
      selectedStreetData.stops.forEach(element => {
        if (
          Number.isInteger(element.sequence) &&
          stop.sequence < element.sequence
        ) {
          element.sequence -= 1;
        }
      });
      stop.sequence = '';
    }
  }

  addDirectionData() {
    // console.log(this.direction1Data);
    if (this.isEverySearchLock) {
      this._snackBar.open('All stops are added, make a new search.', null, {
        duration: 2000,
      });
      return false;
    }

    var selectedStreetData: any = {};
    // var isSingleStop: boolean = false;
    // var startPoint = this.searchForm.value.startPoint;
    // var endPoint = this.searchForm.value.endPoint;

    if (this.currDirection === 1) {
      selectedStreetData = this.selectedStreetDirection1Data;
      // this.selectedStreetDirection1Data.streetName = '';
    } else {
      selectedStreetData = this.selectedStreetDirection2Data;
      // this.selectedStreetDirection2Data.streetName = '';
    }
    // console.log(selectedStreetData);
    // return false;

    var directionData: any = this.direction1Data;
    // var directionCount:number = this.direction1Count;
    // var currStreet = selectedStreetData;
    var selectedStops: any = [];

    // var currStop = directionData.find(element => element.streetName === currStreet.streetName);
    // console.log(selectedStreetData);
    // return false;

    selectedStreetData.stops.forEach(element => {
      if (Number.isInteger(element.sequence)) {
        element.isChecked = true;
        element.streetName = selectedStreetData.streetName;
        selectedStops.push(element);
      }
    });

    if (selectedStops.length === 0) {
      this._snackBar.open('Please select stops.', null, {
        duration: 2000,
      });
      return false;
    }

    // var countStops = directionData.reduce(
    //   (arr, elem) => arr.concat(elem.stops), []
    // )

    if (this.currDirection !== 1) {
      directionData = this.direction2Data;
      //add stops count
      // this.direction2Count = countStops.length;
    }
    // else {
    //   //add stops count
    //   this.direction1Count = countStops.length;
    // }

    // if(this.isBusCodeSearch || selectedStops.length <= 1) {
    //   let directionDataCopy = JSON.parse(JSON.stringify(directionData));
    //   let prevStop;
    //   if(directionDataCopy.reverse()[0] === undefined) {
    //     prevStop = startPoint;
    //   }
    //   else {
    //     prevStop = directionDataCopy.reverse()[0].stops.reverse()[0];
    //   }
    //   console.log(prevStop);
    //   // selectedStops.stops = [];
    //   selectedStops.push(prevStop);
    //   isSingleStop = true;
    // }
    // let directionDataCopy = JSON.parse(JSON.stringify(directionData));
    // let prevStop = directionDataCopy.reverse()[0].stops.reverse()[0];

    //check if no prev stop assign start point
    let prevStop;
    if (directionData[directionData.length - 1] === undefined) {
      // prevStop = this.searchForm.value.startPoint;
      prevStop = 'Start Point';
    } else {
      prevStop = directionData[directionData.length - 1].stops[0];
    }

    if (prevStop.streetName === selectedStreetData.streetName) {
      var differenceStops = selectedStops.filter(
        ({ stopCode: id1 }) =>
          !directionData[directionData.length - 1].stops.some(
            ({ stopCode: id2 }) => id2 === id1
          )
      );

      if (differenceStops.length === 0) {
        this._snackBar.open(
          'Selected stops are already added. Please make a new search or add new stops.', // 'Checked stops are added, choose other stops.',
          null,
          {
            duration: 2000,
          }
        );
        return false;
      }
      // directionData[directionData.length-1].stops = []; //clear data before appending new to avoid duplicate

      differenceStops.forEach(elementStop => {
        directionData[directionData.length - 1].stops.push(elementStop);
      });
      this.cancelNewSearch();
      this._snackBar.open(
        `${differenceStops?.length} stop${
          differenceStops?.length > 1 ? 's have' : ' has'
        } been added to Direction ${this.currDirection}`,
        null,
        {
          duration: 3000,
        }
      );
    } else {
      directionData.push({
        streetName: selectedStreetData.streetName,
        stops: selectedStops,
      });
      console.log('dir data', directionData);
      const dirDataLength =
        this.getSelectedFlatStops(directionData).listStopCode?.length ?? 0;
      if (dirDataLength > 0) {
        this._snackBar.open(
          `${dirDataLength} stop${
            dirDataLength > 1 ? 's have' : ' has'
          } been added to Direction ${this.currDirection}`,
          null,
          {
            duration: 3000,
          }
        );
      }

      this.cancelNewSearch();
    }

    //removed placed to map mode
    // if(selectedStops.length <= 1) {
    //   let singleStop:any = [];
    //   singleStop.push(prevStop);
    //   selectedStops.forEach(element => {
    //     singleStop.push(element);
    //   });
    //   isSingleStop = true;
    //   // this.drawStPathMapBox(singleStop);
    // }
    // else {
    // this.drawStPathMapBox(selectedStops);
    // }
    //end removed placed to map mode

    // else {
    //   var diffArray:any = selectedStops.filter(e => !currStop.stops.find(a => e.stopCode == a.stopCode));
    //   diffArray.forEach(element => {
    //     currStop.stops.push(element);
    //   });
    // }

    //sort by selected sequence
    directionData.forEach(element => {
      element.stops.sort((a, b) => {
        return <any>a.sequence - <any>b.sequence;
      });
    });

    //update stops
    var updatedCount = directionData.reduce(
      (arr, elem) => arr.concat(elem.stops),
      []
    );
    // this.drawStPathMapBox(updatedCount, 'motorway');

    this.onChangeDirectionProvider('heremap');
    this.initMapInteraction();

    if (this.currDirection === 1 && this.routeServiceDisplay === 'table') {
      setTimeout(() => {
        this.chatPS.directiveRef.scrollToBottom(0, 50);
      }, 100);
    } else if (this.routeServiceDisplay === 'table') {
      setTimeout(() => {
        this.chatPS2.directiveRef.scrollToBottom(0, 50);
      }, 100);
    }

    //remove last array after drawing on the map for table mode view
    // if(isSingleStop) {
    //   directionData.reverse()[0].stops.pop();
    //   directionData.reverse();
    // }
    // this.addMarkStop(selectedStops);

    // this.searchForm.value.streetName = '';
    // this.searchForm.controls['streetName'].setValue('');
    // this.searchForm.get('streetName').setValue('');
    this.searchForm.get('streetName').reset();
    // console.log('clear input');

    var countStops = directionData.reduce(
      (arr, elem) => arr.concat(elem.stops),
      []
    );

    if (this.currDirection !== 1) {
      //add stops count
      this.direction2Count = countStops.length;
    } else {
      //add stops count
      this.direction1Count = countStops.length;
    }

    this.filteredOptions = new Observable<[]>();
  }

  addMarkStop(selectedStops) {
    console.log('addMarkStop: selectedStops - ', selectedStops);
    let currLayerName = 'pathStreet-' + this.currDirection;
    let layerToAppend: VectorLayer<any> | VectorImageLayer<any>;

    this.map.getLayers().forEach(function (layer) {
      let layerName = layer.get('name');
      if (
        layerName === currLayerName &&
        (layer instanceof VectorLayer || layer instanceof VectorImageLayer)
      ) {
        layerToAppend = layer;
      }
    });

    let source = layerToAppend.getSource();
    let featureCount = source.getFeatures().length + 1 ?? 1;

    var firstStop = selectedStops[0]; //selectedStops.shift();
    var lastStop = selectedStops[selectedStops.length - 1]; //selectedStops.pop();
    var featureList = [];

    firstStop.idx = 0;
    firstStop.imgSrc = 'assets/images/icon/map-marker-red.png';
    firstStop.zIndex = 2;
    firstStop.direction = this.currDirection;
    var firstFeature = this.iconMap(
      firstStop,
      featureCount + selectedStops.length + 1
    );
    featureList.push(firstFeature);

    lastStop.idx = selectedStops.length - 1; //add first and last stop
    lastStop.imgSrc = 'assets/images/icon/map-marker-red.png';
    lastStop.zIndex = 2;
    lastStop.direction = this.currDirection;
    var lastFeature = this.iconMap(lastStop);
    featureList.push(lastFeature, featureCount + selectedStops.length);

    var idxCount: number = 0;
    selectedStops.forEach((stop, index) => {
      if (index === 0) {
        // || (selectedStops.length - 1) === index) {
        idxCount++;
      }
      stop.idx = idxCount += 1;
      stop.imgSrc = 'assets/images/icon/map-marker-green.png';
      stop.zIndex = 1;
      stop.direction = this.currDirection;
      featureCount += 1;
      let iconFeature = this.iconMap(stop, featureCount);
      featureList.push(iconFeature);
    });

    source.addFeatures(featureList);
  }

  addOtherMarkStop(selectedStops) {
    console.log('addOtherMarkStop: ', selectedStops);
    var otherDirection = 1;
    if (this.currDirection === 1) {
      otherDirection = 2;
    }

    // var otherDirectionLayerName = 'stopOtherDirection-'+otherDirection;
    var currDirectionLayerName = 'stopOtherDirection-' + this.currDirection;

    this.removeLayer(currDirectionLayerName);

    let layerToAppend = new VectorImageLayer({
      source: new VectorSource({
        // features: featursList
      }),
      className: currDirectionLayerName,
      properties: {
        name: currDirectionLayerName,
      },
    });

    const source = layerToAppend.getSource();
    let featureCount = source.getFeatures().length ?? 0;

    var featureList = [];

    // var idxCount:number = 0;
    selectedStops.forEach((stop, index) => {
      // if(index === 0){// || (selectedStops.length - 1) === index) {
      //   idxCount++;
      // }
      stop.idx = stop?.sequence ?? ''; //idxCount += 1;
      stop.imgSrc = 'assets/images/icon/cirle-gray-bus.png';
      stop.zIndex = 1;
      stop.direction = otherDirection;
      featureCount += 1;
      let iconFeature = this.iconMap(stop, featureCount);
      featureList.push(iconFeature);
    });

    source.addFeatures(featureList);
    this.map.addLayer(layerToAppend);
  }

  iconMap(data, zIndex = 0) {
    // console.log('data icon', data);
    const { direction = 0 } = data || {};
    // let count = index + 1;
    let iconFeature = new OlFeature({
      geometry: new Point(
        transform(
          [parseFloat(data.stopLon), parseFloat(data.stopLat)],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      name: 'stop-' + data.stopCode,
      dataObj: data,
    });

    const textData = data.idx.toString()?.trim();
    // if (data.idx) {
    //   textData =
    //     data.idx < 10 ? '0' + data.idx.toString() : data.idx.toString();
    // }

    const markerSrc =
      direction === 1 || direction === 2
        ? `assets/images/icon/marker-location-dir-${direction}.svg`
        : 'assets/images/icon/marker-location-red.svg';

    const pinStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        // displacement: [0, 0],
        // imgSize: [24, 24],
        opacity: 1,
        scale: 0.9,
        src: markerSrc,
      }),
      zIndex: zIndex,
    });

    const textStyle = new Style({
      zIndex: zIndex,
      // image: new Circle({
      //   displacement: [0, 24],
      //   radius: 14,
      //   stroke: new Stroke({
      //     color: '#fff',
      //     width: 2,
      //   }),
      //   fill: new Fill({
      //     color: '#49d4e3',
      //   }),
      // }),
      text: new Text({
        offsetY: -20,
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'bottom',
        text: textData,
        fill: new Fill({
          color: '#333',
        }),
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
      }),
    });

    // let iconStyle = new Style({
    //   image: new Icon(
    //     /** @type {module:ol/style/Icon~Options} */ {
    //       anchor: [0.5, 25],
    //       anchorXUnits: 'fraction',
    //       anchorYUnits: 'pixels',
    //       opacity: 1,
    //       // src: 'assets/images/icon/map-marker-green.png'
    //       src: data.imgSrc,
    //     }
    //   ),
    //   // text: new Text({
    //   //   offsetY: -37,
    //   //   // offsetX: 5,
    //   //   text: data.idx.toString(),
    //   //   fill: new Fill({
    //   //     color: '#fff'
    //   //   }),
    //   //   backgroundFill: new Fill({
    //   //     color: '#2a2b30'
    //   //   }),
    //   //   font: '16px Calibri,sans-serif',
    //   //   padding: [2, 5, 2, 5]
    //   // }),
    //   text: new Text({
    //     offsetY: -35,
    //     text: textData,
    //     fill: new Fill({
    //       color: '#000',
    //     }),
    //     padding: [5, 5, 5, 5],
    //     font: 'bold 12px Arial, Verdana, Helvetica, sans-serif',
    //     stroke: new Stroke({
    //       color: '#49d4e3',
    //       width: 20,
    //     }),
    //   }),
    //   zIndex: data.zIndex,
    // });
    iconFeature.setStyle([pinStyle, textStyle]);
    return iconFeature;
  }

  cancelNewSearch() {
    // this.selectedStreetData = {};

    this.selectedStreetDirection1Data = {};
    this.selectedStreetDirection2Data = {};
    this.isSearchStreet = false;
    this.selectedStopCount = 0;
    this.isEverySearchLock = false;
    this.filteredOptions = undefined;

    // this.searchForm.value.streetName = '';
    // this.searchForm.controls['streetName'].setValue('');
    // this.searchForm.get('streetName').setValue('');
    this.searchForm.get('streetName').reset();
    // console.log('clear input');
  }

  hideLayer(layerName) {
    this.map.getLayers().forEach(function (layer) {
      let layerNameMap = layer.get('name');
      if (layerName === layerNameMap) {
        layer.setVisible(!layer.getVisible());
      }
    });
  }

  removeLayer(layerName) {
    this.map
      .getLayers()
      .getArray()
      .filter(layer => layer.get('name') === layerName)
      .forEach(layer => this.map.removeLayer(layer));
  }

  changeDirection(direction) {
    if (direction === this.currDirection || this.isLoop) {
      return false;
    }
    // //hide layer
    // this.hideLayer('pathStreet-'+this.currDirection);

    // //override variable
    this.currDirection = direction;
    this.directionProvider = ''; // TODO: reset direction provider or not?

    this.cancelNewSearch();

    // //overridden, new layer name
    // var currLayerName = 'pathStreet-'+this.currDirection;
    // var isCurrLayerExisting: boolean = false;

    // //unhide new layer
    // this.hideLayer(currLayerName);

    // this.map.getLayers().forEach(function (layer) {
    //   let layerName = layer.get('name');
    //   if(layerName === currLayerName) {
    //     isCurrLayerExisting = true;
    //   }
    // });

    this.map
      .getLayers()
      .getArray()
      .filter(
        layer =>
          layer.get('name') === 'pathStreet-1' ||
          layer.get('name') === 'pathStreet-2' ||
          layer.get('name') === 'stopOtherDirection-1' ||
          layer.get('name') === 'stopOtherDirection-2'
      )
      .forEach((layer2: VectorImageLayer<any> | VectorLayer<any>) => {
        // this.map.removeLayer(layer)
        let features = layer2.getSource().getFeatures();
        features.forEach(element => {
          this.mapService.removeLayerFeature(layer2, element.get('name'));
        });
      });

    // if(!isCurrLayerExisting) {
    //   console.log('create new layer ' + currLayerName);
    // this.drawStPathMapBox(this.selectedStreetData);
    // }
    var startPoint = this.searchForm.value.startPoint;
    var endPoint = this.searchForm.value.endPoint;
    // if(direction === 1) {
    this.searchForm.controls['startPoint'].setValue(endPoint);
    this.searchForm.controls['endPoint'].setValue(startPoint);
    // }
    // else {
    //   this.searchForm.controls['startPoint'].setValue(endPoint);
    //   this.searchForm.controls['endPoint'].setValue(startPoint);
    // }

    this.directionProvider = 'heremap'; //default TODO replace here with current direction

    const currDirectionData = this.getSelectedFlatStops(
      this.getCurrentDirectionData().directionData
    );

    if (this.isModify && currDirectionData?.listStop?.length > 0) {
      this.routeHereDirection(currDirectionData.listStop);
      // this.onChangeDirectionProvider(this.directionProvider); // use current direction
    }
    // if (this.isModify && direction === 2) {
    //   // console.log(1);
    //   // this.drawStPathMapManually(this.selectedStreetDirection2PathData,this.direction2Data);
    //   this.routeHereDirection(
    //     this.getSelectedFlatStops(this.getCurrentDirectionData().directionData)
    //       .listStop
    //   );
    // } else if (this.isModify && direction === 1) {
    //   // console.log(2);
    //   // this.drawStPathMapManually(this.selectedStreetDirection1PathData,this.direction1Data);
    //   this.routeHereDirection(
    //     this.getSelectedFlatStops(this.getCurrentDirectionData().directionData)
    //       .listStop
    //   );
    // }
  }

  initMapInteraction() {
    var me = this;
    this.map.on('pointermove', e => {
      if (e.dragging) {
        return;
      }
      var pixel = e.map.getEventPixel(e.originalEvent);
      var hit = false;
      var dataObj = undefined;

      e.map.forEachFeatureAtPixel(
        pixel,
        function (feature, layer) {
          let layerName = layer.get('name');
          let featureName = feature.get('name');
          // let routeName = me.ladders.routeId === undefined ? me.ladders.route[0] : me.ladders.routeId[0];

          if (
            layerName &&
            layerName.includes('pathStreet-' + me.currDirection)
          ) {
            hit = true;
            dataObj = feature.get('dataObj');
          }
        },
        {
          hitTolerance: 3,
        }
      );
      e.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    this.map.on('singleclick', evt => {
      let feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        },
        {
          hitTolerance: 3,
        }
      );
      if (feature) {
        let featureName = feature.get('name') ?? '';

        if (featureName.includes('stop')) {
          me.isPanel = true;
          me.panelData = feature.get('dataObj');
          this.openBusStopEvent(me.panelData);
        }
      }
      evt.preventDefault(); //add this to avoid bubbling

      //start drag and drop

      // var iconFeature = new OlFeature({
      //   geometry: new Point(evt.coordinate),
      // });
      // iconFeature.setStyle(feature.iconStyle);
      // this.map.rasterVectorSource.addFeature(iconFeature);

      // let modify = new OlMap.interaction.Modify({
      //   features: new OlMap.Collection([iconFeature])
      // });

      // iconFeature.on('change',function(){
      //   console.log('Feature Moved To:' + this.getGeometry().getCoordinates());
      // },iconFeature);

      // this.map.addInteraction(modify);
    });
    // this.dragIcon();
  }

  // dragIcon() {
  //   // map = new OpenLayers.Map("mapdiv");
  //   this.map.addLayer(new OpenLayers.Layer.OSM());

  //   var lonLat = new OpenLayers.LonLat( 72.91152, 19.11186 )
  //     .transform(
  //       new OpenLayers.Projection("EPSG:4326"), // transform from WGS 1984
  //       this.map.getProjectionObject() // to Spherical Mercator Projection
  //     );

  //   var zoom=16;
  //   var osm = new OpenLayers.Layer.OSM();
  //   var vectors = new OpenLayers.Layer.Vector("Vector Layer");
  //   this.map.addLayers([osm, vectors]);

  //   var markers = new OpenLayers.Layer.Markers( "Markers" );
  //   this.map.addLayer(markers);

  //   var size = new OpenLayers.Size(25,37);
  //   var offset = new OpenLayers.Pixel(-(size.w/2), -size.h);
  //   var mymarker = new OpenLayers.Icon('assets/images/icon/location-green-32.png', size, offset);
  //   markers.addMarker(new OpenLayers.Marker(lonLat, mymarker));

  //   var point = new OpenLayers.Geometry.Point(lonLat.lon,lonLat.lat);
  //   vectors.addFeatures([new OpenLayers.Feature.Vector(point)]);
  //   var drag = new OpenLayers.Control.DragFeature(vectors, {autoActivate: true});
  //   this.map.addControl(drag);
  // }

  // showMap() {
  //   var directionData = this.direction1Data;

  //   if(this.currDirection !== 1) {
  //     directionData = this.direction2Data;
  //   }

  //   if(directionData[0] === undefined) {
  //     return false;
  //   }

  //   const dialogRef = this.dialog.open(MapModeModalComponent, {
  //     // width: '250px',
  //     data: directionData[0]
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     // this.animal = result;
  //   });
  // }

  isOpenBusStopEventRunning = false;
  openBusStopEvent(featureData) {
    if (this.isOpenBusStopEventRunning) return;
    this.isOpenBusStopEventRunning = true;
    this.stopManagementService
      .getRoutesByStopCode(featureData.stopCode)
      .subscribe(
        (data: any) => {
          this.panelData.busService = data.busService;
          this.isOpenBusStopEventRunning = false;
        },
        e => {
          console.error('Bus Stop Event error: ', e);
          this.isOpenBusStopEventRunning = false;
        }
      );
  }

  onReset() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        header: 'Cancel',
        message:
          'Are you sure you want to cancel? All unsaved data will be lost.',
        mode: 'warn',
        textCancel: 'No',
        textConfirm: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.reset();
      }
    });
  }

  reset() {
    this.searchForm.reset();
    this.cancelNewSearch();

    this.direction1Data = [];
    this.direction1DataPath = [];
    this.direction1Distance = [];
    this.selectedStreetDirection1PathData = {};
    this.direction1Count = 0;

    this.direction2Data = [];
    this.direction2Distance = [];
    this.direction2DataPath = [];
    this.selectedStreetDirection2PathData = {};
    this.direction2Count = 0;

    this.isServiceExist = false;

    this.isLoop = true;
    this.resultServiceSearch = {};

    this.directionProvider = '';

    this.mode = 'reset';
    this.isExpand = false;

    this.map
      .getLayers()
      .getArray()
      .filter(
        layer =>
          layer.get('name') === 'pathStreet-1' ||
          layer.get('name') === 'pathStreet-2' ||
          layer.get('name') === 'stopOtherDirection-1' ||
          layer.get('name') === 'stopOtherDirection-2'
      )
      .forEach(layer => this.map.removeLayer(layer));

    this.viewAnimate.animate({
      center: fromLonLat(environment.map.locationLonLat),
      duration: 1000,
      zoom: 12,
    });
  }

  chunked(array, chunkSize) {
    var retArr = [];
    // var i2 = 0;
    for (var i = 0; i < array.length - (chunkSize - 1); i++) {
      // console.log(i, i+chunkSize);
      if (i !== 0) {
        i += 23; //if chunked by 25
        // i++; /if chunked by 3
      }
      // console.log(array.slice(i, chunkSize-i));
      // i2+=1;
      // console.log(array.slice(i2, chunkSize+i));

      retArr.push(array.slice(i, chunkSize + i));
      // i2++;
    }
    return retArr;
  }

  chunkedByGroup(data) {
    var perChunk = 25; // items per chunk
    var retData = [];
    if (data.length < 25) {
      retData = data.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    } else {
      retData = this.chunked(data, perChunk);
    }
    return retData;
  }

  // onSave() {
  //   // var startPoint = 'Start Point';
  //   // var endPoint = 'End Point';

  //   // var startPoint = '';//this.direction1Data[0].stops[0].stopName;
  //   // var endPoint = '';//this.direction1Data.slice(-1)[0].stops.slice(-1)[0].stopName;

  //   // if(this.currDirection === 1) {
  //   //   startPoint = this.direction1Data[0].stops[0].stopName;
  //   //   endPoint = this.direction1Data.slice(-1)[0].stops.slice(-1)[0].stopName;
  //   // }
  //   // else {
  //   //   startPoint = this.direction2Data[0].stops[0].stopName;
  //   //   endPoint = this.direction2Data.slice(-1)[0].stops.slice(-1)[0].stopName;
  //   // }

  //   // console.log(startPoint, endPoint);

  //   var serviceData = {
  //     'routeId': this.searchForm.value.searchService,
  //     'navigation': []
  //   };

  //   this._snackBar.open('Working...');

  //   var listStopCode:any = [];
  //   var listStopChecked:any = [];

  //   this.direction1Data.forEach(element => {
  //     element.stops.forEach(stop => {
  //       if(stop.isChecked) {
  //         listStopCode.push(stop.stopCode);
  //         listStopChecked.push(stop);
  //       }
  //     });
  //     // listStopCode.push(element.stops.map(a => a.stopCode));
  //   });

  //   var result = this.chunkedByGroup(listStopChecked);

  //   var stopsLonLat:any = [];
  //   var replaceStr = /undefined/gi;

  //   result.forEach((element,index) => {
  //     element.forEach(element2 => {
  //       stopsLonLat[index] += element2.stopLon +','+ element2.stopLat+';';
  //     });
  //     if(stopsLonLat[index]) {
  //       stopsLonLat[index] = stopsLonLat[index].replace(replaceStr, "").slice(0,-1);
  //     }
  //   });

  //   // console.log(stopsLonLat);
  //   // return false;

  //   // var test2 = [];
  //   var coordsDirection1 = [];
  //   // var test5 = [];
  //   // stopsLonLat.forEach((element,idx) => {

  //   // this.stopManagementService.getDirection('driving', element).then(
  //   //   (directionRes:any) => {

  //   //     console.log(idx + ' = ' + stopsLonLat.length);
  //   //     test2[idx] = directionRes;
  //   //     if(idx === stopsLonLat.length-1) {
  //   //       test2.forEach(element => {
  //   //         element.subscribe(
  //   //           (dataRes3:any) => {
  //   //             console.log(dataRes3);
  //   //             test5.push(dataRes3);
  //   //           }
  //   //         )
  //   //       });

  //   //     }
  //   //   }
  //   // )
  // // });
  //   var delayTimer = stopsLonLat.length * 500;

  //   this.stopManagementService.getDirection2('driving', stopsLonLat).then(
  //     (directionRes:any) => {
  //       setTimeout(() => {
  //         // console.log(directionRes);
  //         if(directionRes.success instanceof HttpErrorResponse) {
  //           this._snackBar.open('Stops must be more than 1', null, {
  //             duration: 2000,
  //           });
  //           return false;
  //         }

  //         directionRes.forEach((element:any) => {
  //           let coords = element.routes[0].geometry.coordinates;
  //           coords.forEach((element2:any) => {
  //             coordsDirection1.push([parseFloat(element2[1]),parseFloat(element2[0])]);
  //           });
  //         });

  //         let startPoint = this.direction1Data[0].stops[0].stopName;
  //         let endPoint = this.direction1Data.slice(-1)[0].stops.slice(-1)[0].stopName;

  //         var direction1 = {
  //           'direction': 1,
  //           'path': coordsDirection1, //remove temp
  //           'stops': listStopCode,
  //           'routeHeadSign': startPoint + ' - ' + endPoint
  //         }
  //         serviceData.navigation.push(direction1);

  //         var urlServiceAction = 'gtfsupload/v1/createServiceRoute';
  //         if(this.isServiceEdit) {
  //           urlServiceAction = 'gtfsupload/v1/updateServiceRoute';
  //         }

  //         //start direction 2
  //         if(!this.isLoop) {

  //           var listStopCode2:any = [];
  //           var listStopChecked2:any = [];

  //           this.direction2Data.forEach(element => {
  //             element.stops.forEach(stop => {
  //               if(stop.isChecked) {
  //                 listStopCode2.push(stop.stopCode);
  //                 listStopChecked2.push(stop);
  //               }
  //             });
  //           });

  //           let result2 = this.chunkedByGroup(listStopChecked2);

  //           var stopsLonLat2:any = [];
  //           // var replaceStr = /undefined/gi;

  //           result2.forEach((element,index) => {
  //             element.forEach(element2 => {
  //               stopsLonLat2[index] += element2.stopLon +','+ element2.stopLat+';';
  //             });
  //             stopsLonLat2[index] = stopsLonLat2[index].replace(replaceStr, "").slice(0,-1);
  //           });
  //           // console.log(result2);
  //           // console.log(stopsLonLat2);

  //           var coordsDirection2 = [];

  //           var delayTimer2 = stopsLonLat.length * 500;

  //           this.stopManagementService.getDirection2('driving', stopsLonLat2).then(
  //             (directionRes2:any) => {
  //               setTimeout(() => {
  //                 if(directionRes2[0] == undefined) {
  //                   this._snackBar.open(directionRes2.success.error.message, null, {
  //                     duration: 2000,
  //                   });
  //                   return false;
  //                 }
  //                 directionRes2.forEach((element:any) => {
  //                   let coords = element.routes[0].geometry.coordinates;
  //                   coords.forEach((element2:any) => {
  //                     coordsDirection2.push([parseFloat(element2[1]),parseFloat(element2[0])]);
  //                   });
  //                 });

  //                 let startPoint2 = this.direction2Data[0].stops[0].stopName;
  //                 let endPoint2 = this.direction2Data.slice(-1)[0].stops.slice(-1)[0].stopName;

  //                 var direction2 = {
  //                   'direction': 2,
  //                   'path': coordsDirection2, //remove temp
  //                   'stops': listStopCode2,
  //                   'routeHeadSign': startPoint2 + ' - ' + endPoint2
  //                 }
  //                 serviceData.navigation.push(direction2);

  //                 this.setServiceMgtData(urlServiceAction, serviceData);
  //               }, delayTimer2);
  //           },
  //           (err:any) => {
  //             console.log(err);
  //           });
  //         }
  //         //end direction 2
  //         else { //only direction 1
  //           this.setServiceMgtData(urlServiceAction, serviceData);
  //         }
  //       }, delayTimer);
  //   });

  // }

  // selectedFlatStops:any = [];
  onSave2() {
    const selectedStopsCode = this.getSelectedFlatStops(this.direction1Data);
    const direction1FirstStop = selectedStopsCode.listStop[0];
    const direction1LastStop =
      selectedStopsCode.listStop[selectedStopsCode.listStop.length - 1];
    const navigationData = [];
    const direction1Path =
      selectedStopsCode.listStopCode?.length > 0 ? this.direction1DataPath : [];

    navigationData.push({
      routeHeadSign:
        (direction1FirstStop === undefined
          ? ''
          : direction1FirstStop.stopName + ' - ') +
        (direction1LastStop === undefined ? '' : direction1LastStop.stopName),
      direction: 1,
      path: direction1Path,
      stops: selectedStopsCode.listStopCode,
      distance: this.direction1Distance,
    });

    //start direction 2
    if (!this.isLoop) {
      const direction2SelectedStopsCode = this.getSelectedFlatStops(
        this.direction2Data
      );
      const direction2FirstStop = direction2SelectedStopsCode.listStop[0];
      const direction2LastStop =
        direction2SelectedStopsCode.listStop[
          direction2SelectedStopsCode.listStop.length - 1
        ];
      const direction2Path =
        direction2SelectedStopsCode.listStopCode?.length > 0
          ? this.direction2DataPath
          : [];
      navigationData.push({
        routeHeadSign:
          (direction2FirstStop === undefined
            ? ''
            : direction2FirstStop.stopName + ' - ') +
          (direction2LastStop === undefined ? '' : direction2LastStop.stopName),
        direction: 2,
        path: direction2Path,
        stops: direction2SelectedStopsCode.listStopCode,
        distance: this.direction2Distance,
      });
    }

    var serviceData = {
      routeId:
        this.mode === 'new'
          ? this.searchForm.value.newService
          : this.searchForm.value.searchService,
      navigation: navigationData,
    };

    var urlServiceAction = 'gtfsupload/v1/createServiceRoute';
    if (this.mode === 'edit') {
      urlServiceAction = 'gtfsupload/v1/updateServiceRoute';
    }

    // console.log('service data: ', serviceData);

    // this.setServiceMgtData(urlServiceAction, serviceData);
    this.commonService
      .commonPostAction(urlServiceAction, serviceData, 'text') // gtfupload returns string instead of JSON
      .subscribe(
        (data: any) => {
          const currMode = this.mode === 'new' ? 'Create' : 'Update';
          console.log(currMode, data);
          this._snackBar.dismiss();
          this._snackBar.open(
            `Service Route has been successfully ${
              this.mode === 'new' ? 'created' : 'updated'
            }.`,
            null,
            {
              duration: 3000,
            }
          );
          if (this.mode === 'new') {
            this.searchForm.controls['searchService'].setValue(
              this.searchForm.value.newService
            );
          }
          this.searchServiceRoute();

          this.mode = 'edit';
        },
        (err: any) => {
          console.error('Service Error: ', err);
          this._snackBar.dismiss();

          this._snackBar.open(
            `There was an issue ${
              this.mode === 'new' ? 'creating' : 'updating'
            } the service. Please try again.`,
            null,
            {
              duration: 3000,
            }
          );
        }
      );
  }

  getCurrentDirectionData() {
    var directionData: any;
    var directionPath: any;
    if (this.currDirection === 1) {
      directionData = this.direction1Data;
      directionPath = this.direction1DataPath;
    } else {
      directionData = this.direction2Data;
      directionPath = this.direction2DataPath;
    }
    return {
      directionData: directionData,
      directionPath: directionPath,
    };
  }

  getSelectedFlatStops(directionData) {
    var listStopCode: any = [];
    var listStopChecked: any = [];
    directionData.forEach(element => {
      element.stops.forEach(stop => {
        if (stop.isChecked) {
          listStopCode.push(stop.stopCode);
          listStopChecked.push(stop);
        }
      });
      // listStopCode.push(element.stops.map(a => a.stopCode));
    });
    return {
      listStopCode: listStopCode,
      listStop: listStopChecked,
    };
  }

  setServiceMgtData(urlServiceAction, serviceData) {
    // var urlAction = 'gtfsupload/v1/stops/getStopDetail/' + this.streetNameBusStop;
    this.commonService
      .commonPostAction(urlServiceAction, serviceData)
      // this.stopManagementService.setServiceManagement(urlServiceAction, serviceData)
      .subscribe(
        (data: any) => {
          // this.openInfoDialog();
          this._snackBar.dismiss();
          this._snackBar.open('Service successfully added/updated!', null, {
            duration: 2000,
          });
          // this.searchServiceRoute();
          // this.updateDirectionData(this.direction2Data);
          // this.searchServiceRoute();
          // console.log(data);
          // if(this.currDirection === 1)  {
          //   this.direction1Data = this.updateDirectionData(this.direction1Data);
          // }
          // else {
          //   this.direction2Data = this.updateDirectionData(this.direction2Data);
          // }
          this.searchServiceRoute();
          // this.onChangeDirectionProvider(this.directionProvider);
        },
        (err: any) => {
          // console.log(err);
          this._snackBar.dismiss();

          this._snackBar.open('Service successfully added/updated!', null, {
            duration: 2000,
          });
          // if(this.currDirection === 1)  {
          //   this.direction1Data = this.updateDirectionData(this.direction1Data);
          //   this.updateDirectionStopsData(this.selectedStreetDirection1Data);
          // }
          // else {
          //   this.direction2Data = this.updateDirectionData(this.direction2Data);
          // }
          this.searchServiceRoute();
          // this.onChangeDirectionProvider(this.directionProvider);
          // console.log(this.currDirection);
          // this.searchServiceRoute();
          // changeDirection
          // this.searchServiceRoute();
        }
      );
  }

  updateDirectionStopsData(selectedStreetData) {
    // console.log(selectedStreetData);
    // selectedStreetData.forEach(element => {
    selectedStreetData.stops.forEach((stops, stopIdx) => {
      if (!stops.isChecked) {
        // console.log(stopIdx, stops);
        // console.log(stops);
        selectedStreetData.stops.splice(stopIdx, 1);
      }
    });
    // });
    // console.log(selectedStreetData);
  }

  updateDirectionData(directionData) {
    // console.log(this.direction2Data);
    // this.direction1Data.forEach(element => {
    //   element.stops.forEach((stops, stopIdx) => {
    //     if(!stops.isChecked) {
    //       // console.log(stopIdx, stops);
    //       // console.log(element.stops);
    //       element.stops.splice(stopIdx, 1);
    //     }
    //   });
    // });

    // directionData.forEach(element => {
    //   element.stops.forEach((stops, stopIdx) => {
    //     // console.log(stops.isChecked);
    //     if(stops.isChecked === false) {
    //       // console.log(stopIdx, stops);
    //       // console.log(element.stops);
    //       element.stops.splice(stopIdx, 1)
    //       // object.splice(stopIdx, 1)
    //     }
    //   });
    // });
    var test1 = [];
    directionData.forEach((element, idx) => {
      test1[idx] = {};
      test1[idx].stops = [];
      element.stops.filter(function (stopElem) {
        if (stopElem.isChecked) {
          // console.log(test1[idx].stops);
          // directionData[idx].streetName = stopElem.streetName;
          // if(directionData[idx].stops === undefined) {
          //   directionData[idx].stops = [];
          // }
          test1[idx].streetName = stopElem.streetName;
          test1[idx].stops.push(stopElem);
          // return stopElem;
        }
        // stops.splice(stopIdx, 1)
        // return stops;
      });
    });
    // console.log(directionData);
    // console.log(test1);
    return test1;

    // this.direction2Data.map((elemnent, stopIdx) => {
    //   let ObjStops = elemnent.stops;
    //   ObjStops.map((stops,idx) => {
    //     if(!stops.isChecked) {
    //       console.log(idx, stops);
    //       // ObjStops.splice(idx, 1)
    //       this.direction2Data[stopIdx].stops.splice(idx, 1);
    //       console.log(this.direction2Data[stopIdx]);
    //     }
    //   })
    // })

    // for (let index = 0; index < this.direction2Data.length; index++) {
    //   const element = this.direction2Data[index].stops;
    //   for (let j = 0; j < element.length; j++) {
    //     // const element2 = j[index];
    //     let stops = element[j];
    //     if(!stops.isChecked) {
    //       console.log(stops);
    //       console.log(element);
    //       element.splice(j, 1)
    //     }
    //   }
    // }
  }

  selectedStreetDirection1Data: any = {};
  selectedStreetDirection2Data: any = {};
  selectedStreetDirection2PathData: any = {};
  selectedStreetDirection1PathData: any = {};
  resultServiceSearch: any = {};
  direction1Count: number = 0;
  direction2Count: number = 0;
  searchServiceRoute(event?) {
    // if (this.mode === 'new' || this.mode === 'edit') return; // do not search if new service

    if (event) {
      event?.preventDefault();
      event?.stopPropagation();
    }

    this.running = true;
    this.options = [];
    //remove existing layers
    this.map
      .getLayers()
      .getArray()
      .filter(
        layer =>
          layer.get('name') === 'pathStreet-1' ||
          layer.get('name') === 'pathStreet-2' ||
          layer.get('name') === 'stopOtherDirection-1' ||
          layer.get('name') === 'stopOtherDirection-2'
      )
      .forEach((layer2: VectorImageLayer<any> | VectorLayer<any>) => {
        // this.map.removeLayer(layer)
        let features = layer2.getSource().getFeatures();
        features.forEach(element => {
          this.mapService.removeLayerFeature(layer2, element.get('name'));
        });
      });

    this.direction1Data = [];
    this.direction2Data = [];
    this.direction1DataPath = [];
    this.direction2DataPath = [];
    this.selectedStreetDirection1Data = {};
    this.selectedStreetDirection2Data = {};
    this.isLoop = true;
    this.isEverySearchLock = false;
    this.directionProvider = '';
    this.routeType = 'motorway';
    this.direction1Count = 0;
    this.direction2Count = 0;
    this.direction1Distance = [];
    this.direction2Distance = [];

    this.stopManagementService
      .getSearchServiceRoute(this.searchForm.value.searchService)
      .subscribe(
        (data: any) => {
          if (data.length === 0) {
            this._snackBar.open(
              'Service line ' +
                this.searchForm.value.searchService +
                ' does not exist.',
              null,
              {
                duration: 2000,
              }
            );
            this.running = false;
            let searchValue = this.searchForm.value.searchService;
            this.reset();
            this.searchForm.controls['searchService'].setValue(searchValue);
            return false;
          }
          this.resultServiceSearch = data;
          console.log('result service', data);
          let direction1Len = data.navigation[0].stops.length - 1;
          // let headSign = data.navigation[0].routeHeadSign.split('-');
          let startPoint = this.busIntTer.find(
            x => x.stopCode === data.navigation[0].stops[0]
          );
          let endPoint = this.busIntTer.find(
            x => x.stopCode === data.navigation[0].stops[direction1Len]
          );

          if (data.navigation.length === 1) {
            this.searchForm.controls['endPoint'].setValue(startPoint);
          } else {
            this.searchForm.controls['endPoint'].setValue(endPoint);
          }

          this.searchForm.controls['startPoint'].setValue(startPoint);
          // let isLoop = data.navigation.length === 1 ? true : false;
          // let startPoint = '';
          // let endPoint = '';
          // this.selectedStreetData.stops = [];

          data.navigation.forEach(element => {
            // element.stops.forEach(elementStop => {
            //   console.log(elementStop);
            //   elementStop.replace(" ","");
            // });
            // let headSign = element.routeHeadSign.split('-');
            // startPoint = headSign[0].trim();
            // endPoint = isLoop ? headSign[0].trim() : headSign[1].trim();
            if (element.direction === 1) {
              this.direction1DataPath = element.path;
              this.direction1Distance = element?.distance ?? [];
            } else {
              this.direction2DataPath = element.path;
              this.direction2Distance = element?.distance ?? [];
            }

            this.stopManagementService
              .getMultipleStops(element.stops)
              .subscribe((res: any) => {
                this.isServiceExist = true;
                // this.isSearchStreet = true; // why turn on isSearchStreet ?
                this.isSearchStreet = false;
                let street: any = [];

                res.forEach((stop, index) => {
                  // if(index >= 10) {
                  //   return false;
                  // }
                  stop.isChecked = true;
                  let prev = res[index - 1];

                  if (
                    prev !== undefined &&
                    prev.streetName === stop.streetName
                  ) {
                    street.slice(-1)[0].stops.push(stop);
                  } else {
                    let streetTest: any = {};
                    streetTest.streetName = stop.streetName;
                    streetTest.stops = [];
                    streetTest.stops.push(stop);
                    street.push(streetTest);
                  }
                  stop.sequence = index + 1;
                });
                // console.log(element);
                // console.log(street);
                if (element.direction === 1) {
                  this.currDirection = 1;
                  this.direction1Data = street;
                  this.selectedStreetDirection1Data.stops = res;
                  // this.drawStPathMapManually(element, street);
                  this.drawStPathMapManually(element, street); //temp remove
                  this.selectedStreetDirection1PathData = element;
                  this.direction1Count = element.stops.length;

                  // this.directionProvider = 'heremap';
                  // this.onChangeDirectionProvider('heremap');
                } else {
                  // this.currDirection = 2;
                  this.direction2Data = street;
                  this.selectedStreetDirection2Data.stops = res;
                  this.isLoop = false;
                  this.selectedStreetDirection2PathData = element;
                  this.direction2Count = element.stops.length;
                }
                // this.mode = 'view'; // should default to view mode at first
                this.mode = 'edit';
                // this.routeHereDirection();
              });
          });

          this.running = false;
          // console.log(
          //   'direction distance',
          //   this.direction1Distance,
          //   this.direction2Distance
          // );
          // this._snackBar.dismiss();
        },
        (err: HttpErrorResponse) => {
          console.error('getSearchServiceRoute err', err);
          const { errorMessage, response: errResponse } = err?.error || {};
          this._snackBar.open(
            errorMessage || errResponse || err.statusText,
            null,
            {
              duration: 5000,
              panelClass: 'custom-snack-bar-panel-error',
            }
          );
          this.running = false;
          console.log(err);
        }
      );
  }

  clearServiceSearch(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.searchForm.controls.searchService.reset();
  }

  removePathLayers() {
    this.removeLayer('pathStreet-1');
    this.removeLayer('pathStreet-2');
    this.removeLayer('stopOtherDirection-1');
    this.removeLayer('stopOtherDirection-2');
  }

  drawStPathMapManually(selectedStops, stopData) {
    // console.log('drawStPathMapManually', selectedStops, stopData);
    this.removePathLayers();
    const { direction } = selectedStops || {};
    if (!direction) {
      console.log('Manual Path - No path to draw');
      return;
    } // no selected stop
    // this.removeLayer('pathStreet-' + direction);

    var vectorLayer = new VectorImageLayer({
      source: new VectorSource(),
      className: 'pathStreet-' + direction,
      properties: {
        name: 'pathStreet-' + direction,
      },
    });
    this.map.addLayer(vectorLayer);

    if (stopData) {
      var listStops: any = [];
      stopData.forEach(element => {
        listStops.push(element.stops);
      });

      // test start generate lat lon
      // console.log(listStops.flat());
      // // let latLonData = [];
      // let latLonData = '';

      // listStops.flat().forEach(element => {
      //   // latLonData.push([element.stopLat, element.stopLon]);
      //   let test1 = element.stopLat +' '+ element.stopLon +', ';
      //   latLonData += test1
      // // console.log(test1);
      // });
      // // console.log(JSON.stringify(latLonData));
      // console.log(latLonData);
      // test end generate lat lon

      this.addMarkStopManually(listStops.flat(), selectedStops.direction);

      // this.markOtherDirection();
    }

    if (selectedStops.path === null) {
      return false;
    }
    // console.log(selectedStops.direction, stopData);
    // var stopsLonLat:string='';

    // selectedStops.forEach((element,index) => {
    //   stopsLonLat += element.stopLon +','+ element.stopLat+';';
    // });
    // console.log(selectedStops);
    // return false;

    // var stopPoints = stopsLonLat.slice(0,-1);
    var stopsData = [];

    // this.stopManagementService.getDirection('driving', stopPoints)
    // .subscribe(
    //   (data: any) => {
    // stopsData = data.routes[0].geometry.coordinates;
    stopsData = selectedStops.path;

    var coordsArray = [];
    let arrPaths = [];
    stopsData.forEach((element: any) => {
      coordsArray.push([parseFloat(element[1]), parseFloat(element[0])]);
    });

    //assign coords for saving
    // if(selectedStops.direction === 1) {
    //   this.direction1DataPath = coordsArray;
    // }
    // else {
    //   this.direction2DataPath = coordsArray;
    // }

    // console.log('Draw line string coordinates', coordsArray, listStops);

    var routeGeom = new LineString(coordsArray).transform(
      'EPSG:4326',
      'EPSG:3857'
    );
    var routeFeature = new OlFeature({
      type: 'route',
      geometry: routeGeom,
    });

    // console.log('dir stroke', this.currDirection);
    var style = new Style({
      stroke: new Stroke({
        color: this.currDirection === 1 ? '#111e6c' : '#3C3105',
        width: 4,
      }),
    });
    routeFeature.setStyle(style);
    arrPaths.push(routeFeature);
    let featursList = [];

    for (const path of arrPaths) {
      featursList.push(path); //route
    }
    // console.log(stopsData);
    vectorLayer.getSource().addFeatures(featursList);

    // this.viewAnimate.animate({
    //   center: fromLonLat([streetData.stops[0].stopLon, streetData.stops[0].stopLat]),
    //   duration: 1000,
    //   zoom: 16
    // });
    var extent = vectorLayer.getSource().getExtent();
    this.map
      .getView()
      .fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });

    //   }
    // );
  }

  markOtherDirection() {
    var otherDirection: any = [];
    var otherDirectionData: any;
    if (this.currDirection === 1) {
      otherDirectionData = this.direction2Data;
    } else {
      otherDirectionData = this.direction1Data;
    }

    otherDirectionData.forEach(element => {
      otherDirection.push(element.stops);
    });

    this.addOtherMarkStop(otherDirection.flat());
  }

  addMarkStopManually(selectedStops, direction) {
    // console.log('Add mark stop manually');
    var currLayerName = 'pathStreet-' + direction;
    var layerToAppend: VectorLayer<any> | VectorImageLayer<any> =
      new VectorImageLayer();
    var isExisLayer: boolean = false;

    this.map.getLayers().forEach(function (layer) {
      let layerName = layer.get('name');
      // console.log('layerName', layerName)
      if (
        layerName === currLayerName &&
        (layer instanceof VectorLayer || layer instanceof VectorImageLayer)
      ) {
        layerToAppend = layer;
        isExisLayer = true;
      } else if (isExisLayer) {
        isExisLayer = false;
      }
    });

    const source = layerToAppend.getSource();
    let featureCount = source?.getFeatures()?.length + 1 ?? 1;

    var firstStop = selectedStops.shift();
    var lastStop = selectedStops.pop();
    var featureList = [];

    if (firstStop) {
      firstStop.idx = 1;
      firstStop.imgSrc = 'assets/images/icon/map-marker-red.png';
      firstStop.zIndex = 2;
      firstStop.direction = direction;
      var firstFeature = this.iconMap(
        firstStop,
        featureCount + selectedStops.length + 2
      );
      featureList.push(firstFeature);
    }

    if (lastStop) {
      lastStop.idx = selectedStops.length + 2; //add first and last stop
      lastStop.imgSrc = 'assets/images/icon/map-marker-red.png';
      lastStop.zIndex = 2;
      lastStop.direction = direction;
      var lastFeature = this.iconMap(
        lastStop,
        featureCount + selectedStops.length + 1
      );
      featureList.push(lastFeature);
    }

    var idxCount: number = 0;
    selectedStops.forEach((stop, index) => {
      if (index === 0) {
        // || (selectedStops.length - 1) === index) {
        idxCount++;
      }
      stop.idx = idxCount += 1;
      stop.imgSrc = 'assets/images/icon/map-marker-green.png';
      stop.zIndex = 1;
      stop.direction = direction;
      featureCount += 1;
      let iconFeature = this.iconMap(stop, featureCount);

      featureList.push(iconFeature);
    });

    if (isExisLayer) {
      let source = layerToAppend.getSource();
      source.addFeatures(featureList);
    } else {
      var newLayerToAppend = new VectorImageLayer({
        source: new VectorSource({
          features: featureList,
        }),
        className: currLayerName,
        properties: {
          name: currLayerName,
        },
      });
      this.map.addLayer(newLayerToAppend);
    }
    // layerToAppend.addFeatures([new OpenLayers.Feature.Vector(featureList, null, {
    //   externalGraphic: 'assets/images/icon/map-marker-green.png',
    //   graphicWidth: 25,
    //   graphicYOffset: -30
    // })]);

    // let drag = new OpenLayers.Control.DragFeature(layerToAppend, {autoActivate: true});
    // this.map.addControl(drag);
  }

  // private _filterService(value: any): any[] {
  //   if(!value){
  //     return;
  //   }

  //   const filterValue = value.toLowerCase();
  //   return this.optionsService.filter((option:any) => {option.routeId.toLowerCase().includes(filterValue)});
  // }

  // selectedService(data) {
  //   if(data.streetName === 'Service not existing') {
  //     return false;
  //   }
  //   this.selectedServiceData = data;
  //   this.isServiceExist = true;
  // }

  // newService() {
  //   this.isServiceExist = true;
  // }

  // openInfoDialog() {
  //   this.dialog.open(InfoModalComponent, {
  //     data: {
  //       header: 'Service Management',
  //       message: 'Service successfully added!'
  //     }
  //   });
  // }

  // what does this service do? what is the outcome we want?
  createNewService() {
    if (this.isServiceExist) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          header: 'New Service',
          message:
            "All the changes you've made will not be saved if you continue.",
        },
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.reset();
          this.direction1Count = 0;
          this.direction2Count = 0;
          this.isServiceExist = true;
          this.mode = 'new';
          // this.searchForm.reset();
          // this.cancelNewSearch();

          // this.direction1Data = [];
          // this.direction2Data = [];
          // this.direction1DataPath = [];
          // this.direction2DataPath = [];
          // this.isLoop = true;

          // this.map.getLayers().getArray()
          // .filter(layer => layer.get('name') === 'pathStreet-1' || layer.get('name') === 'pathStreet-2')
          // .forEach(layer => this.map.removeLayer(layer));

          // this.viewAnimate.animate({
          //   center: fromLonLat(environment.map.locationLonLat),
          //   duration: 1000,
          //   zoom: 12
          // });
        }
      });
    } else {
      this.searchForm.reset();
      this.isServiceExist = true;
      this.mode = 'new';
    }
  }

  onStartPoint(data) {
    //if loop assign the same end point
    if (this.isLoop) {
      this.searchForm.controls['endPoint'].setValue(data);
    }

    //false if new service
    if (this.direction1Data.length === 0 || this.direction2Data.length === 0) {
      return false;
    }
    data.stopDesc = data.stopName;
    data.isChecked = true;
    if (this.currDirection === 1) {
      data.sequence = 1;
      this.selectedStreetDirection1Data.stops[0] = data;
      this.direction1Data[0].stops[0] = data;
    } else {
      this.selectedStreetDirection2Data.stops[0] = data;
      this.direction2Data[0].stops[0] = data;
    }
  }

  onEndPoint(data) {
    //false if new service
    if (this.direction1Data.length === 0 || this.direction2Data.length === 0) {
      return false;
    }
    data.stopDesc = data.stopName;
    data.isChecked = true;
    if (this.currDirection === 1) {
      let direction1Len = this.selectedStreetDirection1Data.stops.length - 1;
      let direction1StLen = this.direction1Data.length - 1;
      data.sequence = direction1Len;
      this.selectedStreetDirection1Data.stops[direction1Len] = data;
      let direction1StStopLen =
        this.direction1Data[direction1StLen].stops.length - 1 <= 1
          ? 0
          : this.direction1Data[direction1StLen].stops.length - 1;
      this.direction1Data[direction1StLen].stops[direction1StStopLen] = data;
    } else {
      let direction2Len = this.selectedStreetDirection2Data.stops.length - 1;
      let direction2StLen = this.direction2Data.length - 1;
      data.sequence = direction2Len;
      this.selectedStreetDirection2Data.stops[direction2Len] = data;
      let direction2StStopLen =
        this.direction2Data[direction2StLen].stops.length - 1 <= 0
          ? 0
          : this.direction2Data[direction2StLen].stops.length - 1;
      this.direction2Data[direction2StLen].stops[direction2StStopLen] = data;
    }
  }

  // dropSelectedStreet(event: CdkDragDrop<string[]>) {
  //   // let list = this.direction1Data.stops;
  //   let list = this.selectedStreetDirection1Data.stops;
  //   // console.log(list);
  //   // console.log(event);
  //   moveItemInArray(list, event.previousIndex, event.currentIndex);
  // }

  dropStreet(event: CdkDragDrop<string[]>, data) {
    // let list = this.direction1Data;
    // let list = this.selectedStreetDirection1Data.stops;
    // console.log('street', JSON.parse(JSON.stringify(data)));
    if (event.currentIndex !== 0) {
      moveItemInArray(data, event.previousIndex, event.currentIndex);

      if (event.previousIndex !== event.currentIndex) {
        this.sequenceStreet(data);
      }
    } else {
      this._snackBar.open(
        'The first street group cannot be replaced or changed.',
        null,
        {
          duration: 2000,
        }
      );
    }
  }

  sequenceStreet(street) {
    let count = 1;
    street.forEach(
      item =>
        item?.stops?.forEach(stop => {
          if (stop.isChecked) {
            stop.sequence = count;
            count++;
          } else {
            stop.sequence = 0;
          }
        })
    );
  }

  sequenceList(list) {
    let count = 1;
    if (list?.length > 0) {
      count = Math.min(...list.map(list => list?.sequence ?? 1));
    }
    // console.log('sequence', JSON.parse(JSON.stringify(list)));
    list.forEach(item => {
      if (item.isChecked) {
        item.sequence = count;
        count++;
      } else {
        item.sequence = 0;
      }
    });
  }

  dropDirection(
    event: CdkDragDrop<string[]>,
    index: number,
    direction: 1 | 2 = 1
  ) {
    this.running = true;
    let directionData = [];
    let directionDataPath = {};

    if (direction === 1) {
      directionData = this.direction1Data;
      directionDataPath = this.direction1DataPath;
    } else {
      directionData = this.direction2Data;
      directionDataPath = this.direction2DataPath;
    }

    let list = directionData[index].stops;

    // console.log(
    //   'b d1',
    //   event.previousIndex,
    //   event.currentIndex,
    //   JSON.parse(
    //     JSON.stringify({
    //       d1: directionData,
    //       d1Path: directionDataPath,
    //     })
    //   )
    // );
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    // console.log(JSON.parse(JSON.stringify(list)));
    if (event.previousIndex !== event.currentIndex) {
      this.sequenceList(list);

      const currDirectionData = this.getSelectedFlatStops(directionData);
      if (currDirectionData?.listStop?.length > 0) {
        this.updateRouteHere(currDirectionData.listStop, direction);
      }

      this.onChangeDirectionProvider('heremap');
    }

    // console.log(
    //   'a d1',
    //   JSON.parse(
    //     JSON.stringify({
    //       d1: directionData,
    //       d1Path: directionDataPath,
    //     })
    //   )
    // );

    this.running = false;
  }

  tickStopCheckbox(event: MatCheckboxChange, stop, direction: 1 | 2) {
    const { checked } = event || {};
    console.log('event', event, stop);
    stop.isChecked = checked;

    let directionData = [];
    let directionDataPath = {};

    if (direction === 1) {
      this.sequenceStreet(this.direction1Data);
      directionData = this.direction1Data;
      directionDataPath = this.direction1DataPath;
    } else {
      this.sequenceStreet(this.direction2Data);
      directionData = this.direction2Data;
      directionDataPath = this.direction2DataPath;
    }

    const currDirectionData = this.getSelectedFlatStops(directionData);
    if (currDirectionData?.listStop?.length > 0) {
      this.updateRouteHere(currDirectionData.listStop, direction);
    }

    this.onChangeDirectionProvider('heremap');
    // console.log(
    //   'dir data',
    //   directionData,
    //   directionDataPath,
    //   this.direction1Distance,
    //   this.direction2Distance
    // );
  }

  onUpload() {
    console.log('upload');
  }

  //start map options
  //TODO: move to service
  glRoadmap: any;
  glTerrain: any;
  glSatellite: any;
  glHybrid: any;
  trafficLayer;
  olGM;
  currGoogleLayer: string = '';
  provider = 'OpenStreetMap'; //this.mapService.mapProvider;

  onChangeDirectionProvider(provider) {
    // clean up routes first
    this.removePathLayers();

    if (provider === 'mapbox') {
      // console.log('1');
      this.initMapbox();
    } else if (provider === 'heremap') {
      // || this.currDirection === 1){

      // console.log('2');

      const currDirectionData = this.getSelectedFlatStops(
        this.getCurrentDirectionData().directionData
      );
      if (currDirectionData?.listStop?.length > 0) {
        this.routeHereDirection(currDirectionData.listStop);
      }

      // this.initMapInteraction();
      // this.addMarkStop(this.selectedStreetDirection1Data.stops);
      // } else if (this.currDirection === 2 && provider === 'heremap') {
      //   // || this.currDirection === 1){

      //   // console.log('3');
      //   // this.drawStPathMapManually(this.selectedStreetDirection2PathData,this.direction2Data);
      //   this.routeHereDirection(
      //     this.getSelectedFlatStops(this.getCurrentDirectionData().directionData)
      //       .listStop
      //   );
      //   // this.initMapInteraction();
      //   // this.addMarkStop(this.selectedStreetDirection1Data.stops);
    } else if (this.currDirection === 1) {
      // console.log('4');
      // this.routeHereDirection(this.getSelectedFlatStops(this.getCurrentDirectionData().directionData).listStop);
      this.drawStPathMapManually(
        this.selectedStreetDirection1PathData,
        this.direction1Data
      ); //temp remove
    } else if (this.currDirection === 2) {
      // console.log('5');
      this.drawStPathMapManually(
        this.selectedStreetDirection2PathData,
        this.direction2Data
      );
    } else {
      // console.log(this.selectedStreetDirection1PathData);
      // console.log('6');
      this.drawStPathMapManually(
        this.selectedStreetDirection1PathData,
        this.direction1Data
      );
    }
    this.directionProvider = provider;
    // this.layerMapSetVisibility(provider);
  }

  initMapbox() {
    // this.removeLayer('pathStreet-1');
    // this.removeLayer('stopOtherDirection-1');
    this.reRenderMap('motorway');
    // this.onChangeDirectionProvider('heremap');
  }

  onChangeProvider(provider) {
    if (provider === 'GoogleMap' && this.currGoogleLayer === '') {
      this.initGoogleMap();
    }
    this.provider = provider;
    this.layerMapSetVisibility(provider);
  }

  initGoogleMap() {
    this.currGoogleLayer = 'roadmap';
    if (!this.glRoadmap) {
      this.glRoadmap = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        name: 'gm-roadmap',
        visible: true,
      });
      this.map.addLayer(this.glRoadmap);
    }

    if (!this.glTerrain) {
      this.glTerrain = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        name: 'gm-terrain',
        visible: false,
      });
      this.map.addLayer(this.glTerrain);
    }

    if (!this.glHybrid) {
      this.glHybrid = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.HYBRID,
        name: 'gm-hybrid',
        visible: false,
      });
      this.map.addLayer(this.glHybrid);
    }

    if (!this.glSatellite) {
      this.glSatellite = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        name: 'gm-satellite',
        visible: false,
      });
      this.map.addLayer(this.glSatellite);
    }

    if (!this.trafficLayer) {
      this.trafficLayer = new google.maps.TrafficLayer();
    }

    if (!this.olGM) {
      this.olGM = new OLGoogleMaps({
        map: this.map,
      }); // map is the Map instance

      this.olGM.activate();
    }
  }

  layerMapSetVisibility(mapProvider) {
    if (mapProvider === 'GoogleMap' && this.currGoogleLayer === 'roadmap') {
      this.glRoadmap.setVisible(true);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = true;
      // this.isMapType = true;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'terrain'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(true);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = true;
      // this.isMapType = false;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'satellite'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(true);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = false;
      // this.isMapType = false;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'hybrid'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(true);

      // this.isTrafficLayer = true;
      // this.isMapType = false;
    }

    if (this.OSMLayer && mapProvider !== 'OpenStreetMap') {
      this.OSMLayer.setVisible(false);
    } else if (this.OSMLayer && mapProvider === 'OpenStreetMap') {
      this.OSMLayer.setVisible(true);
    }
  }

  trafficToggle(e) {
    var isTraffic = e.checked; //!this.isTraffic;

    const gmap = this.olGM.getGoogleMapsMap();
    if (isTraffic) {
      this.trafficLayer.setMap(gmap);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  /**
   * Google maps related function
   */
  onChangeMapType(data) {
    this.currGoogleLayer = data;

    let layers = this.map.getLayers().getArray().slice();

    for (let row of layers) {
      let layerName = row.get('name');
      // this.map.removeLayer(row);
      if (row instanceof GoogleLayer) {
        if (layerName === 'gm-roadmap' && data === 'roadmap') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-terrain' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-terrain' && data === 'terrain') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-satellite' && data === 'satellite') {
          row.setVisible(true);
          // this.isTrafficLayer = false;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-terrain'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-hybrid' && data === 'hybrid') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-terrain' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else {
          row.setVisible(false);
        }
      }
    }
  }
  //end map service

  initTrainStations() {
    var urlAction = 'gtfsupload/v1/stops/getMRTStationList';
    this.commonService
      .commonPostAction(urlAction, '')
      .subscribe((dataRes: any) => {
        this.allTrainStation = dataRes;
      });
  }

  onEditStop(data) {
    let drv = {
      streetName: data.streetName,
      stopCode: data.stopCode,
      stopName: data.stopName,
      trainStation: data.trainStation,
      lat: data.stopLat,
      lon: data.stopLon,
      busTerminal: data.busTerminal,
      stationCode: data.mrtStationCode,
      stationName: data.mrtStationName,
      message: data.sequence,
      allTrainStation: this.allTrainStation,
      action: 'edit',
    };

    // const dialogConfig = new MatDialogConfig();

    // dialogConfig.autoFocus = true;
    // dialogConfig.data = drv;

    // dialogConfig.width = '50vh';
    // dialogConfig.height = '50vh';
    var dialogData = {
      autoFocus: true,
      data: drv,
    };

    const dialogRef = this.dialog.open(AddStopModalComponent, dialogData);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result !== undefined) {
        // console.log(result);
        // console.log(this.selectedStreetDirection1PathData);
        // console.log(this.selectedStreetDirection2PathData);
        // console.log(this.direction1Data);
        // console.log(this.direction2Data);

        let directionData = this.direction1Data;
        if (this.currDirection === 1) {
          directionData = this.direction1Data;
        } else {
          directionData = this.direction2Data;
        }

        // console.log(directionData);
        directionData.forEach(element => {
          if (element.streetName === element.streetName) {
            // console.log(element);
            element.stops.forEach(stops => {
              if (result.stopCode === stops.stopCode) {
                stops.busTerminal = result.busTerminal;
                // stops.imgSrc = 'assets/images/icon/map-marker-green.png';
                stops.lat = result.stopLat;
                stops.lon = result.stopLon;
                stops.name = 'stop-' + result.stopCode;
                stops.message = result.message;
                stops.mrtStationCode = result.mrtStationCode;
                stops.mrtStationName = result.mrtStationName;
                stops.stopCode = result.stopCode;
                stops.stopDesc = result.stopDesc;
                stops.stopLat = result.stopLat;
                stops.stopLon = result.stopLon;
                stops.stopName = result.stopName;
                stops.stopStatus = result.stopStatus;

                //remove layer and features
                this.map
                  .getLayers()
                  .getArray()
                  .filter(
                    layer =>
                      layer.get('name') === 'pathStreet-1' ||
                      layer.get('name') === 'pathStreet-2' ||
                      layer.get('name') === 'stopOtherDirection-1' ||
                      layer.get('name') === 'stopOtherDirection-2'
                  )
                  .forEach(
                    (layer2: VectorLayer<any> | VectorImageLayer<any>) => {
                      // this.map.removeLayer(layer)
                      let features = layer2.getSource().getFeatures();
                      features.forEach(element => {
                        this.mapService.removeLayerFeature(
                          layer2,
                          element.get('name')
                        );
                      });
                    }
                  );

                //redraw map
                // this.reRenderMap('motorway');
                this.onChangeDirectionProvider('heremap');
              }
            });
          }
        });
      }
    });
  }

  onSearchLocation(stop, direction: number) {
    this.isStopZoom = true;
    this.changeMode();
    this.changeDirection(direction);

    this.viewAnimate.animate({
      center: fromLonLat([stop.stopLon, stop.stopLat]),
      duration: 1000,
      zoom: 15,
    });

    this.isPanel = true;
    this.panelData = stop;
    this.openBusStopEvent(this.panelData);
  }

  onExpand() {
    this.isExpand = !this.isExpand;
    var me = this;
    setTimeout(function () {
      me.map.updateSize();
    }, 200);
  }

  menuSubscription: Subscription;
  watchMenuChange() {
    const me = this;
    this.menuSubscription = this.menuService.getPinned().subscribe(value => {
      me._spinner.show();
      setTimeout(function () {
        if (me?.map) {
          me.map.updateSize();
        }
      }, 150);
      setTimeout(() => me._spinner.hide(), 350);
    });
  }

  ngOnDestroy() {
    if (this.menuSubscription) {
      this.menuSubscription?.unsubscribe?.();
    }
  }

  /**
   * Service ID Autocomplete
   */
  @ViewChild('serviceAutocompleteTrigger')
  routeAutoCompleteTrigger: MatAutocompleteTrigger;
  allowedRouteList = [];
  filteredServiceList: Observable<string[]>;

  initAllowedRouteSelection() {
    this._mapService
      .getInitSwitch()
      .then((data: any) => {
        const { primary, secondary } = data || {};
        const routeList = [];
        if (primary) {
          primary.forEach(route => {
            const { service } = route || {};
            if (service) {
              if (routeList.indexOf(service) < 0) {
                routeList.push(service);
              }
            }
          });
        }
        if (secondary) {
          secondary.forEach(route => {
            const { service } = route || {};
            if (service) {
              if (routeList.indexOf(service) < 0) {
                routeList.push(service);
              }
            }
          });
        }
        this.allowedRouteList = [...routeList];
        this.initRouteAutocomplete();
        // console.log('responsibilities', data, routeList);
      })
      .catch(e => {
        this.allowedRouteList = [];
        console.log('Roster: Route Responsibility error: ', e);
        this._snackBar.open(
          'Server error. Failed to get routes list. Please refresh the page and try again.',
          null,
          {
            duration: 2000,
          }
        );
      });
  }

  initRouteAutocomplete() {
    this.filteredServiceList =
      this.searchForm.controls.searchService.valueChanges.pipe(
        startWith(''),
        map(value => this.filterRouteOptions(value || ''))
      );
  }

  private filterRouteOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allowedRouteList.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  selectAllowedRoute(event: MatAutocompleteSelectedEvent): void {
    // const { option } = event || {};
    // const { value } = option || {};
    // const val = this.searchForm.value.searchService;
    // console.log(val, value, event);
    event.option.deselect();
    this.searchServiceRoute();
  }

  distanceDisplay(distance) {
    if (isNaN(distance)) return '';
    if (distance >= 1000) {
      return parseFloat((distance / 1000).toFixed(3)) + ' km';
    } else if (distance > 0) {
      return distance + ' m';
    }
    return '';
  }

  // searchServiceRouteHereMap() {

  //   this._snackBar.open('Searching...');

  //   this.stopManagementService.getSearchServiceRoute(this.searchForm.value.searchService)
  //   .subscribe(
  //     (data: any) => {
  //       if(data.length === 0) {
  //         this._snackBar.open('Service line '+this.searchForm.value.searchService+' not existing.', null, {
  //           duration: 2000,
  //         });
  //         this.running = false;
  //         let searchValue = this.searchForm.value.searchService;
  //         this.reset();
  //         this.searchForm.controls['searchService'].setValue(searchValue);
  //         return false;
  //       }
  //       console.log(data);

  //       this.resultServiceSearch = data;
  //       let direction1Len = data.navigation[0].stops.length - 1;
  //       let startPoint = this.busIntTer.find(x => x.stopCode === data.navigation[0].stops[0]);
  //       let endPoint = this.busIntTer.find(x => x.stopCode === data.navigation[0].stops[direction1Len]);

  //       if(data.navigation.length === 1) {
  //         this.searchForm.controls['endPoint'].setValue(startPoint);
  //       }
  //       else {
  //         this.searchForm.controls['endPoint'].setValue(endPoint);
  //       }

  //       this.searchForm.controls['startPoint'].setValue(startPoint);

  //       data.navigation.forEach(element => {
  //         if(element.direction === 1) {
  //           this.direction1DataPath = element.path;
  //         }
  //         else {
  //           this.direction2DataPath = element.path;
  //         }

  //         this.stopManagementService.getMultipleStops(element.stops)
  //         .subscribe(
  //           (res: any) => {
  //             this.isServiceExist = true;
  //             this.isSearchStreet = true;
  //             let street:any = [];

  //             res.forEach((stop, index) => {
  //               stop.isChecked = true;
  //               let prev = res[index-1];

  //               if(prev !== undefined && prev.streetName === stop.streetName) {
  //                   street.slice(-1)[0].stops.push(stop);
  //               }
  //               else {
  //                 let streetTest: any = {};
  //                 streetTest.streetName = stop.streetName;
  //                 streetTest.stops = [];
  //                 streetTest.stops.push(stop);
  //                 street.push(streetTest);
  //               }
  //               stop.sequence = index+1;
  //             });

  //             if(element.direction === 1) {
  //               this.currDirection = 1;
  //               this.direction1Data = street;
  //               this.selectedStreetDirection1Data.stops = res;
  //               this.drawStPathMapManually(element, street); //temp remove
  //               this.selectedStreetDirection1PathData = element;
  //               this.direction1Count = element.stops.length;
  //             }
  //             else {
  //               this.direction2Data = street;
  //               this.selectedStreetDirection2Data.stops = res;
  //               this.isLoop = false;
  //               this.selectedStreetDirection2PathData = element;
  //               this.direction2Count = element.stops.length;
  //             }
  //             this.isServiceEdit = true;
  //         });

  //       });

  //       this.running = false;
  //       this._snackBar.dismiss();
  //   },
  //   (err:HttpErrorResponse) => {
  //     this._snackBar.open(err.statusText, null, {
  //       duration: 2000,
  //     });

  //     this.running = false;
  //     console.log(err);
  //   });
  // }
}
