import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-multi-ladder',
  templateUrl: './multi-ladder.component.html',
  styleUrls: ['./multi-ladder.component.scss']
})
export class MultiLadderComponent implements OnInit {

  isNewWindow: boolean = false;
  allLadder = [
    {
      'service': '912',
      'directionData': [
        {
          'direction': '1',
          'stops': [
            {
              'stopCode': 'Woodlands Temp Int'
            },
            {
              'stopCode': '2b'
            },
            {
              'stopCode': 'Woodlands Temp Int'
            }
          ]
        }
      ]
    },
    {
      'service': '2',
      'directionData': [
        {
          'direction': '1',
          'stops': [
            {
              'stopCode': 'Changi Village Ter'
            },
            {
              'stopCode': '5e'
            },
            {
              'stopCode': '6f'
            },
            {
              'stopCode': 'Kampong Bahru Ter'
            }
          ]
        },
        {
          'direction': '2',
          'stops': [
            {
              'stopCode': 'Kampong Bahru Ter'
            },
            {
              'stopCode': 'e55'
            },
            {
              'stopCode': 'f66'
            },
            {
              'stopCode': 'g77'
            },
            {
              'stopCode': 'Changi Village Ter'
            }
          ]
        }
      ]
    },
    {
      'service': '3',
      'directionData': [
        {
          'direction': '1',
          'stops': [
            {
              'stopCode': 'Tampines Int'
            },
            {
              'stopCode': '5e'
            },
            {
              'stopCode': '6f'
            },
            {
              'stopCode': 'g7'
            },
            {
              'stopCode': '4d'
            },
            {
              'stopCode': '5e'
            },
            {
              'stopCode': '6f'
            },
            {
              'stopCode': 'g7'
            },
            {
              'stopCode': '4d'
            },
            {
              'stopCode': '5e'
            },
            {
              'stopCode': '6f'
            },
            {
              'stopCode': 'Punggol Temp Int'
            }
          ]
        },
        {
          'direction': '2',
          'stops': [
            {
              'stopCode': 'Punggol Temp Int'
            },
            {
              'stopCode': '5e'
            },
            {
              'stopCode': '6f'
            },
            {
              'stopCode': 'g7'
            },
            {
              'stopCode': '4d'
            },
            {
              'stopCode': '5e'
            },
            {
              'stopCode': '6f'
            },
            {
              'stopCode': 'g7'
            },
            {
              'stopCode': '4d'
            },
            {
              'stopCode': '5e'
            },
            {
              'stopCode': '6f'
            },
            {
              'stopCode': 'Tampines Int'
            }
          ]
        }
      ]
    }
  ]
  
  constructor(
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/multi-ladder/2','Multi Ladder','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}
