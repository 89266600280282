<div class="dispatcher-group">
  <div>
    <h3 class="title-header">Dispatcher<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div>

  <div class="criteria-row">
    <form [formGroup]="rptForm" (ngSubmit)="run()">
      <mat-form-field floatLabel="always">
        <mat-select [(value)]="selectedCriteria">
          <mat-option *ngFor="let n of criteria" [value]="n">
            {{n}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" *ngIf="selectedCriteria === 'Bus'">
        <input type="text" placeholder="Bus Reg No" aria-label="Bus Reg No" matInput formControlName="busRegNo">
        
        <mat-error *ngIf="rptForm.get('busRegNo').invalid">Invalid bus reg no</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always" *ngIf="selectedCriteria === 'Driver Id'">
        <input type="text" placeholder="Driver Id" aria-label="Driver Id" matInput formControlName="driverId">
        
        <mat-error *ngIf="rptForm.get('driverId').invalid">Invalid driver id</mat-error>
      </mat-form-field>

      <ng-container *ngIf="selectedCriteria === 'Duty Date'">
        <mat-form-field floatLabel="always">
          <input matInput [matDatepicker]="startDate" placeholder="Start Date" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
          <mat-error *ngIf="rptForm.get('startDate').invalid">Invalid start date</mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <input matInput [matDatepicker]="endDate" placeholder="End Date" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
          <mat-error *ngIf="rptForm.get('endDate').invalid">Invalid end date</mat-error>
        </mat-form-field>
      </ng-container>

      <button mat-raised-button type="submit" 
      
      >Run</button>
      <!-- <button type="button" mat-raised-button (click)="exporter.exportTable('xlsx')"  [disabled]="running">Export</button> -->
    </form>
  </div>

  <!-- <div>
    <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" (exportStarted)="exportStarted('Exporting...')" (exportCompleted)="exportCompleted()">

      <ng-container matColumnDef="busRegNo">
        <th mat-header-cell *matHeaderCellDef>Bus Reg No</th>
        <td mat-cell *matCellDef="let element"> {{element.busRegNo}} </td>
      </ng-container>

      <ng-container matColumnDef="driverId">
        <th mat-header-cell *matHeaderCellDef>Driver Id</th>
        <td mat-cell *matCellDef="let element"> {{element.driverId}} </td>
      </ng-container>

      <ng-container matColumnDef="dutyDate">
        <th mat-header-cell *matHeaderCellDef>Duty Date</th>
        <td mat-cell *matCellDef="let element"> {{element.dutyDate}} </td>
      </ng-container>

      <ng-container matColumnDef="updatedUser">
        <th mat-header-cell *matHeaderCellDef>Updated User</th>
        <td mat-cell *matCellDef="let element"> {{element.updatedUser}} </td>
      </ng-container>

      <ng-container matColumnDef="updatedDate">
        <th mat-header-cell *matHeaderCellDef>Updated Date</th>
        <td mat-cell *matCellDef="let element"> {{element.updatedDate}} </td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="deviation">
        <th mat-header-cell *matHeaderCellDef>Deviation</th>
        <td mat-cell *matCellDef="let element"> {{element.deviation}} </td>
      </ng-container> -->

      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div> -->
  <div class="dispatch-image"></div>
</div>