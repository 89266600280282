export const initTimeTable = [
  {
    routeId: '7001',
    routePrefix: 'RR',
    busCode: 'R',
    timeStop: 5,
    numBus: 5,
    maxBus: 5,
    stopList: [
      {
        direction: '1',
        stops: ['RS1', 'RS2', 'RS3', 'RS4', 'RS5', 'RS6', 'RS7'],
      },
      {
        direction: '2',
        stops: ['RS7', 'RS6', 'RS5', 'RS4', 'RS3', 'RS2', 'RS1'],
      },
    ],
    timetable: [],
    busArr: ['SG1791G', 'SG6186X', 'SG5143B', 'SG1783E', 'SG5114K'],
  },
  {
    routeId: '7002',
    routePrefix: 'GR',
    busCode: 'G',
    timeStop: 5,
    numBus: 8,
    maxBus: 8, // need extra bus to avoid overlap
    stopList: [
      {
        direction: '1',
        stops: [
          'GS1',
          'GS2',
          'GS3',
          'GS4',
          'GS5',
          'GS6',
          'GS7',
          'GS8',
          'GS9',
          'GS10',
        ],
      },
      {
        direction: '2',
        stops: [
          'GS10',
          'GS9',
          'GS8',
          'GS7',
          'GS6',
          'GS5',
          'GS4',
          'GS3',
          'GS2',
          'GS1',
        ],
      },
    ],
    timetable: [],
    busArr: [
      'SG6042C',
      'SG1713H',
      'SG5031R',
      'SG1894T',
      'SG5115H',
      'SG6184B',
      'SG6159A',
      'SG5434L',
    ],
  },
  {
    routeId: '7003',
    routePrefix: 'OR',
    busCode: 'O',
    timeStop: 5,
    numBus: 5,
    maxBus: 5, // need extra bus to avoid overlap
    stopList: [
      {
        direction: '1',
        stops: ['OS1', 'OS2', 'OS3', 'OS4', 'OS5'],
      },
      {
        direction: '2',
        stops: ['OS5', 'OS4', 'OS3', 'OS2', 'OS1'],
      },
    ],
    timetable: [],
    busArr: ['SG1769Y', 'SG1778X', 'SG1784C', 'SG1759B', 'SG1768A'],
  },
];
