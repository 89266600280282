<div class="segment-group">
    <div>
      <h3 class="title-header">Segment<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
    </div>
    <div class="groups">
        <div class="schedule-group">
            <mat-grid-list cols="9" rowHeight="5:1">
                <mat-grid-tile rowspan="4" class="stopCode">51</mat-grid-tile>
                <mat-grid-tile colspan="2" rowspan="2" class="operator">SBS Transit</mat-grid-tile>
                <mat-grid-tile colspan="2" class="grid-title">Weekdays</mat-grid-tile>
                <mat-grid-tile colspan="2" class="grid-title">Saturdays</mat-grid-tile>
                <mat-grid-tile colspan="2" class="grid-title">Suns / P.Hs</mat-grid-tile>
            
                <mat-grid-tile colspan="1">1st Bus</mat-grid-tile>
                <mat-grid-tile colspan="1">Last Bus</mat-grid-tile>
                <mat-grid-tile colspan="1">1st Bus</mat-grid-tile>
                <mat-grid-tile colspan="1">Last Bus</mat-grid-tile>
                <mat-grid-tile colspan="1">1st Bus</mat-grid-tile>
                <mat-grid-tile colspan="1">Last Bus</mat-grid-tile>
            
                <ng-container *ngFor="let sched of segmentData.schedule">
                    <mat-grid-tile colspan="2">{{sched.stopName}}</mat-grid-tile>
                    
                    <mat-grid-tile colspan="1">{{sched.weekdays.firstBus}}</mat-grid-tile>
                    <mat-grid-tile colspan="1">{{sched.weekdays.lastBus}}</mat-grid-tile>

                    <mat-grid-tile colspan="1">{{sched.saturday.firstBus}}</mat-grid-tile>
                    <mat-grid-tile colspan="1">{{sched.saturday.lastBus}}</mat-grid-tile>

                    <mat-grid-tile colspan="1">{{sched.sundayHoliday.firstBus}}</mat-grid-tile>
                    <mat-grid-tile colspan="1">{{sched.sundayHoliday.lastBus}}</mat-grid-tile>
                </ng-container>
            </mat-grid-list>
        </div>
        
        <div class="frequency-group">
            <mat-grid-list cols="5" rowHeight="9:1">
                <mat-grid-tile colspan="1" class="grid-title">Period</mat-grid-tile>
                <mat-grid-tile colspan="1" class="grid-title">0630 - 0830</mat-grid-tile>
                <mat-grid-tile colspan="1" class="grid-title">0831 - 1659</mat-grid-tile>
                <mat-grid-tile colspan="1" class="grid-title">1700 - 1900</mat-grid-tile>
                <mat-grid-tile colspan="1" class="grid-title">After 1900</mat-grid-tile>
                
                <ng-container *ngFor="let freq of segmentData.frequency">
                    <mat-grid-tile colspan="1">{{freq.name}}</mat-grid-tile>
                    <ng-container *ngFor="let period of freq.period">
                        <mat-grid-tile colspan="1">{{period.time}}</mat-grid-tile>
                    </ng-container>
                </ng-container>
            </mat-grid-list>
        </div>

        <div class="direction-group">
            <ng-container *ngFor="let direction of segmentData.directions">
                <mat-grid-list cols="4" rowHeight="6:1">
                    <mat-grid-tile colspan="4" class="grid-title">Direction {{direction.direction}}</mat-grid-tile>
                    <mat-grid-tile colspan="1">Distance (km)</mat-grid-tile>
                    <mat-grid-tile colspan="1">Bus Stop Code</mat-grid-tile>
                    <mat-grid-tile colspan="1">Bus Stop</mat-grid-tile>
                    <mat-grid-tile colspan="1">Travelling Time</mat-grid-tile>
                    
                    <ng-container *ngFor="let stop of direction.stops">
                        <mat-grid-tile colspan="1">{{stop.distance}}</mat-grid-tile>
                        <mat-grid-tile colspan="1">{{stop.stopCode}}</mat-grid-tile>
                        <mat-grid-tile colspan="1">{{stop.stopName}}</mat-grid-tile>
                        <mat-grid-tile colspan="1">{{stop.travellingPeriod}}</mat-grid-tile>
                    </ng-container>
                </mat-grid-list>
            </ng-container>
        </div>
    </div>
</div>