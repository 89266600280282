import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';

import { JitsiService } from '../../shared/services/jitsi.service';
import {
  INTERFACE_CONFIG_OVERWRITE,
  CONFIG_OVERWRITE,
} from '../../shared/others/jitsi-config';
import { environment } from 'src/environments/environment';

declare var JitsiMeetExternalAPI;

function transformObjToArr(obj: Object) {
  return Object.entries(obj).map(([name, obj]) => ({ name, ...obj }));
}

function transformBreakoutObj(obj: Object) {
  return Object.entries(obj).map(([id, obj]) => ({ id, ...obj }));
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss'],
})
export class BroadcastComponent implements OnInit {
  decodedToken: any;
  agencyName = '';
  showJitsi;
  displayName;
  email;
  jitsiBroadcastMuteSubscription: Subscription;
  @ViewChild('broadcastcallframe') callFrame: ElementRef;
  @ViewChild('callPS', { static: true }) chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
  };
  loading: boolean = false;
  _participants = {};
  jitsiApi = undefined;
  isJitsiHidden: boolean = true;
  jitsiError: boolean = false;

  readonly LOBBY: string = 'Lobby';
  readonly BREAKOUT: string = 'Breakout Room';
  readonly INVITE: string = 'INVITE';
  readonly REMOVE: string = 'REMOVE';
  lobbyTitle: string = '';
  roomTitle: string = this.LOBBY;
  selectedUsers: any = [];
  isSelecting: boolean = false;
  inCall: boolean = false;
  isRoomCreator: boolean = false;
  isBreakoutRoom: boolean = false;
  hasOngoingRoomCall: boolean = false;
  broadcastRoomName;
  operatorBreakoutRoomName;
  operatorBreakoutRoomInfo;
  operatorInfo;
  operatorName;
  _currentRoomId = '';
  _currentRoomName = '';
  _breakoutRooms = {};

  constructor(
    public _jitsiService: JitsiService,
    private _snackBar: MatSnackBar,
    private _spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this._spinner.show();
  }

  ngOnDestroy() {
    this.jitsiBroadcastMuteSubscription?.unsubscribe?.();
    this.decodedToken = {};
    this.displayName = '';
    this.email = '';
    this.agencyName = '';
  }

  ngAfterViewInit(): void {
    this.decodedToken = jwt_decode(localStorage.getItem('access_token'));
    const { agency, displayname, email } = this.decodedToken;
    this.displayName = displayname;
    this.email = email;
    this.agencyName = agency;
    setTimeout(() => {
      this.initJitsi();
    }, 500);
  }

  sortSelfFirst = (a: any, b: any): number => {
    if (a?.value?.displayName?.indexOf('(me)') > -1) return -1;
    if (b?.value?.displayName?.indexOf('(me)') > -1) return 1;
    return a?.value?.displayName?.localeCompare(b?.value?.displayName) ?? 0;
  };

  sortAnnouncementFirst = (a: any, b: any): number => {
    if (a?.value?.isMainRoom) return -1;
    if (b?.value?.isMainRoom) return 1;
    if (a?.value?.name && b?.value?.name) {
      return a?.value?.name?.localeCompare(b?.value?.name);
    }
    return 0;
  };

  sortAlphabetical = (a: any, b: any): number => {
    return a?.value?.displayName?.localeCompare(b?.value?.displayName);
  };

  trackByKey(index, item) {
    return item?.key;
  }

  startSelection() {
    this.isSelecting = true;
    this.selectedUsers = [];
  }

  cancelSelection() {
    this.isSelecting = false;
    this.selectedUsers = [];
  }

  toggleSelection(participant: any) {
    const { key: id, value } = participant;
    const { displayName } = value;
    if (!this.isSelecting) return;
    if (displayName?.includes('(me)')) return;
    if (this.selectedUsers.indexOf(id) > -1) {
      this.selectedUsers = this.selectedUsers.filter(userId => userId !== id);
    } else {
      this.selectedUsers.push(id);
    }
  }

  inviteList = [];
  inviteList$ = [];
  async startCall() {
    if (!this.inCall) {
      this.inCall = true;
      const operatorRoom = await this.getRoomByName(
        this.operatorBreakoutRoomName
      );
      if (operatorRoom) {
        this.operatorBreakoutRoomInfo = operatorRoom;
      } else {
        this.jitsiApi.executeCommand(
          'addBreakoutRoom',
          this.operatorBreakoutRoomName
        );
        await sleep(1000);
        this.operatorBreakoutRoomInfo = await this.getRoomByName(
          this.operatorBreakoutRoomName
        );
      }
      try {
        if (!this.operatorBreakoutRoomInfo)
          throw Error('Failed to create Breakout Room.');
        this.isRoomCreator = true;
        this.isJitsiHidden = true;
        // console.log('Broadcast: Start Call: ', this.operatorBreakoutRoomInfo, this.selectedUsers);
        this.selectedUsers.forEach(userId => {
          this.jitsiApi.executeCommand(
            'sendParticipantToRoom',
            userId,
            this.operatorBreakoutRoomInfo.id
          );
        });
        this.jitsiApi.executeCommand(
          'joinBreakoutRoom',
          this.operatorBreakoutRoomInfo.jid
        );
        this.isSelecting = false;
        this.selectedUsers = [];
      } catch (e) {
        console.log('Broadcast: Start Call: Failed to initialize room');
        this._snackBar.open('Error creating room. Please try again.', null, {
          duration: 5000,
        });
        this.inCall = false;
        this.isSelecting = false;
        this.selectedUsers = [];
      }
    }
  }

  hoverIndex = -1;
  inviteBreakoutRoom(participantData) {
    const { jid: userJid } = participantData;
    console.log(
      'Broadcast: Invite User: ',
      participantData,
      this.operatorBreakoutRoomInfo.id
    );
    this.jitsiApi.executeCommand(
      'sendParticipantToRoom',
      userJid,
      this.operatorBreakoutRoomInfo.id
    );
  }

  mainHoverIndex = -1;
  async kickBreakoutRoom(participantId) {
    const { rooms } = await this.jitsiApi.getRoomsInfo();
    const mainRoom = rooms.find(room => room.isMainRoom);
    const { id: mainRoomId } = mainRoom;
    console.log('Broadcast: Kick User: ', participantId);
    this.sendRemoveSignal(participantId);
    this.jitsiApi.executeCommand(
      'sendParticipantToRoom',
      participantId,
      mainRoomId
    );
    this.mainHoverIndex = -1;
  }

  joinBreakoutRoom(breakoutRoomData) {
    if (this.roomTitle === this.LOBBY && !this.isSelecting) {
      const { jid: breakoutJid } = breakoutRoomData;
      this._breakoutRooms = {};
      this.jitsiApi.executeCommand('joinBreakoutRoom', breakoutJid);
      this.isJitsiHidden = true;
      this.inCall = true;
    }
  }

  rejoinOwnBreakoutRoom() {
    this.isRoomCreator = true;
    this.isJitsiHidden = true;
    if (this.operatorBreakoutRoomInfo) {
      this.jitsiApi.executeCommand(
        'joinBreakoutRoom',
        this.operatorBreakoutRoomInfo.jid
      );
    }
    this.inCall = true;
    this.isSelecting = false;
    this.selectedUsers = [];
  }

  async endBreakout() {
    const { rooms } = await this.jitsiApi.getRoomsInfo();
    const currentBreakoutRoom = rooms.find(
      room => room.id === this.operatorBreakoutRoomInfo.id
    );
    const mainRoom = rooms.find(room => room.isMainRoom);
    const { participants } = currentBreakoutRoom;
    const { id: mainRoomId } = mainRoom;

    this.isJitsiHidden = true;

    // console.log('end breakout ', mainRoom, currentBreakoutRoom);

    participants.forEach(participant => {
      this.sendRemoveSignal(participant.id);
      this.jitsiApi.executeCommand(
        'sendParticipantToRoom',
        participant.id,
        mainRoomId
      );
    });
    this.jitsiApi.executeCommand('joinBreakoutRoom');
    this.roomTitle = this.LOBBY;
    this.selectedUsers = [];
    this.inCall = false;
    this.isRoomCreator = false;
  }

  reloadJitsi() {
    if (!this.loading && this._jitsiService.status === 'fail') {
      this._jitsiService.generateJaasToken();
      this.initJitsi();
    }
  }

  async getRoomByName(roomName) {
    const breakoutRoomObject = await this.jitsiApi.listBreakoutRooms();
    const breakoutRoomList = transformObjToArr(breakoutRoomObject);
    const currentRoom = breakoutRoomList.find(room => room.name === roomName);
    return currentRoom;
  }

  async getJitsiParticipants(roomId) {
    const { rooms } = await this.jitsiApi.getRoomsInfo();
    const currentRoom = rooms.find(room => room.id === roomId) || {};
    const { participants = [] } = currentRoom || {};
    return participants;
  }

  async leaveBreakoutRoom() {
    this.isJitsiHidden = true;
    this.jitsiApi.executeCommand('joinBreakoutRoom');
    this.roomTitle = this.LOBBY;
    this.inCall = false;
  }

  listenEndpointMessage(info) {
    console.log('Broadcast: Receive endpoint text: ', info);
    if (this.isBreakoutRoom) {
      const { data } = info;
      const { eventData, senderInfo } = data || {};
      const { text } = eventData || {};
      const { id: senderParticipantId } = senderInfo || {};
      try {
        const parsedText = JSON.parse(text);
        // console.log('Broadcast Receive: ', text, parsedText);
        const { announcement } = parsedText || {};
        const { type, id } = announcement || {};

        // check for acknowledgement
        if (type === this.INVITE) {
          if (this.isRoomCreator) {
            // received acknowledge and remove from invite list
            this.inviteList = this.inviteList.filter(
              invitee => invitee !== senderParticipantId
            );
            // console.log('Broadcast: Remove Invite List: ', this.inviteList);
          } else {
            // acknowledge invite
            const message = {
              announcement: {
                type: this.INVITE,
                to: senderParticipantId,
                ack: id,
              },
            };
            const jsonMessage = JSON.stringify(message);
            this.jitsiApi.executeCommand(
              'sendEndpointTextMessage',
              senderParticipantId,
              jsonMessage
            );
          }
        }
      } catch (e) {
        console.log('Broadcast: Endpoint Listener Error: ', e);
      }
    }
  }

  sendInviteSignal(toParticipantId) {
    if (this.isRoomCreator) {
      const uuid = uuidv4();
      const message = {
        announcement: {
          type: this.INVITE,
          to: toParticipantId,
          id: uuid,
        },
      };
      const jsonMessage = JSON.stringify(message);
      console.log(
        'Broadcast: Send Invite Signal: ',
        toParticipantId,
        jsonMessage
      );
      this.jitsiApi.executeCommand(
        'sendEndpointTextMessage',
        toParticipantId,
        jsonMessage
      );
    }
  }

  sendUntilReceivedSignal(toParticipantId) {
    if (this.isRoomCreator) {
      this.inviteList.push(toParticipantId);
      const intervalSource = interval(1000);
      const obs = intervalSource
        .pipe(takeWhile(() => this.inviteList?.indexOf(toParticipantId) >= 0))
        .subscribe(() => {
          this.sendInviteSignal(toParticipantId);
        });
      this.inviteList$.push(obs);
      // console.log('Broadcast: Add Invite List: ', this.inviteList);
    }
  }

  unsubscribeSignals() {
    this.inviteList$?.forEach(inviteeSubscription => {
      inviteeSubscription?.unsubscribe?.();
    });
    this.inviteList$ = [];
  }

  sendRemoveSignal(toParticipantId) {
    if (this.isRoomCreator) {
      const uuid = uuidv4();
      const message = {
        announcement: {
          type: this.REMOVE,
          to: toParticipantId,
          id: uuid,
        },
      };
      const jsonMessage = JSON.stringify(message);
      // console.log('Broadcast: Send Remove Signal: ', toParticipantId, jsonMessage);
      this.jitsiApi.executeCommand(
        'sendEndpointTextMessage',
        toParticipantId,
        jsonMessage
      );
    }
  }

  onParticipantJoined(info) {
    const { id: userId, displayName, formattedDisplayName } = info;
    this._participants[userId] = this._participants[userId] || {};
    this._participants[userId].displayName =
      formattedDisplayName || displayName;
    // setTimeout(() => {
    //   me.isJitsiHidden = false;
    // }, 200);

    if (this.isBreakoutRoom) {
      if (this.isRoomCreator) {
        this.sendUntilReceivedSignal(userId);
      }
    }
    // console.log('Broadcast: participantJoined info: ', info);
  }

  onParticipantLeft(info) {
    const { id: userId } = info;
    delete this._participants?.[userId];
    if (this.isBreakoutRoom && this.isRoomCreator) {
      this.inviteList = this.inviteList?.filter(invitee => invitee !== userId);
    }
    // console.log('Broadcast participantLeft info: ', info);
  }

  onVideoConferenceLeft(info) {
    console.log('Broadcast: videoConferenceLeft info: ', info);
    const { roomName } = info;
    const { id: userId } = this.operatorInfo;
    setTimeout(() => {
      this.isJitsiHidden = true;
    }, 0);

    if (this.isBreakoutRoom && this.isRoomCreator) {
      this.unsubscribeSignals();
    }

    delete this._participants?.[userId];
    delete this._breakoutRooms?.[roomName];
    this.selectedUsers = [];
    this.isSelecting = false;
    this._currentRoomName = '';
  }

  initJitsi() {
    try {
      // const currentToken = localStorage.getItem('access_token');
      const jaasToken = this._jitsiService.jaasToken;
      const { agency, displayname, email } = this.decodedToken;
      const decodedJaasToken: any = jwt_decode(jaasToken);
      const { context } = decodedJaasToken || {};
      const { avatar: operatorAvatar } = context || {};
      const domain = this._jitsiService.domain;
      this.broadcastRoomName = `${environment.jitsi.appId}/${agency}-announcement-lobby`;
      this.operatorName = `${agency}-OPS-${displayname}`;
      this.lobbyTitle = `${agency}: Lobby`;
      // this.operatorBreakoutRoomName = 'B' + uuidv5(`[BROADCAST] (${this.agencyName})-${this.displayName}`, uuidv5.URL).replace(/-/g, '');
      this.operatorBreakoutRoomName = `Room: OPS-${displayname}`;
      const options = {
        jwt: jaasToken,
        roomName: this.broadcastRoomName,
        width: '100%',
        height: '100%',
        parentNode: this.callFrame.nativeElement,
        userInfo: {
          displayName: this.operatorName,
          email: email,
        },
        interfaceConfigOverwrite: {
          // TILE_VIEW_MAX_COLUMNS: 1,
          DEFAULT_BACKGROUND: '#292929',
          ...INTERFACE_CONFIG_OVERWRITE,
        },
        configOverwrite: {
          ...CONFIG_OVERWRITE,
          // startAudioOnly: true,
          subject: `${agency}: Lobby`,
          backgroundColor: '#292929',
          startAudioMuted: 0,
          toolbarButtons: [
            'camera',
            // 'hangup',
            'tileview',
            'microphone',
            'settings',
            // 'participants-pane',
          ],
          hideConferenceSubject: true,
          hideConferenceTimer: true,
          startWithAudioMuted: true,
          remoteVideoMenu: {
            disabled: true,
            disableKick: true,
            disableGrantModerator: true,
            disablePrivateChat: true,
          },
          disableFilmstripAutohiding: true,
          filmstrip: {
            // disableResizable: true,
            disableStageFilmstrip: false,
            stageFilmstripParticipants: 6,
            disableTopPanel: true,
          },
          // Disables self-view tile. (hides it from tile view and from filmstrip)
          disableSelfView: false,
          // Disables self-view settings in UI
          // disableSelfViewSettings: true,
        },
      };
      this.jitsiError = false;
      this.loading = true;
      this.jitsiApi = new JitsiMeetExternalAPI(domain, options);
      let me = this;
      this.jitsiApi.executeCommand('displayName', this.operatorName);

      this.jitsiApi.addListener('videoConferenceJoined', async info => {
        const updateToolbar = ({
          hasMic,
          id,
        }: {
          hasMic: boolean;
          id: string;
        }) => {
          const defaultButtons = ['tileview', 'settings'];
          me.jitsiApi.executeCommand('overwriteConfig', {
            toolbarButtons: [
              ...defaultButtons,
              ...(hasMic ? ['microphone', 'camera'] : []),
            ],
            disabledSounds: hasMic ? [] : ['TALK_WHILE_MUTED_SOUND'],
            disabledNotifications: hasMic
              ? []
              : ['toolbar.talkWhileMutedPopup'],
          });
          me.jitsiApi.isAudioMuted().then(jitsiMuted => {
            if (!jitsiMuted) {
              me.jitsiApi.executeCommand('toggleAudio');
            }
          });
          me.jitsiApi.isVideoMuted().then(jitsiMuted => {
            if (!jitsiMuted) {
              me.jitsiApi.executeCommand('toggleVideo');
            }
          });
        };

        me.jitsiApi.executeCommand('toggleFilmStrip');
        me.jitsiApi.executeCommand('setTileView', true);
        me.operatorInfo = info;
        const {
          id: userId,
          email,
          avatarURL,
          displayName,
          formattedDisplayName,
          breakoutRoom,
          roomName,
        } = info;

        console.log('Broadcast: Conference joined: ', info);

        me._participants[userId] = {
          email,
          avatarURL: operatorAvatar || avatarURL,
          displayName: formattedDisplayName || displayName,
        };
        me._currentRoomId = roomName;

        setTimeout(() => {
          me.isJitsiHidden = false;
          me.jitsiApi.executeCommand('setNoiseSuppressionEnabled', {
            enabled: true, // Enable or disable noise suppression.
          });
        }, 350);

        if (breakoutRoom) {
          updateToolbar({ hasMic: true, id: userId });
          me.roomTitle = me.BREAKOUT;
          // me.isJitsiHidden = false;
          me.inCall = true;
          me.isBreakoutRoom = true;
          if (roomName === me?.operatorBreakoutRoomInfo?.id) {
            me.isRoomCreator = true;
          } else {
            me.isRoomCreator = false;
          }
        } else {
          me.isBreakoutRoom = false;
          updateToolbar({ hasMic: false, id: userId });
          me.jitsiApi.executeCommand('muteEveryone', 'audio');
          me.roomTitle = me.LOBBY;
          me.inCall = false;
        }
        me.hasOngoingRoomCall = false;
        me.isSelecting = false;
      });

      // listen to participants joining room
      this.jitsiApi.addListener(
        'participantJoined',
        this.onParticipantJoined.bind(this)
      );
      this.jitsiApi.addListener(
        'participantLeft',
        this.onParticipantLeft.bind(this)
      );
      this.jitsiApi.addListener(
        'videoConferenceLeft',
        this.onVideoConferenceLeft.bind(this)
      );

      this.jitsiApi.addListener('avatarChanged', function (info) {
        const { id: userId, avatarURL } = info;
        // console.log('Broadcast: Avatar Changed: ', info);
        if (me._participants[userId]) {
          me._participants[userId].avatarURL = avatarURL;
        }
      });

      this.jitsiApi.addListener(
        'endpointTextMessageReceived',
        this.listenEndpointMessage.bind(this)
      );

      // this.jitsiApi.addListener('audioMuteStatusChanged', (res) => {
      //   console.log('Broadcast: Mic Status Change: ', res);
      //   const { muted } = res;
      //   if (!muted) {
      //     const { breakoutRoom } = me.operatorInfo || {};
      //     console.log('Broadcast: Mic info: isBreakoutRoom: ', breakoutRoom, ', isMuted: ', muted);

      //     if (!breakoutRoom) {
      //       me.jitsiApi.isAudioMuted().then((jitsiMuted) => {
      //         if (!jitsiMuted) {
      //           console.log('Broadcast: mute participant', jitsiMuted);
      //           me.jitsiApi.executeCommand('toggleAudio');
      //         }
      //       })
      //     }
      //   }
      //   // if (me._jitsiService.broadcastMuted !== muted) {
      //   //   me._jitsiService.setBroadcastMuteStatus(muted);
      //   // }
      // });

      // monitor breakout rooms - can be used to monitor who enters room / leaves room / transfer between broadcast rooms?
      this.jitsiApi.addListener('breakoutRoomsUpdated', async res => {
        const { rooms } = res;
        const breakoutRoomList = transformBreakoutObj(rooms);
        breakoutRoomList.forEach(room => {
          me._breakoutRooms[room.id] = room;
          if (me.roomTitle === me.BREAKOUT && room.id === me._currentRoomId) {
            me._currentRoomName = room.name;
          }
        });

        // console.log('Broadcast: Breakout rooms: ', me._breakoutRooms);

        if (me.roomTitle === me.LOBBY) {
          const breakoutRoomList = transformObjToArr(rooms);
          const operatorRoom = breakoutRoomList.find(
            room => room.name === me.operatorBreakoutRoomName
          );
          // console.log('Broadcast: Breakout Lobby: ', breakoutRoomList, operatorRoom, me.operatorBreakoutRoomName);
          // check if operator's room exists
          if (operatorRoom) {
            const { participants } = operatorRoom || {};
            if (participants) {
              const participantCount = Object.keys(participants)?.length;
              if (participantCount > 0) {
                me.hasOngoingRoomCall = true;
                me.operatorBreakoutRoomInfo = operatorRoom;
              } else {
                me.hasOngoingRoomCall = false;
              }
            }
          }
        }
      });

      // this.jitsiBroadcastMuteSubscription = this._jitsiService.getBroadcastMute().subscribe((muted) => {
      //   if (this.jitsiApi) {
      //     this.jitsiApi.isAudioMuted().then((jitsiMuted) => {
      //       // console.log('jitsi check toggle', jitsiMuted, muted);
      //       if (jitsiMuted !== muted) {
      //         this.jitsiApi.executeCommand('toggleAudio');
      //       }
      //     })
      //   }
      // });

      this._jitsiService.setStatus('ready');
    } catch (e) {
      this._jitsiService.setStatus('fail');
      this._jitsiService.setBroadcastMuteStatus(true);
      this._snackBar.open(
        'Error initializing the broadcast component. Please reload the page.',
        null,
        {
          duration: 5000,
        }
      );
      this.jitsiError = true;
      console.log('Error initializing Jitsi Broadcast', e);
    } finally {
      this.loading = false;
    }
  }
}
