/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MapType } from '../../../shared/others/constant';

export type OverlayLayer = {
  name: string;
  layer: any;
  enabled: boolean;
  icon: any;
};

@Component({
  selector: 'app-panel-layer-control',
  templateUrl: './layer-control.component.html',
  styleUrls: ['./layer-control.component.scss'],
})
export class LayerControlComponent {
  @Input() listOverlayLayers: OverlayLayer[] = [];
  @Input() selectedBasemap: MapType;
  @Input() position = 'top';
  @Output() onClickOverlayEvent = new EventEmitter();
  @Output() onClickMapTypeEvent = new EventEmitter();

  mapTypeList = (Object.entries(MapType) as Array<Array<MapType>>).map(
    ([name, value]) => ({ name, value, icon: `${name.toLowerCase()}.png` })
  );

  constructor() {}

  onClickMapType(basemapId) {
    if (this.selectedBasemap !== basemapId) {
      this.onClickMapTypeEvent.emit(basemapId);
    }
  }

  onClickOverlayItem(layer, index) {
    const output = {
      name: layer.name,
      enabled: !layer.enabled,
      index,
    };
    this.onClickOverlayEvent.emit(output);
  }
}
