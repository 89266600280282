import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MatTableDataSource, MatPaginator, MatTable, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import moment from 'moment';
import { map } from 'rxjs/operators';
// import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserElement } from '../../shared/others/constant';
// import { MapService } from 'src/app/component/shared/services/map.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceDialog } from '../../shared/services/service-modal/service-modal';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/services/auth.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CommonService } from '../../shared/services/common.service';
// import { MatTableExporterDirective } from 'mat-table-exporter';

export interface BusEventElement {
  schedTime: string;
  stopCode: string;
  status: string;
  deviation: string;
  manual: string;
  eventCode: string;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  isNewWindow: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatTableExporterDirective) matTableExporter: MatTableExporterDirective;

  constructor(
    private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public authService: AuthService
  ) {}

  rptForm = new FormGroup({
    // route: new FormControl(null, [Validators.required]),
    // startDate: new FormControl(null, [Validators.required]),
    schedDate: new FormControl(moment().format('YYYY-MM-DD'), [
      Validators.required,
    ]),
  });

  allRoutes = [];
  filteredRoutes: string[] = [];

  displayedColumns: string[] = [
    'select',
    'tripId',
    'tripHeadSign',
    'busStopName',
    'arrivalTimeToStop',
    'trafficMode',
    'actions',
  ];

  dataSource = new MatTableDataSource<BusEventElement>();

  running: boolean = false;
  schedDate: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatTable) table: MatTable<any>;
  selection = new SelectionModel<any>(true, []);
  pageData = {
    id: 2,
    name: 'userManagement',
    displayPageName: 'User Management',
  };
  isHasAccess: boolean = false;
  rightsError = '';

  ngOnInit() {
    if (this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
    setTimeout(() => {
      this.schedDate = moment().format('DD-MM-YYYY');
      this.run();
    }, 0);
    setTimeout(() => {
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      this.rightsError = "You don't have access rights to this module.";
    }, 500);
  }

  filterRoutes(search: string) {
    return this.allRoutes.filter(
      value => value.toLowerCase().indexOf(search.toLowerCase()) === 0
    );
  }

  run() {
    this.exportStarted('Loading...');

    let url =
      environment.apiServerUrl +
      'gtfsupload/busfleetdetails/getTransitDataSetByDateWebUI';
    let body = {
      tripDate: moment(this.rptForm.value.schedDate).format('YYYY-MM-DD'),
    };

    this.httpClient
      .post(url, body)
      .pipe(
        map((data: any) => {
          if (data.length == 0) {
            throw new Error('No record found');
          } else {
            return data;
          }
        })
      )
      .subscribe(
        (data: any) => {
          let t = [];
          if (data instanceof Array) {
            data.forEach(scheduleData => {
              //   let sched = scheduleData.schedule;
              scheduleData.forEach(block => {
                //     block.forEach(trip => {
                t.push(block);
              });
              //   });
            });
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            this.dataSource.data = t;
            this.table.renderRows();

            this.exportCompleted();
          } else {
            this._snackBar.open(data, null, {
              duration: 2000,
            });
            this.running = false;
          }
        },
        (error: any) => {
          this._snackBar.open(error.statusText, null, {
            duration: 2000,
          });
          this.running = false;
        }
      );
  }

  exportStarted(message: string) {
    this.running = true;

    this._snackBar.open(message);
  }

  exportCompleted() {
    this._snackBar.dismiss();

    this.running = false;
  }

  highlightHover(element: any) {
    element.highlighted = !element.highlighted;
  }

  dehighlight(element: any) {
    element.highlighted = false;
  }

  highlight(element: any) {
    element.highlighted = true;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach(row => {
        this.dehighlight(row);
        this.selection.deselect(row);
      });
    } else {
      this.dataSource.data.forEach(row => {
        this.highlight(row);
        this.selection.select(row);
      });
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/system-configuration/account-management/user/2',
      'user management',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  add() {
    let drv: UserElement = {
      userId: null,
      fullName: null,
      firstName: null,
      middleName: null,
      lastName: null,
      identificationId: null,
      identificationDocType: null,
      primaryPhone: null,
      secondaryPhone: null,
      address: null,
      joiningDate: null,
      isTemporary: 'N',
      lastDutyDate: null,
      driverStatus: 'NEW',
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = drv;

    this.dialog.open(UserEditComponent, dialogConfig);
  }

  edit(drv: UserElement) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = drv;

    this.dialog.open(UserEditComponent, dialogConfig);
  }

  delete() {
    const dialogRef = this.dialog.open(ServiceDialog, {
      width: '250px',
      data: {
        title: 'Confirmation',
        content: 'Are you sure to delete?',
        modalType: 'confirm',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
}
