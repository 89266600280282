import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-management',
  templateUrl: './service-management.component.html',
  styleUrls: ['./service-management.component.scss']
})
export class ServiceManagementComponent implements OnInit {

  constructor() { }
  
  ngOnInit() {
  }
}
