import { Pipe, PipeTransform } from '@angular/core';
import { default as moment, Moment } from 'moment';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'momentPipe',
  pure: true,
})
export class MomentPipe implements PipeTransform {
  constructor() {}

  transform(value: string, dateFormat: string): any {
    return moment(value).utcOffset(environment.timezone).format(dateFormat);
  }
}
