import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import { initializeApp, deleteApp as firebaseDeleteApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getMessaging,
  getToken,
  onMessage,
  Messaging,
  MessagePayload,
  deleteToken as firebaseDeleteToken,
} from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapService } from './map.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  app: any;
  messaging: Messaging;
  messagingListener: any;
  currentMessage: Subject<any> = new Subject<any>();
  fcmToken: string;
  analytics: any;
  initialized: boolean = false;
  routes = [];

  constructor(
    private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private mapService: MapService
  ) {}

  initializeFirebase() {
    const firebaseCreds = {
      ...environment.firebase.ADMIN,
      ...environment.firebase.common,
    };
    if (!this.app || !this.messaging) {
      this.app = initializeApp(firebaseCreds);
      this.messaging = getMessaging(this.app);
      this.listen();
    }
  }

  init(agency) {
    if (!environment.sw) {
      return;
    }

    this.initializeFirebase();

    if (!this.initialized) {
      const agencyIds = environment.firebase[agency];
      if (agencyIds) {
        // const firebaseCreds = { ...agencyIds, ...environment.firebase.common};
        // const firebaseCreds = { ...environment.firebase.ADMIN, ...environment.firebase.common };
        // this.app = initializeApp(firebaseCreds);
        // this.messaging = getMessaging();
        try {
          this.requestPermission();
          this.initialized = true;
        } catch (e) {
          console.log('FCM: Error initializing firebase: ', e);
        }
      }
    }
  }

  postFullUrlAction(urlAction, data) {
    // let url = this.configService.apiServerUrl + urlAction;
    return this.httpClient.post(urlAction, data).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  hasNotificationPermission() {
    if ('Notification' in window) {
      let { permission } = Notification;
      if (permission === 'granted') {
        // console.log('Notifications are already allowed.');
        return true;
      }
    }
    // console.log('Notifications are not allowed on this site.');
    return false;
  }

  async requestPermission() {
    const self = this;
    // await firebaseDeleteToken(this.messaging);
    if (this.hasNotificationPermission()) {
      getToken(this.messaging, {
        vapidKey: environment.firebase.common.vapidKey,
      })
        .then(async currentToken => {
          try {
            if (currentToken) {
              localStorage.setItem('fcmToken', currentToken);
              const accessToken = localStorage.getItem('access_token');
              const { agency = '' } = jwt_decode<any>(accessToken);
              self.fcmToken = currentToken;

              // get routes then subscribe
              this.mapService.getInitSwitch().then((data: any) => {
                this.routes = [];
                const newRoutes = [];
                for (const routeType in data) {
                  for (const route of data[routeType]) {
                    newRoutes.push(route.service);
                  }
                }
                this.routes = [...new Set(newRoutes)];

                const subUrl =
                  environment.firebase.nodeUrl + 'topics/subscribe';
                const body = {
                  fcmToken: currentToken,
                  apiKey: environment.jitsi.apiKey,
                  agency,
                  topics: this.routes,
                };
                this.postFullUrlAction(subUrl, body).subscribe(
                  (respData: any) => {
                    console.log(
                      'FCM: Subscribed. Token: ',
                      respData,
                      currentToken
                    );
                  },
                  async error => {
                    throw error;
                    // this._snackBar.open('There was an error connecting to the notification service. Please refresh the page', null, {
                    //   duration: 5000,
                    // });
                  }
                );
              });

              // register to agency topic - fallback?
              const subUrl = environment.firebase.nodeUrl + 'subscribe';
              const body = {
                fcmToken: currentToken,
                apiKey: environment.jitsi.apiKey,
              };
              this.postFullUrlAction(subUrl, body).subscribe(
                (respData: any) => {
                  console.log(
                    'FCM: Subscribed. Token: ',
                    respData,
                    currentToken
                  );
                },
                async error => {
                  throw error;
                  // this._snackBar.open('There was an error connecting to the notification service. Please refresh the page', null, {
                  //   duration: 5000,
                  // });
                }
              );
            } else {
              console.log(
                'FCM: No registration token available. Request permission to generate one.'
              );
            }
          } catch (e) {
            console.log('FCM: Firebase registration error', e);
          }
        })
        .catch(err => {
          console.log('FCM: An error occurred while retrieving token. ', err);
          throw err;
        });
    } else {
      console.log(
        'FCM: Notifications are not allowed on this site. Stopping token registration.'
      );
    }
  }

  listen() {
    this.messagingListener = onMessage(this.messaging, payload => {
      this.currentMessage.next(payload);
    });
  }

  getMessage(): Observable<MessagePayload> {
    return this.currentMessage.asObservable();
  }

  async deleteFirebaseInstance() {
    if (!environment.sw) {
      return;
    }
    try {
      // this.unsubscribe();
      this.deleteToken();
      this?.messagingListener();
      this.initialized = false;
      await this.deleteApp();
    } catch (e) {
      console.log('FCM: Failed to delete Firebase instance');
    }
  }

  unsubscribe(agency) {
    this.initialized = false;
    if (!environment.sw) {
      return;
    }
    if (this.hasNotificationPermission()) {
      getToken(this.messaging, {
        vapidKey: environment.firebase.common.vapidKey,
      })
        .then(currentToken => {
          if (currentToken) {
            console.log('FCM: Unsubscribe - Obtained token: ', currentToken);

            // unsubscribe to agency routes
            try {
              const routeUrl =
                environment.firebase.nodeUrl + 'topics/unsubscribe';
              const routeBody = {
                fcmToken: currentToken,
                apiKey: environment.jitsi.apiKey,
                agency,
                topics: this.routes,
              };
              this.postFullUrlAction(routeUrl, routeBody).subscribe(
                (respData: any) => {
                  console.log(
                    'FCM: Unsubscribed to topics. Result: ',
                    respData
                  );
                  this.routes = [];
                },
                async error => {
                  throw error;
                  // this._snackBar.open('There was an error connecting to the notification service. Please refresh the page', null, {
                  //   duration: 5000,
                  // });
                }
              );
            } catch (e) {
              console.log('FCM: Unsubscribe to topics error: ', e);
            }

            // unsubscribe to agency
            const subUrl = environment.firebase.nodeUrl + 'unsubscribe';
            const body = {
              fcmToken: currentToken,
              agency,
              apiKey: environment.jitsi.apiKey,
            };
            this.postFullUrlAction(subUrl, body).subscribe(
              (respData: any) => {
                console.log('FCM: Unsubscribe result: ', respData);
              },
              error => {
                console.log('FCM: Unsubscribe error: ', error);
              }
            );
          } else {
            console.log('FCM: No registration token available.');
          }
        })
        .catch(err => {
          console.log('FCM: An error occurred while retrieving token. ', err);
        });
    } else {
      console.log(
        'FCM: Unsubscribe - Notifications are not allowed on this site.'
      );
    }
  }

  subscribeRoutes(routes) {
    const self = this;
    if (this.hasNotificationPermission() && this.initialized) {
      getToken(this.messaging, {
        vapidKey: environment.firebase.common.vapidKey,
      })
        .then(async currentToken => {
          try {
            if (currentToken) {
              localStorage.setItem('fcmToken', currentToken);
              const accessToken = localStorage.getItem('access_token');
              const { agency = '' } = jwt_decode<any>(accessToken);
              self.fcmToken = currentToken;

              // subscribe to routes
              const subUrl = environment.firebase.nodeUrl + 'topics/subscribe';
              const body = {
                fcmToken: currentToken,
                apiKey: environment.jitsi.apiKey,
                agency,
                topics: routes,
              };
              this.postFullUrlAction(subUrl, body).subscribe(
                (respData: any) => {
                  console.log('FCM: Subscribed: ', routes, respData);
                },
                async error => {
                  throw error;
                  // this._snackBar.open('There was an error connecting to the notification service. Please refresh the page', null, {
                  //   duration: 5000,
                  // });
                }
              );
            } else {
              console.log(
                'FCM Route: No registration token available. Request permission to generate one.'
              );
            }
          } catch (e) {
            console.log('FCM Route: Firebase registration error', e);
          }
        })
        .catch(err => {
          console.log(
            'FCM Route: An error occurred while retrieving token. ',
            err
          );
          throw err;
        });
    } else {
      console.log(
        'FCM Route: Notifications are not allowed on this site. Stopping token registration.'
      );
    }
  }

  unsubscribeRoutes(routes) {
    const self = this;
    if (this.hasNotificationPermission() && this.initialized) {
      getToken(this.messaging, {
        vapidKey: environment.firebase.common.vapidKey,
      })
        .then(async currentToken => {
          try {
            if (currentToken) {
              localStorage.setItem('fcmToken', currentToken);
              const accessToken = localStorage.getItem('access_token');
              const { agency = '' } = jwt_decode<any>(accessToken);
              self.fcmToken = currentToken;

              // unsubscribe to routes
              const subUrl =
                environment.firebase.nodeUrl + 'topics/unsubscribe';
              const body = {
                fcmToken: currentToken,
                apiKey: environment.jitsi.apiKey,
                agency,
                topics: routes,
              };
              this.postFullUrlAction(subUrl, body).subscribe(
                (respData: any) => {
                  console.log('FCM Route: Unsubscribed: ', routes, respData);
                },
                async error => {
                  throw error;
                  // this._snackBar.open('There was an error connecting to the notification service. Please refresh the page', null, {
                  //   duration: 5000,
                  // });
                }
              );
            } else {
              console.log(
                'FCM Route: No registration token available. Request permission to generate one.'
              );
            }
          } catch (e) {
            console.log('FCM Route: Firebase registration error', e);
          }
        })
        .catch(err => {
          console.log(
            'FCM Route: An error occurred while retrieving token. ',
            err
          );
          throw err;
        });
    } else {
      console.log(
        'FCM Route: Notifications are not allowed on this site. Stopping token registration.'
      );
    }
  }

  updateRouteSubscription(newRoutes) {
    const currRoutes = this.routes;

    const removedRoutes =
      currRoutes.filter(route => newRoutes.indexOf(route) < 0) ?? [];
    const addedRoutes =
      newRoutes.filter(route => currRoutes.indexOf(route) < 0) ?? [];

    // console.log('Current routes', currRoutes);
    // console.log('Selected routes', newRoutes);
    // console.log('Removed routes', removedRoutes);
    // console.log('Added routes', addedRoutes);
    if (addedRoutes?.length > 0) {
      this.subscribeRoutes(addedRoutes);
    }
    if (removedRoutes?.length > 0) {
      this.unsubscribeRoutes(removedRoutes);
    }
    this.routes = newRoutes;
  }

  deleteToken() {
    if (!environment.sw) {
      return;
    }
    if (this.messaging) {
      try {
        firebaseDeleteToken(this.messaging);
      } catch (e) {
        console.error('FCM: Delete Token error: ', e);
      }
    }
  }

  async deleteApp() {
    if (!environment.sw) {
      return;
    }
    firebaseDeleteApp(this.app)
      .then(() => {
        this.messaging = undefined;
        this.app = undefined;
      })
      .catch(e => {
        console.error('FCM: Failed to delete app', e);
      });
  }
}
