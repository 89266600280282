<app-demo-sidebar></app-demo-sidebar>
<div class="component">
  <div class="search-stop-group" [formGroup]="searchForm">
    <div fxLayout="column" fxFlex="35" [ngClass]="{ 'd-none': isExpand }">
      <div class="header-group" fxLayout="row">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
          <div>
            <span class="title-header">Service Route Management</span>
          </div>
        </div>
      </div>
      <div class="left-side stop-group" fxLayout="column" fxFlex>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="mar-bot-20 mar-top-10"
          *ngIf="isModify">
          <div *ngIf="mode === 'edit' || mode === 'view'" class="service-title">
            {{ resultServiceSearch.routeName }}
          </div>
          <div *ngIf="mode === 'new'">
            <mat-form-field
              subscriptSizing="dynamic"
              appearance="outline"
              hideRequiredMarker>
              <mat-label>Service ID</mat-label>
              <input
                type="text"
                matInput
                aria-div="Service ID"
                formControlName="newService" />
            </mat-form-field>
          </div>
          <div *ngIf="isModify" class="edit-actions">
            <button
              mat-stroked-button
              class="btn-med"
              (click)="onReset()"
              [disabled]="!isServiceExist">
              Cancel
            </button>
            <!-- <button
              mat-raised-button
              class="btn-med mar-left-10"
              color="white"
              (click)="onSave2()"
              [disabled]="true">
              Save
              <mat-icon iconPositionEnd>save</mat-icon>
            </button> -->
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="flex-start center">
          <mat-form-field
            fxFlex="1 0 30%"
            subscriptSizing="dynamic"
            appearance="outline"
            hideRequiredMarker
            [ngClass]="{ 'd-none': mode !== 'reset' }">
            <mat-label>Search Service</mat-label>
            <input
              type="text"
              matInput
              aria-div="Service Name"
              name="routeId"
              #searchService
              #searchServiceMatInput="matInput"
              #serviceAutocompleteTrigger="matAutocompleteTrigger"
              [matAutocompleteDisabled]="running"
              [matAutocomplete]="autoSearchService"
              formControlName="searchService"
              [disabled]="running" />
            <button
              mat-icon-button
              matSuffix
              (click)="clearServiceSearch($event)"
              *ngIf="searchService.value"
              aria-label="Clear">
              <mat-icon
                [color]="
                  searchServiceMatInput.focused
                    ? 'primary'
                    : searchServiceMatInput.invalid
                      ? 'error'
                      : ''
                "
                >close</mat-icon
              >
            </button>
            <button
              mat-icon-button
              matSuffix
              [disabled]="running"
              matTooltip="Search Service"
              (click)="searchServiceRoute($event)"
              type="submit">
              <mat-icon
                [color]="
                  searchServiceMatInput.focused
                    ? 'primary'
                    : searchServiceMatInput.invalid
                      ? 'error'
                      : ''
                "
                >search</mat-icon
              >
            </button>
            <mat-autocomplete
              [requireSelection]="false"
              autoActiveFirstOption
              autoSelectActiveOption
              #serviceAutoComplete
              #autoSearchService="matAutocomplete"
              (click)="clickOption()"
              (optionSelected)="selectAllowedRoute($event)">
              <mat-option
                *ngFor="let route of filteredServiceList | async"
                [value]="route">
                {{ routeLabel[route] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div
            fxFlex="nogrow"
            fxLayout="row"
            fxLayoutAlign="flex-start center"
            [ngClass]="{ 'd-none': !isServiceExist }">
            <!-- <div
              class="loop-action mar-bot-10 mar-right-10"
              fxFlex="nogrow"
              fxLayout="row"
              [ngClass]="{ 'd-none': false }"
              fxLayoutAlign="flex-start center">
              <span>Loop service</span>
              <mat-checkbox
                [disabled]="false && mode !== 'new'"
                [checked]="isLoop"
                (change)="loopService($event)"></mat-checkbox>
            </div> -->
            <!-- <div
              class="direction-group text-center mar-bot-10"
              [ngClass]="{
                'bg-direction-1': currDirection === 1,
                'bg-direction-2': currDirection === 2,
                pointer: !isLoop
              }"
              *ngIf="mode !== 'reset'"
              matTooltip="Change Direction"
              [matTooltipDisabled]="isLoop"
              (click)="changeDirection(currDirection === 1 ? 2 : 1)"
              fxLayout="row"
              fxLayoutAlign="center center"
              [matRipple]="!isLoop"
              fxFlex="0 0 auto">
              <div>Direction {{ currDirection }}</div>
              <mat-icon *ngIf="!isLoop" class="mar-left-10" iconPositionEnd>
                autorenew
              </mat-icon>
            </div> -->
          </div>
        </div>

        <div
          class="direction-container"
          [ngClass]="{ 'div-disabled': !isServiceExist }"
          fxLayout="column"
          fxFlex>
          <div
            class="street-group"
            *ngIf="!isSearchStreet"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            [ngClass]="{ 'hidden-element': !isServiceExist }"></div>

          <div *ngIf="isSearchStreet" class="selected-street-group" fxFlex>
            <div
              fxFlex="100"
              *ngIf="currDirection === 1"
              class="left-direction-group"
              fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{ selectedStreetDirection1Data?.streetName }}</span>
                <div class="mar-bot-5">
                  <button
                    mat-raised-button
                    class="mar-right-10"
                    color="accent"
                    (click)="cancelNewSearch()">
                    New Search
                    <mat-icon iconPositionEnd>search</mat-icon>
                  </button>
                  <button
                    mat-raised-button
                    (click)="addDirectionData()"
                    color="white">
                    Add
                    <mat-icon iconPositionEnd>add_location</mat-icon>
                  </button>
                </div>
              </div>

              <div class="left-stops-container">
                <perfect-scrollbar
                  #chatPSDir1
                  class="ps-list-selected-stops"
                  [config]="config"
                  [scrollIndicators]="true">
                  <!-- <div> -->
                  <div *ngFor="let stop of selectedStreetDirection1Data.stops">
                    <mat-checkbox
                      fxFlex="100"
                      class="stop-cb float-left dd-box"
                      [checked]="stop.isChecked"
                      (change)="addSelectedCount($event, stop)">
                      ({{ stop.stopCode }}) {{ stop.stopName }}
                      <div
                        *ngIf="stop.sequence"
                        class="count-stop stop-direction-1">
                        {{ stop.sequence }}
                      </div>
                    </mat-checkbox>
                  </div>
                  <!-- </div> -->
                </perfect-scrollbar>
              </div>
            </div>
            <div fxFlex="100" *ngIf="currDirection === 2">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{ selectedStreetDirection2Data.streetName }}</span>
                <div class="mar-bot-5">
                  <button
                    mat-raised-button
                    class="mar-right-10"
                    color="white"
                    (click)="cancelNewSearch()">
                    Search
                  </button>
                  <button
                    mat-raised-button
                    (click)="addDirectionData()"
                    color="accent">
                    Add
                  </button>
                </div>
              </div>

              <perfect-scrollbar
                #chatPSDir2
                class="ps-list-selected-stops"
                [config]="config"
                [scrollIndicators]="true">
                <div *ngFor="let stop of selectedStreetDirection2Data.stops">
                  <mat-checkbox
                    class="stop-cb float-left"
                    [checked]="stop.isChecked"
                    (change)="addSelectedCount($event, stop)">
                    ({{ stop.stopCode }}) {{ stop.stopName }}
                    <div
                      *ngIf="stop.sequence"
                      class="count-stop stop-direction-2">
                      {{ stop.sequence }}
                    </div>
                  </mat-checkbox>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="right-side"
      [fxFlex]="isExpand ? 100 : 65"
      fxLayoutAlign="start start"
      fxLayout="column">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="width100"
        [ngClass]="{
          isLoop: isLoop,
          loopService: !isLoop
        }">
        <div
          fxLayoutAlign="space-between center"
          [ngClass]="{
            'div-disabled': !isServiceExist,
            'd-none': mode === 'reset'
          }"
          fxFlex="50">
          <div class="pointer">
            <button mat-raised-button (click)="changeMode()">
              {{ routeServiceDisplay === 'table' ? 'MAP' : 'TABLE' }} MODE
              <mat-icon class="mar-left-5" iconPositionEnd>map</mat-icon>
            </button>
          </div>

          <div
            class="text-uppercase service-map"
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngIf="
              routeServiceDisplay === 'map' && mode !== 'reset' && isExpand
            ">
            <div>
              <span *ngIf="mode === 'new'">New </span>
              <span class="font-30 mar-left-5 font-bold">{{
                resultServiceSearch.routeName
              }}</span>
            </div>
          </div>
        </div>

        <div
          *ngIf="routeServiceDisplay === 'map' && mode !== 'reset'"
          fxLayoutAlign="end center"
          fxFlex="50">
          <button
            mat-raised-button
            [disabled]="mode === 'reset'"
            (click)="changeDirection(currDirection === 1 ? 2 : 1)"
            class="mar-right-10 font-md">
            Direction {{ currDirection }}
            <mat-icon *ngIf="!isLoop" class="mar-left-10" iconPositionEnd>
              autorenew
            </mat-icon>
          </button>

          <mat-select
            [disabled]="mode === 'reset'"
            [(value)]="directionProvider"
            (selectionChange)="onChangeDirectionProvider(directionProvider)"
            fxFlex="30"
            fxFlex.md="40"
            fxFlex.lg="40">
            <mat-option value="">Current Route</mat-option>
            <mat-option value="heremap">Algo 1</mat-option>
            <mat-option value="mapbox">Algo 2</mat-option>
          </mat-select>
        </div>

        <div
          (click)="onExpand()"
          class="pointer mar-right-10 zoom-map"
          [ngClass]="{
            'div-disabled': !isServiceExist,
            'hidden-element': routeServiceDisplay !== 'map'
          }">
          <mat-icon *ngIf="isExpand" inline>zoom_in_map</mat-icon>
          <mat-icon *ngIf="!isExpand" inline>zoom_out_map</mat-icon>
        </div>
      </div>

      <div
        class="width100 map-container"
        fxFlex
        [ngClass]="{ 'd-none': routeServiceDisplay !== 'map' }">
        <div class="map-panel mat-elevation-z4" *ngIf="isPanel" [@slideInLeft]>
          <div class="text-right">
            <span
              class="material-icons pointer"
              matTooltip="Close"
              (click)="closePanel()"
              >close</span
            >
          </div>
          <!-- <h5>
            {{ panelData.stopDesc
            }}<sup
              class="material-icons pointer edit-icon-sup"
              matTooltip="Edit"
              (click)="onEditStop(panelData)"
              >edit</sup
            >
          </h5> -->

          <div class="panel-header">
            <h5 *ngIf="currDirection === 1">
              {{ selectedStreetDirection1Data.streetName }}
            </h5>
            <h5 *ngIf="currDirection === 2">
              {{ selectedStreetDirection2Data.streetName }}
            </h5>
          </div>
          <div class="mar-top-10">
            <div>Street Name:</div>
            <div>{{ panelData.streetName }}</div>
          </div>

          <div class="mar-top-10">
            <div>Stop Name:</div>
            <div>{{ panelData.stopName }}</div>
          </div>

          <div class="mar-top-10">
            <div>Stop Code:</div>
            <div>
              <span class="link1">{{ panelData.stopCode }}</span>
            </div>
          </div>

          <!-- <div class="mar-top-10">
            <div>Buses:</div>
            <ng-container *ngFor="let bus of panelData.busService">
              <button mat-raised-button class="minWidthAuto btn-bus">
                {{ bus.routeId }}
              </button>
            </ng-container>
          </div> -->
        </div>
        <ngx-spinner
          bdColor="rgba(27, 30, 39, 1)"
          size="medium"
          color="#fff"
          type="ball-clip-rotate"
          [fullScreen]="false"
          [disableAnimation]="true"></ngx-spinner>
        <div
          id="map-mode"
          #mapElementRef
          [ngClass]="{ 'pos-abs width100': isExpand }"></div>
      </div>

      <div
        fxLayout="row"
        class="direction-panel"
        fxFlex="100"
        [ngClass]="{
          border: !isLoop,
          'div-disabled': !isServiceExist
        }"
        *ngIf="routeServiceDisplay === 'table'">
        <div class="stop-border" fxFlex [ngClass]="{ 'loop-border': isLoop }">
          <div class="inner-border" [ngClass]="{ 'd-none': !isServiceExist }">
            <div class="text-center text-direction-1">
              Direction 1
              <i class="color-dadada font-sm" *ngIf="direction1Count"
                >({{ direction1Count }} stops)</i
              >
            </div>
            <div class="stop-list">
              <perfect-scrollbar
                fxLayout="column"
                #chatPS
                class="ps-stop-list dd"
                [config]="config"
                [scrollIndicators]="true"
                cdkDropList
                cdkDropListLockAxis="y"
                (cdkDropListDropped)="dropStreet($event, direction1Data)">
                <div
                  *ngFor="let streets of direction1Data; let i = index"
                  class="dd-box"
                  cdkDrag>
                  <div
                    class="dd-custom-placeholder-md"
                    *cdkDragPlaceholder></div>
                  <div
                    cdkDropList
                    #direction1DataList="cdkDropList"
                    cdkDropListLockAxis="y"
                    (cdkDropListDropped)="dropDirection($event, i, 1)">
                    <div class="street-name textBlue">
                      {{ streets.streetName }}
                    </div>
                    <div
                      class="cb-stop stop-list-item"
                      *ngFor="let stop of streets.stops"
                      cdkDrag>
                      <div
                        class="dd-custom-placeholder-sm"
                        *cdkDragPlaceholder></div>
                      <mat-checkbox
                        [disableRipple]="true"
                        class="stop-list-item-cb"
                        [checked]="stop.isChecked"
                        (click)="$event.preventDefault()">
                        ({{ stop.stopCode }})
                        {{ stop.stopName }}
                      </mat-checkbox>
                      <div class="distance-indicator" *ngIf="showDistance">
                        {{
                          distanceDisplay(
                            direction1Distance?.[stop?.sequence - 1] ?? 0
                          )
                        }}
                      </div>
                      <mat-icon
                        class="material-icons location-icon pointer text-direction-1"
                        matTooltip="Stop location"
                        (click)="onSearchLocation(stop, 1)"
                        inline>
                        location_searching
                      </mat-icon>
                      <div
                        class="count-stop stop-direction-1"
                        [ngClass]="{ inactive: !(stop?.sequence > 0) }">
                        {{ stop?.sequence > 0 ? stop.sequence : '' }}
                      </div>
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>

        <div class="stop-border" fxFlex="50" [ngClass]="{ 'd-none': isLoop }">
          <div class="inner-border">
            <div class="text-center text-direction-2">
              Direction 2
              <i class="color-dadada font-sm" *ngIf="direction2Count"
                >({{ direction2Count }} stops)</i
              >
            </div>
            <div class="stop-list">
              <perfect-scrollbar
                fxLayout="column"
                #chatPS2
                class="ps-stop-list"
                [config]="config"
                [scrollIndicators]="true"
                cdkDropList
                (cdkDropListDropped)="dropStreet($event, direction2Data)">
                <div
                  *ngFor="let streets of direction2Data; let i = index"
                  class="dd-box"
                  cdkDrag>
                  <div
                    class="dd-custom-placeholder-md"
                    *cdkDragPlaceholder></div>
                  <div
                    cdkDropList
                    #direction1DataList="cdkDropList"
                    (cdkDropListDropped)="dropDirection($event, i, 2)">
                    <div class="street-name text-direction-2">
                      {{ streets.streetName }}
                    </div>
                    <div
                      class="cb-stop stop-list-item"
                      *ngFor="let stop of streets.stops"
                      cdkDrag
                      fxLayout="row"
                      fxLayoutAlign="flex-start center">
                      <!-- [cdkDragDisabled]="i === 0 ? true : false" -->
                      <div
                        class="dd-custom-placeholder-sm"
                        *cdkDragPlaceholder></div>
                      <mat-checkbox
                        class="stop-list-item-cb"
                        [checked]="stop.isChecked"
                        (change)="tickStopCheckbox($event, stop, 2)">
                        ({{ stop.stopCode }})
                        {{ stop.stopName }}
                      </mat-checkbox>
                      <!-- <span *ngIf="stop.sequence" class="count-stop">{{stop.sequence}}</span> -->
                      <!-- <div
                        *ngIf="stop.sequence"
                        class="count-stop stop-direction-2">
                        {{ stop.sequence }}
                      </div> -->
                      <!-- <span class="stop-cb float-left"
                        >({{ stop.stopCode }}) {{ stop.stopName }}</span
                      > -->
                      <div class="distance-indicator" *ngIf="showDistance">
                        {{
                          distanceDisplay(
                            direction2Distance?.[stop?.sequence - 1] ?? 0
                          )
                        }}
                      </div>
                      <mat-icon
                        class="material-icons location-icon pointer text-direction-2"
                        matTooltip="Stop location"
                        (click)="onSearchLocation(stop, 2)"
                        inline>
                        location_searching
                      </mat-icon>
                      <div
                        class="count-stop stop-direction-2"
                        [ngClass]="{ inactive: !(stop?.sequence > 0) }">
                        {{ stop.sequence > 0 ? stop.sequence : '' }}
                      </div>
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>

      <div
        class="btn-footer"
        fxLayoutAlign="end end"
        [ngClass]="{
          width100: routeServiceDisplay === 'map',
          'hidden-element': !isServiceExist
        }">
        <ng-container *ngIf="isExpand">
          <button
            mat-stroked-button
            class="btn-med"
            (click)="onReset()"
            [disabled]="!isServiceExist">
            Cancel
          </button>
          <!-- <button
            mat-raised-button
            class="btn-med mar-left-10"
            color="white"
            (click)="onSave2()"
            [disabled]="true">
            Save
          </button> -->
        </ng-container>
      </div>
    </div>
  </div>
</div>
