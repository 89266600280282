
<div class="modal fade1" id={{modalId}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <form [formGroup]="driverForm" (ngSubmit)="onSubmit()">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">{{data.title}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">

          <perfect-scrollbar class="ps-driver-reg" [config]="config">
            <div class="form-group row">
              <div class="col">
                <label>First Name</label>
                <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.firstName.errors }" />
                <div *ngIf="isvalid && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
              </div>
              <div class="col">
                <label>Middle Name</label>
                <input type="text" formControlName="middleName" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.middleName.errors }" />
                <div *ngIf="isvalid && f.middleName.errors" class="invalid-feedback">
                  <div *ngIf="f.middleName.errors.required">Middle Name is required</div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>Last Name</label>
                <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.lastName.errors }" />
                <div *ngIf="isvalid && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
              </div>
              <div class="col">
                <label>Driver Status</label>
                <!-- <input type="text" formControlName="driverStatus" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.driverStatus.errors }" /> -->
                <select class="form-control" formControlName="driverStatus">
                  <option *ngFor="let status of listDriverStatus" [value]="status.listName">
                    {{status.listName}}
                  </option>
                </select>

                <div *ngIf="isvalid && f.driverStatus.errors" class="invalid-feedback">
                    <div *ngIf="f.driverStatus.errors.required">Driver status is required</div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-6">
                <label>Driver Id</label>
                <input type="text" formControlName="driverId" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.driverId.errors }" />
                <div *ngIf="isvalid && f.driverId.errors" class="invalid-feedback">
                  <div *ngIf="f.driverId.errors.required">Driver id is required</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Address</label>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Block</label>
                    <div formGroupName="address">
                      <input type="text" formControlName="block" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.address['controls']['block'].errors }" />
                      <div *ngIf="isvalid && f.address['controls']['block'].errors" class="invalid-feedback">
                        <div *ngIf="f.address['controls']['block'].errors.required">Block is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <label>Street</label>
                    <div formGroupName="address">
                      <input type="text" formControlName="street" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.address['controls']['street'].errors }" />
                      <div *ngIf="isvalid && f.address['controls']['street'].errors" class="invalid-feedback">
                        <div *ngIf="f.address['controls']['street'].errors.required">Street is required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Floor Unit</label>
                    <div formGroupName="address">
                      <input type="text" formControlName="floorUnit" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.address['controls']['floorUnit'].errors }" />
                      <div *ngIf="isvalid && f.address['controls']['floorUnit'].errors" class="invalid-feedback">
                        <div *ngIf="f.address['controls']['floorUnit'].errors.required">Floor unit is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <label>Postal</label>
                    <div formGroupName="address">
                      <input type="text" formControlName="postal" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.address['controls']['postal'].errors }" />
                      <div *ngIf="isvalid && f.address['controls']['postal'].errors" class="invalid-feedback">
                        <div *ngIf="f.address['controls']['postal'].errors.required">Postal is required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>Primary Phone</label>
                <input type="text" formControlName="primaryPhone" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.primaryPhone.errors }" />
                <div *ngIf="isvalid && f.primaryPhone.errors" class="invalid-feedback">
                    <div *ngIf="f.primaryPhone.errors.required">Primary phone is required</div>
                </div>
              </div>
              <div class="col">
                <label>Secondary Phone</label>
                <input type="text" formControlName="secondaryPhone" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.secondaryPhone.errors }" />
                <div *ngIf="isvalid && f.secondaryPhone.errors" class="invalid-feedback">
                    <div *ngIf="f.secondaryPhone.errors.required">Secondary phone is required</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Identification Document</label>
              <input type="text" formControlName="identificationDoc" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.identificationDoc.errors }" />
              <div *ngIf="isvalid && f.identificationDoc.errors" class="invalid-feedback">
                  <div *ngIf="f.identificationDoc.errors.required">Identification document is required</div>
              </div>
            </div>

            <div class="form-group">
              <label>Identification Id</label>
              <input type="text" formControlName="identificationId" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.identificationId.errors }" />
              <div *ngIf="isvalid && f.identificationId.errors" class="invalid-feedback">
                  <div *ngIf="f.identificationId.errors.required">Identification Id is required</div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>Is Temporary</label>
                <!-- <input type="text" formControlName="isTemporary" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.isTemporary.errors }"/> -->
                <select class="form-control" formControlName="isTemporary">
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                <div *ngIf="isvalid && f.isTemporary.errors" class="invalid-feedback">
                  <div *ngIf="f.isTemporary.errors.required">Is Temporary is required</div>
                </div>
              </div>
              <div class="col">
                <label>Joining Date</label>
                <!-- <input type="text" formControlName="joiningDate" class="form-control" [ngClass]="{ 'is-invalid': isvalid && f.joiningDate.errors }" /> -->
                <div class="input-group">
                  <input class="form-control" placeholder="YYYY-MM-DD" formControlName="joiningDate" ngbDatepicker #d1="ngbDatepicker" [ngClass]="{ 'is-invalid': isvalid && f.joiningDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar-icon" (click)="d1.toggle()" type="button"></button>
                  </div>
                  <div *ngIf="isvalid && f.joiningDate.errors" class="invalid-feedback">
                    <div *ngIf="f.joiningDate.errors.required">Joining Date is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="f.isTemporary.value === 'Y'">
              <label>Last Duty Date</label>
              <div class="input-group">
                <input class="form-control" placeholder="YYYY-MM-DD" formControlName="lastDutyDate" ngbDatepicker #d2="ngbDatepicker" [ngClass]="{ 'is-invalid': isvalid && f.lastDutyDate.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar-icon" (click)="d2.toggle()" type="button"></button>
                </div>
                <div *ngIf="isvalid && f.lastDutyDate.errors" class="invalid-feedback">
                  <div *ngIf="f.lastDutyDate.errors.required">Last Duty Date is required</div>
                </div>
              </div>
            </div>

          </perfect-scrollbar>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" #btnClose data-dismiss="modal" (click)="closeModal()">Close</button>
          <button type="submit" class="btn btn-primary">Register</button>
        </div>
      </div>

    </div>
  </form>
  </div>