import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { PushNotificationService } from 'src/app/component/shared/services/push-notification.service';

@Component({
  selector: 'app-emergency-call-wrapper',
  templateUrl: './emergency-call-wrapper.component.html',
  styleUrls: ['./emergency-call-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyCallWrapperComponent implements OnInit, OnDestroy {
  // randomNumber = Math.random() * (100 - 1) + 1;

  constructor(
    public pushNotificationService: PushNotificationService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.listenToEmergency();
  }

  emergencySubscription;
  listenToEmergency() {
    this.emergencySubscription =
      this.pushNotificationService.emergencyObservable?.subscribe(() => {
        this._cdr.markForCheck();
      });
  }

  closeEmergency(rid) {
    console.log('close emergency: ', rid);
    this.pushNotificationService.emergencyEnd(rid);
  }

  trackByRid(index, item) {
    return item?.rid;
  }

  ngOnDestroy() {
    this.emergencySubscription?.unsubscribe?.();
    this.emergencySubscription = undefined;
  }
}
