import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';
import OlMap from 'ol/Map';
import OlZoom from 'ol/control/Zoom';
import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import OlView from 'ol/View';
import { fromLonLat } from 'ol/proj';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { VectorImage as VectorImageLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { MapService } from 'src/app/component/shared/services/map.service';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vehicle-assign',
  templateUrl: './vehicle-assign.component.html',
  styleUrls: ['./vehicle-assign.component.scss'],
})
export class VehicleAssignComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mapService: MapService,
    public dialogRef: MatDialogRef<VehicleAssignComponent>
  ) {}

  files: any = null;
  // form: FormGroup;

  form = this.formBuilder.group({
    type: new FormControl('Accident', [Validators.required]),
    subType: new FormControl('Chain Collision', [Validators.required]),
    startTime: new FormControl(moment('03/03/2020').format('YYYY-MM-DD'), [
      Validators.required,
    ]),
    endTime: new FormControl(moment('03/03/2020').format('YYYY-MM-DD'), [
      Validators.required,
    ]),
    roadName: new FormControl('Commonwealth Avenue', [Validators.required]),
    locationType: new FormControl('Major road', [Validators.required]),
    startLat: new FormControl('1.298625', [Validators.required]),
    startLon: new FormControl('103.802946', [Validators.required]),
    endLat: new FormControl('1.298067', [Validators.required]),
    endLon: new FormControl('103.803500', [Validators.required]),
    // congestionStartTime: new FormControl('13:40', [Validators.required]),
    // congestionEndTime: new FormControl('14:05', [Validators.required]),
    description: new FormControl('Multiple car affected', [
      Validators.required,
    ]),
  });

  busServices = ['4', '16', '20'];

  running: boolean = false;
  // isUploaded: boolean = false;
  // uploadedMessage: String = '';
  isGTFS: boolean = false;

  typeList = [
    { value: 'Accident', viewValue: 'Accident' },
    { value: 'Diversion', viewValue: 'Diversion' },
    { value: 'Road Block', viewValue: 'Road Block' },
  ];
  subtypeList = [
    { value: 'Chain Collision', viewValue: 'Chain Collision' },
    { value: 'Accident', viewValue: 'Accident' },
    { value: 'Police Activities', viewValue: 'Police Activities' },
  ];

  //start chart data
  single: any[] = [
    {
      name: 'Low',
      value: 100,
    },
    {
      name: 'Medium',
      value: 200,
    },
    {
      name: 'High',
      value: 300,
    },
    // {
    //   "name": "UK",
    //   "value": 5200000
    // },
    // {
    //   "name": "Italy",
    //   "value": 7700000
    // },
    // {
    //   "name": "Spain",
    //   "value": 4300000
    // }
  ];

  view: any[] = [300, 200];
  // legend: boolean = false;
  // legendPosition: string = 'below';

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  //end chart data

  map: OlMap;
  viewAnimate: OlView = new OlView({
    center: fromLonLat(environment.map.locationLonLat),
    zoom: 12,
    // minZoom: 8,
    maxZoom: 16,
    projection: 'EPSG:900913',
  });

  @ViewChild('PSAlarm', { static: true }) PSAlarm: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {};

  ngOnInit() {
    console.log(this.data);
    // this.form = this.formBuilder.group({
    //   // file: ['', Validators.required],
    //   effectiveFromDate: ['', Validators.required],
    //   effectiveTillDate: ['', Validators.required]
    // });

    var OSMLayer = new OlTileLayer({
      //environment.map.osmLayer // 'assets/maps/Tiles/{z}/{x}/{y}.png'
      source: new OSM({
        url: environment.map.osmLayer,
      }),
    });
    this.map = new OlMap({
      controls: [new OlZoom()],
      target: 'map',
      view: this.viewAnimate,
    });
    this.map.addLayer(OSMLayer);

    var alarmLayer = new VectorImageLayer({
      source: new VectorSource({
        features: [],
      }),
      className: 'alarmLayer',
      properties: {
        name: 'alarmLayer',
      },
    });

    var mapData = {
      imgSrc: 'assets/images/icon/warning-32.png',
      lat: 1.298625,
      lon: 103.802946,
      name: 'alarmLayer',
      message: 'Commonwealth Avenue',
    };
    this.mapService.addMapMarkWithText(alarmLayer, mapData);
    this.map.addLayer(alarmLayer);
    this.mapService.centerLayerMap(this.map, alarmLayer);
  }

  // uploadFile(event) {
  //   // for (let index = 0; index < event.length; index++) {
  //   //   const element = event[index];
  //   //   this.files = element;
  //   // }
  //   // console.log(event.item(0));
  //   // var selectedFiles = event.target.files;

  //   this.isUploaded = false
  //   this.files = event.item(0);//selectedFiles.item(0);;
  // }

  deleteAttachment() {
    this.files = null;
    // this.files.splice(index, 1)
  }

  btnUploadFile() {
    this.running = true;

    if (this.files === null || !this.form.valid) {
      this.running = false;
      return;
    }
    var body = new FormData();

    var urlUploadFile = environment.apiUrl.uploadSchedule;

    // if(this.files.type === 'application/x-zip-compressed') {
    //   urlUploadFile = environment.apiUrl.uploadScheduleGTFS;
    // }

    if (this.isGTFS) {
      body.append('isGTFS', 'Y');
      // urlUploadFile = environment.apiUrl.uploadScheduleGTFS;
    } else {
      body.append('isGTFS', 'N');
    }

    // body.append('file',this.files);
    body.append('file', this.files);
    body.append(
      'effectiveFromDate',
      moment(this.form.value.effectiveFromDate).format('YYYY-MM-DD')
    );
    body.append(
      'effectiveTillDate',
      moment(this.form.value.effectiveTillDate).format('YYYY-MM-DD')
    );

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>"
    //   })
    // };

    // const headers = new HttpHeaders();
    // headers.set('Content-Type', undefined);

    // this.httpClient.post(this.configService.apiServerUrl + 'gtfsupload/uploadClarkScheduleFile', body).pipe(
    // this.httpClient.post(this.configService.apiServerUrl + 'gtfsupload/v1/uploadXLSScheduleZipFile', body).pipe(
    this.httpClient
      .post(urlUploadFile, body)
      .pipe(
        map((data: any) => {
          console.log(data);
          // if (data.length == 0) {
          //   throw new Error('No record found');
          // } else {
          //   return data;
          // }
        })
      )
      .subscribe(
        (data: any) => {
          this.form.reset();
          this.deleteAttachment();
          this.running = false;
          // this.isUploaded = true;
          // this.uploadedMessage = 'Uploading Successful';
        },
        (error: any) => {
          console.log(error);
          this.running = false;
          // this.isUploaded = true;
          // this.uploadedMessage = error.error.message;
        }
      );
  }
}
