<div class="device-monitoring-group">
  <div>
    <h3 class="title-header">Vehicle List<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div>

  <!-- <div class="container no-padding" *ngIf="!authService.isAdmin()">
    <div class="col message">
      <h3>Only administrator has access rights to this module.</h3>  
    </div>
  </div> -->

  <!-- <div *ngIf="authService.isAdmin()"> -->
    <div class="left-aligned">
      <mat-form-field floatLabel="always">
        <input matInput (keyup)="filter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <button mat-raised-button type="button" (click)="refresh()">Refresh</button>
    </div>

    <div class="table-wrapper">
      <!-- <table mat-table [dataSource]="dataSource" matTableExporter matSort #exporter="matTableExporter"> -->
      <table mat-table [dataSource]="dataSource" matSort >

        <ng-container matColumnDef="deviceId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Device ID</th>
          <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
        </ng-container>

        <ng-container matColumnDef="busRegNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle License Plate</th>
          <td mat-cell *matCellDef="let element"> {{element.busRegNo}} </td>
        </ng-container>

        <ng-container matColumnDef="routeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Route Name</th>
          <td mat-cell *matCellDef="let element"> {{element.routeName}} </td>
        </ng-container>

        <ng-container matColumnDef="simCardId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sim Card ID</th>
          <td mat-cell *matCellDef="let element"> {{element.simCardId}} </td>
        </ng-container>

        <ng-container matColumnDef="simPhoneNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sim Phone No</th>
          <td mat-cell *matCellDef="let element"> {{element.simPhoneNo}} </td>
        </ng-container>

        <ng-container matColumnDef="reportedTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Reported Time</th>
          <td mat-cell *matCellDef="let element"> {{format(element.reportedTime)}} </td>
        </ng-container>

        <ng-container matColumnDef="curr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{curr}}</th>
          <td mat-cell *matCellDef="let element" class="bg-gray"> 
            <span *ngIf="element.rxMegaBytesMonthly + element.txMegaBytesMonthly === 0" class="no-data">No Data</span>
            <span *ngIf="element.rxMegaBytesMonthly + element.txMegaBytesMonthly !== 0">{{element.rxMegaBytesMonthly + element.txMegaBytesMonthly | number}} MB</span> </td>
        </ng-container>

        <ng-container matColumnDef="prev">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{prev}}</th>
          <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.prevDataConsume === 0 || element.prevDataConsume === undefined" class="no-data">No Data</span>
            <span *ngIf="element.prevDataConsume !== 0 && element.prevDataConsume !== undefined" [ngClass]="{'no-data': element.prevDataConsume === 0 && element.prevDataConsume === undefined}">{{element.prevDataConsume | number}} MB</span> 
          </td>
        </ng-container>

        <ng-container matColumnDef="befPrev">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{beforePrev}}</th>
          <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.beforePrevDataConsume === 0 || element.beforePrevDataConsume === undefined" class="no-data">No Data</span>
            <span *ngIf="element.beforePrevDataConsume !== 0 && element.beforePrevDataConsume !== undefined" [ngClass]="{'no-data': element.beforePrevDataConsume === 0 && element.beforePrevDataConsume === undefined}">{{element.beforePrevDataConsume | number}} MB</span> 
          </td>
        </ng-container>

        <ng-container matColumnDef="header-row-first-group">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="6"><div class="text-center"></div></th>
          <td mat-cell *matCellDef="let element"> </td>
        </ng-container>

        <ng-container matColumnDef="header-row-second-group">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="bg-gray text-center">Data Consumptions</th>
          <td mat-cell *matCellDef="let element"> {{element.rxMegaBytesMonthly + element.txMegaBytesMonthly | number}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="driverId">
          <th mat-header-cell *matHeaderCellDef>Driver Name</th>
          <td mat-cell *matCellDef="let element"> {{element.driverId}} </td>
        </ng-container>

        <ng-container matColumnDef="routeName">
          <th mat-header-cell *matHeaderCellDef>Service/Block/Trip</th>
          <td mat-cell *matCellDef="let element"> {{element.routeName}} </td>
        </ng-container>

        <ng-container matColumnDef="tripStatus">
          <th mat-header-cell *matHeaderCellDef>Service/Block/Trip Status</th>
          <td mat-cell *matCellDef="let element"> {{element.tripStatus}} </td>
        </ng-container>

        <ng-container matColumnDef="swVersion">
          <th mat-header-cell *matHeaderCellDef>Sw Version</th>
          <td mat-cell *matCellDef="let element"> {{element.swVersion}} </td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 'header-row-second-group']"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  <!-- </div> -->
</div>