<div id="home2-group">
  <div class="bbox-group" fxLayout="column" fxLayoutAlign="space-between start" >
    <mat-form-field appearance="outline">
      <input matInput [value]="coords1.lat + ',' + coords1.lng">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input matInput [value]="coords2.lat + ',' + coords2.lng">
    </mat-form-field>
    <button mat-raised-button (click)="addRectangleToMap()">Preview BBox</button>
  </div>
  <div #map class="map"></div>
  <div class="stop-group" *ngIf="stopList.length > 0">
    <h3>Stops</h3>
    <div fxLayout="row" fxLayoutAlign="space-between center" >
      <div>Name</div>
      <!-- <div>Location</div> -->
    </div>

    <div class="stop-list">
      <div *ngFor="let stop of stopList" fxLayout="row" fxLayoutAlign="space-between center">
        <div>{{stop.title}}</div>
        <div>{{stop.position.lat}} {{stop.position.lng}}</div>
      </div>
    </div>
    <!-- <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let stop of stopList">
        <div>{{stop.title}}</div>
        <div>{{stop.position.lat}} {{stop.position.lng}}</div>
      </mat-list-item>
    </mat-list> -->
  </div>
</div>