export const INTERFACE_CONFIG_OVERWRITE = {
    // TILE_VIEW_MAX_COLUMNS: 0,
    // DEFAULT_BACKGROUND: '#2a2b30',
    HIDE_INVITE_MORE_HEADER: true,
    DISPLAY_WELCOME_FOOTER: false,
    DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
    DISPLAY_WELCOME_PAGE_CONTENT: false,
    DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    SHOW_JITSI_WATERMARK: false,
    HIDE_DEEP_LINKING_LOGO: true,
    SHOW_BRAND_WATERMARK: false,
    SHOW_WATERMARK_FOR_GUESTS: false,
    SHOW_POWERED_BY: false,
}

export const CONFIG_OVERWRITE = {
    disableReactions: true,
    disablePolls: true,
    readOnlyName: true,
    prejoinConfig: {
        enabled: false,
        hideDisplayName: true,
    },
    startWithVideoMuted: true,
    // toolbarConfig: {
    //     alwaysVisible: true
    // },
    hiddenPremeetingButtons: ['select-background', 'invite', 'camera'],
    lobby: {
        autoKnock: false,
        enableChat: false,
    },
    defaultRemoteDisplayName: 'User',
    disableProfile: true,
    hideConferenceSubject: false,
    conferenceInfo: {
        autoHide: [
            'subject',
            'conference-timer',
            'participants-count',
            'e2ee',
            'transcribing',
            'video-quality',
            'insecure-room',
            'highlight-moment',
            'top-panel-toggle',
        ],
    },
    speakerStats: {
        disabled: true,
    },
    hideParticipantsStats: true,
    analytics: {
        disabled: true,
    },
    apiLogLevels: ['warn', 'error'],
    logging: {
        // Default log level for the app and lib-jitsi-meet.
        defaultLogLevel: 'error',
        // Option to disable LogCollector (which stores the logs on CallStats).
        disableLogCollector: true,
        // Individual loggers are customizable.
        loggers: {
        // The following are too verbose in their logging with the default level.
            'modules/RTC/TraceablePeerConnection.js': 'error',
            'modules/statistics/CallStats.js': 'error',
            'modules/xmpp/strophe.util.js': 'error',
            'modules/statistics/LocalStatsCollector.js': 'error',
            'modules/browser/BrowserCapabilities.js': 'error',
        }
    },
    participantsPane: {
        // Hides the moderator settings tab.
        hideModeratorSettingsTab: true,
        // Hides the more actions button.
        hideMoreActionsButton: true,
        // Hides the mute all button.
        hideMuteAllButton: true,
    },
    breakoutRooms: {
        // Hides the add breakout room button. This replaces `hideAddRoomButton`.
        hideAddRoomButton: true,
        // Hides the auto assign participants button.
        hideAutoAssignButton: true,
        // Hides the join breakout room button.
        hideJoinRoomButton: true,
    },
    remoteVideoMenu: {
        // Whether the remote video context menu to be rendered or not.
        disabled: true,
        // If set to true the 'Kick out' button will be disabled.
        disableKick: true,
        // If set to true the 'Grant moderator' button will be disabled.
        disableGrantModerator: true,
        // If set to true the 'Send private message' button will be disabled.
        disablePrivateChat: true,
    },
    notifications: [
        'connection.CONNFAIL', // shown when the connection fails,
        'dialog.cameraNotSendingData', // shown when there's no feed from user's camera
        'dialog.kickTitle', // shown when user has been kicked
        'dialog.liveStreaming', // livestreaming notifications (pending, on, off, limits)
        'dialog.lockTitle', // shown when setting conference password fails
        'dialog.maxUsersLimitReached', // shown when maximmum users limit has been reached
        'dialog.micNotSendingData', // shown when user's mic is not sending any audio
        'dialog.passwordNotSupportedTitle', // shown when setting conference password fails due to password format
        'dialog.recording', // recording notifications (pending, on, off, limits)
        'dialog.remoteControlTitle', // remote control notifications (allowed, denied, start, stop, error)
        'dialog.reservationError',
        'dialog.serviceUnavailable', // shown when server is not reachable
        'dialog.sessTerminated', // shown when there is a failed conference session
        'dialog.sessionRestarted', // show when a client reload is initiated because of bridge migration
        'dialog.tokenAuthFailed', // show when an invalid jwt is used
        'dialog.transcribing', // transcribing notifications (pending, off)
        'dialOut.statusMessage', // shown when dial out status is updated.
        'liveStreaming.busy', // shown when livestreaming service is busy
        'liveStreaming.failedToStart', // shown when livestreaming fails to start
        'liveStreaming.unavailableTitle', // shown when livestreaming service is not reachable
        'lobby.joinRejectedMessage', // shown when while in a lobby, user's request to join is rejected
        // 'lobby.notificationTitle', // shown when lobby is toggled and when join requests are allowed / denied
        // 'notify.chatMessages', // shown when receiving chat messages while the chat window is closed
        'notify.disconnected', // shown when a participant has left
        'notify.connectedOneMember', // show when a participant joined
        'notify.connectedTwoMembers', // show when two participants joined simultaneously
        'notify.connectedThreePlusMembers', // show when more than 2 participants joined simultaneously
        'notify.leftOneMember', // show when a participant left
        'notify.leftTwoMembers', // show when two participants left simultaneously
        'notify.leftThreePlusMembers', // show when more than 2 participants left simultaneously
        // 'notify.grantedTo', // shown when moderator rights were granted to a participant
        'notify.hostAskedUnmute', // shown to participant when host asks them to unmute
        'notify.invitedOneMember', // shown when 1 participant has been invited
        'notify.invitedThreePlusMembers', // shown when 3+ participants have been invited
        'notify.invitedTwoMembers', // shown when 2 participants have been invited
        'notify.kickParticipant', // shown when a participant is kicked
        'notify.linkToSalesforce', // shown when joining a meeting with salesforce integration
        'notify.moderationStartedTitle', // shown when AV moderation is activated
        'notify.moderationStoppedTitle', // shown when AV moderation is deactivated
        'notify.moderationInEffectTitle', // shown when user attempts to unmute audio during AV moderation
        'notify.moderationInEffectVideoTitle', // shown when user attempts to enable video during AV moderation
        'notify.moderationInEffectCSTitle', // shown when user attempts to share content during AV moderation
        'notify.mutedRemotelyTitle', // shown when user is muted by a remote party
        // 'notify.mutedTitle', // shown when user has been muted upon joining,
        'notify.newDeviceAudioTitle', // prompts the user to use a newly detected audio device
        'notify.newDeviceCameraTitle', // prompts the user to use a newly detected camera
        'notify.participantWantsToJoin', // shown when lobby is enabled and participant requests to join meeting
        'notify.passwordRemovedRemotely', // shown when a password has been removed remotely
        'notify.passwordSetRemotely', // shown when a password has been set remotely
        'notify.raisedHand', // shown when a partcipant used raise hand,
        'notify.startSilentTitle', // shown when user joined with no audio
        'notify.unmute', // shown to moderator when user raises hand during AV moderation
        'notify.videoMutedRemotelyTitle', // shown when user's video is muted by a remote party,
        'prejoin.errorDialOut',
        'prejoin.errorDialOutDisconnected',
        'prejoin.errorDialOutFailed',
        'prejoin.errorDialOutStatus',
        'prejoin.errorStatusCode',
        'prejoin.errorValidation',
        'recording.busy', // shown when recording service is busy
        'recording.failedToStart', // shown when recording fails to start
        'recording.unavailableTitle', // shown when recording service is not reachable
        'toolbar.noAudioSignalTitle', // shown when a broken mic is detected
        'toolbar.noisyAudioInputTitle', // shown when noise is detected for the current microphone
        // 'toolbar.talkWhileMutedPopup', // shown when user tries to speak while muted
        'transcribing.failedToStart', // shown when transcribing fails to start
    ],

    gravatar: {
        // True if Gravatar should be disabled.
        disabled: true,
    },
    disableShortcuts: true,
    // disabledSounds: [
    //     'TALK_WHILE_MUTED_SOUND'
    // ],
    // disabledNotifications: [
    //     'toolbar.talkWhileMutedPopup'
    // ],
    p2p: {
        enabled: false
    }
}