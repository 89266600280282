import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  COLUMN_LIST,
  IDENTIFICATION_TYPES,
  STATUS_LIST,
  searchKeysList,
} from './driver-list.constants';

import {
  Driver,
  DriverService,
} from 'src/app/component/shared/services/openapi/roster';
import moment, { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import isSubstring from 'src/app/component/shared/others/isSubstring';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/component/shared/services/confirm-modal/confirm-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

interface DriverFilter {
  status: Driver.StatusEnum | string;
  search: string;
}

@Component({
  selector: 'app-driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss'],
})
export class DriverListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private _driverService: DriverService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.dataSource = new MatTableDataSource<any>();
    this.dataSource.sortData = this.sortDriver;
    this.dataSource.filterPredicate = this.driverFilter;
  }

  @ViewChild('tabledrawer') sideDrawer: MatSidenav;
  @ViewChild('drivertable') matDriverTable: MatTable<Driver>;

  displayedColumns: string[] = [
    'userid',
    'full_name',
    'identification',
    'status',
    'date_joined',
    'Actions',
  ];
  columnList = COLUMN_LIST;
  identificationList = IDENTIFICATION_TYPES;
  dataPage;
  filteredPage;
  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isLoading: boolean = true;
  isNewWindow: boolean = false;

  /** Search and Filter Variables */
  searchFilter: string = '';
  searchFilterValue: string = '';
  searchFilterSubject = new Subject<string | undefined>();
  searchFilterSubscription: Subscription;
  statusList = STATUS_LIST;
  selectedStatus: Driver.StatusEnum | string = '';

  driverDetails: Driver = undefined;
  selectedDriver: string = '';
  driverForm: FormGroup;

  ngOnInit() {
    this.getDrivers();
    this.startSearchSubscription();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.sort.sort({ id: 'date_joined', start: 'desc' } as MatSortable);
    this.dataSource.paginator = this.paginator;
    this.initDriverForm();
  }

  initDriverForm() {
    this.driverForm = this.fb.group({
      userid: [''],
      first_name: ['', Validators.required],
      middle_name: ['', Validators.required],
      family_name: ['', Validators.required],
      residential_address: ['', Validators.required],
      mailing_address: ['', Validators.required],
      status: ['', Validators.required],
      country_code: ['', Validators.required],
      identification: this.fb.group({
        identification: ['', Validators.required],
        type: ['', Validators.required],
      }),
      contact_numbers: this.fb.array([]),
    });
  }

  /** Fetch function */
  getDrivers() {
    this.isLoading = true;

    this._driverService.getdrivers(undefined, 100, 0).subscribe(
      driverList => {
        console.log('Driver: Get Success: ', driverList);
        this.dataSource.data = driverList;
        this.dataPage = JSON.parse(JSON.stringify(driverList));
        this.onApplyFilter();
        this.isLoading = false;
      },
      e => {
        console.log('Driver: Failed to get drivers: ', e);
      }
    );

    // setTimeout(() => {
    //   this.dataSource.data = DriverList;
    //   this.dataPage = JSON.parse(JSON.stringify(DriverList));
    //   this.onApplyFilter();
    //   this.isLoading = false;
    // }, 100);
  }

  /** Action Functions */
  drawerOpen: boolean = false;
  isEdit: boolean = false;
  onEdit(record: Driver) {
    // this.sideDrawer.open();
    this.driverDetails = record;
    this.selectedDriver = record.userid;
    this.onUpdateDriverFormData(record);
    this.drawerOpen = true;
    this.toggleEdit(true);
  }

  toggleEdit(value: boolean) {
    console.log('list edit', value);
    this.isEdit = value;
  }

  onView(record: Driver) {
    // this.sideDrawer.open();
    this.isEdit = false;
    this.driverDetails = record;
    this.selectedDriver = record.userid;
    this.onUpdateDriverFormData(record);
    this.drawerOpen = true;
  }

  onUpdateDriverFormData(record: Driver) {
    this.driverForm.markAsPristine();
    const {
      userid,
      family_name,
      first_name,
      middle_name,
      status,
      residential_address,
      mailing_address,
      country_code,
      identification,
      contact_numbers,
    } = record || {};
    const { identification: identificationId, type: identificationType } =
      identification || {};

    const contactDetailsFormArray = this.driverForm.get(
      'contact_numbers'
    ) as FormArray;

    if (userid) {
      this.driverForm.patchValue({
        userid,
        family_name,
        first_name,
        middle_name,
        status,
        residential_address,
        mailing_address,
        country_code,
        identification: {
          identification: identificationId,
          type: identificationType,
        },
      });
      contactDetailsFormArray.clear();
      contact_numbers.forEach(contact =>
        contactDetailsFormArray.push(this.fb.group(contact))
      );
    } else {
      this.driverForm.patchValue({
        userid: '',
        family_name: '',
        first_name: '',
        middle_name: '',
        status: '',
        residential_address: '',
        mailing_address: '',
        country_code: '',
        identification: {
          identification: '',
          type: '',
        },
        contact_numbers: [],
      });
      contactDetailsFormArray.clear();
    }
  }

  onSave(record: Driver) {
    this.isLoading = true;
    const { userid } = record || {};
    const driverRecordIndex: number = this.dataPage.findIndex(
      driver => driver.userid === userid
    );
    this.driverDetails = record;
    this.dataPage[driverRecordIndex] = record;
    this.dataSource.data = JSON.parse(JSON.stringify(this.dataPage));
    this.snackBar.open(`${userid} has been successfully updated`, null, {
      duration: 5000,
    });
    this.isLoading = false;
    this.isEdit = false;
    this.matDriverTable.renderRows();
  }

  onClose() {
    this.selectedDriver = '';
    this.drawerOpen = false;
    this.driverDetails = {};
    this.isEdit = false;
  }

  onDelete(record: Driver) {
    const { userid, full_name } = record;
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        header: 'Delete Driver Data',
        message: `Are you sure you want to delete ${full_name}'s records?`,
        textConfirm: 'Delete',
        textCancel: 'Cancel',
        mode: 'warn',
      },
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.isLoading = true;
        setTimeout(() => {
          const filteredData = this.dataPage.filter(
            (record: Driver) => record.userid !== userid
          );
          this.dataPage = JSON.parse(JSON.stringify(filteredData));
          this.dataSource.data = filteredData;
          this.isLoading = false;

          // remove details
          this.onClose();

          this.snackBar.open(
            `Driver ${userid}: ${full_name}'s data has been deleted`,
            null,
            {
              duration: 5000,
            }
          );
        }, 1000);
      }
    });
  }

  /** Sort and Filter Functions */
  sortDriver(records: Driver[], sort: MatSort): Driver[] {
    const { active, direction } = sort;
    if (!active || direction === '') {
      return records;
    }
    return records.sort((a: Driver, b: Driver) => {
      let comparisonResult = 0;
      switch (active) {
        case 'identification':
          const identificationA = a.identification;
          const identificationB = b.identification;
          const { identification: idA, type: typeA } = identificationA;
          const { identification: idB, type: typeB } = identificationB;

          if (!!identificationA && !identificationB) return -1;
          if (!identificationA && !!identificationB) return 1;
          const compareType = typeA.localeCompare(typeB);
          if (compareType === 0) {
            comparisonResult = idA.localeCompare(idB);
          } else {
            comparisonResult = compareType;
          }
          break;
        case 'date_joined':
          let timeA: Moment = moment(a[active]).utcOffset(environment.timezone);
          let timeB: Moment = moment(b[active]).utcOffset(environment.timezone);
          if (timeA.isValid() && !timeB.isValid()) return -1;
          if (!timeA.isValid() && timeB.isValid()) return 1;
          if (timeA.isSame(timeB)) return 0;
          comparisonResult = timeA.isAfter(timeB) ? 1 : -1;
          break;
        default:
          comparisonResult = a[active].localeCompare(b[active]);
      }
      return comparisonResult * (direction === 'asc' ? 1 : -1);
    });
  }

  driverFilter(record: Driver, filter: string) {
    const parsedFilter: DriverFilter = JSON.parse(filter);
    const { search, status } = parsedFilter;
    const { status: recordStatus } = record;
    if (search.length > 0) {
      const matchSearch = Object.entries(record).some(
        ([key, value]: [string, any]): boolean => {
          if (searchKeysList.indexOf(key) < 0) return false;
          if (key === 'userid' || key === 'full_name') {
            return isSubstring(value, search);
          }
        }
      );
      if (!matchSearch) return false;
    }
    if (status && status?.length > 0) {
      if (status !== recordStatus) return false;
    }
    return true;
  }

  trackDriver(index, item) {
    return item.userid;
  }

  onSearchFilterInput(data) {
    this.searchFilterSubject.next(data);
  }

  clearFilterTextBox(event) {
    event.stopPropagation();
    this.searchFilter = '';
    this.searchFilterSubject.next('');
  }

  onStatusChange(event: MatSelectChange) {
    const { value } = event;
    this.selectedStatus = value;
    this.onApplyFilter();
  }

  onApplyFilter() {
    const filters: DriverFilter = {
      status: this.selectedStatus,
      search: this.searchFilterValue,
    };

    const jsonString = JSON.stringify(filters);
    this.dataSource.filter = jsonString;
  }

  startSearchSubscription() {
    this.searchFilterSubscription = this.searchFilterSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(result => {
        const searchQuery = result?.toString()?.trim()?.toLowerCase();
        this.searchFilterValue = searchQuery;
        this.onApplyFilter();
      });
  }

  /** Window function */
  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/driver-management/list/2',
      'Driver List',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  ngOnDestroy() {
    this.searchFilterSubscription?.unsubscribe?.();
  }
}
