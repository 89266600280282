import { Injectable } from '@angular/core';
import localforage from 'localforage';

export const localForageConfig = {
  driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name        : 'bus-db',
  version     : 1.0,
  storeName   : 'bus-store', // Should be alphanumeric, with underscores.
  description : 'DB for BusApp'
}

@Injectable({
  providedIn: 'root'
})
export class LocalForageService {

  constructor() { }

  init() {
    localforage.config(localForageConfig);
  }

  getItem<T = any>(key: string): Promise<T> {
    return localforage.getItem<T>(key);
  }

  setItem<T = any>(key: string, data: T): Promise<T | null> {
    return localforage.setItem(key, data);
  }

  clear(): Promise<void> {
    return localforage.clear();
  }

}
