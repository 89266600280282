<div class="duty-roster-group custom-page-scroll">
  <div class="header-group" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <div>
        <span class="title-header">Driver Duty Roster</span>
        <sup
          ><i
            *ngIf="!isNewWindow"
            class="fa fa-window-restore open-window"
            (click)="openWindow()"></i
        ></sup>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxFlex class="roster-group">
    <div
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      fxLayoutGap="6px"
      class="roster-filter">
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        floatLabel="always"
        style="width: 240px">
        <mat-label>Name</mat-label>
        <input
          name="Driver Name"
          matInput
          placeholder="Search"
          aria-label="Filter Name"
          #filterName
          [(ngModel)]="searchName"
          (input)="onSearchNameInput($event.target.value)"
          [readonly]="isLoading" />
        <button
          [disableRipple]="true"
          *ngIf="filterName.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearNameTextBox($event)"
          [readonly]="isLoading">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <!-- <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        floatLabel="always"
        style="width: 150px">
        <mat-label>Service</mat-label>
        <input
          name="Service Route"
          matInput
          placeholder="Search"
          aria-label="Filter Service"
          #filterRoute
          [(ngModel)]="searchRoute"
          (input)="onSearchRouteInput($event.target.value)"
          [readonly]="isLoading" />
        <button
          [disableRipple]="true"
          *ngIf="filterRoute.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearRouteTextBox($event)"
          [readonly]="isLoading">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field> -->
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        [ngClass]="{
          'select-search': routeSearchTextboxControl.value?.length > 0
        }"
        style="width: 180px">
        <mat-label>Service Route</mat-label>
        <mat-select
          [ngModel]="selectedRoute"
          name="status"
          placeholder="Select Route"
          (selectionChange)="onRouteChange($event)">
          <mat-optgroup>
            <mat-form-field
              style="width: 144px; margin-top: 8px"
              appearance="outline">
              <input
                #routeSearch
                autocomplete="off"
                placeholder="Search Route"
                aria-label="Search Route"
                matInput
                [formControl]="routeSearchTextboxControl" />
              <button
                [disableRipple]="true"
                *ngIf="routeSearch.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearRouteSearch($event)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </mat-optgroup>
          <mat-option value="">All</mat-option>
          <mat-option
            *ngFor="let route of filteredRouteOptions | async"
            [value]="route.value"
            [ngStyle]="{ display: route.visible ? '' : 'none' }">
            {{ route.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always" style="width: 150px;" *ngIf="isAdmin">
                <mat-label>Agency</mat-label>
                <mat-select>

                </mat-select>
            </mat-form-field> -->
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="roster-date">
      <button
        mat-button
        matTooltip="Previous Month"
        (click)="onClickPrevWeek()"
        [disabled]="isLoading">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="navigation">navigate_before</mat-icon>
        </div>
      </button>
      <h4 class="date-header">{{ selectedDateDisplay }}</h4>
      <button
        mat-button
        matTooltip="Next Month"
        (click)="onClickNextWeek()"
        [disabled]="isLoading">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="navigation">navigate_next</mat-icon>
        </div>
      </button>
    </div>
    <div class="roster-grid">
      <div class="roster-grid-scroll custom-page-scroll">
        <div class="roster-grid-header">
          <div class="header-item">
            <b>Driver</b>
          </div>
          <div
            *ngFor="
              let weekDay of weekHeader;
              let i = index;
              let first = first;
              let last = last
            "
            class="header-item"
            fxLayout="column">
            <div
              class="header-weekday"
              [ngClass]="{ 'week-end': first || last }">
              <b>{{ weekDay }}</b>
            </div>
            <div class="header-day">
              {{ getDateOfWeek(i) }}
            </div>
          </div>
        </div>
        <div class="roster-grid-content">
          <ng-container *ngIf="pagedList?.length > 0; else nodata">
            <div *ngFor="let driverItem of pagedList" class="content-row">
              <div class="content-item content-name">
                <div class="content-id">{{ driverItem.driver_id }}</div>
                <div class="name">{{ driverItem.driver_name }}</div>
              </div>
              <div
                *ngFor="let schedItem of driverItem.schedules"
                class="content-item"
                [ngClass]="{ 'empty-schedule': !schedItem.schedule_day }">
                <div
                  *ngIf="schedItem.schedule_day; else emptyDay"
                  class="schedule"
                  [matMenuTriggerFor]="scheduleMenu"
                  (click)="clickSchedule(driverDay)"
                  matRipple
                  role="button">
                  <div fxLayout="column" fxLayoutGap="4px">
                    <ng-container
                      *ngFor="let timeslot of schedItem.schedule_day">
                      <div
                        class="trip-container"
                        [ngClass]="{
                          duty: timeslot.type === 'ONDUTY',
                          break: timeslot.type === 'BREAK',
                          standby: timeslot.type === 'STANDBY'
                        }">
                        <div class="trip-service">
                          {{
                            timeslot.route_id
                              ? timeslot.route_id
                              : timeslot.type
                          }}
                        </div>
                        <div class="time-slot">
                          {{
                            formatTimeSlot(
                              timeslot.start_datetime,
                              timeslot.end_datetime
                            )
                          }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <mat-menu
                  #scheduleMenu="matMenu"
                  disableRipple
                  class="popup-panel popup-menu">
                  <ng-template matMenuContent>
                    <div
                      (click)="$event.stopPropagation()"
                      class="popup-schedule">
                      <div class="popup-header">
                        <b>{{ driverItem.driver_id }} Schedule</b>
                      </div>
                      <div class="popup-timetable">
                        <div class="popup-row">
                          <div>
                            <b>Service</b>
                          </div>
                          <div class="popup-item">
                            <b>Start</b>
                          </div>
                          <div class="popup-item">
                            <b>End</b>
                          </div>
                          <div class="popup-item">
                            <b>Block ID</b>
                          </div>
                          <div class="popup-item">
                            <b>Trip ID</b>
                          </div>
                        </div>
                        <div
                          *ngFor="
                            let timeslot of schedItem.schedule_day;
                            let i = index
                          "
                          class="popup-row row-schedule"
                          [ngClass]="getShiftScheduleClass(timeslot.type)">
                          <div class="schedule-service">
                            {{ timeslot.route_id || timeslot.type }}
                          </div>
                          <div class="popup-item">
                            {{ formatTime(timeslot.start_datetime) }}
                          </div>
                          <div class="popup-item">
                            {{ formatTime(timeslot.end_datetime) }}
                          </div>
                          <div class="popup-item">
                            {{ timeslot.block_id }}
                          </div>
                          <div class="popup-item">
                            {{ timeslot.trip_id }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-menu>
                <ng-template #emptyDay> - </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #nodata>
          <div class="empty-content" *ngIf="!showLoading">No Roster Data</div>
        </ng-template>
        <div class="roster-content-loading" *ngIf="isLoading && showLoading">
          <mat-spinner></mat-spinner>
        </div>
      </div>
      <mat-paginator
        #paginator
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[5, 10, 25, 50, 100]"
        (page)="pageChanged($event)"
        [length]="filteredDriverList?.length"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
