import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MatTableDataSource, MatPaginator, MatTable } from '@angular/material';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { HomeService } from 'src/app/component/shared/services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

export interface BusEventElement {
  busRegNo: string
  driverId: string;
  dutyDate: string;
  updatedUser: string;
  updatedDate: string;
}

@Component({
  selector: 'app-dispatcher',
  templateUrl: './dispatcher.component.html',
  styleUrls: ['./dispatcher.component.scss']
})
export class DispatcherComponent implements OnInit {

  isNewWindow: boolean = false;

  constructor(private homeService: HomeService, private httpClient: HttpClient, private _snackBar: MatSnackBar, 
    private route: ActivatedRoute, private router: Router) { }

  rptForm = new FormGroup({
    busRegNo: new FormControl(),
    driverId: new FormControl(),
    startDate: new FormControl(),
    endDate: new FormControl()
  });

  allRoutes: string[] = [];
  criteria: string[] = ['Duty Date', 'Bus', 'Driver Id'];
  selectedCriteria: string;

  displayedColumns: string[] = ['busRegNo', 'driverId', 'dutyDate', 'updatedUser', 'updatedDate'];

  dataSource = new MatTableDataSource<BusEventElement>();

  running: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatTable) table: MatTable<any>;
  
  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.selectedCriteria = this.criteria[0];

    // this.homeService.responsibilities['primary'].forEach(e => {
    //     this.allRoutes.push(e.service);
    // });

    // this.filteredRoutes = this.filterRoutes('');

    // this.rptForm.get('route').valueChanges.subscribe(newValue=>{
    //   this.filteredRoutes = this.filterRoutes(newValue);
    // })
    
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
  }

  filterRoutes(search: string) {
    return this.allRoutes.filter(value => value.toLowerCase().indexOf(search.toLowerCase()) === 0);
  }

  setValidation() {
    // const busRegNo = this.rptForm.get('busRegNo');
    // const driverId = this.rptForm.get('driverId');
    // const startDate = this.rptForm.get('startDate');
    // const endDate = this.rptForm.get('endDate');

    if(this.selectedCriteria === 'Duty Date') {
      this.rptForm.controls['startDate'].setValidators([Validators.required]);
      this.rptForm.controls['endDate'].setValidators([Validators.required]);

      this.rptForm.controls['busRegNo'].setValidators(null);
      this.rptForm.controls['driverId'].setValidators(null);
    }

    else if(this.selectedCriteria === 'Bus') {
      this.rptForm.controls['startDate'].setValidators(null);
      this.rptForm.controls['endDate'].setValidators(null);
      this.rptForm.controls['driverId'].setValidators(null);

      this.rptForm.controls['busRegNo'].setValidators([Validators.required]);
    }

    else if(this.selectedCriteria === 'Driver Id') {
      this.rptForm.controls['startDate'].setValidators(null);
      this.rptForm.controls['endDate'].setValidators(null);
      this.rptForm.controls['busRegNo'].setValidators(null);

      this.rptForm.controls['driverId'].setValidators([Validators.required]);
    }
    
    for (const controlIndex in this.rptForm.controls) {
      this.rptForm.controls[controlIndex].updateValueAndValidity();
    }
  }

  run() {
    this.setValidation();

    if(this.rptForm.invalid)
    return false;

    this.exportStarted('Querying...');

    let url = environment.apiServerUrl + 'reports/getSchDeviationByRouteId';

    let body = {
      routeId: '48',
      startDate: moment(this.rptForm.value.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.rptForm.value.endDate).format('YYYY-MM-DD')
    };

    this.httpClient.post(url, body).pipe(
      map((data: any) => {
        if (data.length == 0) {
          throw new Error('No record found');
        } else {
          return data;
        }
      })
    )
      .subscribe(
        (data: any) => {
          // let t = [];

          // data[1].forEach(e => {
          //   t.push({
          //     busRegNo: e.busRegNo,
          //     driverId: e.stopCode,
          //     dutyDate: moment(e.dutyDate).format('DD/MM/YYYY HH:mm:ss'),
          //     updatedUser: e.updatedUser,
          //     updatedDate: moment(e.updatedDate).format('DD/MM/YYYY HH:mm:ss'),
          //   });
          // });
 

          this.dataSource.data = data;
          this.table.renderRows();
          
          this.exportCompleted();
        },
        (error: any) => {
          this._snackBar.open(error.message, null, {
            duration: 2000,
          });
          this.running = false;
        }
      );
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/dispatcher/2','dispatcher','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }

  exportStarted(message: string) {
    this.running = true;

    this._snackBar.open(message);
  }

  exportCompleted() {
    this._snackBar.dismiss();

    this.running = false;
  }
}
