import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlarmService {
  // allAlarm:any = [];
  // allAlarm:any = [{
  //   'alarmId': '1',
  //   'eventType': 'Off Route',
  //   'eventSeverity': 'High',
  //   'timeStamp': '1616240747',
  //   'from': 'SBS1234C'
  // }];

  allAlarm = new Subject<any>();

  constructor(
  ) { }
  
  getAllAlarm(): Observable<any> {
    return this.allAlarm.asObservable();
  }

  // setAlarmVehicleCache(alarm) {
  //   var alarmVehicle = JSON.parse(localStorage.getItem('alarmVehicle'));
  //   console.log(alarmVehicle);
  //   if(alarmVehicle === null) {
  //     alarmVehicle = [];
  //     alarmVehicle.push(alarm);
  //     localStorage.setItem('alarmVehicle', JSON.stringify(alarmVehicle));
  //   }
  //   else {
  //     // let alarmArr = [];
  //     alarmVehicle.push(alarm);
  //     localStorage.setItem('alarmVehicle', JSON.stringify(alarmVehicle));
  //   }
  // }

  // getAlarmVehicleCache() {
  //   var alarmVehicle = JSON.parse(localStorage.getItem('alarmVehicle'));
  //   return alarmVehicle === null ? [] : alarmVehicle;
  // }
}
