<div class="duty-roster-group">
  <div class="header-group" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <div>
        <span class="title-header">Duty Roster</span>
        <sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup>
      </div>
      <!-- <div class="mar-left-10" [formGroup]="form">
        <span>
          <mat-form-field appearance="outline" fxFlex="100">
            <input matInput placeholder="Service No." formControlName="streetNameBusStop">
          </mat-form-field>
        </span>
        <span fxFlex="18">
          <button class="btn-med" mat-raised-button type="submit" [disabled]="running || !form.valid" (click)="searchSchedule()">Search</button>
        </span>
      </div> -->
    </div>
    <!-- <button mat-raised-button type="button" (click)="uploadFile()" class="float-right">Upload</button> -->
    <!-- <span fxFlex="50" fxLayoutAlign="end start">
      <button class="btn-med" mat-raised-button (click)="addStop()" [disabled]="!stopManagementService.allStops || running">Add Stop</button>
    </span> -->
  </div>

  <div fxLayout="column" class="route-group" [ngClass]="{'div-disabled': isDisablePage}" [formGroup]="dutyForm">

    <div fxFlex="20">
      <!-- <mat-label class="select-label">Select route</mat-label>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Route" [(ngModel)]="routeFilter" (keyup)="onFilter()">
      </mat-form-field> -->
      
      <mat-form-field floatLabel="always">
        <mat-label class="select-label">Select route</mat-label>
        <!-- <mat-select (selectionChange)="onFilter($event)"> -->
        <mat-select formControlName="route">
          <mat-option *ngFor="let n of routeList" [value]="n.value">
            {{n.viewValue}}
          </mat-option>
          <!-- <mat-option>None</mat-optionk> -->
          <!-- <mat-option value="sm-station-loop">SM Station Loop</mat-option>
          <mat-option value="airport-loop">Airport Loop</mat-option> -->
        </mat-select>
        <!-- <mat-error *ngIf="rptForm.get('route').invalid">Invalid route</mat-error> -->
      </mat-form-field>

      <mat-form-field floatLabel="always">
        <input matInput [matDatepicker]="filterDate" placeholder="Date" formControlName="filterDate">
        <mat-datepicker-toggle matSuffix [for]="filterDate"></mat-datepicker-toggle>
        <mat-datepicker #filterDate></mat-datepicker>
        <mat-error *ngIf="dutyForm.get('filterDate').invalid">Invalid date</mat-error>
      </mat-form-field>
      
      <button mat-raised-button type="submit" [disabled]="!dutyForm.valid" (click)="onFilter()">Search</button>
    </div>

    <table border="0">
      <perfect-scrollbar #chatPS class="ps-sched-list-hori" [config]="config" [scrollIndicators]="true">
        <thead>
          <tr>
            <th class="td-sched"></th>
            <!-- <th class="td-sched hori-stop-header" *ngFor="let headerSched of selectedServiceData.header; let first = first; let i = index">
              <span class="stop-count">{{i+1}}</span>
              <div>{{headerSched.stopCode}}</div>
            </th> -->
            <th>Route</th>
            <th>Block</th>
            <th>Vehicle</th>
            <!-- <th>Personnel Nr</th> -->
            <th>Driver</th>
            <th>Duty</th>
            <th>Duty Period</th>
            <!-- <th>Duty End</th> -->
            <th>First Trip</th>
            <th>First Stop</th>
            <th>Last Trip</th>
            <th>Last Stop</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let schedData of selectedServiceData.scheduleData; let i = index">
            <td class="td-sched">
              <mat-menu class="colorWhite shift-group" #menu="matMenu">
                <div>
                  <!-- <div>{{schedData.shift.dayTime}}</div>
                  <div fxLayout="row" fxLayoutAlign="space-around center">
                    <div class="shift" *ngFor="let shift of schedData.shift.shiftData" 
                    [ngClass]="{
                      'amShift': schedData.shift.dayTime === 'AM SHIFT',
                      'pmShift': schedData.shift.dayTime === 'PM SHIFT'
                    }">
                      <div *ngIf="shift !== 'blank'">{{shift}}</div>
                      <div *ngIf="shift === 'blank'" class="rest"><div>&nbsp;</div></div>
                    </div>
                  </div> -->
                  <table class="width100">
                    <tr>
                      <td><b>Block</b></td>
                      <td>{{schedData.stpSchedule[1].arrivalTime}}</td>
                    </tr>
                    <tr [ngClass]="{
                      'amShift': schedData.shift.dayTime === 'AM SHIFT',
                      'pmShift': schedData.shift.dayTime === 'PM SHIFT'
                    }">
                      <td class="no-bg"><div class="padding5">&nbsp;</div></td>
                      <td colspan="6"><div class="padding5">First Trip</div></td>
                      <td>Rest</td>
                      <td colspan="4" class="text-right"><div class="padding5">Last Trip End</div></td>
                    </tr>
                    <tbody>
                      <tr>
                        <td>{{schedData.shift.dayTime}}</td>
                        <td class="shift" *ngFor="let shift of schedData.shift.shiftData"
                        [ngClass]="{
                          'amShiftTd': schedData.shift.dayTime === 'AM SHIFT',
                          'pmShiftTd': schedData.shift.dayTime === 'PM SHIFT'
                        }"
                        >
                          <div *ngIf="shift !== 'blank'" class="padding5">{{shift}}</div>
                          <div *ngIf="shift === 'blank'" class="rest padding5"><div class="visibility-hidden">00:00</div></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div class="mar-top-10">
                  <div>LAST TRIP</div>
                  <div fxLayout="row" fxLayoutAlign="space-around center">
                    <div class="shift" *ngFor="let shift of schedData.shift.lastTrip">{{shift}}</div>
                  </div>
                </div> -->
              </mat-menu>

              <span class="material-icons pointer" [matMenuTriggerFor]="menu" *ngIf="!schedData.isInlineEdit">info</span>
              <span class="material-icons pointer" matTooltip="Edit" matTooltipPosition="above" (click)="inlineEdit(schedData)" *ngIf="!schedData.isInlineEdit">edit</span>
              <span class="material-icons pointer" matTooltip="Cancel" matTooltipPosition="above" (click)="inlineEdit(schedData)" *ngIf="schedData.isInlineEdit">cancel</span>
              <span class="material-icons pointer" matTooltip="Save" matTooltipPosition="above" (click)="onSaveSchedule(schedData)" *ngIf="schedData.isInlineEdit">save</span>
            </td>
            <td class="td-sched" *ngFor="let sched of schedData.stpSchedule; let first = first">
              <!-- <span class="inline-text" *ngIf="!schedData.isInlineEdit">
                {{sched.arrivalTime}}
              </span> -->
        
              <!-- <ng-container *ngIf="schedData.isInlineEdit"> -->
                <!-- <mat-form-field appearance="outline"> -->
                  <ng-container *ngIf="schedData.isInlineEdit">
                    <ng-container *ngIf="sched.schedId === '12'">
                      <mat-select [(value)]="sched.arrivalTime" class="inline-textbox">
                        <mat-option *ngFor="let severity of vehicleList" [value]="severity.value">
                          {{severity.viewValue}}
                        </mat-option>
                      </mat-select>
                    </ng-container>
                    <ng-container *ngIf="sched.schedId === '3'">
                      <mat-select [(value)]="sched.arrivalTime" class="inline-textbox">
                        <mat-option *ngFor="let severity of driverList" [value]="severity.value">
                          {{severity.viewValue}}
                        </mat-option>
                      </mat-select>
                    </ng-container>

                    <ng-container *ngIf="sched.schedId === '1' || sched.schedId === '4' || sched.schedId === '5' || sched.schedId === '6' || sched.schedId === '7' || sched.schedId === '8'
                    || sched.schedId === '9' || sched.schedId === '11' || sched.schedId == '10'">
                      <!-- <input (blur)="onBlurSched(sched)" [ngClass]="{'inline-textbox': schedData.isInlineEdit}" matInput [value]="sched.arrivalTime" [(ngModel)]="sched.arrivalTime" [(ngModel)]="sched.deparureTime"> -->
                      {{sched.arrivalTime}}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!schedData.isInlineEdit">
                    {{sched.arrivalTime}}
                  </ng-container>
                <!-- </mat-form-field> -->
              <!-- </ng-container> -->
            </td>
          </tr>
        </tbody>
      </perfect-scrollbar>
    </table>
  </div>
</div>