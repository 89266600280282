
<div class="dispatch-assign-group">
  <div class="header-group" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <div>
        <span class="title-header">Bus Service Schedule</span>
        <sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup>
      </div>
      <div class="mar-left-10" [formGroup]="dispatchForm">
        <span>
          <mat-form-field appearance="outline" fxFlex="100">
            <!-- <mat-label>Street Name / Bus Stop Code</mat-label> -->
            <input matInput placeholder="Service No." formControlName="streetNameBusStop">
          </mat-form-field>
        </span>
        <span fxFlex="18">
          <button class="btn-med" mat-raised-button type="submit" [disabled]="running || !dispatchForm.valid" 
          (click)="onSearch()">Search</button>
        </span>
      </div>
    </div>
    <!-- <button mat-raised-button type="button" (click)="uploadFile()" class="float-right">Upload</button> -->
  </div>

  <perfect-scrollbar #chatPS class="ps-drivers-list" [config]="config">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="sn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          SN
        </th>
        <td mat-cell *matCellDef="let element"> {{element.sn}} </td>
      </ng-container>

      <ng-container matColumnDef="eventNo">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <input matInput placeholder="Event No">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> 
          <div>
            <div>{{element.eventNo}} </div>
            <small>{{element.lastUpdate}}</small> 
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="alarmNo">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <input matInput placeholder="Alarm No">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.alarmNo }} </td>
      </ng-container>

      <ng-container matColumnDef="severity">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <mat-label>Severity</mat-label>
            <mat-select  [(value)]="initSeverity">
              <mat-option *ngFor="let severity of severityList" [value]="severity.value">
                {{severity.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout="row" fxLayoutAlign="start center" class="severity-{{element.severity | lowercase }} ">
            <span class="material-icons mar-right-10">stop_circle</span>
            <span>{{element.severity}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select  [(value)]="initType">
              <mat-option *ngFor="let type of typeList" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> 
          <div fxLayout="row" fxLayoutAlign="start center">
            <!-- <span *ngIf="element.type === 'Accident'"> -->
              <!-- <span *ngIf="element.type === 'Accident'" class="accident-icon"></span> -->
            <!-- </span> -->
            <span *ngIf="element.type === 'Accident' || element.type === 'Vehicle Breakdown'" class="material-icons mar-right-10">electric_car</span>
            <span *ngIf="element.type === 'Diversion'" class="material-icons mar-right-10">alt_route</span>
            <span *ngIf="element.type === 'Road Block'" class="material-icons mar-right-10">block</span>
            <span>{{element.type}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="subtype">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <mat-label>Subtype</mat-label>
            <mat-select  [(value)]="initSubtype">
              <mat-option *ngFor="let subtype of subtypeList" [value]="subtype.value">
                {{subtype.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.subtype}} </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <input matInput placeholder="Created By" (keyup)="filter($event.target.value)">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
      </ng-container>

      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <!-- <input matInput placeholder="Start Time">
            <mat-icon matSuffix>search</mat-icon> -->
            
          <input matInput [matDatepicker]="effectiveFromDate" placeholder="Start Time">
          <mat-datepicker-toggle matSuffix [for]="effectiveFromDate"></mat-datepicker-toggle>
          <mat-datepicker #effectiveFromDate></mat-datepicker>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
      </ng-container>

      <ng-container matColumnDef="roadName">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <input matInput placeholder="Road Name">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.roadName}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select  [(value)]="initStatus">
              <mat-option *ngFor="let status of statusList" [value]="status.value">
                {{status.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <ng-container matColumnDef="planStatus">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field>
            <mat-label>Plan Status</mat-label>
            <mat-select  [(value)]="initPlan">
              <mat-option *ngFor="let status of planStatusList" [value]="status.value">
                {{status.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.planStatus}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEventDetails(row)" class="pointer" matTooltip="Alarm Details"></tr>
    </table>
  </perfect-scrollbar>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>