<mat-card
  class="permission mat-elevation-z12"
  appearance="raised"
  *ngIf="isShow">
  <mat-card-header class="header">
    <mat-card-title *ngIf="permissionDenied; else promptShow">
      Notification permission was denied
    </mat-card-title>
    <ng-template #promptShow>
      <mat-card-title> Show Notifications </mat-card-title>
    </ng-template>
    <button mat-icon-button class="close" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content *ngIf="permissionDenied; else requestMessage">
    <p class="content">
      You will not be able to receive incoming notifications.
    </p>
    <p class="content">
      Please select your browser and change your settings to allow
      notifications:
    </p>
  </mat-card-content>
  <ng-template #requestMessage>
    <mat-card-content>
      <p class="content">
        Notifications are used to show updates from this website.
      </p>
      <p class="content">
        For the best user experience, please allow notifications to display on
        this site.
      </p>
    </mat-card-content>
  </ng-template>

  <mat-card-actions
    align="end"
    class="footer"
    *ngIf="!permissionDenied; else resetPermissions">
    <!-- <button mat-button class="dismiss">Dismiss</button> -->
    <button mat-raised-button (click)="askPermission()">Allow</button>
  </mat-card-actions>

  <ng-template #resetPermissions>
    <mat-card-actions align="end" class="content">
      <a
        href="{{ chromeSupportNotificationUrl }}"
        target="_blank"
        title="{{ chromeSupportNotificationUrl }}"
        ><button mat-button class="text-button">Google Chrome</button></a
      >
      <a
        href="{{ edgeSupportNotificationUrl }}"
        target="_blank"
        title="{{ edgeSupportNotificationUrl }}"
        ><button mat-button class="text-button">Microsoft Edge</button></a
      >
      <a
        href="{{ firefoxSupportNotificationUrl }}"
        target="_blank"
        title="{{ firefoxSupportNotificationUrl }}"
        ><button mat-button class="text-button">Mozilla Firefox</button></a
      >
    </mat-card-actions>
  </ng-template>
</mat-card>
