
<div class="pm-driver-mat-group">
  <div>
    <div class="text-left">
      <mat-form-field floatLabel="always">
        <input matInput (keyup)="filter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <!-- <button mat-raised-button (click)="exporter.exportTable('xlsx')">Export</button> -->
      <button class="pull-right" mat-raised-button type="button" (click)="add()">Add</button>
    </div>
      
  </div>


  <!-- <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter"> -->
  <table mat-table [dataSource]="dataSource" matTableExporter>

    <ng-container matColumnDef="driverId">
      <th mat-header-cell *matHeaderCellDef>Driver ID</th>
      <td mat-cell *matCellDef="let element"> {{element.driverId}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
    </ng-container>

    <ng-container matColumnDef="busRegNo">
      <th mat-header-cell *matHeaderCellDef>Bus Registration No</th>
      <td mat-cell *matCellDef="let element"> {{element.busRegNo}} </td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef>Contact</th>
      <td mat-cell *matCellDef="let element"> {{element.primaryPhone}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element"> {{element.driverStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let element"> <button (click)="edit(element)" mat-icon-button color="primary">
          <mat-icon>edit</mat-icon>
        </button>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>