import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
// import { MatSort, MatDialogConfig, MatDialog, MatAutocompleteTrigger } from '@angular/material';
// import moment from 'moment';
import { map, startWith } from 'rxjs/operators';
// import { HomeService } from 'src/app/component/shared/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/component/shared/services/map.service';
import OlMap from 'ol/Map';
import { ScaleLine, defaults as defaultOlControls } from 'ol/control';
// import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
// import Point from 'ol/geom/Point';
// import OlFeature from 'ol/Feature';
import { Vector as VectorSource } from 'ol/source.js';
import {
  Vector as VectorLayer,
  Image as ImageLayer,
  VectorImage as VectorImageLayer,
} from 'ol/layer.js';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
import { StopManagementService } from 'src/app/component/shared/services/stop-management.service';
// import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { MenuService } from 'src/app/component/shared/services/menu.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { boundingExtent } from 'ol/extent';
import { fadeInOut } from 'src/app/component/shared/others/animation/fadeInOut';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { demoStreetLines } from './demo-stop-data';
import { DemoStopModalComponent } from './demo-stop-modal/demo-stop-modal.component';
import { MockMapService } from '../mock-map/mock-map-service.service';

@Component({
  selector: 'app-demo-stop-management',
  templateUrl: './demo-stop-management.component.html',
  styleUrls: ['./demo-stop-management.component.scss'],
})
export class DemoStopManagementComponent {
  map: OlMap;
  modalAddStopData = {};

  @ViewChild(MatSort) sort: MatSort;
  filteredOptions: Observable<any[]>;

  searchStreetStop: string = '';
  allStops: any;
  allTrainStation: any;

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private mapService: MapService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public stopManagementService: StopManagementService,
    private mockMapService: MockMapService,
    private commonService: CommonService,
    public authService: AuthService,
    private menuService: MenuService,
    private _spinner: NgxSpinnerService
  ) {}

  form = new FormGroup({
    streetNameBusStop: new FormControl('', [
      // Validators.required
    ]), // refactor to hide invalid form and disable submit..
  });

  running: boolean = false;
  // allStops: any;
  // featureList=[];
  isPanel: boolean = false;
  panelData: any;
  streetNameBusStop: any = '';
  options: any = [];
  isSelected: boolean = false;

  boundingExtent = boundingExtent([
    fromLonLat(environment.map.boundingExtent.min),
    fromLonLat(environment.map.boundingExtent.max),
  ]);

  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {};
  viewAnimate: OlView = new OlView({
    center: fromLonLat(environment.map.locationLonLat),
    zoom: environment.map.defaultZoom,
    minZoom: environment.map.minZoom,
    maxZoom: environment.map.maxZoom,
    projection: 'EPSG:900913',
    // extent: this.boundingExtent,
  });
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputSearchStreet: MatAutocompleteTrigger;
  isBusCodeSearch: boolean = false;

  pageData = {
    id: 1,
    name: 'stopManagement',
    displayPageName: 'Stop Management',
  };
  isHasAccess: boolean = false;

  mapOptions: any = {
    isIncident: false,
    isLadder: true,
    isMapLayer: false,
  };
  provider = this.mapService.mapProvider;
  OSMLayer: OlTileLayer<any>;
  rightsError: string = '';

  ngOnInit() {
    setTimeout(() => {
      this.initMap();
    }, 300);
  }

  initMap() {
    this.OSMLayer = new OlTileLayer({
      source: new OSM({
        url: `${environment.map.staticMap}/{z}/{x}/{y}.png`,
        crossOrigin: null,
      }),
    });

    const scaleLine = new ScaleLine({
      units: 'metric',
    });

    this.map = new OlMap({
      controls: defaultOlControls().extend([scaleLine]),
      target: 'map-stop',
      view: this.viewAnimate,
    });

    this.map.addLayer(this.OSMLayer);

    setTimeout(() => {
      this.map.updateSize();
      this.watchMenuChange();
      this.initStreets();
    }, 200);
  }

  searchStop(evt) {
    // if (!(this.form.value.streetNameBusStop?.length > 0)) {
    //   this._snackBar.open('Street Name must not be empty.', null, {
    //     duration: 3000,
    //   });
    //   return;
    // }
    // this.removeLayerByLayerName('allStops');
    // // this.options = [];
    // this.streetNameBusStop = this.form.value.streetNameBusStop;
    // // this.formReset();
    // this.isSelected = false;
    // this.running = true;
    // this._snackBar.open('Searching');
    // this._snackBar.dismiss();
    // this.running = false;
  }

  initStreets() {
    const initList: any[] = demoStreetLines;
    initList.sort((a, b) => (a.streetName > b.streetName ? 1 : -1));
    initList.forEach(element => {
      this.options.push(element);
    });

    this.filteredOptions = this.form.get('streetNameBusStop').valueChanges.pipe(
      startWith(''),
      map(value => (value !== '' ? this._filter(value) : this.options.slice()))
    );

    // if (initList.length > 1) {
    //   this.filteredOptions = this.form
    //     .get('streetNameBusStop')
    //     .valueChanges.pipe(
    //       startWith(''),
    //       map(value =>
    //         value !== '' ? this._filter(value) : this.options.slice()
    //       )
    //     );
    //   evt.stopPropagation();
    //   this.inputSearchStreet.openPanel();
    // } else if (initList[0] !== undefined) {
    //   this.selectedStreet(initList[0]);

    //   initList[0].stops[0].isChecked = true;
    // } else {
    //   this._snackBar.open('No available street or bus stop.', null, {
    //     duration: 2000,
    //   });
    // }
  }

  selectedStreet(data) {
    this.isSelected = true;
    let stops = data.stops;
    this.allStops = stops; //assign stop

    this.streetNameBusStop = data.streetName;
    this.removeLayerByLayerName('allStops');
    this.addMapStopIcon(stops);
    this.initMapInteraction();
    this.closePanel();
  }

  addMapStopIcon(stops) {
    // let featureList = [];

    var vectorLayer = new VectorImageLayer({
      source: new VectorSource({
        features: [],
      }),
      className: 'allStops',
      properties: {
        name: 'allStops',
      },
    });

    stops.forEach((stop, index) => {
      let count = index + 1;
      stop.imgSrc = 'assets/images/icon/marker-location-green.svg';
      stop.lat = stop.stopLat;
      stop.lon = stop.stopLon;
      stop.name = 'stop-' + stop.stopCode;
      stop.message = count.toString();

      this.mapService.addPinLocation(vectorLayer, stop);
    });

    this.map.addLayer(vectorLayer);
    this.mapService.centerLayerMap(this.map, vectorLayer);
  }

  initMapInteraction() {
    var me = this;
    this.map.on('pointermove', e => {
      if (e.dragging) {
        return;
      }
      var pixel = e.map.getEventPixel(e.originalEvent);
      var hit = false;
      var dataObj = undefined;

      e.map.forEachFeatureAtPixel(
        pixel,
        function (feature, layer) {
          let layerName = layer.get('name');
          let featureName = feature.get('name');
          // let routeName = me.ladders.routeId === undefined ? me.ladders.route[0] : me.ladders.routeId[0];

          if (layerName && layerName.includes('allStops')) {
            hit = true;
            dataObj = feature.get('dataObj');
          }
        },
        {
          hitTolerance: 3,
        }
      );
      e.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    this.map.on('singleclick', evt => {
      let feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        },
        {
          hitTolerance: 3,
        }
      );
      if (feature) {
        let featureName = feature.get('name');

        if (featureName.includes('stop')) {
          me.isPanel = true;
          me.panelData = feature.get('dataObj');
          // this.openBusStopEvent(me.panelData);
        }
      }
      evt.preventDefault(); //add this to avoid bubling
    });
  }

  private _filter(value: any): any[] {
    if (!value) {
      return;
    }

    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.streetName.toLowerCase().includes(filterValue)
    );
  }

  removeLayerByLayerName(dataLayerName) {
    var isAllStopLayer = this.mapService.isLayerExist(this.map, dataLayerName);
    if (isAllStopLayer) {
      let features = isAllStopLayer.getSource().getFeatures();
      features.forEach(element => {
        this.mapService.removeLayerFeature(isAllStopLayer, element.get('name'));
      });

      this.mapService.removeLayerByLayerName(this.map, dataLayerName);
    }
  }

  formReset() {
    this.allStops = [];
    this.closePanel();
  }

  closePanel() {
    this.isPanel = false;
  }

  menuSubscription: Subscription;
  watchMenuChange() {
    const me = this;
    this.menuSubscription = this.menuService.getPinned().subscribe(value => {
      me._spinner.show();
      setTimeout(function () {
        if (me?.map) {
          me.map.updateSize();
        }
      }, 150);
      setTimeout(() => me._spinner.hide(), 350);
    });
  }

  editStop(data) {
    let drv = {
      streetName: this.streetNameBusStop,
      stops: [
        {
          ...data,
          stopCode: data.stopCode,
        },
      ],
      stopCode: data.stopCode,
      message: data.message,
      action: 'edit',
    };

    var dialogData = {
      autoFocus: true,
      data: drv,
    };

    const dialogRef = this.dialog.open(DemoStopModalComponent, dialogData);

    dialogRef.afterClosed().subscribe(result => {
      // if (result) {
      //   // let result = retData.stops[0];
      //   this.allStops.forEach(element => {
      //     if (element.stopCode === result.stopCode) {
      //       element.busTerminal = result.busTerminal;
      //       element.imgSrc = 'assets/images/icon/map-marker-green.png';
      //       element.lat = result.stopLat;
      //       element.lon = result.stopLon;
      //       element.name = 'stop-' + result.stopCode;
      //       element.message = result.message;
      //       element.mrtStationCode = result.mrtStationCode;
      //       element.mrtStationName = result.mrtStationName;
      //       element.stopCode = result.stopCode;
      //       element.stopDesc = result.stopDesc;
      //       element.stopLat = result.stopLat;
      //       element.stopLon = result.stopLon;
      //       element.stopName = result.stopName;
      //       element.stopStatus = result.stopStatus;
      //     }
      //   });
      //   let allStopLayer = this.mapService.isLayerExist(this.map, 'allStops');
      //   this.mapService.removeLayerFeature(
      //     allStopLayer,
      //     'stop-' + result.stopCode
      //   );
      //   this.viewAnimate.animate({
      //     center: fromLonLat([
      //       parseFloat(result.stopLon),
      //       parseFloat(result.stopLat),
      //     ]),
      //     duration: 1000,
      //     zoom: 16,
      //   });
      //   result.imgSrc = 'assets/images/icon/map-marker-green.png';
      //   result.lat = result.stopLat;
      //   result.lon = result.stopLon;
      //   result.name = 'stop-' + result.stopCode;
      //   result.message =
      //     parseInt(result.message) < 10 && result.message.length < 2
      //       ? '0' + result.message.toString()
      //       : result.message.toString();
      //   this.mapService.addPinLocation(allStopLayer, result);
      //   this.mapService.centerLayerMap(this.map, allStopLayer);
      // }
    });
  }

  clearStreetSearch(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.form.controls.streetNameBusStop.reset();

    this.filteredOptions = this.form.get('streetNameBusStop').valueChanges.pipe(
      startWith(''),
      map(value => (value !== '' ? this._filter(value) : this.options.slice()))
    );
  }

  ngOnDestroy() {
    if (this.menuSubscription) {
      this.menuSubscription?.unsubscribe?.();
    }
  }
}
