import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
// import { MatSnackBarRef } from '@angular/material';
@Component({
  selector: 'app-common-alarm',
  templateUrl: './common-alarm.component.html',
  styleUrls: ['./common-alarm.component.scss']
})
export class CommonAlarmComponent implements OnInit {

  constructor(private snackBarRef: MatSnackBarRef<CommonAlarmComponent>) { }

  ngOnInit() {
  }
  onClose(){
    this.snackBarRef.dismiss();
  }
}
