<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content [formGroup]="form">

  <div class="row no-margin">
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="driverId" aria-label="Driver Id" placeholder="Driver Id" />
      <mat-error *ngIf="form.get('driverId').invalid">Invalid driver ID</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-select formControlName="driverStatus" aria-label="Driver Status" placeholder="Driver Status">
        <mat-option *ngFor="let status of driverStatuses" [value]="status.listName">
          {{status.listName}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('driverStatus').invalid">Invalid driver status</mat-error>
    </mat-form-field>
  </div>

  <div class="row no-margin">
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="firstName" aria-label="First Name" placeholder="First Name" />
      <mat-error *ngIf="form.get('firstName').invalid">Invalid first name</mat-error>
    </mat-form-field>
  </div>

  <div class="row no-margin">
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="middleName" aria-label="Middle Name" placeholder="Middle Name" />
      <mat-error *ngIf="form.get('middleName').invalid">Invalid middle name</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="lastName" aria-label="Last Name" placeholder="Last Name" />
      <mat-error *ngIf="form.get('lastName').invalid">Invalid last name</mat-error>
    </mat-form-field>
  </div>

  <div class="row no-margin">
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="address" aria-label="Address" placeholder="Address" />
      <mat-error *ngIf="form.get('address').invalid">Invalid address</mat-error>
    </mat-form-field>
  </div>

  <div class="row no-margin">
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="primaryPhone" aria-label="Primary phone"
        placeholder="Primary phone" />
      <mat-error *ngIf="form.get('primaryPhone').invalid">Invalid primary phone</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="secondaryPhone" aria-label="Secondary phone"
        placeholder="Secondary phone" />
      <mat-error *ngIf="form.get('secondaryPhone').invalid">Invalid secondary phone</mat-error>
    </mat-form-field>
  </div>

  <div class="row no-margin">
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="identificationDocType" aria-label="Identification document"
        placeholder="Identification document" />
      <mat-error *ngIf="form.get('identificationDocType').invalid">Invalid identification document</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <input type="text" matInput formControlName="identificationId" aria-label="Identification Id"
        placeholder="Identification Id" />
      <mat-error *ngIf="form.get('identificationId').invalid">Invalid identification Id</mat-error>
    </mat-form-field>
  </div>

  <div class="row no-margin">
    <mat-form-field floatLabel="always">
      <input matInput [matDatepicker]="joiningDate" placeholder="Joining Date" formControlName="joiningDate">
      <mat-datepicker-toggle matSuffix [for]="joiningDate"></mat-datepicker-toggle>
      <mat-datepicker #joiningDate></mat-datepicker>
      <mat-error *ngIf="form.get('joiningDate').invalid">Invalid joining Date</mat-error>
    </mat-form-field>
  </div>

  <div class="row no-margin">
    <mat-checkbox #termsAndConditions formControlName="temporary">Temporary</mat-checkbox>
  </div>

  <div class="row no-margin">
    <mat-form-field *ngIf="termsAndConditions.checked">
      <input matInput [matDatepicker]="lastDutyDate" placeholder="Last Duty Date" formControlName="lastDutyDate">
      <mat-datepicker-toggle matSuffix [for]="lastDutyDate"></mat-datepicker-toggle>
      <mat-datepicker #lastDutyDate></mat-datepicker>
      <mat-error *ngIf="form.get('lastDutyDate').invalid">Invalid Last Duty Date</mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>