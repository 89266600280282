import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import moment from 'moment';
import { CommonService } from 'src/app/component/shared/services/common.service';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  constructor(
    private _commonService: CommonService,
    public dialogRef: MatDialogRef<AddRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar
  ) {
    
    this.form = this.formBuilder.group({
      roleName: new FormControl('', [Validators.required]),
      orders: new UntypedFormArray([])
    });
  
    this.addCheckboxesToForm();
  }

  // @Input() data:any;
  running:boolean = false;
  form: FormGroup;
  pageList = this.dialogData.data.pageList;

  ngOnInit() {
    console.log(this.pageList);

  }

  onSaveRole() {
    console.log('on save');
    var ids = [];
    var idsOnly = [];
    // this.pageList[i].pageId
    const selectedOrderIds = this.form.value.orders
      .map((checked, i) => {
        // checked ? ids.push(this.pageList[i].pageId) : null)
        if(checked) {
          console.log(checked, i, this.pageList[i].id);
          ids.push({
              id: parseInt(this.pageList[i].id),
              isChecked: true,
              name: this.pageList[i].name
          });
          idsOnly.push(parseInt(this.pageList[i].id));
          // let feature = ids.find(x => x.id === this.pageList[i].id);
          
          // feature.isChecked = true;
        }
      })
      .filter(v => v !== null);

    var roleName = this.form.value.roleName;
    var dataBody = {
      "role": roleName,
      "feaureIdList": idsOnly
    };
    console.log(dataBody);

    var urlAction = 'users/v1/assignRoleFeature';
    this._commonService.commonPostAction(urlAction, dataBody).subscribe(roles => {
      this.dialogRef.close({
        "isEdit": false,
        "roleName": roleName,
        "pages": ids
      });
    },
    (err) => {
      this.dialogRef.close({
        "isEdit": false,
        "roleName": roleName,
        "pages": ids
      });
      this._snackBar.open(err.message, null, {
        duration: 5000,
      });
    });
  }
  
  get ordersFormArray() {
    return this.form.controls.orders as UntypedFormArray;
  }

  private addCheckboxesToForm() {
    this.pageList.forEach(() => this.ordersFormArray.push(new FormControl(false)));
  }
}
