import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MapService } from 'src/app/component/shared/services/map.service';
import { Subscription } from 'rxjs';
import { MqttService } from 'src/app/component/shared/services/mqtt.service';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { VoipService } from 'src/app/component/shared/services/voip.service';
import { AccountBaseComponent } from '../../account-base/account-base.component';
import { environment } from 'src/environments/environment';
import { JitsiService } from 'src/app/component/shared/services/jitsi.service';
import {
  CALL_PATH,
  WINDOW_NAME_CALL,
  WINDOW_NAME_MAIN,
} from 'src/app/component/shared/others/constant';

export interface myinterface {
  removeBusComponent(
    index: number,
    busCode: string,
    stopCode: string,
    key: string,
    route: string
  );
  zoomBlinkBusStop(data);
  zoomBusLocation(data);
  showMessageList(data);
  // initVoip(data);
}

@Component({
  selector: 'app-bus-details',
  templateUrl: './bus-details.component.html',
  styleUrls: ['./bus-details.component.scss'],
})
export class BusDetailsComponent implements OnInit {
  environment = environment;
  @Input() data: any = null;
  @Output() closeDetails: EventEmitter<any> = new EventEmitter<any>();

  // public index: number;
  public selfRef: BusDetailsComponent;

  // public compInteraction: myinterface;
  bus: any = null;
  location: string = null;
  route = '';
  // refreshInterval:any;
  busETA: any = [];
  private mqttSubcription: Subscription;
  driverData: any = '';

  constructor(
    // private mapService: MapService,
    private mqttService: MqttService,
    private commonService: CommonService,
    private _voipService: VoipService,
    private _accountBase: AccountBaseComponent,
    private _jitsiService: JitsiService
  ) {}

  ngOnInit() {
    this.route = this.data.value.routeId;
    this.bus = this.data.value;
    var self = this;

    this.mqttSubcription = this.mqttService.mqtt$.subscribe((data: any) => {
      if (data.busCode === self.bus.busRegNo) {
        self.bus.status = data.status;
        self.bus.lat = data.lat;
        self.bus.lon = data.lon;
        self.bus.blockId = data.blockId;
        self.bus.nextStopId = data.nextStopId;
        (self.bus.nextstopeta = data.nextstopeta),
          (self.bus.secondnextstopId = data.secondnextstopId);
        (self.bus.secondnextstopeta = data.secondnextstopeta),
          (self.bus.thirdnextstopId = data.thirdnextstopId);
        self.bus.thirdnextstopeta = data.thirdnextstopeta;
      }
    });

    this.getDriverDetails();
  }

  getDriverDetails() {
    var urlAction = 'drivers/findByDriverId';
    var bodyData = {
      driverId: this.bus.driverId,
    };

    this.commonService
      .commonPostAction(urlAction, bodyData)
      .subscribe(retData => {
        if (retData[0]) {
          this.driverData = retData[0];
        }
      });
  }

  removeBus(index, busCode, stopCode, key) {
    var emittedData = {
      index: index,
      busCode: busCode,
      stopCode: stopCode,
      key: key,
      route: this.route,
    };
    this.closeDetails.emit(emittedData);

    // this.compInteraction.removeBusComponent(index,busCode,stopCode, key, this.route);
  }

  zoomBusLocation() {
    // this.compInteraction.zoomBusLocation(this.data);
  }

  showMessageList() {
    // this.compInteraction.showMessageList(this.data);
    this._accountBase.showMessageList(this.data);
  }

  showVoip() {
    // this.compInteraction.initVoip(this.data);
    // this._voipService.initVoip();
    this._accountBase.voipCall(this.data.value);
  }

  onCheckVechileModel(planumber) {
    var model = '';
    switch (planumber) {
      case 'SBS3319M':
      case 'SBS3323A':
      case 'SBS3325U':
      case 'SBS3328L':
      case 'SBS3370M':
      case 'SBS3408P':
      case 'SBS3416R':
      case 'SBS3427J':
      case 'SBS3431X':
      case 'SG5036C':
        model = 'Volvo DD';
        break;

      case 'SMB3006A':
      case 'SMB3020H':
      case 'SMB3022C':
      case 'SMB3023A':
      case 'SMB3025U':
      case 'SMB3027P':
      case 'SMB3002K':
        model = 'Man SD';
        break;

      default:
        model = '';
        break;
    }
    return model;
  }

  getStopDetails(stopId) {
    if (!stopId) return '';
    const ladder: any = JSON.parse(localStorage.getItem('currentLadderData'));
    const direction = ladder.direction.find(
      x => x.value === this.bus.direction
    );
    // console.log(direction);
    if (!direction) {
      return '';
    }
    const stop = direction.stops.find(x => x.stopCode === stopId);
    let returnData = '';
    if (stop) {
      returnData = '(' + stop.stopCode + ') ' + stop.stopName;
    } else if (stopId) {
      returnData = '(' + stopId + ')';
    } else {
      returnData = '';
    }
    return returnData;
  }

  callJitsi() {
    const { busRegNo } = this.bus;
    const windowFeatures = '';
    this.commonService.openWindow(
      `${CALL_PATH}?busRegistrationNumber=${busRegNo}`,
      WINDOW_NAME_MAIN,
      `${WINDOW_NAME_CALL}_${busRegNo}`,
      windowFeatures
    );
  }

  ngOnDestroy() {
    // clearInterval(this.refreshInterval);
    this.mqttSubcription.unsubscribe();
  }
}
