import {
  Component,
  OnInit,
  ViewChild,
  Pipe,
  AfterViewInit,
  Injectable,
} from '@angular/core';
import jwt_decode from 'jwt-decode';
import { AuthService } from '../../shared/services/auth.service';
import { MenuService } from '../../shared/services/menu.service';
import { AccountMenuComponent } from '../account-menu/account-menu.component';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
} from '@angular/animations';
import { HomeComponent } from '../home/home.component';
import { HomeService } from '../../shared/services/home.service';
import { Subscription } from 'rxjs';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AccountSettingComponent } from './account-setting/account-setting.component';
import { environment } from 'src/environments/environment';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CommonService } from '../../shared/services/common.service';
import { JitsiService } from '../../shared/services/jitsi.service';
import {
  COMMS_PATH,
  WINDOW_NAME_MAIN,
  WINDOW_NAME_COMMS,
  CALL_PATH,
  WINDOW_NAME_CALL,
} from '../../shared/others/constant';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  animations: [
    trigger('slideSideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(-100%,0,0)',
          opacity: 0,
          visibility: 'hidden',
          pointerEvents: 'none',
          // display: 'none'
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(59px, 0, 0)',
          opacity: 1,
          visibility: 'visible',
          pointerEvents: 'auto',
          // display: 'block'
        })
      ),
      transition(
        'in => out',
        animate('250ms ease-in-out')
        // [group([
        //   animate('400ms ease-in-out', style({
        //       'opacity': '0'
        //   })),
        //   animate('600ms ease-in-out', style({
        //       'max-height': '0'
        //   })),
        //   animate('400ms ease-in-out', style({
        //       'display': 'none'
        //   }))
        // ]
        // )]
      ),
      transition('out => in', animate('100ms ease-in-out')),
    ]),
  ],
  providers: [HomeComponent],
})
export class SidebarMenuComponent implements OnInit, AfterViewInit {
  // currUserEmail = localStorage['email'];
  displayName;

  navbarOpen = false;
  isNewWindow: boolean = false;
  // isColor:string = 'colorGreen';
  @ViewChild('appAcctMenu') appAcctMenu: AccountMenuComponent;
  menuState: string = 'in';
  notifState: string = 'in';

  jitsiMuteSubscription: Subscription;
  broadcastMicMuted: boolean = true;

  logo: any = '';
  // messageList = [];
  getAllMessage: Subscription;
  isClark: boolean = false;
  allVehicle = [];

  @ViewChild('chatPS', { static: true }) chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {};
  alarmList = [1, 2, 3];

  // broadcast variables
  showBroadcastMicStatus: boolean = false;

  constructor(
    private authService: AuthService,
    // private homeComponent: HomeComponent,
    private homeService: HomeService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private _commonService: CommonService,
    private _jitsiService: JitsiService,
    public menuService: MenuService
  ) {
    if (!!this.menuService.isMenuPinned) {
      this.menuState = 'out';
    }
  }

  ngOnInit() {
    this.logo = environment.image.logo;
    this.showBroadcastMicStatus = false;

    // var allMessageCache = this.homeService.getAllMessageCache();
    // this.messageList = allMessageCache;
    this.homeService.getAllMessagePanelCache();
    // console.log(this.messageList);

    this.allVehicle = this.homeService.getMessageMessageList();

    this.getAllMessage = this.homeService.getAllMessage().subscribe(message => {
      // if(this.messageList.indexOf(message.busRegNo) < 0 && message.busRegNo !== undefined) {
      // var isVehicleExist = this.messageList.find(x => x.busRegNo === message.busRegNo);

      // console.log(this.messageList);
      // console.log(isVehicleExist);
      // console.log(message);
      // if(!isVehicleExist) {
      if (!this.allVehicle.includes(message.busRegNo)) {
        // this.messageList.push(message);
        // this.homeService.setMessageMessageList(message.busRegNo);
        this.allVehicle.push(message.busRegNo);
        // console.log('pushed');
        // console.log(this.messageList);
      }
    });
    if (environment.project === 'clark') {
      this.isClark = true;
    }

    // setTimeout(() => {
    //   this.alarmList.push(4);
    // }, 3000);

    this.jitsiMuteSubscription = this._jitsiService
      .getBroadcastMute()
      .subscribe(muted => {
        this.broadcastMicMuted = muted;
      });
  }

  userRole = undefined;
  ngAfterViewInit() {
    const token: any = jwt_decode(localStorage.getItem('access_token'));
    const { displayname, scope } = token || {};
    const [role] = scope || {};
    this.displayName = displayname || localStorage['displayname'];
    this.userRole = role;
  }

  getBackgroundImg(imgData) {
    const img = '../../../../assets/images/icon/' + imgData;
    const style = `background: url(${img}) center / 100% no-repeat`;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  onLogout() {
    this._commonService.logout();
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  openedChange(opened) {
    this.appAcctMenu.openedChange(opened);
  }

  toggleMenu() {
    if (!this.menuService.isMenuPinned) {
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
      this.notifState = 'in';
    }
  }
  toggleNotif() {
    this.notifState = this.notifState === 'out' ? 'in' : 'out';
    if (!this.menuService.isMenuPinned) {
      this.menuState = 'in';
    }
  }

  onClickBroadcast() {
    if (this.showBroadcastMicStatus) {
      this._jitsiService.setBroadcastMuteStatus(!this.broadcastMicMuted);
    } else {
      this._commonService.openWindow(
        COMMS_PATH,
        WINDOW_NAME_MAIN,
        WINDOW_NAME_COMMS
      );
    }
    // const windowFeatures = '';
    // const busRegNo = 'SBS330D';
    // this._commonService.openWindow(`${CALL_PATH}?busRegistrationNumber=${busRegNo}`, WINDOW_NAME_MAIN, `${WINDOW_NAME_CALL}_${busRegNo}`, windowFeatures);
  }

  onShowAccount() {
    this.dialog.open(AccountSettingComponent, {
      data: {
        header: 'Account Details',
      },
    });

    // dialogRef.afterClosed().subscribe(
    //   result => {
    //     console.log(result);
    //   }
    // )
  }

  onChangePassword() {
    this.dialog.open(ChangePasswordComponent, {
      data: {
        header: 'Change Password',
      },
      disableClose: true,
      // height: '400px',
      width: '400px',
    });

    // dialogRef.afterClosed().subscribe(
    //   result => {
    //     console.log(result);
    //   }
    // )
  }

  toggleMenuPin() {
    this.menuService.isMenuPinned = !this.menuService.isMenuPinned;
    if (this.menuService.isMenuPinned) {
      this.menuState = 'out';
    }
  }

  ngOnDestroy() {
    this.displayName = '';
    this.userRole = undefined;
    this.getAllMessage.unsubscribe();
  }
}
