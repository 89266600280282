import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import OlMap from 'ol/Map';
import OlXYZ from 'ol/source/XYZ';
import OlView from 'ol/View';
import OlFeature from 'ol/Feature';
import {
  Vector as VectorLayer,
  Image as ImageLayer,
  VectorTile as VectorTileLayer,
  Tile as TileLayer,
  VectorImage as VectorImageLayer,
} from 'ol/layer';
import { fromLonLat, transform } from 'ol/proj';
import { boundingExtent } from 'ol/extent';
import { OSM, Vector as VectorSource, TileArcGISRest } from 'ol/source';
import { apply } from 'ol-mapbox-style';
import { ScaleLine, defaults as defaultOlControls } from 'ol/control';

import { environment } from 'src/environments/environment';
import { MapType, OverlayLayer } from '../../shared/others/constant';
import { busRoutes, routeData } from './constant/routes';
import { MatSelectChange } from '@angular/material/select';
import { Geometry, LineString, MultiLineString, Point } from 'ol/geom';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { MockMapService } from './mock-map-service.service';
import { decode } from '@googlemaps/polyline-codec';
import { encodedPolylines } from './constant/polylines';
import { DemoBusStopTimingService } from './demo-service/bus-stop-timing.service';
import { fadeInOut } from '../../shared/others/animation/fadeInOut';
import { quickFade } from '../../shared/others/animation/quickFade';
import { inAndOut } from 'ol/easing';

const baseUrl = 'https://basemaps-api.arcgis.com/arcgis/rest/services/styles';
const getBaseMapUrl = (name, apiKey) =>
  `${baseUrl}/${name}?type=style&token=${apiKey}`;

@Component({
  selector: 'app-mock-map',
  templateUrl: './mock-map.component.html',
  styleUrls: ['./mock-map.component.scss'],
  animations: [fadeInOut, quickFade],
})
export class MockMapComponent implements AfterViewInit, OnDestroy {
  map: OlMap;
  source: OlXYZ;
  OSMLayer: TileLayer<any>;
  currentLayer: TileLayer<any> | VectorTileLayer | undefined;
  view: OlView;
  selectedBasemap: string = 'OSM';

  routes = busRoutes;
  routePolylines = {};
  isSelectedRoute = true;
  selectedRoute = '7003';

  busDetailsData = [
    {
      trip: '14996267285',
      busCode: 'SBS3408P',
      lat: 1.315257,
      lon: 103.763997,
      drivenPercentage: 0,
      direction: '',
      prevStopId: '17171',
      status: '2',
      isSelected: false,
      degrees: '133',
      isBusBunching: false,
      driverId: 'BlockLogin',
      blockId: '32850002',
      nextStopId: '17009',
      nextstopeta: '18:30',
      secondnextstopId: '',
      secondnextstopeta: '',
      thirdnextstopId: '',
      thirdnextstopeta: '',
      ratio: 0,
      value: '',
      pin: 1,
      expiryDate: '2024-09-23T10:33:39.052Z',
      orientation: 'bottom',
      headway: '1',
      stopCode: '17009',
      stopName: 'Clementi Int',
      fromCode: '17171',
      fromName: 'Clementi Stn Exit A',
      route: '285',
      routeName: '285',
      routeId: '285',
      busRegNo: 'SBS3408P',
    },
  ];

  boundingExtent = boundingExtent([
    fromLonLat(environment.map.boundingExtent.min),
    fromLonLat(environment.map.boundingExtent.max),
  ]);

  viewAnimate: OlView = new OlView({
    center: fromLonLat(environment.map.locationLonLat),
    zoom: environment.map.defaultZoom,
    minZoom: environment.map.minZoom,
    maxZoom: environment.map.maxZoom,
    projection: 'EPSG:900913',
    extent: this.boundingExtent,
  });

  constructor(
    private mockMapService: MockMapService,
    private demoBusStopTimingService: DemoBusStopTimingService,
    private cdr: ChangeDetectorRef
  ) {
    this.routePolylines = mockMapService.routePolylines;
    // console.log('polyline', this.routePolylines);
  }

  initMockService(busRoute, route) {
    this.mockMapService.initBusLayer(this.map);
    // setup dummy bus
    this.mockMapService.initBus(busRoute, route, route.busCount);
    this.demoBusStopTimingService.init();
  }

  ngAfterViewInit() {
    this.initMap();
    this.initMapTraffic();
    this.loadRoute();
    this.initMapInteraction();
  }

  ngOnDestroy() {
    this.mockMapService.clearBus();
    // do unload things
  }

  initMap() {
    this.OSMLayer = new TileLayer({
      //environment.map.osmLayer // 'assets/maps/Tiles/{z}/{x}/{y}.png'
      source: new OSM({
        // url: environment.map.osmLayer
        url: `${environment.map.staticMap}/{z}/{x}/{y}.png`,
        crossOrigin: null,
      }),
    });

    const scaleLine = new ScaleLine({
      units: 'metric',
    });

    this.map = new OlMap({
      controls: defaultOlControls().extend([scaleLine]),
      target: 'map',
      view: this.viewAnimate,
    });

    this.map.addLayer(this.OSMLayer);
    this.currentLayer = this.OSMLayer;
    this.OSMLayer.setVisible(true);

    let busStopPointerLayer = new VectorImageLayer({
      source: new VectorSource({
        features: [],
      }),
      className: 'busStopIndicatorLayer',
      properties: {
        name: 'busStopIndicatorLayer',
      },
    });

    busStopPointerLayer.setZIndex(3);
    busStopPointerLayer.setVisible(true);

    this.map.addLayer(busStopPointerLayer);
  }

  toggleVectorLayer(layerName) {
    let layers = this.map.getAllLayers();
    for (let row of layers) {
      let layerBusCode = layerName;
      let rowName = row.get('name');
      if (layerBusCode === rowName) {
        if (row.getVisible()) {
          row.setVisible(false);
        } else if (!row.getVisible()) {
          row.setVisible(true);
        }
      }
    }
  }

  isLayerExist(layerName) {
    let layers = this.map.getAllLayers();
    for (let row of layers) {
      let rowName = row.get('name');
      if (layerName === rowName) {
        return true;
      }
    }
    return false;
  }

  getLayer(layerName) {
    let layers = this.map.getAllLayers();
    for (let row of layers) {
      let rowName = row.get('name');
      if (layerName === rowName) {
        return row;
      }
    }
    return false;
  }

  applyMapStyle(basemapId: MapType) {
    this.selectedBasemap = basemapId;
    if (basemapId === MapType.OSM) {
      if (this.currentLayer) {
        this.map.removeLayer(this.currentLayer);
      } else {
        let layers = this.map.getLayers().getArray().slice();
        const mapBoxLayers = layers.filter(layer => layer.get('mapbox-source'));
        let me = this;
        mapBoxLayers.forEach(layer => me.map.removeLayer(layer));
      }
      this.map.removeLayer(this.OSMLayer);
      this.map.getLayers().insertAt(0, this.OSMLayer);
      this.currentLayer = this.OSMLayer;
    } else if (
      basemapId === MapType.Navigation ||
      basemapId === MapType.Satellite
    ) {
      const apiKey = environment.esriApiKey;
      const baseMapUrl = getBaseMapUrl(basemapId, apiKey);
      let layers = this.map.getLayers().getArray().slice();

      if (this.currentLayer) {
        this.map.removeLayer(this.currentLayer);
        this.currentLayer = undefined;
      }
      const mapBoxLayers = layers.filter(layer => layer.get('mapbox-source'));
      let me = this;
      mapBoxLayers.forEach(layer => me.map.removeLayer(layer));

      apply(this.map, baseMapUrl);
    }
  }

  listOverlayLayers: OverlayLayer[] = [];
  initOverlayLayers() {
    this.initMapTraffic();
  }

  initMapTraffic() {
    const trafficServiceUrl =
      'https://traffic.arcgis.com/arcgis/rest/services/World/Traffic/MapServer/export';
    const apiKey = environment.esriApiKey;

    const trafficTileLayer = new TileLayer({
      source: new TileArcGISRest({
        url: trafficServiceUrl,
        params: {
          token: apiKey,
          DPI: 224,
          size: '384,384',
          layers: 'show:41',
        },
        projection: 'EPSG:3857',
      }),
      opacity: 0.6,
      className: 'traffic-layer',
    });

    trafficTileLayer.setZIndex(1);
    trafficTileLayer.setVisible(false);
    this.map.addLayer(trafficTileLayer);
    this.listOverlayLayers.push({
      name: 'Traffic',
      layer: trafficTileLayer,
      enabled: false,
      icon: 'traffic.png',
    });
  }

  toggleOverlayLayer(layer: { name: string; enabled: boolean; index: number }) {
    const currLayer = this.listOverlayLayers[layer.index];
    currLayer.enabled = layer.enabled;
    currLayer.layer.setVisible(layer.enabled);
  }

  onSelectRoute(event: MatSelectChange) {
    const { value } = event;
    this.busSwitchOffAll(this.selectedRoute);
    this.mockMapService.clearBus();
    setTimeout(() => {
      this.selectedRoute = value;
      this.loadRoute();
    }, 500);
  }

  ladders = undefined;
  loadRoute() {
    this.isSelectedRoute = true;
    const busRoute = this.selectedRoute;
    const currBusRouteData = routeData[busRoute] ?? undefined;
    const routePath = this.routePolylines[busRoute];

    if (!!currBusRouteData) {
      let layerName = 'BusRoute-' + busRoute;

      this.ladders = currBusRouteData.ladder;
      this.updateLadderData();
      this.routerLadder(busRoute);
      this.toggleVectorLayer(layerName);
      this.createRouteVectorLayer(busRoute);

      this.addRouteMarker(
        busRoute,
        routePath,
        currBusRouteData.color,
        currBusRouteData.colorAlt
      );

      this.ladders.direction.forEach(element => {
        element.stops.forEach(stop => {
          // console.log('ladder stop ', stop);
          this.addStopMarker(
            stop,
            '',
            element.value,
            currBusRouteData.colorAlt,
            currBusRouteData.colorAlt2
          );
        });
      });
    }

    this.initMockService(busRoute, currBusRouteData);

    this.initLadder(busRoute);
    this.cdr.detectChanges();
  }

  updateLadderData() {
    const data = this.ladders;
    if (data.direction) {
      const dir1 = data.direction.find(ladder => ladder.value === '1');
      const dir2 = data.direction.find(ladder => ladder.value === '2');
      data.from = {
        location: dir1.stops[0].stopName,
        latitude: dir1.stops[0].latitude,
        longitude: dir1.stops[0].longitude,
      };
      if (dir2) {
        data.to = {
          location: dir2.stops[0].stopName,
          latitude: dir2.stops[0].latitude,
          longitude: dir2.stops[0].longitude,
        };
      } else {
        const dir1Length = dir1.stops?.length - 1 ?? 0;
        data.to = {
          location: dir1.stops[dir1Length].stopName,
          latitude: dir1.stops[dir1Length].latitude,
          longitude: dir1.stops[dir1Length].longitude,
        };
      }

      for (let row of data.direction) {
        for (let stop of row.stops) {
          stop.pin = '';
          stop.route =
            data.routeId === undefined ? data.route[0] : data.routeId[0];
          stop.routeName =
            data.routeName === undefined ? data.route[0] : data.routeName[0];
        }
      }
      this.ladders = data;
      console.log('updateladderdata', this.ladders);
    }
  }

  routerLadder(routeId) {
    if (this.ladders.direction) {
      var stopLen = 0;
      for (let direction of this.ladders.direction) {
        var stop = Object.keys(direction.stops);
        stopLen = stop.length - 1;
        direction['routeWidth'] = 100 / stopLen;
      }

      this.addRoute(this.ladders);

      this.ladders.direction.forEach((x: any) => {
        x.stops.forEach((y: any) => {
          y.highlight = false;
        });
      });
      this.toggleVisible(routeId);

      return { message: 'Created Route Ladder' };
    }
  }

  tripRoutes = new Map();
  tripStops = new Map();
  addRoute(route: any): void {
    let routeId =
      route.routeId === undefined ? route.route[0] : route.routeId[0];

    if (!this.tripRoutes.has(routeId)) {
      this.indexRoute(route);
      this.tripRoutes.set(routeId, route);

      route.direction.forEach((x: any) => {
        x.stops.forEach((y: any) => {
          let stopId = y.stopCode;
          this.tripStops.set(stopId, y);
        });
      });
    }
  }

  indexRoute(route: any): void {
    route.direction.forEach((x: any) => {
      x.stopIdx = [];

      x.stops
        .sort((x, y) => parseInt(x.stopSequence) - parseInt(y.stopSequence))
        .forEach((y: any) => {
          let stopId = y.stopCode;
          x.stopIdx.push(stopId);
        });
    });
  }

  createRouteVectorLayer(busService) {
    const featuresList = [];
    let vectorLayer = new VectorImageLayer({
      source: new VectorSource({
        features: featuresList,
      }),
      className: 'BusRoute-' + busService,
      properties: {
        name: 'BusRoute-' + busService,
      },
    });

    let busStopLayer = new VectorImageLayer({
      source: new VectorSource({
        features: [],
      }),
      className: 'BusStop-' + busService,
      properties: {
        name: 'BusStop-' + busService,
      },
    });

    let busStopLabelLayer = new VectorImageLayer({
      source: new VectorSource({
        features: [],
      }),
      className: 'BusStopLabel-' + busService,
      properties: {
        name: 'BusStopLabel-' + busService,
      },
    });

    this.map.addLayer(vectorLayer);
    this.map.addLayer(busStopLayer);
    this.map.addLayer(busStopLabelLayer);
    // console.log('bus vector layer', busService, vectorLayer);
    vectorLayer.setZIndex(1);
    vectorLayer.setVisible(true);
    busStopLayer.setZIndex(1);
    busStopLayer.setVisible(true);
    busStopLabelLayer.setZIndex(3);
    busStopLabelLayer.setVisible(true);
  }

  findLayer(busService, isRoute = false) {
    const currLayerName = isRoute
      ? 'BusRoute-' + busService
      : 'BusStop-' + busService;
    const layers = this.map.getLayers().getArray().slice();

    const serviceLayer =
      layers.find(layer => {
        const layerName = layer.get('name');
        if (layerName === currLayerName) {
          return true;
        }
        return false;
      }) ?? undefined;
    return serviceLayer;
  }

  isBirectional = false;
  addRouteMarker(busService, routePath, routeColor, routeColorAlt) {
    if (!routePath || routePath === null) {
      return false;
    }
    let arrPaths = [];
    let busRouteDirection1 = [];
    let busRouteDirection2 = [];

    for (const row of routePath) {
      let busPath = row.path;
      let rowDir = row.value;
      this.isBirectional = false;

      if (busPath.length > 1) {
        this.isBirectional = true;

        let pathColorData = routeColor ? routeColor : environment.map.pathColor;
        let busRouteData = busRouteDirection1;

        if (row.value != 1) {
          pathColorData = routeColorAlt
            ? routeColorAlt
            : environment.map.pathOtherDirectionColor;
          busRouteData = busRouteDirection2;
        }
        // console.log('bus length', busPath.length);
        // const cutBusPath = busPath.slice(0, 3000);
        busPath.forEach((row1, index) => {
          // let coord = row1.split(',');
          busRouteData.push([parseFloat(row1[1]), parseFloat(row1[0]), index]);
        });
        // for (const row1 of busPath) {
        //   // let coord = row1.split(',');
        //   busRouteData.push([parseFloat(row1[1]), parseFloat(row1[0])]);
        // }

        var routeGeom = new MultiLineString([busRouteData], 'XYM').transform(
          'EPSG:4326',
          'EPSG:3857'
        );
        var routeFeature = new OlFeature({
          type: 'route',
          geometry: routeGeom,
          data: { direction: row.value },
        });

        var style = new Style({
          stroke: new Stroke({
            color: pathColorData,
            width: 4,
          }),
          zIndex: 100,
        });
        routeFeature.setStyle(style);
        arrPaths.push(routeFeature);
      }
    }

    let featuresList = [];

    for (const path of arrPaths) {
      featuresList.push(path); //route
    }

    const currLayer = this.findLayer(busService, true);

    if (
      currLayer &&
      (currLayer instanceof VectorLayer ||
        currLayer instanceof VectorImageLayer)
    ) {
      const currSource = currLayer.getSource();

      // console.log('features', featuresList);

      currSource.addFeatures(featuresList);

      if (featuresList?.length > 0) {
        const extent = currSource.getExtent();
        this.map
          .getView()
          .fit(extent, { duration: 1000, padding: [20, 40, 180, 200] });
      }
    }
  }

  removeLayerFeature(layerData, featureNameData) {
    var source = layerData.getSource();
    var features = source.getFeatures();

    for (const feature of features) {
      let featureName = feature.values_.name;
      if (featureNameData === featureName) {
        source.removeFeature(feature);
      }
    }
  }

  busSwitchOffAll(busRoute) {
    this.showLadder = false;
    let layers = this.map.getLayers().getArray().slice();
    for (let row of layers) {
      let rowName = row.get('name');
      if (rowName !== undefined) {
        if (
          !(
            rowName === 'BusRoute-' + busRoute ||
            rowName === 'BusStop-' + busRoute
          )
        ) {
          if (
            rowName !== 'busStopIndicatorLayer' &&
            (row instanceof VectorLayer || row instanceof VectorImageLayer)
          ) {
            let features = row.getSource().getFeatures();
            features.forEach(element => {
              this.removeLayerFeature(row, element.get('name'));
            });
            this.map.removeLayer(row);
          }
        }
      }
    }

    this.busDetailsList = [];
    this.busMqtt = [];
  }

  addStopMarker(
    stopData,
    markerData,
    direction = null,
    markerColor,
    markerColor2 = null
  ) {
    let currLayer = 'BusStop-' + stopData.route;
    let layers = this.map.getLayers().getArray().slice();
    let source;
    let newFeature;
    var bgMarker = markerColor ? markerColor : '#00b5b5';

    if (direction === '2') {
      bgMarker = markerColor2 ? markerColor2 : '#9e8f0b';
    }

    for (const rowLayer of layers) {
      let layerName = rowLayer.get('name');
      if (
        currLayer === layerName &&
        (rowLayer instanceof VectorLayer ||
          rowLayer instanceof VectorImageLayer)
      ) {
        source = rowLayer.getSource();

        var bus1Style = new Style({
          zIndex: +stopData.stopSequence + (direction === '2' ? 0 : 1000),
          image: new Circle({
            radius: 14,
            stroke: new Stroke({
              color: '#fff',
              width: 3,
            }),
            fill: new Fill({
              color: bgMarker,
            }),
          }),
          text: new Text({
            offsetY: 1,
            textAlign: 'center',
            justify: 'center',
            textBaseline: 'middle',
            text: stopData.stopSequence,
            fill: new Fill({
              color: '#fff',
            }),
            font: 'bold 14px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
            // stroke: new Stroke({
            //   color: bgMarker,
            //   width: 30,
            // }),
          }),
        });
        var bus2Style = new Style({
          text: new Text({
            offsetY: 0,
            text: stopData.pin.toString(),
            padding: [2, 5, 2, 5],
          }),
        });

        newFeature = new OlFeature({
          type: 'icon',
          geometry: new Point(
            transform(
              [parseFloat(stopData.longitude), parseFloat(stopData.latitude)],
              'EPSG:4326',
              'EPSG:3857'
            )
          ),
          name: 'stop-' + stopData.stopCode,
          description: stopData,
        });
        // newFeature.setStyle([bus1Style, bus2Style]);
        newFeature.setStyle([bus1Style]);
        source.addFeature(newFeature);
        this.map.render();
      }
    }
  }

  toggleVisible(busRoute) {
    let row = this.ladders;
    let route = row.routeId === undefined ? row.route[0] : row.routeId[0];
    if (route === busRoute && row.isVisible === 'false') {
      row.isVisible = 'true';
    } else if (route === busRoute) {
      row.isVisible = 'false';
    }
    if (row.direction) {
      for (let direction of row.direction) {
        for (let stop of direction.stops) {
          // console.log(stop);
          if (route === busRoute) {
            stop.pin = '';
          }
        }
      }
    }
  }

  /**
   * hover label
   */
  showInfo = false;
  infoData = undefined;
  infoCoordinates = { x: 0, y: 0 };
  displayStationLabel(pixel, target) {
    const hit = this.map.hasFeatureAtPixel(pixel, {
      layerFilter: function (layer) {
        const layerName = layer.getClassName();
        // console.log('layer', layerName);
        return layerName.includes('BusRoute-');
      },
      hitTolerance: 4,
    });

    if (hit) {
      let isValidFeature = false;
      const feature = this.map.forEachFeatureAtPixel(
        pixel,
        function (feature: OlFeature, layer) {
          const featureName = feature.get('name');
          if (featureName && featureName.includes('stop')) {
            return feature;
          }
        }
      );

      if (feature) {
        const featureName = feature.get('name');
        const description = feature.get('description');
        // const featureGeometry = <Point>feature.getGeometry();
        // const featureCoordinates = featureGeometry.getCoordinates();
        this.infoCoordinates = { x: pixel[0], y: pixel[1] };

        const { stopName = undefined, stopCode = undefined } = description;
        // let newStopCode = stopCode;
        // if (newStopCode) {
        //   newStopCode = newStopCode.replace(/^0+/, '');
        // }

        // this.infoData = `${newStopCode ? newStopCode + ': ' : ''}${stopName}`;
        this.infoData = stopName;
        this.showInfo = true;

        // console.log('feature', featureName, this.infoData);
      } else {
        this.showInfo = false;
      }
    } else {
      this.showInfo = false;
    }
  }

  /**
   * vehicle info
   */
  busDetailsList = [];
  onBusClick(pixel, coordinate) {
    const hit = this.map.hasFeatureAtPixel(pixel, {
      layerFilter: function (layer) {
        const layerName = layer.getClassName();
        return layerName.includes('bus-layer');
        // return (
        //   layerName.includes('bus-layer') || layerName.includes('BusRoute-')
        // );
      },
      hitTolerance: 4,
    });

    if (hit) {
      const feature = this.map.forEachFeatureAtPixel(
        pixel,
        function (feature: OlFeature, layer) {
          console.log('feature: ', feature);
          const featureName = feature.get('name');
          if (featureName) {
            return feature;
          }
        }
      );

      if (feature) {
        const featureName = feature.get('name');
        const description = feature.get('description');

        if (featureName?.includes('bus') && description) {
          const { busCode, route } = description;

          if (busCode) {
            const isBusInList = this.busDetailsList.findIndex(
              detail => detail.busCode === busCode
            );
            if (isBusInList < 0) {
              this.busDetailsList.push({ busCode, route });
            }
          }
        }

        const busList = this.mockMapService.busList;

        console.log('feature', { featureName, description }, feature);
        console.log('buslist', busList);

        /**
         * get point nearest to coordinate + path index
         */
        // const point = feature.getGeometry().getClosestPoint(coordinate);

        // const lonlat = transform(
        //   [point[0], point[1]],
        //   'EPSG:3857',
        //   'EPSG:4326'
        // );
        // const pointIndex = point[2];
        // const estimatedIndex = Math.floor(pointIndex);

        // const currPath = this.routePolylines[this.selectedRoute][0].path;

        // const estimatedPoint = currPath[estimatedIndex] ?? null;

        // console.log('feature', featureName, description, feature);

        // console.log(
        //   'coordinates: ',
        //   lonlat,
        //   pointIndex,
        //   `${estimatedPoint[1]}, ${estimatedPoint[0]}`
        // );
      }
    }
  }

  initMapInteraction() {
    const me = this;
    this.map.on('pointermove', function (evt) {
      if (evt.dragging) {
        me.showInfo = false;
        me.infoData = undefined;
      }
      const pixel = me.map.getEventPixel(evt.originalEvent);
      me.displayStationLabel(pixel, evt.originalEvent.target);
    });

    this.map.on('singleclick', function (evt) {
      if (evt.dragging) {
        return;
      }
      const pixel = me.map.getEventPixel(evt.originalEvent);
      me.onBusClick(pixel, evt.coordinate);
    });
  }

  removeBusComponent(emittedData) {
    console.log('busData', emittedData);
    if (this.busDetailsList.length < 1) return;

    let busDetailsInstance = this.busDetailsList.filter(
      x => x.busCode !== emittedData.busCode
    );
    this.busDetailsList = busDetailsInstance;
  }

  /**
   * bus ladder
   */
  orientation: string = 'bottom';
  busMqtt: any = [];
  showLadder = false;

  initLadder(routeId: string) {
    const filteredBus = this.mockMapService.busList.filter(
      bus => bus.data.route === routeId
    );
    this.busMqtt = filteredBus.map(bus => bus.data);
    this.showLadder = true;
    // console.log(
    //   'bus ladder init',
    //   this.ladders,
    //   this.mockMapService.busList,
    //   this.busMqtt,
    //   routeId
    // );
  }

  openAllRoutes(data) {
    console.log('busladderdata', {
      ladders: this.ladders,
      busMqtt: this.busMqtt,
    });
  }
  closeAllRoutes(data) {}

  preventHighlight: boolean = true;
  preventHighlightClickTimer: any;
  highlightBusStopOnLadderAndMap(stopData) {
    console.log('highlight', stopData);
    this.highlightBusStopOnLadder(stopData.stopCode);
    this.highlightBusStopOnMap(stopData);
  }
  unhighlightBusStopOnLadderAndMap(stopData) {
    this.unhighlightBusStopOnLadder();
    this.unhighlightBusStopOnMap(stopData.stopCode);
  }

  highlightBusStopOnMap(stopData) {
    this.preventHighlight = false;
    this.preventHighlightClickTimer = setTimeout(() => {
      if (!this.preventHighlight) {
        const currLayer = 'BusStopLabel-' + this.selectedRoute;
        const layers = this.map.getLayers().getArray().slice();

        for (const rowLayer of layers) {
          let layerName = rowLayer.get('name');
          console.log('highlightBusStopOnMap', layerName, currLayer);
          if (
            currLayer === layerName &&
            (rowLayer instanceof VectorImageLayer ||
              rowLayer instanceof VectorLayer)
          ) {
            const source = rowLayer.getSource();
            const styles = new Style({
              // image: new Icon(
              //   /** @type {module:ol/style/Icon~Options} */ {
              //     anchor: [0.5, 25],
              //     anchorXUnits: 'fraction',
              //     anchorYUnits: 'pixels',
              //     opacity: 1,
              //     src: 'assets/images/icon/map-marker-red.png',
              //   }
              // ),
              text: new Text({
                offsetY: 0,
                text: stopData.stopName,
                fill: new Fill({
                  color: '#fff',
                }),
                padding: [6, 8, 6, 8],
                textBaseline: 'middle',
                font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
              }),
            });

            // rounded background
            const tempText = stopData.stopName.replace(/ /g, '');
            const squareText = '\u25A0'.repeat(tempText.length - 1);
            const squareBgTextStyle = new Style({
              text: new Text({
                text: squareText,
                offsetY: 0,
                fill: new Fill({
                  color: '#2a2b30',
                }),
                textAlign: 'center',
                justify: 'center',
                textBaseline: 'middle',
                padding: [6, 8, 6, 8],
                font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
                stroke: new Stroke({
                  color: '#2a2b30',
                  width: 24,
                }),
              }),
            });

            const newFeature = new OlFeature({
              type: 'icon',
              geometry: new Point(
                transform(
                  [
                    parseFloat(stopData.longitude),
                    parseFloat(stopData.latitude),
                  ],
                  'EPSG:4326',
                  'EPSG:3857'
                )
              ),
              name: 'label-stop-' + stopData.stopCode,
            });
            newFeature.setStyle([squareBgTextStyle, styles]);
            source.addFeature(newFeature);
          }
        }
      }
    }, 200);
  }

  unhighlightBusStopOnMap(stopCode) {
    this.preventHighlight = true;
    clearTimeout(this.preventHighlightClickTimer);
    const currLayer = 'BusStopLabel-' + this.selectedRoute;

    const stopLayer = this.getLayer(currLayer);
    if (stopLayer) {
      const stopNameRemove = 'label-stop-' + stopCode;
      this.removeLayerFeature(stopLayer, stopNameRemove);
    }
    this.preventHighlight = false;
  }

  busStopHiglighted = [];
  highlightBusStopOnLadder(stopCode) {
    this.ladders.direction.forEach((x: any) => {
      x.stops.forEach((y: any) => {
        if (y.stopCode === stopCode) {
          y.highlight = true;
          this.busStopHiglighted.push(y);
        }
      });
    });
  }

  unhighlightBusStopOnLadder() {
    if (this.busStopHiglighted.length > 0) {
      this.busStopHiglighted.forEach(x => {
        x.highlight = false;
      });

      this.busStopHiglighted = [];
    }
  }

  onBusAction(
    e,
    data,
    action: number = 1,
    orientation: string = 'bottom',
    headway: string = '1'
  ) {
    console.log('onBusAction', { e, data, action, orientation, headway });
    if (action === 1) {
      const { route, busCode } = data;
      if (busCode) {
        const isBusInList = this.busDetailsList.findIndex(
          detail => detail.busCode === busCode
        );
        if (isBusInList < 0) {
          this.busDetailsList.push({ busCode, route });
        }
      }
    }
  }

  onStopDetails(ladder, orientation, direction) {
    // console.log('stop detail', { ladder, orientation, direction });
    const { longitude, latitude, stopCode, stopName } = ladder ?? {};
    const stopData = { longitude, latitude, stopCode, stopName };
    if (longitude && latitude) {
      // this.unhighlightBusStopOnMap(stopCode);
      setTimeout(() => {
        this.viewAnimate.animate({
          center: fromLonLat([longitude, latitude]),
          duration: 1000,
          zoom: 16,
          easing: inAndOut,
        });
      }, 200);

      // setTimeout(() => {
      //   this.highlightBusStopOnMap(stopData);
      // }, 400);
    }
  }
}
