<div class="stop-details-all-group">
  <div
    fxLayout="row"
    fxLayoutAlign="left center"
    class="icon-header"
    *ngIf="stops.length > 0">
    <!-- <ng-container class="pull-left">
      <mat-icon *ngIf="isMinimize === 'in'" class="pointer" matSuffix (click)="showMinimize()">keyboard_arrow_up</mat-icon>
      <mat-icon *ngIf="isMinimize === 'out'" class="pointer" matSuffix (click)="showMinimize()">keyboard_arrow_down</mat-icon>
    </ng-container> -->
    <i class="fa fa-times-circle" (click)="closeMessagePanel()"></i>
  </div>
  <div class="sd-wrapper" [@slideUpDown]="isMinimize">
    <perfect-scrollbar
      #stopDetailsAll
      class="ps-stop-details-all"
      [config]="config"
      [scrollIndicators]="true">
      <div
        class="every-stop-all stop-{{ i }}"
        *ngFor="let stopAll of stops; let i = index">
        <app-stop-details-all [data]="stopAll"></app-stop-details-all>
      </div>
    </perfect-scrollbar>
  </div>
</div>
