<div
  class="sidebar-menu-group"
  [class.sidebar-menu-group-expanded]="menuService.isMenuPinned">
  <div class="top-align">
    <div class="side-logo" [style]="getBackgroundImg(logo)"></div>
    <div
      class="sidebar-icon"
      *ngIf="menuState !== 'out' || menuService.isMenuPinned"
      (click)="toggleMenu()"
      matTooltip="Menu"
      matTooltipPosition="right"
      [matTooltipDisabled]="menuService.isMenuPinned"
      [class.disabled-icon]="menuService.isMenuPinned">
      <i class="material-icons">menu</i>
    </div>
    <div
      class="sidebar-icon {{ menuState }}"
      *ngIf="menuState === 'out' && !menuService.isMenuPinned"
      (click)="toggleMenu()">
      <i class="material-icons">keyboard_arrow_left</i>
    </div>
    <div
      class="side-logo"
      *ngIf="isClark"
      [style]="getBackgroundImg('bcda-logo-192.png')"></div>
    <div
      class="side-logo"
      *ngIf="isClark"
      [style]="getBackgroundImg('cdc-logo-192.png')"></div>
  </div>
  <div class="bottom-align">
    <div
      class="sidebar-icon"
      (click)="onClickBroadcast()"
      matTooltip="Broadcast"
      matTooltipPosition="right">
      <i class="material-icons">radio</i>
      <div
        class="microphone"
        *ngIf="showBroadcastMicStatus"
        [class.unmuted]="!broadcastMicMuted">
        <i class="material-icons" *ngIf="broadcastMicMuted">mic_off</i>
        <i class="material-icons" *ngIf="!broadcastMicMuted">mic</i>
      </div>
    </div>
    <div
      class="sidebar-icon"
      *ngIf="notifState !== 'out'"
      (click)="toggleNotif()"
      matTooltip="Messages"
      matTooltipPosition="right">
      <i
        matBadge="{{ allVehicle === null ? '0' : allVehicle.length }}"
        matBadgeColor="warn"
        matBadgeSize="medium"
        [matBadgeHidden]="allVehicle === null || allVehicle?.length === 0"
        class="material-icons"
        >message</i
      >
    </div>
    <div
      class="sidebar-icon {{ notifState }}"
      *ngIf="notifState === 'out'"
      (click)="toggleNotif()">
      <i class="material-icons">keyboard_arrow_left</i>
    </div>
    <!-- <div class="sidebar-icon">
            <i matBadge="{{alarmList.length}}" matBadgeColor="warn" matBadgeSize="medium" class="material-icons"
            [routerLinkActive]="['active']" [routerLink]="['/account/alarm', 1]">alarm</i>
        </div> -->
    <div
      class="sidebar-icon"
      [matMenuTriggerFor]="settings"
      matTooltip="Account"
      matTooltipPosition="right">
      <i class="material-icons">person</i>
    </div>
  </div>
</div>

<mat-menu #settings="matMenu" yPosition="below" class="settings-menu">
  <div mat-menu-item class="current-user" (click)="onShowAccount()">
    {{ displayName | titlecase }}
    <div *ngIf="userRole" class="current-user-role">{{ userRole }}</div>
  </div>
  <div mat-menu-item class="current-user" (click)="onChangePassword()">
    Change Password
  </div>
  <!-- <button mat-menu-item [routerLink]="['/account/settings/ladder', 1]">Settings</button> -->
  <button mat-menu-item class="current-user" (click)="onLogout()">
    Logout
  </button>
</mat-menu>

<!-- <mat-sidenav #sidenav mode="side" opened class="example-sidenav" (openedChange)="openedChange($event)">
        <app-account-menu #appAcctMenu></app-account-menu>
        <div class="toggle-menu" (click)="sidenav.toggle()">
            <i class="fa fa-bars"></i>
        </div>
    </mat-sidenav> -->
<!-- <mat-sidenav #drawer mode="over" class="drawer-menu">
    <app-account-menu></app-account-menu>
</mat-sidenav> -->
<div
  class="drawer-menu"
  [@slideSideInOut]="menuState || menuService.isMenuPinned"
  [class.menu-expanded]="menuService.isMenuPinned">
  <perfect-scrollbar
    #chatPS
    id="ps-sidemenu"
    class="ps-sidemenu"
    [config]="config"
    [scrollIndicators]="true">
    <div class="menu-header">
      <div
        class="header-icon"
        (click)="toggleMenuPin()"
        matTooltip="Toggle Sidebar Pin">
        <i
          [ngClass]="{
            'material-icons': menuService.isMenuPinned,
            'material-icons-outlined': !menuService.isMenuPinned
          }"
          >push_pin</i
        >
      </div>
    </div>
    <app-account-menu></app-account-menu>
  </perfect-scrollbar>
</div>

<div
  class="notification"
  [@slideSideInOut]="notifState"
  [ngClass]="{
    'menu-expanded notification-expanded': menuService.isMenuPinned
  }">
  <app-notification></app-notification>
</div>
