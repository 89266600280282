import { OutletContext, RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { LOGIN_PATH } from './component/shared/others/constant';
import { ComponentRef, Injectable } from '@angular/core';
@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    let f = this.getPath(future);
    let c = this.getPath(curr);
    let r = false;

    // console.log('shouldReuseRoute ' + f + ' ' + c + ' ' + r);

    return r;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    let p = this.getPath(route);
    let r = this.reusable(route)
      ? (this.storedRouteHandles.get(p) as DetachedRouteHandle)
      : undefined;

    // console.log('retrieve ' + p  + ' ' + r);
    return r;
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    let p = this.getPath(route);
    let r = this.reusable(route) && this.storedRouteHandles.get(p) != undefined;

    // console.log('shouldAttach ' + p + ' ' + r)

    return r;
  }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    let p = this.getPath(route);
    let r = this.reusable(route);

    // console.log('shouldDetach ' + p + ' ' + r)

    return r;
  }
  store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle
  ): void {
    let p = this.getPath(route);
    // console.log('store ' + p + ' ' + detachedTree)

    if (detachedTree) {
      const componentRef: ComponentRef<any> = detachedTree['componentRef'];
      if (componentRef) {
        componentRef.destroy();
      }
    }
    // this.storedRouteHandles.set(p, detachedTree);

    this.storedRouteHandles.set(route.data['key'], detachedTree);
    // if(detachedTree) {
    //     console.log(detachedTree);
    //     const componentRef: ComponentRef<any> = detachedTree['componentRef']
    //     if (componentRef) {
    //         componentRef.destroy()
    //     }
    // }
  }

  private reusable(route: ActivatedRouteSnapshot): boolean {
    let p = this.getPath(route);

    return (
      p.includes('/account/') &&
      !p.endsWith('/vehicle-management') &&
      !p.endsWith('/reports') &&
      !p.endsWith('/route-management') &&
      !p.endsWith('/system-configuration') &&
      !p.endsWith('/master-data')
    );
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    return (
      '/' +
      route.pathFromRoot
        .filter(v => v.routeConfig)
        .map(v => v.routeConfig!.path)
        .join('/')
    );
  }
}
