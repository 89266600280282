<div class="login-group" [ngStyle]="{'background-image': 'url(../../../assets/images/'+loginBg+')'}" fxLayout="row" fxLayoutAlign="center center">
  <mat-card appearance="raised" fxFlex="17" fxFlex.sm="40" fxFlex.xs="80">
    <mat-card-header>
      <mat-card-title>Forgot Password</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>Email Address</mat-label>
          <input type="text" matInput name="username" formControlName="email">
        </mat-form-field>

        <i class="error response" *ngIf="form.controls['email'].touched && form.controls['email'].errors">
          Email not valid.
        </i> 

        <i *ngIf="loginResp" class="response">
          {{ loginResp }}
        </i>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <a href="/login" class="textBlack">Back</a>
          </div>
          <button class="btn-sign" mat-button color="primary" [disabled]="!form.valid || isLoader">Submit</button>
        </div>

      </form>
    </mat-card-content>
  </mat-card>
  
</div>
