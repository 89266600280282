<div class="user-edit-group">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" >
      <h2 mat-dialog-title>{{title}}</h2>
      <mat-icon class="pointer mar-right-10" (click)="onClose()">cancel</mat-icon>
    </div>
    <div *ngIf="subTitle" class="subtitle">
      <h6>{{subTitle}}</h6>
    </div>
  </div>

  <mat-dialog-content>
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File Name</th>
        <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
      </ng-container>

      <ng-container matColumnDef="requestedDateTime">
        <th mat-header-cell *matHeaderCellDef>Requested Date Time</th>
        <td mat-cell *matCellDef="let element"> {{element.requestedDateTime}} </td>
      </ng-container>

      <ng-container matColumnDef="downloadStatus">
        <th mat-header-cell *matHeaderCellDef>Download Status</th>
        <td mat-cell *matCellDef="let element"> {{element.downloadStatus}} </td>
      </ng-container>

      <ng-container matColumnDef="downloadedDateTime">
        <th mat-header-cell *matHeaderCellDef>Downloaded Date Time</th>
        <td mat-cell *matCellDef="let element"> {{element.downloadedDateTime}} </td>
      </ng-container>

      <ng-container matColumnDef="processStatus">
        <th mat-header-cell *matHeaderCellDef>Process Status</th>
        <td mat-cell *matCellDef="let element"> {{element.processStatus}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
          [ngClass]="{
            'highlighted': row.highlighted
          }"
      ></tr>
    </table>

    <div 
    [ngClass]="{
      'd-none': dataSource.data.length === 0
    }"
    >
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>