<div class="playback-sidebar-group">
  <div
    *ngIf="!isMaximize"
    class="minimize mat-elevation-z4"
    (click)="onChangeSize()"
    matTooltip="Playback Data"
    matTooltipPosition="left">
    <mat-icon> list </mat-icon>
  </div>
  <div *ngIf="isMaximize" class="maximize mat-elevation-z4" [@slideInRight]>
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title">Playback Data</span>

      <button mat-icon-button class="close-button" (click)="onChangeSize()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div fxLayoutAlign="space-between center" class="search-field">
      <mat-form-field
        fxFlex="50"
        appearance="outline"
        subscriptSizing="dynamic">
        <mat-label>Vehicle No</mat-label>
        <mat-select
          multiple="false"
          [(ngModel)]="selectedVehicle"
          #vehicleNo
          (selectionChange)="onFilterPlayback()">
          <mat-option value="all"> All </mat-option>
          <mat-option *ngFor="let vehicle of vehicleList" [value]="vehicle">
            {{ vehicle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        fxFlex="50"
        appearance="outline"
        subscriptSizing="dynamic">
        <mat-label>Event Type</mat-label>
        <mat-select
          multiple="true"
          [(ngModel)]="selectedEvent"
          #checkedEvent
          (selectionChange)="onFilterEvents()">
          <mat-option
            *ngFor="let event of eventListData"
            [value]="event.eventName">
            {{ event.formatName }}
            <!-- <ng-container *ngIf="event === 'driverlogon'">Driver Logon</ng-container>
                    <ng-container *ngIf="event === 'busbunching'">Bus Bunching</ng-container>
                    <ng-container *ngIf="event === 'textcomms'">Text Comms</ng-container>
                    <ng-container *ngIf="event === 'voiplog'">Voip Log</ng-container> -->
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <!-- <div fxLayout="row" fxLayoutAlign="space-between center" >
                <div>Time Stamp</div>
                <div>Vehicle</div>
                <div>Events</div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" >
                <div>14:02</div>
                <div>SG3099J</div>
                <div>Missing trip 23401</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" >
                <div>14:04</div>
                <div>SG3099J</div>
                <div>Missing trip 23301</div>
            </div> -->

      <perfect-scrollbar #chatPS class="ps-sidebar" [config]="config">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="sidebar-table">
          <ng-container matColumnDef="timeStamp">
            <th mat-header-cell *matHeaderCellDef>Time Stamp</th>
            <td mat-cell *matCellDef="let element">
              {{ showTimeString(element.timeStamp) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="vehicle">
            <th mat-header-cell *matHeaderCellDef>Vehicle</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.busNo">{{
                element.busNo
              }}</ng-container>
              <ng-container *ngIf="element.busRegNo">{{
                element.busRegNo
              }}</ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="events">
            <th mat-header-cell *matHeaderCellDef>Events</th>
            <td mat-cell *matCellDef="let element">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                  <!-- *ngIf="element.eventName === 'tripstartend' || element.eventName === 'Exit' || element.eventName === 'Enter' || element.eventName === 'driverlogon' || element.eventName === 'offroute'" -->
                  <div fxFlex="0 0 auto">
                    <!-- {{formatEventName(element.event !== undefined ? element.event : element.eventName)}} -->
                    {{ formatEventName(element.eventName)?.formatName }}
                    <span class="color-brown" *ngIf="element.stopId">
                      ({{ element.stopId }})</span
                    >
                  </div>
                  <div
                    class="material-icons"
                    *ngIf="
                      element.eventName !== 'textcomms' && element.latitude
                    "
                    (click)="onLocate(element)">
                    location_searching
                  </div>
                </div>

                <div
                  fxFlex
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  class="mar-left-10"
                  *ngIf="element.msgText && element.eventName === 'textcomms'">
                  <div *ngIf="!isOperator(element.msgSource)">From BC</div>
                  <div *ngIf="isOperator(element.msgSource)">From CC</div>
                  <div fxLayout="column">
                    <div
                      class="material-icons"
                      [matMenuTriggerFor]="messageMenu"
                      matTooltip="Message"
                      matTooltipPosition="left">
                      message
                    </div>
                    <mat-menu class="message-group" #messageMenu="matMenu">
                      <div class="message-text">{{ element.msgText }}</div>
                    </mat-menu>
                    <div
                      class="material-icons"
                      *ngIf="element.latitude"
                      (click)="onLocate(element)"
                      matTooltip="Location"
                      matTooltipPosition="left">
                      location_searching
                    </div>
                  </div>
                </div>

                <div
                  fxFlex="100"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  *ngIf="element.eventName === 'voiplog'">
                  <div
                    fxFlex="100"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    *ngIf="element.msgSource === 'WEBUI'">
                    <!-- {{element.callEvent | titlecase}} -->
                    <div>From CC</div>
                    <span class="material-icons" [matMenuTriggerFor]="voipMenu"
                      >call</span
                    >
                  </div>
                  <div
                    fxFlex="100"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    *ngIf="element.msgSource !== 'WEBUI'">
                    <div>From BC</div>
                    <div>
                      <span
                        class="material-icons"
                        [matMenuTriggerFor]="voipMenu"
                        >call</span
                      >
                      <div class="material-icons" (click)="onLocate(element)">
                        location_searching
                      </div>
                    </div>
                  </div>
                  <mat-menu class="voip-group" #voipMenu="matMenu">
                    <!-- {{element.msgSource}}  -->
                    <div fxLayout="column" fxLayoutAlign="space-around start">
                      <div fxLayout="row" fxLayoutAlign="space-around center">
                        <div class="color-brown">Initiated By:&nbsp;</div>
                        <div>{{ element.initiatedBy }}</div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="space-around center">
                        <div class="color-brown">Event:&nbsp;</div>
                        <div *ngIf="element.callEvent === 'attend'">
                          Connected
                        </div>
                        <div *ngIf="element.callEvent !== 'attend'">
                          {{ element.callEvent | titlecase }}
                        </div>
                      </div>
                    </div>
                  </mat-menu>
                </div>

                <div
                  fxFlex="100"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  *ngIf="element.eventName === 'Missed Stops'">
                  <div>
                    <!-- {{formatEventName(element.eventName)}} -->
                    {{ element.eventName }}
                    <span class="color-brown">({{ element.stopId }})</span>
                  </div>
                  <div
                    class="material-icons mar-left-5"
                    (click)="onLocateStop(element)">
                    location_searching
                  </div>
                  <!-- <span class="color-brown" *ngIf="element.eventName === 'Enter' || element.eventName === 'Exit'" >({{element.stopId}})</span> -->
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="pointer"
            [ngClass]="{ selected: row.isSelected }"></tr>
        </table>
      </perfect-scrollbar>
    </div>
  </div>
</div>
