
<div class="container settings-ladder-group">
  <div class="row">
    <div class="col-12 no-padding">
      <div>Ladder location</div>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="ladder-loc" id="inlineRadio1" value="top" checked="checked">
      <label class="form-check-label" for="inlineRadio1">Top</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="ladder-loc" id="inlineRadio2" value="middle">
      <label class="form-check-label" for="inlineRadio2">Middle</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="ladder-loc" id="inlineRadio3" value="bottom">
      <label class="form-check-label" for="inlineRadio3">Bottom</label>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12 no-padding">
      <div>Bus and Stops popup position</div>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="popup-loc" id="inlineRadio1" value="top" checked="checked">
      <label class="form-check-label" for="inlineRadio1">Top</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="popup-loc" id="inlineRadio3" value="bottom">
      <label class="form-check-label" for="inlineRadio3">Bottom</label>
    </div>
  </div>
</div>