import { Injectable } from '@angular/core';
import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text,
} from 'ol/style.js';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import {
  map,
  retryWhen,
  flatMap,
  tap,
  retry,
  catchError,
  concatMap,
  delay,
} from 'rxjs/operators';
import { Observable, throwError, of, interval } from 'rxjs';
import { forkJoin } from 'rxjs'; // RxJS 6 syntax
import Point from 'ol/geom/Point';
import OlFeature from 'ol/Feature';
import { transform } from 'ol/proj.js';
import GoogleLayer from 'olgm/layer/Google.js';
import { environment } from 'src/environments/environment';

class SearchItem {
  constructor(
    public track: string,
    public artist: string,
    public link: string,
    public thumbnail: string,
    public artistId: string
  ) {}
}

@Injectable({ providedIn: 'root' })
export class MapService {
  public strPaymentType: string = '';
  // mapProvider:string = 'OpenStreetMap';
  mapProvider: string = 'GoogleMap';
  // serviceList = [];

  constructor(private httpClient: HttpClient) {}

  httpRetry(maxRetry: number = 3, delayMs: number = 2000) {
    return (
      tap(ev => {
        // if (ev instanceof HttpResponse) {
        // console.log('###processing response', ev, 'this.location');
        // }
      }),
      retryWhen(errors =>
        errors.pipe(
          concatMap((error, count) => {
            //equivalent to 3 tries
            if (count <= 1) {
              //&& (error.status == 400 || error.status == 0)) {
              //this.logger('test1');
              return of(error.status);
            }

            return throwError(error);
          }),
          delay(3000)
        )
      )
    );
  }

  // logger(log) {
  //     console.log(JSON.stringify(log));
  //     const bodyData = new HttpParams().set('log', log);
  //     this.httpClient.post(environment.nodeUrlWs + 'log', bodyData)
  //     .subscribe(
  //         data => {
  //             return data;
  //         },
  //         err => console.log(err),
  //         () => console.log('done set logs')
  //     );
  // }

  isLetter(c) {
    if (!c) return false;
    return c.toLowerCase() != c.toUpperCase();
  }

  getInitSwitch() {
    const promise = new Promise((resolve, reject) => {
      const currEmail = localStorage['username'];
      this.httpClient
        .post(
          environment.apiServerUrl +
            'users/v1/getUserResponsibility/' +
            currEmail,
          null
        )
        .pipe(this.httpRetry())
        .subscribe(
          (data: any) => {
            // console.log(data);
            // for (const i in data) {
            //     this.serviceList.push({
            //         'service': data[i].routeId,
            //         'isOn': '',
            //         'responsibility': data[i].responsibility.toLowerCase()
            //     });
            // }
            let switchData = [];
            let routeList: any = [];
            if (data) {
              for (const row of data) {
                if (row?.routeId && this.isLetter(row?.routeId?.charAt(0)))
                  continue;
                if (
                  row?.routeId &&
                  row?.routeId?.toLowerCase().includes('test')
                )
                  continue;
                let respon = row.responsibility.toLowerCase();
                switchData[respon] = switchData[respon]
                  ? switchData[respon]
                  : [];
                switchData[respon].push({
                  service: row.routeId,
                  isOn: '',
                  routeName: row.routeName === undefined ? '' : row.routeName,
                });
                routeList.push(row.routeId);
              }
            }
            // console.log(routeList)
            localStorage.setItem('routeList', routeList);

            resolve(switchData);
          },
          err => console.log(err),
          () => console.log('done init switch')
        );
    });
    return promise;
  }

  parseRouteladder(data) {
    if (data.direction) {
      for (let row of data.direction) {
        for (let stop of row.stops) {
          stop.pin = '';
          stop.route =
            data.routeId === undefined ? data.route[0] : data.routeId[0];
          stop.routeName =
            data.routeName === undefined ? data.route[0] : data.routeName[0];
        }
      }
    }
    // data.buses = this.getBuses(data.route);
    // console.log(data);
    return data;
  }

  getBusRoute(routeId: string) {
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.apiServerUrl +
            'gtfsupload/v1/findRouteCoordsByRouteId/' +
            routeId,
          null
        )
        .subscribe(
          data => {
            // console.log('Get Bus Route Data: ', data);
            resolve(data);
          },
          err => {
            console.log('Error getting bus route: ', err);
            reject({});
          }
          // () => console.log('done get bus route')
        );
    });
    return promise;
  }

  getRouteLadder(routeId: string) {
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.apiServerUrl +
            'gtfsupload/v1/findRouteLadderByRouteId/' +
            routeId,
          null
        )
        //this.httpClient.post('https://httpstat.us/504', null)
        .pipe(this.httpRetry())
        .subscribe(
          (data: any) => {
            console.log('Get Route Ladder', routeId, data);
            // console.log(data.direction[1].stops[0]);
            // for (const i in data) {
            // for (const row of data.direction) {
            //     // console.log(row);
            //     for (const stop of row.stops) {
            //         let stop1 = {
            //             'stopCode' : stop[0],
            //             'stopName' : stop[1],
            //             'stopDesc' : stop[0],
            //             'latitude' : stop[2],
            //             'longitude' : stop[3]
            //         }
            //         stop.shift();
            //         stop.shift();
            //         stop.shift();
            //         stop.shift();
            //         stop.shift();
            //         console.log(stop);
            //         stop.stopCode = stop[0];
            //         // data[i].row.stopCode = stop[0];
            //         // data[i].row.stopName = stop[1];
            //         // data[i].row.stopDesc = stop[0];
            //         // data[i].row.latitude = stop[2];
            //         // data[i].row.longitude = stop[3];
            //     }
            // }

            // need to refactor / rethink how Direction From/To is obtained if we have multiple stops.
            if (data.direction) {
              data.from = {
                location: data.direction[0].stops[0].stopName,
                latitude: data.direction[0].stops[0].latitude,
                longitude: data.direction[0].stops[0].longitude,
              };
              const hasDirection1 = data.direction[1]?.stops?.length > 0;
              const direction0Length =
                data.direction[0]?.stops?.length - 1 ?? 0;
              data.to = {
                location: hasDirection1
                  ? data.direction[1].stops[0].stopName
                  : data.direction[0].stops[direction0Length].stopName,
                latitude: hasDirection1
                  ? data.direction[1].stops[0].latitude
                  : data.direction[0].stops[direction0Length].latitude,
                longitude: hasDirection1
                  ? data.direction[1].stops[0].longitude
                  : data.direction[0].stops[direction0Length].longitude,
              };
            }
            // }
            let routeLadder = this.parseRouteladder(data);
            // console.log(routeLadder);
            resolve(routeLadder);
          },
          err => {
            console.log('Get Stop Ladder Error: ', err);
            reject(err);
          }
          // () => console.log('done get route ladder')
        );
    });
    return promise;
  }

  getRouteDetailsByRoute(routeId) {
    var bodyOption = new HttpParams().set('routeId', routeId);
    // var bodyOption = {
    //     "routeId": routeId
    // }
    // bodyOption.set('routeId', routeId);
    const promise = new Promise((resolve, reject) => {
      // this.httpClient.post(environment.apiServerUrl + 'stoparrdep/findSummaryByRouteId', bodyOption.toString())//+routeId)
      this.httpClient
        .post(environment.apiRouteSummary + routeId, null)
        .pipe(this.httpRetry())
        .subscribe(
          data => {
            resolve(data);
          },
          err => console.log(err),
          () => console.log('done get route details')
        );
    });
    return promise;
  }

  getStopDetailsByStopCode(stopCode) {
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.apiServerUrl + 'gtfsupload/getStopArrival/' + stopCode)
        .subscribe(
          data => {
            resolve(data);
          },
          err => console.log(err),
          () => console.log('done get stop details')
        );
    });
    return promise;
  }

  getStopEtaByStopCode(stopCode) {
    var bodyOption = {
      stopCode: stopCode,
      isTerminalStop: false,
    };
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.apiServerUrl + 'gtfsupload/etaEstimateWebUI',
          bodyOption
        )
        // this.httpClient.post(environment.apiServerGtfsUrl + 'etaStopEstimate', bodyOption)
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            resolve(undefined);
          },
          () => console.log('done get stop eta details')
        );
    });
    return promise;
  }

  getStopEtaByStopCodeDataMall(stopCode) {
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.apiServerGtfsUrl + 'getSGStopArrival/' + stopCode,
          null
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            resolve(undefined);
          },
          () => console.log('done get stop eta data mall details')
        );
    });
    return promise;
  }

  getBusDetailsEta(data) {
    var bodyOption = {
      busRegNo: data.busCode,
      // "currLat": data.lat,
      // "currLon": data.lon,
      // "nextBusStopCode": data.nextStopId,
      tripId: data.trip,
    };
    const promise = new Promise((resolve, reject) => {
      // this.httpClient.post(environment.apiServerUrl + 'gtfsupload/etaEstimate', bodyOption)
      this.httpClient
        .post(
          environment.apiServerUrl + 'gtfsupload/busEtaEstimateWebUI',
          bodyOption
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            resolve(undefined);
          },
          () => console.log('done get bus eta details')
        );
    });
    return promise;
  }

  getHereTrafficData(area) {
    //'https://cors-anywhere.herokuapp.com/'+
    //let hereUrlLocation = environment.nodeUrl + 'get-location-here';//.replace('#location#', area);
    // return this.httpClient.jsonp(hereUrlLocation, "callback").pipe(
    //     map(res => {
    //     //   return res.results.map(item => {
    //           console.log(res);
    //           return res;
    //         // return new SearchItem(
    //         //   item.trackName,
    //         //   item.artistName,
    //         //   item.trackViewUrl,
    //         //   item.artworkUrl30,
    //         //   item.artistId
    //         // );
    //     //   });
    //     })
    //   );

    const promise = new Promise((resolve, reject) => {
      let trafficObs;
      // let test1 = [];
      // for (const location of area) {
      // console.log(location, ' location');
      const bodyData = new HttpParams().set('location', area);
      trafficObs = this.httpClient.post(
        environment.nodeUrl + 'get-location-here',
        bodyData
      );
      // trafficObs.push(this.httpClient.post(environment.nodeUrl + 'get-location-here', bodyData));
      // this.httpClient.post(environment.nodeUrl + 'get-location-here', bodyData)
      // .subscribe(
      //     data => {
      //         // trafficData.push(data);
      //         // trafficData.next()
      //         // data.next()
      //         resolve(data);
      //     },
      //     err => console.log(err),
      //     () => console.log('done get here data')
      // );
      // }

      // console.log(test1);
      // for (const iterator of test1) {

      //     iterator.subscribe(
      //         data => {
      //             console.log(data);
      //     });
      // }

      // let trafficData2 = [1,2,3,4];
      resolve(trafficObs);
    });

    return promise;
  }

  addMapMark(layer, mapData) {
    var featureList = [];

    // let count = index + 1;
    let iconFeature = new OlFeature({
      geometry: new Point(
        transform(
          [parseFloat(mapData.lon), parseFloat(mapData.lat)],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      name: mapData.name,
      dataObj: mapData,
    });

    let iconStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ {
          anchor: [0.5, 25],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          opacity: 1,
          src: mapData.imgSrc,
        }
      ),
      // text: new Text({
      //     offsetY: -37,
      //     // text: data.idx.toString(),
      //     fill: new Fill({
      //         color: '#fff'
      //     }),
      //     backgroundFill: new Fill({
      //         color: '#2a2b30'
      //     }),
      //     font: '16px Calibri,sans-serif',
      //     padding: [2, 5, 2, 5]
      // }),
      // zIndex: 4
    });
    iconFeature.setStyle(iconStyle);
    // return iconFeature;
    featureList.push(iconFeature);

    let source = layer.getSource();

    // console.log(mapData);

    source.addFeatures(featureList);

    layer.setZIndex(4);
    layer.setVisible(true);
    return layer;
  }

  addMapMarkWithText(layer, mapData) {
    var featureList = [];

    // let count = index + 1;
    let iconFeature = new OlFeature({
      geometry: new Point(
        transform(
          [parseFloat(mapData.lon), parseFloat(mapData.lat)],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      name: mapData.name,
      dataObj: mapData,
    });

    let iconStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ {
          anchor: [0.5, 25],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          opacity: 1,
          src: mapData.imgSrc,
        }
      ),
      text: new Text({
        offsetY: -35,
        text: mapData.message,
        fill: new Fill({
          //   color: '#fff'
          color: '#000',
        }),
        padding: [5, 5, 5, 5],
        font: 'bold 12px Arial, Verdana, Helvetica, sans-serif',
        stroke: new Stroke({
          //   color: '#797979',
          color: '#49d4e3',
          width: 20,
        }),
      }),
      // zIndex: 4
    });
    iconFeature.setStyle(iconStyle);
    // return iconFeature;
    featureList.push(iconFeature);

    let source = layer.getSource();

    console.log(mapData);

    source.addFeatures(featureList);

    layer.setZIndex(4);
    layer.setVisible(true);
    return layer;
  }

  addPinLocation(layer, mapData) {
    const source = layer.getSource();
    const featureCount = source.getFeatures().length ?? 0;

    const iconFeature = new OlFeature({
      geometry: new Point(
        transform(
          [parseFloat(mapData.lon), parseFloat(mapData.lat)],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      name: mapData.name,
      dataObj: mapData,
    });

    const markerSrc = 'assets/images/icon/marker-location-green.svg';

    const pinStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 1,
        scale: 0.9,
        src: markerSrc,
      }),
      zIndex: featureCount + 1,
    });

    const textStyle = new Style({
      zIndex: featureCount + 1,
      text: new Text({
        offsetY: -19,
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'bottom',
        text: mapData.message,
        fill: new Fill({
          color: '#333',
        }),
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
      }),
    });

    iconFeature.setStyle([pinStyle, textStyle]);

    source.addFeature(iconFeature);

    layer.setZIndex(4);
    layer.setVisible(true);
    return layer;
  }

  isLayerExist(mapObj, layerName) {
    let layers = mapObj.getLayers().array_;
    for (let row of layers) {
      let rowName = row.values_.name;
      if (layerName === rowName) {
        return row;
      }
    }
    return false;
  }

  hideGoogleLayer(mapObj) {
    let layers = mapObj.getLayers().array_;
    for (let row of layers) {
      let rowName = row.values_.name;
      if (row instanceof GoogleLayer === true) {
        row.setVisible(false);
      }
    }
  }

  centerLayerMap(mapObj, layer) {
    var extent = layer.getSource().getExtent();
    mapObj.getView().fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
  }

  removeLayerFeature(layerData, featureNameData) {
    var source = layerData.getSource();
    var features = source.getFeatures();

    for (const feature of features) {
      let featureName = feature.values_.name;
      if (featureNameData === featureName) {
        source.removeFeature(feature);
      }
    }
  }

  removeLayerByLayerName(mapObj, layerName) {
    mapObj.getLayers().forEach(layer => {
      if (layer && layer.get('name') === layerName) {
        mapObj.removeLayer(layer);
      }
    });
  }
}
