import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
// import { DataSource } from 'ng2-smart-table/lib/data-source/data-source';

// import { DataSource } from '../../lib/data-source/data-source';
import { TableModule } from 'primeng/table';
import { PmService } from '../pm.service';
import { PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';

export interface Car11 {
  vin;
  year;
  brand;
  color;
  price;
}

@Component({
  selector: 'ng2-smart-table-pager',
  styleUrls: ['./pager.component.scss'],
  templateUrl: './pager.component.html',
})
export class PagerComponent implements OnInit {
  cars = [];

  cols: any[];

  brands;

  colors;

  yearFilter: number;

  yearTimeout: any;

  drivers: any[];
  status: any[];
  temporary: any[];
  selectedCar1;
  totalRecords: number = 0;
  //   public config: PerfectScrollbarConfigInterface = {};

  constructor(private pmService: PmService) {}

  ngOnInit() {
    // this.pmService.getAllDriver().then(
    //   (ret:any) => {
    //     console.log(ret);
    //     this.drivers = ret;
    //   }
    // );

    this.pmService.getAllDriver().then((ret: any) => {
      console.log(ret);
      this.cars = ret;
      // this.cars = [
      //   {"vin":"a1653d4d","brand":"VW","year":1998,"color":"White","price":10000},
      //   {"vin":"ddeb9b10","brand":"Mercedes","year":1985,"color":"Green","price":25000},
      //   {"vin":"d8ebe413","brand":"Jaguar","year":1979,"color":"Silver","price":30000},
      //   {"vin":"aab227b7","brand":"Audi","year":1970,"color":"Black","price":12000},
      //   {"vin":"631f7412","brand":"Volvo","year":1992,"color":"Red","price":15500},
      //   {"vin":"7d2d22b0","brand":"VW","year":1993,"color":"Maroon","price":40000},
      //   {"vin":"50e900ca","brand":"Fiat","year":1964,"color":"Blue","price":25000},
      //   {"vin":"4bbcd603","brand":"Renault","year":1983,"color":"Maroon","price":22000},
      //   {"vin":"70214c7e","brand":"Renault","year":1961,"color":"Black","price":19000},
      //   {"vin":"ec229a92","brand":"Audi","year":1984,"color":"Brown","price":36000},
      //   {"vin":"1083ee40","brand":"VW","year":1984,"color":"Silver","price":215000},
      //   {"vin":"6e0da3ab","brand":"Volvo","year":1987,"color":"Silver","price":32000}
      // ];
    });

    this.status = [
      { value: 'DISMISSED', label: 'DISMISSED' },
      { value: 'ON-LEAVE', label: 'ON-LEAVE' },
      { value: 'NEW', label: 'NEW' },
      { value: 'RESIGNED', label: 'RESIGNED' },
      { value: 'SUSPENDED', label: 'SUSPENDED' },
    ];

    this.temporary = [
      { value: 'Y', label: 'Y' },
      { value: 'N', label: 'N' },
    ];
    // this.brands = [
    //     { label: 'All Brands', value: null },
    //     { label: 'Audi', value: 'Audi' },
    //     { label: 'BMW', value: 'BMW' },
    //     { label: 'Fiat', value: 'Fiat' },
    //     { label: 'Honda', value: 'Honda' },
    //     { label: 'Jaguar', value: 'Jaguar' },
    //     { label: 'Mercedes', value: 'Mercedes' },
    //     { label: 'Renault', value: 'Renault' },
    //     { label: 'VW', value: 'VW' },
    //     { label: 'Volvo', value: 'Volvo' }
    // ];

    this.colors = [
      { label: 'White', value: 'White' },
      { label: 'Green', value: 'Green' },
      { label: 'Silver', value: 'Silver' },
      { label: 'Black', value: 'Black' },
      { label: 'Red', value: 'Red' },
      { label: 'Maroon', value: 'Maroon' },
      { label: 'Brown', value: 'Brown' },
      { label: 'Orange', value: 'Orange' },
      { label: 'Blue', value: 'Blue' },
    ];

    this.cols = [
      { field: 'driverId', header: 'Driver Id' },
      { field: 'driverStatus', header: 'Status' },
      { field: 'fullName', header: 'Name' },
      { field: 'primaryPhone', header: 'Primary No.' },
      { field: 'address', header: 'Address' },
      { field: 'joiningDate', header: 'Joining Date' },
      { field: 'isTemporary', header: 'Is Temporary?' },
      { field: 'lastDutyDate', header: 'Last Duty Date' },
      { field: 'identificationId', header: 'ID No.' },
      { field: 'identificationDocType', header: 'ID Type' },
    ];
  }

  onYearChange(event, dt) {
    if (this.yearTimeout) {
      clearTimeout(this.yearTimeout);
    }

    this.yearTimeout = setTimeout(() => {
      dt.filter(event.value, 'year', 'gt');
    }, 250);
  }

  clonedCars = {};
  onRowEditInit(car) {
    console.log(car);
    this.clonedCars[car.driverId] = { ...car };
  }

  onRowEditSave(car) {
    if (car.year > 0) {
      delete this.clonedCars[car.driverId];
      // this.messageService.add({severity:'success', summary: 'Success', detail:'Car is updated'});
    } else {
      // this.messageService.add({severity:'error', summary: 'Error', detail:'Year is required'});
    }
  }

  onRowEditCancel(car, index: number) {
    this.cars[index] = this.clonedCars[car.driverId];
    delete this.clonedCars[car.driverId];
  }
}
