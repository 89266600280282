<div class="incident-group">
  <div class="container no-padding">
    <div class="col">
      <div class="row">
        <h3 class="title-header">Incident Management<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
      </div>
    </div>
    <div class="col message">
      <h3>Only administrator has access rights to this module.</h3>  
    </div>
    </div>
</div>