
<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Confirmation</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Are you sure?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
  <!-- <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button> -->
  <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('okay')">Okay</button>
</div>