<div class="vehicle-calendar-group">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="large" color="#fff" type="{{spinnerType}}" [fullScreen]="true"><p style="color: white; font-size: 18px; margin-top: 20px;" > Loading... </p></ngx-spinner>
  <div class="calendar-group">
      
    <!-- <div fxLayout="row" fxLayoutAlign="center center" >
      <h3>{{calendarTitle}}</h3>
    </div> -->

    <div class="mar-bot-10" fxLayout="row" fxLayoutAlign="space-between center" >
      <!-- <button style="font-size: 40px" mat-fab color="accent" aria-label="Example icon button with a bookmark icon">
        <mat-icon>navigate_before</mat-icon>
        Previous
      </button> -->
      <button [disabled]="isRunning" mat-button (click)="onPrevMonth()" >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="navigation">navigate_before</mat-icon>
          <span>Previous</span>
        </div>

      </button>
      <h3>{{calendarTitle}}</h3>
      <button [disabled]="isRunning" mat-button (click)="onNextMonth()">
        <div fxLayout="row" fxLayoutAlign="start center">
          <span>Next</span>
          <mat-icon class="navigation">navigate_next</mat-icon>
        </div>

      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" >
      <ng-container *ngFor="let header of weekHeader">
        <div class="text-center" fxFlex="14.2">
          {{header}}
        </div>
      </ng-container>
    </div>

    <div>
      <ng-container *ngFor="let data of calendarData; let idx = index">
          <div *ngIf="data" class="weekly-row" fxLayout="row" fxLayoutAlign="space-between center" >
            <ng-container *ngFor="let week of data; let idx = index">
              <div class="table-cell" fxFlex="14.2"
              [ngClass]="{
                includeDay: !week.isSameMonth,
                today: currentDay === week.date
              }">
                <div class="text-right font-bold" fxLayout="row" fxLayoutAlign="end center" >
                  <ng-container *ngIf="currentDay === week.date">
                    <span class="width100 text-center">Today</span>
                  </ng-container>
                  <span>{{week.dayDate}}</span>
                </div>
                <!-- <ng-container *ngIf="week.data"> -->
                  <!-- <ng-container *ngIf="week.dayNumber === 0; else notSunday"> -->
                    <a
                    class="d-table1 textWhite"
                    href="account/schedule-data-table/1?pto={{pto.agency}}&date={{week.currentSunday}}&day={{week.date}}"
                    target="_blank" fxLayout="row" fxLayoutAlign="space-between center"
                    *ngFor="let pto of week.data">
                      <span class="pto-cell">{{pto.agency}}</span>
                      <div>
                        <span class="text-right">
                          {{pto.totalUpdatedVehicle}}/{{pto.totalVehicle}}
                          <i 
                          [ngClass]="{
                            'textRed': pto.percentage <= 50,
                            'textYellow': pto.percentage > 50 && pto.percentage <= 75,
                            'textGreen': pto.percentage > 75
                          }">({{pto.percentage}}%)</i>
                        </span> 
                      </div>
                    </a>
                  <!-- </ng-container>
                  
                  <ng-template #notSunday>
                    <span fxLayout="row" fxLayoutAlign="start center" 
                    class="d-table"
                    href="account/schedule-data-table/1?pto={{pto.agency}}&date={{week.date}}"
                    target="_blank"
                    *ngFor="let pto of week.data">
                      <span class="mar-right-10">{{pto.agency}}</span>
                      <span class="mar-right-10"
                      [ngClass]="{
                        'textRed': pto.percentage <= 50,
                        'textYellow': pto.percentage > 50 && pto.percentage <= 75,
                        'textGreen': pto.percentage > 75
                      }">{{pto.totalUpdatedVehicle}}/{{pto.totalVehicle}}</span> <i>({{pto.percentage}}%)</i>
                    </span>
                  </ng-template> -->
                <!-- </ng-container> -->
              </div>
            </ng-container>
          </div>
      </ng-container>
    </div>
  </div>
</div>