import { NgModule } from '@angular/core';

import { GetByIdPipe } from './getById.pipe';
import { MomentPipe } from './moment.pipe';
import { MomentUnixPipe } from './momentUnix.pipe';

@NgModule({
  imports: [],
  declarations: [GetByIdPipe, MomentPipe, MomentUnixPipe],
  exports: [GetByIdPipe, MomentPipe, MomentUnixPipe],
})
export class SharedPipesModule {}
