import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export type Message = { [key: string]: string | boolean };

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  private alreadyInited = false;
  private messaging = new Subject<MessageEvent>();

  constructor(
    private swUpdate: SwUpdate,
    private _snackBar: MatSnackBar
    ){}

  async init() {
    if (!environment.sw) return;
    if(!this.swUpdate.isEnabled || this.alreadyInited) return;
    this.alreadyInited = true;
    this.swUpdate.checkForUpdate();
    this.swUpdate.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))).subscribe(async evt => {
        try {
          const snackbarRef = this._snackBar.open('The newest version of the application has been installed. Please refresh the page or wait to automatically refresh in 30 seconds.', 'Refresh', {
            duration: 30000, // triggers afterDismissed after duration ends
          });

          snackbarRef.afterDismissed().subscribe(() => {
            // console.log('Service worker reload');
            location.reload();
          });

          await this.swUpdate.activateUpdate();
          console.log('Service worker update: ', evt);
        } catch (err) {
          console.log('Service worker error: ', err);
        } finally {
          // console.log('Service worker reload');
          // location.reload();
        }
      });

    // in reload loop - need to fix
    // this.swUpdate.versionUpdates.subscribe(async evt => {
    //   console.log('SW Service versionUpdates: ', evt);
    //   switch (evt.type) {
    //       case 'VERSION_DETECTED':
    //           console.log(`Downloading new app version: ${evt.version.hash}`);
    //           // await this.swUpdate.activateUpdate();
    //           // location.reload();
    //           break;
    //       case 'VERSION_READY':
    //           console.log(`Current app version: ${evt.currentVersion.hash}`);
    //           console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
    //           await this.swUpdate.activateUpdate();
    //           location.reload();
    //           break;    
    //       case 'VERSION_INSTALLATION_FAILED':
    //           console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
    //           document.location.reload();
    //           break;
    //   }
    // });

    // this.swUpdate.versionUpdates.pipe(
    //   tap(update => {
    //     console.log('sw updates: ', update);
    //       if (update.type === 'VERSION_DETECTED') {
    //           // activateUpdate() will trigger the 'VERSION_READY' or 'VERSION_INSTALLATION_FAILED' event when done
    //           console.log('New server version detected, trying to install...');
    //           this.swUpdate.activateUpdate().then();
    //       }
    //       if (update.type === 'VERSION_READY') {
    //           // this._reloadPage will be set to true, asking a full page reload on next navigation
    //           console.log('New server version installed');
    //           document.location.reload();
    //           // this.notify(false);
    //       }
    //       if (update.type === 'VERSION_INSTALLATION_FAILED') {
    //           // should do cache clear and full page reload here
    //           console.warn('Error while installing update, page will be reloaded');
    //           document.location.reload();
    //           // this.notify(true);
    //       }
    //       // another event type possible is 'NO_NEW_VERSION_DETECTED', we don't need to handle it
    //   })
    // ).subscribe();
    
    // add message listener
    const me = this;
    this.sw.addEventListener("message", event => {
      me.messaging.next(event)
      // if (event.data.someProperty) {
      //     // do something
      // }
  });

  }

  message(message: Message): void{
    if (environment.sw && this.controller) {
      this.controller.postMessage(message);
    }
  }

  onMessage(): Observable<MessageEvent> {
      return this.messaging.asObservable();
  }

  private get controller(): ServiceWorker {
      return this.sw.controller as ServiceWorker;
  }

  private get sw(): ServiceWorkerContainer {
      return navigator.serviceWorker as ServiceWorkerContainer;
  }
}
