export const demoRouteList = [
  {
    id: 11392,
    userId: 127,
    routeId: '7001',
    responsibility: 'PRIMARY',
    routeName: '7001',
    priorityOrder: 1,
    ctryCode: 'SG',
  },
  {
    id: 11393,
    userId: 127,
    routeId: '7002',
    responsibility: 'PRIMARY',
    routeName: '7002',
    priorityOrder: 1,
    ctryCode: 'SG',
  },
  {
    id: 11394,
    userId: 127,
    routeId: '7003',
    responsibility: 'PRIMARY',
    routeName: '7003',
    priorityOrder: 1,
    ctryCode: 'SG',
  },
];

export const routeLabel = {
  7001: 'Red Route',
  7002: 'Green Route',
  7003: 'Orange Route',
};

export const demoResultService = {
  7001: {
    routeId: '7001',
    routeName: 'Red Route',
    navigation: [
      {
        routeHeadSign: 'Kingston Quay - Lancaster Loop',
        direction: 1,
        path: [],
        stops: ['RS1', 'RS2', 'RS3', 'RS4', 'RS5', 'RS6', 'RS7'],
      },
      {
        routeHeadSign: 'Lancaster Loop - Kingston Quay',
        direction: 2,
        path: [],
        stops: ['RS7', 'RS6', 'RS5', 'RS4', 'RS3', 'RS2', 'RS1'],
      },
    ],
  },
  7002: {
    routeId: '7002',
    routeName: 'Green Route',
    navigation: [
      {
        routeHeadSign: 'Buckingham Blvd - Liverpool Landing',
        direction: 1,
        path: [],
        stops: [
          'GS1',
          'GS2',
          'GS3',
          'GS4',
          'GS5',
          'GS6',
          'GS7',
          'GS8',
          'GS9',
          'GS10',
        ],
      },
      {
        routeHeadSign: 'Liverpool Landing - Buckingham Blvd',
        direction: 2,
        path: [],
        stops: [
          'GS10',
          'GS9',
          'GS8',
          'GS7',
          'GS6',
          'GS5',
          'GS4',
          'GS3',
          'GS2',
          'GS1',
        ],
      },
    ],
  },
  7003: {
    routeId: '7003',
    routeName: 'Orange Route',
    navigation: [
      {
        routeHeadSign: 'Town Hall - Manchester Meadows',
        direction: 1,
        path: [],
        stops: ['PS1', 'PS2', 'PS3', 'PS4', 'PS5'],
      },
      {
        routeHeadSign: 'Manchester Meadows - Town Hall',
        direction: 2,
        path: [],
        stops: ['PS5', 'PS4', 'PS3', 'PS2', 'PS1'],
      },
    ],
  },
};
