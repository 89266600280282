import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment, { months } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/component/shared/services/common.service';

@Component({
  selector: 'app-vehicle-calendar',
  templateUrl: './vehicle-calendar.component.html',
  styleUrls: ['./vehicle-calendar.component.scss']
})
export class VehicleCalendarComponent implements OnInit {

  calendarData = [];
  weekHeader = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  currentDate = moment().format('YYYY-MM-DD');
  currentDay= moment().format('YYYY-MM-DD');
  calendarTitle: string = '';
  firstDay: string = '';
  lastDay: string = '';
  dataSet: any;
  isRunning: boolean = false;
  spinnerType: string;

  constructor(
    private _commonService: CommonService,
    private _spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar
    ) {
    
  }

  ngOnInit() {
    this.initCalendarData();
  }

  initCalendarData() {
    this.isRunning = true;
    this._spinner.show();
    this.spinnerType = this._commonService.spinnerType[Math.floor(Math.random() * 52)];
    // this.currentDate = moment().format('YYYY-MM-DD');
    var fDay = moment(this.currentDate).startOf('month').format('YYYY-MM-DD');
    var firstDay = moment(fDay).startOf('week').format('YYYY-MM-DD');
    this.firstDay = firstDay;
    this.lastDay = moment(firstDay).add(41, 'days').format('YYYY-MM-DD');
    this.calendarData = [];
    this.calendarTitle = moment(this.currentDate).format('MMMM YYYY');

    var urlAction = 'vehicles/v1/getDatasetUpdatedVehicleCount?startDate='+this.firstDay+'&endDate='+this.lastDay;
    this._commonService.commonGetAction(urlAction).subscribe(
      (respData:any) => {
        respData.forEach(element => {
          element.agencies.forEach(pto => {
            
            pto.percentage = (pto.totalUpdatedVehicle/pto.totalVehicle*100).toFixed(2);
          });
          // let percentage = element.totalUpdatedVehicle/element.totalVehicle*100;
        });
        this.dataSet = respData;
        this.initTable();
        this.isRunning = false;
        this._spinner.hide();
      }, 
      error => {
        this._snackBar.open(error.statusText, null, {
          duration: 2000,
        });
        this.isRunning = false;
        this._spinner.hide();
      }
    )
  }

  initTable() {
    let month = moment(this.currentDate).month();

    var idxCounter = 0;
    for (let index = 0; index < 42; index++) {
      let today = moment(this.firstDay).add(index, 'day').format('YYYY-MM-DD');
      // let weekNumber = moment(today).week();
      let dayNumber = moment(today).day();

      if(index === 7 || index === 14 || index === 21 || index === 28 || index === 35 || index === 42) {
      // if((test1 / 6) % 0) {
        idxCounter++;
      }

      if(this.calendarData[idxCounter] === undefined) {
        this.calendarData[idxCounter] = [];
      }

      let isExist = this.dataSet.find(x => x.date === today);

      this.calendarData[idxCounter].push({
        isSameMonth: moment(today).month() === month ? true : false,
        date: moment(today).format('YYYY-MM-DD'),
        dayDate: moment(today).format('DD'),
        data: isExist === undefined ? [] : isExist.agencies,
        dayNumber: dayNumber,
        currentSunday: moment(today).day(0).format('YYYY-MM-DD')
      });
    }
  }

  onPrevMonth() {
    this.currentDate = moment(this.currentDate).subtract(1, 'months').format('YYYY-MM-DD');
    this.initCalendarData();
  }

  onNextMonth() {
    this.currentDate = moment(this.currentDate).add(1, 'months').format('YYYY-MM-DD');
    this.initCalendarData();
  }
}
