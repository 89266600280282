<app-demo-sidebar></app-demo-sidebar>
<div class="component">
  <div class="header-group" fxLayout="row">
    <span fxFlex="50" class="title-header">Bus Stop Management</span>
  </div>
  <div class="stop-group">
    <div class="stop-list" fxFlex="30" [formGroup]="form">
      <div>
        <!-- <button
          class="btn-add-stop btn-med btn-white"
          mat-raised-button
          [disabled]="true">
          Add Stop
        </button> -->
      </div>
      <div
        fxLayout="row wrap"
        fxLayoutAlign="flex-start center"
        class="bus-search">
        <mat-form-field
          fxFlex="1 0 40%"
          subscriptSizing="dynamic"
          hideRequiredMarker
          appearance="outline">
          <mat-label>Street Name / Bus Stop Code</mat-label>
          <input
            type="text"
            aria-label="Number"
            matInput
            formControlName="streetNameBusStop"
            name="streetNameBusStop"
            [matAutocomplete]="auto"
            class="classic-input1"
            (keyup.enter)="searchStop($event)"
            #searchStreetMatInput="matInput"
            #inputSearchStreet />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.streetName"
              (click)="selectedStreet(option)"
              [disabled]="option.isDisable">
              <ng-container *ngIf="!isBusCodeSearch">{{
                option.streetName.length > 0
                  ? option.streetName
                  : 'Unknown street name'
              }}</ng-container>
              <ng-container *ngIf="isBusCodeSearch">{{
                option.stopCode
              }}</ng-container>
            </mat-option>
          </mat-autocomplete>
          <button
            [disabled]="running || !form.valid"
            mat-icon-button
            matSuffix
            (click)="clearStreetSearch($event)"
            *ngIf="inputSearchStreet.value"
            aria-label="Clear">
            <mat-icon
              [color]="
                searchStreetMatInput.focused
                  ? 'primary'
                  : searchStreetMatInput.invalid
                    ? 'error'
                    : ''
              "
              >close</mat-icon
            >
          </button>
          <!-- <button
            mat-icon-button
            matSuffix
            [disabled]="running || !form.valid"
            matTooltip="Search"
            (click)="searchStop($event)"
            type="submit">
            <mat-icon
              [color]="
                searchStreetMatInput.focused
                  ? 'primary'
                  : searchStreetMatInput.invalid
                    ? 'error'
                    : ''
              "
              >search</mat-icon
            >
          </button> -->
        </mat-form-field>
      </div>

      <ng-container *ngIf="isSelected && !running">
        <div class="stop-title">
          <span>{{ streetNameBusStop }}</span
          ><i> (Total {{ allStops.length }} stops)</i>
        </div>
        <div class="stop-content">
          <div class="header" fxLayout="row">
            <div fxFlex="15"></div>
            <div fxFlex="25">Stop Code</div>
            <div fxFlex="50">Stop Name</div>
          </div>
          <mat-list>
            <perfect-scrollbar
              #chatPS
              class="ps-all-stops"
              [config]="config"
              [scrollIndicators]="true">
              <ng-container *ngFor="let stop of allStops; let i = index">
                <mat-list-item class="pointer" (click)="editStop(stop)">
                  <div fxFlex="40">
                    <span
                      class="material-icons float-left edit-icon pointer"
                      matTooltip="Edit"
                      >edit</span
                    >
                    <div class="sequence">{{ i + 1 }}</div>
                    <span class="color-dadada">{{ stop.stopCode }}</span>
                  </div>
                  <div fxFlex="50" class="color-dadada">
                    {{ stop.stopName }}
                  </div>
                </mat-list-item>
                <mat-divider></mat-divider>
              </ng-container>
            </perfect-scrollbar>
          </mat-list>
        </div>
      </ng-container>
    </div>

    <div fxFlex="70" class="map-container">
      <ngx-spinner
        bdColor="rgba(27, 30, 39, 1)"
        size="medium"
        color="#fff"
        type="ball-clip-rotate"
        [fullScreen]="false"
        [disableAnimation]="true"></ngx-spinner>
      <div class="map-panel mat-elevation-z1" *ngIf="isPanel">
        <div class="text-right">
          <span
            class="material-icons pointer"
            matTooltip="Close"
            (click)="closePanel()"
            >close</span
          >
        </div>
        <div class="panel-header">
          <h5>
            {{ panelData.stopDesc }}
            <!-- <sup
              class="material-icons pointer edit-icon-sup"
              matTooltip="Edit"
              (click)="editStop(panelData)"
              >edit</sup
            > -->
          </h5>
          <div class="mar-top-10">
            <div>Street Name: {{ streetNameBusStop }}</div>
          </div>

          <div class="mar-top-10">
            <div>Stop Name: {{ panelData.stopName }}</div>
          </div>

          <div class="mar-top-10">
            <div>
              Stop Code: <span class="link1">{{ panelData.stopCode }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="panel-buses mar-top-10">
          <div>Buses:</div>
          <ng-container *ngFor="let bus of panelData.busService">
            <a
              [routerLink]="['/account/system-configuration/service/create/1']"
              [queryParams]="{ busService: bus.routeId }"
              mat-raised-button
              class="minWidthAuto btn-bus"
              >{{ bus.routeId }}</a
            >
          </ng-container>
        </div> -->
      </div>
      <div id="map-stop"></div>
    </div>
  </div>
</div>
