import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatTable, MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { DriverElement, PmDriverService } from './pm-driver.service';
import { PmDriverEditComponent } from './pm-driver-edit/pm-driver-edit.component';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableExporterModule } from 'mat-table-exporter';

@Component({
  selector: 'app-pm-driver-mat',
  templateUrl: './pm-driver-mat.component.html',
  styleUrls: ['./pm-driver-mat.component.scss']
})
export class PmDriverMatComponent implements OnInit {

  dataSource: MatTableDataSource<DriverElement>;

  displayedColumns: string[] = ['driverId', 'name', 'busRegNo', 'contact', 'status', 'edit'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatTable, { static: true }) table: MatTable<DriverElement>;
  
  constructor(private dialog: MatDialog, private drvService: PmDriverService, private _snackBar: MatSnackBar) { }


  ngOnInit() {

    // this.dataSource = new MatTableDataSource<DriverElement>(drivers);

    this.dataSource = new MatTableDataSource<DriverElement>();

    this.dataSource.paginator = this.paginator;

    // this._snackBar.open('Loading...');

    let self = this;
    this.drvService.getAllDriver().subscribe((data: any) => {
      this.dataSource.data = data;
      this.table.renderRows();

      // this._snackBar.dismiss();
    }, error => {
      this._snackBar.open(error.message, null, {
        duration: 2000,
      });
    });
  }

  refresh() {

    this._snackBar.open('Refreshing...');

    this.drvService.getAllDriver().subscribe((data: any) => {
      this.dataSource.data = data;
      this.table.renderRows();

      this._snackBar.dismiss();
    }, error => {
      this._snackBar.open(error.message, null, {
        duration: 2000,
      });
    });
  }

  filter(keyword: string) {
    this.dataSource.filter = keyword.trim().toLowerCase();
  }

  add() {
    let drv = {
      driverId: null,
      fullName: null,
      firstName: null,
      middleName: null,
      lastName: null,
      identificationId: null,
      identificationDocType: null,
      primaryPhone: null,
      secondaryPhone: null,
      address: null,
      joiningDate: null,
      isTemporary: 'N',
      lastDutyDate: null,
      driverStatus: 'NEW'
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = drv;

    this.dialog.open(PmDriverEditComponent, dialogConfig);
  }

  edit(drv: DriverElement) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = drv;

    this.dialog.open(PmDriverEditComponent, dialogConfig);
  }

}
