import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WebSocketSubject } from 'rxjs/internal-compatibility';
import { environment } from 'src/environments/environment';
import { mqttSendData } from '../../shared/others/data-types';
import { CommonService } from '../../shared/services/common.service';
// import { HereService } from '../../shared/services/here.service';
import { ColorData } from '../../shared/others/constant';
import moment from 'moment';
// import * as playbackDataFake from '../../shared/fakeData/playback.json';
// import { MatSnackBar } from '@angular/material';
import { MapService } from '../../shared/services/map.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { darkTimepickerTheme } from '../../shared/others/timepicker-field-theme';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth.service';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';

// import { trafficFlowLayerConfig, trafficIncidentLayerConfig  } from '../../shared/others/traffic-config';

declare var H: any;

export type TimelineData = {
  [timestamp: number]: string[];
};

export type VehicleTimelineData = {
  [regno: string]: VehicleTimelineItem;
};

export type VehicleTimelineItem = {
  regno: string;
  data?: {
    [timestamp: number]: VehicleTimelineDetails;
  };
  start?: number;
  end?: number;
};

export type VehicleTimelineDetails = {
  regno: string;
  compass: number | string;
  latitude: number | string;
  longitude: number | string;
  status: string | string;
  time: number;
  satellitecount: number | string;
  trip: string;
  direction: number;
};

export type TimeIndicator = {
  width: number;
  position: string;
  timestamp: number;
};

const getUnixTimestamp = (day, timeString) =>
  moment(day + ' ' + timeString + ' +0800', 'YYYY-MM-DD HH:mm:ss Z').unix();
const getTimeString = unixTimestamp =>
  moment
    .unix(unixTimestamp)
    .utcOffset(environment.timezone)
    .format('MMMM D, YYYY HH:mm:ss');

function binarySearchTimelineKeys(arr, targetTimestamp) {
  var midpoint = Math.floor(arr.length / 2);

  if (+arr[midpoint] === targetTimestamp) {
    return +arr[midpoint];
  }
  if (arr.length === 1) {
    return +arr[0];
  }

  if (+arr[midpoint] > targetTimestamp) {
    return binarySearchTimelineKeys(arr.slice(0, midpoint), targetTimestamp);
  } else if (+arr[midpoint] < targetTimestamp) {
    return binarySearchTimelineKeys(arr.slice(midpoint), targetTimestamp);
  }
}

const MAX_MULTIPLIER = 60;

@Component({
  selector: 'app-playback',
  templateUrl: './playback.component.html',
  styleUrls: ['./playback.component.scss'],
})
export class PlaybackComponent implements OnInit {
  pageData = { id: 7, name: 'playback', displayPageName: 'Playback' };
  isHasAccess: boolean = false;
  rightsError: string = '';

  constructor(
    // private _hereService: HereService
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private _mapService: MapService,
    public authService: AuthService
  ) {
    this.platform = new H.service.Platform({
      apikey: environment.hereApiKey,
    });
    this.initSlider();
  }

  //map variables
  platform: any;
  @ViewChild('map', { static: true }) public mapElement: ElementRef;
  mapInstance: any;
  ui: any;
  listPolyLine: any = [];
  listStopMarkers = [];
  listBusActive = [];
  //end map variables
  respPlaybackData: any = {};
  showPlaybackData: boolean = false;
  collapsedSearch: boolean = true;
  isLoader: boolean = false;
  playbackDataEVent: any;
  vehicleList: any;
  showCurrentData: any = {};
  isSatVisible: boolean = false;

  timePickerTheme = darkTimepickerTheme;
  multiplierList: number[] = [0.5, 1, 2, 4, 5, 10];
  playbackSwitchLoading: boolean = false;
  serviceList: any = [];
  waypoints = [];
  colorPath = [
    {
      baseColorDirection1: 'rgba(20, 27, 224, 100%)',
      baseColorDirection2: 'rgba(20, 27, 224, 50%)',
      arrowColor: '#fff', //'rgba(92, 96, 214, 100%)'
    },
    {
      baseColorDirection1: 'rgba(255, 0, 255, 100%)',
      baseColorDirection2: 'rgba(255, 0, 255, 50%)',
      arrowColor: '#fff', //'rgba(227, 161, 227, 100%)'
    },
    {
      baseColorDirection1: 'rgba(255, 136, 0, 100%)',
      baseColorDirection2: 'rgba(255, 136, 0, 50%)',
      arrowColor: '#fff', //'rgba(224, 185, 139, 100%)'
    },
    {
      baseColorDirection1: 'rgba(123, 0, 255, 100%)',
      baseColorDirection2: 'rgba(123, 0, 255, 50%)',
      arrowColor: '#fff', //'rgba(182, 150, 217, 100%)'
    },
  ];

  colorIndex: number = 0;

  maxStartDate = moment().set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 59,
  });
  form = new FormGroup({
    serviceDayStart: new FormControl(moment().utcOffset(environment.timezone), [
      Validators.required,
    ]),
    serviceDayEnd: new FormControl(moment().utcOffset(environment.timezone), [
      Validators.required,
    ]),
    timeFrom: new FormControl('13:00', [Validators.required]),
    timeTo: new FormControl('13:59', [Validators.required]),
    service: new FormControl(null, [Validators.required]),
    vehicle: new FormControl(null),
  });
  isFfDisabled: boolean = false;
  eventMarker: any = '';
  @ViewChild('serviceAutocompleteTrigger')
  routeAutoCompleteTrigger: MatAutocompleteTrigger;

  ngOnInit() {
    // console.log('on init playback')

    setTimeout(() => {
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      this.rightsError = "You don't have access rights to this module.";
      this.initRouteSelection();
      // this.getResponsibility();
    }, 500);
  }

  initStops(serviceId: string, idx) {
    // var urlAction = 'https://ngbms-sg.com/api/getStops/' + serviceId;
    const urlAction =
      environment.apiServerUrl +
      'gtfsupload/v1/findRouteLadderByRouteId/' +
      serviceId;
    this._commonService.commonPostFullUrlAction(urlAction, null).subscribe(
      (respData: any) => {
        const { direction } = respData || {};
        const stopItem = {
          serviceId,
          direction: [],
        };
        direction?.forEach(dir => {
          const { stops, value } = dir || {};
          const groupStop = new H.map.Group({ zIndex: -1 });
          stops?.forEach(stop => {
            this.addStopMarker(stop, serviceId, groupStop, idx, value);
          });
          const dirStop = {
            value,
            stops: groupStop,
          };
          stopItem.direction.push(dirStop);
        });

        this.listStopMarkers.push(stopItem);

        // if (respData.response !== null) {
        //   let stopListDirection1 = respData.response[0].routeLadder;
        //   // let stopListDirection2 = respData.response[1].routeLadder;
        //   let groupStop = new H.map.Group({ zIndex: -1 });
        //   stopListDirection1.forEach(element => {
        //     this.addStopMarker(element, serviceId, groupStop, idx);
        //   });
        // }
      },
      error => {
        console.log(error);
      }
    );
  }

  isLetter(c) {
    if (!c) return false;
    return c.toLowerCase() != c.toUpperCase();
  }

  getResponsibility() {
    var urlAction =
      'users/v1/getUserResponsibility/' + localStorage.getItem('username');
    this._commonService.commonPostAction(urlAction, '').subscribe(
      (respData: any) => {
        if (respData) {
          var filteredServiceList = [];
          respData.forEach(element => {
            let routeId = element.routeId.trim();
            // if(routeId && routeId !== '-' && (routeId === '285' || routeId === '41')) {
            if (
              routeId &&
              routeId !== '-' &&
              !this.isLetter(routeId?.charAt(0))
            ) {
              filteredServiceList.push(element);
            }
          });

          let primaryRoute = filteredServiceList.filter(
            x => x.responsibility === 'PRIMARY'
          );
          // let secondaryRoute = respData.filter(x => x.responsibility === 'SECONDARY');

          // this is to populate the bus-route playback switch
          this.serviceList = filteredServiceList;

          // respData.forEach(element => {
          //   let isVisible: boolean = false;

          //   if (!(element.routeId === '285' || element.routeId === '41')) {
          //     return;
          //   }

          //   if (element.responsibility === 'PRIMARY') {
          //     isVisible = true;
          //   }
          //   this.waypoints.push({
          //     route: element.routeId,
          //     color: ['rgba(20, 27, 224, 100%)', 'rgba(20, 27, 224, 60%)'],
          //     isVisible: isVisible,
          //     points: [],
          //   });
          // });
          // console.log('service', this.serviceList);

          // this is for primary route
          // primaryRoute.forEach((service,idx) => {
          //     this._mapService.getBusRoute(service.routeId).then((resp:any) => {
          //         if(this.colorIndex === 4) {
          //             this.colorIndex = 0;
          //         }
          //         let color = this.colorPath[this.colorIndex];

          //         service.points = resp.direction;
          //         service.color = color;
          //         service.isVisible = false;

          //         // this.waypoints.forEach((element,idx) => {
          //         // this.addPolylineToMap(this.mapInstance, service, idx);
          //         // });
          //         // this.initStops(service.routeId, idx);
          //         this.colorIndex++;

          //         let serviceData = this.serviceList.find(x => x.routeId === service.routeId);
          //         serviceData.isChecked = false;
          //     })
          // });

          // if (primaryRoute?.length === 1) {
          //     this.form.patchValue({
          //         service: primaryRoute[0].routeId
          //     })
          // }
        }
      },
      error => {
        console.log(error);
        this._snackBar.open(error.error.errorMessage, null, {
          duration: 2000,
        });
        this.isLoader = false;
      }
    );
  }

  public ngAfterViewInit() {
    // let defaultLayers = this.platform.createDefaultLayers();
    // this.mapInstance = new H.Map(
    //     this.mapElement.nativeElement,
    //     // defaultLayers.vector.normal.map,
    //     {
    //         zoom: 12,
    //         center: { lat: 1.3521, lng: 103.8198 },
    //         pixelRatio: window.devicePixelRatio || 1,
    //         padding: {top: 50, left: 70, bottom: 50, right: 70}
    //     }
    // );

    // this.ui.removeControl('mapsettings');
    // var ms = new H.ui.MapSettingsControl({
    //     layers: [
    //     {
    //         label: 'Incidents', layer: defaultLayers.vector.normal.trafficincidents
    //     },
    //     {
    //         label: 'Traffic Layer', layer: defaultLayers.vector.normal.traffic
    //     }]
    // });
    // this.ui.addControl('customized', ms);

    //disable buildings in style
    // setTimeout(() => {
    //     let cfg = this.mapInstance.getBaseLayer().getProvider().getStyle().extractConfig('buildings.extrude');
    //     cfg.layers.buildings.extrude['visible'] = false;
    //     this.mapInstance.getBaseLayer().getProvider().getStyle().mergeConfig(cfg);
    // }, 3000);

    // Entfernt den Roads Layer aus der Payload des Vector Tile Services

    // let service = this.platform.getOMVService();
    // service.getUrl().setQuery({
    // datasets: '(-buildings)',
    //     apikey: environment.hereApiKey
    // });

    // let tileLayer = new H.map.layer.TileLayer(
    //     // new H.service.omv.Provider(service, new H.map.Style('https://js.api.here.com/v3/3.1/styles/omv/oslo/japan/normal.day.yaml')), {max: 22}
    //     new H.service.omv.Provider(service, new H.map.Style('https://js.api.here.com/v3/3.1/styles/omv/miami/normal.day.yaml')), {max: 22}
    // );

    // var pixelRatio = window.devicePixelRatio || 1;
    // var defaultLayers2 = this.platform.createDefaultLayers({
    //     tileSize: pixelRatio === 1 ? 256 : 512,
    //     ppi: pixelRatio === 1 ? undefined : 320
    // });

    // //Step 2: initialize a map
    // this.mapInstance = new H.Map(
    //     this.mapElement.nativeElement,//document.getElementById('map'),
    //     defaultLayers2.vector.normal.map, {
    //         center: { lat: 1.3521, lng: 103.8198 },
    //         zoom: 12,
    //         pixelRatio: pixelRatio,
    //         padding: {top: 50, left: 70, bottom: 50, right: 70}
    //     }
    // );

    // this.mapInstance.setBaseLayer(tileLayer);

    // window.addEventListener('resize', () => this.mapInstance.getViewPort().resize());
    // const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.mapInstance));
    // this.ui = H.ui.UI.createDefault(this.mapInstance, defaultLayers2);

    let defaultLayers = this.platform.createDefaultLayers();
    this.mapInstance = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.vector.normal.map,
      {
        zoom: environment.map.defaultZoom,
        center: {
          lat: environment.map.locationLonLat[1],
          lng: environment.map.locationLonLat[0],
        },
        pixelRatio: window.devicePixelRatio || 1,
        padding: { top: 100, left: 100, bottom: 100, right: 100 },
      }
    );
    window.addEventListener('resize', () =>
      this.mapInstance.getViewPort().resize()
    );

    const behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(this.mapInstance)
    );
    const provider = this.mapInstance.getBaseLayer().getProvider();

    //Initialize router and geocoder
    const router = this.platform.getRoutingService();
    const geocoder = this.platform.getGeocodingService();

    //enable traffic layer by default
    // this.mapInstance.addLayer(defaultLayers.vector.normal.traffic);

    this.ui = H.ui.UI.createDefault(this.mapInstance, defaultLayers);
    this.ui.removeControl('mapsettings');

    // var ms = new H.ui.MapSettingsControl({
    //     layers: [
    //             // {
    //             //     label: 'Incidents', layer: defaultLayers.vector.normal.trafficincidents
    //             // },
    //             // {
    //             //     label: 'Traffic Layer', layer: defaultLayers.vector.normal.traffic
    //             // },
    //             // {
    //             //     label: 'Traffic Layer Default', layer: defaultLayers.vector.traffic.map
    //             // },
    //             {
    //                 label: 'Incidents', layer: trafficIncidentLayer
    //             },
    //             {
    //                 label: 'Traffic', layer: trafficFlowLayer
    //             }
    //     ]
    // });
    // this.ui.addControl('customized', ms);
  }

  geofencePolygon;
  addGeofenceShapeToMap() {
    const clementiLineString = new H.geo.LineString(
      [
        1.317145, 103.7640884, 100, 1.3151238, 103.7619263, 100, 1.3132201,
        103.7633753, 100, 1.3155723, 103.7647641, 100,
      ],
      'values lat lng alt'
    );

    this.geofencePolygon = new H.map.Polygon(clementiLineString, {
      style: {
        fillColor: 'rgba(255,255,255,0.5)',
        strokeColor: 'rgba(115, 147, 179, 0.8)',
        lineWidth: 3,
      },
    });

    this.mapInstance.addObject(this.geofencePolygon);
  }

  removeGeofenceShape() {
    if (this.geofencePolygon) {
      this.mapInstance.removeObject(this.geofencePolygon);
      this.geofencePolygon = undefined;
    }
  }

  addPolylineToMap(map, waypoint, indexNumber) {
    var lineString;
    var firstLineString;
    var defaultIcon = new H.map.Icon('assets/maps/icons/marker-green.png');
    // console.log('add polyline', waypoint);
    waypoint.points.forEach((direction, idx) => {
      let startMarkerData: any;
      lineString = new H.geo.LineString();
      const { value: directionValue } = direction ?? {};
      direction.path.forEach((element, idxPath) => {
        let latLon = element.split(',');
        lineString.pushPoint({ lat: latLon[0], lng: latLon[1] });
        if (idxPath === 0) {
          startMarkerData = latLon;
        }
      });

      let startMarker = new H.map.Marker(
        { lat: startMarkerData[0], lng: startMarkerData[1] },
        { icon: defaultIcon }
      );
      startMarker.setData({
        serviceRoute: waypoint.routeId,
        type: 'route-marker',
        value: directionValue,
        responsibility: waypoint.responsibility,
      });
      startMarker.setVisibility(waypoint.isVisible);

      // let routeArrows = new H.map.Polyline(lineString, {
      //     style: {
      //     lineWidth: 5,
      //     strokeColor: waypoint.color.arrowColor,//'rgba(255, 255, 50, 50%)',
      //     lineDash: [2, 10],
      //     lineTailCap: 'arrow-tail',
      //     lineHeadCap: 'arrow-head' }
      //     }
      // );

      let routeArrows = new H.map.Polyline(lineString, {
        style: {
          lineWidth: 5,
          strokeColor: waypoint.color.arrowColor, //'rgba(242, 66, 245, 50%)',
          lineDash: [0, 5],
          lineTailCap: 'arrow-tail',
          lineHeadCap: 'arrow-head',
        },
        data: {
          serviceRoute: waypoint.routeId,
          type: 'route-arrow',
          value: directionValue,
          responsibility: waypoint.responsibility,
        },
      });

      // routeArrows.addEventListener("pointerenter", (event) => {
      //     console.log('enter');
      //     map.addObject(routeArrowsHover);
      // });

      // routeArrows.addEventListener("pointerleave", (event) => {
      //     console.log('leave');
      // });

      let pathColor = waypoint.color.baseColorDirection1;
      if (direction.value === 2) {
        pathColor = waypoint.color.baseColorDirection2;
      }

      map.addObjects([
        new H.map.Polyline(lineString, {
          visibility: waypoint.isVisible,
          style: {
            lineWidth: 10, //base width
            strokeColor: pathColor, //base color
          },
          data: {
            serviceRoute: waypoint.routeId,
            type: 'route-line',
            value: directionValue,
            responsibility: waypoint.responsibility,
          },
        }),
        routeArrows,
        startMarker,
      ]);

      const mapObjects = map.getObjects();

      const mapObjectsList = mapObjects.filter(mapObject => {
        const objectData = mapObject?.getData();
        const {
          serviceRoute,
          value,
          responsibility: objectResponsibility,
        } = objectData;
        return (
          serviceRoute === waypoint.routeId &&
          value === directionValue &&
          objectResponsibility === waypoint.responsibility
        );
      });
      // console.log('mapobject list', waypoint.routeId, mapObjects, mapObjectsList)

      this.listPolyLine.push({
        route: waypoint.routeId,
        mapObject: mapObjectsList,
      });

      if (idx === 0 && indexNumber === 0) {
        firstLineString = lineString;
      }
    });

    var serviceData = this.serviceList.find(
      x =>
        x.routeId === waypoint.routeId &&
        x.responsibility === waypoint.responsibility
    );
    // console.log(serviceData);
    serviceData.lineString = lineString.getBoundingBox();
    // if(indexNumber === 0) {
    //     map.getViewModel().setLookAtData({bounds: lineString.getBoundingBox()}, true);
    // }
  }

  onChangeService(event) {
    // console.log('bus switch event', event);
    if (this.playbackSwitchLoading) return;
    this.playbackSwitchLoading = true;
    this.mapInstance.getObjects().forEach(element => {
      const elementData = element.getData();
      const { serviceRoute, responsibility } = elementData ?? {};
      // console.log('element data', elementData);
      if (
        elementData !== undefined &&
        serviceRoute !== undefined &&
        serviceRoute === event.routeId &&
        responsibility === event.responsibility
      ) {
        element.setVisibility(event.checked);
        if (!event.checked) {
          this.listPolyLine = this.listPolyLine.filter(polylineObject => {
            if (
              polylineObject.route === event.routeId &&
              polylineObject.responsibility === event.responsibility
            ) {
              // console.log('test1',event.checked, element.getData(), this.listPolyLine)
              const polylineMapObject = polylineObject.mapObject;
              this.mapInstance.removeObjects(polylineMapObject);
              return false;
            }
            return true;
          });

          // clear stop markers
          this.listStopMarkers = this.listStopMarkers.filter(stopMarker => {
            if (stopMarker.serviceId === event.routeId) {
              const { direction } = stopMarker || {};
              direction?.forEach(dir => {
                const { stops } = dir;
                this.mapInstance.removeObject(stops);
              });
              return false;
            }
            return true;
          });

          return false;
        }
        // if(event.route === '41') {
        //     this.mapInstance.setCenter({lat:1.335663, lng:103.757240}, true);
        // }
        // else {
        //     this.mapInstance.setCenter({lat:1.309663, lng:103.759993}, true);
        // }

        // this.mapInstance.getViewModel().setLookAtData({bounds: event.lineString.getBoundingBox()}, true);
        // console.log(element.getBoundingBox());
        // this.mapInstance.getViewModel().setLookAtData({
        //     bounds: element.getBoundingBox()
        // });
        // console.log('auto');
      }
    });
    if (event.checked) {
      // var isPolylineExist = this.listPolyLine.find(x => x.route === event.routeId);

      // if(event.checked && isPolylineExist) {
      //     this.mapInstance.getViewModel().setLookAtData({bounds: event.lineString.getBoundingBox()}, true);
      // }
      // else if(!event.checked && isPolylineExist) {

      // }
      // else if(!isPolylineExist) {
      this.getVehicleRoute(event);
    } else {
      this.playbackSwitchLoading = false;
    }
  }

  getVehicleRoute(data) {
    // console.log('get vehicle route', data, this.serviceList);
    this._mapService.getBusRoute(data.routeId).then((resp: any) => {
      if (this.colorIndex === 4) {
        this.colorIndex = 0;
      }
      let color = this.colorPath[this.colorIndex];

      data.points = resp.direction;
      data.color = color;
      data.isVisible = true;

      // this.waypoints.forEach((element,idx) => {
      this.addPolylineToMap(this.mapInstance, data, this.colorIndex);
      // });
      this.initStops(data.routeId, this.colorIndex);
      this.colorIndex++;

      let serviceData = this.serviceList.find(
        x =>
          x.routeId === data.routeId && x.responsibility === data.responsibility
      );

      serviceData.isChecked = true;
      // console.log('servicedata', serviceData);
      this.mapInstance
        .getViewModel()
        .setLookAtData(
          { bounds: serviceData.lineString.getBoundingBox() },
          true
        );
      this.playbackSwitchLoading = false;
    });
  }

  addStopMarker(data, serviceId: string, groupStop: any, idx, value) {
    if (data.latitude === 'nan' || data.longitude === 'nan') {
      return false;
    }

    let color = this.colorPath[idx];
    var me = this;

    let bgColor: string =
      value === '2' ? color.baseColorDirection2 : color.baseColorDirection1; //'#607d8b';

    var outerElement = document.createElement('div'),
      innerElement = document.createElement('div');

    outerElement.style.userSelect = 'none';
    outerElement.style.cursor = 'default';
    outerElement.style.display = 'table';
    outerElement.style.marginLeft = '-12px';

    innerElement.style.color = '#fff';
    innerElement.style.backgroundColor = bgColor;
    innerElement.style.border = '2px solid #fff';
    innerElement.style.borderRadius = '50%';
    innerElement.style.font = 'normal 12px arial';

    innerElement.style.padding = '0 5px';
    innerElement.style.width = '24px';
    innerElement.style.height = '24px';
    innerElement.style.textAlign = 'center';
    innerElement.style.display = 'flex';
    innerElement.style.alignItems = 'center';
    innerElement.style.justifyContent = 'center';

    // Add text to the DOM element
    innerElement.innerHTML = data.stopSequence;

    outerElement.appendChild(innerElement);

    var domIcon = new H.map.DomIcon(outerElement, {
      // the function is called every time marker enters the viewport
      onAttach: function (clonedElement, domIcon, domMarker) {
        clonedElement.addEventListener('mouseover', me.mapChangeCursorPointer);
        clonedElement.addEventListener('mouseout', me.mapChangeCursorAuto);
      },
      // the function is called every time marker leaves the viewport
      onDetach: function (clonedElement, domIcon, domMarker) {
        clonedElement.removeEventListener(
          'mouseover',
          me.mapChangeCursorPointer
        );
        clonedElement.removeEventListener('mouseout', me.mapChangeCursorAuto);
      },
    });

    var coords = {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
    };

    this.mapInstance.addObject(groupStop);

    var defaultIcon = new H.map.DomMarker(coords, { icon: domIcon });

    var htmlData = `<div>` + data.stopName + `</div>`;
    defaultIcon.setData({
      data: data,
      dataHtml: htmlData,
    });
    groupStop.addObject(defaultIcon);
    groupStop.setData({ serviceRoute: serviceId });

    groupStop.addEventListener('tap', function (evt) {
      let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        // read custom data
        content: evt.target.getData().dataHtml,
      });

      me.ui.getBubbles().forEach(bub => me.ui.removeBubble(bub));

      me.ui.addBubble(bubble);
      bubble.addClass('H_ib_noclose');
      // clearInterval(me.stopArrivalInterval);
      // me.initStopArrival(evt.target.getData().data);
    });
  }

  mapChangeCursorPointer(evt) {
    evt.target.style.cursor = 'pointer';
  }

  mapChangeCursorAuto(evt) {
    evt.target.style.cursor = 'auto';
  }

  //----------------------------- PLAYBACK
  isPaused = true;
  timerObj;
  interval = 1000;
  path189Direction1;
  currSlider = 0;
  showTime: string;
  sliderMin = 0;
  sliderMax = 100;
  sliderMinTime = '';
  sliderMaxTime = '';

  fastForwardValue: number = 1;
  searchData: any = {};
  // fastPreviousValue: number = 1;
  timelineData: TimelineData = {};
  vehicleTimelineData: VehicleTimelineData = {};
  fromTimestamp: number = 0;
  fromTimeString: string = '';
  toTimeString: string = '';
  toTimestamp: number = 100;
  currentTime: number = 0;
  currentTimeString: string = '';
  timeIndicators = [];
  currentTimeChanged$: Subject<number> = new Subject<number>();

  initSlider() {
    this.currentTimeChanged$
      .pipe(debounceTime(10), distinctUntilChanged())
      .subscribe(value => {
        // console.log('debounce: ', value);
        this.currentTime = value;
        this.currentTimeString = getTimeString(this.currentTime);
        this.onNavigateTimestamp(value);
        // this.updatePlayback(value);
      });
  }
  cleanupSlider() {
    this.currentTimeChanged$?.unsubscribe();
  }
  onSliderStart() {
    if (!this.isPaused) {
      this.onPlay();
    }
  }
  onSliderChange(event: Event) {
    const value = +(event.target as HTMLInputElement).value;
    // console.log('slider: ', value);
    this.currentTimeChanged$.next(value);
  }

  updatePlayback(timeIndex: number) {
    const timeValue = this.respPlaybackData[timeIndex].playbackData;
    this.showCurrentData = this.respPlaybackData[timeIndex];
    if (timeValue) {
      timeValue.forEach(element => {
        this.addMaker(element);
      });
    }
  }

  removeVehicle(regno) {
    const toRemove = this.listBusActive.find(element => {
      const { regno: activeRegNo } = element.busData;
      if (regno === activeRegNo) return true;
      return false;
    });
    if (toRemove) {
      toRemove.marker.setVisibility(false);
      this.mapInstance.removeObject(toRemove.marker);
      this.listBusActive = this.listBusActive.filter(
        element => element.busData.regno !== regno
      );
    }
  }

  // initPlayback() {
  //   // setTimeout(() => {
  //   //     this.currSlider = '20.56';
  //   // }, 2000);
  //   // console.log(this.waypoints);
  //   var me = this;
  //   this.waypoints.forEach(element => {
  //     if (element.route === '285' && element.points[0].value === 1) {
  //       let path = element.points[0].path;
  //       // console.log(path);
  //       this.path189Direction1 = path;
  //       // let count = 0;
  //       // element.points[0].path.forEach((latlon,idx) => {
  //       // setInterval(function() {
  //       // console.log(latlon, idx);
  //       // console.log(count+=1);
  //       // me.testConsole(idx);
  //       // }, 3000)
  //       // });

  //       //------------------------------------------
  //       // var promise:any = Promise.resolve();
  //       // path.forEach(function (el,idx) {
  //       //   promise = promise.then(function () {
  //       //     console.log(el,idx);
  //       //     return new Promise(function (resolve) {
  //       //       setTimeout(resolve, me.interval);
  //       //     });
  //       //   });
  //       // });

  //       // // setTimeout(() => {
  //       //     promise.catch(() => console.error('Promise rejected!'));
  //       // // }, 3000);

  //       // promise.then(function () {
  //       //     console.log('Loop finished.');
  //       // });
  //       // promise.cancel();
  //       //------------------------------------------
  //       // me.initTimerInterval(path);
  //     }
  //   });
  // }

  removeActiveVehicles() {
    // this.mapInstance.removeObjects(this.mapInstance.getObjects())
    // this.initStops('285');
    // this.initStops('41');
    // console.log(this.listBusActive);
    this.listBusActive.forEach(element => {
      element.marker.setVisibility(false);
      this.mapInstance.removeObject(element.marker);
    });
  }

  clearRouteLines() {
    this.listPolyLine.forEach(polylineObject => {
      const { groupObject } = polylineObject;

      if (groupObject) {
        groupObject?.setVisibility(false);
        this.mapInstance.removeObject(groupObject);
      }
    });
    this.listPolyLine = [];
    this.listStopMarkers.forEach(stopMarker => {
      const { direction } = stopMarker || {};
      direction?.forEach(dir => {
        const { stops } = dir;
        stops?.setVisibility(false);
        this.mapInstance.removeObject(stops);
      });
    });
    this.listStopMarkers = [];
  }

  loadServiceRoute(routeId) {
    this.clearRouteLines();

    this._mapService.getBusRoute(routeId).then((respData: any) => {
      const { direction } = respData || {};
      this.colorIndex = 0;
      let color = this.colorPath[this.colorIndex];

      const data = {
        routeId,
        points: direction,
        color,
      };

      const lineStringBoundingBox = this.addServicePolyline(data);
      this.setStopMarkers(routeId, this.colorIndex);

      if (lineStringBoundingBox) {
        this.mapInstance
          .getViewModel()
          .setLookAtData(
            { bounds: lineStringBoundingBox.getBoundingBox() },
            true
          );
      }
    });
  }

  addServicePolyline(directionData) {
    const defaultIcon = new H.map.Icon('assets/maps/icons/marker-green.png');
    if (directionData) {
      const { points, routeId, color } = directionData;
      let polylineGroupObject;
      points.forEach((direction, index) => {
        const lineString = new H.geo.LineString();
        const { value: directionValue, path } = direction ?? {};
        let startMarkerData = path[0]?.split(',') ?? {};
        path.forEach((element, pathIndex) => {
          const latLon = element.split(',');
          lineString.pushPoint({ lat: latLon[0], lng: latLon[1] });
        });

        const startMarker = new H.map.Marker(
          { lat: startMarkerData[0], lng: startMarkerData[1] },
          { icon: defaultIcon }
        );

        startMarker.setData({
          serviceRoute: routeId,
          type: 'route-marker',
          value: directionValue,
        });
        startMarker.setVisibility(true);

        const routeArrows = new H.map.Polyline(lineString, {
          style: {
            lineWidth: 5,
            strokeColor: color.arrowColor, //'rgba(242, 66, 245, 50%)',
            lineDash: [0, 5],
            lineTailCap: 'arrow-tail',
            lineHeadCap: 'arrow-head',
          },
          data: {
            serviceRoute: routeId,
            type: 'route-arrow',
            value: directionValue,
          },
        });

        let pathColor = color.baseColorDirection1;
        if (directionValue === 2) {
          pathColor = color.baseColorDirection2;
        }

        const polylineGroup = new H.map.Group();

        polylineGroup.addObjects([
          new H.map.Polyline(lineString, {
            visibility: true,
            style: {
              lineWidth: 10, //base width
              strokeColor: pathColor, //base color
            },
            data: {
              serviceRoute: routeId,
              type: 'route-line',
              value: directionValue,
            },
          }),
          routeArrows,
          startMarker,
        ]);

        this.mapInstance.addObject(polylineGroup);

        this.listPolyLine.push({
          route: routeId,
          groupObject: polylineGroup,
        });

        polylineGroupObject = polylineGroup;
      });

      // return linestring bounding box
      return polylineGroupObject.getBoundingBox();
    }
    return undefined;
  }

  setStopMarkers(routeId, index) {
    this._mapService.getRouteLadder(routeId).then((data: any) => {
      // console.log('stop ladder', data);
      const { direction } = data || {};

      const stopItem = {
        serviceId: routeId,
        direction: [],
      };

      direction?.forEach(dir => {
        const { stops, value } = dir || {};
        const groupStop = new H.map.Group({ zIndex: -1 });
        stops?.forEach(stop => {
          this.addStopMarker(stop, routeId, groupStop, index, value);
        });
        const dirStop = {
          value,
          stops: groupStop,
        };
        stopItem.direction.push(dirStop);
      });

      this.listStopMarkers.push(stopItem);
    });
  }

  onSearchPlayback() {
    if (!this.form.valid) {
      return false;
    }
    this.respPlaybackData = {};
    this.showPlaybackData = false;
    this.clearRouteLines();
    this.removeActiveVehicles();
    this.listBusActive = [];
    this.interval = 1000;
    this.fastForwardValue = 10;
    this.showCurrentData = {};
    this.removeGeofenceShape();

    this.isLoader = true;
    this.isPaused = true;
    this.fastForwardValue = 10;
    clearInterval(this.timerObj);
    clearInterval(this.timeProgressObject);
    this.path189Direction1 = '';
    this.showTime = '';
    this.searchData = {};
    this.isFfDisabled = false;

    this.vehicleList = [];
    this.timeProgressObject = undefined;
    this.timeProgressMultiplier = 1;
    this.timeProgressIntervalMax = MAX_MULTIPLIER;
    this.timelineData = {};
    this.vehicleTimelineData = {};
    this.fromTimestamp = 0;
    this.fromTimeString = '';
    this.toTimeString = '';
    this.toTimestamp = 100;
    this.currentTime = 0;
    this.currentTimeString = '';
    this.timeIndicators = [];
    this.collapsedSearch = true;

    // initialize progress bar
    this.timeIndicators = [];
    this.fromTimeString = this.form.value.timeFrom + ':00';
    this.fromTimestamp = getUnixTimestamp(
      this.form.value.serviceDayStart.format('YYYY-MM-DD'),
      this.form.value.timeFrom + ':00'
    );
    this.toTimestamp = getUnixTimestamp(
      this.form.value.serviceDayEnd.format('YYYY-MM-DD'),
      this.form.value.timeTo + ':59'
    );
    this.toTimeString = getTimeString(this.toTimestamp);
    this.currentTime = this.fromTimestamp;
    this.currentTimeString = getTimeString(this.currentTime);

    const fromISOdate = moment
      .unix(this.fromTimestamp)
      .utcOffset(environment.timezone)
      .format();
    const toISOdate = moment
      .unix(this.toTimestamp)
      .utcOffset(environment.timezone)
      .format();

    // var urlAction = 'position/v1/ngbms/positionplayback';
    // var urlAction = 'playback/v1/ngbms/vehicleplayback';
    var urlAction = 'playback/v2/ngbms/vehicleplayback';
    this.searchData = {
      routeId: this.form.value.service,
      vehRegNo: this.form.value.vehicle,
      // "serviceDate": moment(this.form.value.serviceDay).format('YYYY-MM-DD'),
      // "startTime": this.form.value.timeFrom,
      // "endTime": this.form.value.timeTo,
      startDateTime: fromISOdate,
      endDateTime: toISOdate,
      includeBusBunching: true,
      includeLogonInfo: true,
      includeTextComms: true,
      includeCallLogs: true,
      includeOffRouteMsg: true,
      includeTripStartEnd: true,
    };
    const routeId = this.form.value.service;

    this._commonService.commonPostAction(urlAction, this.searchData).subscribe(
      (respData: any) => {
        //   console.log(respData);
        // let respData = (playbackDataFake as any).default;
        if (
          respData === null ||
          respData === undefined ||
          respData.length === 0 ||
          respData.find(
            playback => playback.eventType === 'vehicleposition'
          ) === undefined
        ) {
          this._snackBar.open('No playback data found.', null, {
            duration: 2000,
          });
          this.isLoader = false;
        }
        this.isLoader = false;
        this.respPlaybackData = respData.find(
          playback => playback.eventType === 'vehicleposition'
        )?.eventData;
        this.timelineData = {};
        this.vehicleTimelineData = {};

        if (this.respPlaybackData) {
          this.showPlaybackData = true;

          // process timelinedata
          let markerIndex = -1;
          let lastMarkerTimestamp = -1;

          const finalTime = this.toTimestamp - this.fromTimestamp;
          let prevData = {};

          let currentDay = moment(this.form.value.serviceDayStart);
          let prevTime = undefined;

          this.respPlaybackData.forEach(data => {
            let skip = false;
            const { playbackData, rcvdTime, timestamp } = data;
            // let unixTimestamp = getUnixTimestamp(currentDay.format('YYYY-MM-DD'), rcvdTime);
            let unixTimestamp = timestamp / 1000;
            const timelineVehicleList = [];

            prevTime = unixTimestamp;

            playbackData.forEach(playbackEventData => {
              // const playbackItem: VehicleTimelineData = {};
              // let playbackTimelineItem: VehicleTimelineItem;
              // let timeLineType: TimelineData;
              // let vehicletimelinedetails: VehicleTimelineDetails;

              const {
                regno,
                compass: comp,
                latitude: lat,
                longitude: lon,
                satellitecount: satno,
                status: stat,
                direction,
                trip,
              } = playbackEventData;

              // skip adding data if same as previous data
              // we check if previousdata exists for this regno
              // we compare current data to previous data
              // if its the same, we don't need to save this data
              if (prevData[regno]) {
                const {
                  comp: prevComp,
                  lat: prevLat,
                  lon: prevLon,
                  stat: prevStat,
                  satno: prevSatno,
                  direction: prevDirection,
                  trip: prevTrip,
                } = prevData[regno];

                if (
                  comp === prevComp &&
                  lat === prevLat &&
                  lon === prevLon &&
                  stat === prevStat &&
                  satno === prevSatno &&
                  direction === prevDirection &&
                  trip === prevTrip
                ) {
                  skip = true;
                  // console.log('currData, prevData', playbackEventData, prevData[regno])
                }
              }
              if (!skip) {
                if (!this.vehicleTimelineData[regno]) {
                  this.vehicleTimelineData[regno] = {
                    regno,
                    data: {},
                    start: unixTimestamp,
                  };
                }
                this.vehicleTimelineData[regno].data[unixTimestamp] = {
                  compass: comp,
                  latitude: lat,
                  longitude: lon,
                  status: stat,
                  satellitecount: satno,
                  time: unixTimestamp,
                  regno: regno,
                  direction,
                  trip,
                };
                timelineVehicleList.push(regno);
              }
              prevData[regno] = playbackEventData;
            });

            if (timelineVehicleList?.length > 0) {
              this.timelineData[unixTimestamp] = timelineVehicleList;
            }

            // create timeline indicators. we are guaranteed that the timestamps are non-decreasing.
            // if its not non-decreasing, we need to add a function to sort.
            if (!skip) {
              const timeElapsed = unixTimestamp - this.fromTimestamp;
              if (lastMarkerTimestamp >= 0) {
                const timeDifference = timeElapsed - lastMarkerTimestamp;
                // console.log('time', lastMarkerTimestamp, timeDifference, finalTime)

                // this condition determines the event duration. this is needed for optimization.
                // this will extend the time indicator if the diff between last timestamp and current timestamp
                // is lower than a specific time (finalTime * percent)
                if (timeDifference < finalTime * 0.005) {
                  lastMarkerTimestamp = timeElapsed;
                  if (this.timeIndicators[markerIndex].width === 1) {
                    this.timeIndicators[markerIndex].width =
                      (1 / finalTime) * 100;
                  }
                  this.timeIndicators[markerIndex].width +=
                    (timeDifference / finalTime) * 100;
                } else {
                  lastMarkerTimestamp = -1;
                }
              }

              if (lastMarkerTimestamp < 0) {
                const percentTime = (timeElapsed / finalTime) * 100 + '%';
                const percentObject: TimeIndicator = {
                  position: percentTime,
                  timestamp: unixTimestamp,
                  width: 1,
                };
                // comment next line to skip the timeIndicator optimization
                lastMarkerTimestamp = timeElapsed;
                markerIndex += 1;
                this.timeIndicators.push(percentObject);
              }
            } else {
              // lastMarkerTimestamp = -1;
            }
          });
          if (this.form.value.service === '285') {
            this.addGeofenceShapeToMap();
          }

          // add service route lines

          this.loadServiceRoute(routeId);
        } else {
          this.showPlaybackData = false;
          this.respPlaybackData = {};
          this.timelineData = {};
          this.vehicleTimelineData = {};
        }

        // let clonedPlayback = JSON.parse(JSON.stringify(respData));

        let sidePanelVehicleData = [];
        respData.forEach(element => {
          element.eventData.forEach(data => {
            data.playbackData.forEach(event => {
              let busRegNo = event.regno;
              if (busRegNo && !sidePanelVehicleData.includes(busRegNo)) {
                sidePanelVehicleData.push(busRegNo);
              }
            });
          });
        });

        this.vehicleList = sidePanelVehicleData.sort();
        this.playbackDataEVent = respData.filter(
          playback => playback.eventType !== 'vehicleposition'
        );
      },
      error => {
        const errorMessage =
          error?.error?.errorMessage ||
          'Something went wrong. Please try again.';
        this._snackBar.open(errorMessage, null, {
          duration: 2000,
        });
        this.isLoader = false;
        this.showPlaybackData = false;
        this.timelineData = {};
        this.vehicleTimelineData = {};
        this.respPlaybackData = {};
        this.removeGeofenceShape();
        this.clearRouteLines();
        console.log(error);
      }
    );
    // }, 3000);
  }

  timeProgressObject;
  timeProgressMultiplier: number = 1;
  timeProgressIntervalMax: number = MAX_MULTIPLIER;

  runPlaybackProgress() {
    if (this.currentTime >= this.toTimestamp) {
      this.currentTime = this.fromTimestamp;
      // console.log('reset');
    }
    if (this.currentTime <= this.fromTimestamp) {
      this.currentTime = this.fromTimestamp;
      // console.log('reset start');
    }

    // process playback data
    if (this.timelineData[this.currentTime]) {
      this.timelineData[this.currentTime].forEach(regno => {
        const vehicleData =
          this.vehicleTimelineData[regno].data[this.currentTime];
        // console.log(regno, ' vehicleData: ', vehicleData);
        if (vehicleData) {
          this.addMaker(vehicleData);
        }
      });
    }

    // if multiplier greater than 1
    if (Math.abs(this.timeProgressMultiplier) > 1) {
      this.onNavigateTimestamp(this.currentTime);
    }

    this.currentTime += 1 * this.timeProgressMultiplier;
    this.currentTimeString = getTimeString(this.currentTime);
  }

  initTimeProgress() {
    const me = this;
    const timeInterval =
      this.fastForwardValue > MAX_MULTIPLIER
        ? MAX_MULTIPLIER
        : this.fastForwardValue;

    this.timeProgressObject = setInterval(() => {
      me.runPlaybackProgress();
    }, 1000 / timeInterval);
  }

  onPlay() {
    clearInterval(this.timeProgressObject);
    this.isPaused = true;
  }

  onPause() {
    this.initTimeProgress();
    this.isPaused = false;
  }

  multiplierChange(event) {
    // console.log(event);
    const { value } = event;
    this.fastForwardValue = value;

    if (this.fastForwardValue > MAX_MULTIPLIER) {
      this.timeProgressMultiplier = this.fastForwardValue / MAX_MULTIPLIER;
    } else {
      this.timeProgressMultiplier = 1;
    }

    this.interval = 1000 / this.fastForwardValue;
    // console.log('multiplier', this.fastForwardValue, (1000 / this.fastForwardValue), this.timeProgressMultiplier);
    if (this.isPaused) {
      return;
    }
    clearInterval(this.timerObj);
    // this.initTimerInterval();
    clearInterval(this.timeProgressObject);
    this.initTimeProgress();
  }

  formatLabel(value: number | null) {
    if (!value) {
      return '00:00';
    }
    if (value === 0) {
      return '00:00';
    }
    if (value === 24) {
      return '23:59';
    }
    // var formattedTime = this.formatTime(value);
    // console.log(formattedTime);

    let decimalPart = +value.toString().replace(/^[^\.]+/, '0');
    let mm = decimalPart * 60;
    mm = Math.round(mm);
    var mmPart =
      mm.toString().length == 1 ? '0' + mm.toString() : mm.toString();
    if (mmPart == '60') {
      mmPart = '00';
    }

    if (value >= 0) {
      let valueStr = value.toFixed(2);
      let strArr = valueStr.split('.');
      if (strArr[0].length == 1) {
        strArr[0] = '0' + strArr[0];
      }
      var hhPart = strArr[0];
      //console.log(strArr);
    }

    return hhPart + ':' + mmPart;
  }

  formatTime(value) {
    let decimalPart = +value.toString().replace(/^[^\.]+/, '0');
    let mm = decimalPart * 60;
    mm = Math.round(mm);
    var mmPart =
      mm.toString().length == 1 ? '0' + mm.toString() : mm.toString();
    if (mmPart == '60') {
      mmPart = '00';
    }

    if (value >= 0) {
      let valueStr = value.toFixed(2);
      let strArr = valueStr.split('.');
      if (strArr[0].length == 1) {
        strArr[0] = '0' + strArr[0];
      }
      var hhPart = strArr[0];
      //console.log(strArr);
    }

    return hhPart + ':' + mmPart;
  }

  getMarkerStyles = status => {
    switch (status) {
      case '0':
        return {
          bgColor: ColorData.eGreen,
          color: '#fff',
          vehicleTiming: 'on-time',
        };
      case '-1':
        return {
          bgColor: ColorData.eYellow,
          color: '#000',
          vehicleTiming: 'early',
        };
      default:
        return {
          bgColor: ColorData.eRed,
          color: '#fff',
          vehicleTiming: 'warning',
        };
    }
  };

  createDomIcon(data) {
    const me = this;
    const { satellitecount } = data;
    const { bgColor, vehicleTiming, color } = this.getMarkerStyles(data.status);
    let outerElement = document.createElement('div'),
      innerElement = document.createElement('div'),
      innerSatElement = document.createElement('div'),
      arrowElement = document.createElement('div');

    const displayText = '' + satellitecount;

    outerElement.id = 'markerOuterElem';
    outerElement.className = 'markerOuterElem';

    innerElement.id = 'markerInnerElem';
    innerElement.style.backgroundColor = bgColor;
    innerElement.style.color = color;
    innerElement.className = 'vehicle-label mat-elevation-z1';

    arrowElement.id = 'markerArrowElem';
    arrowElement.className = 'marker-icon ' + vehicleTiming;
    arrowElement.style.transform = 'rotate(' + data.compass + 'deg)';

    innerSatElement.id = 'markerSatElem';
    innerSatElement.className = 'sat-label mat-elevation-z2';
    innerSatElement.style.visibility =
      displayText?.length > 0
        ? this.isSatVisible
          ? 'visible'
          : 'hidden'
        : 'hidden';

    // Add text to the DOM element
    innerElement.innerHTML = data.regno;
    innerSatElement.innerHTML = displayText;
    outerElement.appendChild(arrowElement);
    outerElement.appendChild(innerElement);
    outerElement.appendChild(innerSatElement);

    const domIcon = new H.map.DomIcon(outerElement, {
      // the function is called every time marker enters the viewport
      onAttach: function (clonedElement, domIcon, domMarker) {
        clonedElement.addEventListener('mouseover', me.mapChangeCursorPointer);
        clonedElement.addEventListener('mouseout', me.mapChangeCursorAuto);
      },
      // the function is called every time marker leaves the viewport
      onDetach: function (clonedElement, domIcon, domMarker) {
        clonedElement.removeEventListener(
          'mouseover',
          me.mapChangeCursorPointer
        );
        clonedElement.removeEventListener('mouseout', me.mapChangeCursorAuto);
      },
    });
    return domIcon;
  }

  addMaker(data) {
    if (data.latitude === 'nan' || data.longitude === 'nan') {
      return false;
    }
    let isBusExisting = this.listBusActive.find(
      o => o.busData.regno.toLowerCase() === data.regno.toLowerCase()
    );
    // console.log ('bus exist', isBusExisting);
    if (isBusExisting) {
      // this.listBusActive = this.listBusActive.filter(item => item !== isBusExisting);
      if (isBusExisting.marker) {
        // this.mapInstance.removeObject(isBusExisting.marker);
        const existingData = isBusExisting.marker.getData();
        const { latitude, longitude, status, compass, satellitecount, regno } =
          data;
        isBusExisting.marker.setGeometry({
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        });
        const {
          status: existingStatus,
          compass: existingCompass,
          satellitecount: currSatelliteCount,
          regno: currRegNo,
        } = existingData;
        // console.log('data: ', existingData)
        if (
          status !== existingStatus ||
          compass !== existingCompass ||
          satellitecount !== currSatelliteCount ||
          regno !== currRegNo
        ) {
          const domIcon = this.createDomIcon(data);
          isBusExisting.marker.setIcon(domIcon);
        }
        isBusExisting.marker.setData(data);
        isBusExisting.busData = data;
      }
    } else {
      const domIcon = this.createDomIcon(data);
      const coords = {
        lat: parseFloat(data.latitude),
        lng: parseFloat(data.longitude),
      };
      const domMarker = new H.map.DomMarker(coords, { icon: domIcon });
      domMarker.setData(data);
      const markerObj = this.mapInstance.addObject(domMarker);
      this.listBusActive.push({ busData: data, marker: markerObj });
    }
  }

  onFastForward() {
    if (this.fastForwardValue === MAX_MULTIPLIER) {
      this.isFfDisabled = true;
      return;
    }
    if (this.fastForwardValue >= this.timeProgressIntervalMax) {
      this.timeProgressMultiplier *= 2;
      this.fastForwardValue = this.timeProgressIntervalMax;
    } else {
      this.fastForwardValue *= 2;
      this.timeProgressMultiplier = 1;
    }
    this.interval = 1000 / this.fastForwardValue;
    // console.log('multiplier', this.fastForwardValue, this.timeProgressMultiplier);
    this.isFfDisabled = this.fastForwardValue === MAX_MULTIPLIER ? true : false;
    if (this.isPaused) {
      return;
    }
    clearInterval(this.timerObj);
    // this.initTimerInterval();
    clearInterval(this.timeProgressObject);
    this.initTimeProgress();
  }

  onFastPrevious() {
    if (this.fastForwardValue <= 1) {
      this.isFfDisabled = true;
      return;
    }
    if (
      this.fastForwardValue >= this.timeProgressIntervalMax &&
      this.timeProgressMultiplier > 1
    ) {
      this.timeProgressMultiplier /= 2;
    } else if (this.fastForwardValue > 1) {
      this.fastForwardValue /= 2;
      this.timeProgressMultiplier = 1;
    } else {
      this.fastForwardValue = 1;
    }
    this.interval = 1000 / this.fastForwardValue;
    // console.log('multiplier', this.fastForwardValue, this.timeProgressMultiplier);
    this.isFfDisabled = false;
    if (this.isPaused) {
      return;
    }

    clearInterval(this.timerObj);
    // this.initTimerInterval();
    clearInterval(this.timeProgressObject);
    this.initTimeProgress();
    // console.log('fast prev', this.fastForwardValue, this.interval);
  }

  onLocateEvent(eventData) {
    // console.log(eventData);

    // this.mapInstance.setZoom(20);
    this.mapInstance.setCenter(
      { lat: eventData.latitude, lng: eventData.longitude },
      true
    );
    this.addEventMarker(eventData);
  }

  eventMarkerGroup;
  addEventMarker(evetData) {
    // this.eventMarker = '';
    if (this.eventMarker) {
      this.eventMarkerGroup.removeObject(this.eventMarker);
    }
    var LocationOfMarker = { lat: evetData.latitude, lng: evetData.longitude };

    // // Create a marker icon from an image URL:
    var icon = new H.map.Icon('assets/images/icon/map-marker-orange.png');

    // // Create a marker using the previously instantiated icon:
    // this.eventMarker = new H.map.Marker(LocationOfMarker, { icon: icon });
    // // Add the marker to the map:
    // this.mapInstance.addObject(this.eventMarker);

    this.eventMarkerGroup = new H.map.Group({
      zIndex: 999999999999999999999999,
    });
    this.mapInstance.addObject(this.eventMarkerGroup);

    // marker = new H.map.Marker(map.getCenter());
    this.eventMarker = new H.map.Marker(LocationOfMarker, { icon: icon });

    this.eventMarkerGroup.addObject(this.eventMarker);
  }

  onSatToggle(checked) {
    this.isSatVisible = checked;
    this.listBusActive.forEach(busItem => {
      const existingData = busItem.busData;
      const newDomIcon = this.createDomIcon(existingData);
      busItem.marker.setIcon(newDomIcon);
    });
  }

  /**
   * update all markers based on the nearest markers' timestamps below the given timestamp
   * @param timestamp current timestamp
   */
  onNavigateTimestamp(timestamp: number) {
    // const playbackItem: VehicleTimelineData = {};
    // let playbackTimelineItem: VehicleTimelineItem;
    // let timeLineType: TimelineData;
    // let vehicletimelinedetails: VehicleTimelineDetails;
    this.currentTime = timestamp;
    this.currentTimeString = getTimeString(this.currentTime);
    // get all vehicle data - naive implementation. NOT optimal
    const filteredVehicleData = Object.entries(this.vehicleTimelineData).map(
      ([regno, vehicleTimeLineItem]: [string, VehicleTimelineItem]) => {
        let marker: VehicleTimelineDetails = undefined;
        const { data, start, end } = vehicleTimeLineItem;

        if (timestamp >= start) {
          const filteredKeys = Object.keys(data);
          const closestTimestamp =
            binarySearchTimelineKeys(filteredKeys, timestamp) ?? undefined;
          if (data[closestTimestamp]) {
            marker = data[closestTimestamp];
          }
        }

        return {
          regno,
          marker,
        };
      }
    );

    filteredVehicleData.forEach(data => {
      const { regno, marker } = data;
      if (marker) {
        this.addMaker(marker);
      } else {
        this.removeVehicle(regno);
      }
    });

    // console.log('vehicleData', timestamp, filteredVehicleData);
  }

  onUndoKeyDown() {
    this.onPlay();
    this.timeProgressMultiplier *= -1;
    this.runPlaybackProgress();
    this.initTimeProgress();
  }

  onUndoKeyUp() {
    this.onPlay();
    this.timeProgressMultiplier *= -1;
  }

  onRedoKeyDown() {
    this.onPlay();
    // this.timeProgressMultiplier = 1;
    this.runPlaybackProgress();
    this.initTimeProgress();
  }

  onRedoKeyUp() {
    this.onPlay();
    // this.timeProgressMultiplier = 1;
  }

  toggleSearch() {
    this.collapsedSearch = !this.collapsedSearch;
    const me = this;
    setTimeout(() => {
      me.mapInstance.getViewPort().resize();
    }, 200);
  }

  // Service Number Autocomplete
  routeList = [];
  initRouteSelection() {
    this._mapService
      .getInitSwitch()
      .then((data: any) => {
        const { primary, secondary } = data || {};
        const routeList = [];
        if (primary) {
          primary.forEach(route => {
            const { service } = route || {};
            if (service) {
              if (routeList.indexOf(service) < 0) {
                routeList.push(service);
              }
            }
          });
        }
        if (secondary) {
          secondary.forEach(route => {
            const { service } = route || {};
            if (service) {
              if (routeList.indexOf(service) < 0) {
                routeList.push(service);
              }
            }
          });
        }
        this.routeList = [...routeList];
        this.initRouteAutocomplete();
        // console.log('responsibilities', data, routeList);
      })
      .catch(e => {
        this.routeList = [];
        console.log('Roster: Route Responsibility error: ', e);
        this._snackBar.open(
          'Server error. Failed to get routes list. Please try refresh the page and try again.',
          null,
          {
            duration: 2000,
          }
        );
      });
  }

  /**
   * setup service autocomplete field
   */
  filteredServiceOptions: Observable<string[]>;
  initRouteAutocomplete() {
    this.filteredServiceOptions = this.form.controls.service.valueChanges.pipe(
      startWith(''),
      map(value => this.filterServiceOptions(value || ''))
    );
  }

  private filterServiceOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.routeList.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  selectRoute(event: MatAutocompleteSelectedEvent): void {
    event.option.deselect();
  }

  /** bugged on angular material 15. need to manually deselect option */
  clearServiceSearch(event) {
    event.stopPropagation();
    this.form.controls.service.patchValue('');
    this.routeAutoCompleteTrigger?.activeOption?.deselect();
  }

  ngOnDestroy() {
    this.cleanupSlider();
  }
}
