import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MatTableDataSource, MatPaginator, MatTable, MatDialogConfig, MatDialog, MatSort } from '@angular/material';
// import moment from 'moment';
// import { map } from 'rxjs/operators';
import { HomeService } from 'src/app/component/shared/services/home.service';
import { VehicleDeviceEditComponent } from './vehicle-device-edit/vehicle-device-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleDeviceData } from 'src/app/component/shared/others/data-types';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-vehicle-device',
  templateUrl: './vehicle-device.component.html',
  styleUrls: ['./vehicle-device.component.scss']
})
export class VehicleDeviceComponent implements OnInit {

  isNewWindow: boolean = false;

  constructor(
    private homeService: HomeService, 
    private httpClient: HttpClient, 
    private _snackBar: MatSnackBar, 
    private dialog: MatDialog,
    private route: ActivatedRoute, 
    private router: Router,
    public authService: AuthService) { }

  // rptForm = new FormGroup({
  //   route: new FormControl(null, [Validators.required]),
  //   startDate: new FormControl(null, [Validators.required]),
  //   endDate: new FormControl(null, [Validators.required])
  // });

  allRoutes: string[] = [];
  filteredRoutes: string[] = [];

  displayedColumns: string[] = ['busRegNo', 'deviceId', 'deviceVoipId', 'actions'];

  dataSource = new MatTableDataSource<VehicleDeviceData>();

  running: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  
  ngOnInit() {
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
    this.run();
    // this.homeService.responsibilities['primary'].forEach(e => {
    //     this.allRoutes.push(e.service);
    // });

    // this.filteredRoutes = this.filterRoutes('');

    // this.rptForm.get('route').valueChanges.subscribe(newValue=>{
    //   this.filteredRoutes = this.filterRoutes(newValue);
    // })
  }

  // filterRoutes(search: string) {
  //   return this.allRoutes.filter(value => value.toLowerCase().indexOf(search.toLowerCase()) === 0);
  // }

  run() {
    // this.exportStarted('Generating report...');

    this.homeService.getAllVehicleDevice()
      .subscribe(
        (data: any) => {
          // let t = [];
          // data[1].forEach(e => {
          //   t.push({
          //     busRegNo: e.busRegNo,
          //     deviceId: e.deviceId,
          //     voipId: e.voipId,
          //     ctryCode: e.ctryCode
          //   });
          // });
 
          this.dataSource.data = data.value;
          this.table.renderRows();
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          
          // this.exportCompleted();
        },
        (error: any) => {
          this._snackBar.open(error.message, null, {
            duration: 2000,
          });
          this.running = false;
        }
      );
  }

  add() {
    let deviceData:VehicleDeviceData = {
      busRegNo: null,
      deviceId: null,
      voipId: null,
      ctryCode: null
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = deviceData;

    this.dialog.open(VehicleDeviceEditComponent, dialogConfig);
  }

  edit(drv: VehicleDeviceData) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = drv;

    this.dialog.open(VehicleDeviceEditComponent, dialogConfig);
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/vehicle-management/device/2','vehicle-management','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }

  // exportStarted(message: string) {
  //   this.running = true;

  //   this._snackBar.open(message);
  // }

  // exportCompleted() {
  //   this._snackBar.dismiss();

  //   this.running = false;
  // }
}
