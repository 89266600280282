import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { CommonService } from 'src/app/component/shared/services/common.service';

@Component({
  selector: 'app-upload-time-table',
  templateUrl: './upload-time-table.component.html',
  styleUrls: ['./upload-time-table.component.scss']
})
export class UploadTimeTableComponent implements OnInit {

  constructor(
    private commonService: CommonService
  ) { }

  files: any = null;
  running: boolean = false;
  isUploaded: boolean = false;
  uploadedMessage: String = '';
  fileFormat: string = 'dat';
  uploadForm = new FormGroup({
    fromDate: new FormControl(moment()),
    toDate: new FormControl(moment().add('1', 'day'))
  });
  isRangeError:boolean = false;

  ngOnInit() {
  }
  
  uploadFile(event) {
    this.isUploaded = false
    this.files = event.item(0);
  }

  deleteAttachment() {
    this.files = null;
  }

  btnUploadFile() {
    this.running = true;
    this.uploadedMessage = '';
    
    if (this.files === null || this.isRangeError) {
      this.running = false;
      return;
    }
    var body:any = new FormData();
    
    body.append('file', this.files);

    var urlAction = 'vdv/v1/uploadTTSVDVZipFile';
    if(this.fileFormat === 'dat') {
      urlAction = 'dispatch/v1/uploadDispatchFile';
    }
    else if(this.fileFormat === 'zip') {
      urlAction = 'gtfsupload/v1/uploadScheduleZipFile';
      body.append("isGTFS", 'Y');
      body.append("effectiveFromDate", moment(this.uploadForm.value.fromDate).format('YYYY-MM-DD'));
      body.append("effectiveTillDate", moment(this.uploadForm.value.toDate).format('YYYY-MM-DD'));
    }
    // console.log(this.files.name);
    
    var fileName = this.files.name;
    this.commonService.commonPostAction(urlAction, body).subscribe(
      (data: any) => {
        console.log(data);
        this.deleteAttachment();
        this.running = false;
        this.isUploaded = true;
        this.uploadedMessage = '<p>Successfully uploaded </p><b>' + fileName + '</b>';
      },
      (errorRespp: any) => {
        console.log(errorRespp);
        this.running = false;
        this.isUploaded = true;
        var errMessage: string;
        if(errorRespp.error.errorMessage === undefined) {
          errMessage = errorRespp.error.error;
        }
        else {
          errMessage = errorRespp.error.errorMessage + ', ' + errorRespp.error.errorType
        }
        this.uploadedMessage = errMessage;
      }
    )
  }

  onDateChange(data) {
    console.log(moment(data.value).format('YYYY MM DD'));
    
    var serviceDay = this.uploadForm.value.fromDate;
    this.isRangeError = true;
    
    if(serviceDay.format('YYYY MM DD') === moment(data.value).format('YYYY MM DD') || 
    serviceDay.diff(data.value) < 0) {
      console.log('future');
      this.isRangeError = false;
    }
  }
}
