<!-- <div *ngIf="!isNewWindow" >
    <app-account-header></app-account-header>
</div> -->
<div class="traffic-events-group">
  <div class="container">
    <div class="col">
      <div class="row">
          
        <!-- <button class="button" (click)="openWindow()">open</button> -->
        <h3 class="title-header">Live Traffic Congestion<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
      </div>
    </div>
    <div class="row congestion-group">
      <!-- <div class="col">
        <p-table #dt [columns]="cols" [value]="congestionFlatData" [paginator]="true" [rows]="10" dataKey="routeId" editMode="row" class="events-table" 
          [scrollable]="true" scrollHeight="77vh"
          showCurrentPageReport="true"
          currentPageReportTemplate="{currentPage} of {totalPages}"
          >
          <ng-template pTemplate="caption"> -->
              <!-- <div class="inner-addon right-addon"> -->
              <!-- <i class="fa fa-search" style="margin:4px 4px 0 0"></i> -->
              <!-- <input type="text" pInputText size="50" class="global-filter form-control" placeholder="Search" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto"> -->
            
                <!-- <i class="glyphicon glyphicon-search"></i> -->
                <!-- <i class="fa fa-cog"></i>
                <input type="text" class="form-control" placeholder="Search" />
            </div> -->
            
            <!-- <div class="input-group group-filter">
              <input pInputText type="text" class="form-control global-filter" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" (input)="dt.filterGlobal($event.target.value, 'contains')">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"><i class="fa fa-cog"></i></span> -->
                <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
              <!-- </div>
            </div>
          </ng-template>
          <ng-template pTemplate="paginatorright" let-state> Total elements : {{ dt .totalRecords }} </ng-template>

          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" class=td-{{col.field}}>
                  {{col.header}}
              </th>
            </tr>
          </ng-template> -->
          
          <!-- <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex"> -->
          <!-- <ng-template pTemplate="body" let-rowData let-columns="columns"> -->
            <!-- <tr [pEditableRow]="rowData" [pSelectableRow]="rowData"> -->
            <!-- <tr [ngClass]="'type-' + rowData.jamLevelCode" (click)="initTraffic(rowData)">
              <td *ngFor="let col of columns">
                {{rowData[col.field]}} 
              </td> -->
              <!-- <td>click to show map</td> -->
            <!-- </tr>
          </ng-template>
        </p-table>
    </div> -->
    <div class="col">
      <div id="traffic-map" class="traffic-map" (click)="mapClick($event)"></div>
      <div *ngFor="let location of markerArray; index as i">
        <div style="cursor: pointer;" id="popup-{{i}}"></div>
      </div>
    </div>
    
<!-- <div class="congestion-provider row">
  <div class="col">
    <div>Map Toggle</div>
    <label class="checkbox-slider">
      <input class="sw-cb" (click)="mapToggle($event)" type="checkbox" [(ngModel)]="isOpenLayer">
      <span class="slider round"></span>
    </label>
  </div>
</div> -->

  </div>
</div>