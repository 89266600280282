import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { EditResponsibilityComponent } from '../edit-responsibility/edit-responsibility.component';
import { FirebaseService } from 'src/app/component/shared/services/firebase.service';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
// import { AddRoleComponent } from './add-role/add-role.component';

const searchKeysList = ['userName', 'displayName'];

@Component({
  selector: 'app-responsibility',
  templateUrl: './responsibility.component.html',
  styleUrls: ['./responsibility.component.scss'],
})
export class ResponsibilityComponent implements OnInit, OnDestroy {
  isNewWindow: boolean = false;
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  isLoading: boolean = true;
  dataLoop = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  dataPageClone;
  dataPage: any = {
    responsibilitiesList: [
      {
        respId: '1',
      },
      {
        respId: '2',
      },
      {
        respId: '3',
      },
      {
        respId: '4',
      },
      {
        respId: '5',
      },
      {
        respId: '6',
      },
      {
        respId: '7',
      },
      {
        respId: '8',
      },
      {
        respId: '9',
      },
      {
        respId: '10',
      },
    ],

    respList: [],
    //   {
    //     'respId': '11',
    //     'accountName': 'Casper M.',
    //     'resp': [ 41,47,21
    //       // {
    //       //   "priority": "PRIMARY",
    //       //   "routes": [
    //       //       {
    //       //           "routeId": "41",
    //       //           "routeName": "41",
    //       //           "priorityOrder": "0"
    //       //       },
    //       //       {
    //       //           "routeId": "42",
    //       //           "routeName": "42",
    //       //           "priorityOrder": "0"
    //       //       },
    //       //       {
    //       //           "routeId": "43",
    //       //           "routeName": "43",
    //       //           "priorityOrder": "0"
    //       //       }
    //       //   ]
    //       // }
    //       // {
    //       //   'respId': '4'
    //       // },
    //       // {
    //       //   'respId': '5'
    //       // }
    //     ]
    //   },
    //   {
    //     'respId': '12',
    //     'accountName': 'John V.',
    //     'resp': [55,58
    //       // {
    //       //   'respId': '1'
    //       // },
    //       // {
    //       //   'respId': '2'
    //       // }
    //     ]
    //   }
    // ]
  };

  displayedColumns: string[] = [
    'Username',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    'Actions',
  ];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  tableDataClone: any;

  // filter form
  searchFilter: string = '';
  searchFilterValue: string = '';
  searchFilterSubject = new Subject<string | undefined>();
  searchFilterSubscription: Subscription;

  pageData = {
    id: 10,
    name: 'responsibility',
    displayPageName: 'User Responsibility',
  };
  isHasAccess: boolean = false;
  rightsError: string = '';

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private _firebaseService: FirebaseService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    // this.getResponsibility();
    setTimeout(() => {
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      this.rightsError = "You don't have access rights to this module.";
    }, 500);

    this.getResponsibility2();
    this.startSearchNameSubscription();

    // this.dataPage.respList.forEach((role:any) => {
    //   role.isPrimaryEdit = false;
    //   role.isSecondaryEdit = false;
    //   let currServiceCount = role.resp.length;

    //   for (let i = 0; i < 10-currServiceCount; i++) {
    //     // text += "The number is " + i + "<br>";
    //     role.resp.push('');
    //   }

    // //   let difference = role.resp.filter(x => !this.dataPage.responsibilitiesList.includes(x));
    //   // this.dataPage.responsibilitiesList.forEach((element:any) => {
    // //     let isExist = difference.find(x => x.respId === element.respId);
    //     // element.isPrimaryEdit = false;
    // //     if(!isExist) {
    // //       role.resp.push({
    // //         'respId': element.respId,
    // //         'isEnabled': false
    // //       });
    // //     }
    // // });
    // //   role.resp.sort((a, b) => (a.respId > b.respId) ? 1 : -1)
    // });

    // console.log(this.dataPage);

    if (this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
  }

  getResponsibility() {
    var ptoData: any = [];
    var urlAction = 'users/v1/getUserResponsibilityByAllPTO';
    this._commonService.commonPostAction(urlAction, '').subscribe(
      userResp => {
        // console.log(userResp);
        userResp.forEach(element => {
          let resp = element.userResponsibility;
          // ptoData.type = userResp.type;
          resp.forEach(user => {
            // ptoData.data.push(service);
            let isExist = ptoData.find(
              x => x.userId === user.userId && x.type === user.type
            );
            // if(user.userResponsibilityData.length <= 10) {
            if (user.userData.length <= 10) {
              this.dataLoop.forEach(i => {
                // if(!user.userResponsibilityData[i]) {
                if (!user.userData[i]) {
                  // user.userResponsibilityData[i] = {
                  user.userData[i] = {
                    priorityOrder: '-',
                    routeId: '-',
                    routeName: '-',
                  };
                }
              });
            }

            if (isExist) {
              // console.log('isExist', element.type, isExist);
              // console.log(user);
              ptoData.responsibility =
                ptoData.responsibility === undefined
                  ? []
                  : ptoData.responsibility;
              ptoData.responsibility.push({
                type: element.type,
                // 'data': user.userResponsibilityData.slice(0, 10)//get first 10 elem
                data: user.userData.slice(0, 10), //get first 10 elem
              });
              // user.userResponsibilityData
              // console.log(isExist.responsibility);
            } else {
              // console.log('not');
              // console.log(user);
              // let isAccExist = ptoData.find(x => (x.userId === user.userId));

              // // let data = {
              // // }

              // if(isAccExist) {
              //   isAccExist.responsibility.push([{
              //     'type': element.type,
              //     'data': user.userResponsibilityData
              //   }]);
              // }
              // else {

              let data1 = [];
              data1.push({
                type: element.type,
                // 'data': user.userResponsibilityData.slice(0, 10)//get first 10 elem
                data: user.userData.slice(0, 10), //get first 10 elem
              });
              ptoData.push({
                userId: user.userId,
                userName: user.userName,
                responsibility: data1,

                isPrimaryEdit: false,
                isSecondaryEdit: false,
              });
              // }
            }
          });
        });
        // this._snackBar.open(action + ' successful', null, {
        //   duration: 5000,
        // });

        ptoData.forEach(user => {
          let isPrimary = user.responsibility.find(x => x.type === 'PRIMARY');
          let isSecondary = user.responsibility.find(
            x => x.type === 'SECONDARY'
          );

          let respData = [];
          this.dataLoop.forEach(i => {
            respData.push({
              priorityOrder: '-',
              routeId: '-',
              routeName: '-',
            });
          });

          if (!isPrimary) {
            user.responsibility.push({
              type: 'PRIMARY',
              data: respData,
            });
          } else if (!isSecondary) {
            user.responsibility.push({
              type: 'SECONDARY',
              data: respData,
            });
          }
        });

        this.dataPage.respList = ptoData;
        this.dataPageClone = JSON.parse(JSON.stringify(this.dataPage));
        this.isLoading = false;
        // console.log(this.dataPage);
      },
      err => {
        // this.dataSource = JSON.parse(JSON.stringify(this.allAccountClone));
        this._snackBar.open(err.error.errorType, null, {
          duration: 5000,
        });
      }
    );
  }

  getResponsibility2() {
    this.isLoading = true;
    var tableData = [];
    var urlAction = 'users/v1/getUserResponsibilityByAllPTO';
    this._commonService.commonPostAction(urlAction, '').subscribe(
      resp => {
        // let resp = JSON.parse(JSON.stringify(resp2));
        // console.log('User Responsibility: list - ', resp);
        const decodedToken: any = jwt_decode(
          localStorage.getItem('access_token')
        );
        const { user_name } = decodedToken || {};

        if (!resp) return;

        resp.forEach(type => {
          type.userResponsibility.forEach(responsibility => {
            responsibility.userData.forEach((userData, idx) => {
              let typeData = {
                primary: [],
                secondary: [],
              };

              if (type.type.toLowerCase() === 'primary') {
                typeData.primary = userData.responsibilityData.slice(0, 10);
              } else if (type.type.toLowerCase() === 'secondary') {
                typeData.secondary = userData.responsibilityData.slice(0, 10);
              }
              tableData.push({
                userId: userData.userId,
                userName: userData.userName,
                agency: responsibility.agencyName,
                responsibility: typeData,
                displayName: userData?.displayName,
                isSelf: userData?.userName === user_name,
              });
            });
          });
        });

        var data2 = [];
        var duplicate = [];
        tableData.forEach((element, idx) => {
          let isExist = data2.findIndex(x => x.userId === element.userId);
          if (isExist) {
            duplicate.push(element);
            // tableData.splice(idx, idx+1);
            // let dataIdex = tableData.findIndex(x => x.userId === element.userId);
            // tableData.splice(dataIdex, 1)
            // console.log(dataIdex)
          } else {
            data2.push(element);
          }
        });
        var cloneData = JSON.parse(JSON.stringify(tableData));

        duplicate.forEach(element => {
          let isExist = cloneData.find(x => x.userId === element.userId);
          // let isExist = cloneData.filter(function (el)
          // {
          //   return el.userName === element.userName ;
          // });
          // console.log(isExist);

          let isPrimary = isExist.responsibility.primary;

          if (isPrimary.length === 0) {
            isExist.responsibility.primary = element.responsibility.primary;
          } else {
            isExist.responsibility.secondary = element.responsibility.secondary;
          }
        });

        cloneData.forEach(respon => {
          respon.responsibility.primary.unshift({
            userId: 0,
            userName: 'userName',
            responsibility: [],
          });
          respon.responsibility.secondary.unshift({
            userId: 0,
            userName: 'userName',
            responsibility: [],
          });
        });

        let filteredData = cloneData.filter(
          (v, i, a) => a.findLastIndex(v2 => v2.userName === v.userName) === i
        );
        let filteredFullData = cloneData.filter((v, i, a) => {
          if (
            v.responsibility.primary.length > 1 &&
            v.responsibility.secondary.length > 1
          ) {
            return v;
          }
        });
        let uniqueData = filteredData.map(
          obj => filteredFullData.find(o => o.userName === obj.userName) || obj
        );

        // sort data
        const sortedData = [...uniqueData].sort((a, b) => {
          if (a.isSelf) return -1;
          if (b.isSelf) return 1;
          return 0;
          // return (a?.userName?.localeCompare(b?.userName)) || 0;
        });

        this.tableDataClone = JSON.parse(JSON.stringify(sortedData));
        this.dataSource.data = sortedData;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = this.responsibilityFilter;
        this.isLoading = false;
      },
      err => {
        // this.dataSource = JSON.parse(JSON.stringify(this.allAccountClone));
        this._snackBar.open(
          err?.error?.errorType ||
            'An error occurred. Please refresh the page.',
          null,
          {
            duration: 5000,
          }
        );
      }
    );
  }

  responsibilityFilter(record: any, filter: string) {
    const checkSubString = function (parentString: string, subString: string) {
      return (
        parentString?.toString().trim().toLowerCase().includes(subString) ??
        false
      );
    };
    if (filter.length > 0) {
      const matchSearch = Object.entries(record).some(
        ([key, value]: [string, any]): boolean => {
          if (searchKeysList.indexOf(key) < 0) return false;
          if (key === 'userName' || key === 'displayName') {
            return checkSubString(value, filter);
          }
        }
      );
      return matchSearch;
    }
    return true;
  }

  // removeDuplicates(originalArray, prop) {
  //   var newArray = [];
  //   var lookupObject  = {};

  //   for(var i in originalArray) {
  //      lookupObject[originalArray[i][prop]] = originalArray[i];
  //   }

  //   for(i in lookupObject) {
  //       newArray.push(lookupObject[i]);
  //   }
  //    return newArray;
  // }

  openWindow() {
    this._router.navigate(['/account/home']);
    let newwin = window.open(
      'account/system-configuration/account-management/responsibility/2',
      'role management',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  // onFilter(data) {
  //   var filterData = data.trim();
  //   if(filterData.length === 0) {
  //     this.dataPage.respList = this.dataPageClone.respList;
  //   }
  //   else {
  //     let role = this.dataPage.respList.filter(x => x.accountName.toLowerCase().includes(filterData.toLowerCase()));
  //     this.dataPage.respList = role;
  //   }
  // }

  // onAddRole() {
  //   var dialogData = {
  //     data: {
  //       pageList: this.dataPageClone.pageList
  //     }
  //   }

  // const dialogRef = this.dialog.open(AddRoleComponent, {
  //   data: dialogData,
  //   disableClose: true,
  //   // height: '400px',
  //   width: '600px',
  // });

  // dialogRef.afterClosed().subscribe(
  //   result => {
  //     if(result) {
  //       let difference = result.pages.filter(x => !this.dataPage.pageList.includes(x));

  //       this.dataPage.pageList.forEach(element => {
  //         let isExist = difference.find(x => x.pageId === element.pageId);
  //         if(!isExist) {
  //           result.pages.push({
  //             'pageId': element.pageId,
  //             'pageName': ''
  //           });
  //         }
  //       });
  //       result.pages.sort((a, b) => (a.pageId > b.pageId) ? 1 : -1)

  //       this.dataPage.roleList.unshift(result);
  //     }
  //   }
  // )
  // }

  onEdit(data, event = null) {
    // console.log(this.dataPage);

    if (event === 'cancel') {
      let respAccount = this.dataPageClone.respList.find(
        x => x.respId === data.respId
      );
      // console.log(respAccount);

      let resp = this.dataPage.respList.find(x => x.respId === data.respId);
      // console.log(resp);

      data.isPrimaryEdit = false;
      return false;
    }

    data.isPrimaryEdit = !data.isPrimaryEdit;
    // var respAccount = this.dataPageClone.respList.find(x => x.respId === data.respId);
    // console.log(respAccount);
    // respAccount.respList = data;
  }

  onSecondaryEdit(data, event = null) {
    // console.log(this.dataPage);

    if (event === 'cancel') {
      let respAccount = this.dataPageClone.respList.find(
        x => x.respId === data.respId
      );
      // console.log(respAccount);

      let resp = this.dataPage.respList.find(x => x.respId === data.respId);
      // console.log(resp);

      data.isSecondaryEdit = false;
      return false;
    }

    data.isSecondaryEdit = !data.isSecondaryEdit;
    // var respAccount = this.dataPageClone.respList.find(x => x.respId === data.respId);
    // console.log(respAccount);
    // respAccount.respList = data;
  }

  onSave(data) {
    // console.log(data);
    // console.log(this.dataPage);

    // let resp = this.dataPage.respList.find(x => x.respId === data.respId);
    // console.log(resp);
    var allResp = [];
    var records = [];

    // let primaryData = [];
    // let secondaryData = [];

    data.responsibility.forEach(resp => {
      // let type = [];

      resp.data.forEach(element => {
        // console.log(element);
        // if(resp.type === 'PRIMARY') {
        //   // console.log(1);
        //   primaryData.push({
        //     "routeId": element.routeId,
        //     "routeName": resp.type,
        //     "priorityOrder": 0
        //   })
        // }
        // else if(resp.type === 'SECONDARY') {
        //   secondaryData.push({
        //     "routeId": element.routeId,
        //     "routeName": resp.type,
        //     "priorityOrder": 0
        //   })
        // }
        allResp.push({
          routeId: element.routeId,
          responsibility: resp.type.toLowerCase(),
          priorityOrder: 0,
        });
      });

      // if(primaryData.length > 0 && secondaryData.length > 0) {
      // }
      // console.log(primaryData);
    });

    // allResp.push(primaryData);

    records.push({
      type: data.type,
      userResponsibility: allResp,
    });

    // console.log(allResp);
    // console.log(JSON.stringify(allResp));
    // return false;

    var bodyData = [];
    // resp.responsibility.forEach(element => {
    bodyData.push({
      userId: data.userId,
      responsibilityReq: allResp,
    });
    // });
    // console.log(bodyData);

    var urlAction = 'users/v3/assignUserResponsibility';
    this._commonService.commonPostAction(urlAction, bodyData).subscribe(
      assignResp => {
        console.log(assignResp);
        data.isPrimaryEdit = false;
        data.isSecondaryEdit = false;

        this._snackBar.open('Assign successfully', null, {
          duration: 5000,
        });
      },
      err => {
        this._snackBar.open(err.error.errorType, null, {
          duration: 5000,
        });
      }
    );
  }

  onEditUser(element) {
    // console.log('edit user: ', element);
    const { agency, displayName, userName, userId, responsibility } = element;
    const dialogRef = this.dialog.open(EditResponsibilityComponent, {
      data: {
        agency,
        displayName,
        userName,
        userId,
        responsibility,
      },
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('close dialog', result);
      if (result) {
        let isExist = this.tableDataClone.find(x => x.userId === result.userId);
        // console.log('curr item', isExist, result);
        if (isExist) {
          const tempData = {
            userId: 0,
            userName: 'userName',
            responsibility: [],
          };
          isExist.responsibility.primary = result.responsibility.primary;
          isExist.responsibility.secondary = result.responsibility.secondary;
          isExist.responsibility.primary.unshift(tempData);
          isExist.responsibility.secondary.unshift(tempData);
        }
        this.dataSource.data = this.tableDataClone;

        const { responsibility, userName } = isExist;

        // check if user edited is self first
        const currentUser = localStorage.getItem('username');
        if (userName === currentUser) {
          const newRoutes = [];

          for (const routeType in responsibility) {
            for (const route of responsibility[routeType]) {
              if (route?.routeName) {
                newRoutes.push(route.routeName);
              } else if (route?.routeId) {
                newRoutes.push(route.routeId);
              }
            }
          }
          const uniqueRoutes = [...new Set(newRoutes)];
          this._firebaseService.updateRouteSubscription(uniqueRoutes);
        }
      }
    });
  }

  onSearchFilterInput(data) {
    this.searchFilterSubject.next(data);
  }

  clearFilterTextBox(event) {
    event.stopPropagation();
    this.searchFilter = '';
    this.searchFilterSubject.next('');
  }

  startSearchNameSubscription() {
    this.searchFilterSubscription = this.searchFilterSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(result => {
        const searchQuery = result?.toString()?.trim()?.toLowerCase();
        this.searchFilterValue = searchQuery;
        this.onFilterChange();
      });
  }

  onFilterChange() {
    this.dataSource.filter = this.searchFilterValue;
  }

  ngOnDestroy() {
    this.searchFilterSubscription?.unsubscribe?.();
  }
}
