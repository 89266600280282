import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { MenuService } from '../../shared/services/menu.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  currUserEmail = localStorage['email'];

  // navbarOpen = false;
  isNewWindow: boolean = false;
  opened: boolean;
  // isColor:string = 'colorGreen';
  // menuConfig:any;
  wordLogo: any = '';

  constructor(
    private authService: AuthService,
    public menuService: MenuService,
    private sanitizer: DomSanitizer,
    private _commonService: CommonService,
    public _router: Router
  ) {}

  ngOnInit() {
    this.wordLogo = environment.image.wordLogo;
  }

  get menuConfig() {
    return this.menuService.menu;
  }

  getBackgroundImg(imgData) {
    const img = '../../../../assets/images/icon/' + imgData;
    const style = `background: url(${img}) center / 100% no-repeat`;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  onLogout() {
    this._commonService.logout();
  }

  openedChange(opened) {
    this.opened = opened;
  }

  clickPanel(panel: string, state: boolean) {
    this.menuService.updatePanelState(panel, state);
  }
}
