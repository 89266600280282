import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/component/shared/services/common.service';

@Component({
  selector: 'app-edit-responsibility',
  templateUrl: './edit-responsibility.component.html',
  styleUrls: ['./edit-responsibility.component.scss'],
})
export class EditResponsibilityComponent implements OnInit {
  newData;
  serviceList = [];
  selectedPrimaryServices = [];
  selectedSecondaryServices = [];
  originalPrimaryServices = [];
  originalSecondaryServices = [];
  isLoading: boolean = true;
  isInit: boolean = false;
  showLoading: boolean = false;

  constructor(
    private _commonService: CommonService,
    public dialogRef: MatDialogRef<EditResponsibilityComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _snackBar: MatSnackBar
  ) {
    this.newData = JSON.parse(JSON.stringify(this.dialogData));
  }

  ngOnInit() {
    setTimeout(() => {
      this.showLoading = true;
    }, 500);
    this.initPTO();
    // for (let index = 0; index < 11; index++) {
    //   // const element = array[index];
    //   let elem = this.newData.data[index];
    //   if(elem === undefined) {
    //     // elem.push({routeId: '0', routeName: '0', priorityOrder: 0});
    //     this.newData.data[index] = {routeId: '0', routeName: '0', priorityOrder: 0};
    //   }
    // }
  }

  isLetter(c) {
    if (!c) return false;
    return c.toLowerCase() != c.toUpperCase();
  }

  initPTO() {
    var urlAction = 'users/v2/getRoutesByUserPTO/' + this.dialogData.userName;
    this._commonService.commonGetAction(urlAction).subscribe(
      resp => {
        const uniqueServices = [...new Set(resp?.routeIds ?? [])];
        // const filteredServices = uniqueServices.filter(
        //   (svc: string) => !this.isLetter(svc?.charAt(0))
        // );
        this.serviceList = [...uniqueServices];
        const primaryServiceData = JSON.parse(
          JSON.stringify(this.dialogData.responsibility.primary)
        ).reduce((res, respData) => {
          const { routeId } = respData || {};
          if (routeId) {
            res.push(routeId);
          }
          return res;
        }, []);
        this.selectedPrimaryServices = primaryServiceData;
        this.originalPrimaryServices = JSON.parse(
          JSON.stringify(primaryServiceData)
        );

        const secondaryServiceData = JSON.parse(
          JSON.stringify(this.dialogData.responsibility.secondary)
        ).reduce((res, respData) => {
          const { routeId } = respData || {};
          if (routeId) {
            res.push(routeId);
          }
          return res;
        }, []);
        this.selectedSecondaryServices = secondaryServiceData;
        this.originalSecondaryServices = JSON.parse(
          JSON.stringify(secondaryServiceData)
        );
        this.isLoading = false;

        setTimeout(() => {
          this.isInit = true;
        }, 150);
      },
      err => {
        console.error('User Responsibility: Failed to load - ', err);
        this._snackBar.open(
          'An error occurred. Please close this modal and try again.',
          null,
          {
            duration: 5000,
          }
        );
      }
    );
  }

  onSaveRespon() {
    // if (
    //   this.selectedPrimaryServices.length < 1 &&
    //   this.selectedSecondaryServices.length < 1
    // ) {
    //   this._snackBar.open(
    //     'Please assign at least 1 route responsibility for this user.',
    //     null,
    //     {
    //       duration: 5000,
    //     }
    //   );
    //   return;
    // }

    this.isLoading = true;
    const editedCloneData = JSON.parse(JSON.stringify(this.newData));

    let editedData = [];
    let primaryData = [];
    let secondaryData = [];

    this.selectedPrimaryServices.forEach(service => {
      const primaryItem = {
        routeId: service,
        responsibility: 'primary',
        priorityOrder: 1,
      };
      editedData.push(primaryItem);
      primaryData.push(primaryItem);
    });
    this.selectedSecondaryServices.forEach(service => {
      const secondaryItem = {
        routeId: service,
        responsibility: 'secondary',
        priorityOrder: 1,
      };
      editedData.push(secondaryItem);
      secondaryData.push(secondaryItem);
    });
    const bodyData = [
      {
        userId: this.newData.userId,
        responsibilityReq: editedData,
      },
    ];
    // console.log(bodyData);
    // return false;

    const urlAction = 'users/v3/assignUserResponsibility';
    this._commonService.commonPostAction(urlAction, bodyData).subscribe(
      assignResp => {
        // console.log('Update result: ', assignResp);

        this._snackBar.open(`Responsibility successfully updated.`, null, {
          duration: 5000,
        });

        editedCloneData.responsibility = {
          primary: primaryData,
          secondary: secondaryData,
        };
        this.isLoading = false;
        this.dialogRef.close(editedCloneData);
      },
      err => {
        console.error(
          'User Responsibility: Failed to update responsibilities - ',
          err
        );
        this._snackBar.open(
          err?.error?.errorType || 'An error occurred. Please try again.',
          null,
          {
            duration: 5000,
          }
        );
      }
    );
  }

  displaySelection(data) {
    return data?.join(', ') || '';
  }

  // used for maintaining selection order if needed
  // usage on mat-select: [sortComparator]="selectionCompare"
  selectionCompare(a, b, options) {
    return 1;
  }

  getOptionSelectedOrder(type, value) {
    let valueIndex = -1;
    if (type === 'primary') {
      valueIndex =
        this.selectedPrimaryServices.findIndex(service => service === value) +
        1;
    }
    if (type === 'secondary') {
      valueIndex =
        this.selectedSecondaryServices.findIndex(service => service === value) +
        1;
    }
    return valueIndex;
  }
}
