import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from '../../shared/services/push-notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getBrowser } from '../../shared/utils';

@Component({
  selector: 'app-notification-permission',
  templateUrl: './notification-permission.component.html',
  styleUrls: ['./notification-permission.component.scss'],
})
export class NotificationPermissionComponent implements OnInit {
  readonly chromeSupportNotificationUrl =
    'https://support.google.com/chrome/answer/3220216';
  readonly edgeSupportNotificationUrl =
    'https://support.microsoft.com/en-us/microsoft-edge/manage-website-notifications-in-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b';
  readonly firefoxSupportNotificationUrl =
    'https://support.mozilla.org/en-US/kb/push-notifications-firefox#w_upgraded-notifications';

  constructor(
    private _notificationService: PushNotificationService,
    private _snackBar: MatSnackBar
  ) {}
  isShow: boolean = false;
  permissionDenied: boolean = false;

  ngOnInit(): void {
    this.isShow = this.showRequest();
  }

  showRequest() {
    if ('Notification' in window) {
      let { permission } = Notification;
      if (permission === 'granted') {
        // console.log('Notifications are already allowed.');
        return false;
      }
      if (permission !== 'denied') {
        // console.log('Can request permission');
        return true;
      }
      if (permission === 'denied') {
        this.permissionDenied = true;
        return true;
      }
    }
    console.log('Notifications are not allowed on this site.');
    return false;
  }

  permissionTimeout;
  async askPermission() {
    let me = this;
    // console.log('Ask Permission', getBrowser());
    if ('permissions' in navigator) {
      navigator.permissions
        .query({ name: 'notifications' })
        .then(function (notificationPerm) {
          console.log('query permission: ', notificationPerm);

          me.permissionTimeout = setTimeout(() => {
            me.permissionDenied = true;
          }, 10000);

          notificationPerm.onchange = function () {
            const permissionState = notificationPerm.state;
            console.log(
              'Notification Permission changed: ',
              notificationPerm.state
            );
            if (permissionState === 'granted') {
              me.isShow = false;
              me.permissionDenied = false;
              clearTimeout(me.permissionTimeout);
            }
            if (permissionState === 'denied') {
              me.isShow = true;
              me.permissionDenied = true;
            }
          };
        });
      await this._notificationService._isShowNotificationPermitted();
    }
  }

  closeDialog() {
    this.isShow = false;
    this._snackBar.open(
      'Notifications will not show on this site until permissions are allowed.',
      null,
      {
        duration: 5000,
      }
    );
  }
}
