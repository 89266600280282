<!-- <p-table #dt [columns]="cols" [value]="cars" [paginator]="true" [rows]="10" dataKey="driverId" editMode="row" class="driver-table" 
[scrollable]="true" scrollHeight="77vh"
    showCurrentPageReport="true"
    currentPageReportTemplate="{currentPage} of {totalPages}"
    > -->
    <!-- <ng-template pTemplate="caption">
        <div style="text-align: right">        
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
    </ng-template> -->
    <ng-template pTemplate="paginatorright" let-state> Total elements : {{ dt .totalRecords }} </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th class="td-checkbox">&nbsp;</th>
            <th *ngFor="let col of columns" class=td-{{col.field}}>
                {{col.header}}
            </th>
            <th>Actions</th>
        </tr>
        <tr>
            <th class="td-checkbox">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [ngSwitch]="col.field" class=td-{{col.field}}>
                <input *ngSwitchCase="'driverId'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <p-dropdown *ngSwitchCase="'driverStatus'" [options]="status" class="driver-status" (onChange)="dt.filter($event.value, col.field, 'equals')"></p-dropdown>
                <!-- <div *ngSwitchCase="'year'">
                    Value < {{yearFilter}}
                    <i class="fa fa-close" (click)="yearFilter=null;dt.filter(null, col.field, col.filterMatchMode)" style="cursor:pointer" *ngIf="yearFilter"></i>
                    <p-slider [style]="{'width':'100%','margin-top':'8px'}" [(ngModel)]="yearFilter" [min]="1970" [max]="2010" (onChange)="onYearChange($event, dt)"></p-slider>
                </div>
                <p-dropdown *ngSwitchCase="'brand'" [options]="brands" [style]="{'width':'100%'}" (onChange)="dt.filter($event.value, col.field, 'equals')"></p-dropdown>
                <p-multiSelect *ngSwitchCase="'color'" [options]="colors" defaultLabel="All Colors" (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect> -->
                
                <input *ngSwitchCase="'fullName'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'primaryPhone'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'address'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <!-- <input *ngSwitchCase="'joiningDate'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')"> -->
                <!-- <p-calendar *ngSwitchCase="'joiningDate'" class="joiningDateCalendar" [showIcon]="true" [(ngModel)]="date1"></p-calendar> -->
                <p-dropdown *ngSwitchCase="'isTemporary'" [options]="temporary" class="is-temporary" (onChange)="dt.filter($event.value, col.field, 'equals')"></p-dropdown>
                <input *ngSwitchCase="'lastDutyDate'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'identificationId'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'identificationDocType'" pInputText class="form-control" type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <!-- <input *ngSwitchCase="'driverId'" pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')"> -->
            </th>
            <th>&nbsp;</th>
        </tr>
    </ng-template>
    <!-- <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData"> -->
    <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData" [pSelectableRow]="rowData">
            <td class="td-checkbox">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <!-- <td *ngFor="let col of columns">
                {{rowData[col.field]}} 
            </td> -->

            <td class="td-driverId">
                {{rowData.driverId}}
            </td>
            <td class="td-driverStatus">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown [options]="status" [(ngModel)]="rowData.driverStatus"></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.driverStatus}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="td-fullName">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="rowData.fullName" class="form-control" required>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.fullName}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="td-primaryPhone">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="rowData.primaryPhone"class="form-control">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.primaryPhone}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="td-address">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <textarea pInputText type="text" [(ngModel)]="rowData.address" class="form-control"></textarea>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.address}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="td-joiningDate">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <!-- <p-calendar *ngSwitchCase="'joiningDate'" class="joiningDateCalendar" [(ngModel)]="date1"></p-calendar> -->
                        <p-calendar [(ngModel)]="rowData.joiningDate" [(ngModel)]="date2" [showIcon]="true" class="form-control1 joiningDateCalendar"></p-calendar>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.joiningDate | date:'shortDate'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="td-isTemporary">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown [options]="temporary" [(ngModel)]="rowData.isTemporary" [style]="{'width':'100%'}"></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.isTemporary}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="td-lastDutyDate">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="rowData.lastDutyDate" class="form-control">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.lastDutyDate}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="td-identificationId">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="rowData.identificationId" class="form-control">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.identificationId}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="td-identificationDocType">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="rowData.identificationDocType" class="form-control">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.identificationDocType}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td style="text-align:center">
                <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" (click)="onRowEditInit(rowData)"></button>
                <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check" class="ui-button-success" (click)="onRowEditSave(rowData)"></button>
                <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times" class="ui-button-danger" (click)="onRowEditCancel(rowData, ri)"></button>
            </td>
        </tr>
    </ng-template>
<!-- </p-table> -->
<!-- <p-paginator [rows]="10" [totalRecords]="120" [rowsPerPageOptions]="[10,20,30]"></p-paginator> -->