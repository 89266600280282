import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pm-controller',
  templateUrl: './pm-controller.component.html',
  styleUrls: ['./pm-controller.component.scss']
})
export class PmControllerComponent implements OnInit {

  isNewWindow: boolean = false;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
  }

}
