import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  NgZone,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import jwt_decode from 'jwt-decode';

import { JitsiService } from 'src/app/component/shared/services/jitsi.service';

import {
  INTERFACE_CONFIG_OVERWRITE,
  CONFIG_OVERWRITE,
} from 'src/app/component/shared/others/jitsi-config';
import { environment } from 'src/environments/environment';

declare var JitsiMeetExternalAPI;

@Component({
  selector: 'app-emergency-call',
  templateUrl: './emergency-call.component.html',
  styleUrls: ['./emergency-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyCallComponent implements AfterViewInit {
  decodedToken: any = jwt_decode(localStorage.getItem('access_token'));
  displayName = localStorage['displayname'];
  jitsiApi;
  @ViewChild('jitsicallframe') callFrame: ElementRef;
  busRegistrationNumber: string = '';
  operatorName: string = '';
  operatorInfo: any;
  bus;
  jitsiInitialized: boolean = false;
  callError: boolean = false;
  jitsiEnd: boolean = false;
  jitsiHidden: boolean = true;
  participantCount: number = 0;
  isMaximized: boolean = false;
  @Input() id;
  @Input() index: number = 0;
  @Input() emergencyData: any = {};
  @Output() closeEmergency = new EventEmitter();

  // resize related variables
  @ViewChild('resizeBox') resizeBox: ElementRef;
  @ViewChild('dragHandleCorner') dragHandleCorner: ElementRef;
  @ViewChild('dragHandleRight') dragHandleRight: ElementRef;
  @ViewChild('dragHandleBottom') dragHandleBottom: ElementRef;
  @ViewChild('callHeader') callHeader: ElementRef;
  headerHeight = 40;

  get resizeBoxElement(): HTMLElement {
    return this.resizeBox.nativeElement;
  }

  get dragHandleCornerElement(): HTMLElement {
    return this.dragHandleCorner.nativeElement;
  }

  get dragHandleRightElement(): HTMLElement {
    return this.dragHandleRight.nativeElement;
  }

  get dragHandleBottomElement(): HTMLElement {
    return this.dragHandleBottom.nativeElement;
  }

  get getCallHeaderElement(): HTMLElement {
    return this.callHeader.nativeElement;
  }

  constructor(
    private _jitsiService: JitsiService,
    private ngZone: NgZone,
    private _cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.initData = this.emergencyData;
    // console.log('NGBMS Emergency init:', this.jitsiInitialized, this.isMaximized);
    if (!this.callError) {
      setTimeout(() => {
        this.initJitsi();
        const rect = this.getCallHeaderElement.getBoundingClientRect();
        // console.log('NGBMS Emergency: call header dimensions', rect);
        this.headerHeight = rect.height;
      }, 0);
    }
    this.setAllHandleTransform();
  }

  setAllHandleTransform() {
    const rect = this.resizeBoxElement.getBoundingClientRect();
    this.setHandleTransform(this.dragHandleCornerElement, rect, 'both');
    this.setHandleTransform(this.dragHandleRightElement, rect, 'x');
    this.setHandleTransform(this.dragHandleBottomElement, rect, 'y');
  }

  setHandleTransform(
    dragHandle: HTMLElement,
    targetRect: ClientRect | DOMRect,
    position: 'x' | 'y' | 'both'
  ) {
    const dragRect = dragHandle.getBoundingClientRect();
    const translateX = targetRect.width - dragRect.width;
    const translateY = targetRect.height - dragRect.height;

    if (position === 'x') {
      dragHandle.style.transform = `translate3d(${translateX}px, 0, 0)`;
    }

    if (position === 'y') {
      dragHandle.style.transform = `translate3d(0, ${translateY}px, 0)`;
    }

    if (position === 'both') {
      dragHandle.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`;
    }
  }

  dragMove(dragHandle: HTMLElement, $event?: CdkDragMove<any>) {
    this.ngZone.runOutsideAngular(() => {
      this.resize(dragHandle, this.resizeBoxElement);
    });
  }

  resize(dragHandle: HTMLElement, target: HTMLElement) {
    const dragRect = dragHandle.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const width = dragRect.left - targetRect.left + dragRect.width;
    const height = dragRect.top - targetRect.top + dragRect.height;
    // const height = dragRect.top + dragRect.height;
    // console.log('NGBMS Emergency: drag ', dragRect.top, targetRect.top, dragRect, targetRect, window.innerHeight, window.innerWidth);
    const maxHeight = window.innerHeight - targetRect.top - 10;
    const maxWidth = window.innerWidth - targetRect.left - 59;
    // console.log('NGBMS Emergency: max width', width, ' / ', maxWidth);
    // console.log('NGBMS Emergency: max height', height, ' / ', maxHeight);
    if (width >= 360) {
      target.style.width = width + 'px';
      if (width < maxWidth) {
        target.style.width = width + 'px';
      } else {
        target.style.width = maxWidth + 'px';
      }
    } else {
      target.style.width = 360 + 'px';
    }

    if (height >= 400) {
      if (height < maxHeight) {
        target.style.height = height + 'px';
      } else {
        target.style.height = maxHeight + 'px';
      }
    } else {
      target.style.height = 400 + 'px';
    }

    this.setAllHandleTransform();
  }

  initData: any = {};
  callRoomName: string = '';
  initJitsi() {
    const domain = this._jitsiService.getDomain();
    const jaasToken = this._jitsiService.getToken();
    const { agency } = this.decodedToken;
    this.operatorName = `${agency}-OPS-${this.displayName}`;

    console.log('init emergency call', `domain: ${domain}`, this.initData);

    const { bus, rid } = this.initData;
    this.callRoomName = `${environment.jitsi.appId}/${agency}-emergency-${
      bus ? bus + '-' : ''
    }${rid ? rid : ''}`;
    const options = {
      jwt: jaasToken,
      roomName: this.callRoomName,
      parentNode: this.callFrame.nativeElement,
      width: '100%',
      height: '100%',
      interfaceConfigOverwrite: {
        TILE_VIEW_MAX_COLUMNS: 1,
        DEFAULT_BACKGROUND: '#292929',
        ...INTERFACE_CONFIG_OVERWRITE,
      },
      configOverwrite: {
        ...CONFIG_OVERWRITE,
        subject: `Emergency call`,
        startWithVideoMuted: true,
        toolbarButtons: [
          'tileview',
          'hangup',
          'microphone',
          'camera',
          'settings',
        ],
        hideConferenceTimer: false,
        disableTileView: false,
        startWithAudioMuted: false,
        // Disables self-view tile. (hides it from tile view and from filmstrip)
        disableSelfView: false,
        // Disables self-view settings in UI
        // disableSelfViewSettings: true,
        toolbarConfig: {
          alwaysVisible: true,
        },
      },
    };
    this.jitsiApi = new JitsiMeetExternalAPI(domain, options);
    const me = this;

    this.jitsiApi.executeCommand('displayName', this.operatorName);
    this.jitsiApi.addListener('videoConferenceJoined', info => {
      me.participantCount += 1;
      setTimeout(() => {
        me.jitsiHidden = false;
        me.jitsiApi.executeCommand('setNoiseSuppressionEnabled', {
          enabled: true, // Enable or disable noise suppression.
        });
      }, 200);
    });

    this.jitsiApi.addListener('videoConferenceLeft', res => {
      me.participantCount -= 1;
      setTimeout(() => {
        me.jitsiHidden = true;
      }, 0);
      me.closeJitsiCall();
    });

    this.jitsiApi.addListener('participantJoined', () => {
      me.participantCount += 1;
      // if (me.participantCount < 2) {
      //   me.jitsiApi.isAudioMuted().then(muted => {
      //     if (muted) {
      //       me.jitsiApi.executeCommand('toggleAudio');
      //     }
      //   })
      // }
    });

    this.jitsiApi.addListener('participantLeft', () => {
      me.participantCount -= 1;
      // if (me.participantCount < 2) {
      //   me.jitsiApi.isAudioMuted().then(muted => {
      //     if (!muted) {
      //       me.jitsiApi.executeCommand('toggleAudio');
      //     }
      //   })
      // }
    });

    setTimeout(() => {
      me.jitsiInitialized = true;
      // console.log('NGBMS Emergency init:', me.jitsiInitialized, me.isMaximized);
      me._cdr.detectChanges();
    }, 200);
  }

  closeJitsiCall() {
    this.jitsiEnd = true;
    if (this.jitsiApi) {
      this.jitsiApi.executeCommand('hangup');
      this.jitsiApi.dispose();
    }
    setTimeout(() => {
      // close emergency call component here
      const { rid } = this.emergencyData;
      this.closeEmergency.emit(rid);
    }, 200);
  }

  tempWidth = 360;
  tempHeight = 400;
  toggleMaximize() {
    if (!this.isMaximized) {
      const target = this.resizeBoxElement.getBoundingClientRect();
      this.tempHeight = target.height;
      this.tempWidth = target.width;
    }
    this.isMaximized = !this.isMaximized;
    this.ngZone.runOutsideAngular(() => {
      this.maximizeContainer(
        this.resizeBoxElement,
        this.tempWidth,
        this.tempHeight
      );
    });
  }

  maximizeContainer(targetElement: HTMLElement, width: number, height: number) {
    const target = targetElement;
    if (this.isMaximized) {
      target.style.width = '100%';
      target.style.height = '100%';
    } else {
      target.style.width = width + 'px';
      target.style.height = height + 'px';
    }
  }

  // ngOnDestroy() {
  //   if (this.jitsiApi) {
  //     const { rid } = this.emergencyData;
  //     this.jitsiApi.executeCommand('hangup');
  //     this.jitsiApi.dispose();
  //     this.onCloseEmergency.emit(rid);
  //   }
  // }
}
