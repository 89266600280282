import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { HOME_PATH, LOGIN_PATH } from '../others/constant';
import { CommonService } from './common.service';
import { VoipService } from './voip.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private _voipService: VoipService
  ) {}

  isAuthenticated() {
    return localStorage.getItem('access_token') != undefined;
  }

  login(loginData) {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('password', loginData.password)
      .set('username', loginData.email)
      .set('ctry_code', environment.countryCode);

    const options = {
      headers: new HttpHeaders({
        Authorization: `Basic ` + btoa('USER_CLIENT_APP:password'),
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.httpClient
      .post(environment.apiServerUrl + 'oauth', body, options)
      .pipe(
        catchError(error => {
          // console.log('data0', error);
          // localStorage.clear();
          // this.commonService.spin$.next(false);
          // this.router.navigate([LOGIN_PATH])
          // .then(() => {
          //   window.location.reload();
          // });
          return throwError(error.error.error_description);
        }),
        map(
          (data: any) => {
            if (data.error === 'unauthorized') {
              localStorage.clear();
              // this.commonService.spin$.next(false);
              this.commonService.closeOverlay();
              console.log('DATA ERROR: unauthorized');
              this.router.navigate([LOGIN_PATH]).then(() => {
                window.location.reload();
              });
            } else {
              // localStorage.setItem('access_token', data['access_token']);
              // localStorage.setItem('refresh_token', data['refresh_token']);
              // localStorage.setItem('email', data['email']);
              // localStorage.setItem('scope', data['scope']);
              // localStorage.setItem('displayname', data['displayname']);
              // localStorage.setItem('password', loginData.password);

              return data;
            }
          },
          error => {
            // console.log('data2', error);
            localStorage.clear();
            // this.commonService.spin$.next(false);
            this.commonService.closeOverlay();
            console.log('DATA ERROR:', error);
            this.router.navigate([LOGIN_PATH]).then(() => {
              window.location.reload();
            });
          }
        )
      );
  }

  // logout() {
  //     var bodyOptions = {
  //       "sipId": localStorage.getItem('sipNumber')
  //     }
  //     this._voipService.logOffSipNumber(bodyOptions).subscribe(
  //       resp => {
  //         localStorage.clear();
  //         // this.commonService.spin$.next(false);
  //         this.commonService.closeOverlay();
  //         // this.router.navigate([LOGIN_PATH])
  //         // .then(() => {
  //         //   window.location.reload();
  //         // });
  //       },
  //       err => {
  //         console.log(err);
  //       }
  //     );
  // }

  refreshAccessToken(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Basic ` + btoa('USER_CLIENT_APP:password'),
        'Content-Type': 'application/x-www-form-urlencoded',
        ctry_code: environment.countryCode,
      }),
    };

    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', localStorage.getItem('refresh_token'));

    return this.httpClient
      .post(environment.apiServerUrl + 'oauth', body, options)
      .pipe(
        map(
          (data: any) => {
            // console.log('Refresh: ', data);
            if (data.error === 'unauthorized') {
              localStorage.clear();
              // this.commonService.spin$.next(false);
              this.commonService.closeOverlay();
              console.log('DATA ERROR: unauthorized');
              this.router.navigate([LOGIN_PATH]).then(() => {
                window.location.reload();
              });
            } else {
              localStorage.setItem('access_token', data['access_token']);
              localStorage.setItem('refresh_token', data['refresh_token']);

              return data['access_token'];
            }
          },
          error => {
            // console.log('Refresh Error: ', JSON.stringify(error));
            localStorage.clear();
            // this.commonService.spin$.next(false);
            console.log('DATA ERROR: ', error);
            this.commonService.closeOverlay();
            this.router.navigate([LOGIN_PATH]).then(() => {
              window.location.reload();
            });
          }
        )
      );
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  isAdmin(): boolean {
    return localStorage['scope'] === 'admin';
  }

  isHasAccess(page): boolean {
    var hasAccess = JSON.parse(localStorage.getItem('hasAccess'));
    // var hasAccess = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('hasAccess'))));
    this.initMenu();
    // setTimeout(() => {
    // hasAccess can be undefined
    // if((hasAccess?.find(x => x.id === page.id)) ?? (page.id === 3)) {
    if (hasAccess?.find(x => x.id === page.id)) {
      return true;
    } else {
      return false;
    }
    // }, 500);
  }

  initMenu() {
    var menuConfig = environment.menu;
    var hasAccess = JSON.parse(localStorage.getItem('hasAccess'));
    if (hasAccess === null) {
      return false;
    }

    for (const key in menuConfig) {
      if (Object.prototype.hasOwnProperty.call(menuConfig, key)) {
        const menuElem = menuConfig[key];

        if (menuElem.isVisible === undefined) {
          // || !menuElem.isVisible) {
          for (const key in menuElem) {
            if (Object.prototype.hasOwnProperty.call(menuElem, key)) {
              const subMenuElem = menuElem[key];
              let isExist = hasAccess.find(x => x.id === subMenuElem.id);
              if (isExist) {
                subMenuElem.isVisible = true;
              }
            }
          }
        } else {
          let isExist = hasAccess.find(x => x.id === menuElem.id);
          if (isExist) {
            menuElem.isVisible = true;
          }
        }
      }
    }
  }
}
