<div
  class="layer-control-group"
  app-panel-layer-control
  [class.topright]="position === 'top-right'"
  [class.bottom]="position === 'bottom'">
  <button
    aria-label="Layers"
    class="layer-icon-button"
    matTooltip="Layers"
    [matTooltipPosition]="'left'"
    [matMenuTriggerFor]="menu"
    [matTooltipDisabled]="menuTrigger.menuOpen"
    #menuTrigger="matMenuTrigger"
    [class.button-active]="menuTrigger.menuOpen">
    <mat-icon inline>layers</mat-icon>
  </button>
  <mat-menu
    #menu="matMenu"
    yPosition="below"
    aria-label="Layer Menu"
    class="layer-control-menu layer-menu">
    <div class="subheader">Map Type</div>
    <button
      mat-menu-item
      *ngFor="let mapType of mapTypeList; let i = index"
      [class.layer-active]="selectedBasemap === mapType.value"
      (click)="$event.stopPropagation(); onClickMapType(mapType.value)"
      class="layer-button">
      <img src="assets/images/icon/{{ mapType.icon }}" />
      <span>{{ mapType.name }}</span>
    </button>
    <div class="subheader">Overlay</div>
    <button
      mat-menu-item
      *ngFor="let layer of listOverlayLayers; let i = index"
      [class.layer-active]="layer.enabled"
      (click)="$event.stopPropagation(); onClickOverlayItem(layer, i)"
      class="layer-button">
      <img src="assets/images/icon/{{ layer.icon }}" />
      <span>{{ layer.name }}</span>
    </button>
  </mat-menu>
</div>
