<!-- <div class="account-body">
    <router-outlet></router-outlet>
</div> -->
<!-- <mat-toolbar class="example-header">Header1111</mat-toolbar> -->
<mat-sidenav-container class="global-navigation" autosize>
  <!-- <mat-sidenav #sidenav mode="side" opened class="example-sidenav" (openedChange)="openedChange($event)">
        <app-account-menu #appAcctMenu></app-account-menu>
        <div class="toggle-menu" (click)="sidenav.toggle()">
            <i class="fa fa-bars"></i>
        </div>
    </mat-sidenav> -->

  <mat-sidenav
    *ngIf="isMenuShow"
    mode="side"
    opened
    class="side-navigation text-center"
    [class.side-navigation-expanded]="menuService.isMenuPinned"
    disableClose>
    <app-sidebar-menu></app-sidebar-menu>
  </mat-sidenav>

  <mat-sidenav-content class="height-100vh">
    <app-emergency-call-wrapper></app-emergency-call-wrapper>

    <div class="account-body">
      <router-outlet></router-outlet>
    </div>

    <app-online-status
      [onlineStatusMessage]="connectionStatusMessage"
      [onlineStatus]="connectionStatus">
    </app-online-status>

    <!-- <button id="event-call-listener" class="d-none" (click)="initIncomingData()"></button>
        <div class="voip-panel-group" cdkDrag
        [ngClass]="{
        'd-none1': !isShowVoidPanel
        }">
            <div class="wrapper">
                <div class="bd-header">
                    <span class="voip-bus-code"></span>{{voipData === undefined || voipData.busRegNo === undefined ? '' : ' '+voipData.busRegNo}}
                    <i class="fa fa-times-circle close-voip" (click)="closeVoipPanel()"></i>
                </div>
                <div class="body">
                    <div fxLayout="row" fxLayoutAlign="center center">
                    <div class="outgoing-call">
                        <div class="icon icon-hangup" (click)="voipHangUp()"><i class="fa fa-phone"></i></div>
                    </div>
                    <div class="incoming-call d-none" fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="icon icon-hangup" (click)="voipDecline()"><i class="fa fa-phone"></i></div>
                        <div class="icon icon-answer bounce" (click)="voipAnswer()"><i class="fa fa-phone"></i></div>
                    </div>
                    <div class="idle-call d-none1">
                        <div class="icon icon-answer" (click)="voipCall(voipData)"><i class="fa fa-phone"></i></div>
                    </div>
                    </div>
                    <div class="call-status">Connecting...</div>
                </div>
            </div>
        </div> -->
    <!-- <div class="voip-panel-group" fxLayout="column" fxLayoutAlign="space-between end"
        [ngClass]="{
        'd-none': !isShowVoidPanel
        }">
            <button mat-button class="close-voip-panel" [disabled]="isVoipBusy"(click)="closeVoipPanel()">
                <mat-icon>close</mat-icon>
            </button>
            <div fxLayout="row" fxLayoutAlign="space-around center" fxFlex="100" class="width100">
                <button id="event-call-listener" class="d-none" (click)="initIncomingData()"></button>
                <div class="width100" fxLayout="column" fxLayoutAlign="space-between center">
                    <div class="bd-header">
                        <span class="voip-bus-code"></span>{{voipData === undefined || voipData.busRegNo === undefined ? '' : ' '+voipData.busRegNo}}
                    </div>
                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-between center">
                        <div class="body" fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayoutAlign="space-between center" >
                                <div class="outgoing-call">
                                    <div class="icon icon-hangup" (click)="voipHangUp()"><i class="fa fa-phone"></i></div>
                                </div>
                                <div class="idle-call d-none1">
                                    <div class="icon icon-answer" (click)="voipCall(voipData)"><i class="fa fa-phone"></i></div>
                                </div>
                            </div>

                            <div class="incoming-call d-none" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="icon icon-hangup" (click)="voipDecline()"><i class="fa fa-phone"></i></div>
                                <div class="icon icon-answer bounce" (click)="voipAnswer()"><i class="fa fa-phone"></i></div>
                            </div>
                        </div>
                        <div class="call-status">Connecting...</div>
                    </div>
                </div>  
            </div>
        </div> -->

    <div class="alarm-group">
      <perfect-scrollbar #chatPS class="ps-alarm-group" [config]="config">
        <div class="alarm" *ngFor="let alarm of allAlarms">
          <div class="text-center">
            <strong>{{ alarm.title | uppercase }}</strong>
            <mat-icon class="close-alarm" (click)="onCloseAlarm(alarm, true)"
              >close</mat-icon
            >
          </div>
          <div *ngIf="alarm.eventType === 'Message'">
            <div>From: {{ alarm.busRegNo }}</div>
            <div>Date: {{ alarm.timeStamp | date: 'short' }}</div>
            <div>Message: {{ alarm.messageText }}</div>
            <div class="text-right">
              <button
                mat-raised-button
                class="mar-top-10"
                (click)="notifEvent(alarm)">
                Open Message
              </button>
            </div>
          </div>

          <div *ngIf="alarm.eventType !== 'Message'">
            <!-- <div>Severity: {{alarm.eventSeverity | titlecase}}</div> -->
            <!-- <div>From: {{alarm.busRegNo}}</div> -->
            <div>Vehicle: {{ alarm.busRegNo }}</div>
            <div>Trip ID: {{ alarm.tripId }}</div>
            <div>Block ID: {{ alarm.blockId }}</div>
            <div>Date: {{ alarm.timeStamp }}</div>
            <!-- <div class="text-right">
                        <button mat-raised-button class="mar-top-10" (click)="onCloseAlarm(alarm)" [routerLink]="['/account/alarm/1']">Check</button>
                    </div> -->
          </div>
        </div>
      </perfect-scrollbar>
    </div>

    <div class="mqtt-group">
      <div class="mqtt-wrapper">
        <ng-container
          *ngFor="let message of _homeService.setPredefinedData; let i = index">
          <app-predefined-messages
            [data]="message"
            (closeMessage)="closeMessagePanel($event)"
            (predefinedMessageZoom)="predefinedMessageZoom($event)"
            (predefinedMessageClear)="
              predefinedMessageClear($event)
            "></app-predefined-messages>
        </ng-container>
      </div>
    </div>

    <!-- <ng-container *ngFor="let emergencies of _pushNotificationService.getEmergencyList(); trackBy: trackByRid">
            <app-emergency-call
                [index]="emergencies.index"
                [emergencyData]="emergencies"
                (onCloseEmergency)="closeEmergency($event)"
            >
            </app-emergency-call>
        </ng-container> -->

    <!-- <div class="emergency-group">
            <div class="emergency-wrapper">
                <ng-container *ngFor="let emergencyCalls of [1,2,3,4]; let j = index">

                </ng-container>
            </div>
        </div> -->
  </mat-sidenav-content>
</mat-sidenav-container>
