<div class="service-schedule-group">
  <div class="header-group" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <div>
        <span class="title-header">Bus Service Schedule</span>
        <sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup>
      </div>
      <div class="mar-left-10" [formGroup]="form">
        <span>
          <mat-form-field appearance="outline" fxFlex="100">
            <!-- <mat-label>Street Name / Bus Stop Code</mat-label> -->
            <input matInput placeholder="Service No." formControlName="streetNameBusStop">
          </mat-form-field>
        </span>
        <span fxFlex="18">
          <button class="btn-med" mat-raised-button type="submit" [disabled]="running || !form.valid" (click)="onSearchSchedule()">Search</button>
        </span>
      </div>
    </div>
    <button mat-raised-button type="button" (click)="uploadFile()" class="float-right">Upload</button>
    <!-- <span fxFlex="50" fxLayoutAlign="end start">
      <button class="btn-med" mat-raised-button (click)="addStop()" [disabled]="!stopManagementService.allStops || running">Add Stop</button>
    </span> -->
  </div>

  <div fxLayout="row" class="route-group" [ngClass]="{'div-disabled': isDisablePage}">
    <div class="left-side" fxFlex="70" (mouseover)="onLeftSideHover()">
      <div [ngClass]="{'visibility-hidden': !isServiceSchedule}"><h4>Route {{routeId}} <i class="font-sm" *ngIf="isServiceSchedule">({{selectedServiceData.header.length}} stops)</i></h4></div>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Operating Hours <sup>Edit</sup>
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="space-around center" >
              <ng-container *ngIf="summaryData.origin !== undefined">
                <span>From {{summaryData.origin}} ({{summaryData.originWeekday}})</span>
                <span *ngIf="isBiDirection">From {{summaryData.destination}} ({{summaryData.destWeekday}})</span>
              </ng-container>
            </mat-panel-description>
          </mat-expansion-panel-header>
          
          <div fxLayout="column" class="padding-10">
            <div fxLayoutAlign="space-between center" >
              <span>Schedule</span>
              <span class="pointer" (click)="inlineEdit(summaryData)" *ngIf="!summaryData.isInlineEdit">Edit</span>
              <span class="pointer" (click)="onSaveSummary(summaryData)" *ngIf="summaryData.isInlineEdit">Save</span>
            </div>

            <div fxLayout="column" class="padding-10">
              <div fxFlex="100">
                <div fxFlex="25"><div class="textBlue">Departure time from</div></div>
                <div fxFlex="25"><div class="textBlue">Weekdays</div></div>
                <div fxFlex="25"><div class="textBlue">Saturdays</div></div>
                <div fxFlex="25"><div class="textBlue">Sundays/P.Hs</div></div>
              </div>
              
              <div fxFlex="100">
                <div fxFlex="25"><div>{{summaryData.origin}}</div></div>
                <div fxFlex="25">
                  <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.originWeekday" [(ngModel)]="summaryData.originWeekday">
                </div>
                <div fxFlex="25">
                  <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.originSaturday" [(ngModel)]="summaryData.originSaturday">
                </div>
                <div fxFlex="25">
                  <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.originSunday" [(ngModel)]="summaryData.originSunday">
                </div>
              </div>

              <ng-container *ngIf="isBiDirection">
                <div fxFlex="100" class="mar-top-10">
                  <div fxFlex="25"><div class="textBlue">Departure time from</div></div>
                  <div fxFlex="25"><div class="textBlue">Weekdays</div></div>
                  <div fxFlex="25"><div class="textBlue">Saturdays</div></div>
                  <div fxFlex="25"><div class="textBlue">Sundays/P.Hs</div></div>
                </div>
              
                <div fxFlex="100">
                  <div fxFlex="25"><div>{{summaryData.destination}}</div></div>
                  <div fxFlex="25">
                    <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.destWeekday" [(ngModel)]="summaryData.destWeekday">
                  </div>
                  <div fxFlex="25">
                    <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.destSaturday" [(ngModel)]="summaryData.destSaturday">
                  </div>
                  <div fxFlex="25">
                    <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.destSunday" [(ngModel)]="summaryData.destSunday">
                  </div>
                </div>
              </ng-container>
            </div>
            <!--<hr class="hrBg">

            <div>Frequency</div>
            <div fxLayout="column" class="padding-10">

              <div fxFlex="100">
                <div fxFlex="20"></div>
                <div fxFlex="20" class="textBlue">0630 - 0830</div>
                <div fxFlex="20" class="textBlue">0831 - 1659</div>
                <div fxFlex="20" class="textBlue">1700 - 1900</div>
                <div fxFlex="20" class="textBlue">After 1900</div>
              </div>

              <div fxFlex="100">
                <div fxFlex="20">
                  <div [ngClass]="{'mar-top-10': summaryData.isInlineEdit}">From {{summaryData.origin}}</div>
                </div>
                <div fxFlex="20">
                  <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.
                  firstDirectionPeriod1" [(ngModel)]="summaryData.firstDirectionPeriod1">
                </div>
                <div fxFlex="20">
                  <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.firstDirectionPeriod2" [(ngModel)]="summaryData.firstDirectionPeriod2">
                </div>
                <div fxFlex="20">
                  <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.firstDirectionPeriod3" [(ngModel)]="summaryData.firstDirectionPeriod3">
                </div>
                <div fxFlex="20">
                  <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50': summaryData.isInlineEdit}" matInput [value]="summaryData.firstDirectionPeriod4" [(ngModel)]="summaryData.firstDirectionPeriod4">
                </div>
              </div>
              
              <ng-container *ngIf="isBiDirection">
                <div fxFlex="100">
                  <div fxFlex="20">
                    <div [ngClass]="{'mar-top-10': summaryData.isInlineEdit}">From {{summaryData.destination}}</div>
                  </div>
                  <div fxFlex="20">
                    <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50 mar-top-10': summaryData.isInlineEdit}" matInput [value]="summaryData.secondDirectionPeriod1" [(ngModel)]="summaryData.secondDirectionPeriod1">
                  </div>
                  <div fxFlex="20">
                    <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50 mar-top-10': summaryData.isInlineEdit}" matInput [value]="summaryData.secondDirectionPeriod2" [(ngModel)]="summaryData.secondDirectionPeriod2">
                  </div>
                  <div fxFlex="20">
                    <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50 mar-top-10': summaryData.isInlineEdit}" matInput [value]="summaryData.secondDirectionPeriod3" [(ngModel)]="summaryData.secondDirectionPeriod3">
                  </div>
                  <div fxFlex="20">
                    <input (blur)="onBlurSched(summaryData)" [ngClass]="{'inline-textbox width50 mar-top-10': summaryData.isInlineEdit}" matInput [value]="summaryData.secondDirectionPeriod4" [(ngModel)]="summaryData.secondDirectionPeriod4">
                  </div>
                </div>
              </ng-container>
            </div>
            
            <hr class="hrBg">
            <div>Other Transport Served</div>
            <div fxLayout="row wrap" class="padding-10"> 
              <div fxFlex="8" *ngFor="let line of transportServed">
                <div class="line-train" [ngClass]="line.mrtStationCode.substring(0, 2) | lowercase" matTooltip="{{line.mrtStationName}}">{{line.mrtStationCode}}</div>
              </div>
            </div> -->
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false" fxLayout="column" >
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="!panelOpenState">
              Detailed Schedule Timing
            </mat-panel-title>
            <mat-panel-title class="header-destination" fxLayoutAlign="end center" *ngIf="panelOpenState">
              <span class="textBlue">{{summaryData.origin}}</span>
              <span class="material-icons textBlue arrow-icon">arrow_right_alt</span> 
              <span class="textBlue">{{summaryData.destination}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngIf="panelOpenState" class="header-element" fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
            
            <!-- <div fxLayout="column" fxFlex="25">
              <div>Day Type</div>
              <mat-select [(value)]="selectedDay">
                <mat-option *ngFor="let day of days" [value]="day.dayTypeCode" (click)="daySelected(day.dayType)">
                  {{day.dayType | titlecase}}
                </mat-option>
              </mat-select>
            </div> -->

            <div class="mar-left-10" fxLayout="column" fxFlex="25">
              <div>Direction</div>
              <mat-select [(value)]="selectedDirection">
                <mat-option *ngFor="let direction of directions" [value]="direction.value" (click)="daySelected(direction.value)">
                  {{direction.viewValue}}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div fxLayout="column">
            <!-- <perfect-scrollbar #chatPS class="ps-sched-list-hori" [config]="config" [scrollIndicators]="true">
              
              <div fxLayout="row" class="mar-top-bot-2vh">
                <div class="hori-stop-header" *ngFor="let headerSched of scheduleData.header; let first = first; let i = index" fxLayout="column" [ngClass]="{'first-elem-header': first, 'hori-stop': !first}">
                  <span class="stop-count" *ngIf="!first">{{headerSched.stopNumber}}</span>
                  <span>{{headerSched.stopCode}}</span>
                </div> 
              </div>
              
              <div *ngFor="let schedData of scheduleData.scheduleData" fxLayout="row">
                <div class="first-elem-header inline-text" fxLayoutAlign="space-between center">
                  <span class="material-icons pointer">info</span>
                  <span class="material-icons pointer" matTooltip="Edit" matTooltipPosition="above" (click)="inlineEdit(schedData)" *ngIf="!schedData.isInlineEdit">edit</span>
                  <span class="material-icons pointer" matTooltip="Cancel" matTooltipPosition="above" (click)="inlineEdit(schedData)" *ngIf="schedData.isInlineEdit">cancel</span>
                </div>
              
                <ng-container *ngFor="let sched of schedData.schedule; let first = first">
                  <div class="hori-stop">
                    
                    <div fxFlex="100" class="inline-text" *ngIf="!schedData.isInlineEdit">
                      {{sched.time}}
                    </div>
              
                    <ng-container *ngIf="schedData.isInlineEdit">
                      <mat-form-field fxFlex="100" appearance="outline">
                        <input matInput [value]="sched.time">
                      </mat-form-field>
                    </ng-container>
                  </div>
                </ng-container>
                  
              </div>

            </perfect-scrollbar> -->
            <table border="0">
              <perfect-scrollbar #chatPS class="ps-sched-list-hori" [config]="config" [scrollIndicators]="true" 
              (psScrollRight)="onScrollRight($event)" (psScrollLeft)="onScrollRight($event)" (psYReachEnd)="onYReachEnd()"
              >
                <thead>
                  <tr>
                    <th class="td-sched"></th>
                    <th class="td-sched hori-stop-header" *ngFor="let headerSched of selectedServiceData.header; let first = first; let i = index">
                      <span class="stop-count">{{i+1}}</span>
                      <div>{{headerSched.stopCode}}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let schedData of selectedServiceData.scheduleData">
                    <td class="td-sched">
                      <span class="material-icons pointer" *ngIf="!schedData.isInlineEdit">info</span>
                      <span class="material-icons pointer" matTooltip="Edit" matTooltipPosition="above" (click)="inlineEdit(schedData)" *ngIf="!schedData.isInlineEdit">edit</span>
                      <span class="material-icons pointer" matTooltip="Cancel" matTooltipPosition="above" (click)="inlineEdit(schedData)" *ngIf="schedData.isInlineEdit">cancel</span>
                      <span class="material-icons pointer" matTooltip="Save" matTooltipPosition="above" (click)="onSaveSchedule(schedData)" *ngIf="schedData.isInlineEdit">save</span>
                    </td>
                    <td class="td-sched" *ngFor="let sched of schedData.stpSchedule; let first = first">
                      <!-- <span class="inline-text" *ngIf="!schedData.isInlineEdit">
                        {{sched.arrivalTime}}
                      </span> -->
                
                      <!-- <ng-container *ngIf="schedData.isInlineEdit"> -->
                        <!-- <mat-form-field appearance="outline"> -->
                          <ng-container *ngIf="schedData.isInlineEdit">
                            <input (blur)="onBlurSched(sched)" [ngClass]="{'inline-textbox': schedData.isInlineEdit}" matInput [value]="sched.arrivalTime" [(ngModel)]="sched.arrivalTime" [(ngModel)]="sched.deparureTime">
                          </ng-container>
                          <ng-container *ngIf="!schedData.isInlineEdit">
                            {{sched.arrivalTime}}
                          </ng-container>
                        <!-- </mat-form-field> -->
                      <!-- </ng-container> -->
                    </td>
                  </tr>
                </tbody>
              </perfect-scrollbar>
            </table>
            <!-- <div class="force-overflow"></div> -->
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div fxFlex="30">
      <div class="right-side" (mouseover)="onRightSideHover()">
        <div>Stops</div>
         <!-- <div fxLayout="column"> -->
          <div fxLayout="column" class="mar-top-bot-2vh vertical-sched">
            <perfect-scrollbar #chatPSVert class="ps-sched-list" [config]="configVert" [scrollIndicators]="true" 
            (psScrollUp)="onScrollUp($event)" (psScrollDown)="onScrollUp($event)"
            >
              <ng-container *ngFor="let headerSched of selectedServiceData.header; let first = first; let i = index">
                <div fxLayout="row" [ngClass]="{'first-elem-header': first, 'veri-stop circle1': !first}">
                  <span fxFlex="8" class="stop-count">{{i+1}}</span>
                  <!-- <span class="next-line-vertical" *ngIf="i > 0"></span> -->
                  <!-- <div fxLayout="row">
                    <span>1</span>
                    <span>2</span>
                  </div> -->
                  <div class="street-details" fxLayout="column" fxLayoutAlign="center start" >
                    <span class="stop-data">({{headerSched.stopCode}}) {{headerSched.stopName}}</span>
                    <span>{{headerSched.streetName}}</span>
                  </div>
                  <div class="train-lines">
                    <span [class]="line.substring(0, 2) | lowercase" *ngFor="let line of headerSched.mrtStationCode?.split(',')">{{line}}</span>
                  </div>
                </div>
              </ng-container> 
            </perfect-scrollbar>
          </div>
        <!-- </div> -->

      </div>
    </div>
  </div>
</div>