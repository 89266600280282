import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { freeTextMessageData } from '../others/data-types';
// import { Subject, Observable, Subscription } from 'rxjs';
// import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class StopManagementService {
  public allStops: any;
  public searchStreetStop: any;
  public allTrainStation: any;

  constructor(private httpClient: HttpClient) {}

  searchStopByStreetName(streetName) {
    let url =
      environment.apiServerUrl +
      'gtfsupload/v1/stops/getStopsByStreetName/' +
      streetName;

    return this.httpClient.post(url, '').pipe(
      map((data: any) => {
        if (data.length == 0) {
          // throw new Error('No record found');
          return [];
        } else {
          return data;
        }
      })
    );
  }

  getDirection(profile, stopPoints) {
    let url =
      environment.apiServerMapBoxUrl +
      profile +
      '/' +
      stopPoints +
      '?geometries=geojson&access_token=' +
      environment.map.mapBoxAccessToken;

    return this.httpClient.get(url).pipe(
      map((data: any) => {
        if (data.length == 0) {
          throw new Error('No record found');
        } else {
          return data;
        }
      })
    );
  }

  // async getDirection(profile, stopPoints) {
  //   let url = environment.apiServerMapBoxUrl + profile + '/' + stopPoints + '?geometries=geojson&access_token='+environment.map.mapBoxAccessToken;

  //   return await this.httpClient.get(url).pipe(
  //     map((data: any) => {
  //       if (data.length == 0) {
  //         throw new Error('No record found');
  //       } else {
  //         return data;
  //       }
  //     })
  //   );
  // }

  getDirection2(profile, stopPoints, excludes: string = null) {
    // var testDirection = [];
    var promise: any;
    var dataArr = [];
    var excluded = '';
    if (excludes !== null) {
      excluded = '&exclude=' + excludes;
    }
    stopPoints.forEach((element, index) => {
      // this.getDirection('driving', element).subscribe(
      //   (directionRes:any) => {
      //     console.log(directionRes);
      //     testDirection[index] = [];
      //     testDirection[index] = directionRes;
      //   }
      // )
      var url =
        environment.apiServerMapBoxUrl +
        profile +
        '/' +
        element +
        '?geometries=geojson' +
        excluded +
        '&overview=full&access_token=' +
        environment.map.mapBoxAccessToken;

      promise = new Promise((resolve, reject) => {
        this.httpClient.get(url).subscribe(
          (data: any) => {
            // setTimeout(() => {

            // resolve({'success':'true'});
            // console.log(data);
            dataArr[index] = data;
            resolve(dataArr);
            // console.log(index + ' = ' + element);
            // nextRequest()
            // }, 1000);
          },
          err => {
            resolve({ success: err });
          },
          () => console.log('done direction')
        );
      });
    });

    return promise;
  }

  getDirection3(profile, stopPoints) {
    var dataArr = [];
    var dataArr2;
    stopPoints.forEach((element, index) => {
      let url =
        environment.apiServerMapBoxUrl +
        profile +
        '/' +
        element +
        '?geometries=geojson&access_token=' +
        environment.map.mapBoxAccessToken;

      dataArr2 = this.httpClient.get(url).pipe(
        map((data: any) => {
          if (data.length == 0) {
            throw new Error('No record found');
          } else {
            dataArr[index] = data;
            return data;
          }
        })
      );
    });
    return dataArr2;
  }

  getDirectionSubs(profile, stopPoints) {
    var promise: any;
    var dataArr = [];
    stopPoints.forEach((element, index) => {
      // if(index < 2) {
      var url =
        environment.apiServerMapBoxUrl +
        profile +
        '/' +
        element +
        '?geometries=geojson&access_token=' +
        environment.map.mapBoxAccessToken;
      promise = new Promise((resolve, reject) => {
        this.httpClient.get(url).subscribe(
          data => {
            // setTimeout(() => {
            // resolve({'success':'true'});
            // console.log(data);
            resolve(dataArr);
            dataArr.push(data);
            console.log(index + ' = ' + element);
            // nextRequest()
            // }, 2000);
          },
          err => {
            resolve({ success: err });
          },
          () => console.log('done direction')
        );
      });
      // }
    });

    return promise;

    // let url = environment.apiServerMapBoxUrl + profile + '/' + stopPoints + '?geometries=geojson&access_token='+environment.map.mapBoxAccessToken;

    // return this.httpClient.get(url).pipe(
    //   map((data: any) => {
    //     if (data.length == 0) {
    //       throw new Error('No record found');
    //     } else {
    //       return data;
    //     }
    //   })
    // );
  }

  // async getDirectionSubs2(profile, stopPoints) {

  //   let generatedResponse = []
  //   await Promise.all(stopPoints.map(async (elem) => {
  //     try {
  //       // here candidate data is inserted into
  //       let insertResponse = await this.getDirection(profile, elem).subscribe(
  //         (directionRes:any) => {
  //           // console.log(directionRes);
  //           // and response need to be added into final response array
  //           generatedResponse.push(directionRes);
  //           // generatedResponse[idx] = directionRes;
  //         }
  //       )
  //     } catch (error) {
  //       console.log('error'+ error);
  //     }
  //   }))
  //   console.log('complete all') // gets loged first
  //   return generatedResponse // return without waiting for process of
  // }

  getRoutesByStopCode(stopId) {
    var stopData = { stopId: stopId };
    let url =
      environment.apiServerUrl + 'gtfsupload/v1/stops/getRoutesByStopCode';

    return this.httpClient.post(url, stopData).pipe(
      map((data: any) => {
        if (data.length == 0) {
          throw new Error('No record found');
        } else {
          return data;
        }
      })
    );
  }

  setServiceManagement(urlAction, data) {
    let url = environment.apiServerUrl + urlAction;

    return this.httpClient.post(url, data).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getSearchServiceRoute(routeId) {
    var url = environment.apiServerUrl + 'gtfsupload/v1/getServiceRoute';
    var routeData = { routeId: routeId };

    return this.httpClient.post(url, routeData).pipe(
      map((data: any) => {
        if (data == null) {
          // throw new Error('No record found');
          return [];
        } else {
          return data;
        }
      })
    );
  }

  setStop(data) {
    // const promise = new Promise(
    //   (resolve, reject) => {
    //     this.httpClient.post(environment.apiServerUrl + 'gtfsupload/v1/stops/addStop', setStop)
    //     .subscribe(
    //       (data:any) => {
    //         me.stopManagementService.allStops.push(newStop);
    //         me.closeModal();
    //     },
    //     err => console.log(err),
    //     () => console.log('done set stop')
    //   );
    // });

    var url = environment.apiServerUrl + 'gtfsupload/v1/stops/addStop';
    // var routeData = {'routeId': routeId};

    return this.httpClient.post(url, data).pipe(
      map((data: any) => {
        console.log(data);
        if (data == null) {
          // throw new Error('No record found');
          return [];
        } else {
          return data;
        }
      })
    );
  }

  getStopByCode(stopCode) {
    var url =
      environment.apiServerUrl +
      'gtfsupload/v1/stops/getStopDetailByCode/' +
      stopCode;

    return this.httpClient.post(url, '').pipe(
      map((data: any) => {
        if (data == null) {
          // throw new Error('No record found');
          return [];
        } else {
          return data;
        }
      })
    );
  }

  getMultipleStops(data) {
    var url =
      environment.apiServerUrl + 'gtfsupload/v2/stops/getStopDetailByCode';
    // var url = environment.apiServerUrl + 'gtfsupload/v3/stops/getStopDetailByCode';
    return this.httpClient.post(url, data).pipe(
      map((data: any) => {
        if (data == null) {
          // throw new Error('No record found');
          return [];
        } else {
          return data;
        }
      })
    );
  }
}
