<div class="stop-details cdkDrag">
  <div class="wrapper stop-wrapper">
    <div class="st-header">
      <div class="icon-header">
        <!-- <i class="fa fa-bus" (click)="showMinimize()"></i> -->
        <span class="icon" (click)="showMinimizeEstimate()">ETA</span>
        <!-- <i class="fa fa-times-circle" (click)="closeMessagePanel()"></i> -->
      </div>
      <div class="stop-content">
        <div class="icon-pin">{{ stop.pin }}</div>
        <div class="text-left stopDesc">
          {{
            stop.stopName.length >= 20
              ? (stop.stopName | slice: 0 : 20 | titlecase) + '...'
              : stop.stopName
          }}<span *ngIf="isBusStopCodeShow">({{ stop.stopCode }})</span>
        </div>
      </div>
    </div>

    <div class="st-body" [@slideUpDown]="isMinimize">
      <div class="body-wrapper">
        <div class="row">
          <div class="col">
            <div class="row stop-header">
              <div class="col no-padding row-time-table">Time Table</div>
              <div class="col no-padding row-actual-time">Actual Time</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <!-- <perfect-scrollbar class="ps" [config]="config"> -->
            <div class="row per-arrival" *ngFor="let row of arrivals">
              <div class="col time-table">
                {{ row.scheduledArrival }}
              </div>
              <div class="col low-padding">
                <div
                  class="actual-time"
                  [ngClass]="{
                    bgGreen: row.statusCode === '1',
                    'bgYellow textBlack': row.statusCode === '2',
                    bgRed: row.statusCode === '3'
                  }">
                  {{ row.actualArrival }}
                </div>
              </div>
            </div>
            <!-- </perfect-scrollbar> -->
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <!-- <div class="wrapper estimate-wrapper"> -->
    <!-- <div class="st-header">
      <div class="icon-header">
        <span (click)="showMinimizeEstimate()">ETA</span> -->
    <!-- <i class="fa fa-times-circle" (click)="closeMessagePanel()"></i> -->
    <!-- </div>
      <div>
        <div class="icon-pin visibility-hidden">{{stop.pin}}</div>
        <div class="text-left stopName">
          {{ (stop.stopName.length >= 17) ? ((stop.stopName | slice:0:17) | titlecase)+'...' : (stop.stopName) }}({{stop.stopCode}})
        </div>
      </div>
    </div> -->

    <div class="st-body" [@slideUpDown]="isMinimizeEstimate">
      <div class="body-wrapper">
        <div class="row no-margin stop-header">
          <div class="col-3 no-padding">Bus No.</div>
          <div class="col no-padding">Next Bus</div>
        </div>
        <div class="row">
          <div class="col">
            <!-- <perfect-scrollbar class="ps" [config]="config"> -->
            <!-- {{console.log(etaList)}} -->
            <!-- <ng-container *ngFor="let eta of etaList.currentRoute"> -->
            <ng-container *ngIf="etaList?.currentRoute.length > 0">
              <div class="row no-margin eta">
                <div class="col no-padding">
                  {{ etaList.currentRoute[0].routeName }}
                </div>
                <div
                  class="col no-padding {{ etaBus.Load }}"
                  *ngFor="
                    let etaBus of etaList.currentRoute;
                    let first = first
                  ">
                  <!-- {{etaBus.duration === 'NaN mins' ? '-' : etaBus.duration}} -->
                  <ng-container
                    *ngIf="
                      etaBus.duration === 'NaN mins' ||
                      etaBus.duration === undefined
                    "
                    >-</ng-container
                  >
                  <ng-container *ngIf="etaBus.duration !== 'NaN mins'">
                    <div class="bus-eta" *ngIf="etaBus.BusRegNo">
                      <small>({{ etaBus.BusRegNo }})</small>
                    </div>
                    {{ etaBus.duration }}
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="etaList?.currentRoute.length <= 0">
              <div class="row no-margin eta">
                <div class="col no-padding">-</div>

                <div class="col no-padding">-</div>
                <div class="col no-padding">-</div>
                <div class="col no-padding">-</div>
              </div>
            </ng-container>
            <!-- </ng-container> -->
            <div [@slideUpDown]="isSeeMore">
              <ng-container *ngIf="seeMoreData">
                <ng-container *ngFor="let eta of seeMore">
                  <!-- <ng-container *ngIf="stop.routeName !== eta[0].routeId"> -->
                  <div class="no-margin row eta">
                    <div class="col no-padding">
                      {{ eta[0].routeName }}
                    </div>
                    <div
                      class="col no-padding {{ etaBus.Load }}"
                      *ngFor="let etaBus of eta; let first = first">
                      <ng-container
                        *ngIf="
                          etaBus.duration === 'NaN mins' ||
                          etaBus.duration === undefined
                        "
                        >-</ng-container
                      >
                      <ng-container *ngIf="etaBus.duration !== 'NaN mins'">
                        <div class="bus-eta" *ngIf="etaBus.BusRegNo">
                          <small>({{ etaBus.BusRegNo }})</small>
                        </div>
                        {{ etaBus.duration }}
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!seeMoreData">
                <div class="no-margin row eta">
                  <div class="col no-padding">-</div>

                  <div class="col no-padding">-</div>
                  <div class="col no-padding">-</div>
                  <div class="col no-padding">-</div>
                </div>
              </ng-container>
            </div>

            <div class="row no-margin">
              <div class="col see-more text-right" (click)="showSeeMore()">
                see <span *ngIf="isSeeMore === 'out'">more</span
                ><span *ngIf="isSeeMore !== 'out'">less</span>
              </div>
            </div>

            <!-- </perfect-scrollbar> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
