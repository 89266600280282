<div class="info-modal">
  <h1 mat-dialog-title>{{dataDialog.header}}</h1>
  <div mat-dialog-content class="body-content">
    <div fxLayout="column" fxLayoutAlign="center start" class="mar-bot-10">
      <div fxLayout="row" fxLayoutAlign="start center" >
        <div class="mar-right-10 color-brown">Username:</div>
        <div>{{username | titlecase }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" >
        <div class="mar-right-10 color-brown" fxFlex="50">Role:</div>
        <div>{{scope | titlecase }}</div>
      </div>
      <!-- <div fxLayout="row" fxLayoutAlign="start center" >
        <div class="mar-right-10 color-brown">SIP Number:</div>
        <div>{{sipNumber === 'null' ? 'Not available (cannot make outgoing or incoming calls!)' : sipNumber}}</div>
      </div> -->
    </div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center" class="mar-top-10">
    <button mat-button (click)="onCloseModal()" cdkFocusInitial>Close</button>
  </div>
</div>