import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Inject,
  Injectable,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
// import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
declare var OSMBuildings: any;
import * as mapboxgl from 'mapbox-gl';
import { HttpClient } from '@angular/common/http';
// import { StopManagementService } from 'src/app/component/shared/services/stop-management.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StopManagementService } from 'src/app/component/shared/services/stop-management.service';
// import { SearchStopComponent } from '../search-stop/search-stop.component';

// @Injectable({ providedIn: 'root' })
declare var H: any;

@Component({
  selector: 'app-add-stop-modal',
  templateUrl: './add-stop-modal.component.html',
  styleUrls: ['./add-stop-modal.component.scss'],
})
export class AddStopModalComponent implements OnInit {
  // @Input() data:any;
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveAction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('btnClose') btnClose: ElementRef;
  public config: PerfectScrollbarConfigInterface = {};

  project = environment.project;
  // form:FormGroup = new FormGroup({
  //   trainStation: new FormControl()
  // });
  form: FormGroup;
  isvalid: boolean = false;
  // trainStation = [];
  OSMB: any;
  glJson: any;
  tileMap: any;
  map: any;
  newLatLon: any;
  running = false;
  // @ViewChild("VCMap") VCMap: ElementRef;

  // trainGroup: any[] = [
  // {
  //   name: 'North South Lone (NSL)',
  //   code: 'nsl',
  //   pokemon: [
  //     {value: 'ns21', checked: true, viewValue: '(NS21, DT11) Newtown'},
  //     {value: 'ns22', checked: true, viewValue: '(NS22) Orchard'},
  //     {value: 'ns23', checked: false, viewValue: '(NS23) Somerset'}
  //   ]
  // }
  // {
  //   name: 'Water',
  //   pokemon: [
  //     {value: 'squirtle-3', viewValue: 'Squirtle'},
  //     {value: 'psyduck-4', viewValue: 'Psyduck'},
  //     {value: 'horsea-5', viewValue: 'Horsea'}
  //   ]
  // },
  // {
  //   name: 'Fire',
  //   disabled: true,
  //   pokemon: [
  //     {value: 'charmander-6', viewValue: 'Charmander'},
  //     {value: 'vulpix-7', viewValue: 'Vulpix'},
  //     {value: 'flareon-8', viewValue: 'Flareon'}
  //   ]
  // }
  // ];
  allTrainStation: any[] = [];
  selectedTrainStation: any[] = [];

  //HERE Map
  platform: any;
  mapInstance: any;
  @ViewChild('mapHere', { static: true }) public mapElement: ElementRef;
  ui: any;
  stopData;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddStopModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private httpClient: HttpClient,
    public stopManagementService: StopManagementService,
    private _snackBar: MatSnackBar,
    private _commonService: CommonService
  ) {
    // this.data = dialogData;
    mapboxgl.accessToken = environment.map.mapBoxAccessToken;

    this.platform = new H.service.Platform({
      apikey: environment.hereApiKey,
    });

    this.form = this.formBuilder.group({
      streetName: ['', Validators.required],
      stopCode: ['', Validators.required],
      stopName: ['', Validators.required],
      trainStation: [''],
      lat: [
        '',
        [
          Validators.required,
          Validators.min(environment.map.boundingExtent.min[1]),
          Validators.max(environment.map.boundingExtent.max[1]),
          // Validators.pattern(
          //   /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{6,16})?))$/
          // ),
        ],
      ],
      lon: [
        '',
        [
          Validators.required,
          Validators.min(environment.map.boundingExtent.min[0]),
          Validators.max(environment.map.boundingExtent.max[0]),
          // Validators.pattern(
          //   /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{6,16})?))$/
          // ),
        ],
      ],
      busTerminal: [''],
    });
  }

  ngOnInit() {
    this.initStop();
    // this.initMapBox();
    // this.initMapOSMB();
  }

  // ngAfterViewInit() {
  //   // this.initHereMap();
  // }

  formWatcherSub;
  initFormWatcher() {
    if (this.formWatcherSub) this.formWatcherSub?.unsubscribe();
    this.formWatcherSub = this.form.controls['lat'].valueChanges.subscribe(
      value => {
        // console.log('form err', getFormValidationErrors(this.form));
        const lng = this.form.controls['lon'].value;
        const isValid =
          (this.form.controls['lat'].valid ?? false) &&
          (this.form.controls['lon'].valid ?? false) &&
          !isNaN(lng) &&
          !isNaN(value);
        // console.log('lat', isValid, value, this.form.controls['lat'].value);
        if (isValid) {
          // heremap
          this.hereMarker?.setGeometry({ lat: value, lng });
          this.mapInstance?.setCenter({ lat: value, lng });

          // mapbox
          this.mapboxMarker?.setLngLat([lng, value]);
          this.map?.panTo([lng, value], { duration: 1000 });
        }
      }
    );
    this.form.controls['lon'].valueChanges.subscribe(value => {
      const lat = this.form.controls['lat'].value;
      const isValid =
        (this.form.controls['lat'].valid ?? false) &&
        (this.form.controls['lon'].valid ?? false) &&
        !isNaN(lat) &&
        !isNaN(value);
      // console.log('lon', isValid, value);

      if (isValid) {
        // heremap
        this.hereMarker?.setGeometry({ lat, lng: value });
        this.mapInstance?.setCenter({ lat, lng: value });

        // mapbox
        this.mapboxMarker?.setLngLat([value, lat]);
        this.map?.panTo([value, lat], { duration: 1000 });
      }
    });
  }

  initBaguio() {
    if (this.dialogData.action === 'add') {
      this.stopData = {
        stops: [
          {
            stopLon: environment.map.map3dLocation[0],
            stopLat: environment.map.map3dLocation[1],
          },
        ],
      };
      // this.stopData.stops[0].stopLon = environment.map.map3dLocation[0];
      // this.stopData.stops[0].stopLat = environment.map.map3dLocation[1];
    }
    this.initMapBox();
    this.initMapboxMark();
    this.initFormWatcher();
  }

  initTrainStations() {
    var urlAction = 'gtfsupload/v1/stops/getMRTStationList';
    this._commonService
      .commonPostAction(urlAction, '')
      .subscribe((dataRes: any) => {
        this.allTrainStation = dataRes;
        var allStation: any = [];
        // this.allTrainStation = this.data.allTrainStation;
        // if(this.data.allTrainStation){
        this.allTrainStation.forEach(element => {
          element.stations.forEach(station => {
            allStation.push(station);
          });
        });
        // }

        if (
          this.dialogData.action === 'edit' &&
          this.stopData.stops[0].mrtStationCode
        ) {
          // var stationCode = this.data.stationCode.split('\',');
          var stationCode = this.stopData.stops[0].mrtStationCode.split(';');
          stationCode.forEach(element => {
            // let findStation = allStation.find(x => x.stnCode === element.replace(/'/g, ''));
            let findStation = allStation.find(x =>
              x.stnCode.includes(element.trim())
            );

            if (findStation) {
              this.selectedTrainStation.push(findStation);
            }
          });
        } else if (this.dialogData.action === 'add') {
          this.stopData = {
            stops: [
              {
                stopLon: environment.map.map3dLocation[0],
                stopLat: environment.map.map3dLocation[1],
              },
            ],
          };
          // this.stopData.stops[0].stopLon = environment.map.map3dLocation[0];
          // this.stopData.stops[0].stopLat = environment.map.map3dLocation[1];
        }
        this.initMapBox();
        this.initMapboxMark();
        this.initFormWatcher();
      });
  }

  compareWithFunc(a, b) {
    return a.stnCode === b.stnCode;
  }

  initStop() {
    var urlAction =
      'gtfsupload/v1/stops/getStopDetail/' + this.dialogData.stopCode;
    this._commonService
      .commonPostAction(urlAction, '')
      .subscribe((data: any) => {
        this.stopData = data[0];
        if (environment.project === 'baguio') {
          this.initBaguio();
        } else {
          this.initTrainStations();
        }
        this.initForm();
      });
  }

  initForm() {
    // this.trainStation = [
    //   {
    //       "listCode": "DIS",
    //       "listName": "DISMISSED",
    //       "listDesc": "DRIVER_STATUS"
    //   },
    //   {
    //       "listCode": "LVE",
    //       "listName": "ON-LEAVE",
    //       "listDesc": "DRIVER_STATUS"
    //   }
    // ]
    if (this.dialogData.action === 'edit') {
      this.formReset();
    }
  }

  // mapbox marker event handler
  mapboxMarker;
  initMapboxMark() {
    var me = this;

    const formLon = this.form.controls['lon'].value;
    const formLat = this.form.controls['lat'].value;

    this.mapboxMarker = new mapboxgl.Marker({
      draggable: true,
      // element: `assets/maps/icons/marker-green.png`
      color: 'green',
    })
      .setLngLat([
        formLon > 0 ? formLon : this.stopData.stops[0].stopLon,
        formLat > 0 ? formLat : this.stopData.stops[0].stopLat,
      ])
      .addTo(this.map);

    const marker = this.mapboxMarker;

    function onDragEnd() {
      var lngLat = marker.getLngLat();
      me.form.patchValue({
        lat: lngLat.lat,
        lon: lngLat.lng,
      });
    }

    marker.on('dragend', onDragEnd);
  }

  initMapBox() {
    var me = this;
    const formLon = this.form.controls['lon'].value;
    const formLat = this.form.controls['lat'].value;
    this.map = new mapboxgl.Map({
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [
        formLon > 0 ? formLon : this.stopData.stops[0].stopLon,
        formLat > 0 ? formLat : this.stopData.stops[0].stopLat,
      ],
      zoom: 17,
      pitch: 45,
      bearing: 0,
      container: 'edit-map',
      antialias: true,
      attributionControl: false,
    });

    this.map.on('load', function () {
      // Insert the layer beneath any symbol layer.
      var layers = me.map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      me.map.addLayer(
        {
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
            'fill-extrusion-color': '#aaa',

            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              16,
              ['get', 'height'],
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              16,
              ['get', 'min_height'],
            ],
            'fill-extrusion-opacity': 0.6,
          },
        },
        labelLayerId
      );
    });
    setTimeout(() => {
      this.map.resize();
    }, 10);
  }

  // initMapOSMB() {
  //   console.log('map OSMB');

  //   this.OSMB = new OSMBuildings({
  //     container: 'edit-map',
  //     position: { latitude: this.data.lat, longitude: this.data.lon },
  //     zoom: 16,
  //     minZoom: 15,
  //     maxZoom: 20,
  //     tilt: 40,
  //     rotation: 0,
  //     effects: ['shadows']
  //   });

  //   this.OSMB.addMapTiles('https://api.mapbox.com/styles/v1/osmbuildings/cjt9gq35s09051fo7urho3m0f/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3NtYnVpbGRpbmdzIiwiYSI6IjNldU0tNDAifQ.c5EU_3V8b87xO24tuWil0w');
  //   this.OSMB.addGeoJSONTiles('https://{s}.data.osmbuildings.org/0.2/anonymous/tile/{z}/{x}/{y}.json');

  // }

  formReset() {
    console.log('stop data: ', this.stopData);
    var latLonRegEx =
      '^(+|-)?(?:90(?:(?:.0{6,16})?)|(?:[0-9]|[1-8][0-9])(?:(?:.[0-9]{6,16})?))$';
    this.isvalid = false;
    this.form.patchValue({
      streetName: this.stopData.streetName,
      stopCode: this.stopData.stops[0].stopCode,
      stopName: this.stopData.stops[0].stopName,
      trainStation: this.selectedTrainStation,
      lat: this.stopData.stops[0].stopLat,
      lon: this.stopData.stops[0].stopLon,
      busTerminal: this.stopData.stops[0].busTerminal,
    });

    // this.form = this.formBuilder.group({
    //   streetName: [this.stopData.streetName, Validators.required],
    //   stopCode: [this.stopData.stops[0].stopCode, Validators.required],
    //   stopName: [this.stopData.stops[0].stopName, Validators.required],
    //   trainStation: [this.selectedTrainStation], //[this.stopData.stops[0].trainStation],
    //   lat: [
    //     this.stopData.stops[0].stopLat,
    //     [
    //       Validators.required,
    //       Validators.min(environment.map.boundingExtent.min[1]),
    //       Validators.max(environment.map.boundingExtent.max[1]),
    //       // Validators.pattern(
    //       //   /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{6,16})?))$/
    //       // ),
    //     ],
    //   ],
    //   lon: [
    //     this.stopData.stops[0].stopLon,
    //     [
    //       Validators.required,
    //       Validators.min(environment.map.boundingExtent.min[0]),
    //       Validators.max(environment.map.boundingExtent.max[0]),
    //       // Validators.pattern(
    //       //   /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{6,16})?))$/
    //       // ),
    //     ],
    //   ],
    //   busTerminal: [this.stopData.stops[0].busTerminal],
    // });
  }

  async saveStop() {
    if (!this.form.valid) {
      return false;
    }
    this.running = true;
    this.dialogRef.disableClose = true;
    // if action is add, check if stop code already exists
    if (this.dialogData.action === 'add') {
      const currStopCode = this.form.value.stopCode;
      const stopCodeRes = await this.stopManagementService
        .getStopByCode(this.form.value.stopCode)
        .toPromise();

      const existingStop =
        stopCodeRes?.find(street => {
          const { stops } = street;
          const stopCodeInStreet =
            stops?.find(stop => stop?.stopCode === currStopCode) ?? undefined;
          return stopCodeInStreet;
        }) ?? undefined;
      console.log('Add Stop: ', existingStop);
      if (existingStop) {
        // const { stops: existingStops, streetName } = existingStop || {};
        let message = `Error adding stop. Stop code ${currStopCode} already exists.`;
        this._snackBar.open(message, null, { duration: 5000 });
        this.running = false;
        this.dialogRef.disableClose = false;
        return;
      }
    }

    let arrStationCode = [];
    let stationCode = '';
    let stationName = '';
    let trainStation = this.form.value.trainStation ?? [];

    if (trainStation?.length > 0) {
      trainStation.forEach(element => {
        arrStationCode.push(element.stnCode);
        // stationCode += "\'"+element.stnCode + "\',";
        stationCode += element.stnCode + ';';
        stationName += element.stnName + ';';
      });
    }

    // console.log(stationCode.slice(0,-1));
    // console.log(stationName);
    // console.log(stationName.slice(0,-1));

    // return false;
    var setStop: any = {
      stopId: this.form.value.stopCode,
      stopName: this.form.value.stopName,
      stopDesc: this.form.value.stopName,
      streetName: this.form.value.streetName,
      stopStatus: '1',
      stopLat: this.form.value.lat,
      stopLon: this.form.value.lon,
      locationType: '',
      parentStation: '', //this.form.value.trainStation.toString(),
      busTerminal: this.form.value.busTerminal,

      ...(environment.project != 'baguio' && {
        mrtStationCode: stationCode.slice(0, -1),
        mrtStationName: stationName.slice(0, -1),
      }),
    };
    var newStop: any = {
      stopCode: this.form.value.stopCode, //needs to change because API different from list and update
      stopName: this.form.value.stopName,
      stopDesc: this.form.value.stopName,
      streetName: this.form.value.streetName,
      stopStatus: '1',
      stopLat: this.form.value.lat,
      stopLon: this.form.value.lon,
      locationType: '',
      parentStation: '', //this.form.value.trainStation.toString(),
      busTerminal: this.form.value.busTerminal,

      ...(environment.project != 'baguio' && {
        mrtStationCode: stationCode.slice(0, -1),
        mrtStationName: stationName.slice(0, -1),
      }),
    };

    // ready to send to parent
    this.dialogData = {
      stopCode: this.form.value.stopCode,
      stopName: this.form.value.stopName,
      stopDesc: this.form.value.stopDesc,
      streetName: this.form.value.streetName,
      stopStatus: '1',
      stopLat: this.form.value.lat,
      stopLon: this.form.value.lon,
      locationType: '',
      parentStation: this.selectedTrainStation,
      busTerminal: this.form.value.busTerminal,

      ...(environment.project != 'baguio' && {
        mrtStationCode: stationCode.slice(0, -1),
        mrtStationName: stationName.slice(0, -1),
      }),
      message: this.dialogData.message,
      action: this.dialogData.action,
    };

    // console.log(newStop);
    // console.log(setStop);

    // console.log('Submit: ', this.dialogData.action, setStop, newStop);
    if (this.dialogData.action === 'add') {
      this.dialogData = setStop;
      this.addStop(setStop, newStop);
    } else {
      newStop.message = this.dialogData.message;
      this.dialogData = newStop;
      this.editStop(setStop, newStop);
    }
    // return promise;
    // if(stationCode){
    //   var allStation:any = [];

    //   if(this.data.allTrainStation){
    //     this.data.allTrainStation.forEach(element => {
    //       element.stations.forEach(station => {
    //         allStation.push(station);
    //       });
    //     });
    //   }
    //   console.log(allStation)
    //   var stationCode2 = stationCode.slice(0,-1).split(',');
    //   console.log(stationCode2)

    //   stationCode2.forEach(element => {
    //     // let findStation = allStation.find(x => x.stnCode === element.replace(/'/g, ''));
    //     let findStation = allStation.find(x => x.stnCode.includes(element.replace(/'/g, '').trim()));
    //     console.log(element.replace(/'/g, '').trim())

    //     if(findStation) {
    //       this.selectedTrainStation.push(findStation);
    //     }
    //   });
    // }
  }

  editStop(setStop, newStop) {
    var stopData = [];
    stopData.push(setStop);
    var me = this;

    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.apiServerUrl + 'gtfsupload/v1/stops/updateStopDetail',
          stopData
        )
        .subscribe(
          (data: any) => {
            // me.data.allStops.forEach((element,index) => {
            //   if(element.stopCode === newStop.stopCode) {
            // console.log(element);
            // element = newStop;
            //     me.data.allStops.splice(me.data.allStops.indexOf(element), 1);
            //     me.data.allStops.push(newStop);
            //   }
            // });
            me.closeModal();
            this._snackBar.open('Stop data updated!', null, {
              duration: 2000,
            });
            this.running = false;
            this.dialogRef.disableClose = false;
          },
          err => {
            console.log(err);
            this.running = false;
            this.dialogRef.disableClose = false;
            this._snackBar.open(
              err.statusText + ', please try again later.',
              null,
              {
                duration: 2000,
              }
            );
          },
          () => console.log('done edit stop')
        );
    });
  }

  addStop(setStop, newStop) {
    var me = this;
    var stopData = [];
    stopData.push(setStop);
    // me.closeModal();
    // this._snackBar.open('Stop data added!', null, {
    //   duration: 2000,
    // });
    // return false;
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.apiServerUrl + 'gtfsupload/v1/stops/addStop',
          stopData
        )
        .subscribe(
          (data: any) => {
            // let isStopExist = me.data.allStops.find(stop => stop.stopCode === newStop.stopCode);
            // if(Object.keys(isStopExist).length === 0) {
            // if(isStopExist === undefined) {
            //   me.data.allStops.push(newStop);
            // }
            me.closeModal();
            this._snackBar.open('Stop data added!', null, {
              duration: 2000,
            });
          },
          err => console.log(err),
          () => {
            this.running = false;
            this.dialogRef.disableClose = false;
            console.log('done set stop');
          }
        );
    });
  }

  closeModal() {
    this.dialogRef.close(this.dialogData);
  }

  onCloseModal() {
    if (this.dialogData.action === 'edit') {
      this.formReset();
    }

    this.dialogData.stopLat = this.form.value.lat;
    this.dialogData.stopLon = this.form.value.lon;
    this.dialogData.stopName = this.form.value.stopName;
    this.dialogRef.close(this.dialogData);
    return false;
  }

  initHereMap() {
    var hereData = this.stopData.stops[0];
    console.log(hereData);
    // let service = this.platform.getOMVService();
    // service.getUrl().setQuery({
    //   datasets: '(-buildings)',
    //   apikey: environment.hereApiKey
    // });

    // let tileLayer = new H.map.layer.TileLayer(
    //     // new H.service.omv.Provider(service, new H.map.Style('https://js.api.here.com/v3/3.1/styles/omv/oslo/japan/normal.day.yaml')), {max: 22}
    //     new H.service.omv.Provider(service, new H.map.Style('https://js.api.here.com/v3/3.1/styles/omv/miami/normal.day.yaml')), {max: 22}
    // );

    var pixelRatio = window.devicePixelRatio || 1;
    // var defaultLayers2 = this.platform.createDefaultLayers({
    //     tileSize: pixelRatio === 1 ? 256 : 512,
    //     ppi: pixelRatio === 1 ? undefined : 320
    // });
    var defaultLayers = this.platform.createDefaultLayers();

    //Step 2: initialize a map
    const formLon = this.form.controls['lon'].value;
    const formLat = this.form.controls['lat'].value;

    this.mapInstance = new H.Map(
      this.mapElement.nativeElement,
      // document.getElementById('mapHere'),
      defaultLayers.vector.normal.map,
      // defaultLayers.raster.normal.map,
      {
        center: {
          lat: formLat > 0 ? formLat : hereData.stopLat,
          lng: formLon > 0 ? formLon : hereData.stopLon,
        },
        zoom: 18,
        pixelRatio: pixelRatio,
        padding: { top: 50, left: 70, bottom: 50, right: 70 },
      }
    );

    // adjust tilt and rotation of the map
    this.mapInstance.getViewModel().setLookAtData({
      tilt: 45,
      // heading: 60
    });

    // this.mapInstance.setBaseLayer(tileLayer);

    window.addEventListener('resize', () =>
      this.mapInstance.getViewPort().resize()
    );
    var behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(this.mapInstance)
    );
    this.ui = H.ui.UI.createDefault(this.mapInstance, defaultLayers);
    this.ui.removeControl('mapsettings');
    this.initHereMark(this.mapInstance, behavior);
  }

  tabChanged(event) {
    if (event.index === 0) {
      this.initMapBox();
      this.initMapboxMark();
    } else if (!this.mapInstance) {
      this.initHereMap();
    }
  }

  // init here map marker
  hereMarker;
  initHereMark(map, behavior) {
    var me = this;
    var iconImage = new H.map.Icon('assets/maps/icons/marker-green.png');

    const formLon = this.form.controls['lon'].value;
    const formLat = this.form.controls['lat'].value;

    this.hereMarker = new H.map.Marker(
      {
        lat: formLat > 0 ? formLat : this.stopData.stops[0].stopLat,
        lng: formLon > 0 ? formLon : this.stopData.stops[0].stopLon,
      },
      {
        // mark the object as volatile for the smooth dragging
        volatility: true,
        icon: iconImage,
      }
    );

    // Ensure that the marker can receive drag events
    this.hereMarker.draggable = true;
    map.addObject(this.hereMarker);

    // var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.mapInstance));
    map.addEventListener(
      'dragstart',
      function (ev) {
        var target = ev.target,
          pointer = ev.currentPointer;
        // console.log(target.getGeometry());

        if (target instanceof H.map.Marker) {
          var targetPosition = map.geoToScreen(target.getGeometry());
          target['offset'] = new H.math.Point(
            pointer.viewportX - targetPosition.x,
            pointer.viewportY - targetPosition.y
          );
          behavior.disable();
        }
      },
      false
    );

    map.addEventListener(
      'dragend',
      function (ev) {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
          behavior.enable();
          me.form.patchValue({
            lat: target.getGeometry().lat,
            lon: target.getGeometry().lng,
          });
        }
      },
      false
    );

    map.addEventListener(
      'drag',
      function (ev) {
        var target = ev.target,
          pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          target.setGeometry(
            map.screenToGeo(
              pointer.viewportX - target['offset'].x,
              pointer.viewportY - target['offset'].y
            )
          );
        }
      },
      false
    );
  }

  // ngOnDestroy() {}
}
