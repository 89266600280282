import { Component, Input, OnInit } from '@angular/core';
import { WebSocketSubject } from 'rxjs/observable/dom/WebSocketSubject';

import { mqttData, mqttSendData, predefinedMessageData, offRouteData } from '../../../shared/others/data-types';
import { MqttType } from 'src/app/component/shared/others/constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-ladder',
  templateUrl: './single-ladder.component.html',
  styleUrls: ['./single-ladder.component.scss']
})
export class SingleLadderComponent implements OnInit {

  //subscription
  private $vehiclePos: WebSocketSubject<any>;
  @Input() stopData;
  direction1 = null;
  direction2 = null;
  stop1Percentage: number = 0;
  stop2Percentage: number = 0;

  constructor() { }

  ngOnInit() {
    console.log(this.stopData);
    this.listenVehiclePosition();
    var data = {
      type: MqttType.eVehiclePosition,
      busRegNo: '',
      service: this.stopData.service
    }
    this.mqttRequest(data);
    this.assignDirection();
  }

  listenVehiclePosition() {
    this.$vehiclePos = new WebSocketSubject(environment.nodeUrlWs);
    this.$vehiclePos.subscribe(
      (returnData) => {
          let retStr = returnData.data.toString().replace('[', '').replace(']', '').split(',');
          console.log(retStr);
      }
    )
  }

  mqttRequest(data): void {
    const mqttSend:mqttSendData = {
      type: data.type,
      busRegNo: data.busRegNo === undefined ? '' : data.busRegNo,
      service: data.service === undefined ? '' : data.service
    }
    const message = new mqttSendData(mqttSend.type, mqttSend.service, mqttSend.busRegNo);
    
    if(MqttType.eVehiclePosition === mqttSend.type) {
      this.$vehiclePos.next(message);
    }
  }

  assignDirection() {
    this.stopData.directionData.forEach(element => {
      if(element.direction === '1') {
        this.direction1 = element.stops;
      }
      else if(element.direction === '2') {
        this.direction2 = element.stops;
      }
    });
    
    console.log(this.direction2);
    this.stop1Percentage = 87 / (this.direction1.length-1);
    if(this.direction2) {
      this.stop2Percentage = 87 / (this.direction2.length-1);
    }
  }
}
