<div class="settings-group">
  <div class="container">
    <div class="row">
      <div class="col-3">
        <ul class="list-group">
          <li class="list-group-item" [routerLinkActive]="['active']" [routerLink]="['ladder']">Ladder</li>
          <li class="list-group-item" [routerLinkActive]="['active']" [routerLink]="['map']">Map</li>
          <li class="list-group-item">Morbi leo risus</li>
          <li class="list-group-item">Porta ac consectetur ac</li>
          <li class="list-group-item">Vestibulum at eros</li>
        </ul>
      </div>
      <div class="">
        <!-- <app-settings-ladder></app-settings-ladder> -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<!-- <div id="div1" style="width: 100px; height: 100px; top:0; left:0; background:#777; position:absolute;"></div>
<div id="div2" style="width: 100px; height: 100px; top:300px; left:300px; background:#333; position:absolute;"></div>
<svg width="500" height="500"><line x1="50" y1="50" x2="350" y2="350" stroke="black"/></svg> -->