import { Component, OnInit } from '@angular/core';
import { DataManagementService } from 'src/app/component/shared/services/data-management.service';
import { MapService } from 'src/app/component/shared/services/map.service';

//start map
import Map from 'ol/Map';
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import { Vector as VectorLayer } from 'ol/layer.js';
import View from 'ol/View';
import { fromLonLat, toLonLat } from 'ol/proj';
import { transform } from 'ol/proj.js';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { Vector as VectorSource } from 'ol/source.js';
import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text,
} from 'ol/style.js';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate';
import TileJSON from 'ol/source/TileJSON.js';
import { PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';
import { ActivatedRoute } from '@angular/router';
//end map

declare var $: any;

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss'],
})
export class RoutesComponent implements OnInit {
  objectKeys = Object.keys;
  trips: any = {};
  isTrip: boolean = false;

  //start map
  map: Map;
  source: XYZ;
  OSMLayer: TileLayer<any>;
  mapLayer: VectorLayer<any>[] = [];
  mapList = [];
  marker;
  vienna: Overlay;
  pTitle;
  mapInstance = [];
  prevPopup;
  //end map

  //crud trip
  trip;
  isDisplayModal: boolean = false;
  headerModal: string = '';
  //end crud

  isGetAllRoutes: boolean = false;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private dmService: DataManagementService,
    private mapService: MapService
  ) {}

  ngOnInit() {
    const page = 1;
    this.dmService.getTripByRoutes(page).then((ret: any) => {
      this.isTrip = true;
      this.trips = ret;
      this.isGetAllRoutes = true;

      var keys = Object.keys(this.trips);

      this.initMap(this.trips[keys[0]], keys[0]);
    });
  }

  initMap(data, index) {
    const mapId = 'map-trip-' + index;
    let markList = [];

    var bus1Style = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ {
          anchor: [0.5, 30],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'assets/images/icon/map-marker-blue.png',
        }
      ),
    });

    for (const direction in data) {
      for (const row of data[direction]) {
        let mark = new Feature({
          type: 'click',
          geometry: new Point(
            transform(
              [parseFloat(row.busStopLon), parseFloat(row.busStopLat)],
              'EPSG:4326',
              'EPSG:3857'
            )
          ),
          name: row.busStopName,
        });
        mark.setStyle(bus1Style);
        markList.push(mark);
      }
    }

    $(this.prevPopup).popover('dispose');
    let element = document.getElementById('popup-' + index);

    if (this.mapList.indexOf(mapId) < 0) {
      setTimeout(() => {
        let vectorLayer = new VectorLayer({
          source: new VectorSource({
            features: markList,
          }),
        });
        vectorLayer.setVisible(true);

        this.map = new Map({
          target: mapId,
          layers: [
            new TileLayer({
              source: new XYZ({
                url: 'http://tile.osm.org/{z}/{x}/{y}.png',
                crossOrigin: '',
                // url: 'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}'
              }),
            }),
            vectorLayer,
          ],
          view: new View({
            // center: fromLonLat([event.stopLon, event.stopLat]),
            center: [0, 0], //fromLonLat([data[1][0]['busStopLon'], data[1][0]['busStopLat']]),
            zoom: 12.5,
            minZoom: 8,
            maxZoom: 16,
          }),
        });
        this.mapList.push(mapId);
        this.mapInstance.push(this.map);

        let popup = new Overlay({
          element: element,
          positioning: 'bottom-center',
          stopEvent: false,
          offset: [0, -30],
        });
        this.map.addOverlay(popup);

        this.map.on('click', evt => {
          $(this.prevPopup).popover('dispose');
          this.prevPopup = element;

          let feature = this.map.forEachFeatureAtPixel(
            evt.pixel,
            function (feature) {
              return feature;
            }
          );
          if (feature) {
            let geometry = feature.getGeometry();
            if (geometry instanceof Point) {
              let coordinates = geometry.getCoordinates();
              popup.setPosition(coordinates);
              $(element).popover({
                placement: 'top',
                html: true,
                content: feature.get('name'),
              });
              $(element).popover('show');
            }
          } else {
            $(this.prevPopup).popover('dispose');
          }
        });

        // change mouse cursor when over marker
        this.map.on('pointermove', e => {
          if (e.dragging) {
            $(this.prevPopup).popover('dispose');
            return;
          }
          var pixel = this.map.getEventPixel(e.originalEvent);
          var hit = this.map.hasFeatureAtPixel(pixel);
          this.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
        });
      }, 200);
    } //end if
    else {
      let existingMap = 'map-trip-' + index;
      let existingMapInstance;

      for (const map of this.mapInstance) {
        let target = map.values_.target;
        if (target === existingMap) {
          existingMapInstance = map;
        }
      }

      let popup = new Overlay({
        element: element,
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -30],
      });
      existingMapInstance.addOverlay(popup);

      existingMapInstance.on('click', evt => {
        $(element).popover('dispose');
        let feature = existingMapInstance.forEachFeatureAtPixel(
          evt.pixel,
          function (feature) {
            return feature;
          }
        );
        if (feature) {
          let coordinates = feature.getGeometry().getCoordinates();
          popup.setPosition(coordinates);
          $(element).popover({
            placement: 'top',
            html: true,
            content: feature.get('name'),
          });
          $(element).popover('show');
        } else {
          $(element).popover('dispose');
        }
      });

      // change mouse cursor when over marker
      existingMapInstance.on('pointermove', e => {
        if (e.dragging) {
          $(element).popover('dispose');
          return;
        }
        var pixel = existingMapInstance.getEventPixel(e.originalEvent);
        var hit = existingMapInstance.hasFeatureAtPixel(pixel);
        document.getElementById(existingMapInstance.getTarget()).style.cursor =
          hit ? 'pointer' : '';
      });
    }
  }

  onAddTrip() {
    this.trip = [];
    this.headerModal = 'Add Trip';
    this.isDisplayModal = true;
  }

  onEditTrip(data) {
    this.trip = data;
    this.headerModal = 'Edit Trip';
    this.isDisplayModal = true;
  }

  ngAfterViewInit() {}

  onPaginate(event) {
    const page = event.page + 1;
    this.dmService.getTripByRoutes(page).then((ret: any) => {
      this.isTrip = true;
      this.trips = ret;
    });
  }
}
