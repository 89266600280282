import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface MessageElement {
  time: string
  from: string;
  to: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiVVNFUl9DTElFTlRfUkVTT1VSQ0UiLCJVU0VSX0FETUlOX1JFU09VUkNFIl0sInVzZXJfbmFtZSI6ImFkbWluIiwic2NvcGUiOlsiYWRtaW4iXSwiZXhwIjoxNTc0MTU4Mjg5LCJhdXRob3JpdGllcyI6WyJhZG1pbiIsImNhbl91cGRhdGVfdXNlciIsImNhbl9yZWFkX3VzZXIiLCJjYW5fY3JlYXRlX3VzZXIiLCJjYW5fZGVsZXRlX3VzZXIiXSwianRpIjoiYjYzZjdkNmEtYjdmMy00YTYwLWIyNTItMTE5OTI1MThiMTIxIiwiZW1haWwiOiJhZG1pbkBtYWlsLmNvbSIsImNsaWVudF9pZCI6IlVTRVJfQ0xJRU5UX0FQUCIsInVzZXJuYW1lIjoiYWRtaW4ifQ.CeuYRhLdj8E-SO6d-vtf9OjnJ1yFtl1tixkpXRbmnrhpgJID5Hh-jeDe4VPzQj1-yDwHlpbDzqO1N2_aGyEId00jHORza3AvnT2rbyK_wzC4JDQKVfYsWm4bVnrgAKjsCNcEvhKJ_FFC4pFE53iScr597WQRfHbQv845AuJZqwizl8B9dJz3_DJTzAaDlX4Q_aSs9u2lq-_maksNs-uqNyvuwJyRedwr5SZohQc8dD4wpCcD0ojZofDasy7c1hw5Mf2spqDAKSE5VAO5-0BmIBmFNhMkyt_A3z-X5d7HLlTD92Iq1CzafJOFrJuNC1gRfOuCWRv9Cjb4y9Tn03eFBQ'
  //   })
  // };

  constructor(private httpClient: HttpClient) { }

  findMsg(searchBy: string, keyword: string) {
    let busRegNo = '';
    let tripId = '';
    let routeId = '';
    let msgSource = '';
    let msgDate = '';

    if (searchBy === 'findByBusRegNo') {
      busRegNo = keyword;
    } else if (searchBy === 'findByMsgSource') {
      tripId = keyword;
    } else if (searchBy === 'findByRouteId') {
      routeId = keyword;
    } else if (searchBy === 'findByTripId') {
      msgSource = keyword;
    } else {
      msgDate = keyword;
    }
    
    let body = {
      busRegNo: busRegNo,
      tripId: tripId,
      routeId: routeId,
      msgSource: msgSource,
      msgDate: msgDate
    };

    return this.httpClient.post(environment.apiServerUrl +'operatormessage/' + searchBy, body);
  }

}
