<div class="bus-details-group {{ location }}">
  <div class="wrapper">
    <div
      class="bd-header"
      [ngClass]="{
        bgGreen: bus.status === '0',
        'bgYellow textBlack': bus.status === '-1',
        bgRed: bus.status === '1',
        bgGray: bus.status === '2',
        bgOrange: bus.status === '3'
      }">
      <!-- <div class="col"> -->
      <!-- <div class="icon-pin">{{bus.pin}}</div> -->
      <span class="bus-code" (click)="zoomBusLocation()">{{
        bus.busRegNo
      }}</span>
      <div
        class="icon-cancel"
        (click)="removeBus(data.key, bus.busRegNo, '', '')"></div>
      <!-- </div> -->
    </div>
    <div class="bd-body">
      <!-- <div class="container"> -->
      <div class="row no-margin">
        <div class="col-4 no-padding">
          <div
            class="bus-route text-center route-name"
            *ngIf="environment.home.busDetailIsRouteName">
            {{ bus.routeName }}
          </div>
          <div
            class="bus-route text-center"
            *ngIf="!environment.home.busDetailIsRouteName">
            {{ bus.routeId }}
          </div>
        </div>
        <div class="col no-padding">
          <div class="bus-status bgGreen" *ngIf="bus.status === '0'">
            On time
          </div>
          <div
            class="bus-status bgYellow textBlack"
            *ngIf="bus.status === '-1'">
            Early
          </div>
          <div class="bus-status bgRed" *ngIf="bus.status === '1'">Late</div>
          <div class="bus-status bgGray" *ngIf="bus.status === '2'">
            Not on trip
          </div>
          <div class="bus-status bgOrange" *ngIf="bus.status === '3'">
            Off Route
          </div>
        </div>
        <div
          class="col-2 no-padding"
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <!-- <div class="icon icon-location"></div> -->
          <div>
            <div
              class="icon icon-phone"
              (click)="callJitsi()"
              matTooltip="Start Jitsi Call"></div>
            <!-- <div class="icon icon-phone" (click)="showVoip()" matTooltip="Start VoIP Call"></div> -->
            <div
              class="icon icon-msg"
              (click)="showMessageList()"
              matTooltip="Show messages list"></div>
          </div>
          <!-- <span class="material-icons pointer" (click)="bus.isShowChart = !bus.isShowChart">
              insert_chart_outlined
            </span> -->
        </div>
        <!-- <div class="col"></div> -->
      </div>

      <!-- image behavior -->
      <!-- <div class="row no-margin" *ngIf="bus.isShowChart">
          <div class="behavior-monitoring"></div>
        </div> -->

      <div class="row no-margin">
        <div class="col-5 no-padding">
          <div class="">
            <div class="color-brown">Trip Id:</div>
            <div>{{ bus.trip }}</div>
          </div>
        </div>
        <div class="col no-padding">
          <div class="">
            <div>
              <span class="color-brown">Driver Name:</span> ({{ bus.driverId }})
            </div>
            <div>{{ driverData.driverName }}</div>
          </div>
        </div>
      </div>
      <div class="row no-margin">
        <div class="col-5 no-padding">
          <div class="">
            <span class="color-brown">Block Id:</span>
            {{ bus.blockId }}
          </div>
        </div>
        <div class="col no-padding">
          <div class="">
            <span class="color-brown">Model: </span>
            {{ onCheckVechileModel(bus.busRegNo) }}
          </div>
        </div>
      </div>
      <!-- <div class="row no-margin">
          <div class="col-6 no-padding">
            <div class="">
              <span class="color-brown">Bus System:</span> 
              Bus System 123
            </div>
          </div>
          <div class="col-6 no-padding">
            <div class="">
              <span class="color-brown">Route Operated:</span> 
              321456
            </div>
          </div>
        </div>
        <div class="row no-margin">
          <div class="col-6 no-padding">
            <div class="">
              <span class="color-brown">On service/dead run:</span> 
              On Service
            </div>
          </div>
        </div> -->
      <!-- end image behavior -->

      <div class="row no-margin color-brown">
        <!-- <div class="col no-padding">Bus No.</div> -->
        <div class="col-10 no-padding">Stop</div>
        <div class="col no-padding">ETA</div>
      </div>

      <!-- <div class="row no-margin" *ngFor="let bus of bus.busRouteETAData">
          <div class="col-8 no-padding">
            <div class="stop-blk">{{bus.stopName}}</div>
          </div>
          <div class="col no-padding">
            <div class="stop-blk">{{bus.duration === '-' ? '-' : bus.duration }}</div>
          </div>
        </div> -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="col-10 no-padding">
          Next stop {{ getStopDetails(bus.nextStopId) }}
        </div>
        <div class="col no-padding">
          {{ bus.nextstopeta ? bus.nextstopeta : '-' }}
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="col-10 no-padding">
          After next stop {{ getStopDetails(bus.secondnextstopId) }}
        </div>
        <div class="col no-padding">
          {{ bus.secondnextstopeta ? bus.secondnextstopeta : '-' }}
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="col-10 no-padding">
          Third stop {{ getStopDetails(bus.thirdnextstopId) }}
        </div>
        <div class="col no-padding">
          {{ bus.thirdnextstopeta ? bus.thirdnextstopeta : '-' }}
        </div>
      </div>
    </div>
  </div>
</div>
