import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/component/shared/services/common.service';
// import { MatDialogConfig, MatDialog } from '@angular/material';
// export var OSMBuildings:any;
import moment from 'moment';
import { VoipService } from '../../shared/services/voip.service';
import { MqttType } from '../../shared/others/constant';
import { WebSocketSubject } from 'rxjs/internal-compatibility';
import { mqttSendData } from '../../shared/others/data-types';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
// import { TripCauseCodeAnalysisComponent } from '../reports/trip-cause-code-analysis/trip-cause-code-analysis.component';
import { darkTimepickerTheme } from '../../shared/others/timepicker-field-theme';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MapService } from '../../shared/services/map.service';
import { RosterService } from '../../shared/services/openapi/roster';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { busRoutes, stopNames } from '../mock-map/constant/routes';
import { DemoBusStopTimingService } from '../mock-map/demo-service/bus-stop-timing.service';
import { MockMapService } from '../mock-map/mock-map-service.service';

@Component({
  selector: 'app-mock-timetable',
  templateUrl: './mock-timetable.component.html',
  styleUrls: ['./mock-timetable.component.scss'],
})
export class MockTimetableComponent {
  stopNames = stopNames;
  constructor(
    private _snackBar: MatSnackBar,
    private _spinner: NgxSpinnerService,
    private commonService: CommonService,
    public demoBusStopTimingService: DemoBusStopTimingService,
    private mockMapService: MockMapService
  ) {}

  selectedDirection = '1';
  selectedServiceData: any = {};
  cloneSelectedServiceData: any = {};
  routeId: any = '7003';
  blockListData: any[] = [];
  timePickerTheme = darkTimepickerTheme;
  todayDate = moment();

  getStop(stopCode: string) {
    return stopNames[stopCode] ?? '';
  }

  todayHour = moment().subtract(1, 'hour').format('HH:00');

  form = new FormGroup({
    service: new FormControl('7003', [Validators.required]),
    serviceDay: new FormControl(moment().utcOffset(environment.timezone), [
      Validators.required,
    ]),
    timeAfter: new FormControl(this.todayHour ?? '05:00', [
      Validators.required,
    ]),
  });

  running: boolean = false;
  isLazyLoading: boolean = false;
  lazyLoadingMessage: string = 'Load more...';

  dialogConfig = new MatDialogConfig();
  lazyLoadingIndexStart: number = 0;
  countLoad = 20;
  lazyLoadingIndexEnd: number = this.countLoad;
  countLoadedData: number = this.countLoad;

  $timeTableEvent: WebSocketSubject<any>;
  directionListData = [];
  isHeadway: boolean = false;
  pageData = { id: 5, name: 'timeTable', displayPageName: 'Time Table' };
  isHasAccess: boolean = false;
  spinnerType: string;
  rightsError: string = '';
  @ViewChild('serviceAutocompleteTrigger')
  routeAutoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('blockSearch') searchTextBox: ElementRef;

  // select filtering
  separatorKeysCodes: number[] = [ENTER, COMMA];
  blockControl = new FormControl('');
  filteredBlocks: Observable<string[]>;
  selectedBlocks: string[] = [];
  @ViewChild('blockAutocompleteTrigger')
  blockAutocompleteTrigger: MatAutocompleteTrigger;

  ngOnInit() {
    console.log(this.demoBusStopTimingService.timetable);
    setTimeout(() => {
      this.initRouteAutocomplete();
      this.onSearchSchedule();
    }, 500);
  }

  selectRoute(event: MatAutocompleteSelectedEvent): void {
    event.option.deselect();
  }

  /**
   * setup service autocomplete field
   */
  routeList = busRoutes;
  filteredOptions: Observable<any[]>;
  initRouteAutocomplete() {
    this.filteredOptions = this.form.controls.service.valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value || ''))
    );
  }
  private filterOptions(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.routeList.filter(option =>
      option.route.toLowerCase().includes(filterValue)
    );
  }

  displayFn(route: any): string {
    return route && route.route ? route.route : '';
  }

  isLoading = false;
  onSearchSchedule(selectedDirection: string = '1') {
    this.isLoading = true;
    this.spinnerType = 'ball-clip-rotate';
    this._spinner.show();

    this.selectedServiceData = {};
    this.cloneSelectedServiceData = {};
    this.lazyLoadingIndexStart = 0;
    this.lazyLoadingIndexEnd = this.countLoad;
    this.countLoadedData = this.countLoad;
    this.selectedBlocks = [];
    this.blockListData = [];
    this.directionListData = [];
    this.lazyLoadingMessage = 'Load more';

    this.routeId = this.form.value.service;
    var serviceDay = this.form.value.serviceDay;
    var selectedDirectionIndex = parseInt(selectedDirection) - 1;

    this.selectedDirection = selectedDirection;

    setTimeout(() => {
      const serviceRouteTable = this.demoBusStopTimingService.timetable.find(
        timetable => timetable.routeId === `${this.routeId}`
      );
      if (!serviceRouteTable) {
        this._spinner.hide();
        this._snackBar.open('No schedule found', null, {
          duration: 2000,
        });
        return false;
      }
      const data = serviceRouteTable;
      const stopList = data.stopList.find(
        stop => stop.direction === selectedDirection
      );

      if (data === undefined || stopList === undefined) {
        this._spinner.hide();
        this._snackBar.open('No schedule found', null, {
          duration: 2000,
        });
        return false;
      }

      this.selectedServiceData.dispatchList = [];
      this.selectedServiceData.stopList = stopList;
      this.selectedServiceData.timetable = [];

      this.cloneSelectedServiceData.timetable = [];

      //push direction
      data.timetable.forEach(elem => {
        this.directionListData.push({ direction: elem.direction });
      });

      const direction = data.timetable[selectedDirectionIndex];
      const filteredTrips = this.filterByTime(direction.trips).then(
        (respData: any) => {
          return respData;
        }
      );

      filteredTrips.then(
        respData => {
          // })

          let clonedSched = respData.slice(); //direction 1
          // let clonedSchedSpliced = clonedSched.splice(
          //   this.lazyLoadingIndexStart,
          //   this.lazyLoadingIndexEnd
          // );

          this.selectedServiceData.timetable.push({
            // direction.trips.push({
            direction: direction.direction,
            trips: clonedSched,
          });

          this.cloneSelectedServiceData.timetable.push({
            // direction.trips.push({
            direction: direction.direction,
            trips: respData,
          });

          respData.forEach((trip, index, array) => {
            if (!trip.isTimeFiltered) {
              return false;
            }
            if (this.blockListData.indexOf(trip.blockId) === -1) {
              this.blockListData.push(trip.blockId);
            }

            var reconArr = [];

            for (let index = 0; index < stopList.stops.length; index++) {
              let stopId = stopList.stops[index].split(';')[0];
              let skipIndex = 0;

              if (stopList.stops.length - 1 === index) {
                skipIndex = 1;
              }
              // console.log('stop', stopList.stops, stopId, trip);

              let arrBlock = trip?.stops.filter(x => x.stopId === stopId);
              let stop: any = arrBlock[skipIndex];

              //if index 1 undefined try index 0
              if (stop === undefined) {
                let arrBlock2 = trip?.stops.filter(x => x.stopId === stopId);
                stop = arrBlock2[0];
              }

              //overwrite first stop
              if (index === 0 && stopId !== trip.stops[0].stopId) {
                stop = {};
              }

              // refactor - DO NOT MUTATE array?
              if (
                stop !== undefined &&
                stopId === stop.stopId &&
                !stop.isAdded
              ) {
                // console.log('stop', stop);
                var retPlanTime = this.commonService.formatPlanTime(stop);
                stop.schArrTime = retPlanTime.schArrTime;

                var retActualTime = this.commonService.formatActualTime(stop);
                stop.obsArrTime = retActualTime.obsArrTime;
                // console.log(stop);
                stop.statusColor = retActualTime.statusColor;

                let retHeadway = this.commonService.formatHeadwayTime(stop);
                stop.headwayStatusColor = retHeadway.headwayStatusColor;
                // stop.planHeadway =
                //   stop.plannedHeadway === 'null' ||
                //   stop.plannedHeadway === null ||
                //   stop.plannedHeadway === '0' ||
                //   stop.plannedHeadway === ''
                //     ? '-'
                //     : Math.round(stop.plannedHeadway / 60) + 'min';
                stop.planHeadway = '15min';
                stop.actualHeadway =
                  stop.observedHeadway === 'null' ||
                  stop.observedHeadway === null ||
                  stop.observedHeadway === '0' ||
                  stop.observedHeadway === ''
                    ? '-'
                    : Math.round(stop.observedHeadway / 60) + 'min';
                stop.deviationHeadway =
                  stop.headwayDeviation === 'null' ||
                  stop.headwayDeviation === null ||
                  stop.headwayDeviation === '0' ||
                  stop.headwayDeviation === ''
                    ? '-'
                    : Math.round(stop.headwayDeviation / 60) + 'min';
                stop.isAdded = true;
                reconArr.push(stop);
              } else {
                if (stop !== undefined) {
                  reconArr.push(stop);
                }
              }
            }
            trip.stops = reconArr;

            // console.log(index, trip);
          });

          //mqttt init
          this.initTableEvent();

          this.blockListData = this.blockListData.sort();
          this.isLoading = false;
          this._spinner.hide();
        },
        errorRes => {
          console.log(errorRes);
          this._snackBar.open(errorRes.error.errorMessage, null, {
            duration: 2000,
          });
          this._spinner.hide();
        }
      );
    }, 200);
  }

  filterByTime(data) {
    return new Promise(resolve => {
      var dataFiltered = [];
      var timeAfter = moment(this.form.value.timeAfter, 'HH:mm');

      var bar = new Promise((resolve, reject) => {
        data.forEach((element, index, array) => {
          element.isTimeFiltered = false;
          let firstStop = element.stops[0];
          let schedTime = moment(firstStop.schArrTime, 'HH:mm');
          if (schedTime.isSameOrAfter(timeAfter)) {
            element.isTimeFiltered = true;
            dataFiltered.push(element);
          }

          if (index === array.length - 1) resolve(dataFiltered);
        });
      });

      bar.then(dataTrip => {
        resolve(dataTrip);
      });
    });
  }

  initTableEvent() {
    // start listening for timetable events here
  }

  onChangeDirection() {
    if (this.selectedDirection === '1') {
      this.selectedDirection = '2';
    } else if (this.selectedDirection === '2') {
      this.selectedDirection = '1';
    }
    this.onSearchSchedule(this.selectedDirection);
  }

  onDeviationChange(eventData) {
    this.isHeadway = eventData.checked;
  }

  /** bugged on angular material 15. need to manually deselect option */
  clearServiceSearch(event) {
    event.stopPropagation();
    this.form.controls.service.patchValue('');
    this.routeAutoCompleteTrigger.activeOption.deselect();
  }

  clickTimeTableCell(timetableBusData) {
    console.log({ timetableBusData });
    const serviceRouteTable = this.demoBusStopTimingService.timetable.find(
      timetable => timetable.routeId === `${this.routeId}`
    );
    console.log('timetable', serviceRouteTable);
  }

  onBusDetails(trip, stop) {}

  ngOnDestroy() {
    console.log('destroy ' + this.$timeTableEvent);
    if (this.$timeTableEvent) {
      this.$timeTableEvent.unsubscribe();
    }
  }
}
