<div class="edit-respon-group">
  <div [ngClass]="{ init: !isInit }">
    <h1 mat-dialog-title class="header">Edit Responsibility</h1>
    <h5 class="responsibility">{{ newData.displayName }}</h5>
    <div class="subheader">
      {{ newData.userName }} <i>({{ newData.agency }})</i>
    </div>
  </div>

  <mat-dialog-content class="custom-scroll" [ngClass]="{ init: !isInit }">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <div class="helperText">
        <i>Select up to <b>10</b> service routes for each category</i>
      </div>
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        class="select-field">
        <mat-label>Primary Service</mat-label>
        <mat-select
          multiple
          [(ngModel)]="selectedPrimaryServices"
          panelClass="multi-select-panel"
          [disabled]="isLoading">
          <mat-select-trigger>
            {{ displaySelection(selectedPrimaryServices) }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let service of serviceList"
            [value]="service"
            [disabled]="
              selectedPrimaryServices?.length >= 10 &&
              getOptionSelectedOrder('primary', service) < 1
            ">
            <div class="responsibility-option">
              <span>
                {{ service }}
              </span>
              <span
                class="selection-order primary-bg"
                [ngClass]="{
                  'd-none': getOptionSelectedOrder('primary', service) < 1
                }">
                {{ getOptionSelectedOrder('primary', service) }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        class="select-field">
        <mat-label>Secondary Service</mat-label>
        <mat-select
          multiple
          [(ngModel)]="selectedSecondaryServices"
          panelClass="multi-select-panel"
          [disabled]="isLoading">
          <mat-select-trigger>
            {{ displaySelection(selectedSecondaryServices) }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let service of serviceList"
            [value]="service"
            [disabled]="
              selectedSecondaryServices?.length >= 10 &&
              getOptionSelectedOrder('secondary', service) < 1
            ">
            <div class="responsibility-option">
              <span>
                {{ service }}
              </span>
              <span
                class="selection-order secondary-bg"
                [ngClass]="{
                  'd-none': getOptionSelectedOrder('secondary', service) < 1
                }">
                {{ getOptionSelectedOrder('secondary', service) }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <div class="content-loading" *ngIf="!isInit && showLoading">
    <mat-spinner class="margin-auto"></mat-spinner>
  </div>

  <mat-dialog-actions align="end" [ngClass]="{ init: !isInit }">
    <button mat-button mat-dialog-close [disabled]="isLoading">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="onSaveRespon()"
      [disabled]="isLoading">
      Save
    </button>
  </mat-dialog-actions>
</div>
