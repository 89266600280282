
<div class="vehicle-device-group">
  <h2 mat-dialog-title>{{title}}</h2>

  <mat-dialog-content [formGroup]="form">

    <div class="row no-margin">
      <div class="padd-bot" *ngIf="title !== 'New'">
        {{data.busRegNo}}
      </div>
      <mat-form-field floatLabel="always" *ngIf="title === 'New'">
        <input type="text" matInput formControlName="busRegNo" aria-label="Bus Reg No" placeholder="Bus Reg No" />
        <mat-error *ngIf="form.get('busRegNo').invalid">Invalid registration no</mat-error>
      </mat-form-field>
    </div>
      
    <div class="row no-margin">
      <mat-form-field floatLabel="always">
        <input type="text" matInput formControlName="deviceId" aria-label="Device Id" placeholder="Device Id" />
        <mat-error *ngIf="form.get('deviceId').invalid">Invalid device id</mat-error>
      </mat-form-field>
    </div>

    <div class="row no-margin">
      <mat-form-field floatLabel="always">
        <input type="text" matInput formControlName="voipId" aria-label="Voip Id" placeholder="Voip Id" />
        <mat-error *ngIf="form.get('voipId').invalid">Invalid voip id</mat-error>
      </mat-form-field>
    </div>

    <!-- <div class="row">
      <mat-form-field floatLabel="always">
        <input type="text" matInput formControlName="ctryCode" aria-label="ctryCode" placeholder="ctryCode" />
        <mat-error *ngIf="form.get('ctryCode').invalid">Invalid ctryCode</mat-error>
      </mat-form-field> -->
      <mat-form-field floatLabel="always" *ngIf="title === 'New'">
        <mat-select formControlName="ctryCode" aria-label="Country Code" placeholder="Country Code">
          <mat-option *ngFor="let status of CountryCode" [value]="status.listName">
            {{status.listName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('ctryCode').invalid">Invalid country code</mat-error>
      </mat-form-field>
    <!-- </div> -->
  </mat-dialog-content>

  <mat-dialog-actions class="pull-right">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
  </mat-dialog-actions>
</div>