<div class="main">
  <div>
    <h3 class="title-header">Bus Arrival Time Report<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div>

  <div class="criteria-row">
    <form [formGroup]="rptForm" (ngSubmit)="run()">
      <!-- <mat-form-field style="width:400px !important">
        <input type="text" placeholder="Route" aria-label="Route" matInput formControlName="route"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let n of filteredRoutes" [value]="n">
            {{n}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="rptForm.get('route').invalid">Invalid route</mat-error>
      </mat-form-field> -->
      
      <mat-form-field floatLabel="always">
        <mat-label class="select-label">Select Route</mat-label>
        <mat-select formControlName="route">
          <mat-option *ngFor="let n of allRoutes" [value]="n.routeId">
            {{n.routeName}}
          </mat-option>
          <!-- <mat-option>None</mat-optionk> -->
          <!-- <mat-option value="sm-station-loop">SM Station Loop</mat-option>
          <mat-option value="airport-loop">Airport Loop</mat-option> -->
        </mat-select>
        <mat-error *ngIf="rptForm.get('route').invalid">Invalid route</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always">
        <input matInput [matDatepicker]="startDate" placeholder="Start Date" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error *ngIf="rptForm.get('startDate').invalid">Invalid start date</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always">
        <input matInput [matDatepicker]="endDate" placeholder="End Date" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
        <mat-error *ngIf="rptForm.get('endDate').invalid">Invalid end date</mat-error>
      </mat-form-field>

      <button mat-raised-button type="submit" 
        [disabled]="running || !rptForm.valid">Run</button>
      <!-- <button type="button" mat-raised-button (click)="exporter.exportTable('xlsx')"  [disabled]="running">Export</button> -->
    </form>
  </div>

  <div class="table-wrapper">
    <!-- <table mat-table matTableExporter [dataSource]="dataSource" matSort #exporter="matTableExporter" (exportStarted)="exportStarted('Exporting...')" (exportCompleted)="exportCompleted()"> -->
    <table mat-table matTableExporter [dataSource]="dataSource" matSort (exportStarted)="exportStarted('Exporting...')" (exportCompleted)="exportCompleted()">

      <ng-container matColumnDef="tripId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trip ID</th>
        <td mat-cell *matCellDef="let element"> {{element.tripId}} </td>
      </ng-container>

      <ng-container matColumnDef="busRegNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bus</th>
        <td mat-cell *matCellDef="let element"> {{element.busRegNo}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="schedTime">
        <th mat-header-cell *matHeaderCellDef>Schedule Time</th>
        <td mat-cell *matCellDef="let element"> {{element.schedTime}} </td>
      </ng-container> -->

      <ng-container matColumnDef="stopEnterTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actual Time</th>
        <td mat-cell *matCellDef="let element"> {{element.stopEnterTime}} </td>
      </ng-container>

      <ng-container matColumnDef="stopName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Stop Name</th>
        <td mat-cell *matCellDef="let element"> {{element.stopName}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="stopName">
        <th mat-header-cell *matHeaderCellDef>Stop Name</th>
        <td mat-cell *matCellDef="let element"> {{element.stopCode}} </td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="event">
        <th mat-header-cell *matHeaderCellDef>Event</th>
        <td mat-cell *matCellDef="let element"> {{element.eventCode}} </td>
      </ng-container>

      <ng-container matColumnDef="manual">
        <th mat-header-cell *matHeaderCellDef>Manual</th>
        <td mat-cell *matCellDef="let element"> {{element.manual}} </td>
      </ng-container> -->

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <ng-container matColumnDef="dwellTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dwell Time</th>
        <td mat-cell *matCellDef="let element"> {{element.dwellTime}} </td>
      </ng-container>

      <ng-container matColumnDef="deviation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Schedule Deviation (hh:mm:ss)</th>
        <td mat-cell *matCellDef="let element"> {{element.deviation}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

</div>