import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CommonService } from '../../../shared/services/common.service';
import jwt_decode from 'jwt-decode';
import {
  RosterService,
  Timetable,
  Roster,
} from '../../../shared/services/openapi/roster';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import {
  sampleDetailSchedule,
  sampleScheduleList,
  dummyRoster,
} from './dummyData';
import { MapService } from 'src/app/component/shared/services/map.service';
import { MatSelectChange } from '@angular/material/select';
import { FormControl } from '@angular/forms';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

interface RosterSchedule {
  service_day: string;
  schedule_day?: Roster[] | undefined;
}
interface RosterTimeTable {
  driver_id: string;
  driver_name: string;
  schedules: RosterSchedule[];
}
@Component({
  selector: 'app-duty-rostering',
  templateUrl: './duty-rostering.component.html',
  styleUrls: ['./duty-rostering.component.scss'],
})
export class DutyRosteringComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  decodedToken: any = jwt_decode(localStorage.getItem('access_token'));
  agency: string = '';
  isAdmin: boolean = false;
  isNewWindow: boolean = false;
  selectedDate = moment().utcOffset(environment.timezone);
  weekYear = this.selectedDate.week();
  weeksOfCurrentYear = this.selectedDate.weeksInYear();
  weekHeader = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  isLoading: boolean = true;
  showLoading = true;

  driverList = [];
  filteredDriverList = [];
  pagedList = [];

  scheduleList = sampleScheduleList;
  detailSchedule = sampleDetailSchedule;

  constructor(
    private router: Router,
    private _commonService: CommonService,
    private _rosterService: RosterService,
    private _snackBar: MatSnackBar,
    private _mapService: MapService
  ) {}

  ngOnInit() {
    const { agency } = this.decodedToken;
    this.agency = agency;
    if (agency === 'LTA') {
      this.isAdmin = true;
    }
    this.startSearchNameSubscription();
    this.startSearchRouteSubscription();
    // this.filteredDriverList = [...this.driverList];
  }

  ngAfterViewInit() {
    // this.getDriverList();
    this.initRostering();
    // call this after API call to initialize pagination
    // this.pageChanged({
    //   pageIndex: 0,
    //   pageSize: 10,
    //   length: this.filteredDriverList.length
    // })
  }

  // paginator
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageIndex = 0;
  pageSize = 5;

  pageChanged(event: PageEvent) {
    // console.log('page event', event);
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = (event.pageIndex + 1) * event.pageSize;
    this.pagedList = this.filteredDriverList.slice(startIndex, endIndex);
  }

  // filter form
  searchName: string = '';
  searchNameValue: string = '';
  searchNameSubject = new Subject<string | undefined>();
  searchNameSubscription: Subscription;
  searchRoute: string = '';
  searchRouteValue: string = '';
  searchRouteSubject = new Subject<string | undefined>();
  searchRouteSubscription: Subscription;

  // select filtering
  routeSearchTextboxControl = new FormControl();
  filteredRouteOptions: Observable<any[]>;
  selectedValue = '';
  isEmptyRouteSearch: boolean = false;
  /**
   * Clearing search textbox value
   */
  clearRouteSearch(event) {
    event.stopPropagation();
    this.routeSearchTextboxControl.patchValue('');
  }

  /**
   * Used to filter data based on search input
   */
  private filterRoute(name: string): any[] {
    const filterValue = name.toLowerCase();
    let filteredList =
      this.routeResponsibilities?.map(option => ({
        value: option,
        visible: !!option.toLowerCase()?.includes(filterValue),
      })) ?? [];
    this.isEmptyRouteSearch = filteredList.every(
      option => option.visible === false
    );
    return filteredList;
  }

  initFilter() {
    this.filteredRouteOptions =
      this.routeSearchTextboxControl.valueChanges.pipe(
        startWith<string>(''),
        debounceTime(150),
        distinctUntilChanged(),
        map(name => this.filterRoute(name))
      );
  }

  onSearchNameInput(data) {
    this.searchNameSubject.next(data);
  }

  clearNameTextBox(event) {
    event.stopPropagation();
    this.searchName = '';
    this.searchNameSubject.next('');
  }

  clearRouteTextBox(event) {
    event.stopPropagation();
    this.searchRoute = '';
    this.searchRouteSubject.next('');
  }

  startSearchNameSubscription() {
    this.searchNameSubscription = this.searchNameSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(result => {
        const searchQuery = result?.toString()?.trim()?.toLowerCase();
        this.searchNameValue = searchQuery;
        this.onFilterChange();
      });
  }

  onSearchRouteInput(data) {
    this.searchRouteSubject.next(data);
  }

  startSearchRouteSubscription() {
    this.searchRouteSubscription = this.searchRouteSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(result => {
        const searchQuery = result?.toString()?.trim()?.toLowerCase();
        this.searchRouteValue = searchQuery;
        this.onFilterChange();
      });
  }

  onFilterChange() {
    let currList = this.formattedRoster;

    if (this.searchNameValue?.length > 0) {
      currList = currList.filter(
        driverData =>
          driverData?.driver_name.toLowerCase().includes(this.searchNameValue)
      );
    }
    if (this.searchRouteValue?.length > 0) {
      currList = currList.filter(driverData => {
        // check service route here
        const { schedules = [] } = driverData || {};
        return schedules.some(sched => {
          return !!sched.schedule_day?.some(
            timeslot =>
              timeslot?.route_id
                ?.toLowerCase()
                ?.includes(this.searchRouteValue.toLowerCase())
          );
        });
        // return driverData.schedule.some((sched) => sched?.firstTripService === this.searchRouteValue || sched?.lastTripService === this.searchRouteValue)
      });
    }
    this.filteredDriverList = currList;
    this.pageIndex = 0;
    this.pageChanged({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: currList.length,
    });
  }

  getShiftScheduleClass(type) {
    switch (type) {
      case 'ONDUTY':
        return 'schedule-duty';
      case 'BREAK':
        return 'schedule-break';
      case 'STANDBY':
        return 'schedule-standby';
      default:
        return 'schedule-standby';
    }
  }

  get selectedDateDisplay() {
    return this.selectedDate.format('YYYY[, Week] W');
  }

  onClickPrevWeek() {
    console.log('weeks: ', this.weekYear, ' / ', this.weeksOfCurrentYear);
    const currWeek = this.weekYear;
    this.selectedDate.add(-1, 'week');
    this.weekYear = this.selectedDate.week();
    if (currWeek === 1) {
      this.weeksOfCurrentYear = this.selectedDate.weeksInYear();
    }

    // fetch new driver schedule
    this.getRoster();
  }

  onClickNextWeek() {
    console.log('weeks: ', this.weekYear, ' / ', this.weeksOfCurrentYear);
    const currWeek = this.weekYear;
    this.selectedDate.add(1, 'week');
    this.weekYear = this.selectedDate.week();
    if (currWeek === this.weeksOfCurrentYear) {
      this.weeksOfCurrentYear = this.selectedDate.weeksInYear();
    }

    // fetch new driver schedule
    this.getRoster();
  }

  getDriverList() {
    const urlAction = 'drivers/listAllDrivers';
    this.isLoading = true;
    this.showLoading = false;
    const loadingTimeout = setTimeout(() => (this.showLoading = true), 500);
    this._commonService
      .commonPostAction(urlAction, null)
      .subscribe(driverList => {
        // console.log('driver', driverList);
        const newDriverList = driverList.map(driver => ({
          name: driver.fullName.toLowerCase(),
          joiningDate: driver.joiningDate,
          id: driver.driverId,
          schedule: this.scheduleList[getRandomInt(0, 2)],
        }));
        this.driverList = newDriverList;
        this.filteredDriverList = [...newDriverList];
        this.pageChanged({
          pageIndex: 0,
          pageSize: 10,
          length: this.filteredDriverList.length,
        });
        this.isLoading = false;
        clearTimeout(loadingTimeout);
        this.showLoading = false;
      });
  }

  currentRosters: Timetable[] = [];
  formattedRoster: RosterTimeTable[] = [];
  getRoster() {
    this.isLoading = true;
    this.showLoading = false;
    const loadingTimeout = setTimeout(() => (this.showLoading = true), 500);
    const startDate = moment()
      .utcOffset(environment.timezone)
      .weekYear(this.selectedDate.year())
      .week(this.weekYear)
      .startOf('week');
    const endDate = startDate.clone().add(6, 'days');
    const weekDays = Array(7)
      .fill(null)
      .map((u, index) => startDate.clone().add(index, 'days').format());

    // this.currentRosters = [];
    const userSelectedRoute =
      this.selectedRoute?.length > 0 ? this.selectedRoute : undefined;
    this._rosterService
      .getrosters(
        undefined,
        startDate.format(),
        endDate.format(),
        userSelectedRoute
      )
      .subscribe(
        rosterList => {
          console.log('Roster: Get Success: ', rosterList);
          this.currentRosters = rosterList;
          this.formattedRoster = this.currentRosters.map(
            (roster: Timetable) => {
              const { driver_name, driver_id, schedule } = roster;
              const scheduleObject: [string, Roster[]][] = Object.entries(
                schedule.reduce((res: Object, sched: Roster) => {
                  const { service_day } = sched;
                  res[service_day] = res[service_day] || [];
                  res[service_day].push(sched);
                  return res;
                }, {})
              );
              console.log('sched', scheduleObject);
              const schedules: RosterSchedule[] = scheduleObject.map(
                ([key, value]: [string, Roster[]]) => {
                  const sortedRoster = [...value].sort((a, b) => {
                    const timeA = moment.parseZone(a.start_datetime);
                    const timeB = moment.parseZone(b.start_datetime);
                    if (timeA.isValid() && !timeB.isValid()) return -1;
                    if (!timeA.isValid() && timeB.isValid()) return 1;
                    if (timeA.isSame(timeB)) return 0;
                    return timeA.isAfter(timeB) ? 1 : -1;
                  });
                  return {
                    service_day: key,
                    schedule_day: sortedRoster,
                  };
                }
              );

              const completeSchedules: RosterSchedule[] = weekDays.map(day => {
                const findSchedule =
                  schedules.find(sched => sched.service_day === day) ||
                  undefined;
                const { schedule_day = undefined } = findSchedule || {};
                return {
                  service_day: day,
                  schedule_day,
                };
              });

              return {
                driver_id,
                driver_name,
                schedules: completeSchedules,
              };
            }
          );
          console.log('Roster', this.currentRosters, this.formattedRoster);

          this.filteredDriverList = [...this.formattedRoster];
          this.pageChanged({
            pageIndex: 0,
            pageSize: 5,
            length: this.filteredDriverList.length,
          });

          this.isLoading = false;
          clearTimeout(loadingTimeout);
          this.showLoading = false;
        },
        err => {
          console.log('Roster: Get Rosters Error: ', err);
          this.isLoading = false;
          this.showLoading = false;
          this.currentRosters = [];
          this.formattedRoster = [];
          this.filteredDriverList = [];
          this.pageChanged({
            pageIndex: 0,
            pageSize: 5,
            length: 0,
          });
          // this._snackBar.open('Failed to get rosters', null, {
          //   duration: 5000,
          // });
        }
      );
  }

  formatTimeSlot(startTime, endTime) {
    const startFormatted = moment.parseZone(startTime).format('HH:mm');
    const endFormatted = moment.parseZone(endTime).format('HH:mm');
    return startFormatted + ' - ' + endFormatted;
  }

  formatTime(time) {
    return moment.parseZone(time).format('HH:mm');
  }

  menuData = {};
  clickSchedule(scheduleData) {
    this.menuData = scheduleData;
    // trigger.openMenu();
  }

  getDateOfWeek(day: number) {
    // console.log('day', this.selectedDate, this.selectedDate.year(), this.weekYear);
    const startDate = moment()
      .utcOffset(environment.timezone)
      .weekYear(this.selectedDate.year())
      .week(this.weekYear)
      .startOf('week');
    if (day > 0) {
      return startDate.add(day, 'days').format('DD/MM/YYYY');
    }
    return startDate.format('DD/MM/YYYY');
  }

  routeResponsibilities = [];
  selectedRoute = '';
  selectedRoutes = [];
  initRostering() {
    this.isLoading = true;
    this._mapService
      .getInitSwitch()
      .then((data: any) => {
        const { primary, secondary } = data || {};
        const routeList = [];
        if (primary) {
          primary.forEach(route => {
            const { service } = route || {};
            if (service) {
              if (routeList.indexOf(service) < 0) {
                routeList.push(service);
              }
            }
          });
        }
        if (secondary) {
          secondary.forEach(route => {
            const { service } = route || {};
            if (service) {
              if (routeList.indexOf(service) < 0) {
                routeList.push(service);
              }
            }
          });
        }
        this.routeResponsibilities = [...routeList];
        console.log('responsibilities', data, routeList);
        this.initFilter();
        this.getRoster();
      })
      .catch(e => {
        this.routeResponsibilities = [];
        this.selectedRoute = '';
        console.log('Roster: Route Responsibility error: ', e);
        this._snackBar.open(
          'Server error. Failed to get rosters. Please try again.',
          null,
          {
            duration: 2000,
          }
        );
      });
  }

  onRouteChange(event: MatSelectChange) {
    if (!this.isLoading) {
      const { value } = event;
      this.selectedRoute = value;
      this.getRoster();
    }
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/driver-management/duty-rostering/2',
      'Duty Roster',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  ngOnDestroy() {
    this.searchNameSubscription?.unsubscribe();
    this.searchRouteSubscription?.unsubscribe();
    this.searchNameSubscription = undefined;
    this.searchRouteSubscription = undefined;
  }
}
