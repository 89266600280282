import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { UserElement, USER_STATUS } from 'src/app/component/shared/others/constant';
// import { DRIVER_STATUS, DriverElement, PmDriverService } from '../pm-driver.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  driverStatuses: any = USER_STATUS;
  form: FormGroup;
  data: UserElement;
  title: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UserEditComponent>, 
    @Inject(MAT_DIALOG_DATA) data: UserElement, 
    // private drvService: PmDriverService, 
    private _snackBar: MatSnackBar) {
    
    this.data = data;
  }

  ngOnInit() {
    this.title = this.data.userId == null ? 'New user' : 'User ' + this.data.userId;
    
    this.form = this.formBuilder.group({
      firstName: [this.data.firstName, Validators.required],
      middleName: [this.data.middleName, Validators.required],
      lastName: [this.data.lastName, Validators.required],
      driverStatus: [this.data.driverStatus, Validators.required],
      driverId: [this.data.userId, Validators.required],
      address: [this.data.address, Validators.required],
      primaryPhone: [this.data.primaryPhone, Validators.required],
      secondaryPhone: [this.data.secondaryPhone],
      identificationDocType: [this.data.identificationDocType, Validators.required],
      identificationId: [this.data.identificationId, Validators.required],
      joiningDate: [this.data.joiningDate, Validators.required],
      isTemporary: [this.data.isTemporary, Validators.required],
      temporary: [this.data.isTemporary === 'Y', Validators.required],
      lastDutyDate: [this.data.lastDutyDate]
    });

    let self = this;

    this.form.get('temporary').valueChanges.subscribe(x => {
      self.form.get('lastDutyDate').setValidators(x ? [Validators.required] : null);

      self.form.get('lastDutyDate').updateValueAndValidity();
    });
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this._snackBar.open('Saving...');

    this.form.value.isTemporary = this.form.value.temporary ? 'Y' : 'N';

    // this.drvService.setDriver(this.form.value).subscribe((data: any) => {

    //   this._snackBar.dismiss();

    //   this.dialogRef.close(this.form.value);
    // }, error => {
    //   this._snackBar.open(error.message, null, {
    //     duration: 2000,
    //   });
    // });

  }

}
