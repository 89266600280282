import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import moment from 'moment';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss'],
})
export class AddAccountComponent implements OnInit {
  constructor(
    private _commonService: CommonService,
    public dialogRef: MatDialogRef<AddAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      displayName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      password: new FormControl({ value: 'P@ssw0rd123', disabled: true }, [
        Validators.required,
      ]),
      role: new FormControl('', [Validators.required]),
      agency: new FormControl('', [Validators.required]),
      supervisorName: new FormControl(''),
      isAccountDisabled: new FormControl('', [Validators.required]),
      // isAccountLocked: new FormControl('', [Validators.required]),
      // isCredentialExpired: new FormControl('', [Validators.required]),
      // isAccountDeactivated: new FormControl('', [Validators.required])
    });

    // this.addCheckboxesToForm();
  }

  // @Input() data:any;
  running: boolean = false;
  form: FormGroup;
  accountData = this.dialogData.data.accountData;
  isHide = true;
  // companyList = [
  //   {'value': 'gas', 'viewValue': 'GAS'},
  //   {'value': 'lta', 'viewValue': 'LTA'},
  //   {'value': 'sbst', 'viewValue': 'SBST'},
  //   {'value': 'smrt', 'viewValue': 'SMRT'}
  // ];

  roleList = [
    // {
    //   'roleId': '11',
    //   'roleName': 'Controller'
    // },
    // {
    //   'roleId': '12',
    //   'roleName': 'Admin'
    // }
  ];

  agencyList = [...environment.accountMgmt.agencyList];

  supervisorList = [
    { value: 'cheemeng@mail.com', viewValue: 'cheemeng@mail.com' },
    { value: 'nicholas@mail.com', viewValue: 'nicholas@mail.com' },
    { value: 'admin@mail.com', viewValue: 'admin@mail.com' },
  ];

  selectedCompany: string;
  selectedStatus: string;
  selectedRole: string;
  selectedAgency: string;
  selectedSup: string;
  title: string = 'Add User';
  isEdit: boolean = false;
  // inputEmail:string = '';

  ngOnInit() {
    // console.log(this.accountData);
    this.getAllRoles();

    if (this.accountData) {
      this.isEdit = true;

      this.form = this.formBuilder.group({
        // displayName: [this.accountData.displayName, Validators.required],
        displayName: new FormControl(this.accountData.displayName, [
          Validators.required,
        ]),
        // displayName: new FormControl(this.accountData.displayName, [Validators.required]),
        email: new FormControl(
          { value: this.accountData.userEmail, disabled: true },
          [Validators.required]
        ),
        // password: new FormControl({value:'P@ssw0rd123', disabled: true}, [Validators.required]),
        role: new FormControl(this.accountData.roleName, [Validators.required]),
        agency: new FormControl('', [Validators.required]),
        supervisorName: new FormControl(this.accountData.supervisorName),
        isAccountDisabled: new FormControl(this.accountData.isAccountDisabled, [
          Validators.required,
        ]),
        // isAccountLocked: new FormControl(this.accountData.isAccountLocked, [Validators.required]),
        // isCredentialExpired: new FormControl(this.accountData.isCredentialExpired, [Validators.required]),
        // isAccountDeactivated: new FormControl(this.accountData.isAccountDeactivated, [Validators.required])
      });
      // this.form.get('company').setValue(this.accountData.company);
      this.selectedCompany = this.accountData.company;
      this.selectedStatus = this.accountData.status;
      this.selectedRole = this.accountData.roleName;
      this.selectedSup = this.accountData.supervisorName;
      this.title = 'Edit User';
      // this.form.get('role').setValue(this.accountData.roleId);
    }
  }

  getAllRoles() {
    var urlAction = 'users/v1/getRolesMasterData';
    this._commonService.commonPostAction(urlAction, '').subscribe(roles => {
      roles.forEach(element => {
        this.roleList.push({
          roleName: element.name,
          pages: element.id,
          isEdit: false,
        });
      });
      // for (const key in roles) {
      // if(roles.hasOwnProperty(key)) {
      //   // console.log(`${key} : ${roles[key]}`);
      //   let pageId = [];
      //   roles[key].forEach(element => {
      //       pageId.push({'id': element, 'name': 'pageName', 'isChecked': true});
      //   });

      // this.roleList.push({
      //   'roleName': key,
      //   'pages': pageId,
      //   'isEdit': false
      // })
      // }
      // }
    });
  }

  onSaveRole() {
    // console.log('Account Management: Save', this.form.value);

    var returnData: any = {};
    if (this.accountData) {
      returnData = {
        displayName: this.form.value.displayName,
        userEmail: this.accountData.userEmail,
        roleName: this.form.value.role || this.accountData.roleName,
        agency: this.accountData.agency,
        supervisorName: this.form.value.supervisorName,
        isAccountDisabled: this.form.value.isAccountDisabled,
        isAccountLocked: false,
        isCredentialExpired: false,
        // "isAccountDeactivated": this.form.value.isAccountDeactivated
      };
      this.updateUser(returnData);
    } else {
      returnData = {
        displayName: this.form.value.displayName,
        userEmail: this.form.value.email,
        roleName: this.form.value.role,
        agency: this.form.value.agency,
        supervisorName: this.form.value.supervisorName,
        isAccountDisabled: this.form.value.isAccountDisabled,
        isAccountLocked: false,
        isCredentialExpired: false,
        // "isAccountDeactivated": this.form.value.isAccountDeactivated
      };
      this.addNewUser(returnData);
    }
  }

  updateUser(returnData) {
    // console.log('User Management: Add User ', returnData);
    var dataBody = [
      {
        email: returnData.userEmail,
        displayName: returnData.displayName,
      },
    ];

    var urlAction = 'users/v1/updateDisplayName';
    this._commonService
      .commonPostAction(urlAction, dataBody)
      .subscribe(roles => {
        let assignDataBody = {
          roleName: returnData.roleName,
          userName: returnData.userEmail,
          supvrEmail:
            returnData.roleName.toLowerCase() === 'controller'
              ? ''
              : returnData.supervisorName,
        };
        var assignUrlAction = 'users/v2/assignUserRoleAndSupervisor';
        this._commonService
          .commonPostAction(assignUrlAction, assignDataBody)
          .subscribe(
            assignResp => {
              this._snackBar.open(
                roles.response.charAt(0).toUpperCase() +
                  roles.response.slice(1),
                null,
                {
                  duration: 5000,
                }
              );

              if (returnData.isAccountDisabled) {
                this.accountEnabled(returnData);
              }

              // if(returnData.isAccountDeactivated) {
              //   this.accountDeactivate(returnData);
              // }
              returnData.isAccountDisabled = returnData.isAccountDisabled
                ? true
                : false;
              // console.log(returnData);
              this.dialogRef.close(returnData);
            },
            error => {
              console.log(error);
              this._snackBar.open(error.error.errorMessage, null, {
                duration: 5000,
              });
            }
          );
      });
  }

  addNewUser(returnData) {
    var dataBody = [
      {
        email: returnData.userEmail,
        agency: returnData.agency,
        displayName: returnData.displayName,
      },
    ];

    var urlAction = 'users/v2/addUsers';
    this._commonService.commonPostAction(urlAction, dataBody).subscribe(
      roles => {
        let isExist = roles.response.includes('already exists');

        if (!isExist) {
          let assignDataBody = {
            roleName: returnData.roleName,
            userName: returnData.userEmail,
            supvrEmail:
              returnData.roleName.toLowerCase() === 'controller'
                ? ''
                : returnData.supervisorName,
          };
          var assignUrlAction = 'users/v2/assignUserRoleAndSupervisor';
          this._commonService
            .commonPostAction(assignUrlAction, assignDataBody)
            .subscribe(
              assignResp => {
                this._snackBar.open(
                  roles.response.charAt(0).toUpperCase() +
                    roles.response.slice(1),
                  null,
                  {
                    duration: 5000,
                  }
                );

                if (returnData.isAccountDisabled) {
                  this.accountEnabled(returnData);
                }

                // if(returnData.isAccountDeactivated) {
                //   this.accountDeactivate(returnData);
                // }

                returnData.isAccountDisabled = returnData.isAccountDisabled
                  ? true
                  : false;
                this.dialogRef.close(returnData);
              },
              err => {
                console.log('Add Account Error: ', err);
                this._snackBar.open('Error occured.', null, {
                  duration: 5000,
                });
                this.dialogRef.close(returnData);
              }
            );
        } else {
          this.dialogRef.close(returnData);
        }
      },
      err => {
        console.log('Add Account Error: ', err);
        const { error } = err;
        const { errorMessage } = error || {};
        if (errorMessage) {
          this._snackBar.open(`An Error occured. ${errorMessage}`, null, {
            duration: 5000,
          });
        } else {
          this._snackBar.open('An Error occured.', null, {
            duration: 5000,
          });
        }
        this.dialogRef.close(returnData);
      }
    );
  }

  accountEnabled(returnData) {
    var dataBodyDisable = {
      email: returnData.userEmail,
      userDisableFlag: false,
    };

    var urlDisableAction = 'users/v3/enableDisableUser';
    this._commonService
      .commonPostAction(urlDisableAction, dataBodyDisable)
      .subscribe(
        account => {
          console.log(account);
        },
        error => {
          console.log(error);
          this._snackBar.open('Enabling/Disabling user error occured.', null, {
            duration: 5000,
          });
        }
      );
  }

  // accountDeactivate(returnData) {
  //   var dataBodyDisable = {
  //     "email" : returnData.userEmail,
  //     "deActivateUserFlag": true
  //   };

  //   var urlDisableAction = 'users/v1/activateDeactivateUser';
  //   this._commonService.commonPostAction(urlDisableAction, dataBodyDisable).subscribe(account => {
  //     console.log(account);
  //   },
  //   error => {
  //     console.log(error);
  //     this._snackBar.open('Activating/Deactivating user error occured.', null, {
  //       duration: 5000,
  //     });
  //   });
  // }

  // get ordersFormArray() {
  //   return this.form.controls.orders as FormArray;
  // }

  // private addCheckboxesToForm() {
  //   this.pageList.forEach(() => this.ordersFormArray.push(new FormControl(false)));
  // }
  // onEmailChange(data) {
  //   var displayName  = data.substring(0, data.lastIndexOf("@"));
  //   console.log('data', data);
  //   console.log('displayName', displayName);
  //   if(displayName) {
  //     this.form.get('displayName').setValue(displayName);
  //   }
  //   else {
  //     this.form.get('displayName').setValue(data);
  //   }
  // }
}
