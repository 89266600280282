import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-map',
  templateUrl: './settings-map.component.html',
  styleUrls: ['./settings-map.component.scss']
})
export class SettingsMapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
