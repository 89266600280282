import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from "@angular/core";

import { environment } from 'src/environments/environment';
// @Injectable({ providedIn: 'root' })
@Injectable()
export class MenuService
{
    private isDispatcher = new Subject();
    private isPinned$ = new Subject<boolean>();
    private menuConfig: any;
    private panelObject: any = {
        accountManagement: false,
        vehicleManagement: false,
        masterDataConfiguration: false,
    };

    public isPinned: boolean;

    constructor ()
    {
        this.isPinned = false;
        this.menuConfig = environment.menu;
    }

    get menu() {
        return this.menuConfig;
    }

    getPinned(): Observable<any> {
        return this.isPinned$.asObservable();
    }

    set isMenuPinned(val: boolean) {
        this.isPinned$.next(val);
        this.isPinned = val;
    }

    get isMenuPinned() {
        return this.isPinned;
    }

    updatePanelState(panel: string, state: boolean) {
        this.panelObject[panel] = state;
    }

    get panelState(): any {
        return this.panelObject;
    }

    setDispatcher(data:boolean){
        this.isDispatcher.next(data);
    }
    getDispatcher(){
        return this.isDispatcher;
    }
}