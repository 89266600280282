import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../shared/services/common.service';
import {
  INTERFACE_CONFIG_OVERWRITE,
  CONFIG_OVERWRITE,
} from '../../shared/others/jitsi-config';
import { JitsiService } from '../../shared/services/jitsi.service';

declare var JitsiMeetExternalAPI;

@Component({
  selector: 'app-mock-bus',
  templateUrl: './mock-bus.component.html',
  styleUrls: ['./mock-bus.component.scss'],
})
export class MockBusComponent {
  constructor(
    private _commonService: CommonService,
    private httpClient: HttpClient,
    private _jitsiService: JitsiService
  ) {}

  listBus = [];
  counter = 11;

  domain;

  async generateJaasToken(busName) {
    const decodedToken: any = jwt_decode(localStorage.getItem('access_token'));
    const displayName = busName;
    const email = 'bus@mail.com';
    const { agency } = decodedToken || {};
    const reqUrl = environment.firebase.nodeUrl + 'auth-jaas';
    const body = {
      role: 'BUS',
      displayName,
      email,
      agency,
      apiKey: environment.jitsi.apiKey,
    };

    try {
      const response: any = await this.httpClient
        .post(reqUrl, body)
        .toPromise();
      const { token: newToken, domain } = response;

      this.domain = domain;
      return newToken;
    } catch (e) {
      console.log('Error generating token: ', e);
      return undefined;
    }
  }

  async initBus() {
    try {
      const busName = '' + this.counter;
      const testToken = await this.generateJaasToken(busName);
      this.counter += 1;
      // console.log(busName, testToken);
      this.listBus.push({
        busName,
        token: testToken,
        jitsiApi: undefined,
      });
      console.log(this.listBus);
      setTimeout(() => this.initJitsi(busName), 500);
    } catch (e) {
      console.log('error', e);
    }
  }

  initJitsi(busName) {
    const currentBus = this.listBus.find(bus => bus.busName === busName);
    const { token } = currentBus;

    const decodedToken: any = jwt_decode(localStorage.getItem('access_token'));
    const decodedJaasToken: any = jwt_decode(token);
    const email = 'bus@mail.com';
    const { agency } = decodedToken || {};
    const { context } = decodedJaasToken || {};
    const { avatar: busAvatar } = context || {};
    const domain = this.domain;
    const broadcastRoomName = `${environment.jitsi.appId}/${agency}-announcement-lobby`;
    const displayName = `${agency}-BUS-${busName}`;

    const options = {
      jwt: token,
      roomName: broadcastRoomName,
      width: '100px',
      height: '100px',
      parentNode: document.getElementById(busName),
      userInfo: {
        displayName,
        email,
      },
      interfaceConfigOverwrite: {
        TILE_VIEW_MAX_COLUMNS: 1,
        DEFAULT_BACKGROUND: '#292929',
        ...INTERFACE_CONFIG_OVERWRITE,
      },
      configOverwrite: {
        ...CONFIG_OVERWRITE,
        startSilent: true, // disable audio
        startAudioOnly: true,
        subject: `${agency}: Lobby`,
        backgroundColor: '#292929',
        startAudioMuted: 0,
        toolbarButtons: ['microphone', 'settings'],
        hideConferenceSubject: true,
        hideConferenceTimer: true,
        startWithAudioMuted: true,
        remoteVideoMenu: {
          disabled: true,
          disableKick: true,
          disableGrantModerator: true,
          disablePrivateChat: true,
        },
        disableFilmstripAutohiding: true,
        filmstrip: {
          disableStageFilmstrip: false,
          stageFilmstripParticipants: 6,
          disableTopPanel: true,
        },
        disableSelfView: true,
        disableSelfViewSettings: true,
      },
    };

    currentBus.jitsiApi = new JitsiMeetExternalAPI(domain, options);
  }
}
