<div id="resp-mgt-group" fxLayout="column">
  <div class="group" [hidden]="!isHasAccess">
    <div
      class="header-group"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div>
          <span class="title-header">User Responsibility</span>
          <sup
            ><i
              *ngIf="!isNewWindow"
              class="fa fa-window-restore open-window"
              (click)="openWindow()"></i
          ></sup>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'd-none': isLoading }">
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        floatLabel="always"
        style="width: 270px">
        <mat-label>Search</mat-label>
        <input
          name="Search"
          matInput
          placeholder="Search Name or Email"
          aria-label="Search"
          #filterField
          [(ngModel)]="searchFilter"
          (input)="onSearchFilterInput($event.target.value)"
          [disabled]="isLoading" />
        <button
          [disableRipple]="true"
          *ngIf="filterField.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearFilterTextBox($event)"
          [disabled]="isLoading">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <!-- <table border="0" class="role-table" *ngIf="!isLoading">
      <perfect-scrollbar #chatPS [config]="config" [scrollIndicators]="false"
      >
          <thead>
            <tr>
              <th>Username</th>
              <th>
                Route List
              </th>
              <th class="header" *ngFor="let page of dataPage.responsibilitiesList; let first = first; let i = index">
                <span class="stop-count">{{i+1}}</span>
              </th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let role of dataPage.respList">
                <td>
                  <div fxLayout="row" fxLayoutAlign="space-around center" >
                    <div>{{role.userName}}</div>
                  </div>
                </td>
  
                <td>
                    <div fxLayout="row" fxLayoutAlign="center center" >
                      <span>Primary</span>
                      <ng-container *ngIf="role.isPrimaryEdit">
                        <mat-icon class="pointer" (click)="onEdit(role, 'cancel')">cancel</mat-icon>
                        <mat-icon class="pointer" (click)="onSave(role)">save</mat-icon>
                      </ng-container>
                      <ng-container *ngIf="!role.isPrimaryEdit">
                        <mat-icon class="pointer" (click)="onEdit(role)">edit</mat-icon>
                      </ng-container>
                    </div>
                    
                    <div fxLayout="row" fxLayoutAlign="center center" >
                      <span>Secondary</span>
                      <ng-container *ngIf="role.isSecondaryEdit">
                        <mat-icon class="pointer" (click)="onSecondaryEdit(role, 'cancel')">cancel</mat-icon>
                        <mat-icon class="pointer" (click)="onSave(role)">save</mat-icon>
                      </ng-container>
                      <ng-container *ngIf="!role.isSecondaryEdit">
                        <mat-icon class="pointer" (click)="onSecondaryEdit(role)">edit</mat-icon>
                      </ng-container>
                    </div>
                </td>
  
                <ng-container *ngFor="let i of dataLoop;">
                  <td class="td-body">
                    
                    <ng-container *ngIf="!role.isSecondaryEdit">
                      <ng-container *ngIf="!role.isPrimaryEdit && role.responsibility[1] !== undefined">
                        <div *ngIf="role.responsibility[1].type === 'PRIMARY'">{{role.responsibility[1].data[i].routeId}}</div>
                      </ng-container>
                      <ng-container *ngIf="!role.isPrimaryEdit && role.responsibility[0] !== undefined">
                        <div *ngIf="role.responsibility[0].type === 'PRIMARY'">{{role.responsibility[0].data[i].routeId}}</div>
                      </ng-container>
                    </ng-container>
  
                    <ng-container *ngIf="!role.isPrimaryEdit">
                      <ng-container *ngIf="!role.isSecondaryEdit && role.responsibility[1] !== undefined">
                        <div *ngIf="role.responsibility[1].type === 'SECONDARY'">{{role.responsibility[1].data[i].routeId}}</div>
                      </ng-container>
                      <ng-container *ngIf="!role.isSecondaryEdit && role.responsibility[0] !== undefined">
                        <div *ngIf="role.responsibility[0].type === 'SECONDARY'">{{role.responsibility[0].data[i].routeId}}</div>
                      </ng-container>
                    </ng-container>
  
                    <ng-container *ngIf="role.isPrimaryEdit && role.responsibility[0].type === 'PRIMARY'">
                      <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="role.responsibility[0].data[i].routeId" [value]="role.responsibility[0].data[i].routeId"> 
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="role.isPrimaryEdit && role.responsibility[1].type === 'PRIMARY'">
                      <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="role.responsibility[1].data[i].routeId" [value]="role.responsibility[1].data[i].routeId"> 
                      </mat-form-field>
                    </ng-container>
  
                    <ng-container *ngIf="role.isSecondaryEdit && role.responsibility[0].type === 'SECONDARY'">
                      <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="role.responsibility[0].data[i].routeId" [value]="role.responsibility[0].data[i].routeId"> 
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="role.isSecondaryEdit && role.responsibility[1].type === 'SECONDARY'">
                      <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="role.responsibility[1].data[i].routeId" [value]="role.responsibility[1].data[i].routeId"> 
                      </mat-form-field>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
          </tbody>
      </perfect-scrollbar>
    </table> -->

    <div
      fxLayout="column"
      class="custom-page-scroll content-container"
      [ngClass]="{ 'd-none': isLoading }">
      <table
        mat-table
        matTableExporter
        [dataSource]="dataSource"
        matSort
        #exporter="matTableExporter"
        class="content-table">
        <ng-container
          [matColumnDef]="column"
          *ngFor="let column of displayedColumns; let idx = index">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="content-header"
            [ngClass]="{
              'content-number': column !== 'Username' && column !== 'Actions',
              'action-cell': column === 'Actions'
            }">
            <!-- <div fxLayout="row" fxLayoutAlign="space-around center"> -->
            <div
              [ngClass]="{
                numberHeader: column !== 'Username' && column !== 'Actions'
              }">
              {{ column }}
            </div>
            <!-- </div> -->
          </th>
          <ng-container *ngIf="idx === 0; else dataCell">
            <td mat-cell *matCellDef="let element">
              {{ element.userName }} <i>({{ element.agency }})</i>&nbsp;<b
                *ngIf="element.isSelf"
                >(You)</b
              >
            </td>
          </ng-container>

          <ng-template #dataCell>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="{
                'number-cell': idx < 11,
                'action-cell': idx === 11
              }">
              <!-- {{element.responsibility.secondary[0].routeId}} -->
              <!-- <ng-container *ngFor="let secondary of element.responsibility.secondary; let seconIdx = index"> -->
              <!-- <ng-container *ngFor="let seconData of secondary"> -->

              <!-- <ng-container *ngIf="idx === 0; else dataRespon">
                    <td mat-cell *matCellDef="let element"> 100 </td>
                  </ng-container>
    
                  <ng-template #dataRespon> -->
              <ng-container *ngIf="element.responsibility.primary[idx]">
                <div class="primary-text">
                  {{ element.responsibility.primary[idx].routeId }}
                </div>
              </ng-container>
              <ng-container *ngIf="element.responsibility.secondary[idx]">
                <div class="secondary-text">
                  {{ element.responsibility.secondary[idx].routeId }}
                </div>
              </ng-container>

              <!-- <ng-container *ngIf="element === 'Actions'"> -->

              <ng-container *ngIf="idx === 11">
                <div class="action-container">
                  <button
                    matTooltip="Edit Responsibility"
                    mat-icon-button
                    (click)="onEditUser(element)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <!-- <button class="secondary-bg" matTooltip="Edit Secondary" mat-mini-fab (click)="onEditUser('secondary', element.responsibility.secondary, element, element.responsibility.primary, 'primary')">
                        <mat-icon>edit</mat-icon>
                      </button> -->
                </div>
              </ng-container>

              <!-- </ng-container> -->
              <!-- </ng-template> -->
              <!-- </ng-container> -->
              <!-- </ng-container> -->
            </td>
          </ng-template>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            highlighted: row.highlighted
          }"
          class="content-row"></tr>
      </table>
    </div>
    <div [ngClass]="{ 'd-none': isLoading }">
      <mat-paginator
        #paginator
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[10, 15, 25, 50, 100]"></mat-paginator>
    </div>

    <div class="content-loading" *ngIf="isLoading">
      <mat-spinner class="margin-auto"></mat-spinner>
    </div>
  </div>
  <div
    class="height-50vh"
    fxLayout="column"
    fxLayoutAlign="center center"
    [hidden]="isHasAccess">
    <h3>{{ rightsError }}</h3>
  </div>
</div>
