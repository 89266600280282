import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import moment from 'moment';
import { LogsData } from 'src/app/component/shared/others/data-types';
// import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';
import { CommonService } from 'src/app/component/shared/services/common.service';
import { VoipService } from 'src/app/component/shared/services/voip.service';
import {
  CALL_PATH,
  WINDOW_NAME_CALL,
  WINDOW_NAME_MAIN,
} from 'src/app/component/shared/others/constant';
// import { janus_2 } from 'src/assets/js/lib/janus_2';
// export interface myinterface {
//   initVoip(data);
// }

@Component({
  selector: 'app-time-bus-details',
  templateUrl: './time-bus-details.component.html',
  styleUrls: ['./time-bus-details.component.scss'],
})
export class TimeBusDetailsComponent implements OnInit {
  // public compInteraction: myinterface;
  @Input() data: any;
  // @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  // public config: PerfectScrollbarConfigInterface = {suppressScrollX: true, suppressScrollY: true};

  constructor(
    private commonService: CommonService,
    public dialogRef: MatDialogRef<TimeBusDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private voipService: VoipService,
    private _snackBar: MatSnackBar
  ) {
    // this.data = dialogData;
  }

  ngOnInit(): void {
    console.log('detail', this.dialogData);
  }

  // onShowVoip() {
  //   this.compInteraction.initVoip(this.data);
  // }

  callJitsi() {
    // const { busRegNo } = this.bus;
    const { busRegNo } = this.dialogData;
    const windowFeatures = '';
    this.commonService.openWindow(
      `${CALL_PATH}?busRegistrationNumber=${busRegNo}`,
      WINDOW_NAME_MAIN,
      `${WINDOW_NAME_CALL}_${busRegNo}`,
      windowFeatures
    );
  }

  // voipData:any;
  isOnCall: boolean = false;
  onVoipCall() {
    this.isOnCall = true;
    // let testData = {
    //   'busRegNo' : this.dialogData.vehicleCode,
    //   'stattus': 'calling'
    // };
    // this.voipData = testData;

    var postData: LogsData = {
      busRegNo: this.dialogData.busRegNo,
      routeId: this.dialogData.routeId,
      tripId: this.dialogData.tripId,
      latitude: 0,
      longitude: 0,
      timeInSeconds: moment().unix(),
      msgEvent: 'make',
    };

    this.voipService
      .getVehicleSipNumber(this.dialogData.busRegNo)
      .subscribe(respSip => {
        if (respSip.sipId === '-') {
          this._snackBar.open(
            'No SIP number available on this vehicle ' +
              this.dialogData.busRegNo,
            null,
            {
              duration: 5000,
            }
          );
          return false;
        } else if (respSip.sipId >= 1 && respSip.sipId <= 600) {
          console.log('bus details');
          this._snackBar.open(
            'SIP number disabled ' + this.dialogData.busRegNo,
            null,
            {
              duration: 5000,
            }
          );
          return false;
        } else {
          this.voipService.logVoip(postData).then(dataResp => {
            this.voipService.doCall('sip:' + respSip.sipId + '@10.12.0.101');
          });
        }
      });
  }

  onVoipHangUp() {
    var postData: LogsData = {
      busRegNo: this.dialogData.busRegNo,
      routeId: this.dialogData.routeId,
      tripId: this.dialogData.tripId,
      latitude: 0,
      longitude: 0,
      timeInSeconds: moment().unix(),
      msgEvent: 'drop',
    };

    this.voipService.logVoip(postData).then(dataResp => {
      this.isOnCall = false;
      this.voipService.doHangup();
    });
  }

  onVoipAnswer() {
    var postData: LogsData = {
      busRegNo: this.dialogData.busRegNo,
      routeId: this.dialogData.routeId,
      tripId: this.dialogData.tripId,
      latitude: 0,
      longitude: 0,
      timeInSeconds: moment().unix(),
      msgEvent: 'attend',
    };

    this.voipService.logVoip(postData).then(dataResp => {
      this.voipService.doAnswer();
    });
  }

  onVoipDecline() {
    this.voipService.doDecline();
  }
}
