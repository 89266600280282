import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatTable, MatDialog, MatDialogConfig, MatSnackBar, MatSort } from '@angular/material';
// import { DriverElement } from '../../personnel-management/pm-driver-mat/pm-driver.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { AlarmDetailComponent } from './alarm-detail/alarm-detail.component';
import { CommonAlarmComponent } from '../../common/common-alarm/common-alarm.component';
import { AlarmService } from '../../shared/services/alarm.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { DriverElement, PmDriverService } from './pm-driver.service';
// import { PmDriverEditComponent } from './pm-driver-edit/pm-driver-edit.component';

export class AlarmMgtElem {
  sn: string;
  eventNo: number;
  alarmNo: number;
  type: string;
  subtype: string;
  createdBy: string;
  startTime: string;
  roadName: string;
  status: string;
  planStatus: string;
}

@Component({
  selector: 'app-alarm-management',
  templateUrl: './alarm-management.component.html',
  styleUrls: ['./alarm-management.component.scss'],
})
export class AlarmManagementComponent implements OnInit {
  dataSource: MatTableDataSource<any>;

  // displayedColumns: string[] = ['sn', 'eventNo', 'alarmNo', 'severity', 'type', 'subtype', 'createdBy', 'startTime', 'roadName', 'status', 'planStatus'];
  displayedColumns: string[] = [
    'routeId',
    'driverId',
    'blockId',
    'tripId',
    'severity',
    'type',
    'createdBy',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  isNewWindow: boolean = false;

  @ViewChild('chatPS', { static: true }) chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  // allAlarms: any = [{"sn":1,"eventNo":32145624,"alarmNo":125,"severity":"High","type":"Road Block","subtype":"","createdBy":"Ali Rahmani Abdul","lastUpdate":"23/03/2020 22:00","startTime":"23/03/2020","roadName":"Sheikh Zayed Road","status":"Open","planStatus":"No Plan"},{"sn":2,"eventNo":64683456,"alarmNo":126,"severity":"Low","type":"Vehicle Breakdown","subtype":"","createdBy":"Muahmmed Tahir Rashid","lastUpdate":"23/03/2020 13:00","startTime":"23/03/2020","roadName":"Sheikh Zayed Road","status":"Open","planStatus":"Error in Response Plan Generation"},{"sn":3,"eventNo":55789254,"alarmNo":127,"severity":"Medium","type":"Diversion","subtype":"Road Works","createdBy":"Dildar Hussain Haji","lastUpdate":"23/03/2020 15:00","startTime":"23/03/2020","roadName":"Sheikh Zayed Road","status":"Open","planStatus":"Ready"}];
  allAlarms: any = [];
  // filterEntity: any;
  initSeverity: string = 'all';
  initType: string = 'all';
  initSubtype: string = 'all';
  initStatus: string = 'all';
  initPlan: string = 'all';

  severityList = [
    { value: 'all', viewValue: 'All' },
    { value: 'low', viewValue: 'Low' },
    { value: 'medium', viewValue: 'Medium' },
    { value: 'high', viewValue: 'High' },
  ];
  typeList = [
    { value: 'all', viewValue: 'All' },
    { value: 'accident', viewValue: 'Accident' },
    { value: 'diversion', viewValue: 'Diversion' },
    { value: 'Road Block', viewValue: 'Road Block' },
  ];
  subtypeList = [
    { value: 'all', viewValue: 'All' },
    { value: 'Chain Collision', viewValue: 'Chain Collision' },
    { value: 'accident', viewValue: 'Accident' },
    { value: 'Police Activities', viewValue: 'Police Activities' },
  ];
  statusList = [
    { value: 'all', viewValue: 'All' },
    { value: 'open', viewValue: 'Open' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'resolved', viewValue: 'Resolved' },
  ];
  planStatusList = [
    { value: 'all', viewValue: 'All' },
    {
      value: 'Error in Response Plan Generation',
      viewValue: 'Error in Response Plan Generation',
    },
    { value: 'ready', viewValue: 'Ready' },
    { value: 'No Plan', viewValue: 'No Plan' },
  ];
  dialogConfig = new MatDialogConfig();

  constructor(
    private dialog: MatDialog,
    // private drvService: PmDriverService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private _alarmService: AlarmService
  ) {}

  ngOnInit() {
    this._alarmService.getAllAlarm().subscribe(alarm => {
      console.log(alarm);
      this.allAlarms.push(alarm);
      this.dataSource.data = this.allAlarms;
    });
    // this.dataSource = new MatTableDataSource<DriverElement>(drivers);

    this.dataSource = new MatTableDataSource<any>();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // let self = this;
    // this.drvService.getAllDriver().subscribe((data: any) => {
    // this.dataSource.data = this._alarmService.allAlarm;
    // this.table.renderRows();

    //   // this._snackBar.dismiss();
    // }, error => {
    //   this._snackBar.open(error.message, null, {
    //     duration: 2000,
    //   });
    // });
    // this.allAlarms.forEach(element => {
    //   element.licenseNumber = 'LN'+Math.floor(100000 + Math.random() * 900000);
    // });
    // console.log(JSON.stringify(this.allAlarms));

    // this.dataSource.filterPredicate = (data: any, filter: string) => {
    //   return data.name == filter;
    //  };
    // this.dataSource.filterPredicate = (data: AlarmMgtElem, filter: string) => {
    //   // console.log(data.createdBy);
    //   // console.log(filter);
    //   // return data.createdBy.trim().toLowerCase() == filter;
    //   return data.createdBy.trim().toLowerCase().includes(filter);
    //  };

    // this.dataSource.filterPredicate = (data: any, filter: string) =>
    // (data.name.indexOf(filter) !== -1 || data.id.indexOf(filter) !== -1 );
    // setTimeout(() => {
    //   var newEvent = {
    //     "sn": 4,
    //     "eventNo": 98756425,
    //     "alarmNo": 128,
    //     "severity": "High",
    //     "type": "Accident",
    //     "subtype": "Chain Collision",
    //     "createdBy": "Hussain Khan Umar",
    //     "lastUpdate": "23/03/2020 18:50",
    //     "startTime": "23/03/2020",
    //     "roadName": "Commonwealth Avenue",
    //     "status": "Open",
    //     "planStatus": "No Plan"
    //   };
    //   this.allAlarms.push(newEvent);

    //   this.dataSource.data = this.allAlarms;
    //   // this._snackBar.open(
    //   //   'message here', 'Close', {
    //   //     panelClass: ['alarm-info'],
    //   //     verticalPosition: 'top'
    //   //   }
    //   // );
    //   this._snackBar.openFromComponent(CommonAlarmComponent, {
    //       panelClass: ['alarm-info'],
    //       verticalPosition: 'top',
    //       duration: 8000
    //     }
    //   );
    // }, 3000);
  }

  refresh() {
    this._snackBar.open('Refreshing...');

    // this.drvService.getAllDriver().subscribe((data: any) => {
    //   this.dataSource.data = data;
    //   this.table.renderRows();

    //   this._snackBar.dismiss();
    // }, error => {
    //   this._snackBar.open(error.message, null, {
    //     duration: 2000,
    //   });
    // });
  }

  onFilter(eventData) {
    console.log(eventData);
    var value = eventData.value;
    this.dataSource.filter = value.toLowerCase();
    // this.dataSource.filter = keyword;
  }

  // add() {
  //   let drv = {
  //     driverId: null,
  //     fullName: null,
  //     firstName: null,
  //     middleName: null,
  //     lastName: null,
  //     identificationId: null,
  //     identificationDocType: null,
  //     primaryPhone: null,
  //     secondaryPhone: null,
  //     address: null,
  //     joiningDate: null,
  //     isTemporary: 'N',
  //     lastDutyDate: null,
  //     driverStatus: 'NEW'
  //   };

  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.autoFocus = true;
  //   dialogConfig.data = drv;

  //   // this.dialog.open(PmDriverEditComponent, dialogConfig);
  // }

  // edit(drv: DriverElement) {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.data = drv;

  //   // this.dialog.open(PmDriverEditComponent, dialogConfig);
  // }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/master-data/drivers/2',
      'drivers',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }

  onEventDetails(data) {
    this.dialogConfig.data = data;
    this.dialog.open(AlarmDetailComponent, this.dialogConfig);
  }
}
