<div class="password-expiry-group" [ngStyle]="{'background-image': 'url(../../../assets/images/'+loginBg+')'}" fxLayout="row" fxLayoutAlign="space-around center" >
  <div fxFlex="40" class="bgWhite padding-10">
    <h1 mat-dialog-title>
      <ng-container *ngIf="isValidToken">Change Password</ng-container>
      <ng-container *ngIf="!isValidToken">Invalid Token</ng-container>
    </h1>
    <div mat-dialog-content class="body-content">
      <form *ngIf="isValidToken" [formGroup]="form" autocomplete="off">
  
        <div>
          <mat-form-field floatLabel="always" style="width: 100%" [color]="passwordComponent7.color">
            <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
            <mat-label>Password</mat-label>
            <input matInput #password formControlName="password" (input)="onPasswordConfirm($event.target.value)"
                   [type]="toggle.type"
                   placeholder="Password">
            <mat-hint align="end" aria-live="polite">
              {{password.value.length}} / {{passwordComponent7.max}}
            </mat-hint>
          </mat-form-field>
          <mat-password-strength #passwordComponent7
                                 class="green"
                                 [password]="password.value">
          </mat-password-strength>
          <mat-password-strength-info
            [passwordComponent]="passwordComponent7">
          </mat-password-strength-info>
        </div>
  
        <div class="mar-top-10">
  
          <mat-form-field floatLabel="always"  style="width: 100%">
            <mat-pass-toggle-visibility #confirmToggle matSuffix></mat-pass-toggle-visibility>
            <mat-label>Confirm Password</mat-label>
            <input matInput formControlName="confirmPassword" (input)="onPasswordConfirm($event.target.value)"
                    [type]="confirmToggle.type"
                    placeholder="Confirm Password" name="confirmPassword">
            <mat-error *ngIf="!isPasswordMatch">
              Passwords do not match
            </mat-error>  
          </mat-form-field>
        </div>
      </form>
      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!isValidToken">Token not valid.</div>
    </div>

    <div *ngIf="isValidToken" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
      </div>
      <button class="btn-sign" mat-raised-button type="button" (click)="onSave()" [disabled]="!form.valid">Submit</button>
    </div>
  </div>
</div>