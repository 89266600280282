<!-- <div *ngIf="!isNewWindow" >
    <app-account-header></app-account-header>
</div> -->
<div class="kpi-group">
  <div class="container">
    <div class="col">
      <div class="row">
        <h3>KPI<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <h5>Revenue Collection</h5>
      </div>
      <div class="row">
        <div class="pie-chart-parent">
          <ngx-charts-advanced-pie-chart
            [scheme]="colorScheme"
            [results]="service"
            [valueFormatting]="myYAxisTickFormatting"
            >
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <h5>Schedule Adherence</h5>
      </div>
      <div class="row">
        <div class="number-card-parent">
          <ngx-charts-number-card
          [scheme]="colorSchemeCard"
          [results]="single2"
          [animations]="false">
        </ngx-charts-number-card>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- <div class="col">
      <div class="row">
        <div class="col"><div class="kpi-1"></div></div>
        <div class="col"><div class="kpi-2"></div></div>
      </div>
      <div class="row">
        <div class="col"><div class="kpi-3"></div></div>
        <div class="col"></div>
      </div>
    </div> -->
  </div>
</div>