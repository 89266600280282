import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Subject, Subscription, timer } from 'rxjs';
import { VectorImage as VectorImageLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style.js';
import { transform } from 'ol/proj.js';
import OlFeature from 'ol/Feature';
import Point from 'ol/geom/Point';

import { ColorData } from '../../shared/others/constant';
import {
  map,
  repeat,
  repeatWhen,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { decode } from '@googlemaps/polyline-codec';
import { encodedPolylines } from './constant/polylines';
import { createTimeTables, getStop } from './demo-utils/demo-timetable';
import moment, { DurationInputArg2 } from 'moment';
import {
  computeTimeInterval,
  getNearestTimeSlot,
  getPrevTimeSlot,
  getTimeDifference,
} from './demo-utils/demo-timer';
import { DemoBusStopTimingService } from './demo-service/bus-stop-timing.service';
import { routeData, stopNames } from './constant/routes';

// minCoordinate: [103.59, 1.13],
// maxCoordinate: [104.1, 1.49],
function getRandom(min, max) {
  return (
    (Math.random() * (max * 100 - min * 100) + min * 100) /
    100
  ).toPrecision(10);
}

function getAngle(cx, cy, ex, ey) {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dx, dy); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
}

@Injectable({
  providedIn: 'root',
})
export class MockMapService {
  busList = [];
  busLayer;
  busListSubscription = [];
  busSubjects: Subject<void>[] = [];
  mapInstance;
  route;
  routePath;

  isInit = {
    7001: false,
    7002: false,
    7003: false,
  };

  busRouteFeatureList = {
    7001: [],
    7002: [],
    7003: [],
  };

  activeRoute;

  routePolylines = {};

  startedTrips = [];

  constructor(private demoBusStopTimingService: DemoBusStopTimingService) {
    const decodedPolylines = Object.entries(encodedPolylines).reduce(
      (prev, [route, polyline]) => {
        const decodedPolyline = decode(polyline);
        const reversedPolyline = [...decodedPolyline].reverse();
        prev[route] = [
          {
            value: 1,
            path: decodedPolyline,
          },
          {
            value: 2,
            path: reversedPolyline,
          },
        ];
        return prev;
      },
      {}
    );
    this.routePolylines = decodedPolylines;
    // console.log('polylines: ', this.routePolylines);

    // init all routes
    Object.entries(routeData).forEach(([route, routeData]) => {
      this.initBus(route, routeData, routeData.busCount, true);
    });
  }

  setActiveRoute(route) {
    this.activeRoute = route;
  }

  initBusLayer(mapInstance) {
    this.mapInstance = mapInstance;
    const layerName = 'bus-layer';
    const vectorLayer = new VectorImageLayer({
      source: new VectorSource({
        features: [],
        useSpatialIndex: false,
      }),
      renderBuffer: 16,
      className: layerName,
      properties: {
        name: layerName,
      },
    });

    vectorLayer.setZIndex(2);
    vectorLayer.setVisible(true);

    this.mapInstance.addLayer(vectorLayer);
    this.busLayer = vectorLayer;
  }

  initBus(route, routeData, numBus = 3, startup = false) {
    this.route = route;
    const { busCode, busArr, busId, busIdStr, stops, timeStop } = routeData;
    const newTimeCurrent = moment();
    const newTimeslot = getNearestTimeSlot(newTimeCurrent, 15); // scheduled trip starts
    // console.log('initbus', routeData);
    this.routePath = this.routePolylines[busId];
    const routePath = this.routePath;
    const currRoute = this.route;

    if (!this.isInit[route]) {
      this.isInit[route] = true;

      const nearestTrip = this.demoBusStopTimingService.findTripWithTimeslot(
        newTimeslot,
        route,
        1
      );

      // const assignedTrips = this.demoBusStopTimingService.getNextBlockTrips(
      //   route,
      //   numBus
      // );

      const assignedTrips = this.demoBusStopTimingService.getBlockTrips(
        route,
        15,
        5,
        true
      );

      // console.log('')

      // console.log(busIdStr, 'init trip schedule', {
      //   newTimeslot,
      //   timetable: this.demoBusStopTimingService.timetable,
      //   assignedTrips,
      //   nearestTrip,
      // });

      for (let i = 0; i < assignedTrips.length; i++) {
        const busPlate = busArr[i];
        // const busCodePlate = `${busCode}-${busPlate}`;
        // parse currentTrip
        const currTime = moment();
        let prevTimeSlot = getPrevTimeSlot(currTime, timeStop);
        let currTimeSlot = getNearestTimeSlot(currTime, timeStop);
        let nextTimeSlot = getNearestTimeSlot(currTime, timeStop);

        let assignedTrip = assignedTrips[i];

        const { blockId } = assignedTrip;
        const busCodePlate = blockId;

        let prevStop =
          getStop(assignedTrip.stops, prevTimeSlot) ?? assignedTrip.stops[0];
        let nextStop =
          getStop(assignedTrip.stops, nextTimeSlot) ?? assignedTrip.stops[1];

        prevTimeSlot = prevStop.schArrTime;
        nextTimeSlot = nextStop.schArrTime;

        // get timetable for blockId
        const blockTimeTableData =
          this.demoBusStopTimingService.getTimeTableByBlock(
            route,
            assignedTrip.blockId,
            assignedTrip.tripId
          );

        const { timeTableData: blockTimeTable, currentTripData } =
          blockTimeTableData;

        // timetable fill up data
        const { tripId: tempTripId } = assignedTrip;
        const { flatTimeTable: tempFlatTable } = blockTimeTable;

        const filteredFlatTable = tempFlatTable.filter(
          ttable => ttable.blockId === busCodePlate
        );

        const tempTripIndex = filteredFlatTable.findIndex(
          trip => trip.tripId === tempTripId
        );

        if (tempTripIndex > -1) {
          const slicedTrips = tempFlatTable.slice(0, tempTripIndex);
          // console.log(
          //   'zxc',
          //   busCodePlate,
          //   tempFlatTable,
          //   slicedTrips,
          //   assignedTrip,
          //   tempTripIndex
          // );

          slicedTrips.forEach(trip => {
            const { stops, tripId, direction } = trip;
            stops.forEach(stop => {
              const { schArrTime, stopId } = stop;
              const plusOrMinus = Math.random() < 0.5 ? -1 : 1;
              const addOrNot = Math.random() < 0.5 ? 0 : 1;
              this.demoBusStopTimingService.timetableArrival({
                routeId: route,
                direction: direction,
                tripId: tripId,
                stopId: stopId,
                obsArrTime: moment(schArrTime, 'HH:mm:ss')
                  .add(plusOrMinus * addOrNot, 'minute')
                  .format('HH:mm'),
                busRegNo: busCodePlate,
              });
            });
          });
        }

        let currTripHelpData = currentTripData;
        let { direction: currTripDirection, index: currTripIndex } =
          currentTripData;

        // let rotation = getRandom(0, 360);
        let rotation = 0;
        // const longitude = getRandom(103.59, 104.1);
        // const latitude = getRandom(1.13, 1.49);
        let currDir = currTripDirection
          ? +currTripDirection
          : prevStop?.direction
            ? +prevStop.direction
            : 1;
        let currPath = routePath.find(dir => dir.value === currDir)?.path;

        // console.log(
        //   'trip: ',
        //   busCodePlate,
        //   i,
        //   currTime.format('HH:mm:ss'),
        //   assignedTrip,
        //   prevStop,
        //   nextStop,
        //   currDir
        // );

        // console.log('trip timeslots: ', busCodePlate, {
        //   prevTimeSlot,
        //   currTimeSlot,
        //   nextTimeSlot,
        // });

        const busStops = stops;
        // console.log('bus stops', stops);
        let currStop = busStops.find(stop => stop.direction === currDir);

        if (
          this.busList.findIndex(
            busItem => busItem?.data?.busCode === busCodePlate
          ) < 0
        ) {
          // const currStopSequence = stops.find(
          //   stop => stop.direction === currDir
          // ).stop;

          let pathStop = currStop.stop;

          // findPrev pathIndex and next pathIndex
          let prevStopSequence =
            pathStop.findIndex(
              stopSequence => stopSequence.stopCode === prevStop?.stopId
            ) ?? 0;

          // console.log(busCodePlate, 'path stop', pathStop, prevStopSequence);
          const prevStopObj = pathStop?.[prevStopSequence] ?? undefined;
          const prevStopIndex = prevStopObj?.index ?? 0;

          const nextStopIndex =
            pathStop.find(
              stopSequence => stopSequence.stopCode === nextStop?.stopId
            )?.index ?? 0;

          const totalTimeDiff = getTimeDifference(prevTimeSlot, nextTimeSlot);
          const prevTimeDiff = getTimeDifference(
            prevTimeSlot,
            currTime.format('HH:mm:ss')
          );

          const nextTimeDiff = Math.min(
            getTimeDifference(currTime.format('HH:mm:ss'), nextTimeSlot),
            totalTimeDiff
          );

          const isLastStop = (+prevStop?.stopSequence ?? 0) === pathStop.length;
          const isFirstStop = prevStop?.schArrTime === nextStop.schArrTime;

          const timePercent = 1 - nextTimeDiff / totalTimeDiff;

          // record previous stops arrival in timetable
          console.log(busCodePlate, 'current trip', {
            prevStopObj,
            assignedTrip,
            prevStop,
            isLastStop,
            isFirstStop,
            prevTimeDiff,
          });

          if (!isLastStop && !isFirstStop && prevStop && prevTimeDiff > 0) {
            const { stopSequence: endTimetableStopSequence } = prevStop;
            const { stops: timetableStops } = assignedTrip;
            for (let i = 0; i < endTimetableStopSequence; i++) {
              const currTimeTableStop = timetableStops[i];
              if (currTimeTableStop) {
                this.demoBusStopTimingService.timetableArrival({
                  routeId: route,
                  direction: currDir,
                  tripId: assignedTrip.tripId,
                  stopId: currTimeTableStop.stopId,
                  obsArrTime: moment(
                    currTimeTableStop.schArrTime,
                    'HH:mm:ss'
                  ).format('HH:mm'),
                  busRegNo: busCodePlate,
                });
              }
            }
          }

          // console.log(
          //   busCodePlate,
          //   'time diff',
          //   prevTimeDiff,
          //   nextTimeDiff,
          //   totalTimeDiff
          // );

          // console.log('time index', busCodePlate, {
          //   prevStopIndex,
          //   nextStopIndex,
          //   timePercent,
          // });

          const prevIndex = isFirstStop
            ? 0
            : prevStopIndex +
              Math.floor((nextStopIndex - prevStopIndex) * timePercent);

          let sequenceIndex = isLastStop ? 0 : prevStopSequence;
          let pathIndex = isFirstStop || isLastStop ? 0 : prevIndex;

          // if last stop need to get next trip and reverse direction
          if (isLastStop) {
            currDir = currDir === 1 ? 2 : 1;
            currPath = routePath.find(dir => dir.value === currDir)?.path;
            currStop = busStops.find(stop => stop.direction === currDir);
            pathStop = currStop.stop;
          }

          // console.log(
          //   'trip stop obj: ',
          //   busCodePlate,
          //   currStop,
          //   // stopObj,
          //   prevStopIndex,
          //   prevIndex,
          //   timePercent,
          //   nextStopIndex,
          //   isFirstStop,
          //   isLastStop,
          //   pathIndex
          // );

          // console.log(
          //   'coord check',
          //   busCodePlate,
          //   currPath,
          //   pathIndex,
          //   routePath
          // );

          const coordItem = currPath[pathIndex];
          let [latitude, longitude] = coordItem;
          if (pathIndex < currPath.length) {
            const newCoords = currPath[pathIndex];
            const [newLat, newLong] = newCoords;
            rotation = getAngle(+longitude, +latitude, +newLong, +newLat);
            // console.log(
            //   'rotation: ',
            //   rotation,
            //   +longitude,
            //   +latitude,
            //   +newLong,
            //   +newLat
            // );
          }

          const notOnTrip = prevTimeDiff < 0;
          const { blockId, tripId } = assignedTrip;
          const busData = {
            status: notOnTrip ? '2' : '0',
            lon: longitude,
            lat: latitude,
            degrees: rotation,
            route,
            trip: tripId,
            busCode: busCodePlate,
            description: `${busCode}-bus-${i + 1}`,
            busFeature: null,
            blockId,
            tripId,

            // stop info
            stops: [
              { stop: '', eta: '-' },
              { stop: '', eta: '-' },
              { stop: '', eta: '-' },
            ],

            // bus ladder info
            prevStopId: '',
            nextStopId: '',
            drivenPercentage: 0,
            direction: `${currDir}`,
          };

          // add trip id to list
          this.startedTrips.push(tripId);

          const newBus = this.addBus(busData);

          if (!startup) {
            this.busLayer.getSource().addFeature(newBus);
          }

          this.busRouteFeatureList[route].push(newBus);

          const prevBusStop = pathStop[sequenceIndex].index;
          const nextBusStop =
            sequenceIndex >= pathStop.length - 1
              ? currPath.length
              : pathStop[sequenceIndex + 1].index;

          // setup initial stop data
          // console.log(
          //   busCodePlate,
          //   'next bus stop init',
          //   { nextTimeDiff },
          //   pathStop
          // );
          const initPrevStop = pathStop[sequenceIndex] ?? undefined;
          const initFirstStop = pathStop?.[sequenceIndex + 1] ?? undefined;
          // console.log(
          //   'init bus ladder',
          //   busCodePlate,
          //   busData,
          //   initPrevStop?.stopCode,
          //   initFirstStop?.stopCode,
          //   timePercent * 100
          // );

          this.updateDemoBusLadder(
            busData,
            initPrevStop?.stopCode,
            initFirstStop?.stopCode,
            timePercent * 100
          );

          if (!notOnTrip) {
            const initFirstStop = pathStop?.[sequenceIndex + 1] ?? undefined;
            const initSecondStop = pathStop?.[sequenceIndex + 2] ?? undefined;
            const initThirdStop = pathStop?.[sequenceIndex + 3] ?? undefined;
            const initStopsEta = [initFirstStop, initSecondStop, initThirdStop];

            busData.stops = busData.stops.map((_, index) =>
              this.updateBusStopEta(
                initStopsEta[index],
                nextTimeDiff + 300000 * index,
                'millisecond'
              )
            );
          }

          let isTest = false;
          const testInterval = 10;
          // let interval = computeTimeInterval(prevBusStop, nextBusStop);
          // const initGoalTime = 300000; // 5 minutes in MS = 300000, 30s = 30000;
          let interval = isTest
            ? testInterval
            : isFirstStop
              ? computeTimeInterval(prevBusStop, nextBusStop)
              : computeTimeInterval(prevIndex, nextBusStop, nextTimeDiff);

          // console.log(busCodePlate, 'start interval', interval);
          const indexSubject = new BehaviorSubject(interval);

          let delaySub = isTest ? 0 : prevTimeDiff < 0 ? prevTimeDiff * -1 : 0;

          const _stop = new Subject<void>();
          const _start = new Subject<void>();
          // create subscription to move buses
          function start(): void {
            _start.next();
          }

          function stop(): void {
            _stop.next();
          }

          function changeInterval(newInterval: number) {
            delaySub = 0;
            interval = newInterval;
            if (isTest) {
              indexSubject.next(testInterval);
            } else {
              indexSubject.next(interval);
            }
          }

          const busObservable = indexSubject.pipe(
            switchMap(duration => timer(delaySub, duration)),
            map(x => x + pathIndex + 1),
            takeUntil(_stop),
            repeatWhen(() => _start)
          );

          let prevStopPathIndex = prevBusStop ?? 1;
          let nextStopPathIndex = nextBusStop;

          const initPosition = this.computePercent(
            prevStopPathIndex,
            nextStopPathIndex,
            pathIndex
          );
          busData.drivenPercentage = initPosition;

          const busSubscription = busObservable.subscribe(x => {
            if (x <= currPath.length) {
              const busStopIndex = currStop.stop.findIndex(
                stop => stop.index === x
              );

              /**
               * call timetable update here
               */
              if (busStopIndex > -1) {
                const currTime = moment().format('HH:mm');
                const currBusStop = currStop.stop[busStopIndex];
                const nextBusStop =
                  currStop.stop[busStopIndex + 1] ?? undefined;
                const secondBusStop =
                  currStop.stop?.[busStopIndex + 2] ?? undefined;
                const thirdBusStop =
                  currStop.stop?.[busStopIndex + 3] ?? undefined;

                // update busladder
                this.updateDemoBusLadder(
                  busData,
                  currBusStop?.stopCode,
                  nextBusStop?.stopCode,
                  0
                );

                const stopsEtaData = [nextBusStop, secondBusStop, thirdBusStop];

                const { index } = currBusStop;

                prevStopPathIndex = index ?? 1;
                nextStopPathIndex = nextBusStop?.index;

                console.log(busCodePlate, currDir, 'Bus Stop', {
                  x,
                  busData,
                  currBusStop,
                  nextBusStop,
                  currTime,
                  index,
                });
                const { tripId } = assignedTrip;
                const { stopCode } = currBusStop;

                // update stops
                busData.stops = busData.stops.map((_, index) =>
                  this.updateBusStopEta(stopsEtaData[index], 5 * (index + 1))
                );

                this.demoBusStopTimingService.timetableArrival({
                  routeId: route,
                  direction: currDir,
                  tripId,
                  stopId: stopCode,
                  obsArrTime: currTime,
                  busRegNo: busCodePlate,
                });

                // update interval
                if (nextBusStop) {
                  pathIndex = index;
                  const newInterval = computeTimeInterval(
                    index,
                    nextBusStop.index
                  );
                  // console.log('new interval', newInterval);
                  changeInterval(newInterval);
                }
              }

              if (x < currPath.length) {
                const newCoordinates = currPath[x];
                const [newLat, newLong] = newCoordinates;

                newBus
                  .getGeometry()
                  .setCoordinates(
                    transform(
                      [Number(newLong), Number(newLat)],
                      'EPSG:4326',
                      'EPSG:3857'
                    )
                  );

                const newRotation = getAngle(
                  +longitude,
                  +latitude,
                  +newLong,
                  +newLat
                );

                busData.lat = newLat;
                busData.lon = newLong;
                busData.degrees = newRotation;

                // update bus ladder position

                const newDrivenPercentage = this.computePercent(
                  prevStopPathIndex,
                  nextStopPathIndex,
                  x
                );
                // console.log('zpercent', busCodePlate, {
                //   prevStopPathIndex,
                //   nextStopPathIndex,
                //   x,
                //   newDrivenPercentage,
                // });
                busData.drivenPercentage = newDrivenPercentage;

                // update early / late here
                busData.status = '0';

                const newBusStyle = this.createNewBusStyle(busData);
                newBus.setStyle(newBusStyle);

                latitude = newLat;
                longitude = newLong;
              }
            }

            if (x >= currPath.length) {
              pathIndex = 0;
              stop();

              currDir = currDir === 1 ? 2 : 1;
              currPath = routePath.find(dir => dir.value === currDir)?.path;

              currStop = busStops?.find(stop => stop.direction === currDir);
              const prevStop = currStop.stop[0];
              const nextStop = currStop.stop[1];

              currTripIndex = currTripIndex + 1;

              const { flatTimeTable } = blockTimeTable;

              console.log(
                'finished trip',
                busCodePlate,
                tripId,
                busData,
                assignedTrip,
                { currTripIndex, currDir },
                flatTimeTable.length,
                flatTimeTable
              );

              // clear all stops ETA
              busData.stops.forEach(stop => {
                stop.stop = '';
                stop.eta = '-';
              });

              // clear bus stop ladder
              // this.updateDemoBusLadder(busData, undefined, undefined, 0);

              busData.status = '2';
              const newBusStyle = this.createNewBusStyle(busData);
              newBus.setStyle(newBusStyle);

              if (currTripIndex < flatTimeTable.length) {
                assignedTrip = flatTimeTable[currTripIndex];
                const { blockId, tripId } = assignedTrip;
                busData.trip = tripId;
                busData.tripId = tripId;
                busData.blockId = blockId;
                busData.status = '2';
                busData.direction = `${currDir}`;

                const currTime = moment().format('HH:mm');
                const nextTime = assignedTrip.stops[0].schArrTime;

                const waitTime = getTimeDifference(currTime, nextTime);

                // console.log(
                //   busCodePlate,
                //   busData,
                //   tripId,
                //   assignedTrip,
                //   currDir,
                //   { prevStop, nextStop },
                //   `Next Trip (${nextTime}) in: `,
                //   waitTime / 1000 / 60,
                //   'm'
                // );

                this.updateDemoBusLadder(
                  busData,
                  prevStop.stopCode,
                  prevStop.stopCode,
                  0
                );

                setTimeout(
                  () => {
                    const newInterval = computeTimeInterval(0, nextStop.index);
                    changeInterval(newInterval);
                    start();
                    const currBusStop = currStop.stop[0];
                    const { stopCode } = currBusStop;
                    this.demoBusStopTimingService.timetableArrival({
                      routeId: route,
                      direction: currDir,
                      tripId,
                      stopId: stopCode,
                      obsArrTime: currTime,
                      busRegNo: busCodePlate,
                    });

                    // set eta stops
                    const nextBusStop = currStop.stop[1] ?? undefined;
                    const secondBusStop = currStop.stop?.[2] ?? undefined;
                    const thirdBusStop = currStop.stop?.[3] ?? undefined;

                    const stopsEtaData = [
                      nextBusStop,
                      secondBusStop,
                      thirdBusStop,
                    ];
                    busData.stops = busData.stops.map((_, index) =>
                      this.updateBusStopEta(
                        stopsEtaData[index],
                        5 * (index + 1)
                      )
                    );

                    // set new busladder
                    this.updateDemoBusLadder(
                      busData,
                      currBusStop.stopCode,
                      nextBusStop.stopCode,
                      0
                    );

                    prevStopPathIndex = 1;
                    nextStopPathIndex = nextBusStop.index;
                  },
                  isTest ? 2000 : waitTime
                );
              } else {
                console.log('finished all trips', busCodePlate);
              }
            }
          });

          this.busListSubscription.push(busSubscription);
          this.busSubjects.push(_start);
          this.busSubjects.push(_stop);

          this.busList.push({ data: busData, busFeature: newBus });
        } else {
          const currBus = this.busList.find(
            busItem => busItem?.data?.busCode === busCodePlate
          );
          const { busFeature } = currBus;
          this.busLayer.getSource().addFeature(busFeature);
        }
      }
    } else {
      // add each bus to bus layer
      this.busRouteFeatureList[currRoute].forEach(bus => {
        this.busLayer.getSource().addFeature(bus);
      });
    }

    // console.log('buses', this.busList, this.busLayer.getSource().getFeatures());
    if (!startup) {
      this.busLayer.setVisible(true);
    }
  }

  computePercent(fromValue: number, toValue: number, currValue: number) {
    if (
      fromValue === undefined ||
      toValue === undefined ||
      currValue === undefined
    )
      return 0;
    const percentValue =
      ((currValue - fromValue) / (toValue - fromValue)) * 100;
    return percentValue < 0 ? 100 : percentValue;
  }

  updateDemoBusLadder(busData, prevStopCode, nextStopCode, percent) {
    // console.log('update bus ladder', {
    //   busData,
    //   prevStopCode,
    //   nextStopCode,
    //   percent,
    // });
    if (!busData) return;
    if (!prevStopCode || !nextStopCode) {
      busData.prevStopId = '';
      busData.nextStopId = '';
      busData.drivenPercentage = 0;
      return;
    }
    busData.prevStopId = prevStopCode ?? '';
    busData.nextStopId = nextStopCode ?? '';
    busData.drivenPercentage = percent ?? 0;
  }

  updateBusStopEta(
    busStopData,
    time = 5,
    timeUnit: DurationInputArg2 = 'minute'
  ) {
    if (busStopData) {
      const stop = stopNames[busStopData.stopCode] ?? busStopData.stopCode;
      const eta = moment().add(time, timeUnit).format('HH:mm');
      return { stop, eta };
    }
    return { stop: '', eta: '-' };
  }

  clearBus() {
    this.route = undefined;
    this.routePath = undefined;

    var features = this.busLayer.getSource().getFeatures();

    for (let feature of features) {
      this.busLayer.getSource().removeFeature(feature);
    }
    // this.busList = [];

    // if (this.busListSubscription.length > 0) {
    //   this.busListSubscription.forEach(sub => sub?.unsubscribe());
    //   this.busListSubscription = [];
    // }

    // if (this.busSubjects.length > 0) {
    //   this.busSubjects.forEach(subj => subj?.complete());
    //   this.busSubjects = [];
    // }
  }

  addBus(dataBus) {
    let busColor = '';
    var bus2Style = new Style({});
    let caret = '';
    var textColor = '#fff';

    if (dataBus.status === '0') {
      // on-time
      busColor = ColorData.eGreen;
      caret = 'location-green-32.png';
    } else if (dataBus.status === '-1') {
      // early
      busColor = ColorData.eYellow;
      caret = 'location-yellow-32.png';
      textColor = '#000';
    } else if (dataBus.status === '1') {
      // late
      busColor = ColorData.eRed;
      caret = 'location-red-32.png';
    } else if (dataBus.status === '2') {
      // not on trip
      busColor = ColorData.eGray;
      caret = 'location-gray-32.png';
    } else if (dataBus.status === '3') {
      // ?
      busColor = ColorData.eOrange;
      caret = 'location-orange-32.png';
    }

    var bus1 = new OlFeature({
      type: 'icon',
      geometry: new Point(
        transform(
          [parseFloat(dataBus.lon), parseFloat(dataBus.lat)],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      name: 'bus-' + dataBus.busCode,
      description: dataBus,
    });

    var bus1Style = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        // opacity: 1,
        src: 'assets/images/icon/' + caret,
        scale: 0.5,
        rotation: (parseFloat(dataBus.degrees) * Math.PI) / 180,
        // rotation: 60 * 0.01745329251,  // in rad / 360° = 6.28318531 rad = 2PI rad
        rotateWithView: true,
      }),

      text: new Text({
        offsetY: -24,
        text: dataBus.busCode,
        fill: new Fill({
          color: textColor,
        }),
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'middle',
        padding: [5, 5, 5, 5],
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
      }),
    });

    // rounded background
    const squareText = '\u25A0'.repeat(dataBus.busCode.length);
    const squareBgTextStyle = new Style({
      text: new Text({
        text: squareText,
        offsetY: -26,
        fill: new Fill({
          color: busColor,
        }),
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'middle',
        padding: [5, 5, 5, 5],
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
        stroke: new Stroke({
          color: busColor,
          width: 21,
        }),
      }),
    });

    if (dataBus.isSelected) {
      bus2Style = new Style({
        image: new Icon({
          anchor: [0.5, 1.6],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          // opacity: 1,
          src: 'assets/images/icon/map-marker-orange.png',
        }),
        zIndex: 2,
      });
    }
    bus1.setStyle([squareBgTextStyle, bus1Style]);
    return bus1;
  }

  createNewBusStyle(dataBus) {
    let busColor = '';
    let caret = '';
    var textColor = '#fff';

    if (dataBus.status === '0') {
      // on-time
      busColor = ColorData.eGreen;
      // caret = 'caret-green-32.png';
      caret = 'location-green-32.png';
    } else if (dataBus.status === '-1') {
      // early
      busColor = ColorData.eYellow;
      // caret = 'caret-yellow-32.png';
      caret = 'location-yellow-32.png';
      textColor = '#000';
    } else if (dataBus.status === '1') {
      // late
      busColor = ColorData.eRed;
      // caret = 'caret-red-32.png';
      caret = 'location-red-32.png';
    } else if (dataBus.status === '2') {
      // not on trip
      busColor = ColorData.eGray;
      // caret = 'caret-red-32.png';
      caret = 'location-gray-32.png';
    } else if (dataBus.status === '3') {
      // ?
      busColor = ColorData.eOrange;
      caret = 'location-orange-32.png';
    }
    var bus1Style = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        // opacity: 1,
        src: 'assets/images/icon/' + caret,
        scale: 0.5,
        rotation: (parseFloat(dataBus.degrees) * Math.PI) / 180,
        // rotation: 60 * 0.01745329251,  // in rad / 360° = 6.28318531 rad = 2PI rad
        rotateWithView: true,
      }),

      text: new Text({
        offsetY: -24,
        text: dataBus.busCode,
        fill: new Fill({
          color: textColor,
        }),
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'middle',
        padding: [5, 5, 5, 5],
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
      }),
    });

    // rounded background
    const squareText = '\u25A0'.repeat(dataBus.busCode.length);
    const squareBgTextStyle = new Style({
      text: new Text({
        text: squareText,
        offsetY: -26,
        fill: new Fill({
          color: busColor,
        }),
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'middle',
        padding: [5, 5, 5, 5],
        font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
        stroke: new Stroke({
          color: busColor,
          width: 21,
        }),
      }),
    });

    return [squareBgTextStyle, bus1Style];
  }
}
