import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Inject,
  Injectable,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

declare var OSMBuildings: any;
import * as mapboxgl from 'mapbox-gl';

import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var H: any;

@Component({
  selector: 'app-demo-stop-modal',
  templateUrl: './demo-stop-modal.component.html',
  styleUrls: ['./demo-stop-modal.component.scss'],
})
export class DemoStopModalComponent {
  // @Input() data:any;
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveAction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('btnClose') btnClose: ElementRef;
  public config: PerfectScrollbarConfigInterface = {};

  project = environment.project;

  form: FormGroup;
  isvalid: boolean = false;
  // trainStation = [];
  OSMB: any;
  glJson: any;
  tileMap: any;
  map: any;
  newLatLon: any;
  running = false;
  // @ViewChild("VCMap") VCMap: ElementRef;
  allTrainStation: any[] = [];
  selectedTrainStation: any[] = [];

  //HERE Map
  platform: any;
  mapInstance: any;
  @ViewChild('mapHere', { static: true }) public mapElement: ElementRef;
  ui: any;
  stopData;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DemoStopModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    // this.data = dialogData;
    mapboxgl.accessToken = environment.map.mapBoxAccessToken;

    this.platform = new H.service.Platform({
      apikey: environment.hereApiKey,
    });

    this.form = this.formBuilder.group({
      streetName: ['', Validators.required],
      stopCode: ['', Validators.required],
      stopName: ['', Validators.required],
      trainStation: [''],
      lat: [
        '',
        [
          Validators.required,
          Validators.min(environment.map.boundingExtent.min[1]),
          Validators.max(environment.map.boundingExtent.max[1]),
        ],
      ],
      lon: [
        '',
        [
          Validators.required,
          Validators.min(environment.map.boundingExtent.min[0]),
          Validators.max(environment.map.boundingExtent.max[0]),
        ],
      ],
      busTerminal: [''],
    });
  }

  ngOnInit() {
    this.initStop();
  }

  formWatcherSub;
  initFormWatcher() {
    if (this.formWatcherSub) this.formWatcherSub?.unsubscribe();
    this.formWatcherSub = this.form.controls['lat'].valueChanges.subscribe(
      value => {
        // console.log('form err', getFormValidationErrors(this.form));
        const lng = this.form.controls['lon'].value;
        const isValid =
          (this.form.controls['lat'].valid ?? false) &&
          (this.form.controls['lon'].valid ?? false) &&
          !isNaN(lng) &&
          !isNaN(value);
        // console.log('lat', isValid, value, this.form.controls['lat'].value);
        if (isValid) {
          // heremap
          this.hereMarker?.setGeometry({ lat: value, lng });
          this.mapInstance?.setCenter({ lat: value, lng });

          // mapbox
          this.mapboxMarker?.setLngLat([lng, value]);
          this.map?.panTo([lng, value], { duration: 1000 });
        }
      }
    );
    this.form.controls['lon'].valueChanges.subscribe(value => {
      const lat = this.form.controls['lat'].value;
      const isValid =
        (this.form.controls['lat'].valid ?? false) &&
        (this.form.controls['lon'].valid ?? false) &&
        !isNaN(lat) &&
        !isNaN(value);

      if (isValid) {
        // heremap
        this.hereMarker?.setGeometry({ lat, lng: value });
        this.mapInstance?.setCenter({ lat, lng: value });

        // mapbox
        this.mapboxMarker?.setLngLat([value, lat]);
        this.map?.panTo([value, lat], { duration: 1000 });
      }
    });
  }

  initBaguio() {
    if (this.dialogData.action === 'add') {
      this.stopData = {
        stops: [
          {
            stopLon: environment.map.map3dLocation[0],
            stopLat: environment.map.map3dLocation[1],
          },
        ],
      };
    }
    this.initMapBox();
    this.initMapboxMark();
    this.initFormWatcher();
  }

  initTrainStations() {}

  compareWithFunc(a, b) {
    return a.stnCode === b.stnCode;
  }

  initStop() {
    // console.log('dialog data', this.dialogData);
    const { stops, streetName } = this.dialogData;
    this.stopData = { streetName, stops };
    setTimeout(() => {
      this.initMapBox();
      this.initMapboxMark();
      this.initFormWatcher();
      this.initForm();
    }, 300);
  }

  initForm() {
    if (this.dialogData.action === 'edit') {
      this.formReset();
    }
  }

  // mapbox marker event handler
  mapboxMarker;
  initMapboxMark() {
    var me = this;

    const formLon = this.form.controls['lon'].value;
    const formLat = this.form.controls['lat'].value;

    this.mapboxMarker = new mapboxgl.Marker({
      draggable: true,
      // element: `assets/maps/icons/marker-green.png`
      color: 'green',
    })
      .setLngLat([
        formLon > 0 ? formLon : this.stopData.stops[0].stopLon,
        formLat > 0 ? formLat : this.stopData.stops[0].stopLat,
      ])
      .addTo(this.map);

    const marker = this.mapboxMarker;

    function onDragEnd() {
      var lngLat = marker.getLngLat();
      me.form.patchValue({
        lat: lngLat.lat,
        lon: lngLat.lng,
      });
    }

    marker.on('dragend', onDragEnd);
  }

  initMapBox() {
    var me = this;
    const formLon = this.form.controls['lon'].value;
    const formLat = this.form.controls['lat'].value;
    this.map = new mapboxgl.Map({
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [
        formLon > 0 ? formLon : this.stopData.stops[0].stopLon,
        formLat > 0 ? formLat : this.stopData.stops[0].stopLat,
      ],
      zoom: 17,
      pitch: 45,
      bearing: 0,
      container: 'edit-map',
      antialias: true,
      attributionControl: false,
    });

    this.map.on('load', function () {
      // Insert the layer beneath any symbol layer.
      var layers = me.map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      me.map.addLayer(
        {
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
            'fill-extrusion-color': '#aaa',

            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              16,
              ['get', 'height'],
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              16,
              ['get', 'min_height'],
            ],
            'fill-extrusion-opacity': 0.6,
          },
        },
        labelLayerId
      );
    });
    setTimeout(() => {
      this.map.resize();
    }, 10);
  }

  formReset() {
    var latLonRegEx =
      '^(+|-)?(?:90(?:(?:.0{6,16})?)|(?:[0-9]|[1-8][0-9])(?:(?:.[0-9]{6,16})?))$';
    this.isvalid = false;
    this.form.patchValue({
      streetName: this.stopData.streetName,
      stopCode: this.stopData.stops[0].stopCode,
      stopName: this.stopData.stops[0].stopName,
      trainStation: this.selectedTrainStation,
      lat: this.stopData.stops[0].stopLat,
      lon: this.stopData.stops[0].stopLon,
      busTerminal: this.stopData.stops[0].busTerminal,
    });
    //   streetName: [this.stopData.streetName, Validators.required],
    //   stopCode: [this.stopData.stops[0].stopCode, Validators.required],
    //   stopName: [this.stopData.stops[0].stopName, Validators.required],
    //   trainStation: [this.selectedTrainStation], //[this.stopData.stops[0].trainStation],
    //   lat: [
    //     this.stopData.stops[0].stopLat,
    //     [
    //       Validators.required,
    //       Validators.min(environment.map.boundingExtent.min[1]),
    //       Validators.max(environment.map.boundingExtent.max[1]),
    //       // Validators.pattern(
    //       //   /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{6,16})?))$/
    //       // ),
    //     ],
    //   ],
    //   lon: [
    //     this.stopData.stops[0].stopLon,
    //     [
    //       Validators.required,
    //       Validators.min(environment.map.boundingExtent.min[0]),
    //       Validators.max(environment.map.boundingExtent.max[0]),
    //       // Validators.pattern(
    //       //   /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{6,16})?))$/
    //       // ),
    //     ],
    //   ],
    //   busTerminal: [this.stopData.stops[0].busTerminal],
    // });
  }

  async saveStop() {
    return false;
  }

  closeModal() {
    this.dialogRef.close(this.dialogData);
  }

  onCloseModal() {
    if (this.dialogData.action === 'edit') {
      this.formReset();
    }

    this.dialogData.stopLat = this.form.value.lat;
    this.dialogData.stopLon = this.form.value.lon;
    this.dialogData.stopName = this.form.value.stopName;
    this.dialogRef.close(this.dialogData);
    return false;
  }

  initHereMap() {
    var hereData = this.stopData.stops[0];

    var pixelRatio = window.devicePixelRatio || 1;
    var defaultLayers = this.platform.createDefaultLayers();

    //Step 2: initialize a map
    const formLon = this.form.controls['lon'].value;
    const formLat = this.form.controls['lat'].value;

    this.mapInstance = new H.Map(
      this.mapElement.nativeElement,
      // document.getElementById('mapHere'),
      defaultLayers.vector.normal.map,
      // defaultLayers.raster.normal.map,
      {
        center: {
          lat: formLat > 0 ? formLat : hereData.stopLat,
          lng: formLon > 0 ? formLon : hereData.stopLon,
        },
        zoom: 18,
        pixelRatio: pixelRatio,
        padding: { top: 50, left: 70, bottom: 50, right: 70 },
      }
    );

    // adjust tilt and rotation of the map
    this.mapInstance.getViewModel().setLookAtData({
      tilt: 45,
      // heading: 60
    });

    // this.mapInstance.setBaseLayer(tileLayer);

    window.addEventListener('resize', () =>
      this.mapInstance.getViewPort().resize()
    );
    var behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(this.mapInstance)
    );
    this.ui = H.ui.UI.createDefault(this.mapInstance, defaultLayers);
    this.ui.removeControl('mapsettings');
    this.initHereMark(this.mapInstance, behavior);
  }

  tabChanged(event) {
    if (event.index === 0) {
      this.initMapBox();
      this.initMapboxMark();
    } else if (!this.mapInstance) {
      this.initHereMap();
    }
  }

  // init here map marker
  hereMarker;
  initHereMark(map, behavior) {
    var me = this;
    var iconImage = new H.map.Icon('assets/maps/icons/marker-green.png');

    const formLon = this.form.controls['lon'].value;
    const formLat = this.form.controls['lat'].value;

    this.hereMarker = new H.map.Marker(
      {
        lat: formLat > 0 ? formLat : this.stopData.stops[0].stopLat,
        lng: formLon > 0 ? formLon : this.stopData.stops[0].stopLon,
      },
      {
        // mark the object as volatile for the smooth dragging
        volatility: true,
        icon: iconImage,
      }
    );

    // Ensure that the marker can receive drag events
    this.hereMarker.draggable = true;
    map.addObject(this.hereMarker);

    // var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.mapInstance));
    map.addEventListener(
      'dragstart',
      function (ev) {
        var target = ev.target,
          pointer = ev.currentPointer;
        // console.log(target.getGeometry());

        if (target instanceof H.map.Marker) {
          var targetPosition = map.geoToScreen(target.getGeometry());
          target['offset'] = new H.math.Point(
            pointer.viewportX - targetPosition.x,
            pointer.viewportY - targetPosition.y
          );
          behavior.disable();
        }
      },
      false
    );

    map.addEventListener(
      'dragend',
      function (ev) {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
          behavior.enable();
          me.form.patchValue({
            lat: target.getGeometry().lat,
            lon: target.getGeometry().lng,
          });
        }
      },
      false
    );

    map.addEventListener(
      'drag',
      function (ev) {
        var target = ev.target,
          pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          target.setGeometry(
            map.screenToGeo(
              pointer.viewportX - target['offset'].x,
              pointer.viewportY - target['offset'].y
            )
          );
        }
      },
      false
    );
  }
}
