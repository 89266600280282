import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexYAxis,
  ApexXAxis,
  ApexPlotOptions,
  ApexDataLabels,
  ApexStroke
} from "ng-apexcharts";
import { CommonService } from 'src/app/component/shared/services/common.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
};

export const barData = [
  {
    x: 'SBS111',
    y: 33.85,
    data: {
      date: "Jan 3, 2021"
    }
  },
  {
    x: 'SBS222',
    y: 12.95,
    data: {
      date: "Jan 1, 2021"
    }
  },
  {
    x: 'SBS333',
    y: 52.48,
    data: {
      date: "Jan 2, 2021"
    }
  },
  
  {
    x: 'SBS111',
    y: -33.85,
    data: {
      date: "Jan 3, 2021"
    }
  },
  {
    x: 'SBS222',
    y: 12.95,
    data: {
      date: "Jan 1, 2021"
    }
  },
  {
    x: 'SBS333',
    y: -52.48,
    data: {
      date: "Jan 2, 2021"
    }
  }
];

export const candleData = [
  {
    x: '11',
    y: [51.98, 56.29, 51.59, 53.85],
    data: {
      operator: "SBS",
      other: "test1"
    }
  },
  {
    x: '22',
    y: [53.66, 54.99, 51.35, 52.95],
    data: {
      operator: "SMRT",
      other: "test2"
    }
  },
  {
    x: '33',
    y: [52.96, 53.78, 51.54, 52.48],
    data: {
      operator: "SBS",
      other: "test3"
    }
  },
  {
    x: '111',
    y: [51.98, 56.29, 51.59, 53.85],
    data: {
      operator: "SBS",
      other: "test1"
    }
  },
  {
    x: '222',
    y: [53.66, 54.99, 51.35, 52.95],
    data: {
      operator: "SMRT",
      other: "test2"
    }
  },
  {
    x: '333',
    y: [52.96, 53.78, 51.54, 52.48],
    data: {
      operator: "SBS",
      other: "test3"
    }
  }
];

// export const candleData = [
//   {
//     x: 'SBS1234',
//     y: 33.85,
//     data: {
//       date: "Jan 3, 2021"
//     }
//   },
//   {
//     x: 'SBS1234',
//     y: 12.95,
//     data: {
//       date: "Jan 1, 2021"
//     }
//   },
//   {
//     x: 'SBS1234',
//     y: 52.48,
//     data: {
//       date: "Jan 2, 2021"
//     }
//   }
// ];

@Component({
  selector: 'app-candle-chart',
  templateUrl: './candle-chart.component.html',
  styleUrls: ['./candle-chart.component.scss']
})
export class CandleChartComponent implements OnInit {

  @ViewChild("chart") chart: any;
  public chartCandleOptions: Partial<any>;
  public chartBarOptions: Partial<ChartComponent>;
  isRunning: boolean = false;
  vehicleList = [];
  
  form: FormGroup;
  
  constructor(
    private _commonService: CommonService,
    private _formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.initForm();
    this.initCandleChart2();
  }

  initCandleChart2() {
    this.isRunning = true;

    // var urlAction = 'https://ngbms-sg.com:8080/cjb/api/v1/getChargingInfo?date=2022-01-01&timezone=8';

    // this._commonService.commonGetFullUrlAction(urlAction).subscribe(
    //   retData => {
    //     retData.socCharges.forEach(element => {
    //       this.vehicleList.push(element.x);
    //     });

        this.chartCandleOptions = {
          series: [
            {
              name: "candle",
              data: [
                {
                    "x": "SG3070R",
                    "y": [
                        93.0,
                        100.0,
                        68.0,
                        86.5
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SBST"
                    }
                },
                {
                    "x": "SG3072K",
                    "y": [
                        77.5,
                        77.5,
                        77.5,
                        77.5
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SBST"
                    }
                },
                {
                    "x": "SG3073H",
                    "y": [
                        100.0,
                        100.0,
                        93.0,
                        97.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SMRT"
                    }
                },
                {
                    "x": "SG3074E",
                    "y": [
                        45.0,
                        99.0,
                        39.5,
                        91.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SMRT"
                    }
                },
                {
                    "x": "SG3075C",
                    "y": [
                        55.0,
                        55.0,
                        55.0,
                        55.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SMRT"
                    }
                },
                {
                    "x": "SG3076A",
                    "y": [
                        80.5,
                        80.5,
                        78.0,
                        78.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SMRT"
                    }
                },
                {
                    "x": "SG3077Y",
                    "y": [
                        55.0,
                        55.0,
                        55.0,
                        55.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SMRT"
                    }
                },
                {
                    "x": "SG3078U",
                    "y": [
                        91.0,
                        91.0,
                        90.0,
                        90.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SBST"
                    }
                },
                {
                    "x": "SG3080L",
                    "y": [
                        81.0,
                        100.0,
                        65.0,
                        94.5
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SBST"
                    }
                },
                {
                    "x": "SG3081J",
                    "y": [
                        93.0,
                        100.0,
                        84.0,
                        97.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SBST"
                    }
                },
                {
                    "x": "SG3082G",
                    "y": [
                        85.5,
                        100.0,
                        63.0,
                        81.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SBST"
                    }
                },
                {
                    "x": "SG3083D",
                    "y": [
                        15.5,
                        100.0,
                        11.0,
                        97.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SMRT"
                    }
                },
                {
                    "x": "SG3086X",
                    "y": [
                        83.0,
                        83.0,
                        68.5,
                        68.5
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SMRT"
                    }
                },
                {
                    "x": "SG3088R",
                    "y": [
                        75.0,
                        75.0,
                        75.0,
                        75.0
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SBST"
                    }
                },
                {
                    "x": "SG3089M",
                    "y": [
                        49.0,
                        49.0,
                        48.5,
                        48.5
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "SBST"
                    }
                },
                {
                    "x": "SG3100M",
                    "y": [
                        14.4,
                        95.2,
                        8.8,
                        88.8
                    ],
                    "data": {
                        "date": "2022-01-01",
                        "agency": "RETROFIT"
                    }
                }
            ]
            }
          ],
          chart: {
            type: "candlestick",
            height: 400,
            id: "candles",
            toolbar: {
              autoSelected: "pan",
              show: false
            },
            zoom: {
              enabled: false
            },
            foreColor: '#fff'
          },
          plotOptions: {
            candlestick: {
              colors: {
                upward: "#3C90EB",
                downward: "#DF7D46"
              }
            }
          },
          

          // xaxis: {
          //   title: {
          //     text: 'Buses'
          //   }
          // },
          // yaxis: {
          // //   title: {
          // //     text: 'Charge Level'
          // //   }
          //   tooltip: {
          //     enabled: true
          //   }
          // },
          // title: {
          //   text: 'candle chart',
          //   align: "left"
          // }
        };

        this.chartBarOptions = {
          series: [
            {
              name: "volume",
              data: []
            }
          ],
          chart: {
            height: 300,
            type: "bar",
            brush: {
              enabled: true,
              target: "candles"
            },
            selection: {
              enabled: true,
              xaxis: {
                min: new Date("20 Jan 2017").getTime(),
                max: new Date("10 Dec 2017").getTime()
              },
              fill: {
                color: "#ccc",
                opacity: 0.4
              },
              stroke: {
                color: "#0D47A1"
              }
            },
            foreColor: '#fff'
          },
          dataLabels: {
            enabled: false
          },
          plotOptions: {
            bar: {
              columnWidth: "80%",
              colors: {
                ranges: [
                  {
                    from: -1000,
                    to: 0,
                    color: "#F15B46"
                  },
                  {
                    from: 1,
                    to: 10000,
                    color: "#FEB019"
                  }
                ]
              }
            }
          },
          stroke: {
            width: 0
          },
          

          xaxis: {
            title: {
              text: 'Buses'
            }
          },
          yaxis: {
            title: {
              text: 'Charge Level'
            }
          }
        };
        this.isRunning = false;
      // }
    // );
  }

  initForm() {
    this.form = this._formBuilder.group({
      vehicle: new FormControl('', [Validators.required]),
      startDate: new FormControl(''),
      endDate: new FormControl('')
    })
  }
}
