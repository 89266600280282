<div class="device-monitoring-group">
  <div>
    <h3 class="title-header">Vehicle List<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
  </div>
  <div>
    <div class="text-left">
      <mat-form-field floatLabel="always">
        <input matInput (keyup)="filter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <!-- <button mat-raised-button (click)="exporter.exportTable('xlsx')">Export</button> -->
      <!-- <button class="pull-right" mat-raised-button type="button" (click)="add()">Add</button> -->
    </div>
      
  </div>

  <perfect-scrollbar #chatPS class="ps-vehicle-list" [config]="config">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="busId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bus ID</th>
        <td mat-cell *matCellDef="let element"> {{element.busId}} </td>
      </ng-container>

      <ng-container matColumnDef="numberPlate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Number Plate</th>
        <td mat-cell *matCellDef="let element"> {{element.numberPlate}} </td>
      </ng-container>

      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
        <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
      </ng-container>

      <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
        <td mat-cell *matCellDef="let element"> {{element.model}} </td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
        <td mat-cell *matCellDef="let element"> {{element.company}} </td>
      </ng-container>

      <ng-container matColumnDef="depot">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Depot</th>
        <td mat-cell *matCellDef="let element"> {{element.depot}} </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </perfect-scrollbar>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>