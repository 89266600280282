import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { CommonService } from 'src/app/component/shared/services/common.service';
// import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
  @Input() data: any;
  // @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  // @Output() okayAction: EventEmitter<any> = new EventEmitter<any>();
  // username: string = localStorage.getItem('displayname');
  // scope: string = localStorage.getItem('scope');
  // sipNumber: string = localStorage.getItem('sipNumber');
  isOldHide: boolean = true;
  isHide: boolean = true;
  isConfirmHide: boolean = true;
  form: FormGroup;
  // showDetails: boolean = true;
  isPasswordMatch: boolean = true;
  message = '';

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private _formBuilder: UntypedFormBuilder,
    public cdRef: ChangeDetectorRef,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    // console.log(this.dataDialog);
    this.form = this._formBuilder.group({
      oldPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });

    // let timer = 3;
    // let interval = setInterval(() => {
    //   if (timer != 0) {
    //     timer--
    //     console.log(timer);
    //     this.message = `Redirecting in ${timer}s...`;
    //   } else {
    //     clearInterval(interval)
    //     this.message = null
    //   }
    // }, 1000);
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }

  onStrengthChanged(strength: number) {
    // console.log('password strength = ', strength);
    // if(strength >= 100) {
    //   this.form.get('password').setErrors({valid:true});
    // }
  }

  onPasswordConfirm(data) {
    var password = this.form.value.password;
    var confirmData = this.form.get('confirmPassword').value;
    if (password === confirmData) {
      this.isPasswordMatch = true;
      console.log('match');
      this.form.get('confirmPassword').setErrors(null);
    } else {
      this.isPasswordMatch = false;
      console.log('not not');
      this.form.get('confirmPassword').setErrors({ valid: false });
    }
    // isPasswordMatch
  }

  onSave() {
    // var isMatch = this.onPasswordConfirm(this.form.get('confirmPassword').value);
    if (this.form.valid) {
      let bodyData = {
        userName: localStorage.getItem('username'),
        oldPassword: this.form.get('oldPassword').value,
        newPassword: this.form.get('password').value,
      };
      // console.log(bodyData);
      this._snackBar.dismiss();

      let urlAction = 'users/v2/changePassword';
      this._commonService.commonPostAction(urlAction, bodyData).subscribe(
        resp => {
          let isExist = resp.response.includes('does not match');
          if (!isExist) {
            // this.form.reset();
            setTimeout(() => {
              this.onCloseModal();
              this._commonService.logout();
            }, 200);
          } else {
            this._snackBar.open(resp.response, null, {
              duration: 5000,
              panelClass: 'custom-snack-bar-panel-error',
            });
          }
        },
        error => {
          console.log(error);
          this._snackBar.open(error.error.errorMessage, null, {
            duration: 5000,
            panelClass: 'custom-snack-bar-panel-error',
          });
        }
      );
    }
  }
}
