<div
  app-driver-list
  class="driver-list-group custom-page-scroll"
  fxLayout="column">
  <div class="header-group" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <div>
        <span class="title-header">Driver List</span>
        <sup
          ><i
            *ngIf="!isNewWindow"
            class="fa fa-window-restore open-window"
            (click)="openWindow()"></i
        ></sup>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxFlex class="driver-group">
    <div
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      fxLayoutGap="6px"
      class="driver-filter">
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        floatLabel="always"
        style="width: 240px">
        <mat-label>Search</mat-label>
        <input
          name="Search"
          matInput
          placeholder="Search Driver"
          aria-label="Search"
          #filterField
          [(ngModel)]="searchFilter"
          (input)="onSearchFilterInput($event.target.value)"
          [disabled]="isLoading" />
        <button
          [disableRipple]="true"
          *ngIf="filterField.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearFilterTextBox($event)"
          [disabled]="isLoading">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Status</mat-label>
        <mat-select
          [ngModel]="selectedStatus"
          name="status"
          placeholder="Select Status"
          (selectionChange)="onStatusChange($event)"
          [disabled]="isLoading">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let status of statusList" [value]="status.id">
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-drawer-container fxFlex [hasBackdrop]="false">
      <mat-drawer-content fxLayout="column" class="content-container">
        <div fxLayout="column" class="driver-content custom-page-scroll">
          <table
            #drivertable
            mat-table
            [dataSource]="dataSource"
            matSort
            class="content-table">
            <ng-container
              *ngFor="
                let columnName of displayedColumns;
                let idx = index;
                trackBy: trackDriver
              "
              [matColumnDef]="columnName">
              <ng-container [ngSwitch]="columnName">
                <ng-container *ngSwitchDefault>
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="content-header"
                    mat-sort-header
                    disableClear>
                    <div>
                      {{ columnList | getById: columnName : 'name' }}
                    </div>
                  </th>
                </ng-container>
                <ng-container *ngSwitchCase="'Actions'">
                  <th mat-header-cell *matHeaderCellDef class="content-header">
                    <div>
                      {{ columnList | getById: columnName : 'name' }}
                    </div>
                  </th>
                </ng-container>
              </ng-container>

              <ng-container>
                <td mat-cell *matCellDef="let element">
                  <ng-container [ngSwitch]="columnName">
                    <ng-container *ngSwitchDefault>{{
                      element[columnName]
                    }}</ng-container>
                    <ng-container *ngSwitchCase="'status'">
                      {{ statusList | getById: element[columnName] : 'name' }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'identification'">
                      <div class="identification">
                        <div class="type">
                          {{
                            identificationList
                              | getById: element[columnName]['type'] : 'name'
                          }}
                        </div>
                        <div>
                          {{ element[columnName]['identification'] }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date_joined'">
                      {{ element[columnName] | momentPipe: 'DD/MM/YYYY HH:mm' }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'Actions'">
                      <div class="action-container">
                        <button
                          matTooltip="View Driver Info"
                          mat-icon-button
                          [disabled]="isLoading || drawerOpen"
                          (click)="onView(element)">
                          <mat-icon>info</mat-icon>
                        </button>
                        <button
                          matTooltip="Edit Driver Info"
                          mat-icon-button
                          [disabled]="isLoading || drawerOpen"
                          (click)="onEdit(element)">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button
                          matTooltip="Delete Driver"
                          mat-icon-button
                          [disabled]="isLoading || drawerOpen"
                          (click)="onDelete(element)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns; let entry"
              class="content-row"
              [ngClass]="{
                'selected-driver': entry.userid === selectedDriver
              }"></tr>
          </table>
        </div>
      </mat-drawer-content>
      <mat-drawer
        #tabledrawer
        mode="over"
        position="end"
        [opened]="drawerOpen"
        disableClose
        [autoFocus]="false"
        class="driver-detail-drawer">
        <app-driver-detail
          *ngIf="drawerOpen"
          [isLoading]="isLoading"
          [isEdit]="isEdit"
          [driverDetails]="driverDetails"
          [parentForm]="driverForm"
          (closeDrawer)="onClose()"
          (saveDriver)="onSave($event)"
          (deleteDriver)="onDelete($event)"
          (toggleEdit)="toggleEdit($event)"></app-driver-detail>
      </mat-drawer>
    </mat-drawer-container>
    <div>
      <mat-paginator
        #paginator
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[1, 10, 15, 25, 50, 100]"></mat-paginator>
    </div>
    <div class="content-loading" *ngIf="isLoading">
      <mat-spinner class="margin-auto"></mat-spinner>
    </div>
  </div>
</div>
