import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatTable, MatDialog, MatDialogConfig, MatSnackBar, MatSort } from '@angular/material';
// import { DeviceStatusElement, DeviceMonitorService } from './vehicle-list.service';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { VmService } from '../vm.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {


  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['deviceId', 'busRegNo', 'routeName', 'simCardId', 'simPhoneNo', 'reportedTime', 'curr', 'prev', 'befPrev'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  isNewWindow: boolean = false;
  curr = moment().format('MMM YYYY');
  prev = moment().subtract(1, 'months').format('MMM YYYY');
  beforePrev = moment().subtract(2, 'months').format('MMM YYYY');
  
  constructor(private dialog: MatDialog, private vmService: VmService, private _snackBar: MatSnackBar, private router: Router, 
    public authService: AuthService, private route: ActivatedRoute) { }


  ngOnInit() {

    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }

    this.dataSource = new MatTableDataSource<any>();

    var prevMonth = moment().subtract(1, 'months').format('MM/YYYY');
    var beforePrevMonth = moment().subtract(2, 'months').format('MM/YYYY');
    
    this.vmService.getAllDeviceSim().subscribe((data: any) => {
      data.forEach((element, index) => {
        if(parseInt(element.deviceId)) {
          let monthsData = [{
              "deviceId": element.deviceId,
              "monthYear": [prevMonth,beforePrevMonth]
          }];
          
          this.vmService.getDeviceDataByDeviceId(monthsData).subscribe((resData: any) => {

            let val = Object.keys(resData)[0];
            let firstElem = Object.keys(resData[val])[0];
            let secondElem = Object.keys(resData[val])[1];

            if(resData[val][firstElem] !== null){
              if(resData[val][firstElem].hasOwnProperty('receivedData')) {
                let firstElemReceivedData = resData[val][firstElem].receivedData;
                let firstElemSentData = resData[val][firstElem].sentData;
                element.prevDataConsume = firstElemReceivedData+firstElemSentData;
              }
            }
            if(resData[val][secondElem] !== null){
              if(resData[val][secondElem].hasOwnProperty('receivedData')) {
                let secondElemReceivedData = resData[val][secondElem].receivedData;
                let secondElemSentData = resData[val][secondElem].sentData;
                element.beforePrevDataConsume = secondElemReceivedData+secondElemSentData;
              }
            }
            
          });
          this._snackBar.open("Updating...", null, {
            duration: 2000,
          });
        }
      });

      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.table.renderRows();
    }, error => {
      this._snackBar.open(error.statusText, null, {
        duration: 2000,
      });
    });
  }

  refresh() {

    this._snackBar.open('Refreshing...');

    this.vmService.getAllDeviceSim().subscribe((data: any) => {
      this.dataSource.data = data;
      this.table.renderRows();

      this._snackBar.dismiss();
    }, error => {
      this._snackBar.open(error.statusText, null, {
        duration: 2000,
      });
    });
  }

  filter(keyword: string) {
    this.dataSource.filter = keyword.trim().toLowerCase();
  }

  format(time: string): string {
    return moment(time).format('DD/MM/YYYY HH:mm:ss');
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/vehicle-management/vehicle-list/2','vehicle-list','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }

}
