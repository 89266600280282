<div class="user-edit-group">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" >
      <h2 mat-dialog-title>{{title}}</h2>
      <mat-icon class="pointer mar-right-10" (click)="onClose()">cancel</mat-icon>
    </div>
    <div *ngIf="subTitle">
      <h3 mat-dialog-title>{{subTitle}}</h3>
    </div>
  </div>

  <mat-dialog-content>
    
    <div class="custom-scroll">
      <table mat-table [dataSource]="dataSource">

        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let idx = index">
          <th mat-header-cell *matHeaderCellDef>
            <ng-container *ngIf="idx === 0; else notFirstCol">
              <mat-checkbox [checked]="true" (change)="onCheckAll($event)"></mat-checkbox>
            </ng-container>
            <ng-template #notFirstCol>
              {{column}}
            </ng-template>
          </th>
          <td mat-cell *matCellDef="let element"
          [ngClass]="{
            rowChecked: element.isChecked
          }"
          > 
            <ng-container *ngIf="idx === 0">
              <mat-checkbox [checked]="element.isChecked" (change)="onCheck(element, $event)"></mat-checkbox>
            </ng-container>

            <ng-container *ngIf="idx === 1">
                {{element.busRegNo}}
            </ng-container>
              
            <ng-container *ngIf="idx > 1">
              <ng-container *ngIf="element.dates[idx-2].status === 1; else noStatus">
                <mat-icon class="statusGreen">check_circle</mat-icon>
              </ng-container>
              
              <ng-template #noStatus>
                <mat-icon class="statusRed">cancel-circle</mat-icon>
              </ng-template>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
            [ngClass]="{
              'highlighted': row.highlighted
            }"
        ></tr>
      </table>
    </div>

    <div 
    [ngClass]="{
      'd-none': dataSource.data.length === 0
    }"
    >
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="100" [pageSizeOptions]="[100, 500, 1000]"></mat-paginator>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onClose()">Close</button>
    <button mat-raised-button color="primary" class="mar-left-10" (click)="onSubmit()">Submit</button>
  </mat-dialog-actions>
</div>