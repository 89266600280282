import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isNewWindow: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/multi-ladder/2','Multi Ladder','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}
