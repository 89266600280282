import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { DataManagementService } from '../../../shared/services/data-management.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-data-management',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.scss']
})
export class DataManagementComponent implements OnInit {
  @ViewChild('autocompletePanel') autocompletePanel;
  currTab:string = 'routes';
  
  filteredBrands: string[] = [];
  isNewWindow: boolean = false;

  constructor(private dmService: DataManagementService, private route: ActivatedRoute, private router: Router) { }
  // results;
  // brands: string[] = ['Audi','BMW','Fiat','Ford','Honda','Jaguar','Mercedes','Renault','Volvo','VW'];

  ngOnInit() {
    if(this.route.snapshot.params.id === '2') {
      this.isNewWindow = true;
    }
    // this.results = [
    //     {label: 'Audi', value: 'Audi'},
    //     {label: 'BMW', value: 'BMW'},
    //     {label: 'Fiat', value: 'Fiat'},
    //     {label: 'Ford', value: 'Ford'},
    //     {label: 'Honda', value: 'Honda'},
    //     {label: 'Jaguar', value: 'Jaguar'},
    //     {label: 'Mercedes', value: 'Mercedes'},
    //     {label: 'Renault', value: 'Renault'},
    //     {label: 'VW', value: 'VW'},
    //     {label: 'Volvo', value: 'Volvo'},
    // ];
  //   const gtfsData = [
  //     {
  //       "recId": "T101101",
  //       "tripId": "T101",
  //       "routeId": "10",
  //       "tripHeadSign": null,
  //       "direction": "1",
  //       "busStopCode": "75009",
  //       "busStopName": "Tampines Int",
  //       "busStopDesc": null,
  //       "busStopLat": 1.35407552367477,
  //       "busStopLon": 103.94339098473914,
  //       "busStopSequence": "1",
  //       "arrivalTimeToStop": "05:30:00",
  //       "departTimeFromStop": "05:30:00",
  //       "pickUpType": null,
  //       "dropOffType": null
  //   }
  // ];

  //   this.dmService.addGtfsData(gtfsData);
  }

  tabEvent(tab) {
    this.currTab = tab;
  }

  // search(event) {
  //   console.log(event);
  //   setTimeout(() => {
  //     const autocompletePanel = this.autocompletePanel.el.nativeElement.querySelector('.ui-autocomplete-panel');
  //     if (autocompletePanel) {
  //       this.renderer.listen(autocompletePanel, 'scroll', event => {
  //         if ((event.target.scrollHeight - event.target.clientHeight) === event.target.scrollTop) {
  //           // ... handle it
  //           console.log('scrolled to the bottom of the panel');
  //         }
  //       })
  //     }
  //   }, 500);
  // }
  
  filterBrands(event) {
    // setTimeout(() => {
      
    this.filteredBrands = [];
    // for(let i = 0; i < this.brands.length; i++) {
    //   let brand = this.brands[i];
    //   if(brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
    //       this.filteredBrands.push(brand);
    //   }
    // }
    // console.log(this.filteredBrands);

    // console.log(event);
    let currPage = 1;
    let me = this;
    this.dmService.getFindStopsByRoute(event.query.toLowerCase(), currPage).then(
      (ret:any) => {
        // this.stopsData = ret;
        // this.isGetAllStops = true;
        
        // this.filteredBrands = ret;
        for (const row of ret) {
          // console.log(row.busStopName);  
          me.filteredBrands.push(row.busStopName);
        }
        // this.datasource = ret;
        // this.totalRecords = this.datasource.length;
        // console.log(this.stopsData);
      }
    ); 

    console.log(this.filteredBrands);
  // }, 1000);
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open('account/data-management/2','data-management','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}
