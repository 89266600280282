<div class="alarm-detail-group">
  <div class="mar-bot-20" fxLayout="row" fxLayoutAlign="space-between center" >
    <h2 class="no-margin-imp" mat-dialog-title>Vehicle Assign</h2>
    <mat-icon class="pointer" matTooltip="Close" (click)="dialogRef.close()">close</mat-icon>
  </div>

  <mat-dialog-content [formGroup]="form">
    <perfect-scrollbar #PSAlarm class="ps-alarm" [config]="config" [scrollIndicators]="true">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <!-- <ngx-charts-gauge
          [view]="view"
          [scheme]="colorScheme"
          [results]="single"
          > -->
          <!--
          [legend]="legend"
          [legendPosition]="legendPosition"
          (select)="onSelect($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)" -->
        <!-- </ngx-charts-gauge> -->
        <div class="severity-img"></div>
      </div>

      <div>
        <div fxLayout="row" class="status-group">
          <div class="rect status-notified">Notified</div>
          <!-- <div class="rect">Scheduled</div> -->
          <div class="rect status-open active">Open</div>
          <div class="rect status-closed">Closed</div>
          <div class="rect status-over">Over</div>
        </div>

        <div fxLayout="row">
          <div fxLayout="column" fxFlex="50">
            <div>Type</div>
            <mat-form-field>
              <mat-select formControlName="type">
                <mat-option *ngFor="let source of typeList" [value]="source.value">
                  {{source.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="50">
            <div>Sub Type</div>
            <mat-form-field>
              <mat-select formControlName="subType">
                <mat-option *ngFor="let source of subtypeList" [value]="source.value">
                  {{source.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        
        <div fxLayout="row">
          <div fxLayout="column">
            <div>Start Time</div>
            <mat-form-field>
              <input matInput [matDatepicker]="startTime" formControlName="startTime">
              <mat-datepicker-toggle matSuffix [for]="startTime"></mat-datepicker-toggle>
              <mat-datepicker #startTime></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxLayout="column">
            <div>End Time</div>
            <mat-form-field>
              <input matInput [matDatepicker]="endDate" formControlName="endTime">
              <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <mat-checkbox class="example-margin">Auto Update</mat-checkbox>
          <mat-checkbox class="example-margin">Auto Activation Plan</mat-checkbox>
          <mat-checkbox class="example-margin">Auto Close</mat-checkbox>
        </div>
      </div>

      <div>
        <div id="map" class="map" ></div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-tab-group fxFlex="100">
        <mat-tab label="Event Information"> 
          
          <!-- <div fxLayout="row" class="mar-top-10" fxLayoutAlign="space-between center">
            <div fxFlex="50" fxLayout="column" fxLayoutAlign="space-around center">
              <div>1</div>
              <div>1</div>
              <div>1</div>
            </div>
              <div class="car-accident"></div>
          </div> -->

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="50" class="mar-top-10" fxLayout="column">
              <div>
                <div fxFlex="50" fxLayout="column">
                  <div>Road Name</div>
                  <mat-form-field>
                    <input matInput formControlName="roadName">
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxLayout="column">
                  <div>Location Type</div>
                  <mat-form-field>
                    <input matInput formControlName="locationType">
                  </mat-form-field>
                </div>
              </div>
              <div>
                <div fxFlex="50" fxLayout="column">
                  <div>Start Point</div>
                  <div fxLayout="row">
                    <mat-form-field fxFlex="49">
                      <input matInput formControlName="startLat">
                    </mat-form-field>
                    <mat-form-field fxFlex="45">
                      <input matInput formControlName="startLon">
                    </mat-form-field>
                  </div>
                </div>
                <div fxFlex="50" fxLayout="column">
                  <div>End Point</div>
                  <div fxLayout="row">
                    <mat-form-field fxFlex="49">
                      <input matInput formControlName="endLat">
                    </mat-form-field>
                    <mat-form-field fxFlex="45">
                      <input matInput formControlName="endLon">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              
              <!-- <div>
                <div fxFlex="50" fxLayout="column">
                  <div>Cogestion Start Time</div>
                  <mat-form-field>
                    <input matInput formControlName="congestionStartTime">
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxLayout="column">
                  <div>Cogestion End Time</div>
                  <mat-form-field>
                    <input matInput formControlName="congestionEndTime">
                  </mat-form-field>
                </div>
              </div> -->

              <div>
                <div fxFlex="50" fxLayout="column">
                  <div>Description</div>
                  <mat-form-field>
                    <textarea matInput formControlName="description"></textarea>
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxLayout="column">
                  <div>Bus Services Affected</div>
                  <div fxLayout="row">
                    <div *ngFor="let bus of busServices" class="bus-service">{{bus}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div fxFlex="50">
              <div class="car-accident"></div>
            </div>
          </div>
          
        </mat-tab>
        <!-- <mat-tab label="Alarms"> Alarms information </mat-tab> -->
        <mat-tab label="Activation Plan">Activation Plan Information </mat-tab>
      </mat-tab-group>
    </div>
    </perfect-scrollbar>
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <!-- (click)="btnUploadFile()"  -->
    <button mat-raised-button color="primary" 
        [disabled]="running || !form.valid">Save</button>
  </mat-dialog-actions>
</div>