import { Component, OnInit, ViewChild } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { FormGroup, FormControl, Validators } from '@angular/forms';
// import moment from 'moment';
// import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
// import { SelectionModel } from '@angular/cdk/collections';
// import { UserEditComponent } from './user-edit/user-edit.component';
// import { UserElement } from '../../shared/others/constant';
// import { ServiceDialog } from '../../shared/services/service-modal/service-modal';
// import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/services/auth.service';
// import { MatSort } from '@angular/material/sort';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { MatTable, MatTableDataSource } from '@angular/material/table';
// import { MatPaginator } from '@angular/material/paginator';
// import { CommonService } from '../../shared/services/common.service';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { DataStatusComponent } from './data-status/data-status.component';
// import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
// import { InfoModalComponent } from '../../shared/services/info-modal/info-modal.component';

export interface BusEventElement {
  schedTime: string;
  stopCode: string;
  status: string;
  deviation: string;
  manual: string;
  eventCode: string;
}

@Component({
  selector: 'app-bus-schedule-data-management',
  templateUrl: './bus-schedule-data-management.component.html',
  styleUrls: ['./bus-schedule-data-management.component.scss'],
})
export class BusScheduleDataManagementComponent implements OnInit {
  isNewWindow: boolean = false;
  rightsError: string = '';

  constructor(
    // private httpClient: HttpClient,
    // private _snackBar: MatSnackBar,
    private router: Router,
    // private route: ActivatedRoute,
    // private dialog: MatDialog,
    // private _commonService: CommonService,
    // private _spinner: NgxSpinnerService
    public authService: AuthService
  ) {}

  pageData = {
    id: 2,
    name: 'userManagement',
    displayPageName: 'User Management',
  };
  isHasAccess: boolean = false;

  ngOnInit() {
    setTimeout(() => {
      this.isHasAccess = this.authService.isHasAccess(this.pageData);
      this.rightsError = "You don't have access rights to this module.";
    }, 500);
  }

  openWindow() {
    this.router.navigate(['/account/home']);
    let newwin = window.open(
      'account/schedule-data-management/2',
      'bus dataset',
      'height=' + screen.height + ', width=' + screen.width + ' '
    );
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }
}
