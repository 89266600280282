import { Component, AfterViewInit } from '@angular/core';

// import { Cell, DefaultEditor, Editor } from 'ng2-smart-table';

@Component({
    templateUrl: './vm-join-date-picker.component.html',
})
export class VmJoinDatePickerComponent implements AfterViewInit {

    constructor() {
        // super();
    }

    ngAfterViewInit() {

    }
}