import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../shared/services/common.service';
import { AccountSettingComponent } from '../account/sidebar-menu/account-setting/account-setting.component';
import { ChangePasswordComponent } from '../account/sidebar-menu/change-password/change-password.component';
import { HOME_PATH, WINDOW_NAME_MAIN, WINDOW_NAME_COMMS } from '../shared/others/constant';
import { environment } from 'src/environments/environment';
import { JitsiService } from '../shared/services/jitsi.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-comms',
  templateUrl: './comms.component.html',
  styleUrls: ['./comms.component.scss']
})
export class CommsComponent implements OnInit {

  decodedToken: any;
  logo = '';
  displayName = localStorage['displayname'];
  email = localStorage['email'];
  userRole = undefined;
  jitsiMuteSubscription: Subscription;
  broadcastMicMuted: boolean = false;
  @ViewChild('broadcastcallframe') callFrame: ElementRef;

  constructor(
    private _cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private _commonService: CommonService,
    private _jitsiService: JitsiService,
  ) {
    this.broadcastMicMuted = this._jitsiService.broadcastMuted;
    this._commonService.onIdle();
  }

  ngOnInit(): void {
    this.logo = "assets/images/icon/" + environment.image.logo;
    this.decodedToken = jwt_decode(localStorage.getItem('access_token'));
    const { displayname, email, scope } = this.decodedToken || {};
    const [ role ] = scope || {};
    this.userRole = role;
    this.displayName = displayname;
    this.email = email;

    window.name = WINDOW_NAME_COMMS;

    // TO DO: detect if comms already open in another tab


    this._jitsiService.init();
    this.jitsiMuteSubscription = this._jitsiService.getBroadcastMute().subscribe((muted) => {
      this.broadcastMicMuted = muted;
    })
  }

  ngAfterViewChecked(){
    //your code to update the model
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
  }

  ngAfterViewInit(): void {
    console.log('broadcast');
  }

  onShowAccount() {
    this.dialog.open(AccountSettingComponent, {
      data: {
        header: 'Account Details'
      }
    });
  }

  onChangePassword() {
    this.dialog.open(ChangePasswordComponent, {
      data: {
        header: 'Change Password'
      },
      disableClose: true,
      width: '400px',
    });
  }

  onLogout() {
    this._commonService.logout();
  }

  onClickHome() {
    this._commonService.openWindow(HOME_PATH, WINDOW_NAME_COMMS, WINDOW_NAME_MAIN);
  }

  toggleBroadcast() {
    this._jitsiService.setBroadcastMuteStatus(!this._jitsiService.broadcastMuted);
  }

}
