import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-management',
  templateUrl: './schedule-management.component.html',
  styleUrls: ['./schedule-management.component.scss']
})
export class ScheduleManagementComponent implements OnInit {

  constructor() { }
  
  ngOnInit() {
  }
}
