<div class="device-monitoring-group">
  <ng-container *ngIf="isHasAccess; else noAccess">
    <div>
      <h3 class="title-header">Device Monitoring<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
    </div>

    <!-- <div class="container no-padding" *ngIf="!authService.isAdmin()">
      <div class="col message">
        <h3>Only administrator has access rights to this module.</h3>  
      </div>
    </div> -->
    <!-- *ngIf="authService.isAdmin()" -->
    <div>
      <div class="left-aligned">
        <mat-form-field appearance="outline">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="filter($event.target.value)" placeholder="Filter">
        </mat-form-field>
        <button mat-raised-button type="button" (click)="refresh()">Refresh</button>
      </div>

      <perfect-scrollbar #chatPS class="device-list" [config]="config" [scrollIndicators]="false"
      >
      <!-- <table mat-table [dataSource]="dataSource" matTableExporter matSort #exporter="matTableExporter"> -->
        <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="busRegNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle License Plate</th>
          <td mat-cell *matCellDef="let element"> {{element.busRegNo}} </td>
        </ng-container>

        <ng-container matColumnDef="deviceId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Device ID</th>
          <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
        </ng-container>

        <ng-container matColumnDef="voipId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VoIP ID</th>
          <td mat-cell *matCellDef="let element"> {{element.voipId}} </td>
        </ng-container>

        <ng-container matColumnDef="ipAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>IP Address</th>
          <td mat-cell *matCellDef="let element"> {{element.ipAddress}} </td>
        </ng-container>

        <ng-container matColumnDef="deviceStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Status</th>
          <td mat-cell *matCellDef="let element"> {{element.deviceStatusDisplay}} </td>
        </ng-container>

        <ng-container matColumnDef="reportedTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Last Update /<br> Connection Time</th>
          <td mat-cell *matCellDef="let element"> {{format(element.reportedTime)}} </td>
        </ng-container>

        <ng-container matColumnDef="driverId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver Name</th>
          <td mat-cell *matCellDef="let element"> {{element.driverId}} </td>
        </ng-container>

        <ng-container matColumnDef="routeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Service/Block/Trip</th>
          <td mat-cell *matCellDef="let element"> {{element.routeName}} </td>
        </ng-container>

        <ng-container matColumnDef="tripStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Service/Block/Trip Status</th>
          <td mat-cell *matCellDef="let element"> {{element.tripStatus}} </td>
        </ng-container>

        <ng-container matColumnDef="swVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sw Version</th>
          <td mat-cell *matCellDef="let element"> {{element.swVersion}} </td>
        </ng-container>

        <ng-container matColumnDef="datasetVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Dataset Version</th>
          <td mat-cell *matCellDef="let element"> {{element.datasetVersion}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </perfect-scrollbar>

      <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </ng-container>
  
  <ng-template #noAccess>
    <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
      <h3>{{rightsError}}</h3>  
    </div>
  </ng-template>
</div>