<!-- <div *ngIf="!isNewWindow" >
    <app-account-header></app-account-header>
</div> -->
<div class="vm-driver-group">
  <div class="col">
    <div class="row">
      <div class="col no-padding">
        <!-- <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Driver</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol> -->
        <h3>Vehicle Management<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
      </div>
      <!-- <div class="col no-padding button-group">
        <button class="btn btn-primary btn-sm" data-toggle="modal" data-target='#modalAddDriver'>
          Add
        </button>
      </div> -->
    </div>
  </div>

  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a class="nav-item nav-link active" id="nav-owner-tab" data-toggle="tab" href="#nav-owner" role="tab" aria-controls="nav-owner" aria-selected="true" (click)="initVehicleOwner()">Owners</a>
      <a class="nav-item nav-link" id="nav-types-tab" data-toggle="tab" href="#nav-types" role="tab" aria-controls="nav-types" aria-selected="false" (click)="initVehicleTypes()">Types</a>
      <a class="nav-item nav-link" id="nav-vehicle-tab" data-toggle="tab" href="#nav-vehicle" role="tab" aria-controls="nav-vehicle" aria-selected="false" (click)="initVehicle()">Vehicles</a>
      <a class="nav-item nav-link" id="nav-property-tab" data-toggle="tab" href="#nav-property" role="tab" aria-controls="nav-property" aria-selected="false" (click)="initVehicleProperty()">Property</a>
    </div>
  </nav>

  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-owner" role="tabpanel" aria-labelledby="nav-owner-tab">
      <div class="loader-group" *ngIf="!isGetAllOwner">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <!-- <ng2-smart-table *ngIf="isGetAllOwner"
        class="nav-vehicle-table dark table table-striped table-responsive"
        [settings]="ownerSettings" 
        [source]="vehicleOwnerData"
        (deleteConfirm)="onDeleteConfirm($event)"
        (editConfirm)="onEditConfirm($event)"
      ></ng2-smart-table> -->
    </div>

    <div class="tab-pane fade" id="nav-types" role="tabpanel" aria-labelledby="nav-types-tab">
      <div class="loader-group" *ngIf="!isGetAllVehicleTypes">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <!-- <ng2-smart-table *ngIf="isGetAllVehicleTypes"
        class="nav-vehicle-table dark table table-striped table-responsive"
        [settings]="typeSettings" 
        [source]="vehicleTypesData"
        (deleteConfirm)="onDeleteConfirm($event)"
        (editConfirm)="onEditConfirm($event)"
      ></ng2-smart-table> -->
    </div>

    <div class="tab-pane fade" id="nav-vehicle" role="tabpanel" aria-labelledby="nav-vehicle-tab">
      <div class="loader-group" *ngIf="!isGetAllVehicle">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <!-- <ng2-smart-table *ngIf="isGetAllVehicle"
        class="nav-vehicle-table dark table table-striped table-responsive"
        [settings]="vehicleSettings" 
        [source]="vehicleData"
        (deleteConfirm)="onDeleteConfirm($event)"
        (editConfirm)="onEditConfirm($event)"
      ></ng2-smart-table> -->
    </div>

    <div class="tab-pane fade" id="nav-property" role="tabpanel" aria-labelledby="nav-property-tab">
      <div class="loader-group" *ngIf="!isGetAllVehicleProperty">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <!-- <ng2-smart-table *ngIf="isGetAllVehicleProperty"
        class="nav-vehicle-table dark table table-striped table-responsive"
        [settings]="propertySettings" 
        [source]="vehiclePropertyData"
        (deleteConfirm)="onDeleteConfirm($event)"
        (editConfirm)="onEditConfirm($event)"
      ></ng2-smart-table> -->
    </div>
  </div>

</div>
<app-vm-driver-registration [data]=modalAddDriverData (closeAction)="closeModalAction($event)" (saveAction)="addDriver($event)"></app-vm-driver-registration>
<!-- <button #btnModal class="btn btn-primary btn-sm" data-toggle="modal" data-target='#modalConfirm' style="display: none">test</button> -->
<!-- <app-modal [data]="confirmModalData" (closeAction)="closeModalAction($event)" (okayAction)="okayModalAction($event)"></app-modal> -->