<div class="personnel-mgnt-group">
  <div class="container">
    <h3 class="title-header">Driver Management<sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup></h3>
     
    <div class="row">
      <!-- <div class="col list-group-links">
        <ul class="list-group">
          <li class="list-group-item" [routerLinkActive]="['active']" [routerLink]="['driver']">Driver</li>
          <li class="list-group-item" [routerLinkActive]="['active']" [routerLink]="['controller']">Controller</li>
        </ul>
      </div> -->
      <div class="col pm-content">
        <!-- <app-settings-ladder></app-settings-ladder> -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>