import moment from 'moment';

export const timeStringToMinutes = (timeStr, separator = ':') =>
  timeStr
    .split(separator)
    .slice(0, 2)
    .reduce((h, m) => h * 60 + +m);

const minutesToTimeString = (minutes, separator) => {
  const minutesPart = (minutes % 60).toString().padStart(2, '0');
  const hoursPart = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0');
  return hoursPart + separator + minutesPart + ':00';
};

export function generateTimeSlots(
  startStr = '6:00:00',
  endStr = '22:00:00',
  periodInMinutes = 15,
  separator = ':'
) {
  let startMinutes = timeStringToMinutes(startStr, separator);
  let endMinutes = timeStringToMinutes(endStr, separator);
  const oneDayInMinutes = 1440;
  if (endMinutes >= oneDayInMinutes) endMinutes = oneDayInMinutes - 1;
  if (startMinutes <= 0) startMinutes = 0;

  return Array.from(
    { length: Math.floor((endMinutes - startMinutes) / periodInMinutes) + 1 },
    (_, i) => minutesToTimeString(startMinutes + i * periodInMinutes, separator)
  );
}

export function getNearestTimeSlot(currTime, periodInMinutes = 15) {
  if (!currTime) return undefined;
  const remainder = periodInMinutes - (currTime.minute() % periodInMinutes);
  const dateTime = moment(currTime).add(remainder, 'minute').format('HH:mm:00');
  return dateTime;
}

export function getPrevTimeSlot(currTime, periodInMinutes = 15) {
  if (!currTime) return undefined;
  const remainder = currTime.minute() % periodInMinutes;
  const dateTime = moment(currTime)
    .subtract(remainder, 'minute')
    .format('HH:mm:00');
  return dateTime;
}

export function computeTimeInterval(startIndex, endIndex, goalTimeMs = 300000) {
  const interval = endIndex - startIndex;
  // const goalTimeMs = 30000; // 5 minutes in MS = 300000, 30s = 30000
  const timerMs = Math.floor(goalTimeMs / interval);
  // console.log('computed time interval: ', goalTimeMs, timerMs);
  return timerMs;
}

export function getTimeDifference(startTime, endTime) {
  // parse time using 24-hour clock and use UTC to prevent DST issues
  const start = moment.utc(startTime, 'HH:mm:ss');
  const end = moment.utc(endTime, 'HH:mm:ss');

  // account for crossing over to midnight the next day
  // if (end.isBefore(start)) end.add(1, 'day');
  // calculate the duration
  // const d = moment.duration(end.diff(start));

  // const msDiff = d.asMinutes();
  // return msDiff;
  const diff = end.diff(start, 'milliseconds');
  return diff;
}

export function isWithinTime(startTime, endTime, currTime) {
  // parse time using 24-hour clock and use UTC to prevent DST issues
  const start = moment.utc(startTime, 'HH:mm:ss');
  const end = moment.utc(endTime, 'HH:mm:ss');
  const curr = moment.utc(currTime, 'HH:mm:ss');

  return curr.isBetween(start, end);
}

export function isStartTimeAfterCurrentTime(startTime, currTime) {
  // parse time using 24-hour clock and use UTC to prevent DST issues
  const start = moment.utc(startTime, 'HH:mm:ss');
  const curr = moment.utc(currTime, 'HH:mm:ss');

  return start.isAfter(curr);
}

export function compareDates(dateA, dateB) {
  const parsedA = moment.utc(dateA, 'HH:mm:ss');
  const parsedB = moment.utc(dateB, 'HH:mm:ss');
  return parsedA.diff(parsedB);
}
