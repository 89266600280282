import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/component/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { HOME_PATH, LOGIN_PATH } from '../shared/others/constant';
import { CommonService } from '../shared/services/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @Input() data:any;
  isOldHide: boolean = true;
  isHide: boolean = true;
  isConfirmHide: boolean = true;
  form: FormGroup;
  isPasswordMatch: boolean = true;
  isValidToken:boolean = false;
  loginBg = environment.image.login;
  token = this._activatedRoute.snapshot.params.token;
  username: string = '';

  constructor(
    private _formBuilder: FormBuilder,
    public cdRef:ChangeDetectorRef,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private _authService: AuthService,
    private _router: Router, 
    private _activatedRoute: ActivatedRoute,) {}

  ngOnInit() {
    this._activatedRoute.queryParamMap
      .subscribe((params:any) => {
        this.token = params.params.token;
      }
    );
    
    this.initPassword();
    this.form = this._formBuilder.group({
      // oldPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
    
  initPassword() {
    var bodyData = {
      token: this.token
    }
    let urlAction = 'users/v1/checkResetLinkExpiry';
    this._commonService.commonPostAction(urlAction, bodyData).subscribe(resp => {
      console.log(resp);
      this.isValidToken = true;
      this.username = resp.userName;
    },
    error => {
      console.log(error);
      this.isValidToken = false;
      this._snackBar.open(error.error.errorMessage, null, {
        duration: 5000,
        panelClass: 'custom-snack-bar-panel-error'
      });

    });
  }
  // onSkip(): void {
  //   // this.dialogRef.close();
  //   this._router.navigate([HOME_PATH])
  // }
  
  // onStrengthChanged(strength: number) {
    // console.log('password strength = ', strength);
    // if(strength >= 100) {
    //   this.form.get('password').setErrors({valid:true});
    // }
  // }

  onPasswordConfirm(data) {
    var password = this.form.value.password;
    var confirmData = this.form.get('confirmPassword').value;
    if(password === confirmData) {
      this.isPasswordMatch = true;
      console.log('match');
      this.form.get('confirmPassword').setErrors(null);
    }
    else {
      this.isPasswordMatch = false;
      console.log('not not');
      this.form.get('confirmPassword').setErrors({valid:false});
    }
    // isPasswordMatch
  }

  onSave() {
    if(this.form.valid) {
      let bodyData = {
        userName: this.username,
        "token" : this.token,
        "newPassword": this.form.get('password').value
      }
      this._snackBar.dismiss();

      let urlAction = 'users/v3/resetPassword';
      this._commonService.commonPostAction(urlAction, bodyData).subscribe(resp => {
        this._router.navigate([LOGIN_PATH]);
        this._snackBar.open(resp.response, null, {
          duration: 5000,
        });
      },
      error => {
        console.log(error)
        this._snackBar.open(error.error.errorMessage, null, {
          duration: 5000,
          panelClass: 'custom-snack-bar-panel-error'
        });
      });
    }
  }

  openWindow() {
    this._router.navigate(['/account/home']);
    let newwin = window.open('account/vehicle-management/device-monitor/2','device-monitor','height='+screen.height+', width='+screen.width+' ');
    if (window.focus) {newwin.focus()}
    return false;
  }
}
