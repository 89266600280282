import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.scss']
})
export class AccountSettingComponent implements OnInit {
  @Input() data:any;
  // @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  // @Output() okayAction: EventEmitter<any> = new EventEmitter<any>();
  username: string = localStorage.getItem('displayname');
  scope: string = localStorage.getItem('scope');
  sipNumber: string = localStorage.getItem('sipNumber');

  constructor(
    public dialogRef: MatDialogRef<AccountSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any) {}

  ngOnInit() {
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }
}