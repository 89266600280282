<div class="user-mgt-group">
  <ng-container *ngIf="isHasAccess; else noAccess">
    <div class="container no-padding">
      <div class="col">
        <div class="row">
          <h3 class="title-header">
            User Management<sup
              ><i
                *ngIf="!isNewWindow"
                class="fa fa-window-restore open-window"
                (click)="openWindow()"></i
            ></sup>
          </h3>
        </div>
      </div>
      <!-- <div class="col message">
        <h3>Only administrator has access rights to this module.</h3>  
      </div> -->

      <div class="group-actions">
        <button mat-raised-button (click)="add()">Add</button>
      </div>

      <div class="table-wrapper">
        <table
          mat-table
          matTableExporter
          [dataSource]="dataSource"
          matSort
          #exporter="matTableExporter"
          (exportStarted)="exportStarted('Exporting...')"
          (exportCompleted)="exportCompleted()">
          <!-- <table mat-table [dataSource]="dataSource" matSort > -->

          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef="let rowData">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <!--(click)="$event.stopPropagation()" 
                (click)="row.highlighted = !row.highlighted" -->
              <mat-checkbox
                (click)="highlightHover(row)"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="tripId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trip ID</th>
            <td mat-cell *matCellDef="let element">{{ element.tripId }}</td>
          </ng-container>

          <ng-container matColumnDef="tripHeadSign">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Head Sign</th>
            <td mat-cell *matCellDef="let element">
              {{ element.tripHeadSign }}
            </td>
          </ng-container>

          <ng-container matColumnDef="busStopName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stop Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.busStopName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="arrivalTimeToStop">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Arrival Time (hh:mm)
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.arrivalTimeToStop }}
            </td>
          </ng-container>

          <ng-container matColumnDef="trafficMode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Traffic Mode
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.trafficMode }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <!-- <button mat-raised-button (click)="edit(element)">Edit</button>  -->
              <button (click)="edit(element)" mat-icon-button color="primary">
                <mat-icon>edit</mat-icon>
              </button>
              <button (click)="delete()" mat-icon-button color="primary">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
              highlighted: row.highlighted
            }"></tr>
          <!-- (click)="row.highlighted = !row.highlighted" -->
        </table>

        <mat-paginator
          [pageSizeOptions]="[10, 25, 50, 100]"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </ng-container>

  <ng-template #noAccess>
    <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
      <h3>{{ rightsError }}</h3>
    </div>
  </ng-template>
</div>
