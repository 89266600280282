<div class="side-logo"></div>
<a
  role="listitem"
  matRipple
  [routerLinkActive]="['active']"
  [routerLink]="['/demo/map-demo']"
  matTooltip="Home"
  matTooltipPosition="after"
  ><mat-icon matListIcon [inline]="true">home</mat-icon></a
>
<a
  role="listitem"
  matRipple
  [routerLinkActive]="['active']"
  [routerLink]="['/demo/timetable']"
  matTooltip="Time Table"
  matTooltipPosition="after"
  ><mat-icon matListIcon [inline]="true">table_chart</mat-icon></a
>
<a
  role="listitem"
  matRipple
  [routerLinkActive]="['active']"
  [routerLink]="['/demo/route-mgmt']"
  matTooltip="Route Management"
  matTooltipPosition="after"
  ><mat-icon matListIcon [inline]="true">route</mat-icon></a
>
<a
  role="listitem"
  matRipple
  [routerLinkActive]="['active']"
  [routerLink]="['/demo/stop-mgmt']"
  matTooltip="Stop Management"
  matTooltipPosition="after"
  ><mat-icon matListIcon [inline]="true">pin_drop</mat-icon></a
>
