<div class="group-account-group">
  <ng-container *ngIf="isHasAccess; else noAccess">
    <div
      class="header-group"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <!-- <div fxFlex="50" fxLayout="row">
        <div>
          <span class="title-header">Account Management</span>
          <sup><i *ngIf="!isNewWindow" class="fa fa-window-restore open-window" (click)="openWindow()"></i></sup>
        </div>
        <div class="mar-left-10">
          test 2 -->
      <!-- <span>
            <mat-form-field appearance="outline" fxFlex="100">
              <input matInput placeholder="Service No." formControlName="streetNameBusStop">
            </mat-form-field>
          </span>
          <span fxFlex="18">
            <button class="btn-med" mat-raised-button type="submit" [disabled]="running || !form.valid" (click)="searchSchedule()">Search</button>
          </span> -->
      <!-- </div>
      </div> -->

      <div fxLayout="row">
        <div>
          <span class="title-header">User Management</span>
          <sup
            ><i
              *ngIf="!isNewWindow"
              class="fa fa-window-restore open-window"
              (click)="openWindow()"></i
          ></sup>
        </div>
      </div>

      <button
        mat-raised-button
        type="button"
        (click)="onAddAccount()"
        class="float-right">
        Add User
      </button>
    </div>

    <div class="left-aligned mar-bot-10">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="onFilter($event.target.value)" />
      </mat-form-field>

      <button mat-raised-button type="button" (click)="onRefresh()">
        Refresh
      </button>
    </div>

    <perfect-scrollbar
      #chatPS
      class="account-list"
      [config]="config"
      [scrollIndicators]="false">
      <table
        class="table-striped"
        mat-table
        [dataSource]="dataSource"
        matTableExporter
        matSort
        #exporter="matTableExporter">
        <!-- <table mat-table [dataSource]="dataSource"> -->

        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef>Display Name</th>
          <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
        </ng-container>
        <ng-container matColumnDef="userEmail">
          <th mat-header-cell *matHeaderCellDef>Email Address</th>
          <td mat-cell *matCellDef="let element">{{ element.userEmail }}</td>
        </ng-container>
        <ng-container matColumnDef="roleName">
          <th mat-header-cell *matHeaderCellDef>Role</th>
          <td mat-cell *matCellDef="let element">
            {{ element.roleName | titlecase }}
          </td>
        </ng-container>
        <ng-container matColumnDef="agency">
          <th mat-header-cell *matHeaderCellDef>Agency</th>
          <td mat-cell *matCellDef="let element">{{ element.agency }}</td>
        </ng-container>
        <ng-container matColumnDef="supervisorName">
          <th mat-header-cell *matHeaderCellDef>Supervisor</th>
          <td mat-cell *matCellDef="let element">
            {{ element.supervisorName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="isAccountDisabled">
          <th mat-header-cell *matHeaderCellDef>Account Disabled</th>
          <td mat-cell *matCellDef="let element">
            <!-- {{element.isAccountDisabled}}  -->
            <mat-checkbox
              type="checkbox"
              [checked]="element.isAccountDisabled"
              (change)="onCheckChange($event.checked, element, 'enabled')">
              {{ element.isAccountDisabled ? 'Yes' : 'No' }}
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="isAccountLocked">
          <th mat-header-cell *matHeaderCellDef>Account Locked</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              type="checkbox"
              [checked]="element.isAccountLocked"
              (change)="onCheckChange($event.checked, element, 'locked')">
              {{ element.isAccountLocked ? 'Locked' : 'No' }}
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="isCredentialExpired">
          <th mat-header-cell *matHeaderCellDef>Credential Expired</th>
          <td mat-cell *matCellDef="let element">
            <!-- {{element.isCredentialExpired}} -->
            <!-- <mat-checkbox type="checkbox" [checked]="element.isCredentialExpired" (change)="onCheckChange($event.checked, element, 'expired')">  -->
            {{ element.isCredentialExpired ? 'Yes' : 'No' }}
            <!-- </mat-checkbox> -->
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="isAccountDeactivated">
          <th mat-header-cell *matHeaderCellDef >Account Deactivated</th>
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox type="checkbox" [checked]="element.isAccountDeactivated" (change)="onCheckChange($event.checked, element, 'activated')"> 
              {{element.isAccountDeactivated ? 'Yes' : 'No'}}
            </mat-checkbox>
          </td>
        </ng-container> -->

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <!-- <button mat-raised-button (click)="edit(element)">Edit</button>  -->
            <button
              matTooltip="Edit User"
              (click)="onAddAccount(element)"
              mat-icon-button
              color="primary">
              <mat-icon>edit</mat-icon>
            </button>
            <button
              matTooltip="Send Reset Password URL"
              (click)="onResetPassword(element)"
              mat-icon-button
              color="primary">
              <mat-icon>password</mat-icon>
            </button>
            <button (click)="onDelete(element)" mat-icon-button color="primary">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </perfect-scrollbar>

    <!-- <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator> -->

    <mat-paginator
      #paginator
      [pageIndex]="0"
      [pageSize]="25"
      [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </ng-container>

  <ng-template #noAccess>
    <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
      <h3>{{ rightsError }}</h3>
    </div>
  </ng-template>
</div>
